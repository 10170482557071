import _ from 'lodash-es';

export interface Indexable {
  key: string;
}

export type IndexableConstructor<TItem, TParent> = new (key: string, parent: TParent) => TItem;

export class ItemIndex<TItem extends Indexable, TParent> implements Iterable<TItem> {
  private items = new Map<string, TItem>();

  *[Symbol.iterator](): Generator<TItem, void, unknown> {
    for (const item of this.items.values()) yield item;
  }

  constructor(
    private type: IndexableConstructor<TItem, TParent>,
    private parent: TParent,
    items: TItem[] = []
  ) {
    for (const item of items) {
      if (!this.items.has(item.key)) this.items.set(item.key, item);
    }
  }

  getItem(key: string): TItem {
    let item = this.items.get(key);
    if (item == null) {
      item = new this.type(key, this.parent);
      this.items.set(key, item);
    }
    return item;
  }

  setItem(item: TItem) {
    this.items.set(item.key, item);
  }

  static toDto<TItem extends Indexable, TParent>(
    type: IndexableConstructor<TItem, TParent> & { toDto(item: TItem): unknown },
    index: ItemIndex<TItem, TParent>
  ): unknown[] {
    return Array.from(index)
      .filter((item) => !_.isEqual(type.toDto(item), type.toDto(new type(item.key, index.parent))))
      .map((item) => type.toDto(item));
  }

  static fromDto<TItem extends Indexable, TParent>(
    type: IndexableConstructor<TItem, TParent> & { fromDto(dto: unknown, parent: TParent): TItem },
    dtos: any[],
    parent: TParent
  ): ItemIndex<TItem, TParent> {
    return new ItemIndex(
      type,
      parent,
      (dtos ?? []).map((dto) => type.fromDto(dto, parent))
    );
  }
}
