import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { SLFormularCategoryKey } from '../sl-formularsatz';
import { SLMitteilungFormular } from './sl-mitteilung-formular';
import { FormularViewType } from '../../formular/formular';
import { SLSchuelerComponent } from '../projectables/sl-schueler.component';
import { SLUnterschriftProtokollComponent } from '../projectables/sl-unterschrift-protokoll.component';
import { SLSchulortComponent } from '../projectables/sl-schulort.component';

export class SL124hMitteilungFormular extends SLMitteilungFormular {
  override get category(): SLFormularCategoryKey {
    return 'mitteilung';
  }
  get viewType(): FormularViewType {
    return SL124hMitteilungFormularComponent;
  }
}

@Component({
  selector: 'fz-sl-124h-mitteilung-formular',
  templateUrl: './sl-124h-mitteilung-formular.component.html',
})
export class SL124hMitteilungFormularComponent extends FormularComponentBase<SL124hMitteilungFormular> {
  static createModel(formular: SL124hMitteilungFormular) {
    return formular.isMitteilungAktiv
      ? {
          schueler: SLSchuelerComponent.createModel(formular),
          erziehungsberechtigte: {
            label: formular.erziehungsberechtigte.description.label,
            field: formular.erziehungsberechtigte,
          },
          anschrift: { label: formular.anschrift.description.label, field: formular.anschrift },
          teilnehmer: { label: formular.teilnehmer.description.label, field: formular.teilnehmer },
          vorgeseheneGespraechsinhalte: {
            label: formular.vorgeseheneGespraechsinhalte.description.label,
            field: formular.vorgeseheneGespraechsinhalte,
          },
          lernentwicklungsbericht: {
            label: formular.lernentwicklungsbericht.description.label,
            field: formular.lernentwicklungsbericht,
          },
          schriftlicheMitteilungHinweise: {
            label: formular.schriftlicheMitteilungHinweise.description.label,
            field: formular.schriftlicheMitteilungHinweise,
          },
          schulort: SLSchulortComponent.createModel(formular),
          unterschrift: SLUnterschriftProtokollComponent.createModel(formular),
        }
      : undefined;
  }

  get model() {
    return SL124hMitteilungFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<SL124hMitteilungFormular>) {
    super(formularService);
  }
}
