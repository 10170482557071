import { Component } from '@angular/core';
import { GeistigTyp } from '../../../models/enums/geistig-typ';
import { SchuelerTyp } from '../../../models/enums/schueler-typ';
import { OptionsBase } from '../../formular/options-base';
import { NIFormular } from '../formulare/ni-formular';

@Component({
  selector: 'fz-ni-schueler-typ',
  templateUrl: 'ni-schueler-typ.component.html',
})
export class NISchuelerTypComponent extends OptionsBase<NIFormular> {
  get formularTyp(): SchuelerTyp | GeistigTyp | null {
    if (this.formular.zeugnis.schuelerTyp === 'Geistigbehindert' && this.formular.zeugnis.geistigTyp === 'Geistig')
      return 'Geistig';
    else return this.formular.zeugnis.schuelerTyp;
  }
  set formularTyp(value: SchuelerTyp | GeistigTyp | null) {
    if (value === 'Geistig') {
      this.formular.zeugnis.schuelerTyp = 'Geistigbehindert';
      this.formular.zeugnis.geistigTyp = 'Geistig';
    } else {
      this.formular.zeugnis.schuelerTyp = value;
      this.formular.zeugnis.geistigTyp = null;
    }
  }
}
