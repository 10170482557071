import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-unterschrift',
  templateUrl: 'he-unterschrift.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, HEUnterschriftComponent)],
})
export class HEUnterschriftComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      zeugnisausgabedatumField: formular.zeugnisausgabedatum,
      schulleitungField: formular.zeugnissatz.schulleitung,
      klassenleitungField: formular.zeugnissatz.klassenleitung,
    };
  }

  @Input() model: ReturnType<typeof HEUnterschriftComponent.createModel> | undefined;
}
