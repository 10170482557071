import { Directive, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { NodeHelper, NodeWalker, TextPosition } from '@modules/dom';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { SpellCheckerService } from './spell-checker.service';
import { WorkspaceService } from '../../shared/services';

@Directive({ selector: '[fzHighlightedRichText]' })
export class HighlightedRichTextDirective implements OnChanges {
  @Input() fzHighlightedRichText: DocumentFragment = document.createDocumentFragment();
  @Input() lineStart = 0;
  @Input() lineLength = 0;
  @Input() selectionStart = 0;
  @Input() selectionEnd = 0;
  @Input() hasFocus = false;
  @Input() endsWithNewline = false;
  private readonly element: HTMLElement;
  constructor(
    elementRef: ElementRef,
    private richText: RichTextComponent,
    private spellChecker: SpellCheckerService,
    private workspaceService: WorkspaceService
  ) {
    this.element = elementRef.nativeElement;
  }

  get lineEnd() {
    return this.lineStart + this.lineLength;
  }

  ngOnChanges(_changes: SimpleChanges): void {
    const fragment = this.spellChecker.checkFragment(
      this.fzHighlightedRichText,
      this.workspaceService.selectedZeugnis?.schuelerVorname?.split(' ') ?? []
    );
    if (
      !this.hasFocus ||
      this.selectionStart === this.selectionEnd ||
      this.selectionEnd <= this.lineStart ||
      this.lineEnd < this.selectionStart
    ) {
      if (
        fragment.childNodes.length !== this.element.childNodes.length ||
        !Array.from(fragment.childNodes).every((n, i) => n.isEqualNode(this.element.childNodes[i]))
      ) {
        NodeHelper.empty(this.element);
        this.element.appendChild(fragment);
        // this.forceSpellCheck();
      }
    } else {
      NodeHelper.empty(this.element);
      const walker = new NodeWalker(fragment);
      let posSelectionStart: TextPosition = {
        node: fragment,
        nodeOffset: 0,
        index: 0,
      };
      let posSelectionEnd: TextPosition = {
        node: fragment,
        nodeOffset: 0,
        index: 0,
      };
      for (const pos of walker.textPositions) {
        if (this.lineStart + pos.index <= this.selectionStart) posSelectionStart = pos;
        if (this.lineStart + pos.index <= this.selectionEnd) posSelectionEnd = pos;
      }
      this.element.appendChild(
        NodeHelper.cloneContents({
          startNode: fragment,
          startOffset: 0,
          endNode: posSelectionStart.node,
          endOffset: posSelectionStart.nodeOffset,
        })
      );
      const spanHighlight = document.createElement('span');
      spanHighlight.className = 'fz-highlight';
      spanHighlight.appendChild(
        NodeHelper.cloneContents({
          startNode: posSelectionStart.node,
          startOffset: posSelectionStart.nodeOffset,
          endNode: posSelectionEnd.node,
          endOffset: posSelectionEnd.nodeOffset,
        })
      );
      if (this.endsWithNewline && this.selectionStart <= this.lineEnd && this.lineEnd < this.selectionEnd) {
        spanHighlight.appendChild(document.createTextNode(String.fromCharCode(160)));
      }
      this.element.appendChild(spanHighlight);
      this.element.appendChild(
        NodeHelper.cloneContents({
          startNode: posSelectionEnd.node,
          startOffset: posSelectionEnd.nodeOffset,
          endNode: fragment,
          endOffset: fragment.childNodes.length,
        })
      );
      // this.forceSpellCheck();
    }
  }
  // forceSpellCheck() {
  //   console.log('force spellcheck for ' + this.element.innerHTML);
  //   const activeElement = document.activeElement as HTMLElement;
  //   this.richText.suppressEventHandling++;
  //   // this.element.focus();
  //   document.getSelection()?.setBaseAndExtent(this.element, 0, this.element, 0);
  //   document.execCommand('insertText', false, 'X');
  //   document.execCommand('delete');
  //   activeElement.focus();
  //   this.richText.suppressEventHandling--;
  // }
}
