import { Formular } from '../modules/formular/formular';
import { FormularFach } from './formular-fach';

export class FormularFachList {
  faecher: FormularFach[];

  constructor(
    public formular: Formular,
    public fachKey: string,
    private kategorien: string | undefined = undefined
  ) {
    this.faecher = Array.from(this.fachList).map(
      (f) =>
        new FormularFach(formular, f, {
          label: 'Fach',
          labelShort: 'Fa',
          kategorien,
        })
    );
    this.faecher.sort((a, b) => a.fach.index - b.fach.index);
  }

  get fachList() {
    return this.formular.zeugnis.faecher.getItemList(this.fachKey);
  }

  addFach(): void {
    const fach = new FormularFach(this.formular, this.fachList.addNew(), {
      label: 'Fach',
      labelShort: 'Fa',
      kategorien: this.kategorien,
    });
    this.faecher.push(fach);
  }

  deleteFach(fach: FormularFach): void {
    this.fachList.remove(fach.fach);
    this.faecher.splice(this.faecher.indexOf(fach), 1);
  }
}
