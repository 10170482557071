import { SpsTyp } from '../../../models/enums/sps-typ';
import { PropertyField } from '../../../models/fields/property-field';
import { RPZeugnis } from '../../../models/rp-zeugnis';

export class SpsTypField extends PropertyField<RPZeugnis, SpsTyp | null> {
  constructor(private zeugnis: RPZeugnis) {
    super(
      {
        label: 'SPS-Typ',
        labelShort: 'SPSTyp',
        property: 'spsTyp',
      },
      { object: zeugnis }
    );
  }
  get value(): SpsTyp | null {
    return this.propertyValue;
  }
  set value(value: SpsTyp | null) {
    this.propertyValue = value;
  }
}
