<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="fz-paragraph-text">
    <p>Die Textbausteine konnten nicht exportiert werden.</p>
    <p class="preWhiteSpaced">
      {{ exportTbResult?.text }}
    </p>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button pButton type="button" class="w-64" label="Erneut exportieren" (click)="restart()"></button>
    </div>
  </ng-template>
</p-panel>
