import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-leistungen-wahl',
  templateUrl: 'he-leistungen-wahl.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, HELeistungenWahlComponent)],
})
export class HELeistungenWahlComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      herkunftssprache: {
        labelField: formular.herkunftssprache.gesamt.label,
        noteField: formular.herkunftssprache.gesamt.note,
      },
    };
  }

  @Input() model: ReturnType<typeof HELeistungenWahlComponent.createModel> | undefined;
}
