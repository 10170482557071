import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-zeugnissatz-protokoll',
  templateUrl: 'sl-zeugnissatz-protokoll.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLZeugnissatzProtokollComponent)],
})
export class SLZeugnissatzProtokollComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      zeugnisTypField: formular.zeugnisTyp,
      gespraechGefuehrtVonLabel: 'Das Gespräch wurde geführt von:',
      gespraechGefuehrtVonField: formular.gespraechGefuehrtVon,
    };
  }

  @Input() model: ReturnType<typeof SLZeugnissatzProtokollComponent.createModel> | undefined;
}
