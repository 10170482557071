<p-overlayPanel #op>
  <ng-template pTemplate>
    <div class="pre-whitespaced">{{ text }}</div>
  </ng-template>
</p-overlayPanel>

<button
  pButton
  type="button"
  class="fz-help-info p-button-rounded p-button-info p-button-text"
  icon="pi pi-question-circle"
  (click)="op.toggle($event)"
></button>
