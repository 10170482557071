import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { FlinkyAuthService } from '../services/auth/flinky-auth.service';
import { NotificationService } from '../services/notification/notification.service';
import { ClientException } from '../../models/client-exception';
import { Message } from 'primeng/api';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private message: Message = {
    severity: 'warn',
    detail: 'Der Server ist nicht erreichbar. Bitte prüfen Sie zunächst Ihre Internetverbindung.',
  };

  constructor(
    private router: Router,
    private flinkyAuth: FlinkyAuthService,
    private notificationService: NotificationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0), // nochmal versuchen
      tap(() => {
        this.notificationService.removeHeaderMessage(this.message);
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 0:
          case 504:
            this.notificationService.addHeaderMessage(this.message);
            break;
          case 400:
            return throwError(() => ClientException.fromDto(error.error));
        }
        return throwError(() => error);
      })
    );
  }
}
