import { Zeugniskopflizenz } from './zeugniskopflizenz';
import { Zeugnissatz } from './zeugnissatz';

export class Helper {
  static getSchuljahrHalbjahr(
    zeugnissatz: Zeugnissatz | Zeugniskopflizenz | { schuljahr: number; halbjahr: number }
  ): number {
    return zeugnissatz.schuljahr + zeugnissatz.halbjahr / 2;
  }
  static getKlassenstufeHalbjahr(zeugnissatz: Zeugnissatz): number {
    return zeugnissatz.klassenstufe + zeugnissatz.halbjahr / 2;
  }

  static jsonNullReplacer(_key: string, value: unknown) {
    return value != null ? value : undefined;
  }
}
