import { BWZeugnis } from '../../../models/bw-zeugnis';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { Zeugnissatz } from '../../../models/zeugnissatz';

export class FremdspracheBezeichnungField extends DropdownField<Zeugnissatz, string | null> {
  constructor(zeugnis: BWZeugnis) {
    super(
      {
        label: 'Fremdsprachenbezeichnung',
        labelShort: 'FrmdBez',
        property: 'fremdspracheBezeichnung',
        candidates: [
          { displayStringShort: String.fromCharCode(160), value: null, content: '' },
          { displayStringShort: 'Französisch', value: 'Französisch', content: 'Französisch' },
          { displayStringShort: 'Englisch', value: 'Englisch', content: 'Englisch' },
        ],
      },
      { object: zeugnis.zeugnissatz }
    );
  }
}
