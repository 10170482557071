export class PageOrder {
  front: number[] = [];
  back: number[] = [];
  duplex: number[] = [];
  countOfEmptyPagesToAdd: number = 0;
}

export class BookletPageOrder {
  static calc(pagesCount: number): PageOrder {
    const res = new PageOrder();
    const count = pagesCount + ((4 - (pagesCount % 4)) % 4);
    res.countOfEmptyPagesToAdd = count - pagesCount;

    for (let i = 1; i < count / 2; i = i + 2) {
      const front = [];
      const back = [];
      front.push(count - i + 1);
      front.push(i);
      res.front = res.front.concat(front);
      back.push(i + 1);
      back.push(count - i);
      res.back = res.back.concat(back);
      res.duplex = res.duplex.concat(front, back);
    }
    return res;
  }
}
