<div class="flex-grow flex flex-wrap gap-3 overflow-auto h-0">
  <p-card header="Geschlechterverteilung" [style]="{ width: '25rem', 'margin-bottom': '2em' }">
    <fz-report-geschlechter></fz-report-geschlechter>
  </p-card>
  <!-- <p-card header="Formulartypen" [style]="{ width: '25rem', 'margin-bottom': '2em' }">
    <fz-report-formulartypen></fz-report-formulartypen>
  </p-card> -->
  <p-card header="Notenschnitt aller Jahrgänge" [style]="{ width: '50rem', 'margin-bottom': '2em' }">
    <fz-report-notenschnitt></fz-report-notenschnitt>
  </p-card>
  <p-card header="Noten eines Jahrgangs" [style]="{ width: '50rem', 'margin-bottom': '2em' }">
    <fz-report-noten-faecher></fz-report-noten-faecher>
  </p-card>
  <p-card header="Noten eines Jahrgangs" [style]="{ width: '50rem', 'margin-bottom': '2em' }">
    <fz-report-noten-faecher-1></fz-report-noten-faecher-1>
  </p-card>
</div>
