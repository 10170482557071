<form [formGroup]="licenseform" (ngSubmit)="downloadLicence()">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="lizenznummer">Lizenznummer</label>
      <input id="lizenznummer" pInputText type="text" formControlName="lizenznummer" />
    </div>
    <div class="p-field p-col-12 p-md-12">
      <button
        pButton
        type="submit"
        icon="pi pi-download"
        label="Herunterladen"
        [loading]="loading"
        [disabled]="!licenseform.valid"
      ></button>
    </div>
  </div>
</form>

<div *ngIf="!showForm">
  <p class="preWhiteSpaced" [ngClass]="{ 'fz-validation-error': isError }">{{ detailText }}</p>
  <p-progressBar *ngIf="showIndicator" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
  <div *ngIf="showErrorButtons">
    <button
      pButton
      type="button"
      label="Ok"
      (click)="restartDownload()"
      icon="pi pi-replay"
      [loading]="loading"
    ></button>
  </div>
</div>
