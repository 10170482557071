<ng-template fzTemplate="sidebar">
  <p-checkbox
    label="Formular zur Lernstands- und Kompetenzbeschreibung für SchülerInnen nichtdeutscher Herkunftssprache verwenden"
    [binary]="true"
    [(ngModel)]="formular.zeugnis.anlageLernstandAktiv"
  ></p-checkbox>
</ng-template>
<fz-formular
  name="NI1234hjLernstand"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
>
  <ng-container *ngIf="model != null">
    <fz-ni-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-ni-zeugniskopf>
    <fz-ni-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-ni-zeugnistyp>
    <fz-ni-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-ni-schueler>
    <fz-ni-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-ni-zeugnissatz>
    <fz-deco
      fzFormularItem="lernstandDeutsch"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-section
        [label]="formular.lernstandDeutsch.gesamt.description.label"
        headerClass="Ueberschriften"
        headerPaddingBottom="normal"
      >
        <fz-sequence gap="normal">
          <fz-section
            *ngFor="let bereich of model.deutschBereiche; trackBy: trackByProperty"
            [label]="bereich.label"
            headerPaddingBottom="normal"
          >
            <fz-verbal
              [field]="bereich.textField"
              [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
            ></fz-verbal>
          </fz-section>
        </fz-sequence>
      </fz-section>
    </fz-deco>
    <fz-deco
      fzFormularItem="englisch"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-section
        [label]="formular.lernstandEnglisch.gesamt.description.label"
        headerClass="Ueberschriften"
        headerPaddingBottom="normal"
      >
        <fz-sequence gap="normal">
          <fz-section
            *ngFor="let bereich of model.englischBereiche; trackBy: trackByProperty"
            [label]="bereich.label"
            headerPaddingBottom="normal"
          >
            <fz-verbal
              [field]="bereich.textField"
              [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
            ></fz-verbal>
          </fz-section>
        </fz-sequence>
      </fz-section>
    </fz-deco>
    <ng-container *ngFor="let fach of model.faecher; trackBy: trackByProperty">
      <fz-deco
        [fzFormularItem]="fach.key"
        [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
      >
        <fz-section [label]="fach.label" headerPaddingBottom="normal">
          <fz-verbal
            [field]="fach.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-section>
      </fz-deco>
    </ng-container>
    <fz-deco
      fzFormularItem="lernstandEmpfehlungen"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-section [label]="model.lernstandEmpfehlungen.label" headerPaddingBottom="normal">
        <fz-intelli
          [field]="model.lernstandEmpfehlungen.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-section>
    </fz-deco>
  </ng-container>
</fz-formular>
