/* eslint-disable no-bitwise */
import { Component, Input } from '@angular/core';
import { PanelDecoratorBase } from '@modules/projectables/panel.component';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';

@Component({
  selector: 'fz-ni-label-container',
  templateUrl: 'ni-label-container.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, NILabelContainerComponent)],
})
export class NILabelContainerComponent extends PanelDecoratorBase {
  @Input() html = '';
  @Input() styleClass = 'Ueberschriften2';
}
