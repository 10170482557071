import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { Formular } from '@modules/formular/formular';
import { FormularService } from '@modules/formular/formular.service';
import {
  Projectable,
  ProjectableBase,
  ProjectableOwnerProvider,
  ProjectableProvider,
} from '@modules/projectables/projectable';

@Component({
  selector: 'fz-radio',
  styles: [':host { height: 100%; display: grid; justify-items: center; align-items: center }'],
  templateUrl: 'radio.component.html',
})
export class RadioComponent extends ProjectableBase implements Projectable {
  @Input() shadow?: string;
  @Input() value: unknown;
  @Input() selectedValue: unknown;
  @Output() selectedValueChange = new EventEmitter<any>();
  @ViewChild('cross') cross?: ElementRef<HTMLElement>;
  rect = new DOMRect();
  crossRect = new DOMRect();
  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef,
    private ownerProvider: ProjectableOwnerProvider,
    public formularService: FormularService<Formular>
  ) {
    super(elementRef, parentProvider);
  }

  onClick(_e: MouseEvent): boolean {
    if (!this.formularService.isReadonly) {
      if (this.selectedValue !== this.value) this.selectedValue = this.value;
      this.selectedValueChange.emit(this.selectedValue);
      this.ownerProvider.provided.projectableContentChange(this);
    }
    return false;
  }

  projectPosition(parentRect: DOMRect): void {
    const childRect = this.sourceElement.getBoundingClientRect();
    this.rect = new DOMRect(
      childRect.left - parentRect.left,
      childRect.top - parentRect.top,
      childRect.width,
      childRect.height
    );
    if (this.cross != null) {
      const crossRect = this.cross.nativeElement.getBoundingClientRect();
      this.crossRect = new DOMRect(
        crossRect.left - childRect.left,
        crossRect.top - childRect.top,
        crossRect.width,
        crossRect.height
      );
      this.changeDetector.detectChanges();
    }
  }
}
