import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SystemActionsService {
  get isElectron() {
    return (window as any).electronAPI != null;
  }

  constructor(private http: HttpClient) {}

  public createUserBackup(): Promise<HttpResponse<Blob>> {
    const options = {
      responseType: 'blob' as const,
      observe: 'response' as const,
    };

    return lastValueFrom(this.http.get('/api/backup/createUserBackup', options));
  }

  public createAutoBackup(): Promise<void> {
    return lastValueFrom(this.http.get<void>('/api/backup/createAutoBackup'));
  }

  public getFilenameFromContentDisposition(contentDisposition: string): string {
    const regex = /filename=(?<filename>[^;]+);/g;
    const match = regex.exec(contentDisposition);
    let filename = match?.groups?.['filename'] ?? '';
    filename = filename.replace(/"/g, '');
    return filename;
  }

  get touchDevice() {
    return navigator.maxTouchPoints && navigator.maxTouchPoints > 0;
  }

  get os(): 'Windows' | 'IOS' | 'MacOS' | 'UNIX' | 'Linux' | null {
    const os = navigator.userAgent;
    if (os.search('Windows') !== -1) {
      return 'Windows';
    } else if (this.isIOS()) {
      return 'IOS';
    } else if (os.search('Mac') !== -1) {
      return 'MacOS';
    } else if (os.search('X11') !== -1 && !(os.search('Linux') !== -1)) {
      return 'UNIX';
    } else if (os.search('Linux') !== -1 && os.search('X11') !== -1) {
      return 'Linux';
    }
    return null;
  }

  private isIOS() {
    if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      return true;
    } else {
      return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
    }
  }
}
