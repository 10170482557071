import { AnlageTyp } from './enums/anlage-typ';
import { IntensivklasseTyp } from './enums/intensivklasse-typ';
import { KlasseLerngruppe } from './enums/klasse-lerngruppe';
import { KlassenstufeFlexTyp } from './enums/klassenstufe-flex-typ';
import { Zeugnis } from './zeugnis';

export class HEZeugnis extends Zeugnis {
  teilgenommen: string | null = null;
  anlageFreitext: string | null = null;
  anlageTyp: AnlageTyp | null = null;
  schulbesuchsjahrText: string | null = null;
  klasseLerngruppe: KlasseLerngruppe | null = null;
  fontSizeFactorAnlage: number | null = null;
  intensivklasseVon: string | null = null;
  intensivklasseBis: string | null = null;
  intensivklasseTyp: IntensivklasseTyp | null = null;
  klassenstufeFlex: KlassenstufeFlexTyp | null = null;
}
