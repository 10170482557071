<fz-panel *ngIf="model != null">
  <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; column-gap: 19px" *ngIf="model.zeugnis != null">
    <div style="grid-column: 1">
      <fz-label class="Allgemeiner_Zeugnis_Text">Schuljahr</fz-label>&nbsp;
      <fz-schuljahr-label class="Schuljahr">{{ formular.zeugnissatz.schuljahrLabel }}</fz-schuljahr-label>
    </div>
    <div style="grid-column: 2; text-align: center" *ngIf="formular.zeugnis.zeugnisTyp !== 'Abgang'">
      <fz-label class="Allgemeiner_Zeugnis_Text">{{ formular.halbjahrLabel }}</fz-label>
    </div>
    <div style="grid-column: 3; text-align: right">
      <fz-label class="Allgemeiner_Zeugnis_Text">Klasse</fz-label>&nbsp;
      <fz-text
        class="Schuljahr"
        [field]="model.zeugnis.klassenbezeichnungField"
        placeholder="Klassenbezeichnung"
      ></fz-text>
    </div>
  </div>
  <div
    style="display: grid; grid-template-columns: auto 1fr 1fr; grid-template-rows: auto auto auto; column-gap: 19px"
    *ngIf="model.lernstand != null"
  >
    <div style="grid-row: 1; grid-column: 1">
      <fz-label>Aufnahme in die Schule am:</fz-label>
    </div>
    <div style="grid-row: 1; grid-column: 2">
      <fz-text [field]="model.lernstand.aufnahmeAmField" placeholder="Datum"></fz-text>
    </div>
    <div style="grid-row: 2; grid-column: 1">
      <fz-label>Aufnahme in eine Sprachfördermaßnahme am:</fz-label>
    </div>
    <div style="grid-row: 2; grid-column: 2">
      <fz-text [field]="model.lernstand.sprachfoerderAmField" placeholder="Datum"></fz-text>
    </div>
    <div style="grid-row: 3; grid-column: 1">
      <fz-label>Art der Sprachfördermaßnahme:</fz-label>
    </div>
    <div style="grid-row: 3; grid-column: 2">
      <fz-text [field]="model.lernstand.sprachfoerderartField" placeholder="Art"></fz-text>
    </div>
    <div style="grid-row: 3; grid-column: 3; text-align: right">
      <fz-label>Klasse</fz-label>&nbsp;
      <fz-text [field]="model.lernstand.klassenbezeichnungField" placeholder="Klassenbezeichnung"></fz-text>
    </div>
  </div>
</fz-panel>
