import { PropertyField } from './property-field';

export class ValueField<T, TValue> extends PropertyField<T, TValue> {
  get value(): TValue {
    return this.propertyValue;
  }
  set value(value: TValue) {
    this.propertyValue = value;
  }
}
