import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  QueryList,
  SkipSelf,
  ViewChildren,
} from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableOwnerProvider, ProjectableBase } from './projectable';
import { RichTextSelection } from './rich-text-selection';
import { TextView, TextViewProvider } from './text-editor.directive';
import { TextViewAccessor } from './text-view-accessor';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { StringElement } from '@modules/pdl';
import { NodeHelper } from '@modules/dom';
import { Font } from '@modules/shared/font.directive';
import { DropdownFieldBase } from '../../models/fields/dropdown-field';
import { TextInputReplaceEvent } from './text-input-replace-event';

@Component({
  selector: 'fz-dropdown',
  templateUrl: 'dropdown.component.html',
  providers: [
    provideInterfaceBy(ProjectableProvider, DropdownComponent),
    provideInterfaceBy(TextViewProvider, DropdownComponent),
  ],
})
export class DropdownComponent
  extends ProjectableBase
  implements AfterViewInit, AfterViewChecked, Projectable, TextView
{
  @Input() placeholder = 'Auswahl';
  @Input() field: DropdownFieldBase<unknown> | undefined;
  @Input() isShadow = false;
  @Output() hasFocusChange = new EventEmitter<boolean>();
  @ViewChildren('stringElement') stringElementRef = new QueryList<ElementRef<StringElement>>();

  #search = '';
  #value: unknown = null;
  selection = new RichTextSelection();
  fragment = document.createDocumentFragment();
  textNode = document.createTextNode('');
  rect = new DOMRect();
  font?: Font;
  private afterViewInit = false;

  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef,
    private ownerProvider: ProjectableOwnerProvider
  ) {
    super(elementRef, parentProvider);
  }

  get text(): string {
    if (this.field != null) {
      const candidate = this.field.candidates.filter((c) => c.value === this.value)[0];
      if (candidate != null) return candidate.content ?? '';
    }
    return '';
  }
  get textShadow(): string {
    return this.isShadow ? this.text : '';
  }
  get textNormal(): string {
    return this.isShadow ? '' : this.text;
  }

  get value(): unknown {
    return this.#value;
  }
  set value(value: unknown) {
    this.#value = value;
    if (this.field != null) this.field.value = value;
    this.updateSlices();
  }
  ngAfterViewInit(): void {
    this.updateSlices();
    this.afterViewInit = true;
  }
  ngAfterViewChecked(): void {
    if (this.field != null && this.field.value !== this.value) this.value = this.field.value;
  }

  onPointCaret(): void {
    this.updateSelection();
  }
  onInsert(char: string): void {
    if (char.length === 1 && this.field != null) {
      this.#search += char.toLowerCase();
      this.#search = this.#search.trim();
      const candidates = this.field.candidates.filter((c) => c.displayString?.toLowerCase().startsWith(this.#search));
      if (candidates.length <= 1) this.#search = '';
      if (candidates.length === 1) {
        this.value = candidates[0].value;
        this.updateSelection();
        // this.focusNext();
      }
    }
  }
  onReplace(e: TextInputReplaceEvent): void {
    if (e.text.length === 1 && this.field != null) {
      this.#search = e.text.toLowerCase();
      const candidates = this.field.candidates.filter((c) => c.displayString?.toLowerCase().startsWith(this.#search));
      if (candidates.length <= 1) this.#search = '';
      if (candidates.length === 1) {
        this.value = candidates[0].value;
        this.updateSelection();
        // this.focusNext();
      }
      this.stringElementRef.first.nativeElement.innerHTML = ' ';
    }
  }

  onDelete(): void {
    this.#search = '';
    this.value = null;
    this.updateSelection();
  }
  onFocusin(): void {
    this.updateSelection();
  }

  projectPosition(parentRect: DOMRect): void {
    const style = window.getComputedStyle(this.sourceElement);
    const topDiff = NodeHelper.getFontTopDiff(style.fontFamily, style.fontSize, style.fontWeight);
    const element = this.textNode.parentNode as HTMLElement;
    if (element != null) {
      const childRect = element.getBoundingClientRect();
      this.rect = new DOMRect(
        childRect.left - parentRect.left + 0.1, // + 0.1 wegen Bug in Webkit (Caret wird nicht angezeigt bei ganzzahligen x-Positionen)
        childRect.top + topDiff.topDiff - parentRect.top,
        childRect.width,
        topDiff.height
      );
      this.font = { family: style.fontFamily, size: style.fontSize, weight: style.fontWeight };
      this.changeDetector.detectChanges();
    }
  }

  updateSelection(): void {
    const textView = new TextViewAccessor(this.stringElementRef.map((r) => r.nativeElement));
    textView.updateSelection(0, this.textNormal.length, false);
  }

  candidateClick(): void {
    this.updateSelection();
    this.#search = '';
    // if (this.value != null) this.focusNext();
  }

  // focusNext(): void {
  //   const stringElement = this.stringElementRef.get(0)?.nativeElement;
  //   if (stringElement != null) {
  //     let root: Node = stringElement;
  //     while (root.parentNode != null) root = root.parentNode;
  //     const focusableElements = Array.from(
  //       (root as DocumentFragment).querySelectorAll('[tabindex]:not([tabindex="-1"]),[contentEditable="true"]')
  //     ).filter((el) => !el.hasAttribute('disabled'));
  //     const index = focusableElements.indexOf(stringElement);
  //     if (index < focusableElements.length - 1) (focusableElements[index + 1] as HTMLElement).focus();
  //   }
  // }

  private updateSlices() {
    this.textNode = document.createTextNode(this.text);
    this.fragment = document.createDocumentFragment();
    this.fragment.append(this.textNode);
    this.changeDetector.detectChanges();
    if (this.afterViewInit) this.ownerProvider.provided.projectableContentChange(this);
  }
}
