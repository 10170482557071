<!-- ({{ intelliDescription?.label }})"-->
<p-dialog
  [appendTo]="appendTo"
  header="Textbaustein bearbeiten"
  [(visible)]="dialogVisible"
  [style]="{ width: '90vw' }"
  [baseZIndex]="10000"
  [maximizable]="true"
  [modal]="true"
  [resizable]="true"
  [contentStyle]="{ height: '50vh' }"
  styleClass="p-fluid tb-dialog"
  contentStyleClass="tb-editor"
  [closable]="false"
>
  <fz-tb-editor-table #editor (tbSaved)="tbSaved($event)" class="h-full"></fz-tb-editor-table>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" (click)="cancel()" label="Abbrechen"></button>
    <button
      type="button"
      pButton
      icon="pi pi-save"
      (click)="ok()"
      label="Speichern"
      [disabled]="!editor?.isDirty()"
    ></button>
  </ng-template>
</p-dialog>
