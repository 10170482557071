import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-sprachenportfolio',
  templateUrl: 'sl-sprachenportfolio.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLSprachenportfolioComponent)],
})
export class SLSprachenportfolioComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      selectField: formular.sprachenportfolio,
    };
  }

  @Input() model: ReturnType<typeof SLSprachenportfolioComponent.createModel> | undefined;

  get schuelerLabel(): string {
    if (this.formular.zeugnis.schueler.geschlecht === 'm') return 'Der Schüler';
    if (this.formular.zeugnis.schueler.geschlecht === 'w') return 'Die Schülerin';
    return 'Der Schüler/Die Schülerin';
  }
  get klassenstufe(): number | null {
    return this.formular.zeugnis.zeugnissatz.klassenstufe < 3
      ? this.formular.zeugnis.zeugnislevel
      : this.formular.zeugnis.zeugnissatz.klassenstufe;
  }
}
