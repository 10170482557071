import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FormularFactory } from '@modules/formular/formular-factory';
import { FormularModule } from '@modules/formular/formular.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RP1jVerbalFormularComponent } from './formulare/rp-1j-verbal-formular.component';
import { RP234hProtokollFormularComponent } from './formulare/rp-234h-protokoll-formular.component';
import { RP34hStandardFormularComponent } from './formulare/rp-34h-standard-formular.component';
import { RPZeugniskopfComponent } from './projectables/rp-zeugniskopf.component';
import { RPFormularsatz } from './rp-formularsatz';
import { RPZeugnissatzComponent } from './projectables/rp-zeugnissatz.component';
import { RPSchuelerComponent } from './projectables/rp-schueler.component';
import { RPSprachenportfolioComponent } from './projectables/rp-sprachenportfolio.component';
import { RPVersaeumnisComponent } from './projectables/rp-versaeumnis.component';
import { RPSchulortComponent } from './projectables/rp-schulort.component';
import { RPUnterschriftComponent } from './projectables/rp-unterschrift.component';
import { RPKenntnisComponent } from './projectables/rp-kenntnis.component';
import { RPSeitenkopfComponent } from './projectables/rp-seitenkopf.component';
import { RPSeitenfussComponent } from './projectables/rp-seitenfuss.component';
import { RPSeitenfussLastComponent } from './projectables/rp-seitenfuss-last.component';
import { RPSchuelerTypComponent } from './options/rp-schueler-typ.component';
import { RPHerkunftsspracheComponent } from './options/rp-herkunftssprache.component';
import { RPSpsComponent } from './projectables/rp-sps.component';
import { RPFachHeaderComponent } from './projectables/rp-fach-header.component';
import { RP234jaStandardFormularComponent } from './formulare/rp-234ja-standard-formular.component';
import { RPGrundschulzielComponent } from './projectables/rp-grundschulziel.component';
import { RPSiegelComponent } from './projectables/rp-siegel.component';
import { RPAbgangszeugnisComponent } from './options/rp-abgangszeugnis.component';
import { RPProtokollTypComponent } from './options/rp-protokoll-typ.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { RPSchulortProtokollComponent } from './projectables/rp-schulort-protokoll.component';
import { RP4hEmpfehlungFormularComponent } from './formulare/rp-4h-empfehlung-formular.component';
import { RPKPButtonComponent } from './options/rp-kp-button.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ButtonModule,
    MenuModule,
    CheckboxModule,
    RadioButtonModule,
    FormularModule,
    MultiSelectModule,
  ],
  declarations: [
    RPSchuelerTypComponent,
    RPHerkunftsspracheComponent,
    RPSeitenkopfComponent,
    RPSeitenfussComponent,
    RPSeitenfussLastComponent,
    RP1jVerbalFormularComponent,
    RP234jaStandardFormularComponent,
    RP234hProtokollFormularComponent,
    RP34hStandardFormularComponent,
    RP4hEmpfehlungFormularComponent,
    RPZeugniskopfComponent,
    RPZeugnissatzComponent,
    RPSchuelerComponent,
    RPSprachenportfolioComponent,
    RPVersaeumnisComponent,
    RPSchulortComponent,
    RPUnterschriftComponent,
    RPKenntnisComponent,
    RPSpsComponent,
    RPFachHeaderComponent,
    RPGrundschulzielComponent,
    RPSiegelComponent,
    RPAbgangszeugnisComponent,
    RPProtokollTypComponent,
    RPSchulortProtokollComponent,
    RPKPButtonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class RPModule {}

FormularFactory.registerFormularsatz(new RPFormularsatz());
