import _ from 'lodash-es';
import { NoteTyp } from './enums/note-typ';

export class Note {
  wert: number = 0;
  typ: NoteTyp | null = null;
  suffix: string | null = null;

  toDto(): unknown {
    if (_.isEqual(this, new Note())) return null;
    else return Object.assign({}, this);
  }

  static fromDto(dto: any): Note {
    return Object.assign(new Note(), dto);
  }
}
