import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-zeugnistyp',
  templateUrl: 'he-zeugnistyp.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, HEZeugnistypComponent)],
})
export class HEZeugnistypComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      titelDropdownField: formular.zeugnis.zeugnissatz.klassenstufe > 0 ? formular.zeugnisTyp : undefined,
      titelString: formular.zeugnis.zeugnissatz.klassenstufe === 0 ? 'Entwicklungsbericht' : undefined,
      untertitelString:
        formular.zeugnis.schuelerTyp === 'Lernbehindert'
          ? 'Bildungsgang Förderschwerpunkt Lernen'
          : formular.zeugnis.schuelerTyp === 'Geistigbehindert'
            ? 'Bildungsgang Förderschwerpunkt geistige Entwicklung'
            : undefined,
      untertitelIntensivklasseField:
        formular.zeugnis.schuelerTyp === 'Intensiv' ? formular.intensivklasseTypGenitiv : undefined,
    };
  }

  @Input() model: ReturnType<typeof HEZeugnistypComponent.createModel> | undefined;
}
