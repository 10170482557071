import { ListIndexable } from './item-list-index';
import { KPFach } from './kp-fach';
import { KPItem } from './kp-item';

export class KPBereich implements ListIndexable {
  index: number = 0;
  label: string | null = null;
  items: KPItem[] = [];
  version: number | null = null;

  constructor(
    public readonly key: string | null,
    public readonly fach: KPFach
  ) {}

  static cleanDto(dto: any): any {
    const { key, fach, items, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(bereich: KPBereich): unknown {
    return { ...this.cleanDto(bereich), key: bereich.key, items: bereich.items.map((i) => KPItem.toDto(i)) };
  }

  static fromDto(dto: any, fach: KPFach): KPBereich {
    return Object.assign(new KPBereich(dto.key ?? null, fach), {
      ...this.cleanDto(dto),
      label: dto.label === '' ? null : dto.label,
      items: dto.items.map((item: any) => KPItem.fromDto(item)),
    });
  }
}
