import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';

@Injectable({
  providedIn: 'root',
})
export class FzeGuidedTourService {
  buttonsCloseBackNext: Step.StepOptionsButton[] = [
    {
      classes: 'shepherd-button-secondary',
      text: 'Beenden',
      action: () => this.shepherdService.cancel(),
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Zurück',
      action: () => this.shepherdService.back(),
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Vor',
      action: () => this.shepherdService.next(),
    },
  ];
  buttonsCloseNext: Step.StepOptionsButton[] = [
    {
      classes: 'shepherd-button-secondary',
      text: 'Beenden',
      action: () => this.shepherdService.cancel(),
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Vor',
      action: () => this.shepherdService.next(),
    },
  ];
  buttonsCloseBack: Step.StepOptionsButton[] = [
    {
      classes: 'shepherd-button-secondary',
      text: 'Beenden',
      action: () => this.shepherdService.cancel(),
    },
    {
      classes: 'shepherd-button-primary',
      text: 'Zurück',
      action: () => this.shepherdService.back(),
    },
  ];

  constructor(private shepherdService: ShepherdService) {}
  addSteps(steps: Array<Step.StepOptions>) {
    this.shepherdService.addSteps(steps);
  }

  close() {
    if (this.shepherdService.isActive) this.shepherdService.cancel();
  }

  start() {
    this.shepherdService.start();
  }

  init() {
    // this.shepherdService.defaultStepOptions = defaultStepOptions;
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.defaultStepOptions = {
      id: 'intro',
      beforeShowPromise: () => {
        return new Promise<void>(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          }, 500);
        });
      },
      buttons: this.buttonsCloseBackNext,
      cancelIcon: {
        enabled: true,
      },
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
    };
  }
}
