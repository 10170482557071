import { Benutzer } from '../../../models/generated/benutzer';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  public saveProfileData(
    vorname?: string,
    name?: string,
    email?: string,
    unterschrift?: string,
    kundennummer?: string
  ): Promise<Benutzer> {
    const data = {
      email: email ?? '',
      name: name ?? '',
      vorname: vorname ?? '',
      unterschrift: unterschrift ?? '',
      kundennummer: kundennummer ?? '',
    };

    return lastValueFrom(this.http.put<Benutzer>('/api/Benutzer/updateProfile', data));
  }

  public updateEmail(email: string, currentPassword: string) {
    const data = {
      email: email ?? '',
      currentPassword: currentPassword ?? '',
    };

    return lastValueFrom(this.http.put<boolean>('/api/Benutzer/updateEmail', data));
  }
}
