import jsPDF from 'jspdf';
import { Quantity } from '../dom/quantity';
import { ContentElement } from './content-element';

export class RectElement extends ContentElement {
  constructor() {
    super();
  }

  exportPdf(doc: jsPDF, pageRect: DOMRect): void {
    const nodeRect = this.getBoundingClientRect();
    const style = window.getComputedStyle(this);
    const left = Quantity.px2Mm(nodeRect.left - pageRect.left);
    const top = Quantity.px2Mm(nodeRect.top - pageRect.top);
    const right = Quantity.px2Mm(nodeRect.right - pageRect.left);
    const bottom = Quantity.px2Mm(nodeRect.bottom - pageRect.top);
    const width = Quantity.px2Mm(nodeRect.width);
    const height = Quantity.px2Mm(nodeRect.height);
    const rgba = (style.backgroundColor.match(/\d+(\.\d+)?/g) ?? ['0', '0', '0', '0']).map((c) => parseFloat(c));
    if (rgba[3] !== 0) {
      const rgb = this.rgba2rgb(rgba);
      doc.setFillColor(rgb[0], rgb[1], rgb[2]);
      doc.rect(left, top, width, height, 'F');
    }
    const rgbBorder = (style.borderColor.match(/\d+(\.\d+)?/g) ?? ['0', '0', '0']).map((c) => parseFloat(c));
    doc.setDrawColor(rgbBorder[0], rgbBorder[1], rgbBorder[2]);
    if (style.borderTopStyle === 'solid') {
      const lineWidth = Quantity.px2Mm(parseFloat(style.borderTopWidth));
      doc.setLineWidth(lineWidth);
      doc.line(left + lineWidth / 2, top + lineWidth / 2, right - lineWidth / 2, top + lineWidth / 2);
    }
    if (style.borderBottomStyle === 'solid') {
      const lineWidth = Quantity.px2Mm(parseFloat(style.borderBottomWidth));
      doc.setLineWidth(lineWidth);
      doc.line(left + lineWidth / 2, bottom - lineWidth / 2, right - lineWidth / 2, bottom - lineWidth / 2);
    }
    if (style.borderLeftStyle === 'solid') {
      const lineWidth = Quantity.px2Mm(parseFloat(style.borderLeftWidth));
      doc.setLineWidth(lineWidth);
      doc.line(left + lineWidth / 2, top + lineWidth / 2, left + lineWidth / 2, bottom - lineWidth / 2);
    }
    if (style.borderRightStyle === 'solid') {
      const lineWidth = Quantity.px2Mm(parseFloat(style.borderRightWidth));
      doc.setLineWidth(lineWidth);
      doc.line(right - lineWidth / 2, top + lineWidth / 2, right - lineWidth / 2, bottom - lineWidth / 2);
    }
  }

  get width(): string | null {
    return this.getAttribute('data-width');
  }
  set width(width: string | null) {
    if (width !== null) {
      this.setAttribute('data-width', width);
      this.style.borderWidth = `${width}px`;
    } else this.removeAttribute('data-width');
  }

  get topStyle(): string | null {
    return this.getAttribute('data-top-style');
  }
  set topStyle(style: string | null) {
    if (style !== null) {
      this.setAttribute('data-top-style', style);
      this.style.borderTopStyle = style;
    } else this.removeAttribute('data-top-style');
  }

  get bottomStyle(): string | null {
    return this.getAttribute('data-bottom-style');
  }
  set bottomStyle(style: string | null) {
    if (style !== null) {
      this.setAttribute('data-bottom-style', style);
      this.style.borderBottomStyle = style;
    } else this.removeAttribute('data-bottom-style');
  }

  get leftStyle(): string | null {
    return this.getAttribute('data-left-style');
  }
  set leftStyle(style: string | null) {
    if (style !== null) {
      this.setAttribute('data-left-style', style);
      this.style.borderLeftStyle = style;
    } else this.removeAttribute('data-left-style');
  }

  get rightStyle(): string | null {
    return this.getAttribute('data-right-style');
  }
  set rightStyle(style: string | null) {
    if (style !== null) {
      this.setAttribute('data-right-style', style);
      this.style.borderRightStyle = style;
    } else this.removeAttribute('data-right-style');
  }

  private rgba2rgb(rgba: number[]): number[] {
    const alpha = rgba[3];
    return [
      (1 - alpha) * 255 + alpha * rgba[0],
      (1 - alpha) * 255 + alpha * rgba[1],
      (1 - alpha) * 255 + alpha * rgba[2],
    ];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'fz-rect': RectElement;
  }
}
