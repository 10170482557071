import { KPBereich } from '../kp-bereich';
import { KPItemBenotung } from '../kp-item-benotung';
import { Field, FieldDescription } from './field';

export type KPRowFieldRef = {
  benotung: KPItemBenotung;
  bereich?: KPBereich;
};

export class KPRowField extends Field {
  constructor(
    description: FieldDescription,
    public readonly ref: KPRowFieldRef
  ) {
    super(description);
  }

  get key() {
    return `${this.benotung.bereich.fach.key}.${this.benotung.bereich.key ?? 'gesamt'}.kp.${this.benotung.item.guid}`;
  }

  get lines(): string[] {
    return (
      this.benotung.item.text
        ?.replace(/\t/g, ' ')
        /*?.replace(/•/g, '-')*/ // sowieso kein Einrücken implementiert
        ?.split('\n') ?? ['']
    );
  }

  get value(): number | null {
    return this.benotung.note;
  }
  set value(value: number | null) {
    this.benotung.note = value;
  }
  get displayString() {
    return this.displayStringShort;
  }
  get displayStringShort() {
    return this.value?.toString() ?? '';
  }

  get benotung() {
    return this.ref.benotung;
  }
  get bereich() {
    return this.ref.bereich;
  }
}
