<ng-template fzTemplate="sidebar">
  <fz-rp-schueler-typ></fz-rp-schueler-typ>
  <fz-rp-herkunftssprache></fz-rp-herkunftssprache>
  <fz-rp-abgangszeugnis></fz-rp-abgangszeugnis>
</ng-template>
<fz-formular
  name="RP34hStandard"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  <fz-rp-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-rp-zeugniskopf>
  <fz-rp-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-rp-zeugnissatz>
  <fz-rp-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-rp-schueler>
  <fz-deco fzFormularItem="sps" paddingLeft="normal" paddingRight="normal" *ngIf="model.sps != null">
    <fz-rp-sps fzContainer [model]="model.sps"></fz-rp-sps>
  </fz-deco>
  <fz-deco
    fzFormularItem="sozialLernArbeitsverhalten"
    [marginBottom]="-1"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
    paddingBottom="normal"
  >
    <fz-section
      [label]="model.sozialLernArbeitsverhalten.headerLabel"
      headerClass="Ueberschriften2"
      headerPaddingBottom="normal"
    >
      <fz-verbal
        [field]="model.sozialLernArbeitsverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <ng-container *ngFor="let fach of model.faecher; trackBy: trackByProperty; last as isLast">
    <fz-deco
      [fzFormularItem]="fach.key"
      [marginBottom]="isLast ? 4 : -borderWidth"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingBottom="normal"
    >
      <fz-section [label]="fach.headerLabel" headerClass="Ueberschriften2">
        <fz-rp-fach-header fzSectionHeader [model]="fach.header"></fz-rp-fach-header>
        <fz-panel fzSectionHeader class="Ueberschriften2">
          <fz-label>{{ fach.header.labelFortsetzung }} (Fortsetzung)</fz-label>
        </fz-panel>
        <fz-optional [field]="fach.verbalVisibleField" label="Verbale Beurteilung">
          <fz-deco *ngIf="fach.textField != null" paddingTop="normal">
            <fz-verbal
              [field]="fach.textField"
              [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
            ></fz-verbal>
          </fz-deco>
        </fz-optional>
      </fz-section>
    </fz-deco>
  </ng-container>
  <fz-deco
    fzFormularItem="gespraechssatz"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
    *ngIf="model.gespraechsatz != null"
  >
    <fz-panel fzContainer>
      <fz-select [field]="model.gespraechsatz.selectField" [suppressNull]="true">
        <fz-panel fzSelectCandidate [fzValue]="null" fzLabel="Gespräch geführt (mit Datum)">
          <fz-label
            >Das vorgesehene Gespräch über die allgemeine Lern- und Leistungsentwicklung gem. § 39 Abs. 4 GSchO wurde am
          </fz-label>
          <fz-linked-date
            [field]="model.gespraechsatz.protokolldatumField"
            [linkedField]="formular.zeugnissatz.protokollDatum"
            fieldName="protokolldatum"
          ></fz-linked-date>
          <fz-label> geführt.</fz-label>
        </fz-panel>
        <fz-panel fzSelectCandidate [fzValue]="'Stern'" fzLabel="Gespräch wird noch geführt oder nicht geführt (mit *)">
          <fz-label
            >Das vorgesehene Gespräch über die allgemeine Lern- und Leistungsentwicklung gem. § 39 Abs. 4 GSchO wurde am
            *) geführt.</fz-label
          >
        </fz-panel>
      </fz-select>
    </fz-panel>
  </fz-deco>
  <fz-deco
    fzFormularItem="sprachenportfolio"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-rp-sprachenportfolio fzContainer [model]="model.sprachenportfolio"></fz-rp-sprachenportfolio>
  </fz-deco>
  <fz-deco
    fzFormularItem="schrift"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
    paddingBottom="normal"
  >
    <fz-section [label]="model.schrift.headerLabel" headerClass="Ueberschriften2" headerPaddingBottom="normal">
      <fz-verbal
        [field]="model.schrift.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <fz-rp-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-rp-versaeumnis>
  <fz-deco
    fzFormularItem="bemerkungen"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
    paddingBottom="normal"
  >
    <fz-section [label]="model.bemerkungen.headerLabel" headerClass="Ueberschriften2" headerPaddingBottom="normal">
      <fz-intelli
        [field]="model.bemerkungen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-section>
  </fz-deco>
  <fz-rp-schulort fzFormularItem="schulort" fzContainer [model]="model.schulort"></fz-rp-schulort>
  <fz-rp-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-rp-unterschrift>
  <fz-rp-kenntnis fzFormularItem="kenntnis" fzContainer></fz-rp-kenntnis>
</fz-formular>
