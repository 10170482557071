import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';
import { TimelineModule } from 'primeng/timeline';
import { PanelModule } from 'primeng/panel';
import { SchuelerAkteComponent } from './schueler-akte.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    CalendarModule,
    DropdownModule,
    SkeletonModule,
    PanelModule,
    TimelineModule,
    CardModule,
  ],
  declarations: [SchuelerAkteComponent],
  exports: [SchuelerAkteComponent],
})
export class SchuelerAkteModule {}
