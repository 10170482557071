import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

export interface ErrorMessage {
  message: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  messages: ErrorMessage[] = [];
  private _headerMessageSet = new Set<Message>();

  get headerMessages(): Message[] {
    return Array.from(this._headerMessageSet);
  }

  constructor(private messageService: MessageService) {}

  addHeaderMessage(message: Message) {
    this._headerMessageSet.add(message);
  }
  removeHeaderMessage(message: Message) {
    this._headerMessageSet.delete(message);
  }
  toggleHeaderMessage(message: Message, show: boolean) {
    if (show) this.addHeaderMessage(message);
    else this.removeHeaderMessage(message);
  }

  showInfo(message: string, title: string): void {
    this.messageService.add({ severity: 'info', summary: title, detail: message });
  }

  showSuccess(message: string, title: string): void {
    this.messageService.add({ severity: 'success', summary: title, detail: message });
  }
  showPermanentError(message: string, title: string): void {
    if (this.addMessage(message, title))
      this.messageService.add({ key: 'errorDlg', sticky: true, severity: 'error', summary: title, detail: message });
  }
  showPermanentWarning(message: string, title: string): void {
    if (this.addMessage(message, title))
      this.messageService.add({ key: 'errorDlg', sticky: true, severity: 'warn', summary: title, detail: message });
  }

  showPermanentInfo(message: string, title: string): void {
    if (this.addMessage(message, title))
      this.messageService.add({ key: 'errorDlg', sticky: true, severity: 'info', summary: title, detail: message });
  }

  showPermanentSuccess(message: string, title: string): void {
    if (this.addMessage(message, title))
      this.messageService.add({ key: 'errorDlg', sticky: true, severity: 'success', summary: title, detail: message });
  }

  removeMessages() {
    this.messages = [];
  }
  removeMessage(message: string, title: string) {
    this.messages = this.messages.filter((m) => m.message !== message && m.title === title);
  }
  private addMessage(message: string, title: string) {
    const errorMessage: ErrorMessage = { message, title };
    if (!this.messages.some((m) => m.message === message && m.title === title)) {
      this.messages.push(errorMessage);
      return true;
    }
    return false;
  }
}
