import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerbalbeurteilungComponent } from './verbalbeurteilung.component';
import { TreeModule } from 'primeng/tree';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { TextbausteinEditorModule } from '../textbaustein-editor/textbaustein-editor.module';
import { TagModule } from 'primeng/tag';
import { DividerModule } from 'primeng/divider';
@NgModule({
  declarations: [VerbalbeurteilungComponent],
  imports: [
    CommonModule,
    FormsModule,
    TreeModule,
    ListboxModule,
    ButtonModule,
    DialogModule,
    CheckboxModule,
    TextbausteinEditorModule,
    TagModule,
    DividerModule,
  ],
  exports: [VerbalbeurteilungComponent],
})
export class VerbalbeurteilungModule {}
