<fz-panel *ngIf="model != null">
  <fz-select
    placeholder="Versetzungsvermerk"
    emptyLabel="(Bleibt leer - es wird nichts gedruckt)"
    [emptyValue]="'Leer'"
    class="Allgemeiner_Zeugnis_Text"
    [field]="model.selectField"
  >
    <ng-container *ngIf="formular.zeugnis.schuelerTyp !== 'Intensiv'">
      <fz-panel fzSelectCandidate [fzValue]="'Versetzt'" fzLabel="Versetzt">
        <fz-label>{{ labelVersetzt }}</fz-label>
      </fz-panel>
      <fz-panel fzSelectCandidate [fzValue]="'NichtVersetzt'" fzLabel="Nicht versetzt">
        <fz-label>{{ labelNichtVersetzt }}</fz-label>
      </fz-panel>
    </ng-container>
    <ng-container *ngIf="formular.zeugnis.schuelerTyp === 'Intensiv'">
      <fz-panel fzSelectCandidate [fzValue]="'Versetzt'" fzLabel="Abgeschlossen">
        <fz-label>Nach Beschluss der Klassenkonferenz vom </fz-label
        ><fz-date [field]="model.klassenkonferenzdatumField"></fz-date
        ><fz-label> ist die Sprachfördermaßnahme abgeschlossen.</fz-label>
      </fz-panel>
      <fz-panel fzSelectCandidate [fzValue]="'NichtVersetzt'" fzLabel="Verbleib">
        <fz-label>{{ formular.schueler.personalPronomen }} verbleibt nach Beschluss der Klassenkonferenz vom </fz-label
        ><fz-date [field]="model.klassenkonferenzdatumField"></fz-date
        ><fz-label> für ein weiteres Schulhalbjahr in der Maßnahme.</fz-label>
      </fz-panel>
    </ng-container>
    <fz-panel fzSelectCandidate [fzValue]="'FreierText'" fzLabel="(Freier Text)">
      <fz-text placeholder="Freier Text für Versetzungsvermerk" [field]="model.freitextField"></fz-text>
    </fz-panel>
  </fz-select>
</fz-panel>
