import { FileUpload, FileUploadEvent } from 'primeng/fileupload';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { WorkspaceService } from '../../../shared/services';
import { RestoreUserBackupService } from './restore-user-backup.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import BackupDto from '../../../models/generated/backup-dto';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ClientException } from '../../../models/client-exception';

@Component({
  selector: 'fz-restore-user-backup',
  templateUrl: './restore-user-backup.component.html',
  styleUrls: ['./restore-user-backup.component.scss'],
})
export class RestoreUserBackupComponent implements OnInit {
  @ViewChild('uploader') uploader?: FileUpload;

  backups: BackupDto[] = [];
  rowGroupMetadata: any = {};
  password: string = '';
  backupRestoring = false;
  error: string | undefined;
  completed = false;

  activeStep = 0;
  items: MenuItem[] = [];
  restoreType: 'autosicherung' | 'manuell' | '' = '';
  filename: string = '';

  constructor(
    private restoreUserBackupService: RestoreUserBackupService,
    public workspaceService: WorkspaceService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.restoreUserBackupService.loadBackups().then((backups) => {
      this.backups = backups;
      this.updateRowGroupMetaData();
    });
    this.items = [
      { label: 'Art wählen' },
      { label: 'Sicherung auswählen' },
      { label: 'Passwort angeben' },
      { label: 'Fertig' },
    ];
  }

  onSort(): void {
    this.updateRowGroupMetaData();
  }

  async zurZeugnisUebersicht() {
    await this.workspaceService.openLatest();
    this.router.navigate(['/main']);
  }

  openFileSelectionDialog(): void {
    this.uploader?.clearInputElement();
    this.uploader?.clearIEInput();
    this.uploader?.clear();
    setTimeout(() => {
      const input: HTMLInputElement = this.uploader?.basicFileInput?.nativeElement;
      input.click();
    });
  }

  updateRowGroupMetaData(): void {
    this.rowGroupMetadata = {};

    if (this.backups) {
      for (let i = 0; i < this.backups.length; i++) {
        const rowData = this.backups[i];
        const date = rowData.date;

        if (i === 0) {
          this.rowGroupMetadata[date] = { index: 0, size: 1 };
        } else {
          const previousRowData = this.backups[i - 1];
          const previousRowGroup = previousRowData.date;
          if (date === previousRowGroup) this.rowGroupMetadata[date].size++;
          else this.rowGroupMetadata[date] = { index: i, size: 1 };
        }
      }
    }
  }

  doCombinedUpload() {
    this.confirmationService.confirm({
      message:
        'Wollen Sie diese Datensicherung wirklich wiederherstellen?\nDieser Vorgang überschreibt die aktuellen Daten!',
      header: 'Datensicherung wiederherstellen',
      icon: 'pi pi-question-circle',
      accept: async () => {
        this.backupRestoring = true;
        if (this.uploader) {
          await this.workspaceService.close();
          this.uploader.url = '/api/backup/combinedRestore';
          this.uploader.headers = new HttpHeaders()
            .append('ignoreContentType', 'true')
            .append('password', this.restoreUserBackupService.toBase64(this.password));
          this.uploader.upload();
          this.activeStep++;
        }
      },
    });
  }

  uploadComplete(_event: FileUploadEvent): void {
    this.error = undefined;
    this.completed = true;
    this.backupRestoring = false;
    this.workspaceService.openLatest();
  }

  uploadError(event: { files: any[]; error?: unknown }): void {
    if (this.uploader) {
      this.uploader.clear();
    }
    this.backupRestoring = false;
    if (event.error instanceof ClientException) this.error = event.error.message;
    else throw event.error;
  }

  wiederherstellen(filename: string): void {
    this.confirmationService.confirm({
      message:
        'Wollen Sie diese Datensicherung wirklich wiederherstellen?\nDieser Vorgang überschreibt die aktuellen Daten!',
      header: 'Datensicherung wiederherstellen',
      icon: 'pi pi-question-circle',
      accept: async () => {
        this.activeStep++;
        await this.workspaceService.close();
        try {
          this.backupRestoring = true;
          await this.restoreUserBackupService.restore(filename, this.password);
          this.completed = true;
          this.error = undefined;
          await this.workspaceService.openLatest();
        } catch (ex) {
          if (ex instanceof ClientException) {
            this.error = ex.message;
          } else throw ex;
        } finally {
          this.backupRestoring = false;
        }
      },
    });
  }
}
