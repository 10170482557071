import { Geschlecht } from './enums/geschlecht';
import { SchuelerTyp } from './enums/schueler-typ';
import { SchuelerContainer } from './generated/schueler-container';
import { Helper } from './helper';
import { Jahrgang } from './jahrgang';
import { Metadata } from './metadata';
import { Zeugnis } from './zeugnis';

export class Schueler extends Metadata {
  readonly zeugnisse: Zeugnis[] = [];

  name: string | null = null;
  vorname: string | null = null;
  geschlecht: Geschlecht | null = null;
  geburtsort: string | null = null;
  geburtsdatum: string | null = null;
  isAktiv: boolean = false;
  schuelerTyp: SchuelerTyp | null = null;
  anschriftEltern: string | null = null;

  get jahrgangId() {
    return this.jahrgang.id;
  }

  get personalPronomen(): string {
    if (this.geschlecht === 'm') return 'Er';
    if (this.geschlecht === 'w') return 'Sie';
    return 'Er/Sie';
  }

  get label(): string {
    if (this.geschlecht === 'm') return 'Schüler';
    if (this.geschlecht === 'w') return 'Schülerin';
    return 'Schüler(in)';
  }

  constructor(
    public readonly id: string,
    public readonly jahrgang: Jahrgang
  ) {
    super();
    jahrgang.schuelers.push(this);
  }

  remove() {
    this.jahrgang.schuelers.splice(this.jahrgang.schuelers.indexOf(this), 1);
  }

  static override cleanDto(dto: any): any {
    const { id, jahrgang, zeugnisse, ...cleanDto } = Metadata.cleanDto(dto);
    return cleanDto;
  }

  static override toDto(schueler: Schueler): SchuelerContainer {
    return {
      ...Metadata.toDto(schueler),
      id: schueler.id,
      jahrgangId: schueler.jahrgang.id,
      contentString: JSON.stringify({ ...this.cleanDto(schueler) }, Helper.jsonNullReplacer),
    };
  }

  static fromDto(dto: SchuelerContainer, jahrgang: Jahrgang): Schueler {
    const schueler = new Schueler(dto.id, jahrgang);
    Metadata.assignDto(schueler, dto);
    return Object.assign(schueler, this.cleanDto(JSON.parse(dto.contentString)));
  }

  static assign(dst: Schueler, src: Schueler) {
    Object.assign(dst, this.cleanDto(src));
  }
}
