<fz-panel class="Fusstext">
  <div
    *ngIf="
      formular.category === 'zeugnis' &&
      formular.zeugnissatz.klassenstufe >= 2 &&
      formular.zeugnis.schuelerTyp !== 'Intensiv'
    "
  >
    <fz-label>*) Werken und Textiles Gestalten sind Bestandteil des Faches Kunst.</fz-label>
  </div>
  <div
    *ngIf="
      formular.category === 'zeugnis' &&
      formular.zeugnissatz.klassenstufe >= 3 &&
      formular.zeugnis.schuelerTyp !== 'Intensiv'
    "
  >
    <fz-label
      >**) Die Leistungen in der Fremdsprache bleiben bei der Versetzungsentscheidung unberücksichtigt.</fz-label
    >
  </div>
  <fz-panel
    class="Fusstext"
    [border]="borderDirection.top"
    *ngIf="formular.category === 'zeugnis' && formular.zeugnissatz.klassenstufe >= 2"
  >
    <div style="text-align: center">
      <fz-label>Erläuterungen:</fz-label>
    </div>
    <div style="text-align: center">
      <fz-label>sehr gut (1), gut (2), befriedigend (3), ausreichend (4), mangelhaft (5), ungenügend (6)</fz-label>
    </div>
    <div style="text-align: center">
      <fz-label
        >tg. = teilgenommen, m.E.tg. = mit Erfolg teilgenommen, m.g.E.tg. = mit gutem Erfolg teilgenommen</fz-label
      >
    </div>
  </fz-panel>
  <div
    *ngIf="
      formular.category === 'zeugnis' &&
      formular.zeugnis.zeugnissatz.klassenstufe >= 2 &&
      formular.zeugnis.schuelerTyp !== 'Intensiv'
    "
    style="margin-right: 6px; display: grid; grid-template-columns: 1fr auto"
  >
    <fz-dropdown style="grid-column: 2" placeholder="Keine Anlage" [field]="formular.anlageTyp"></fz-dropdown>
  </div>
</fz-panel>
