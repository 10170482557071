import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadLicenceService {
  constructor(private http: HttpClient) {}

  public downloadLicense(kundennummer: string, connectionId: string | null | undefined) {
    const httpParams = new HttpParams().append('kundennummer', kundennummer).append('connectionId', connectionId ?? '');

    const options = { params: httpParams, responseType: 'text' as const };

    return lastValueFrom(this.http.get('/api/Lizenz/downloadlizenz', options));
  }
}
