import { AfterViewInit, Component } from '@angular/core';

import { AppInfoService } from '../../shared/services';

@Component({
  selector: 'fz-jahrgang-oeffnen-page',
  templateUrl: './jahrgang-oeffnen-page.component.html',
  styleUrls: ['./jahrgang-oeffnen-page.component.scss'],
})
export class JahrgangOeffnenPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Jahrgang öffnen'));
  }
}
