import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';
import { RPFormular } from '../formulare/rp-formular';

@Component({
  selector: 'fz-rp-versaeumnis',
  templateUrl: 'rp-versaeumnis.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPVersaeumnisComponent)],
})
export class RPVersaeumnisComponent extends RPPanelDecoratorBase {
  static createModel(formular: RPFormular) {
    return {
      fehltageEntschuldigtField: formular.fehltageEntschuldigt,
      fehltageUnentschuldigtField: formular.fehltageUnentschuldigt,
    };
  }

  @Input() model: ReturnType<typeof RPVersaeumnisComponent.createModel> | undefined;
}
