<ng-template fzTemplate="sidebar">
  <fz-he-anlage-typ></fz-he-anlage-typ>
</ng-template>
<fz-formular
  name="HE234hjKeineanlage"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
</fz-formular>
