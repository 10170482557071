<div
  [style.paddingTop.px]="paddingTopPx"
  [style.paddingBottom.px]="paddingBottomPx"
  [style.paddingLeft.px]="paddingLeftPx"
  [style.paddingRight.px]="paddingRightPx"
>
  <ng-content></ng-content>
</div>
<ng-container
  *ngFor="let blockModel of blockModels"
  fzBlock
  [content]="content"
  [backDeco]="backDeco"
  [frontDeco]="frontDeco"
>
  <ng-template #content="fzPortal" fzPortal>
    <fz-translate [fzPosition]="blockModel.contentPosition">
      <ng-container [fzPortalOutlet]="blockModel.block.content"></ng-container>
    </fz-translate>
    <fz-rect
      [style.pointer-events]="'none'"
      [width]="borderWidth"
      [style.borderColor]="borderColor"
      [topStyle]="isBorder(border, borderDirection.top) ? 'solid' : 'none'"
      [bottomStyle]="isBorder(border, borderDirection.bottom) ? 'solid' : 'none'"
      [leftStyle]="isBorder(border, borderDirection.left) ? 'solid' : 'none'"
      [rightStyle]="isBorder(border, borderDirection.right) ? 'solid' : 'none'"
      [style.width.px]="blockModel.rect.width"
      [style.height.px]="blockModel.rect.height"
    ></fz-rect>
  </ng-template>
  <ng-template #backDeco="fzPortal" fzPortal>
    <div [fzPosition]="blockModel.contentPosition">
      <ng-container [fzPortalOutlet]="blockModel.block.backDeco"></ng-container>
    </div>
  </ng-template>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div [fzPosition]="blockModel.contentPosition">
      <ng-container [fzPortalOutlet]="blockModel.block.frontDeco"></ng-container>
    </div>
  </ng-template>
</ng-container>
