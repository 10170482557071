import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-sav',
  templateUrl: 'he-sav.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, HESavComponent)],
})
export class HESavComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      lernArbeitsverhalten: {
        label: 'Arbeitsverhalten',
        noteField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.note,
      },
      sozialverhalten: {
        label: 'Sozialverhalten',
        noteField: formular.sozialLernArbeitsverhalten.sozialverhalten.note,
      },
    };
  }

  @Input() model: ReturnType<typeof HESavComponent.createModel> | undefined;
}
