import { FormularBereich } from '../formular-bereich';
import { FormularFach } from '../formular-fach';
import { PropertyFieldDescription, PropertyFieldRef } from './property-field';
import { RichTextField } from './rich-text-field';

export type VerbalDescription<T> = PropertyFieldDescription<T, string | null> & {
  bereich?: FormularBereich;
  kategorien?: string;
  eigeneTextbausteinKey?: string;
  linkedFaecher?: FormularFach[];
};
export type EigeneTextbausteinDescription = {
  label: string;
  labelShort: string;
  eigeneTextbausteinKey: string;
};
export class VerbalField<T, TDesc extends VerbalDescription<T> = VerbalDescription<T>> extends RichTextField<T, TDesc> {
  constructor(
    description: VerbalDescription<T>,
    public override readonly ref: PropertyFieldRef<T, string | null>
  ) {
    super(description, ref);
  }
  override get error(): string | null {
    if (this.description.required === false) return null;
    else return super.error;
  }
  get kategorien(): string {
    const kats: (string | undefined)[] = [];
    if (this.description.bereich != null && this.description.bereich.key == null) {
      kats.push(...this.description.bereich.fach.bereiche.map((b) => b.description.kategorien));
      for (const fach of this.description.bereich.fach.description.linkedFaecher ?? []) {
        kats.push(...fach.bereiche.map((b) => b.description.kategorien));
      }
    } else {
      kats.push(this.description.kategorien);
    }
    for (const fach of this.description.linkedFaecher ?? []) {
      kats.push(...fach.bereiche.map((b) => b.description.kategorien));
    }
    return kats.filter((k) => k != null).join('\r\n');
  }
  get eigeneTextbausteinDescriptions(): EigeneTextbausteinDescription[] {
    const keys: ({ label: string; eigeneTextbausteinKey?: string } | undefined)[] = [];
    if (this.description.bereich != null) {
      if (this.description.bereich.bereich.key == null) {
        keys.push(this.description.bereich.text.description);
        keys.push(
          ...this.description.bereich.fach.bereiche.filter((b) => b.key != null).map((b) => b.text.description)
        );
        for (const fach of this.description.bereich.fach.description.linkedFaecher ?? []) {
          keys.push(...fach.bereiche.map((b) => b.text.description));
        }
      } else {
        keys.push(this.description.bereich.text.description);
      }
    } else {
      keys.push(this.description);
    }
    for (const fach of this.description.linkedFaecher ?? []) {
      keys.push(...fach.bereiche.map((b) => b.text.description));
    }

    return keys.filter((k) => k?.eigeneTextbausteinKey != null) as EigeneTextbausteinDescription[];
  }
}
