<fz-panel *ngIf="model != null">
  <fz-select
    placeholder="Entwicklungsgespräch"
    emptyLabel="(Die Erziehungsberechtigten sind der Einladung gefolgt.)"
    [emptyValue]="'gefolgt'"
    class="Schuelername"
    [field]="model.selectField"
  >
    <fz-panel
      fzSelectCandidate
      [fzValue]="'eine'"
      fzLabel="Die Erziehungsberechtigten des Kindes sind trotz Einladung nicht zum Entwicklungsgespräch erschienen."
    >
      <fz-label>Die Erziehungsberechtigten des Kindes sind trotz Einladung am </fz-label
      ><fz-text placeholder="Datum" [field]="model.datumField"></fz-text
      ><fz-label> nicht zum Entwicklungsgespräch erschienen.</fz-label>
    </fz-panel>
    <fz-panel
      fzSelectCandidate
      [fzValue]="'mehrere'"
      fzLabel="Die Erziehungsberechtigten des Kindes sind trotz mehrmaliger Einladung nicht zum Entwicklungsgespräch erschienen."
    >
      <fz-label>Die Erziehungsberechtigten des Kindes sind trotz mehrmaliger Einladung am </fz-label
      ><fz-text placeholder="Datum" [field]="model.datumField"></fz-text
      ><fz-label> nicht zum Entwicklungsgespräch erschienen.</fz-label>
    </fz-panel>
  </fz-select>
</fz-panel>
