import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextbausteinEditorComponent } from './textbaustein-editor.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TextbausteinModule } from '../textbaustein/textbaustein.module';

@NgModule({
  declarations: [TextbausteinEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    DialogModule,
    TableModule,
    DropdownModule,
    ButtonModule,
    InputTextModule,
    TextbausteinModule,
  ],
  exports: [TextbausteinEditorComponent],
})
export class TextbausteinEditorModule {}
