import { SprachenportfolioTyp } from '../../../models/enums/sprachenportfolio-typ';
import { PropertyField } from '../../../models/fields/property-field';
import { SLZeugnis } from '../../../models/sl-zeugnis';

export class SprachenportfolioField extends PropertyField<SLZeugnis, SprachenportfolioTyp | null> {
  constructor(private zeugnis: SLZeugnis) {
    super(
      {
        label: 'Sprachenportfolio',
        labelShort: 'SprPort',
        property: 'sprachenportfolio',
      },
      { object: zeugnis }
    );
  }
  get value(): SprachenportfolioTyp | null {
    if (this.zeugnis.zeugnissatz.zeugniskopflizenz.isBilingual) return this.zeugnis.sprachenportfolio;
    else if (this.zeugnis.sprachenportfolio === 'Leer') return 'Leer';
    else return 'FranzEng';
  }
  set value(value: SprachenportfolioTyp | null) {
    this.zeugnis.sprachenportfolio = value;
  }
  override get error(): string | null {
    if (this.zeugnis.zeugnissatz.zeugniskopflizenz.isBilingual && this.zeugnis.sprachenportfolio == null) {
      return 'Feld darf nicht leer sein.';
    }
    return null;
  }
}
