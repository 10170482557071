import { Component } from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';

@Component({
  selector: 'fz-sl-legende',
  templateUrl: 'sl-legende.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLLegendeComponent)],
})
export class SLLegendeComponent extends SLPanelDecoratorBase {
  override borderDirection = BorderDirection;
}
