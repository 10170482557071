<div class="flex flex-col gap-2 pt-5">
  <button pButton (click)="openFileSelectionDialog()" class="w-48" label="Datei wählen"></button>
  <div>Gewählte Datei: {{ uploader.files.length > 0 ? uploader.files[0].name : '' }}</div>
  <div>Dateigröße: {{ uploader.files.length > 0 ? uploader.files[0].size : '' }} byte</div>
  <!-- <div>Dateityp: {{ uploader.files[0]?.type }}</div>
  <div>letzte Änderung: {{ uploader.files[0]?.lastModified }}</div> -->

  <div class="fz-button-group pt-2">
    <button
      pButton
      (click)="next.emit(uploader.files[0])"
      class="w-40"
      icon="pi pi-caret-right"
      label="Weiter"
      [disabled]="uploader?.files?.length == 0"
    ></button>
  </div>

  <p-fileUpload
    #uploader
    mode="basic"
    [styleClass]="'fz-system-restore-uploader'"
    chooseIcon="pi pi-upload"
    [auto]="false"
    chooseLabel="Datei auswählen"
  ></p-fileUpload>
</div>
