import { Field, FieldDescription } from './field';
import { PropertyFieldDescription, PropertyFieldRef } from './property-field';

export type DropdownCandidateDescription<T> = {
  value: T;
  displayStringShort?: string;
  content?: string;
};

export class DropdownCandidate<T> {
  constructor(private description: DropdownCandidateDescription<T>) {}

  get value(): T {
    return this.description.value;
  }
  get content(): string {
    return (
      this.description.content ?? (typeof this.description.value === 'string' ? this.description.value.toString() : '')
    );
  }
  get displayString(): string {
    return this.description.displayStringShort != null && this.description.displayStringShort !== this.content
      ? this.content?.length > 0
        ? `${this.description.displayStringShort} | ${this.content}`
        : '<leer>'
      : this.content;
  }
  get displayStringShort(): string {
    return this.description.displayStringShort ?? '';
  }
}

export abstract class DropdownFieldBase<T, TDesc extends FieldDescription = FieldDescription> extends Field<TDesc> {
  abstract override value: T | null;
  abstract candidates: DropdownCandidate<T>[];

  constructor(description: TDesc) {
    super(description);
  }

  get displayString() {
    return this.candidates.find((c) => c.value === this.value)?.displayString ?? '';
  }
  get displayStringShort() {
    return this.candidates.find((c) => c.value === this.value)?.displayStringShort ?? '';
  }
  get content() {
    return this.candidates.find((c) => c.value === this.value)?.content ?? '';
  }
}
export type DropdownFieldDescription<TObject, TValue> = PropertyFieldDescription<TObject, TValue> & {
  candidates: DropdownCandidateDescription<TValue>[];
};

export class DropdownField<TObject, TValue> extends DropdownFieldBase<
  TValue,
  DropdownFieldDescription<TObject, TValue>
> {
  public candidates: DropdownCandidate<TValue>[];

  constructor(
    description: DropdownFieldDescription<TObject, TValue>,
    public readonly ref: PropertyFieldRef<TObject, TValue>
  ) {
    super(description);
    this.candidates = this.description.candidates.map((cd) => new DropdownCandidate(cd));
  }
  get key() {
    if (this.description.keyPrefix != null) {
      return `${this.description.keyPrefix}.${this.description.property as string}`;
    } else return this.description.property as string;
  }
  get value(): TValue {
    return this.ref.object[this.description.property];
  }
  set value(value: TValue) {
    this.ref.object[this.description.property] = value;
  }
}
