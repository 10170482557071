import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SLZeugniskopfComponent } from './projectables/sl-zeugniskopf.component';
import { SLZeugnissatzComponent } from './projectables/sl-zeugnissatz.component';
import { SLSchulbesuchsjahrComponent } from './projectables/sl-schulbesuchsjahr.component';
import { SLSchuelerComponent } from './projectables/sl-schueler.component';
import { SLVersaeumnisComponent } from './projectables/sl-versaeumnis.component';
import { SLSchulortComponent } from './projectables/sl-schulort.component';
import { SLUnterschriftComponent } from './projectables/sl-unterschrift.component';
import { SLKenntnisComponent } from './projectables/sl-kenntnis.component';
import { SLLeistungenComponent } from './projectables/sl-leistungen.component';
import { SLLegendeComponent } from './projectables/sl-legende.component';
import { SLNotensystemComponent } from './options/sl-notensystem.component';
import { SLPflichtunterrichtComponent } from './projectables/sl-pflichtunterricht.component';
import { SLSchuelerTypComponent } from './options/sl-schueler-typ.component';
import { SLVersetzungsvermerkComponent } from './projectables/sl-versetzungsvermerk.component';
import { SL12hProtokollFormularComponent } from './formulare/sl-12h-protokoll-formular.component';
import { SL124hMitteilungFormularComponent } from './formulare/sl-124h-mitteilung-formular.component';
import { SL12jVerbalFormularComponent } from './formulare/sl-12j-verbal-formular.component';
import { SL12hjNotenFormularComponent } from './formulare/sl-12hj-noten-formular.component';
import { SL234hjErlaeuterungenFormularComponent } from './formulare/sl-234hj-erlaeuterungen-formular.component';
import { SL34hjStandardFormularComponent } from './formulare/sl-34hj-standard-formular.component';
import { SL4hStandardFormularComponent } from './formulare/sl-4h-standard-formular.component';
import { SL1234hjFreitextFormularComponent } from './formulare/sl-1234hj-freitext-formular.component';
import { SL1234hjGeistigFormularComponent } from './formulare/sl-1234hj-geistig-formular.component';
import { FormularFactory } from '@modules/formular/formular-factory';
import { SLFormularsatz } from './sl-formularsatz';
import { FormularModule } from '@modules/formular/formular.module';
import { SLFolgeseiteComponent } from './projectables/sl-folgeseite.component';
import { SLSchuelerLabelComponent } from './projectables/sl-schueler-label.component';
import { SLSeitenfussComponent } from './projectables/sl-seitenfuss.component';
import { SLZeugnislevelComponent } from './options/sl-zeugnislevel.component';
import { SLZeugnissatzAnlageComponent } from './projectables/sl-zeugnissatz-anlage.component';
import { SLZeugnissatzErlaeuterungenComponent } from './projectables/sl-zeugnissatz-erlaeuterungen.component';
import { SLFormularTypComponent } from './options/sl-formular-typ.component';
import { SLSprachenportfolioComponent } from './projectables/sl-sprachenportfolio.component';
import { SL12jNullFormularComponent } from './formulare/sl-12j-null-formular.component';
import { SLZeugnissatzProtokollComponent } from './projectables/sl-zeugnissatz-protokoll.component';
import { SLGespraechssatzComponent } from './projectables/sl-gespraechssatz.component';
import { SLZeugnissatzMitteilungComponent } from './projectables/sl-zeugnissatz-mitteilung.component';
import { SLUnterschriftProtokollComponent } from './projectables/sl-unterschrift-protokoll.component';

@NgModule({
  declarations: [
    SLZeugniskopfComponent,
    SLZeugnissatzComponent,
    SLZeugnissatzAnlageComponent,
    SLZeugnissatzErlaeuterungenComponent,
    SLSchulbesuchsjahrComponent,
    SLSchuelerComponent,
    SLSchuelerLabelComponent,
    SLVersaeumnisComponent,
    SLSchulortComponent,
    SLUnterschriftComponent,
    SLUnterschriftProtokollComponent,
    SLKenntnisComponent,
    SLLeistungenComponent,
    SLNotensystemComponent,
    SLLegendeComponent,
    SLPflichtunterrichtComponent,
    SLSprachenportfolioComponent,
    SLSchuelerTypComponent,
    SLFormularTypComponent,
    SLZeugnislevelComponent,
    SLVersetzungsvermerkComponent,
    SLFolgeseiteComponent,
    SLSeitenfussComponent,
    SL12hProtokollFormularComponent,
    SL124hMitteilungFormularComponent,
    SL12jNullFormularComponent,
    SL12jVerbalFormularComponent,
    SL12hjNotenFormularComponent,
    SL234hjErlaeuterungenFormularComponent,
    SL34hjStandardFormularComponent,
    SL4hStandardFormularComponent,
    SL1234hjFreitextFormularComponent,
    SL1234hjGeistigFormularComponent,
    SLZeugnissatzProtokollComponent,
    SLGespraechssatzComponent,
    SLZeugnissatzMitteilungComponent,
  ],
  imports: [BrowserModule, FormsModule, ButtonModule, MenuModule, CheckboxModule, RadioButtonModule, FormularModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class SLModule {}

FormularFactory.registerFormularsatz(new SLFormularsatz());
