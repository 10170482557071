import { Component, Input } from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-schueler',
  templateUrl: 'sl-schueler.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, SLSchuelerComponent)],
})
export class SLSchuelerComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      schuelerNameField: formular.schuelerName,
      schuelerNameLabel: 'Name',
      schuelerVornameField: formular.schuelerVorname,
      schuelerVornameLabel: 'Vorname',
      schuelerGeburtsdatumField: formular.schueler.geburtsdatum,
      schuelerGeburtsdatumLabel: 'Geburtsdatum',
      klassenbezeichnungField: formular.zeugnissatz.klassenbezeichnung,
      klassenbezeichnungLabel: 'Klassenbezeichnung',
      geschlechtField: formular.schueler.geschlecht,
    };
  }

  @Input() model: ReturnType<typeof SLSchuelerComponent.createModel> | undefined;

  @Input() isFuerHidden = false;
  override borderDirection = BorderDirection;
}
