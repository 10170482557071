import { Component } from '@angular/core';
import { RPFormular } from '../formulare/rp-formular';
import { OptionsBase } from '../../formular/options-base';

@Component({
  selector: 'fz-rp-herkunftssprache',
  templateUrl: 'rp-herkunftssprache.component.html',
})
export class RPHerkunftsspracheComponent extends OptionsBase<RPFormular> {
  get isHerkunftssprache(): boolean {
    return this.formular.herkunftssprache.gesamt.isVisibleOverrideDefaultFalse;
  }
  set isHerkunftssprache(value: boolean) {
    this.formular.herkunftssprache.gesamt.isVisibleOverrideDefaultFalse = value;
    if (!value) this.isHerkunftssprache2 = false;
  }
  get isHerkunftssprache2(): boolean {
    return this.formular.herkunftssprache2.gesamt.isVisibleOverrideDefaultFalse;
  }
  set isHerkunftssprache2(value: boolean) {
    this.formular.herkunftssprache2.gesamt.isVisibleOverrideDefaultFalse = value;
  }
}
