import {
  Component,
  OnInit,
  AfterContentInit,
  ChangeDetectorRef,
  ViewEncapsulation,
  QueryList,
  ContentChildren,
  OnDestroy,
  Input,
  ViewChildren,
  Type,
} from '@angular/core';
import { FormularService } from './formular.service';
import { Page, PageItem } from './formular-viewer.component';
import { Formular } from './formular';
import { BlockFactory, BlockFactoryOwnerProvider, BlockInstance, BlockPosition } from '@modules/blocks/block-factory';
import { InterfaceProvider } from '@modules/shared/interface-provider';
import { HeaderFooter, HeaderFooterDirective } from './header-footer.directive';
import { FormularItemDirective } from './formular-item.directive';
import { UserLayoutFormular } from '../../models/user-layout';
import { Zeugnis } from '../../models/zeugnis';
import { Quantity } from '@modules/dom/quantity';

export type FormularItemInstance = {
  item: FormularItemDirective;
  blockFactory: BlockFactory;
  blockCount: number;
  bottomPosition: BlockPosition;
  bottomWithMarginPosition: BlockPosition;
  blockInstances: BlockInstance[];
  bottomAligned: boolean;
  bottomAlignedOffset: number;
  height: number;
  name: string;
};
export type FormularInstance = {
  pageCount: number;
  formularItemInstances: FormularItemInstance[];
};

export interface FormularOwner {
  formulars: FormularComponent[];
  register(formular: FormularComponent): void;
  unregister(formular: FormularComponent): void;
}

export class FormularOwnerProvider implements InterfaceProvider<FormularOwner> {
  constructor(public provided: FormularOwner) {}
}

export interface FormularItem {
  project(): void;
  measure(position: BlockPosition): FormularItemInstance;
}

export class FormularItemProvider implements InterfaceProvider<FormularItem> {
  constructor(public provided: FormularItem) {}
}

@Component({
  selector: 'fz-formular',
  templateUrl: './formular.component.html',
  styleUrls: ['./formular.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class FormularComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() name: string = '';
  @Input() pageMarginTop = 57;
  @Input() pageMarginTopFirst: number | null = null;
  @Input() pageMarginBottom = 57;
  @Input() pageMarginBottomLast: number | null = null;
  @Input() pageMarginLeft = 57;
  @Input() pageMarginRight = 57;
  @Input() pageHeader?: Type<unknown>;
  @Input() pageFooter?: Type<unknown>;
  @Input() lastPageFooter?: Type<unknown>;

  @ContentChildren(FormularItemProvider) items = new QueryList<FormularItemProvider>();
  @ViewChildren('header', { read: HeaderFooterDirective }) headerFooters = new QueryList<HeaderFooterDirective>();
  pageHeight = 1123; // 297mm
  pageWidth = 794; // 210mm
  pages: Page[] = [];
  pageItems: PageItem[] = [];
  headerFooterPageCount = 0;
  headerFooterModels: HeaderFooter[] = [];
  pageHeaderItems: PageItem[] = [];
  formularItemInstances: FormularItemInstance[] = [];

  get formular(): Formular {
    return this.formularService.formular;
  }

  get userLayout(): UserLayoutFormular {
    return this.formular.zeugnis.layouts.getItem(this.name);
  }

  get userPageMarginTopM(): number {
    return (
      this.userLayout.properties.followingPageMarginTopM ??
      this.userLayout.properties.pageMarginTopM ??
      Math.round(Quantity.px2Mm(this.pageMarginTop))
    );
  }
  set userPageMarginTopM(value: number) {
    if (this.userPageMarginTopM !== value) this.userLayout.properties.followingPageMarginTopM = value;
  }
  get userPageMarginTop(): number {
    return Math.round(Quantity.mm2Px(this.userPageMarginTopM));
  }
  set userPageMarginTop(value: number) {
    this.userPageMarginTopM = Math.round(Quantity.px2Mm(value));
  }

  get userPageMarginTopFirstM(): number {
    return (
      this.userLayout.properties.pageMarginTopM ??
      Math.round(Quantity.px2Mm(this.pageMarginTopFirst ?? this.pageMarginTop))
    );
  }
  set userPageMarginTopFirstM(value: number) {
    if (this.userPageMarginTopFirstM !== value) this.userLayout.properties.pageMarginTopM = value;
  }
  get userPageMarginTopFirst(): number {
    return Math.round(Quantity.mm2Px(this.userPageMarginTopFirstM));
  }
  set userPageMarginTopFirst(value: number) {
    this.userPageMarginTopFirstM = Math.round(Quantity.px2Mm(value));
  }

  get userPageMarginBottomM(): number {
    return this.userLayout.properties.pageMarginBottomM ?? Math.round(Quantity.px2Mm(this.pageMarginBottom));
  }
  set userPageMarginBottomM(value: number) {
    if (this.userPageMarginBottomM !== value) this.userLayout.properties.pageMarginBottomM = value;
  }
  get userPageMarginBottom(): number {
    return Math.round(Quantity.mm2Px(this.userPageMarginBottomM));
  }
  set userPageMarginBottom(value: number) {
    this.userPageMarginBottomM = Math.round(Quantity.px2Mm(value));
  }

  get userPageMarginBottomLastM(): number {
    return (
      this.userLayout.properties.followingPageMarginBottomM ??
      this.userLayout.properties.pageMarginBottomM ??
      Math.round(Quantity.px2Mm(this.pageMarginBottomLast ?? this.pageMarginBottom))
    );
  }
  set userPageMarginBottomLastM(value: number) {
    if (this.userPageMarginBottomLastM !== value) this.userLayout.properties.followingPageMarginBottomM = value;
  }
  get userPageMarginBottomLast(): number {
    return Math.round(Quantity.mm2Px(this.userPageMarginBottomLastM));
  }
  set userPageMarginBottomLast(value: number) {
    this.userPageMarginBottomLastM = Math.round(Quantity.px2Mm(value));
  }

  get usablePageWidth(): number {
    return this.pageWidth - this.pageMarginLeft - this.pageMarginRight;
  }
  get pageCount(): number {
    if (this.pageItems.length === 0) return 0;
    return this.pageItems[this.pageItems.length - 1].pageIndex + 1;
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    public formularService: FormularService<Formular>,
    private formularOwnerProvider: FormularOwnerProvider,
    private blockFactoryOwnerProvider: BlockFactoryOwnerProvider
  ) {}

  ngOnInit(): void {
    this.formularOwnerProvider.provided.register(this);
  }

  ngOnDestroy(): void {
    this.formularOwnerProvider.provided.unregister(this);
  }

  ngAfterContentInit(): void {
    this.changeDetector.detectChanges();
    this.items.changes.subscribe(() => {
      this.blockFactoryOwnerProvider.provided.invalidate();
    });
  }
  resetPageMargins(): void {
    this.userLayout.properties.pageMarginTopM = null;
    this.userLayout.properties.followingPageMarginTopM = null;
    this.userLayout.properties.pageMarginBottomM = null;
    this.userLayout.properties.followingPageMarginBottomM = null;
  }

  applyLayoutToAll(zeugnisse: Zeugnis[]): void {
    for (const zeugnis of zeugnisse) {
      const targetUserLayout = UserLayoutFormular.fromDto(UserLayoutFormular.toDto(this.userLayout), zeugnis);
      zeugnis.layouts.setItem(targetUserLayout);
    }
  }

  getPageMarginTop(pageIndex: number): number {
    return pageIndex === 0 ? this.userPageMarginTopFirst : this.userPageMarginTop;
  }
  getPageMarginBottom(pageIndex: number): number {
    return pageIndex > 0 ? this.userPageMarginBottomLast : this.userPageMarginBottom;
  }
  getPageHeight(pageIndex: number): number {
    return this.pageHeight - this.getPageMarginTop(pageIndex) - this.getPageMarginBottom(pageIndex);
  }

  project(): void {
    for (const item of this.items) {
      item.provided.project();
    }
    for (const headerFooter of this.headerFooters) {
      headerFooter.project();
    }
  }

  updatePages(): void {
    // eslint-disable-next-line no-console
    console.log('------------------------------------------------------------------');
    // eslint-disable-next-line no-console
    console.log('Formular layout');
    const instance = this.measure();
    // eslint-disable-next-line no-console
    console.log(`Height (sum): ${instance.formularItemInstances.reduce((prev, curr) => prev + curr.height, 0)}`);
    for (const formularItemInstance of instance.formularItemInstances) {
      // eslint-disable-next-line no-console
      console.log(`Height (${formularItemInstance.name}): ${formularItemInstance.height}`);
    }
    this.moveBottomAligned(instance);
    this.formularItemInstances = instance.formularItemInstances;
    this.pageItems = instance.formularItemInstances
      .map((bfi) => {
        const blocks = bfi.blockFactory.layout(bfi.blockInstances.map((bi) => bi.range));
        return blocks.map((block, index) => {
          const blockInstance = bfi.blockInstances[index];
          return {
            pageIndex: blockInstance.position.pageIndex,
            position: new DOMRect(this.pageMarginLeft, blockInstance.position.y + bfi.bottomAlignedOffset),
            block,
          };
        });
      })
      .reduce((prev, curr) => [...prev, ...curr], []);
    this.updatePageHeaders();
    this.mergePageItems();
    // eslint-disable-next-line no-console
    console.log('------------------------------------------------------------------');
  }

  private updatePageHeaders(): void {
    if (this.headerFooterPageCount !== this.pageCount) {
      this.headerFooterModels.splice(0);
      for (let pageIndex = 0; pageIndex < this.pageCount; pageIndex++) {
        if (pageIndex > 0 && this.pageHeader != null) {
          this.headerFooterModels.push({
            pageIndex,
            type: 'header',
            component: this.pageHeader,
          });
        }
        if (pageIndex < this.pageCount - 1 && this.pageFooter != null) {
          this.headerFooterModels.push({
            pageIndex,
            type: 'footer',
            component: this.pageFooter,
          });
        }
        if (pageIndex === this.pageCount - 1 && this.lastPageFooter != null) {
          this.headerFooterModels.push({
            pageIndex,
            type: 'footer',
            component: this.lastPageFooter,
          });
        }
      }
    }
    this.headerFooterPageCount = this.pageCount;
    this.changeDetector.detectChanges();
    this.pageHeaderItems = this.headerFooters.map((header) => {
      const pageIndex = header.headerFooter?.pageIndex ?? 0;
      const position =
        header.headerFooter?.type === 'header'
          ? new DOMRect(
              this.pageMarginLeft,
              this.getPageMarginTop(pageIndex ?? 0) - 1 - (header.projectable?.height ?? 0)
            )
          : new DOMRect(this.pageMarginLeft, this.getPageMarginTop(pageIndex) + this.getPageHeight(pageIndex) + 1);
      return {
        pageIndex,
        position,
        block: {
          content: header.projectable?.content,
          backDeco: header.projectable?.backDeco,
          frontDeco: header.projectable?.frontDeco,
        },
      };
    });
  }

  private mergePageItems(): void {
    this.pages = this.pageItems.reduce((pages: Page[], pageItem: PageItem) => {
      const pageIndex = pageItem.pageIndex;
      pages[pageIndex] = pages[pageIndex] ?? {
        items: [],
        index: pageIndex,
        formularItemInstances: [],
        formular: this,
        isLast: false,
      };
      pages[pageIndex].items.push(pageItem);
      return pages;
    }, []);
    if (this.pages.length > 0) {
      this.pages[this.pages.length - 1].isLast = true;
    }
    for (const item of this.pageHeaderItems) {
      this.pages[item.pageIndex].items.push(item);
    }
    for (const instance of this.formularItemInstances.slice(0, this.formularItemInstances.length - 1)) {
      this.pages[instance.bottomPosition.pageIndex].formularItemInstances.push(instance);
    }
  }

  private measure(): FormularInstance {
    return this.measureProperties();
  }

  private measureProperties(): FormularInstance {
    let position: BlockPosition = {
      pageIndex: 0,
      y: this.getPageMarginTop(0),
    };
    const instances: FormularItemInstance[] = [];
    for (const item of this.items.map((i) => i.provided)) {
      const instance = item.measure(position);
      position = instance.bottomWithMarginPosition;
      instances.push(instance);
    }
    return {
      formularItemInstances: instances,
      pageCount: position.pageIndex + 1,
    };
  }

  private getFreeSpaceAtBottom(instance: FormularInstance): number {
    if (
      instance.formularItemInstances.length > 0 &&
      instance.pageCount > 0 &&
      instance.formularItemInstances[instance.formularItemInstances.length - 1].bottomPosition.pageIndex ===
        instance.pageCount - 1
    ) {
      return (
        this.getPageMarginTop(instance.pageCount - 1) +
        this.getPageHeight(instance.pageCount - 1) -
        instance.formularItemInstances[instance.formularItemInstances.length - 1].bottomPosition.y
      );
    } else return 0;
  }

  private moveBottomAligned(instance: FormularInstance) {
    const freeSpace = this.getFreeSpaceAtBottom(instance);
    if (freeSpace > 0) {
      let bottomAligned = false;
      for (const itemInstance of instance.formularItemInstances) {
        if (!bottomAligned) {
          if (itemInstance.bottomWithMarginPosition.pageIndex === instance.pageCount - 1) {
            if (itemInstance.bottomAligned) bottomAligned = true;
          }
        } else {
          itemInstance.bottomAlignedOffset = freeSpace;
        }
      }
    }
  }

  positionHeader(): void {
    for (const page of this.pages) {
      for (const item of page.items) {
        if (this.pageHeaderItems.indexOf(item) >= 0) {
          const header = this.headerFooters.filter(
            (hf) =>
              hf.headerFooter?.pageIndex === item.pageIndex &&
              hf.headerFooter?.type === 'header' &&
              item.block.content === hf.projectable?.content
          )[0];
          if (header != null) {
            item.position = new DOMRect(
              this.pageMarginLeft,
              this.getPageMarginTop(item.pageIndex ?? 0) - 1 - (header.projectable?.height ?? 0)
            );
          }
        }
      }
    }
  }
}
