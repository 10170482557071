<fz-panel *ngIf="model != null">
  <div *ngIf="model.titelDropdownField != null" style="text-align: center" class="Zeugnistitel">
    <fz-dropdown [field]="model.titelDropdownField"></fz-dropdown>
  </div>
  <div *ngIf="model.titelString != null" style="text-align: center" class="Zeugnistitel">
    <fz-label>{{ model.titelString }}</fz-label>
  </div>
  <div *ngIf="model.untertitelString != null" style="text-align: center" class="Ueberschriften">
    <fz-label>{{ model.untertitelString }}</fz-label>
  </div>
  <div *ngIf="model.untertitelIntensivklasseField != null" style="text-align: center" class="Ueberschriften">
    <fz-label>Teilnahme an </fz-label
    ><fz-dropdown placeholder="Intensivklasse/Intensivkurs" [field]="model.untertitelIntensivklasseField"></fz-dropdown
    ><fz-label> an einer allgemein bildenden Schule</fz-label>
  </div>
</fz-panel>
