import { AppInfoService } from './../../shared/services/app-info.service';
import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'fz-jahrgang-neu-page',
  templateUrl: './jahrgang-neu-page.component.html',
  styleUrls: ['./jahrgang-neu-page.component.scss'],
})
export class JahrgangNeuPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Neuen Jahrgang erstellen'));
  }
}
