<fz-formular
  name="BW34hStandard"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  <fz-bw-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-bw-zeugniskopf>
  <fz-bw-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-bw-zeugnistyp>
  <fz-bw-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-bw-schueler>
  <fz-bw-leistungen fzFormularItem="leistungen" fzContainer [model]="model.leistungen"></fz-bw-leistungen>
  <fz-section fzFormularItem="schrift" [label]="model.schrift.label" headerPaddingBottom="normal">
    <fz-verbal [field]="model.schrift.textField"></fz-verbal>
  </fz-section>
  <fz-section fzFormularItem="bemerkungen" [label]="model.bemerkungen.label" headerPaddingBottom="normal">
    <fz-intelli [field]="model.bemerkungen.textField"></fz-intelli>
  </fz-section>
  <fz-bw-gespraech fzFormularItem="gespraech" fzContainer [model]="model.gespraech"></fz-bw-gespraech>
  <fz-bw-unterschrift-halbjahr
    fzFormularItem="unterschrift"
    fzContainer
    [model]="model.unterschrift"
  ></fz-bw-unterschrift-halbjahr>
</fz-formular>
