import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ClientException } from '../../../models/client-exception';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { NotificationService } from '../../services/notification/notification.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: FlinkyAuthService,
    private notificationService: NotificationService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.authService.getToken();
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && !authReq.url.includes('Auth/signin') && error.status === 401) {
          return this.handle401Error(authReq, next);
        }

        return throwError(() => error);
      })
    );
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   const token = this.authService.getToken();
  //   let headers: HttpHeaders;
  //   if (request.headers.get('ignoreContentType') !== 'true') {
  //     headers = request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token).append('Content-Type', 'application/json');
  //   } else {
  //     headers = request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token);
  //   }
  //   const requestClone = request.clone({
  //     headers,
  //   });

  //   if (request.headers.get('absolute') === 'true' || (!environment.production && request.url.startsWith('/assets'))) {
  //     return next.handle(request);
  //   } else {
  //     const baseUrl = document.getElementsByTagName('base')[0].href;
  //     const url = (baseUrl + requestClone.url).replace(/([^:]\/)\/+/g, '$1');
  //     const apiReq = requestClone.clone({ url: `${url}` });
  //     return next.handle(apiReq);
  //   }
  // }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refToken = this.authService.getRefreshToken();
      const token = this.authService.getToken();
      if (refToken && token) {
        return this.authService.refreshToken().pipe(
          switchMap((tokenRes: any) => {
            this.isRefreshing = false;
            this.authService.saveToken(tokenRes.jwtToken);
            this.refreshTokenSubject.next(tokenRes.jwtToken);

            return next.handle(this.addTokenHeader(request, tokenRes.jwtToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            // auto-logout nur bei 401 || 410
            if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 410)) {
              this.authService.logout(true);
              return throwError(
                () =>
                  new ClientException(
                    'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
                    'Sitzung abgelaufen'
                  )
              );
            } else {
              return throwError(() => err);
            }
          })
        );
      } else {
        this.isRefreshing = false;
        this.authService.logout(true);
        return throwError(
          () =>
            new ClientException('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.', 'Sitzung abgelaufen')
        );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${token}`
      ) /*.append('Content-Type', 'application/json') es gibt auch den Content-Type application/x-www-form-urlencoded -> individuell gelöst*/,
    });
  }
}
