import { Component } from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';

@Component({
  selector: 'fz-sl-schueler-label',
  templateUrl: 'sl-schueler-label.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, SLSchuelerLabelComponent)],
})
export class SLSchuelerLabelComponent extends SLPanelDecoratorBase {
  override borderDirection = BorderDirection;
}
