import { ZeugnissatzViewModel } from './../../../modules/formular/formular';
import { Component, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';

import { WorkspaceService } from '../../services/workspace.service';
import { Router } from '@angular/router';
import { PrimeIcons } from 'primeng/api';
import { Zeugnis } from '../../../models/zeugnis';
import { Geschlecht } from '../../../models/enums/geschlecht';
import { MainPageComponent } from '../../../pages/main-page/main-page.component';

export interface WerdegangTimelineEntry {
  date: Date;
  type: string;
  icon: PrimeIcons;
  color: string;
  schuljahr: string;
  klassenstufe: string;
  klassenbezeichnung: string;
  klassenleitung: string;
  zeugnisausgabedatum: string;
}
@Component({
  selector: 'fz-sl-schueler-akte',
  templateUrl: 'schueler-akte.component.html',
  styleUrls: ['./schueler-akte.component.scss'],
})
export class SchuelerAkteComponent implements OnChanges, AfterViewInit {
  @Input()
  zeugnis?: Zeugnis;
  geschlechter: { label: string | undefined; value: Geschlecht | undefined }[] = [
    { label: 'Männlich', value: 'm' },
    { label: 'Weiblich', value: 'w' },
    { label: 'Divers', value: 'divers' },
  ];
  today = new Date();
  yearRange = `${this.today.getFullYear() - 12}:${this.today.getFullYear() + 1}`;

  werdegang: WerdegangTimelineEntry[] = [];

  constructor(
    private workspaceService: WorkspaceService,
    private mainPage: MainPageComponent,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    // eslint-disable-next-line no-console
    console.log('akte initialisiert');
  }
  ngOnChanges(_changes: SimpleChanges): void {
    if (this.zeugnis?.schueler?.id) {
      const w: WerdegangTimelineEntry[] = [];
      this.zeugnis.schueler.zeugnisse.forEach((zeugnis: Zeugnis) => {
        const zsVm = new ZeugnissatzViewModel(zeugnis.zeugnissatz);
        // const zVm = FormularFactory.getFormularsatz(zeugnis).createFormular(zeugnis, 'zeugnis');
        w.push({
          date: new Date(zeugnis.zeugnissatz.schuljahr, zeugnis.zeugnissatz.halbjahr, 1),
          zeugnisausgabedatum: zeugnis.zeugnisausgabedatum ?? '',
          type: zeugnis.zeugnisTyp ?? zsVm.zeugnisTypLabel,
          icon: this.getIcon(zeugnis, zsVm),
          color: this.getColor(zeugnis, zsVm),
          schuljahr: zsVm.schuljahrLabel,
          klassenstufe: zsVm.klassenstufeLabel,
          klassenleitung: zeugnis.zeugnissatz.klassenleitung ?? '',
          klassenbezeichnung: zeugnis.zeugnissatz.klassenbezeichnung ?? '',
        });
        w.sort((a, b) => (a.date > b.date && -1) || 1);

        this.werdegang = w;
        this.werdegang = this.werdegang.slice(0);
      });
    }
  }

  getIcon(zeugnis: Zeugnis, zeugnissatzViewModel: ZeugnissatzViewModel): PrimeIcons {
    switch (zeugnis.zeugnisTyp ?? zeugnissatzViewModel.zeugnisTypLabel) {
      case 'Jahreszeugnis':
        return PrimeIcons.FILE;
      case 'Abgangszeugnis':
      case 'Abgang':
        return PrimeIcons.FILE;
      case 'Halbjahreszeugnis':
        return PrimeIcons.FILE;
    }
    return PrimeIcons.QUESTION_CIRCLE;
  }

  getColor(zeugnis: Zeugnis, zeugnissatzViewModel: ZeugnissatzViewModel): string {
    switch (zeugnis.zeugnisTyp ?? zeugnissatzViewModel.zeugnisTypLabel) {
      case 'Jahreszeugnis':
        return '#673AB7';
      case 'Abgangszeugnis':
      case 'Abgang':
        return '#607D8B';
      case 'Halbjahreszeugnis':
        return '#FF9800';
    }
    return '#000000';
  }
  nameChange(): void {
    if (this.zeugnis) {
      this.zeugnis.schuelerName = this.zeugnis.schueler.name;
    }
  }

  vornameChange(): void {
    if (this.zeugnis) {
      this.zeugnis.schuelerVorname = this.zeugnis.schueler.vorname;
    }
  }

  isEmpty(): boolean {
    return this.workspaceService.currentJahrgangId == null && this.mainPage.countAllJahrgaenge === 0;
  }

  hasNoZeugnissatz(): boolean {
    return this.workspaceService.jahrgang != null && this.workspaceService.currentZeugnissatz == null;
  }

  isNotLoaded(): boolean {
    return this.workspaceService.currentJahrgangId == null && this.mainPage.countAllJahrgaenge > 0;
  }

  restore(): void {
    this.router.navigate(['/restore-user-backup']);
  }

  import(): void {
    this.router.navigate(['/import-page']);
  }

  neuanlage(): void {
    this.router.navigate(['/jahrgang-neu']);
  }

  oeffnen(): void {
    this.router.navigate(['/jahrgang-oeffnen']);
  }

  wiederfuellen(): void {
    this.router.navigate(['/jahrgang-wiederfuellen']);
  }
}
