export class Quantity {
  public static px2Mm(px: number): number {
    return (px / 96.0) * 25.4;
  }

  public static mm2Px(mm: number): number {
    return (mm / 25.4) * 96.0;
  }

  public static px2Pt(px: number): number {
    return (px / 96.0) * 72.0;
  }

  public static pt2Px(pt: number): number {
    return (pt / 72.0) * 96.0;
  }

  public static mm2Pt(mm: number): number {
    return (mm / 25.4) * 72.0;
  }
}
