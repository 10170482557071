/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BWFormularCategoryKey } from '../bw-formularsatz';
import BWFachKey from '../../../models/generated/bw-fach-key';
import { BWSeitenkopfComponent } from '../projectables/bw-seitenkopf.component';
import { BWSeitenfussComponent } from '../projectables/bw-seitenfuss.component';
import { BWSeitenfussLastComponent } from '../projectables/bw-seitenfuss-last.component';
import BWBereichKey from '../../../models/generated/bw-bereich-key';
import { FormularFach } from '../../../models/formular-fach';
import { FormularBereich } from '../../../models/formular-bereich';
import { Formular } from '@modules/formular/formular';
import { ZeugnisTypField } from '../fields/bw-zeugnis-typ-field';
import { ReligionsunterrichtField } from '../fields/bw-religionsunterricht-field';
import { IntelliField } from '../../../models/fields/intelli-field';
import { FremdspracheBezeichnungField } from '../fields/bw-fremdsprache-bezeichnung-field';
import { ValueField } from '../../../models/fields/value-field';
import { BWZeugnis } from '../../../models/bw-zeugnis';

export class SozialLernArbeitsverhalten extends FormularFach {
  sozialverhalten = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.sozialverhalten, {
      label: 'Sozialverhalten',
      labelShort: 'V',
      isKopfnote: true,
      kategorien: '9001\r\n20\r\n21\r\n9000\r\n10\r\n11\r\n12\r\n1\r\n',
      eigeneTextbausteinKey: 'Verhalten',
    })
  );
  arbeitsverhalten = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.arbeitsverhalten, {
      label: 'Arbeiten',
      labelShort: 'A',
      isKopfnote: true,
      kategorien:
        '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9002\r\n9001\r\n2000\r\n1003\r\n1004\r\n1006\r\n1008\r\n1010\r\n1001\r\n1000\r\n1014\r\n1016\r\n1018\r\n1019\r\n1020\r\n1021\r\n1002\r\n2001\r\n1005\r\n1007\r\n1009\r\n1011\r\n1015\r\n1017\r\n60\r\n80\r\n90\r\n70',
      eigeneTextbausteinKey: 'Arbeiten',
    })
  );
  lernverhalten = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.lernverhalten, {
      label: 'Lernen',
      labelShort: 'L',
      kategorien:
        '9001\r\n22\r\n23\r\n24\r\n2000\r\n1003\r\n1004\r\n1006\r\n1008\r\n1010\r\n1001\r\n1000\r\n1014\r\n1016\r\n1018\r\n1019\r\n1020\r\n1021\r\n1002\r\n2001\r\n1005\r\n1007\r\n1009\r\n1011\r\n1015\r\n1017\r\n60\r\n80\r\n90\r\n70',
      eigeneTextbausteinKey: 'Lernen',
    })
  );
  lernArbeitsverhalten = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.lernArbeitsverhalten, {
      label: 'Allgemeine Beurteilung',
      labelShort: 'AB',
      kategorien:
        '9002\r\n31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n9000\r\n10\r\n11\r\n12\r\n1\r\n2000\r\n1003\r\n1004\r\n1006\r\n1008\r\n1010\r\n1001\r\n1000\r\n1014\r\n1016\r\n1018\r\n1019\r\n1020\r\n1021\r\n1002\r\n2001\r\n1005\r\n1007\r\n1009\r\n1011\r\n1015\r\n1017\r\n60\r\n80\r\n90\r\n70',
      eigeneTextbausteinKey: 'Allgemeine_Beurteilung',
    })
  );
  zusammenarbeit = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.zusammenarbeit, {
      label: 'Zusammenarbeit mit anderen',
      labelShort: 'Zus',
    })
  );
  eigenstaendigesArbeiten = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.eigenstaendigesArbeiten, {
      label: 'Eigenständiges Arbeiten',
      labelShort: 'Eig',
    })
  );
  sorgfalt = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.sorgfalt, {
      label: 'Zuverlässigkeit und Sorgfalt',
      labelShort: 'ZuS',
    })
  );
  constructor(formular: Formular) {
    super(formular, BWFachKey.sozialLernArbeitsverhalten, {
      label: 'Arbeits- und Sozialverhalten',
      labelShort: 'SLAV',
      isKopfnote: true,
    });
  }
}

class Deutsch extends FormularFach {
  sprechenZuhoeren = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.sprechenZuhoeren, {
      label: 'Sprechen und Zuhören',
      labelShort: 'Spr',
      eigeneTextbausteinKey: 'Deutsch_Sprechen_Zuhoeren',
    })
  );
  lesen = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.lesen, {
      label: 'Lesen',
      labelShort: 'Les',
      eigeneTextbausteinKey: 'Deutsch_Lesen',
    })
  );
  texteVerfassen = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.texteVerfassen, {
      label: 'Texte schreiben',
      labelShort: 'Schr',
    })
  );
  rechtschreiben = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.rechtschreiben, {
      label: 'Rechtschreiben / Richtig schreiben',
      labelShort: 'Recht',
    })
  );
  spracheUntersuchen = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.spracheUntersuchen, {
      label: 'Sprache und Sprachgebrauch untersuchen',
      labelShort: 'SU',
    })
  );
  constructor(formular: Formular) {
    super(formular, BWFachKey.deutsch, {
      label: 'Deutsch',
      labelShort: 'De',
      kategorien: '2000\r\n1004\r\n1006\r\n1008\r\n1001\r\n1010\r\n2001\r\n1005\r\n1007\r\n1009\r\n1011',
      eigeneTextbausteinKey: 'Deutsch_Beilage',
    });
  }
}

class Mathematik extends FormularFach {
  zahlen = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.zahlen, {
      label: 'Zahlen und Operationen',
      labelShort: 'ZuO',
    })
  );
  groessen = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.groessen, {
      label: 'Größen und Messen',
      labelShort: 'GuM',
    })
  );
  raum = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.raum, {
      label: 'Raum und Form',
      labelShort: 'RuF',
    })
  );
  daten = this.add(
    new FormularBereich(this.formular, this, BWBereichKey.daten, {
      label: 'Daten, Häufigkeit und Wahrscheinlichkeit',
      labelShort: 'DHW',
    })
  );
  constructor(formular: Formular) {
    super(formular, BWFachKey.mathematik, {
      label: 'Mathematik',
      labelShort: 'Ma',
      kategorien: '2000\r\n1000\r\n1014\r\n1016\r\n2001\r\n1015\r\n1017',
      eigeneTextbausteinKey: 'Mathe_Beilage',
    });
  }
}

export abstract class BWFormular extends Formular {
  zeugnisTyp: ZeugnisTypField;
  religionsunterricht = new ReligionsunterrichtField(this.zeugnis);
  sozialLernArbeitsverhalten: SozialLernArbeitsverhalten;
  deutsch: Deutsch;
  mathematik: Mathematik;
  sachunterricht: FormularFach;
  bildendeKunst: FormularFach;
  musik: FormularFach;
  sport: FormularFach;
  religion: FormularFach;
  fremdsprache: FormularFach;
  schrift: FormularFach;
  hinweise = new IntelliField<BWZeugnis>(
    { label: 'Hinweise', labelShort: 'HW', property: 'hinweise', textbausteinKey: 'Hinweise' },
    { object: this.zeugnis }
  );
  fremdspracheBezeichnung = new FremdspracheBezeichnungField(this.zeugnis);
  gespraechErwuenscht = new ValueField<BWZeugnis, boolean | null>(
    { label: 'Gespräch erwünscht', labelShort: 'GesprErw', property: 'gespraechErwuenscht', required: false },
    { object: this.zeugnis }
  );

  seitenkopf = BWSeitenkopfComponent;
  seitenfuss = BWSeitenfussComponent;
  seitenfussLast = BWSeitenfussLastComponent;

  constructor(public override zeugnis: BWZeugnis) {
    super(zeugnis);
    this.zeugnisTyp = new ZeugnisTypField(zeugnis);
    this.sozialLernArbeitsverhalten = new SozialLernArbeitsverhalten(this);
    this.deutsch = new Deutsch(this);
    this.mathematik = new Mathematik(this);
    this.fremdsprache = new FormularFach(this, BWFachKey.fremdsprache, {
      label: 'Fremdsprache',
      labelShort: 'Fr',
      eigeneTextbausteinKey: 'Fremdsprache_Beilage',
    });
    this.sachunterricht = new FormularFach(this, BWFachKey.sachunterricht, {
      label: 'Sachunterricht',
      labelShort: 'Sa',
      kategorien: '2000\r\n1018\r\n2001\r\n60',
      eigeneTextbausteinKey: 'Sachunterricht_Beilage',
    });
    this.musik = new FormularFach(this, BWFachKey.musik, {
      label: 'Musik',
      labelShort: 'Mu',
      kategorien: '2000\r\n1019\r\n2001\r\n80',
      eigeneTextbausteinKey: 'Musik_Beilage',
    });
    this.bildendeKunst = new FormularFach(this, BWFachKey.bildendeKunst, {
      label: 'Kunst',
      labelShort: 'BK',
      kategorien: '2000\r\n1021\r\n2001\r\n70',
      eigeneTextbausteinKey: 'Kunst_Beilage',
    });
    this.sport = new FormularFach(this, BWFachKey.sport, {
      label: 'Bewegung, Spiel und Sport',
      labelShort: 'Sp',
      kategorien: '2000\r\n1020\r\n2001\r\n90',
      eigeneTextbausteinKey: 'Sport_Beilage',
    });
    this.religion = new FormularFach(this, BWFachKey.religion, {
      label: 'Religionslehre',
      labelShort: 'Re',
      kategorien: '2000\r\n1003\r\n2001\r\n100',
      eigeneTextbausteinKey: 'Religion_Beilage',
    });
    this.schrift = new FormularFach(this, BWFachKey.schrift, {
      label: 'Schrift und Gestaltung',
      labelShort: 'SuG',
      kategorien: '2000\r\n1002',
      eigeneTextbausteinKey: 'Schrift_Gestaltung_Text',
    });
  }

  get isAbschluss(): boolean {
    return (
      this.zeugnis.zeugnissatz.klassenstufe === 4 &&
      this.zeugnis.zeugnissatz.halbjahr === 1 &&
      this.zeugnis.zeugnisTyp == null
    );
  }

  abstract get category(): BWFormularCategoryKey;
  abstract get faecher(): FormularFach[];

  get beilageTitel(): string {
    const zu =
      this.zeugnis.zeugnissatz.klassenstufe < 3
        ? 'zum Schulbericht der Grundschule'
        : this.zeugnis.zeugnissatz.halbjahr === 0
          ? 'zur Halbjahresinformation der Grundschule'
          : 'zum Zeugnis der Grundschule';
    return `Beilage ${zu} / Grundlage für ein dokumentiertes Lernentwicklungsgespräch: Lernentwicklung und erreichte Kompetenzen`;
  }
}
