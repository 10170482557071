/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Formular, SozialLernArbeitsverhalten } from '@modules/formular/formular';
import { Bereich } from '../../../models/bereich';
import { GespraechsatzTyp } from '../../../models/enums/gespraechsatz-typ';
import { DateField } from '../../../models/fields/date-field';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { NumberField } from '../../../models/fields/number-field';
import { TextField } from '../../../models/fields/text-field';
import { ValueField } from '../../../models/fields/value-field';
import { BereichDescription, FormularBereich } from '../../../models/formular-bereich';
import { FachDescription, FormularFach } from '../../../models/formular-fach';
import RPBereichKey from '../../../models/generated/rp-bereich-key';
import RPFachKey from '../../../models/generated/rp-fach-key';
import { RPZeugnis } from '../../../models/rp-zeugnis';
import { Zeugnis } from '../../../models/zeugnis';
import { AbschlusszeugnisTypField } from '../fields/rp-abschlusszeugnis-typ-field';
import { AnlageTypField } from '../fields/rp-anlage-typ-field';
import { SprachenportfolioField } from '../fields/rp-sprachenportfolio-field';
import { SprachenportfolioSpracheField } from '../fields/rp-sprachenportfolio-sprache-field';
import { SpsTypField } from '../fields/rp-sps-typ-field';
import { RPFormularCategoryKey } from '../rp-formularsatz';

class Deutsch extends FormularFach {
  sprechenZuhoeren: FormularBereich;
  lesen: FormularBereich;
  texteVerfassen: FormularBereich;
  rechtschreiben: FormularBereich;
  spracheUntersuchen: FormularBereich;
  constructor(
    formular: Formular,
    gesamt: FachDescription,
    lesen: BereichDescription,
    sprechenZuhoeren: BereichDescription,
    texteVerfassen: BereichDescription,
    rechtschreiben: BereichDescription,
    spracheUntersuchen: BereichDescription
  ) {
    super(formular, RPFachKey.deutsch, gesamt);
    this.sprechenZuhoeren = this.add(
      new FormularBereich(formular, this, RPBereichKey.sprechenZuhoeren, sprechenZuhoeren)
    );
    this.lesen = this.add(new FormularBereich(formular, this, RPBereichKey.lesen, lesen));
    this.texteVerfassen = this.add(new FormularBereich(formular, this, RPBereichKey.texteVerfassen, texteVerfassen));
    this.rechtschreiben = this.add(new FormularBereich(formular, this, RPBereichKey.rechtschreiben, rechtschreiben));
    this.spracheUntersuchen = this.add(
      new FormularBereich(formular, this, RPBereichKey.spracheUntersuchen, spracheUntersuchen)
    );
  }
}

class Mathematik extends FormularFach {
  geometrie: FormularBereich;
  zahlenRechnen: FormularBereich;
  sachrechnenGroessen: FormularBereich;
  constructor(
    formular: Formular,
    gesamt: FachDescription,
    geometrie: BereichDescription,
    zahlenRechnen: BereichDescription,
    sachrechnenGroessen: BereichDescription
  ) {
    super(formular, RPFachKey.mathematik, gesamt);
    this.geometrie = this.add(new FormularBereich(formular, this, RPBereichKey.geometrie, geometrie));
    this.zahlenRechnen = this.add(new FormularBereich(formular, this, RPBereichKey.zahlenRechnen, zahlenRechnen));
    this.sachrechnenGroessen = this.add(
      new FormularBereich(formular, this, RPBereichKey.sachrechnenGroessen, sachrechnenGroessen)
    );
  }
}

export abstract class RPFormular extends Formular {
  sozialLernArbeitsverhalten: SozialLernArbeitsverhalten;
  religion: FormularFach;
  deutsch: Deutsch;
  deutschSachunterricht: FormularFach;
  sachunterricht: FormularFach;
  mathematik: Mathematik;
  bildendeKunst: FormularFach;
  musik: FormularFach;
  sport: FormularFach;
  musikSportBildendeKunst: FormularFach;
  schrift: FormularFach;
  herkunftssprache: FormularFach;
  herkunftssprache2: FormularFach;
  fehltageEntschuldigt: NumberField<Zeugnis>;
  fehltageUnentschuldigt: NumberField<Zeugnis>;
  fehlstundenEntschuldigt: NumberField<Zeugnis>;
  fehlstundenUnentschuldigt: NumberField<Zeugnis>;
  sprachenportfolio: SprachenportfolioField;
  sprachenportfolioSprache: SprachenportfolioSpracheField;
  anlageTyp: AnlageTypField;
  spsTyp: SpsTypField;
  spsText: TextField<RPZeugnis>;
  religionLabel: DropdownField<Bereich, string | null>;
  abschlusszeugnisTyp: AbschlusszeugnisTypField;
  gespraechsatzTyp: ValueField<RPZeugnis, GespraechsatzTyp | null>;
  protokolldatum: DateField<RPZeugnis>;

  constructor(public override zeugnis: RPZeugnis) {
    super(zeugnis);
    this.fehltageEntschuldigt = new NumberField(
      {
        label: 'Fehltage entschuldigt',
        labelShort: 'entTg',
        property: 'fehltageEntschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.fehltageUnentschuldigt = new NumberField(
      {
        label: 'Fehltage unentschuldigt',
        labelShort: 'unentTg',
        property: 'fehltageUnentschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.fehlstundenEntschuldigt = new NumberField(
      {
        label: 'Fehlstunden entschuldigt',
        labelShort: 'entStd',
        property: 'fehlstundenEntschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.fehlstundenUnentschuldigt = new NumberField(
      {
        label: 'Fehlstunden unentschuldigt',
        labelShort: 'unentStd',
        property: 'fehlstundenUnentschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.sozialLernArbeitsverhalten = new SozialLernArbeitsverhalten(
      this,
      {
        label: 'Sozial-, Lern- und Arbeitsverhalten',
        labelShort: 'SLAV',
        isKopfnote: true,
      },
      {
        label: 'Sozialverhalten',
        labelShort: 'SV',
        isKopfnote: true,
        kategorien: '10\r\n11\r\n12\r\n1\r\n9000\r\n',
        eigeneTextbausteinKey: 'Sozialverhalten',
      },
      {
        label: 'Lern- und Arbeitsverhalten',
        labelShort: 'LAV',
        isKopfnote: true,
        kategorien: '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002',
        eigeneTextbausteinKey: 'LernArbeitsverhalten',
      }
    );
    this.religion = new FormularFach(this, RPFachKey.religion, {
      labelKey: 'religion.gesamt.label',
      label: 'Religion',
      labelShort: 'Re',
      kategorien: '2000\r\n1003',
      eigeneTextbausteinKey: 'Religion_Ethik',
      teilnahmeCandidates: [
        { value: null, label: 'Keine Einschränkung' },
        { value: RPBereichKey.katholisch, label: 'Teilnahme am RU katholisch' },
        { value: RPBereichKey.evangelisch, label: 'Teilnahme am RU evangelisch' },
        { value: RPBereichKey.ethik, label: 'Teilnahme am Ethikunterricht' },
        { value: RPBereichKey.sonst, label: 'Teilnahme an sonstigem RU' },
      ],
      orderedKPBereichKeys: [RPBereichKey.katholisch, RPBereichKey.evangelisch, RPBereichKey.ethik, RPBereichKey.sonst],
    });
    this.religionLabel = new DropdownField(
      {
        label: 'Religion/Ethik',
        labelShort: 'R/E',
        required: false,
        property: 'label',
        keyPrefix: 'religion.gesamt',
        candidates: [
          { value: null, displayStringShort: 'R/E', content: 'Religion/Ethik' },
          { value: 'Religion', displayStringShort: 'R' },
          { value: 'Ethik', displayStringShort: 'E' },
          { value: 'Katholische Religion', displayStringShort: 'rk' },
          { value: 'Evangelische Religion', displayStringShort: 'ev' },
          { value: 'Alevitische Religion', displayStringShort: 'alev' },
          { value: 'Islamische Religion', displayStringShort: 'Isl' },
          { value: 'Mennonitische Religion', displayStringShort: 'Menn' },
        ],
      },
      { object: this.religion.gesamt.bereich }
    );
    this.deutsch = new Deutsch(
      this,
      {
        label: 'Deutsch',
        labelShort: 'De',
        subs: [
          'Sprechen und Zuhören / Lesen, Umgang mit Texten und Medien /',
          'Texte verfassen / Richtig schreiben / Sprache untersuchen',
        ],
        kategorien: '2000\r\n1004\r\n1006\r\n1008\r\n1001\r\n1010\r\n2001\r\n1005\r\n1007\r\n1009\r\n1011',
        orderedKPBereichKeys: [
          RPBereichKey.sprechenZuhoeren,
          RPBereichKey.lesen,
          RPBereichKey.texteVerfassen,
          RPBereichKey.rechtschreiben,
          RPBereichKey.spracheUntersuchen,
        ],
      },
      {
        label: 'Lesen, Umgang mit Texten und Medien',
        labelShort: 'Les',
        eigeneTextbausteinKey: 'Deutsch_Lesen_UmgangTxtMedien',
      },
      {
        label: 'Sprechen und Zuhören',
        labelShort: 'Spr',
        eigeneTextbausteinKey: 'Deutsch_Sprechen_Zuhoeren',
      },
      {
        label: 'Texte verfassen',
        labelShort: 'Texte',
        eigeneTextbausteinKey: 'Deusch_Texte_verfassen',
      },
      {
        label: 'Richtig schreiben',
        labelShort: 'Recht',
        eigeneTextbausteinKey: 'Deutsch_Richtig_schreiben',
      },
      {
        label: 'Sprache untersuchen',
        labelShort: 'SU',
        eigeneTextbausteinKey: 'Deutsch_Sprache_untersuchen',
      }
    );
    this.sachunterricht = new FormularFach(this, RPFachKey.sachunterricht, {
      label: 'Sachunterricht',
      labelShort: 'Sa',
      kategorien: '2000\r\n1018\r\n2001\r\n60',
      eigeneTextbausteinKey: 'Sachunterricht',
    });
    this.deutschSachunterricht = new FormularFach(this, RPFachKey.deutschSachunterricht, {
      label: 'Deutsch/Sachunterricht',
      labelShort: 'DeSa',
      eigeneTextbausteinKey: 'Deutsch_Sachunterricht',
      linkedFaecher: [this.deutsch, this.sachunterricht],
    });
    this.mathematik = new Mathematik(
      this,
      {
        label: 'Mathematik',
        labelShort: 'Ma',
        subs: ['Geometrie / Zahlen und Rechnen / Sachrechnen und Größen'],
        kategorien: '2000\r\n1000\r\n1014\r\n2001\r\n1015\r\n1017\r\n',
        eigeneTextbausteinKey: 'Mathematik',
        orderedKPBereichKeys: [RPBereichKey.geometrie, RPBereichKey.zahlenRechnen, RPBereichKey.sachrechnenGroessen],
      },
      {
        label: 'Geometrie',
        labelShort: 'Geo',
        eigeneTextbausteinKey: 'Mathe_Geometrie',
      },
      {
        label: 'Zahlen und Rechnen',
        labelShort: 'ZuR',
        eigeneTextbausteinKey: 'Mathe_Zahlen_Rechnen',
      },
      {
        label: 'Sachrechnen und Größen',
        labelShort: 'SuG',
        eigeneTextbausteinKey: 'Mathe_Sachrechnen_Groessen',
      }
    );
    this.musik = new FormularFach(this, RPFachKey.musik, {
      label: 'Musik',
      labelShort: 'Mu',
      kategorien: '2000\r\n1019\r\n2001\r\n80',
      eigeneTextbausteinKey: 'Musik',
    });
    this.sport = new FormularFach(this, RPFachKey.sport, {
      label: 'Sport',
      labelShort: 'Sp',
      kategorien: '2001\r\n90',
      eigeneTextbausteinKey: 'Sport',
    });
    this.bildendeKunst = new FormularFach(this, RPFachKey.bildendeKunst, {
      label: 'Bildende Kunst/Textiles Gestalten/Werken',
      labelShort: 'BK',
      kategorien: '2000\r\n1021\r\n2001\r\n70',
      eigeneTextbausteinKey: 'BK_Textiles_Werken',
    });
    this.musikSportBildendeKunst = new FormularFach(this, RPFachKey.musikSportBildendeKunst, {
      label: 'Musik/Sport/Bildende Kunst/Textiles Gestalten/Werken',
      labelShort: 'MuSpBK',
      linkedFaecher: [this.musik, this.sport, this.bildendeKunst],
      eigeneTextbausteinKey: 'Musik_Sport_BK_Tex_Werk',
    });
    this.schrift = new FormularFach(this, RPFachKey.schrift, {
      label: 'Schrift',
      labelShort: 'Schr',
      kategorien: '2000\r\n1002',
      eigeneTextbausteinKey: 'Schrift',
    });
    this.sprachenportfolio = new SprachenportfolioField(zeugnis);
    this.sprachenportfolioSprache = new SprachenportfolioSpracheField(zeugnis);
    this.anlageTyp = new AnlageTypField(zeugnis);
    this.herkunftssprache = new FormularFach(this, RPFachKey.herkunftssprache, {
      label: '1. Herkunftssprachenunterricht',
      labelShort: 'He1',
      labelKey: 'herkunftssprache.gesamt.label',
      eigeneTextbausteinKey: 'Herkunftssprache',
      teilnahmeCandidates: [
        { value: null, label: 'Keine Einschränkung' },
        { value: RPBereichKey.sprache1, label: 'Teilnahme an Sprache 1' },
        { value: RPBereichKey.sprache2, label: 'Teilnahme an Sprache 2' },
        { value: RPBereichKey.sprache3, label: 'Teilnahme an Sprache 3' },
        { value: RPBereichKey.sprache4, label: 'Teilnahme an Sprache 4' },
        { value: RPBereichKey.sprache5, label: 'Teilnahme an Sprache 5' },
      ],
      orderedKPBereichKeys: [
        RPBereichKey.sprache1,
        RPBereichKey.sprache2,
        RPBereichKey.sprache3,
        RPBereichKey.sprache4,
        RPBereichKey.sprache5,
      ],
    });
    this.herkunftssprache2 = new FormularFach(this, RPFachKey.herkunftssprache2, {
      label: '2. Herkunftssprachenunterricht',
      labelShort: 'He2',
      labelKey: 'herkunftssprache2.gesamt.label',
      eigeneTextbausteinKey: 'Herkunftssprache2',
      kpZeugnissatzFachKey: 'herkunftssprache',
      teilnahmeCandidates: [
        { value: null, label: 'Keine Einschränkung' },
        { value: RPBereichKey.sprache1, label: 'Teilnahme an Sprache 1' },
        { value: RPBereichKey.sprache2, label: 'Teilnahme an Sprache 2' },
        { value: RPBereichKey.sprache3, label: 'Teilnahme an Sprache 3' },
        { value: RPBereichKey.sprache4, label: 'Teilnahme an Sprache 4' },
        { value: RPBereichKey.sprache5, label: 'Teilnahme an Sprache 5' },
      ],
      orderedKPBereichKeys: [
        RPBereichKey.sprache1,
        RPBereichKey.sprache2,
        RPBereichKey.sprache3,
        RPBereichKey.sprache4,
        RPBereichKey.sprache5,
      ],
    });
    this.spsTyp = new SpsTypField(zeugnis);
    this.spsText = new TextField({ label: 'SPS Text', labelShort: 'SPSTxt', property: 'spsText' }, { object: zeugnis });
    this.abschlusszeugnisTyp = new AbschlusszeugnisTypField(zeugnis);
    this.gespraechsatzTyp = new ValueField(
      { label: 'Gesprächsatztyp', labelShort: 'GesprTyp', property: 'gespraechsatzTyp', required: false },
      { object: zeugnis }
    );
    this.protokolldatum = new DateField(
      { label: 'Protokolldatum', labelShort: 'ProtDat', property: 'protokolldatum' },
      { object: zeugnis }
    );
  }

  abstract get category(): RPFormularCategoryKey;
  abstract get faecher(): FormularFach[];

  get hasLegende(): boolean {
    return (
      this.zeugnis.zeugnissatz.klassenstufe >= 3 &&
      (this.zeugnis.schuelerTyp === null ||
        (this.zeugnis.schuelerTyp === 'Lernbehindert' &&
          this.faecher.some((f) => f.gesamt.isVisibleOverrideNoteDefaultFalse)))
    );
  }
  get isAbschlussMoeglich(): boolean {
    const zeugnis = this.zeugnis;
    const zeugnissatz = this.zeugnis.zeugnissatz;
    return (
      zeugnissatz.klassenstufe === 4 &&
      zeugnissatz.halbjahr === 1 &&
      (zeugnis.schuelerTyp === null || zeugnis.schuelerTyp === 'Schuleingangsphase')
    );
  }
  get isAbschluss(): boolean {
    return (
      this.zeugnis.zeugnisTyp === null &&
      this.zeugnis.zeugnissatz.klassenstufe === 4 &&
      this.zeugnis.zeugnissatz.halbjahr === 1
    );
  }

  abstract get zeugnisTypLabel(): string;

  get isBeglaubigt(): boolean {
    const zeugnis = this.zeugnis;
    const zeugnissatz = this.zeugnis.zeugnissatz;
    return (
      zeugnis.zeugnisTyp === 'Abgang' ||
      (zeugnis.zeugnisTyp === null &&
        zeugnissatz.klassenstufe === 4 &&
        zeugnissatz.halbjahr === 1 &&
        (zeugnis.schuelerTyp === null || zeugnis.schuelerTyp === 'Schuleingangsphase'))
    );
  }
  get isKPMoeglich(): boolean {
    const zeugnis = this.zeugnis;
    const zeugnissatz = this.zeugnis.zeugnissatz;
    return (
      zeugnissatz.halbjahr === 1 &&
      zeugnissatz.klassenstufe >= 3 &&
      zeugnis.schuelerTyp === null &&
      zeugnissatz.raster?.isKPActive === true
    );
  }
}
