import { Guid } from 'guid-typescript';

export class KPItem {
  guid: string = Guid.create().toString();
  text: string = '';
  gruppe: string = '';
  typ: string | null = null;
  sort: number | null = null;
  key: string | null = null;

  static toDto(item: KPItem): unknown {
    return { ...item, guid: item.guid.toString() };
  }

  static fromDto(dto: any): KPItem {
    return Object.assign(new KPItem(), dto);
  }
}
