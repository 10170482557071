<ng-template fzTemplate="sidebar">
  <fz-sl-schueler-typ></fz-sl-schueler-typ>
  <fz-sl-formular-typ></fz-sl-formular-typ>
  <fz-sl-zeugnislevel *ngIf="formular.zeugnis.formularTyp === 'Regel'"></fz-sl-zeugnislevel>
</ng-template>
<fz-formular
  name="SL1234hjGeistig"
  [pageMarginTop]="151"
  [pageMarginTopFirst]="57"
  [pageHeader]="slFolgeseiteComponent"
  [pageFooter]="slSeitenfussComponent"
>
  <ng-container *ngIf="model != null">
    <fz-sl-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-sl-zeugniskopf>
    <fz-sl-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-sl-zeugnissatz>
    <fz-sl-schulbesuchsjahr
      fzFormularItem="schulbesuchsjahr"
      fzContainer
      [model]="model.schulbesuchsjahr"
    ></fz-sl-schulbesuchsjahr>
    <fz-sl-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-sl-schueler>
    <fz-section
      fzFormularItem="sozialverhalten"
      [label]="model.sozialverhalten.label"
      [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
      >
        <fz-verbal
          [field]="model.sozialverhalten.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="lernArbeitsverhalten"
      [label]="model.lernArbeitsverhalten.label"
      [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
      >
        <fz-verbal
          [field]="model.lernArbeitsverhalten.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="besondereFaehigkeiten"
      [label]="model.besondereFaehigkeiten.label"
      [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
      >
        <fz-intelli
          [field]="model.besondereFaehigkeiten.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="fachbezogeneLernUndLeistungsentwicklung"
      [label]="model.fachbezogeneLernLeistungsentwicklung.label"
      [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
      >
        <fz-verbal
          [field]="model.fachbezogeneLernLeistungsentwicklung.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="bemerkungen"
      [label]="model.bemerkungen.label"
      [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
      >
        <fz-intelli
          [field]="model.bemerkungen.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-deco>
    </fz-section>
    <fz-sl-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-sl-versaeumnis>
    <fz-sl-schulort fzFormularItem="schulort" fzContainer [model]="model.schulort"></fz-sl-schulort>
    <fz-sl-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-sl-unterschrift>
    <fz-sl-kenntnis fzFormularItem="kenntnis" fzContainer></fz-sl-kenntnis>
  </ng-container>
</fz-formular>
