<fz-panel
  padding="normal"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div style="text-align: center" class="Zeugnistitel">
    <fz-label>Schriftliche Mitteilung</fz-label>
  </div>
  <div style="text-align: center" class="Schuelername">
    <fz-label>über vorgesehene zentrale Inhalte</fz-label>
  </div>
  <div style="text-align: center" class="SchuelerGebOrt">
    <fz-label>eines nicht in Anspruch genommenen</fz-label>
  </div>
  <div style="text-align: center" class="Schuelername">
    <fz-label>Entwicklungsgesprächs</fz-label>
  </div>
  <div>
    <fz-label
      >Leider sind Sie der Einladung zum Entwicklungsgespräch Ihres Kindes nicht gefolgt. Aus diesem Grund informiere
      ich Sie schriftlich über zentrale Inhalte zur Lern- und Leistungsentwicklung Ihres Kindes
    </fz-label>
  </div>
</fz-panel>
