import { ImportService } from './import.service';
import { Component, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Jahrgang } from '../../../models/jahrgang';
import { TransferFile } from '../../../models/generated/transfer-file';
import { Router } from '@angular/router';
import { ImportSelectSourceComponent } from './import-select-source/import-select-source.component';
import { ImportInternetPinComponent } from './import-internet-pin/import-internet-pin.component';
import { ImportAssiPasswortComponent } from './import-assi-passwort/import-assi-passwort.component';
import { ImportAssiSelectContentComponent } from './import-assi-select-content/import-assi-select-content.component';
import { SelectKundennummerComponent } from '../select-kundennummer/select-kundennummer.component';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent {
  @ViewChild(ImportSelectSourceComponent) selectSource: ImportSelectSourceComponent | undefined;
  @ViewChild(SelectKundennummerComponent) internetKundennummer: SelectKundennummerComponent | undefined;
  @ViewChild(ImportInternetPinComponent) internetPin: ImportInternetPinComponent | undefined;
  @ViewChild(ImportAssiPasswortComponent) assiPasswort: ImportAssiPasswortComponent | undefined;
  @ViewChild(ImportAssiSelectContentComponent) assiSelectContent: ImportAssiSelectContentComponent | undefined;

  pin: string = '';
  transferFile: TransferFile | undefined;

  jahrgang: Jahrgang | undefined;
  items: MenuItem[] = [];
  activeStep = 0;

  constructor(
    private importService: ImportService,
    private router: Router
  ) {
    this.items = [
      { label: 'Start' },
      { label: 'Lizenz angeben' },
      { label: 'Pin angeben' },
      { label: 'Passwort angeben' },
      { label: 'Umfang auswählen' },
      { label: 'Fertig' },
    ];
  }

  async firstStep() {
    this.activeStep = 0;

    // ggf aufräumen, falls eine guid existiert
    if (this.transferFile?.id != null) await this.importService.resetImport(this.transferFile.id);
    this.transferFile = undefined;
  }

  internet(): void {
    this.activeStep = 1;
  }

  kundennummerChecked() {
    this.activeStep = 2;
  }

  pinChecked(transferFile: TransferFile): void {
    this.transferFile = transferFile;
    this.activeStep = 3;
  }

  uploadCompleted(transferFile: TransferFile): void {
    this.transferFile = transferFile;
    this.activeStep = 3;
  }

  passwordChecked(data: Jahrgang): void {
    this.jahrgang = data;
    this.activeStep = 4;
  }

  importCompleted(): void {
    this.activeStep = 5;
  }

  jahrgangAnzeigen(): void {
    this.router.navigate(['/main']);
  }
}
