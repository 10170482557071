import { ContentElement } from './content-element';

export class ImageElement extends ContentElement {
  constructor() {
    super();
  }
  exportPdf(/*_doc: jsPDF, _pageRect: DOMRect*/): void {
    //
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'fz-image': ImageElement;
  }
}
