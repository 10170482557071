import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';

@Component({
  selector: 'fz-bw-seitenkopf',
  templateUrl: 'bw-seitenkopf.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, BWSeitenkopfComponent)],
})
export class BWSeitenkopfComponent extends BWPanelDecoratorBase {
  @Input() pageIndex = 0;
  @Input() hidePage = false;
}
