<div class="flex flex-col gap-2 pt-5">
  <div class="flex flex-ro gap-2 items-center">
    <label for="horizontal">Ignorierte Zeilen</label>
    <p-inputNumber
      [(ngModel)]="ignoreRows"
      [showButtons]="true"
      buttonLayout="horizontal"
      inputId="horizontal"
      spinnerMode="horizontal"
      [step]="1"
      incrementButtonIcon="pi pi-plus"
      decrementButtonIcon="pi pi-minus"
      (ngModelChange)="ignoredRowsChanged()"
      [min]="0"
      [max]="lines.length"
    >
    </p-inputNumber>
  </div>
  <p-table
    styleClass="p-datatable-sm"
    [value]="selectedLines"
    sortMode="single"
    sortField="name"
    [scrollable]="true"
    scrollHeight="300px"
  >
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td *ngFor="let key of Object.keys(rowData)">
          {{ schuelerlisteService.getDisplayValue(rowData[key]) }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="fz-button-group pt-2">
    <button pButton (click)="back.emit()" class="w-40" icon="pi pi-caret-left" label="Zurück"></button>
    <button pButton (click)="next.emit(selectedLines)" class="w-40" icon="pi pi-caret-right" label="Weiter"></button>
  </div>
</div>
