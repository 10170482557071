<fz-panel>
  <fz-panel
    class="Fusstext"
    [border]="borderDirection.top"
    *ngIf="
      formular.zeugnissatz.klassenstufe < 3 &&
      !(
        formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule &&
        formular.zeugnis.zeugnislevel === 2 &&
        formular.zeugnis.zeugnissatz.halbjahr === 0 &&
        !formular.zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal
      )
    "
  >
    <div>
      <fz-label>Folgende Fächer werden in der Schuleingangsphase unterrichtet:</fz-label>
    </div>
    <div>
      <fz-label>
        Religion, Deutsch, Sachunterricht, Mathematik, Bildende Kunst, Musik, Sport<ng-container
          *ngIf="!formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule"
          >, ggf. Französisch</ng-container
        >
      </fz-label>
    </div>
  </fz-panel>
  <fz-panel
    class="Fusstext"
    [border]="borderDirection.top"
    *ngIf="
      formular.zeugnissatz.klassenstufe >= 3 ||
      (formular.zeugnissatz.klassenstufe < 3 &&
        formular.zeugnis.zeugnislevel === 2 &&
        !formular.zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal)
    "
  >
    <div style="display: grid; grid-template-columns: 4fr 5fr" *ngIf="formular.zeugnissatz.klassenstufe >= 3">
      <div style="grid-column: 1">
        <fz-label>Notenstufen für Sozialverhalten/Lern- und Arbeitsverhalten:</fz-label>
      </div>
      <div style="grid-column: 2">
        <fz-label>sehr gut - gut - befriedigend - nicht immer befriedigend - unbefriedigend</fz-label>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: 4fr 5fr">
      <div style="grid-column: 1">
        <fz-label>Notenstufen für Leistungen:</fz-label>
      </div>
      <div style="grid-column: 2" *ngIf="!formular.zeugnissatz.is15">
        <fz-label>sehr gut - gut - befriedigend - ausreichend - mangelhaft - ungenügend</fz-label>
      </div>
      <div style="grid-column: 2" *ngIf="formular.zeugnissatz.is15">
        <div><fz-label>sehr gut (15/14/13), gut (12/11/10), befriedigend (09/08/07),</fz-label></div>
        <div><fz-label>ausreichend (06/05/04), mangelhaft (03/02/01), ungenügend (00)</fz-label></div>
      </div>
    </div>
  </fz-panel>
  <div class="Fusstext" style="text-align: right; margin-top: 19px" *ngIf="formular.isErlaeuterungenAktiv">
    <fz-label>- es folgen ergänzende Erläuterungen -</fz-label>
  </div>
</fz-panel>
