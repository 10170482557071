import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Geschlecht } from '../../../models/enums/geschlecht';
import Kategorie from '../../../models/generated/kategorie';
import VerbalbeurteilungCollection from '../../../models/generated/verbalbeurteilung-collection';
import { TextbausteinHelperService } from '../../services/textbaustein-helper.service';

@Injectable({
  providedIn: 'root',
})
export class VerbalbeurteilungService {
  vbc: BehaviorSubject<VerbalbeurteilungCollection> = new BehaviorSubject<VerbalbeurteilungCollection>(
    {} as VerbalbeurteilungCollection
  );

  constructor(
    private http: HttpClient,
    private tbhelper: TextbausteinHelperService
  ) {}

  get(): BehaviorSubject<VerbalbeurteilungCollection> {
    if (this.vbc.value.gruppen == null) {
      this.http.get<VerbalbeurteilungCollection>('/api/verbalbeurteilungen/get').subscribe((res) => {
        this.vbc.next(res);
      });
    }
    return this.vbc;
  }

  getKategorienByKategorienSatz(kategorienSatz?: string): Observable<Kategorie[]> {
    return new Observable<Kategorie[]>((observer) => {
      const kategorienSatzArray = kategorienSatz?.split('\r\n').filter((i) => i.trim() !== '');
      this.get().subscribe((res) => {
        let kategorien: Kategorie[] = [];
        if (res.kategorien != null) {
          kategorien = res.kategorien.filter((k) => kategorienSatzArray?.includes(k.id.toString()));
        }
        observer.next(kategorien);
      });
    });
  }

  getBeurteilungen(
    kategorien: Kategorie[],
    klassenstufe: number | null,
    noten: string[] | null,
    geschlecht: Geschlecht | 'n' | null,
    name: string | null
  ): Observable<{ label: string; note: number | null }[]> {
    const beurteilungen: { label: string; note: number | null }[] = [];
    return new Observable<{ label: string; note: number | null }[]>((observer) => {
      if (name == null || name === '') observer.next(beurteilungen);
      else {
        this.get().subscribe((res) => {
          if (res.mappings != null) {
            const gruppen = res.mappings
              .filter((m) => kategorien.map((k) => k?.id).includes(m.kategorie?.id))
              .map((m) => m.gruppe)
              .filter(
                (g) =>
                  this.notenMatch(noten, g.note?.toString() ?? null) &&
                  this.klassenstufeMatch(klassenstufe, g.klassenstufeMin, g.klassenstufeMax)
              );
            gruppen.forEach((g) =>
              g.beurteilungen
                .filter(
                  (fb) =>
                    (fb.bv == null || fb.bv.toLowerCase() === 'b') &&
                    (geschlecht === 'n' || (fb.geschlecht != null && fb.geschlecht.toLowerCase() === geschlecht))
                )
                .forEach((b) => beurteilungen.push({ label: this.tbhelper.applyValues(b.text, name), note: g.note }))
            );
            observer.next(beurteilungen);
          } else {
            observer.next([]);
          }
        });
      }
    });
  }
  notenMatch(noten: string[] | null, note: string | null): boolean {
    if (noten != null && note != null) return noten.includes(note.toString());
    else if (noten?.includes('k.A.') && note == null) return true;
    else return false;
  }

  klassenstufeMatch(
    klassenstufe: number | null,
    klassenstufeMin: number | null,
    klassenstufeMax: number | null
  ): boolean {
    if (klassenstufe != null && klassenstufeMin != null && klassenstufeMax == null)
      return klassenstufeMin <= klassenstufe;
    if (klassenstufe != null && klassenstufeMin == null && klassenstufeMax != null)
      return klassenstufe <= klassenstufeMax;
    if (klassenstufe != null && klassenstufeMin != null && klassenstufeMax != null)
      return klassenstufeMin <= klassenstufe && klassenstufe <= klassenstufeMax;
    return true;
  }
}
