/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

import BereichKey from './bereich-key';

class NIBereichKey extends BereichKey {
  public static readonly hoerverstehen: string = 'hoerverstehen';
  public static readonly muendlich: string = 'muendlich';
  public static readonly sprachermittlung: string = 'sprachermittlung';
  public static readonly zahlenOperationen: string = 'zahlenOperationen';
  public static readonly groessenMessen: string = 'groessenMessen';
  public static readonly raumForm: string = 'raumForm';
  public static readonly datenZufall: string = 'datenZufall';
  public static readonly zeitGeschichte: string = 'zeitGeschichte';
  public static readonly gesellschaftPolitik: string = 'gesellschaftPolitik';
  public static readonly raum: string = 'raum';
  public static readonly natur: string = 'natur';
  public static readonly technik: string = 'technik';
  public static readonly kommunikativeFertigkeiten: string = 'kommunikativeFertigkeiten';
  public static readonly verfuegungSprachlicheMittel: string = 'verfuegungSprachlicheMittel';
  public static readonly interkulturelleKompetenzen: string = 'interkulturelleKompetenzen';
  public static readonly methodenkompetenzen: string = 'methodenkompetenzen';
}
export default NIBereichKey;
