import { Component } from '@angular/core';
import { OptionsBase } from '../../formular/options-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-sav-typ',
  templateUrl: 'he-sav-typ.component.html',
})
export class HESavTypComponent extends OptionsBase<HEFormular> {
  get savNote(): boolean {
    return this.formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideNoteDefaultTrue;
  }
  set savNote(value: boolean) {
    this.formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideNoteDefaultTrue = value;
    this.formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideTextDefaultFalse = !value;
  }
}
