import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-schulbesuchsjahr',
  templateUrl: 'sl-schulbesuchsjahr.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLSchulbesuchsjahrComponent)],
})
export class SLSchulbesuchsjahrComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      schulbesuchsjahrField: formular.schulbesuchsjahr,
    };
  }

  @Input() model: ReturnType<typeof SLSchulbesuchsjahrComponent.createModel> | undefined;
}
