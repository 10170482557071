<fz-panel *ngIf="model != null">
  <div class="Ueberschriften2" style="display: grid; grid-template-columns: 1fr auto 151px; column-gap: 4px">
    <div style="grid-column: 1">
      <fz-dropdown *ngIf="model.fachLabelDropdownField != null" [field]="model.fachLabelDropdownField"></fz-dropdown>
      <div *ngIf="model.fachLabelStringAndTextField != null">
        <fz-label>{{ model.fachLabelStringAndTextField.text }}</fz-label>
        <fz-text [field]="model.fachLabelStringAndTextField.field"></fz-text>
      </div>
      <fz-label *ngIf="model.fachLabelString != null">{{ model.fachLabelString }}</fz-label>
    </div>
    <div style="grid-column: 2">
      <fz-label *ngIf="model.noteLabelString != null">{{ model.noteLabelString }}</fz-label>
      <fz-select *ngIf="model.noteLabelSelectField != null" [field]="model.noteLabelSelectField" [suppressNull]="true">
        <fz-panel fzSelectCandidate [fzValue]="false" fzLabel="(keine)">
          <fz-label shadow="[Note]"></fz-label>
        </fz-panel>
        <fz-panel fzSelectCandidate [fzValue]="true" fzLabel="Note:">
          <fz-label>Note:</fz-label>
        </fz-panel>
      </fz-select>
    </div>
    <fz-panel
      *ngIf="model.noteField != null"
      style="grid-column: 3; text-align: center"
      backgroundColor="rgba(0,0,0,0.15)"
    >
      <fz-note [field]="model.noteField"></fz-note>
    </fz-panel>
  </div>
  <div class="Ueberschriften3" *ngFor="let sub of model.subs">
    <fz-label>{{ sub }}</fz-label>
  </div>
</fz-panel>
