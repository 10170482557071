import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Formular } from '@modules/formular/formular';
import { FormularService } from '@modules/formular/formular.service';
import _ from 'lodash-es';
import { TextInputReplaceEvent } from './text-input-replace-event';

export enum CaretDirection {
  right,
  left,
  wordRight,
  wordLeft,
  down,
  up,
  contentHome,
  lineHome,
  contentEnd,
  lineEnd,
}

export type PointTarget = { target: EventTarget; clientX: number };

@Directive({ selector: '[fzTextInput]' })
export class TextInputDirective implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output() editableCopy = new EventEmitter<DataTransfer>();
  @Output() editablePaste = new EventEmitter<DataTransfer>();
  @Output() insert = new EventEmitter<string>();
  @Output() replace = new EventEmitter<TextInputReplaceEvent>();
  @Output() delete = new EventEmitter<void>();
  @Output() backspace = new EventEmitter<void>();
  @Output() selectAll = new EventEmitter<void>();
  @Output() selectWord = new EventEmitter<PointTarget>();
  @Output() moveCaret = new EventEmitter<{
    direction: CaretDirection;
    isSelect: boolean;
  }>();
  @Output() pointCaret = new EventEmitter<PointTarget & { isSelect: boolean }>();
  @Output() setClass = new EventEmitter<number>();
  @Output() moveSelection = new EventEmitter<PointTarget>();
  @Output() undoRedo = new EventEmitter<{ action: 'undo' | 'redo' }>();

  #element: HTMLElement;
  // #dragOver: number = 0;
  #dragging = false;
  #ignoreNextMouseMove = false;
  private eventRemovers: (() => void)[] = [];

  constructor(
    ref: ElementRef,
    private formularService: FormularService<Formular>,
    private zone: NgZone,
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef
  ) {
    this.#element = ref.nativeElement;
    this.#element.contentEditable = 'true';
    this.#element.spellcheck = false;
    this.#element.style.cursor = 'text';
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.eventRemovers.push(
        this.renderer.listen(this.#element, 'mousemove', (e) => {
          this.onMousemove(e);
          this.changeDetector.detectChanges();
        })
      );
    });
  }
  ngOnDestroy(): void {
    for (const eventRemover of this.eventRemovers) {
      eventRemover();
    }
  }

  get isReadonly(): boolean {
    return this.formularService.isReadonly;
  }

  @HostListener('copy', ['$event']) onCopy(e: ClipboardEvent): boolean {
    if (e.clipboardData != null) this.editableCopy.emit(e.clipboardData);
    return false;
  }

  @HostListener('cut', ['$event']) onCut(e: ClipboardEvent): boolean {
    if (e.clipboardData != null) {
      this.editableCopy.emit(e.clipboardData);
      if (!this.isReadonly) this.delete.emit();
    }
    return false;
  }

  @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent): boolean {
    if (e.clipboardData != null) if (!this.isReadonly) this.editablePaste.emit(e.clipboardData);
    return false;
  }

  @HostListener('keypress', ['$event']) onKeypress(e: KeyboardEvent): boolean {
    // console.log('keypress: "' + e.key + '"');
    const isControl = e.getModifierState('Control');
    const isMeta = e.getModifierState('Meta') || e.getModifierState('OS');
    if (!isControl && !isMeta) {
      if (!this.isReadonly) {
        if (e.key.length === 1) {
          this.insert.emit(e.key);
          return false;
        }
      }
    }
    return true;
  }

  @HostListener('keydown', ['$event']) onKeydown(e: KeyboardEvent): boolean {
    if (_.some(['Shift', 'Control', 'Alt', 'Meta', 'OS'], (modifier) => e.key === modifier)) {
      return false;
    }
    if (e.code === '229' && e.key === 'Unidentified') {
      // Virtuelle Tastaturen sollen laut W3c immer ein keyCode 229 schicken, das macht auch Android, nich aber das Ipad
      // Damit ist hier keine Behandlung möglioch, da keine Tatste identifiziert werden kann, wird in input-Event behandelt
      return false;
    }
    if (!(e instanceof KeyboardEvent)) {
      // Beim Umwandeln von Handschrift auf Text wird auch ein keydown-Event gefeuert, allerdings ist dieses kein Keyboard-Event
      // Passiert hauptsächlich auf IPad
      // Daher keine Behandlung hier möglich, wird in input-Event behandelt
      return false;
    }

    // console.log('keydown: "' + e.key + '"');

    const isControl = e.getModifierState('Control');
    const isAlt = e.getModifierState('Alt');
    const isMeta = e.getModifierState('Meta') || e.getModifierState('OS');
    const isShift = e.getModifierState('Shift');
    if (e.key === 'Tab') {
      // if (!isShift) {
      //   this.moveCaret.emit({
      //     direction: CaretDirection.contentEnd,
      //     isSelect: false,
      //   });
      // } else {
      //   this.moveCaret.emit({
      //     direction: CaretDirection.contentHome,
      //     isSelect: false,
      //   });
      // }
      return true;
    }
    if (isControl && isShift && e.key === ' ') {
      this.insert.emit(String.fromCharCode(160));
    }
    if (!isControl && !isMeta) {
      if (!this.isReadonly) {
        if (e.key.length === 1) {
          // this.insert.emit(e.key); // wird in keypress behandelt
          return true;
        }
        if (e.key === 'Enter') this.insert.emit('\n');
        if (e.key === 'Delete') this.delete.emit();
        if (e.key === 'Backspace') this.backspace.emit();
      }
    }
    if ((isControl || isMeta) && e.key === 'a') {
      this.selectAll.emit();
    }
    if (e.key === 'ArrowRight') {
      if (isControl || isAlt)
        this.moveCaret.emit({
          direction: CaretDirection.wordRight,
          isSelect: isShift,
        });
      else
        this.moveCaret.emit({
          direction: CaretDirection.right,
          isSelect: isShift,
        });
    }
    if (e.key === 'ArrowLeft') {
      if (isControl || isAlt)
        this.moveCaret.emit({
          direction: CaretDirection.wordLeft,
          isSelect: isShift,
        });
      else
        this.moveCaret.emit({
          direction: CaretDirection.left,
          isSelect: isShift,
        });
    }
    if (e.key === 'ArrowDown') {
      this.moveCaret.emit({
        direction: CaretDirection.down,
        isSelect: isShift,
      });
    }
    if (e.key === 'ArrowUp') {
      this.moveCaret.emit({ direction: CaretDirection.up, isSelect: isShift });
    }
    if (e.key === 'Home') {
      if (isControl)
        this.moveCaret.emit({
          direction: CaretDirection.contentHome,
          isSelect: isShift,
        });
      else
        this.moveCaret.emit({
          direction: CaretDirection.lineHome,
          isSelect: isShift,
        });
    }
    if (e.key === 'End') {
      if (isControl)
        this.moveCaret.emit({
          direction: CaretDirection.contentEnd,
          isSelect: isShift,
        });
      else
        this.moveCaret.emit({
          direction: CaretDirection.lineEnd,
          isSelect: isShift,
        });
    }
    if ((isControl || isMeta) && e.key === 'x') return true;
    if ((isControl || isMeta) && e.key === 'c') return true;
    if ((isControl || isMeta) && e.key === 'v') return true;
    if ((isControl || isMeta) && e.key === 'z') if (!this.isReadonly) this.undoRedo.emit({ action: 'undo' });
    if ((isControl || isMeta) && e.key === 'y') if (!this.isReadonly) this.undoRedo.emit({ action: 'redo' });

    if (isShift && e.key === 'Delete') return true;
    if (isControl && e.key === 'Insert') return true;
    if (!isControl && isShift && e.key === 'Insert') return true;
    // if (isAlt && !isControl && !isMeta && ['1', '2', '3'].includes(e.key)) {
    //   const key = parseInt(e.key, 10);
    //   this.setClass.emit(key);
    // }
    // if (isAlt && !isControl && !isMeta && e.key === 'n') {
    //   this.setClass.emit(0);
    // }
    return false;
  }

  @HostListener('keyup', ['$event']) onKeyup(_e: KeyboardEvent): boolean {
    return false;
  }

  @HostListener('dragstart', ['$event']) onDragstart(e: DragEvent): boolean {
    if (e.dataTransfer != null) {
      e.dataTransfer.effectAllowed = 'copyMove';
      this.editableCopy.emit(e.dataTransfer);
    }
    return true;
  }

  // @HostListener('dragenter', ['$event']) onDragenter(e: DragEvent): boolean {
  //   // if (this.#dragOver === 0) console.log('begin dragover');
  //   this.#dragOver++;
  //   return false;
  // }

  // @HostListener('dragleave', ['$event']) onDragleave(e: DragEvent): boolean {
  //   this.#dragOver--;
  //   // if (this.#dragOver === 0) console.log('end dragover');
  //   return false;
  // }

  @HostListener('dragover', ['$event']) onDragover(e: DragEvent): boolean {
    const isControl = e.getModifierState('Control');
    if (e.dataTransfer != null) {
      e.dataTransfer.dropEffect = isControl ? 'copy' : 'move';
    }
    return false;
  }

  @HostListener('drop', ['$event']) onDrop(e: DragEvent): boolean {
    if (e.target != null) {
      if (this.#dragging && !e.getModifierState('Control')) {
        if (!this.isPointInSelection(e.screenX, e.screenY)) {
          if (!this.isReadonly) this.moveSelection.emit({ target: e.target, clientX: e.clientX });
        }
      } else {
        if (e.dataTransfer != null) {
          this.pointCaret.emit({
            target: e.target,
            clientX: e.clientX,
            isSelect: false,
          });
          if (!this.isReadonly) this.editablePaste.emit(e.dataTransfer);
        }
      }
    }
    if (this.#dragging) {
      this.#dragging = false;
      this.#ignoreNextMouseMove = true; // Firefox sendet mousemove nach dragend
    }
    // this.#dragOver = 0;
    return false;
  }

  @HostListener('dragend', ['$event']) onDragend(e: DragEvent): boolean {
    this.#dragging = false;
    this.#ignoreNextMouseMove = true; // Firefox sendet mousemove nach dragend
    if (e.dataTransfer?.dropEffect === 'move') if (!this.isReadonly) this.delete.emit();
    // this.#dragOver = 0;
    return false;
  }

  @HostListener('mousedown', ['$event']) onMousedown(e: MouseEvent): boolean {
    if (!this.isPointInSelection(e.clientX, e.clientY)) {
      if (e.target != null)
        this.pointCaret.emit({
          target: e.target,
          clientX: e.clientX,
          isSelect: false,
        });
    } else {
      if (e.detail < 3) {
        this.#dragging = true;
        return true;
      } else {
        // triple click --> prevent line selection
        return false;
      }
    }
    return false;
  }

  // @HostListener('mousemove', ['$event']) -- wird in OnInit registriert
  onMousemove(e: MouseEvent): boolean {
    if (this.#dragging) return true;
    if (e.buttons > 0) {
      if (this.#ignoreNextMouseMove) {
        // Firefox sendet mousemove nach dragend
        this.#ignoreNextMouseMove = false;
        return true;
      }
      if (e.target != null)
        this.pointCaret.emit({
          target: e.target,
          clientX: e.clientX,
          isSelect: true,
        });
    }
    return false;
  }

  @HostListener('mouseup', ['$event']) onMouseup(e: MouseEvent): boolean {
    if (e.detail < 3) {
      // console.log('mouseup');
      if (this.#dragging) {
        this.#dragging = false;
        return true;
      }
      if (e.target != null)
        this.pointCaret.emit({
          target: e.target,
          clientX: e.clientX,
          isSelect: true,
        });
    }
    return false;
  }

  @HostListener('click', ['$event']) onClick(_e: MouseEvent): boolean {
    return false;
  }

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    // console.log('input: "' + event.data + '"');
    // console.log(event.inputType);
    if (event.inputType === 'deleteContentBackward') {
      this.backspace.emit();
      return;
    }
    if (event?.target != null && event.target instanceof HTMLElement && event.target.textContent != null) {
      if (event.inputType === 'insertText' && event.data != null) {
        this.replace.emit({ text: event.target.textContent, moveCaret: event.data?.length ?? undefined });
        return;
      }
      if (event.inputType === 'insertCompositionText' || event.inputType === 'insertReplacementText') {
        this.replace.emit({ text: event.target.textContent, moveCaret: event.data?.length ?? 1 });
        return;
      }
      if (event.inputType == null || event.inputType.trim() === '') {
        this.replace.emit({ text: event.target.textContent });
        return;
      }
    }

    console.log(`${event.inputType} not captured`);
  }

  @HostListener('dblclick', ['$event']) onDblclick(e: MouseEvent): void {
    if (e.target != null) this.selectWord.emit({ target: e.target, clientX: e.clientX });
  }

  private isPointInSelection(screenX: number, screenY: number): boolean {
    const selection = window.getSelection();
    if (selection != null) {
      for (let rangeIndex = 0; rangeIndex < selection.rangeCount; rangeIndex++) {
        const rects = selection.getRangeAt(rangeIndex).getClientRects();
        for (let rectIndex = 0; rectIndex < rects.length; rectIndex++) {
          const rect = rects.item(rectIndex);
          if (rect != null) {
            if (rect.left <= screenX && screenX <= rect.right && rect.top <= screenY && screenY <= rect.bottom)
              return true;
          }
        }
      }
    }
    return false;
  }
}
