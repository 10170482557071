import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { AutoFocusModule } from '../../auto-focus.module';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { NotificationService } from '../../services/notification/notification.service';
import { DeleteAccountService } from './delete-account.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
})
export class DeleteAccountComponent {
  password: string = '';
  loading: boolean = false;
  constructor(
    private flinkyAuth: FlinkyAuthService,
    private notificationService: NotificationService,
    private deleteAccountService: DeleteAccountService
  ) {}

  async deleteAccount() {
    try {
      this.loading = true;
      await this.deleteAccountService.deleteAccount(this.password);
      this.flinkyAuth.logout(true);
      this.notificationService.showPermanentSuccess('Ihr Benutzeraccount wurd erfolgreich gelöscht.', 'Erfolg');
    } finally {
      this.loading = false;
    }
  }

  cancel() {
    history.back();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PasswordModule,
    ReactiveFormsModule,
    PanelModule,
    DividerModule,
    ButtonModule,
    AutoFocusModule,
  ],
  declarations: [DeleteAccountComponent],
  exports: [DeleteAccountComponent],
})
export class DeleteAccountModule {}
