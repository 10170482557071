import { CharPosition, NodeHelper, NodeWalker } from '@modules/dom';

export class ChunkedCloneWalker {
  private static readonly chunkSize = 120;
  readonly beforeCharacterPositions: Iterable<{
    position: CharPosition;
    positionClone: CharPosition;
  }>;

  private readonly walker: NodeWalker;
  private readonly walkerClone: NodeWalker;

  constructor(node: Node, cloneTarget: Node) {
    this.walker = new NodeWalker(node);
    this.walkerClone = new NodeWalker(cloneTarget);
    this.beforeCharacterPositions = {
      [Symbol.iterator]: this.generateBeforeCharacterPositions.bind(this),
    };
  }

  private static updateCloneTarget(cloneTarget: Node, chunkCount: number, node: Node) {
    const walker = new NodeWalker(node);
    let foundPos: CharPosition | null = null;
    for (const pos of walker.beforeCharacterPositions) {
      if (pos.index >= chunkCount * this.chunkSize) {
        foundPos = pos;
        break;
      }
    }
    const contents =
      foundPos != null
        ? NodeHelper.cloneContents({
            startNode: node,
            startOffset: 0,
            endNode: foundPos.node,
            endOffset: foundPos.nodeOffset,
          })
        : NodeHelper.cloneContents(node);
    // (cloneTarget as HTMLElement).style.display = 'none';
    NodeHelper.empty(cloneTarget);
    cloneTarget.appendChild(contents);
    // (cloneTarget as HTMLElement).style.display = '';
  }

  private *generateBeforeCharacterPositions() {
    let chunkCount = 1;
    ChunkedCloneWalker.updateCloneTarget(this.walkerClone.node, chunkCount, this.walker.node);
    const it = this.walker.beforeCharacterPositions[Symbol.iterator]();
    let itClone = this.walkerClone.beforeCharacterPositions[Symbol.iterator]();
    while (true) {
      const result = it.next();
      let resultClone = itClone.next();
      if (!result.done && resultClone.done) {
        chunkCount++;
        ChunkedCloneWalker.updateCloneTarget(this.walkerClone.node, chunkCount, this.walker.node);
        itClone = this.walkerClone.beforeCharacterPositions[Symbol.iterator]();
        resultClone = itClone.next();
        for (let i = 0; i < result.value.index; i++) {
          resultClone = itClone.next();
        }
      }
      if (result.done) {
        break;
      }
      if (resultClone.value == null) {
        // eslint-disable-next-line no-console
        console.log('ChunkedCloneWalker.*generateBeforeCharacterPositions(): null!');
      }
      yield { position: result.value, positionClone: resultClone.value };
    }
  }
}
