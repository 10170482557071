<fz-panel *ngIf="model != null">
  <div style="display: grid; grid-template-columns: auto 1fr 1fr; grid-template-rows: 38px auto; column-gap: 38px">
    <div style="grid-row: 1; grid-column: 1">
      <fz-label class="Zeugnisausgabe_Ort">{{ formular.zeugnissatz.zeugniskopflizenz.schulort }}, den </fz-label>
      <fz-linked-date
        class="Zeugnisausgabe_Datum"
        fieldName="zeugnisausgabedatum"
        [field]="model.zeugnisausgabedatumField"
        [linkedField]="formular.zeugnissatz.zeugnisausgabedatum"
      ></fz-linked-date>
    </div>
    <fz-panel
      *ngIf="model.klassenleitungField.value !== ''"
      style="grid-row: 1; grid-column: 2"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 2; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.klassenleitungField"
      placeholder="Bezeichnung Klassenleitung"
    ></fz-text>
    <fz-panel style="grid-row: 1; grid-column: 3" [border]="borderDirection.bottom"></fz-panel>
    <fz-label style="grid-row: 2; grid-column: 3; justify-self: center" class="Unterschriften_Beschriftung"
      >Gesehen: Ein(e) Erziehungsberechtigte(r)</fz-label
    >
  </div>
</fz-panel>
