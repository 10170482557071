import { Component, Input } from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-zeugnissatz',
  templateUrl: 'sl-zeugnissatz.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLZeugnissatzComponent)],
})
export class SLZeugnissatzComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      zeugnisTypField: formular.zeugnisTyp,
      klassenstufeLabel: formular.zeugnissatz.klassenstufeLabel,
      schuljahrLabel: `Schuljahr ${formular.zeugnissatz.schuljahrLabel}`,
    };
  }

  @Input() model: ReturnType<typeof SLZeugnissatzComponent.createModel> | undefined;
  override borderDirection = BorderDirection;
}
