import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-zeugnissatz-foerderschwerpunkt',
  templateUrl: 'he-zeugnissatz-foerderschwerpunkt.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, HEZeugnissatzFoerderschwerpunktComponent)],
})
export class HEZeugnissatzFoerderschwerpunktComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      schulbesuchsjahrTextField: formular.schulbesuchsjahrText,
      klasseLerngruppeField: formular.klasseLerngruppe,
      klassenbezeichnungField: formular.zeugnissatz.klassenbezeichnung,
    };
  }

  @Input() model: ReturnType<typeof HEZeugnissatzFoerderschwerpunktComponent.createModel> | undefined;
}
