import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableDecoratorBase } from './projectable';
import { InputComponent } from './input.component';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NumberField } from '../../models/fields/number-field';

@Component({
  selector: 'fz-number',
  templateUrl: 'number.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, NumberComponent)],
})
export class NumberComponent extends ProjectableDecoratorBase implements AfterViewInit, AfterViewChecked, Projectable {
  @Input() placeholder = '#';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() field: NumberField<any> | undefined;
  @Output() modelChange = new EventEmitter<number | null>();
  @ViewChild(InputComponent) input: InputComponent | undefined;

  #text = '';
  rect = new DOMRect();

  @Input()
  get model(): number | null {
    if (this.#text === '') return null;
    const value = parseInt(this.#text, 10);
    return !Number.isNaN(value) ? value : null;
  }
  set model(value: number | null) {
    if (value != null) this.text = value.toString();
    else this.text = '';
  }

  get text(): string {
    return this.#text;
  }
  set text(value: string) {
    if (this.#text !== value) {
      const oldModel = this.model;
      this.#text = value;
      if (this.model !== oldModel) this.modelChange.emit(this.model);
      if (this.field != null) this.field.value = this.model;
    }
  }

  ngAfterViewInit(): void {
    if (this.field != null) this.model = this.field.value;
  }

  ngAfterViewChecked(): void {
    if (this.field != null && this.field.value !== this.model) this.model = this.field.value;
  }

  onHasFocusChange(hasFocus: boolean): void {
    if (!hasFocus) {
      // eslint-disable-next-line no-self-assign
      this.model = this.model;
      this.input?.selection?.setPosition(this.#text.length);
    }
  }

  keyFilter(e: string): boolean {
    return e >= '0' && e <= '9';
  }
}
