<fz-panel
  fzSectionHeader
  [class]="headerClass"
  [border]="headerBorder"
  [padding]="headerPadding"
  [paddingTop]="headerPaddingTop"
  [paddingBottom]="headerPaddingBottom"
  [paddingLeft]="headerPaddingLeft"
  [paddingRight]="headerPaddingRight"
>
  <fz-label html="{{ label }}"></fz-label>
</fz-panel>
<fz-panel
  fzSectionHeader
  [class]="headerClass"
  [border]="headerBorder"
  [padding]="headerPadding"
  [paddingTop]="headerPaddingTop"
  [paddingBottom]="headerPaddingBottom"
  [paddingLeft]="headerPaddingLeft"
  [paddingRight]="headerPaddingRight"
>
  <fz-label html="{{ label }} (Fortsetzung)"></fz-label>
</fz-panel>
<ng-content></ng-content>
<ng-container
  *ngFor="let blockModel of blockModels"
  fzBlock
  [content]="content"
  [backDeco]="backDeco"
  [frontDeco]="frontDeco"
>
  <ng-template #content="fzPortal" fzPortal>
    <fz-translate>
      <ng-container [fzPortalOutlet]="blockModel.header?.content"></ng-container>
    </fz-translate>
    <fz-translate [fzPosition]="blockModel.contentPosition">
      <ng-container [fzPortalOutlet]="blockModel.contentBlock.content"></ng-container>
    </fz-translate>
  </ng-template>
  <ng-template #backDeco="fzPortal" fzPortal>
    <div style="position: absolute">
      <ng-container [fzPortalOutlet]="blockModel.header?.backDeco"></ng-container>
    </div>
    <div style="position: absolute" [fzPosition]="blockModel.contentPosition">
      <ng-container [fzPortalOutlet]="blockModel.contentBlock.backDeco"></ng-container>
    </div>
  </ng-template>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div style="position: absolute">
      <ng-container [fzPortalOutlet]="blockModel.header?.frontDeco"></ng-container>
    </div>
    <div style="position: absolute" [fzPosition]="blockModel.contentPosition">
      <ng-container [fzPortalOutlet]="blockModel.contentBlock.frontDeco"></ng-container>
    </div>
  </ng-template>
</ng-container>
