<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="fz-paragraph-text">
    <p>Sie haben folgende Möglichkeiten:</p>

    <ol class="list-decimal list-inside">
      <li>Übertragung über Internet (empfohlen)</li>
      <li>
        Schließen Sie jetzt Ihren USB-Speicherstick oder ext. Festplatte an Ihren Computer an und klicken Sie auf die
        Schaltfläche "Manuell", um einen Speicherort auszuwählen
      </li>
    </ol>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Über Internet"
        class="w-64"
        (click)="internet()"
        [loading]="loading"
        icon="pi pi-globe"
      ></button>

      <p-fileUpload
        #uploader
        mode="basic"
        [url]="uploadUrl"
        [headers]="headers"
        (onUpload)="uploadComplete($event)"
        (onError)="uploadError()"
        chooseIcon="pi pi-upload"
        accept=".fztb"
        [auto]="true"
        chooseLabel="Manuell"
        styleClass="w-64"
        [disabled]="loading"
      ></p-fileUpload>
    </div>
  </ng-template>
</p-panel>
