import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { TextbausteinHelperService } from './textbaustein-helper.service';
import { IntelliText, IntelliTextItem, IntelliTextItemCandidate } from '../components/intellibox/intelliText';
import { IntelliTextOptions } from '../components/intellibox/intelliTextOptions';
import BenutzerTextbaustein from '../../models/generated/benutzer-textbaustein';
import { Geschlecht } from '../../models/enums/geschlecht';

@Injectable({
  providedIn: 'root',
})
export class IntelliTextService {
  constructor(
    private http: HttpClient,
    private tbhelper: TextbausteinHelperService
  ) {}

  async getEigeneVerbalTexte(
    intelliTextOptions: IntelliTextOptions,
    felder: (string | undefined)[]
  ): Promise<IntelliTextItem[]> {
    if (felder == null) {
      return [];
    }
    const items = await this.getEigeneRawVerbalTexte(felder);
    const filteredItems: IntelliTextItem[] = [];
    items
      .filter((i) => i.geschlecht == null || i.geschlecht === 'n' || i.geschlecht === intelliTextOptions.geschlecht)
      .filter((i) => i.klasse == null || i.klasse.includes(intelliTextOptions.klassenstufe.toString()))
      .forEach((item) => {
        item.text = this.tbhelper.applyValues(
          item.text,
          intelliTextOptions.schuelervorname,
          intelliTextOptions.schuelername,
          intelliTextOptions.klassenstufe,
          intelliTextOptions.schuljahrVoll,
          intelliTextOptions.schuljahrVollPlus1,
          intelliTextOptions.datumKlassenkonferenz
        );
        filteredItems.push(item);
      });
    return filteredItems;
  }

  async getEigeneRawVerbalTexte(felder: (string | undefined)[]): Promise<IntelliTextItem[]> {
    if (felder == null) return [];
    const tbs = await lastValueFrom(
      this.http.get<BenutzerTextbaustein[]>('/api/textbaustein/getEigeneVerbalTexte', {
        params: new HttpParams().set('felder', felder.join('|')),
      })
    );
    if (tbs != null) {
      const items: IntelliTextItem[] = [];
      tbs.forEach((tb) => {
        const lines = tb.inhalt.split('\r\n');
        lines.forEach((line) => {
          const item = this.convertStringToIntelliTextItem(line);
          item.kategorie = felder.find((d) => `Textbausteine-${d}.txt` === tb.feld);
          if (line.length > 0) items.push(item);
        });
      });
      return items;
    } else return [];
  }

  async getEigeneIntelliTexte(
    intelliTextOptions: IntelliTextOptions,
    feld: string | undefined
  ): Promise<IntelliTextItemCandidate[]> {
    const items = await this.getEigeneRawIntelliTexte(feld);
    const candidate: IntelliTextItemCandidate = { anmerkung: 'Eigene', texte: [] };
    items
      .filter((i) => i.geschlecht == null || i.geschlecht === 'n' || i.geschlecht === intelliTextOptions.geschlecht)
      .filter((i) => i.klasse == null || i.klasse.includes(intelliTextOptions.klassenstufe.toString()))
      .forEach((item) => {
        candidate.texte.push(
          this.tbhelper.applyValues(
            item.text,
            intelliTextOptions.schuelervorname,
            intelliTextOptions.schuelername,
            intelliTextOptions.klassenstufe,
            intelliTextOptions.schuljahrVoll,
            intelliTextOptions.schuljahrVollPlus1,
            intelliTextOptions.datumKlassenkonferenz
          )
        );
      });
    return [candidate];
  }

  async getEigeneRawIntelliTexte(
    feld: string | undefined,
    replaceNamePlaceHolder: boolean = true
  ): Promise<IntelliTextItem[]> {
    if (feld == null) return [];
    const inhalt = await lastValueFrom(
      this.http.get<string>('/api/textbaustein/getIntelliTexte', {
        params: new HttpParams().set('feld', feld),
      })
    );
    if (inhalt != null) {
      const lines = inhalt.split('\r\n');
      const items: IntelliTextItem[] = [];
      lines.forEach((line) => {
        if (line.length > 0) items.push(this.convertStringToIntelliTextItem(line, replaceNamePlaceHolder));
      });
      return items;
    } else return [];
  }

  postEigeneIntelliTexte(items: IntelliTextItem[], feld: string | undefined): Promise<any> {
    return lastValueFrom(
      this.http.post(
        '/api/textbaustein/post',
        JSON.stringify(encodeURIComponent(this.convertToOldTextbausteine(items))),
        {
          params: new HttpParams().set('feld', feld ?? ''),
          headers: new HttpHeaders().append('Content-Type', 'application/json'),
        }
      )
    );
  }

  createIntelliTextItemCandidates(
    items: IntelliTextItem[],
    schuelervorname?: string | null,
    schuelername?: string | null,
    klassenstufe?: number | null,
    schuljahrVoll?: string | null,
    schuljahrVollPlus1?: string | null,
    datumKlassenkonferenz?: string | null
  ): IntelliTextItemCandidate[] {
    const candidates: IntelliTextItemCandidate = { anmerkung: 'Eigene', texte: [] };
    items.forEach((item) => {
      candidates.texte.push(
        this.tbhelper.applyValues(
          item.text,
          schuelervorname,
          schuelername,
          klassenstufe,
          schuljahrVoll,
          schuljahrVollPlus1,
          datumKlassenkonferenz
        )
      );
    });
    return [candidates];
  }

  convertStringToIntelliTextItem(inhalt: string, replaceNamePlaceHolder: boolean = true): IntelliTextItem {
    const item: IntelliTextItem = {} as IntelliTextItem;
    const regexNote = /{N:(.)}/gm;
    const regexGeschlecht = /{G:(.)}/gm;
    const regexKlasse = /{K:([1-4,]+)}/gm;
    const regexName = /({Name})/gm;

    const matchNote = regexNote.exec(inhalt);
    if (matchNote?.[1] != null) {
      item.note = matchNote?.[1];
      inhalt = inhalt.replace(matchNote?.[0], '');
    } else {
      item.note = 'k.A.';
    }

    const matchGeschlecht = regexGeschlecht.exec(inhalt);
    if (matchGeschlecht?.[1] != null) {
      item.geschlecht = matchGeschlecht?.[1] as Geschlecht | 'n';
      inhalt = inhalt.replace(matchGeschlecht?.[0], '');
    }

    const matchKlasse = regexKlasse.exec(inhalt);
    if (matchKlasse?.[1] != null) {
      item.klasse = matchKlasse?.[1].split(',');
      inhalt = inhalt.replace(matchKlasse?.[0], '');
    }

    if (replaceNamePlaceHolder) {
      const matchName = regexName.exec(inhalt);
      if (matchName?.[1] != null) {
        const regex = new RegExp(matchName?.[0], 'g');
        inhalt = inhalt.replace(regex, 'Timo/Anna');
      }
    }

    item.text = inhalt;
    return item;
  }

  convertToOldTextbausteine(items: IntelliTextItem[]): any {
    let result = '';
    items.forEach((item) => {
      if (item.text.trim() !== '') {
        if (item.geschlecht != null && item.geschlecht.length > 0) result += `{G:${item.geschlecht}}`;
        if (item.note != null && item.note.length > 0 && item.note !== 'k.A.') result += `{N:${item.note}}`;
        if (item.klasse != null && item.klasse.length > 0) result += `{K:${item.klasse.join(',')}}`;
        result += `${item.text}\r\n`;
      }
    });
    return result;
  }

  async getIntelliTexte(
    intelliTextOptions: IntelliTextOptions,
    bundesland: string,
    feld: string | undefined
  ): Promise<IntelliTextItemCandidate[]> {
    if (feld == null) return [];
    const items: IntelliTextItemCandidate[] = [];
    try {
      const arr = await lastValueFrom(
        this.http.get<IntelliText[]>(`/assets/laender/${bundesland.toLowerCase()}/${feld}.json`)
      );
      arr
        .filter(
          (i) =>
            (i.halbjahr === intelliTextOptions.halbjahr || i.halbjahr == null) &&
            (i.klassenstufen?.includes(intelliTextOptions.klassenstufe) || i.klassenstufen == null)
        )
        .forEach((i) => {
          const elem = i.items.filter(
            (item) => item.geschlecht === intelliTextOptions.geschlecht || item.geschlecht === 'n'
          );
          if (elem?.length > 0) {
            elem.forEach((item) => {
              const text = this.tbhelper.applyValues(
                item.text,
                intelliTextOptions.schuelervorname,
                intelliTextOptions.schuelername,
                intelliTextOptions.klassenstufe,
                intelliTextOptions.schuljahrVoll,
                intelliTextOptions.schuljahrVollPlus1,
                intelliTextOptions.datumKlassenkonferenz
              );
              const found = items.find((t) => t.anmerkung === i.anmerkung);
              if (found) {
                found.texte.push(text);
              } else {
                items.push({ anmerkung: i.anmerkung, texte: [text] } as IntelliTextItemCandidate);
              }
            });
          }
        });
    } catch (_ex) {
      // evtl. gibt es keine vorgegebenen Textbausteine
    }
    return items;
  }
}
