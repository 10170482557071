<fz-formular
  name="BW12hjStandard"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
>
  <fz-bw-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-bw-zeugniskopf>
  <fz-bw-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-bw-zeugnistyp>
  <fz-bw-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-bw-schueler>
  <fz-deco
    fzFormularItem="sozialverhalten"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.sozialverhalten.label" headerPaddingBottom="normal">
      <fz-verbal
        [field]="model.sozialverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="arbeitsverhalten"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.arbeitsverhalten.label" headerPaddingBottom="normal">
      <fz-verbal
        [field]="model.arbeitsverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="lernen"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.lernverhalten.label" headerPaddingBottom="normal">
      <fz-verbal
        [field]="model.lernverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <fz-deco fzFormularItem="leistungen" *ngIf="model.leistungen != null" paddingLeft="normal" paddingRight="normal">
    <fz-bw-leistungen-de-ma fzContainer [model]="model.leistungen"></fz-bw-leistungen-de-ma>
  </fz-deco>
  <fz-deco
    fzFormularItem="hinweise"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.hinweise.label" headerPaddingBottom="normal">
      <fz-intelli
        [field]="model.hinweise.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="bemerkungen"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.bemerkungen.label" headerPaddingBottom="normal">
      <fz-intelli
        [field]="model.bemerkungen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-section>
  </fz-deco>
  <fz-bw-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-bw-unterschrift>
</fz-formular>
