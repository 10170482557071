import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';

@Component({
  selector: 'fz-he-seitenkopf',
  templateUrl: 'he-seitenkopf.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, HESeitenkopfComponent)],
})
export class HESeitenkopfComponent extends HEPanelDecoratorBase {
  @Input() pageIndex = 0;
  @Input() hidePage = false;
}
