/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { NIFormular } from './ni-formular';
import { NIZeugnisFormular } from './ni-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { NIZeugnistypComponent } from '../projectables/ni-zeugnistyp.component';
import { NIZeugnissatzComponent } from '../projectables/ni-zeugnissatz.component';
import { NISchuelerComponent } from '../projectables/ni-schueler.component';
import { NIVersaeumnisComponent } from '../projectables/ni-versaeumnis.component';
import { NIUnterschriftComponent } from '../projectables/ni-unterschrift.component';
import { NILeistungenComponent } from '../projectables/ni-leistungen.component';

export class NI34hjStandardFormular extends NIZeugnisFormular {
  get viewType(): FormularViewType {
    return NI34hjStandardFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    noteFields.push(
      this.deutsch.gesamt.note,
      this.fremdsprache.gesamt.note,
      this.sachunterricht.gesamt.note,
      this.religion.gesamt.note,
      this.mathematik.gesamt.note,
      this.musik.gesamt.note,
      this.bildendeKunst.gesamt.note,
      this.werken.gesamt.note,
      this.gestalten.gesamt.note,
      this.sport.gesamt.note,
      this.herkunftssprache.gesamt.note
    );
    return noteFields;
  }
}

@Component({
  selector: 'fz-ni-34hj-standard-formular',
  templateUrl: 'ni-34hj-standard-formular.component.html',
})
export class NI34hjStandardFormularComponent extends FormularComponentBase<NI34hjStandardFormular> {
  static createModel(formular: NIFormular) {
    return {
      zeugnistyp: NIZeugnistypComponent.createModel(formular),
      zeugnissatz: NIZeugnissatzComponent.createModel(formular),
      schueler: NISchuelerComponent.createModel(formular),
      versaeumnis: NIVersaeumnisComponent.createModel(formular),
      leistungen: NILeistungenComponent.createModel(formular),
      arbeitsgemeinschaften: {
        label: formular.arbeitsgemeinschaften.description.label,
        textField: formular.arbeitsgemeinschaften,
      },
      interessen: {
        label: 'Besondere Interessen und Fähigkeiten',
        textField: formular.interessen,
      },
      lernArbeitsverhalten: {
        label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
      },
      sozialverhalten: {
        label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
      },
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      unterschrift: NIUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return NI34hjStandardFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<NI34hjStandardFormular>) {
    super(formularService);
  }
}
