<fz-panel *ngIf="model != null">
  <fz-select
    placeholder="Auswahl: Unterricht des bilingualen Zuges"
    emptyLabel="(Bleibt leer - es wird nichts gedruckt)"
    [emptyValue]="'Leer'"
    class="Allgemeiner_Zeugnis_Text"
    [field]="model.selectField"
    [suppressNull]="!formular.zeugnis.zeugnissatz.zeugniskopflizenz.isBilingual"
  >
    <fz-panel fzSelectCandidate [fzValue]="'FranzEng'" fzLabel="Pflichtunterricht Französisch">
      <fz-label>{{ schuelerLabel }} hat am Pflichtunterricht Französisch teilgenommen.</fz-label>
    </fz-panel>
    <ng-template [ngIf]="formular.zeugnis.zeugnissatz.zeugniskopflizenz.isBilingual">
      <fz-panel fzSelectCandidate [fzValue]="'Bilingual'" fzLabel="Unterricht des bilingualen Zuges">
        <fz-label
          >{{ schuelerLabel }} hat in der Klassenstufe {{ klassenstufe }} am Unterricht des bilingualen Zuges
          teilgenommen.</fz-label
        >
      </fz-panel>
      <fz-panel
        fzSelectCandidate
        [fzValue]="'BilingualMitErfolg'"
        fzLabel="Unterricht des bilingualen Zuges mit Erfolg"
      >
        <fz-label
          >{{ schuelerLabel }} hat in der Klassenstufe {{ klassenstufe }} am Unterricht des bilingualen Zuges mit Erfolg
          teilgenommen.</fz-label
        >
      </fz-panel>
    </ng-template>
  </fz-select>
</fz-panel>
