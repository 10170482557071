import { Injectable } from '@angular/core';
import { Halbjahr } from '../../models/enums/halbjahr';
import { JahrgangScopeDto } from '../../models/generated/jahrgang-scope-dto';
import { Jahrgang } from '../../models/jahrgang';
import { Schueler } from '../../models/schueler';
import { Zeugnis } from '../../models/zeugnis';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { FlinkyAuthService } from './auth/flinky-auth.service';
import { IdService } from './id.service';
import { FormularFactory } from '../../modules/formular/formular-factory';

@Injectable({ providedIn: 'root' })
export class JahrgangFactoryService {
  constructor(
    private authService: FlinkyAuthService,
    private idService: IdService
  ) {}

  async createJahrgang(): Promise<Jahrgang> {
    if (this.authService.lizenz == null)
      throw new Error('Jahrgang kann nur mit definierter Kundennummer erzeugt werden');
    return new Jahrgang(await this.idService.getId(), await this.idService.getId(), this.authService.lizenz);
  }

  async createJahrgangFromScope(scope: JahrgangScopeDto) {
    await this.authService.loadLizenzenIfRequired(scope);
    const jahrgaenge = Jahrgang.fromScope(scope, this.authService.lizenzen);
    for (const jahrgang of jahrgaenge) {
      FormularFactory.getFormularsatz(jahrgang.bundesland).initJahrgang(jahrgang);
    }
    return jahrgaenge;
  }

  async createSchueler(jahrgang: Jahrgang): Promise<Schueler> {
    const schueler = new Schueler(await this.idService.getId(), jahrgang);
    schueler.isAktiv = true;
    return schueler;
  }

  async createZeugnissatz(
    jahrgang: Jahrgang,
    schuljahr: number,
    halbjahr: Halbjahr,
    klassenstufe: number
  ): Promise<Zeugnissatz> {
    return new Zeugnissatz(await this.idService.getId(), jahrgang, schuljahr, halbjahr, klassenstufe);
  }

  async createZeugnis(schueler: Schueler, zeugnissatz: Zeugnissatz): Promise<Zeugnis> {
    return new Zeugnis(await this.idService.getId(), schueler, zeugnissatz);
  }
}
