import { ExportTbService } from './../export-tb.service';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import ExportTbResult from '../../../../models/generated/export-tb-result';
import { FlinkyAuthService } from '../../../services/auth/flinky-auth.service';

@Component({
  selector: 'app-export-tb-confirm',
  templateUrl: './export-tb-confirm.component.html',
  styleUrls: ['./export-tb-confirm.component.scss'],
})
export class ExportTbConfirmComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Input() exportTbResult?: ExportTbResult;
  @Output() exportInternetFinished = new EventEmitter<ExportTbResult>();
  @Output() restartSelected = new EventEmitter<boolean>();

  constructor(
    private exportTbService: ExportTbService,
    private flinkyAuth: FlinkyAuthService
  ) {}

  async ok() {
    this.exportInternetFinished.next(
      await this.exportTbService.exportToInternet(this.flinkyAuth.lizenz?.kundennummer ?? '')
    );
  }

  restart() {
    this.restartSelected.next(true);
  }
}
