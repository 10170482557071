import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';
import { RPFormular } from '../formulare/rp-formular';

@Component({
  selector: 'fz-rp-sprachenportfolio',
  templateUrl: 'rp-sprachenportfolio.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPSprachenportfolioComponent)],
})
export class RPSprachenportfolioComponent extends RPPanelDecoratorBase {
  static createModel(formular: RPFormular) {
    return {
      sprachenportfolioField: formular.sprachenportfolio,
      sprachenportfolioSpracheField:
        formular.zeugnis.sprachenportfolio !== 'Leer' ? formular.sprachenportfolioSprache : undefined,
    };
  }

  @Input() model: ReturnType<typeof RPSprachenportfolioComponent.createModel> | undefined;
}
