import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';

@Component({
  selector: 'fz-bw-seitenfuss-last',
  templateUrl: 'bw-seitenfuss-last.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, BWSeitenfussLastComponent)],
})
export class BWSeitenfussLastComponent extends BWPanelDecoratorBase {
  @Input() showNotenstufen = false;
}
