/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

import FachKey from './fach-key';

class HEFachKey extends FachKey {
  public static readonly allgemeineBeurteilung: string = 'allgemeineBeurteilung';
  public static readonly fremdsprache: string = 'fremdsprache';
  public static readonly herkunftssprache: string = 'herkunftssprache';
  public static readonly sprachkompetenz: string = 'sprachkompetenz';
  public static readonly regelfach: string = 'regelfach';
  public static readonly freiwilligesFach: string = 'freiwilligesFach';
  public static readonly deutschIntensiv: string = 'deutschIntensiv';
}
export default HEFachKey;
