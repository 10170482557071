<div class="text-center">
  <div class="text-2xl mb-3">
    {{ appInfo.title }} <span class="text-sm">{{ environment.clientVersion }}</span>
  </div>
  <div class="bg-gray-100">
    <div *ngIf="systemActionsService.isElectron">
      <div class="text-xl">Desktopanwendung</div>
      <div>Speichert Ihre Zeugnisse auf diesem Rechner</div>
    </div>
    <div *ngIf="!systemActionsService.isElectron">
      <div class="text-xl">Webanwendung</div>
      <div>
        Speichert Ihre Zeugnisse DSGVO-konform in der Cloud auf Servern in ISO 27001 zertifizierten Rechenzentren in
        Deutschland
      </div>
    </div>
  </div>
  <div class="flex justify-center">
    <img class="w-20 h-20 mt-3 mb-3" src="assets/Flinky-Logo.svg" alt="Flinky Logo" />
  </div>
  <div class="mt-3 text-xl">Anmelden</div>
</div>

<div class="pb-5 pt-5" *ngIf="updateCheckRunning || licenseCheckRunning">
  Es wird auf Updates geprüft
  <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</div>
<div class="pb-5 pt-5" *ngIf="updateCheckCompleted">
  <div *ngIf="updateAvailable && !updateDownloaded">
    <p-tag
      class="pb-5"
      value="Es ist eine neue Version von Flinky Zeugnis verfügbar. Diese wird nun heruntergeladen."
      icon="pi pi-exclamation-circle"
      severity="warning"
    ></p-tag>
    <p-progressBar [value]="downloadProgress"></p-progressBar>
  </div>
  <div *ngIf="updateDownloaded && !downloadError">
    <p-tag
      class="pb-5"
      value="Das Update wurde heruntergeladen. Bitte starten Sie Flinky Zeugnis neu."
      icon="pi pi-exclamation-circle"
      severity="warning"
    ></p-tag>
  </div>
  <div *ngIf="updateAvailable && downloadError">
    <p-tag
      class="pb-5"
      value="Herunterladen des Updates fehlgeschlagen"
      icon="pi pi-exclamation-circle"
      severity="danger"
    ></p-tag>
    <p>{{ updateHint }}</p>
  </div>
  <br />
</div>

<form id="login-element" [formGroup]="loginform" (ngSubmit)="login()">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="user">Benutzer</label>
      <input *ngIf="!systemActionsService.isElectron" pInputText formControlName="login" fzAutofocus />
      <p-dropdown
        *ngIf="systemActionsService.isElectron === true"
        [options]="availableUsers"
        optionValue="benutzername"
        optionLabel="benutzername"
        formControlName="login"
        placeholder="Benutzer wählen"
        emptyMessage="keine Benutzer - neuen Benutzer erstellen und danach bei Bedarf Daten aus altem Flinky Zeugnis Classic oder Desktop übernehmen!"
      ></p-dropdown>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <label for="password">Passwort</label>
      <p-password
        id="password"
        formControlName="password"
        [feedback]="false"
        styleClass="p-input-icon-right"
        [toggleMask]="true"
      ></p-password>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <button
        pButton
        [loading]="loginInProcess"
        type="submit"
        label="Anmelden"
        icon="pi pi-sign-in"
        [disabled]="!loginform.valid || loginInProcess"
      ></button>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <button
        pButton
        class="p-button-link"
        type="button"
        id="passwort-vergssen"
        routerLink="/recover"
        label="Passwort vergessen?"
      ></button>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <button
        pButton
        type="button"
        label="Neuen Benutzer anlegen"
        icon="pi pi-user-plus"
        routerLink="/register-form"
      ></button>
    </div>
  </div>
  <div *ngIf="!systemActionsService.isElectron">
    <div class="flex flex-row justify-center">
      <a target="_blank" href="https://flinky-zeugnis.de/datenschutzerklaerung/#impressum">Impressum</a>
      <span>&nbsp;|&nbsp;</span>
      <a target="_blank" href="https://flinky-zeugnis.de/datenschutzerklaerung/#datenschutz">Datenschutzerklärung</a>
    </div>
  </div>
</form>

<app-disclaimer></app-disclaimer>
