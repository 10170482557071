import { PropertyFieldDescription, PropertyFieldRef } from './property-field';
import { RichTextField } from './rich-text-field';

export type IntelliDescription<T> = PropertyFieldDescription<T, string | null> & {
  textbausteinKey?: string;
};

export class IntelliField<T, TDesc extends IntelliDescription<T> = IntelliDescription<T>> extends RichTextField<
  T,
  TDesc
> {
  constructor(
    description: IntelliDescription<T>,
    public override readonly ref: PropertyFieldRef<T, string | null>
  ) {
    super(description, ref);
  }
}
