<fz-panel *ngIf="model != null">
  <div
    style="display: grid; grid-template-rows: auto auto; grid-template-columns: auto 1fr auto; column-gap: 19px"
    *ngIf="model.normal != null"
  >
    <div style="grid-row: 1; grid-column: 1"><fz-label class="Allgemeiner_Zeugnis_Text">Klasse</fz-label>&nbsp;</div>
    <div style="grid-row: 1; grid-column: 2" *ngIf="model.normal.klassenbezeichnungField != null">
      <fz-text
        class="Schuljahr"
        [field]="model.normal.klassenbezeichnungField"
        placeholder="Klassenbezeichnung"
      ></fz-text>
    </div>
    <div style="grid-row: 1; grid-column: 3; text-align: right">
      <fz-label class="Allgemeiner_Zeugnis_Text">Schuljahr</fz-label>&nbsp;
      <fz-schuljahr-label class="Schuljahr">{{ formular.zeugnissatz.schuljahrLabel }}</fz-schuljahr-label>
    </div>
    <div style="grid-row: 2; grid-column: 1">
      <fz-label>Vor- und Zuname</fz-label>
    </div>
    <div style="grid-row: 2; grid-column: 2; align-items: baseline">
      <fz-text class="Schuelername" [field]="model.normal.vornameField" placeholder="Vorname"></fz-text>&nbsp;<fz-text
        class="Schuelername"
        [field]="model.normal.nameField"
        placeholder="Name"
      ></fz-text
      >&nbsp;
      <fz-dropdown class="Schuelername" [isShadow]="true" [field]="model.normal.geschlechtField"></fz-dropdown>
    </div>
    <div style="grid-row: 2; grid-column: 3; text-align: right" *ngIf="formular.zeugnis.zeugnissatz.klassenstufe === 2">
      <fz-label class="Schuljahr">{{ formular.zeugnis.zeugnissatz.halbjahr + 1 }}. Schulhalbjahr</fz-label>
    </div>
  </div>
  <div *ngIf="model.abgang != null">
    <div>
      <fz-label>Vor- und Zuname </fz-label>
      <fz-text class="Schuelername" [field]="model.abgang.vornameField" placeholder="Vorname"></fz-text>&nbsp;<fz-text
        class="Schuelername"
        [field]="model.abgang.nameField"
        placeholder="Name"
      ></fz-text
      >&nbsp;
      <fz-dropdown class="Schuelername" [isShadow]="true" [field]="model.abgang.geschlechtField"></fz-dropdown>
    </div>
    <div>
      <fz-label>geboren am </fz-label>
      <fz-date [field]="model.abgang.geburtsdatumField" class="SchuelerGebDat"></fz-date>
      <fz-label> in </fz-label>
      <fz-text [field]="model.abgang.geburtsortField" class="SchuelerGebOrt"></fz-text>
    </div>
    <div>
      <fz-label>hat das Ziel der Grundschule erreicht.</fz-label>
    </div>
  </div>
</fz-panel>
