import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Formular } from '@modules/formular/formular';
import { FormularService } from '@modules/formular/formular.service';
import { IntelliTextItemCandidate } from './intelliText';
import { IntelliTextService } from '../../services/intelliText.service';
import { IntelliTextOptions } from './intelliTextOptions';
import { IntelliDescription } from '../../../models/fields/intelli-field';

@Component({
  selector: 'fz-intelli-box',
  templateUrl: './intelliText.component.html',
  styleUrls: ['./intelliText.component.scss'],
})
export class IntelliTextComponent implements OnInit {
  @Input() text?: string;
  @Output() selectedText: EventEmitter<string> = new EventEmitter<string>();
  @Input() description!: IntelliDescription<any>;
  @Input() isFeldMitNote: boolean = false;
  @Output() editorRequested = new EventEmitter<IntelliDescription<any>[]>();
  texte: IntelliTextItemCandidate[] = [];
  loading: boolean = false;

  intelliTextOptions: IntelliTextOptions = {} as IntelliTextOptions;
  selectedTextbaustein: string | null = null;

  constructor(
    private intelliService: IntelliTextService,
    private formularService: FormularService<Formular>
  ) {}

  ngOnInit(): void {
    this.intelliTextOptions = {
      datumKlassenkonferenz: this.formularService.formular.zeugnis.zeugnissatz.klassenkonferenzdatum,
      geschlecht: this.formularService.formular.zeugnis.schueler.geschlecht,
      klassenstufe: this.formularService.formular.zeugnissatz.klassenstufe,
      halbjahr: this.formularService.formular.zeugnis.zeugnissatz.halbjahr,
      schuelername: this.formularService.formular.zeugnis.schuelerName,
      schuelervorname: this.formularService.formular.zeugnis.schuelerVorname,
      schuljahrVoll: this.formularService.formular.zeugnissatz.schuljahrLabel,
      schuljahrVollPlus1: this.formularService.formular.zeugnissatz.schuljahrPlus1Label,
    } as IntelliTextOptions;
    this.init();
  }

  async init(eigene: IntelliTextItemCandidate[] | null = null) {
    try {
      this.loading = true;
      this.texte = [];
      if (eigene == null) {
        const eigeneServer = await this.intelliService.getEigeneIntelliTexte(
          this.intelliTextOptions,
          this.description.textbausteinKey
        );

        this.texte.push(...eigeneServer);

        const vorgebene = await this.intelliService.getIntelliTexte(
          this.intelliTextOptions,
          this.formularService.formular.zeugnis.zeugnissatz.jahrgang?.bundesland ?? '',
          this.description.textbausteinKey
        );
        this.texte.push(...vorgebene);
        this.loading = false;
      } else {
        this.texte.push(...eigene);
        const vorgebene = await this.intelliService.getIntelliTexte(
          this.intelliTextOptions,
          this.formularService.formular.zeugnis.zeugnissatz.jahrgang?.bundesland ?? '',
          this.description.textbausteinKey
        );
        this.texte.push(...vorgebene);
        this.loading = false;
      }
    } finally {
      this.loading = false;
    }
  }

  textSelected() {
    this.selectedText.next(this.selectedTextbaustein || '');
  }
}
