import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-zeugnislevel',
  templateUrl: 'sl-zeugnislevel.component.html',
})
export class SLZeugnislevelComponent {
  constructor(private formularService: FormularService<SLFormular>) {}

  get formular(): SLFormular {
    return this.formularService.formular;
  }

  onCheckboxChange(): void {
    //
  }
}
