<fz-panel *ngIf="model != null">
  <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; column-gap: 19px">
    <div style="grid-column: 1">
      <fz-label class="Allgemeiner_Zeugnis_Text">Schuljahr</fz-label>&nbsp;
      <fz-schuljahr-label class="Schuljahr">{{ formular.zeugnissatz.schuljahrLabel }}</fz-schuljahr-label>
    </div>
    <div style="grid-column: 2" *ngIf="formular.zeugnis.zeugnissatz.klassenstufe >= 3">
      <fz-label class="Allgemeiner_Zeugnis_Text">{{ formular.zeugnissatz.halbjahrLabel }}</fz-label>
    </div>
    <div
      style="grid-column: 3; text-align: right"
      *ngIf="formular.zeugnis.schuelerTyp !== 'Intensiv' && formular.zeugnis.zeugnissatz.klassenstufe !== 0"
    >
      <fz-label class="Allgemeiner_Zeugnis_Text">Jahrgangsstufe</fz-label>&nbsp;
      <fz-label *ngIf="formular.zeugnis.schuelerTyp !== 'Schuleingangsphase'" class="Schuljahr">{{
        formular.zeugnis.zeugnissatz.klassenstufe
      }}</fz-label>
      <fz-dropdown *ngIf="model.klassenstufeFlexField != null" [field]="model.klassenstufeFlexField"></fz-dropdown>
    </div>
    <div style="grid-column: 4; text-align: right">
      <fz-label class="Allgemeiner_Zeugnis_Text">Klasse</fz-label>&nbsp;
      <fz-text class="Schuljahr" [field]="model.klassenbezeichnungField" placeholder="Klassenbezeichnung"></fz-text>
    </div>
  </div>
</fz-panel>
