import { ExportTbConfirmComponent } from './export-tb-confirm/export-tb-confirm.component';
import { ExportTbFinishedComponent } from './export-tb-finished/export-tb-finished.component';
import { ExportTbStep1Component } from './export-tb-step1/export-tb-step1.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportTbComponent } from './export-tb.component';
import { ExportTbErrorComponent } from './export-tb-error/export-tb-error.component';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { StepsModule } from 'primeng/steps';

@NgModule({
  imports: [CommonModule, ButtonModule, PanelModule, StepsModule],
  declarations: [
    ExportTbComponent,
    ExportTbStep1Component,
    ExportTbFinishedComponent,
    ExportTbErrorComponent,
    ExportTbConfirmComponent,
  ],
  exports: [ExportTbComponent],
})
export class ExportTbModule {}
