import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';
import { RPFormular } from '../formulare/rp-formular';

@Component({
  selector: 'fz-rp-schueler',
  templateUrl: 'rp-schueler.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, RPSchuelerComponent)],
})
export class RPSchuelerComponent extends RPPanelDecoratorBase {
  static createModel(formular: RPFormular) {
    return {
      notBeglaubigt: !formular.isBeglaubigt
        ? {
            vornameField: formular.schuelerVorname,
            nameField: formular.schuelerName,
            geschlechtField: formular.schueler.geschlecht,
            klassenbezeichnungField: formular.zeugnissatz.klassenbezeichnung,
            schuljahrString: formular.zeugnissatz.schuljahrLabel,
          }
        : undefined,
      beglaubigt: formular.isBeglaubigt
        ? {
            vornameField: formular.schuelerVorname,
            nameField: formular.schuelerName,
            geschlechtField: formular.schueler.geschlecht,
            geburtsdatumField: formular.schueler.geburtsdatum,
            geburtsortField: formular.schueler.geburtsort,
            schuljahrString: formular.zeugnissatz.schuljahrLabel,
            klassenbezeichnungField: formular.zeugnissatz.klassenbezeichnung,
          }
        : undefined,
    };
  }

  @Input() model: ReturnType<typeof RPSchuelerComponent.createModel> | undefined;
}
