<div class="flex-grow h-0 overflow-auto">
  <p-table
    #grid
    [value]="schuelerService.selectedZeugnisse"
    selectionMode="single"
    (sortFunction)="customSort($event)"
    sortMode="single"
    sortField="name"
    [customSort]="true"
    dataKey="id"
  >
    <ng-template pTemplate="caption">
      <button
        *ngIf="schuelerService.currentZeugnissatz != null"
        type="button"
        pButton
        icon="pi pi-download"
        label="Import"
        (click)="importStart()"
        class="p-button-raised"
      ></button>
    </ng-template>
    <ng-template pTemplate="header">
      <tr style="height: 51px">
        <th scope="col" class="w-8"></th>
        <th scope="col" pSortableColumn="name">
          <div class="flex">Name <p-sortIcon field="name"></p-sortIcon></div>
        </th>
        <th scope="col" pSortableColumn="vorname">
          <div class="flex">Vorname <p-sortIcon field="vorname"></p-sortIcon></div>
        </th>
        <th scope="col">
          <div class="flex">Geschlecht</div>
        </th>
        <th scope="col">
          <div class="flex">Geburtsdatum</div>
        </th>
        <th scope="col">
          <div class="flex">Geburtsort</div>
        </th>
        <th scope="col" class="w-16"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-zeugnis let-rowIndex="rowIndex">
      <tr
        [id]="zeugnis.id"
        [ngClass]="{
          'fz-inaktiv': zeugnis.schueler.isAktiv === false,
        }"
      >
        <td class="w-8 text-gray-400">
          <i *ngIf="zeugnis.isLoading" class="pi pi-spin pi-spinner"></i>
          <div *ngIf="!zeugnis.isLoading">{{ rowIndex + 1 }}</div>
        </td>
        <td>{{ zeugnis.schueler.name }}</td>
        <td>{{ zeugnis.schueler.vorname }}</td>
        <td>{{ zeugnis.schueler.geschlecht }}</td>
        <td>{{ zeugnis.schueler.geburtsdatum }}</td>
        <td>{{ zeugnis.schueler.geburtsort }}</td>
        <td class="w-16">
          <!-- <button
          *ngIf="zeugnis.schueler.isAktiv"
          class="p-button-rounded p-button-outlined p-button-sm p-button-danger"
          id="btn{{ rowIndex }}"
          type="button"
          pButton
          icon="pi pi-trash"
          (click)="toggleMenuOptions(zeugnis); schuelerMenu.toggle($event)"
        ></button>
        <button
          *ngIf="!zeugnis.schueler.isAktiv"
          class="p-button-rounded p-button-outlined p-button-sm"
          id="btn{{ rowIndex }}"
          type="button"
          pButton
          icon="pi pi-replay"
        ></button> -->
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  header="Schülerlistenimport"
  [(visible)]="importDialogVisible"
  [modal]="true"
  [style]="{ width: '75vw' }"
  [maximizable]="true"
  [baseZIndex]="10000"
  [draggable]="true"
  [resizable]="true"
>
  <fz-schuelerliste-import (finished)="importDialogVisible = false"></fz-schuelerliste-import>
</p-dialog>
