import { MessagesModule } from 'primeng/messages';
import { ProgressBarModule } from 'primeng/progressbar';
import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { HeaderComponent } from '..';
import { InfoDialogModule } from '../info-dialog/info-dialog.component';

@NgModule({
  imports: [
    PanelMenuModule,
    ButtonModule,
    OverlayPanelModule,
    AvatarModule,
    CommonModule,
    InfoDialogModule,
    DialogModule,
    ProgressBarModule,
    TieredMenuModule,
    MessagesModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
