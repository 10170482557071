import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';
import { BWFormular } from '../formulare/bw-formular';

@Component({
  selector: 'fz-bw-unterschrift',
  templateUrl: 'bw-unterschrift.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, BWUnterschriftComponent)],
})
export class BWUnterschriftComponent extends BWPanelDecoratorBase {
  static createModel(formular: BWFormular) {
    return {
      zeugnisausgabedatumField: formular.zeugnisausgabedatum,
      schulleitungField: formular.zeugnissatz.schulleitung,
      klassenleitungField: formular.zeugnissatz.klassenleitung,
    };
  }

  @Input() model: ReturnType<typeof BWUnterschriftComponent.createModel> | undefined;
}
