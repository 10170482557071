/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularFach } from '../../../models/formular-fach';
import { HEAnlageFormular } from './he-anlage-formular';
import { HEFormular } from './he-formular';
import { FormularViewType } from '../../formular/formular';
import { HEUnterschriftOptionalComponent } from '../projectables/he-unterschrift-optional.component';

export class HE234hjFreitextFormular extends HEAnlageFormular {
  get viewType(): FormularViewType {
    return HE234hjFreitextFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }
}

@Component({
  selector: 'fz-he-234hj-freitext-formular',
  templateUrl: 'he-234hj-freitext-formular.component.html',
})
export class HE234hjFreitextFormularComponent extends FormularComponentBase<HE234hjFreitextFormular> {
  static createModel(formular: HEFormular) {
    return {
      anlageFreitextField: formular.anlageFreitext,
      unterschrift: HEUnterschriftOptionalComponent.createModel(formular),
    };
  }

  get model() {
    return HE234hjFreitextFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<HE234hjFreitextFormular>) {
    super(formularService);
  }
}
