<p-panel header="Benutzeraccount löschen" class="flex-grow h-0 overflow-auto gap-4">
  <div class="flex flex-col font-medium mt-8">Achtung</div>
  <div class="flex flex-col mt-4">
    Nach dem Löschen werden alle Daten (Jahrgänge, Zeugnisse, Schülerdaten, etc) unwiederruflich gelöscht. Sie können
    sich nicht mehr mit Ihren Zugangsdaten anmelden. Selbstverständlich haben Sie die Möglichkeit danach einen neuen
    Zugang mit der selben E-Mail-Adresse anzulegen.
  </div>
  <div class="flex flex-col mt-4">
    Sollten Sie lokale Datensicherungen erstellt haben, müssen sie diese selbst von den genutzten Medien entfernen.
  </div>
  <div class="flex flex-col mt-4">Bitte geben Sie zur Bestätigung Ihr Passwort an</div>
  <div class="flex flex-col mt-4">
    <p-password type="text" [(ngModel)]="password" [toggleMask]="true"></p-password>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button pButton label="Abbrechen" icon="pi pi-times" class="w-40 p-button-outlined" (click)="cancel()"></button>
      <button
        pButton
        label="Weiter"
        icon="pi pi-caret-right"
        class="w-40"
        (click)="deleteAccount()"
        [disabled]="password.length == 0"
        [loading]="loading"
      ></button>
    </div>
  </ng-template>
</p-panel>
