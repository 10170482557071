import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';
import { RPFormular } from '../formulare/rp-formular';

@Component({
  selector: 'fz-rp-zeugnissatz',
  templateUrl: 'rp-zeugnissatz.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPZeugnissatzComponent)],
})
export class RPZeugnissatzComponent extends RPPanelDecoratorBase {
  static createModel(formular: RPFormular) {
    return {
      label: formular.isAbgang
        ? 'Abgangszeugnis der Grundschule'
        : !formular.isAbgang && !formular.isAbschlussMoeglich
          ? `${formular.zeugnisTypLabel} der Grundschule`
          : undefined,
      dropDownField: !formular.isAbgang && formular.isAbschlussMoeglich ? formular.abschlusszeugnisTyp : undefined,
    };
  }

  @Input() model: ReturnType<typeof RPZeugnissatzComponent.createModel> | undefined;
}
