import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-versaeumnis',
  templateUrl: 'he-versaeumnis.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, HEVersaeumnisComponent)],
})
export class HEVersaeumnisComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      fehltageEntschuldigtField: formular.fehltageEntschuldigt,
      fehltageUnentschuldigtField: formular.fehltageUnentschuldigt,
      fehlstundenEntschuldigtField: formular.fehlstundenEntschuldigt,
      fehlstundenUnentschuldigtField: formular.fehlstundenUnentschuldigt,
    };
  }

  @Input() model: ReturnType<typeof HEVersaeumnisComponent.createModel> | undefined;
}
