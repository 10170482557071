<fz-date [field]="field" (modelChange)="modelChange()"></fz-date>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div style="position: absolute">
    <ng-container [fzPortalOutlet]="date?.frontDeco"></ng-container>
  </div>
  <div
    *ngIf="hasFocus && isDifferent"
    fzFocusable
    tabindex="0"
    style="position: absolute; border-style: solid; border-width: 1px"
    [style.left.px]="date?.input?.rect?.left"
    [style.bottom.px]="0"
    class="floating fz-date"
  >
    <button
      pButton
      label="Für alle übernehmen"
      class="p-button-secondary"
      [loading]="loading"
      (click)="copyToAll()"
    ></button>
  </div>
</ng-template>
