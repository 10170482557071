<ng-container *ngIf="page != null">
  <div
    class="dragger"
    [style.top.px]="margin === 'top' ? value : page.formular.pageHeight - value"
    (mousedown)="mousedown($event)"
  ></div>
  <div
    *ngIf="userLayoutMode"
    class="button"
    [style.top.px]="margin === 'top' ? value : page.formular.pageHeight - value"
    (click)="dialogVisible = true"
  >
    {{ valueM }}mm
  </div>
  <p-dialog
    header="Seiteneigenschaften"
    [(visible)]="dialogVisible"
    [modal]="true"
    [style]="{ width: '24rem' }"
    (onHide)="layoutChange.emit()"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-4"></div>
      <div class="p-col-4">
        <div [style.paddingBottom.rem]="0.5">
          <label>1. Seite</label>
        </div>
      </div>
      <div class="p-col-4">
        <div [style.paddingBottom.rem]="0.5">
          <label>Folgeseiten</label>
        </div>
      </div>
      <div class="p-col-4">
        <div [style.paddingTop.rem]="0.5">
          <label>oberer Rand</label>
        </div>
      </div>
      <div class="p-field p-col-4">
        <div class="p-inputgroup">
          <p-inputNumber
            [(ngModel)]="page.formular.userPageMarginTopFirstM"
            [min]="0"
            [max]="50"
            [allowEmpty]="false"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">mm</span>
        </div>
      </div>
      <div class="p-field p-col-4">
        <div class="p-inputgroup">
          <p-inputNumber
            [(ngModel)]="page.formular.userPageMarginTopM"
            [min]="0"
            [max]="50"
            [allowEmpty]="false"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">mm</span>
        </div>
      </div>
      <div class="p-col-4">
        <div [style.paddingTop.rem]="0.5">
          <label>unterer Rand</label>
        </div>
      </div>
      <div class="p-field p-col-4">
        <div class="p-inputgroup">
          <p-inputNumber
            [(ngModel)]="page.formular.userPageMarginBottomM"
            [min]="0"
            [max]="50"
            [allowEmpty]="false"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">mm</span>
        </div>
      </div>
      <div class="p-field p-col-4">
        <div class="p-inputgroup">
          <p-inputNumber
            [(ngModel)]="page.formular.userPageMarginBottomLastM"
            [min]="0"
            [max]="50"
            [allowEmpty]="false"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">mm</span>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <button
        pButton
        label="Zurücksetzen"
        (click)="page.formular.resetPageMargins()"
        class="p-button-secondary"
      ></button>
      <button pButton label="Ok" (click)="dialogVisible = false"></button>
    </ng-template>
  </p-dialog>
</ng-container>
