<fz-panel
  *ngIf="model != null"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <fz-label class="Zeugnisausgabe_Ort">{{ model.schulortString }}, den </fz-label>
  <fz-linked-date
    class="Zeugnisausgabe_Datum"
    fieldName="protokolldatum"
    [field]="model.protokolldatumField"
    [linkedField]="formular.zeugnissatz.protokollDatum"
  ></fz-linked-date>
</fz-panel>
