<fz-panel class="Fusstext">
  <div *ngIf="showNotenstufen" style="margin-bottom: 4px">
    <fz-label style="font-weight: bold">Notenstufen</fz-label>
    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr">
      <fz-panel style="grid-column: 1" [border]="borderDirection.all">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">1: sehr gut</fz-label>
        </div>
      </fz-panel>
      <fz-panel style="grid-column: 2" [border]="borderDirection.leftOpen">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">2: gut</fz-label>
        </div>
      </fz-panel>
      <fz-panel style="grid-column: 3" [border]="borderDirection.leftOpen">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">3: befriedigend</fz-label>
        </div>
      </fz-panel>
      <fz-panel style="grid-column: 4" [border]="borderDirection.leftOpen">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">4: ausreichend</fz-label>
        </div>
      </fz-panel>
      <fz-panel style="grid-column: 5" [border]="borderDirection.leftOpen">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">5: mangelhaft</fz-label>
        </div>
      </fz-panel>
      <fz-panel style="grid-column: 6" [border]="borderDirection.leftOpen">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">6: ungenügend</fz-label>
        </div>
      </fz-panel>
    </div>
  </div>
  <div>
    <fz-label style="font-weight: bold">Bewertungsstufen für das Arbeits- und Sozialverhalten</fz-label>
    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr">
      <fz-panel style="grid-column: 1" [border]="borderDirection.all">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">"verdient besondere Anerkennung"</fz-label>
        </div>
      </fz-panel>
      <fz-panel style="grid-column: 2" [border]="borderDirection.leftOpen" paddingLeft="normal" paddingRight="normal">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">"entspricht den Erwartungen in vollem Umfang"</fz-label>
        </div>
      </fz-panel>
      <fz-panel style="grid-column: 3" [border]="borderDirection.leftOpen">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">"entspricht den Erwartungen"</fz-label>
        </div>
      </fz-panel>
      <fz-panel style="grid-column: 4" [border]="borderDirection.leftOpen" paddingLeft="normal" paddingRight="normal">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">"entspricht den Erwartungen mit Einschränkungen"</fz-label>
        </div>
      </fz-panel>
      <fz-panel style="grid-column: 5" [border]="borderDirection.leftOpen">
        <div style="display: grid; justify-content: center; align-content: center">
          <fz-label style="text-align: center">"entspricht nicht den Erwartungen"</fz-label>
        </div>
      </fz-panel>
    </div>
  </div>
  <div
    style="display: grid; grid-template-columns: auto 1fr; grid-auto-rows: auto"
    *ngIf="
      (formular.zeugnis.schuelerTyp == null &&
        (formular.zeugnis.zeugnissatz.klassenstufe < 3 ||
          (formular.zeugnis.zeugnissatz.klassenstufe >= 3 && formular.zeugnis.normalTyp === 'Bericht'))) ||
      (formular.zeugnis.schuelerTyp == 'Lernbehindert' && formular.zeugnis.lernTyp == null)
    "
  >
    <fz-label style="grid-column: 1">{{ formular.zeugnis.zeugnissatz.schuljahr >= 2023 ? '¹' : '*' }}</fz-label>
    <fz-label style="grid-column: 2"
      >Die Kompetenzen im Bereich „Sprache und Sprachgebrauch untersuchen“ fließen in die genannten Kompetenzbereiche
      mit ein.</fz-label
    >
    <fz-label style="grid-column: 1">{{ formular.zeugnis.zeugnissatz.schuljahr >= 2023 ? '²' : '**' }}&nbsp;</fz-label>
    <fz-label *ngIf="formular.zeugnis.zeugnissatz.klassenstufe < 3" style="grid-column: 2"
      >Die Kompetenzen in den Bereichen „Muster und Strukturen“ sowie „Daten und Zufall“ fließen in die genannten
      Kompetenzbereiche mit ein.</fz-label
    >
    <fz-label *ngIf="formular.zeugnis.zeugnissatz.klassenstufe >= 3" style="grid-column: 2"
      >Die Kompetenzen in den Bereichen „Muster und Strukturen“ fließen in die genannten Kompetenzbereiche mit
      ein.</fz-label
    >
  </div>
</fz-panel>
