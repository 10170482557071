<ng-template fzTemplate="sidebar">
  <fz-apply-kp></fz-apply-kp>
  <fz-rp-protokoll-typ></fz-rp-protokoll-typ>
  <fz-rp-herkunftssprache></fz-rp-herkunftssprache>
  <fz-rp-kp-button></fz-rp-kp-button>
  <div class="flex gap-1" *ngIf="formular.zeugnis.useKPProtokoll">
    <p-multiSelect
      class="flex-grow"
      dataKey="ref"
      [showHeader]="false"
      [displaySelectedLabel]="false"
      placeholder="Sichtbarkeit"
      [options]="formular.visibleOptions"
      [ngModel]="formular.visibleSelected"
      (onChange)="formular.visibleChange($event.itemValue)"
    ></p-multiSelect>
    <button
      pButton
      type="button"
      label="Für alle übernehmen"
      [loading]="loading"
      (click)="applyVisibleToAll()"
    ></button>
  </div>
  <fz-rp-abgangszeugnis *ngIf="formular.zeugnis.zeugnissatz.klassenstufe === 2"></fz-rp-abgangszeugnis>
</ng-template>
<fz-formular
  name="RP234hProtokoll"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
>
  <fz-rp-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-rp-zeugniskopf>
  <fz-panel
    fzFormularItem="zeugnissatz"
    fzContainer
    padding="normal"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <div style="text-align: center" class="Zeugnistitel">
      <fz-label>{{ model.zeugnissatz.title }}</fz-label>
    </div>
    <div style="text-align: center" class="Ueberschriften">
      <fz-label>{{ model.zeugnissatz.subtitle }}</fz-label>
    </div>
    <div>
      <fz-label>{{ model.zeugnissatz.text }}</fz-label>
    </div>
  </fz-panel>
  <fz-rp-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-rp-schueler>
  <fz-deco
    fzFormularItem="beteiligtePersonen"
    *ngIf="model.beteiligtePersonen != null"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
  >
    <fz-section
      label="Beteiligte Personen"
      headerClass="Ueberschriften2"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="none"
    >
      <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'" paddingTop="normal" paddingBottom="normal">
        <fz-rich-text
          [field]="model.beteiligtePersonen.richTextField"
          [(model)]="model.beteiligtePersonen.richTextField.value"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-rich-text>
      </fz-deco>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="sozialLernArbeitsverhaltenLernentwicklung"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
  >
    <fz-section
      [label]="model.sozialLernArbeitsverhaltenLernentwicklung.headerLabel"
      headerClass="Ueberschriften2"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="none"
    >
      <fz-sequence>
        <fz-deco
          *ngIf="model.sozialLernArbeitsverhaltenLernentwicklung.kp != null"
          [padding]="formular.showBorder ? 'normal' : 'none'"
          paddingTop="normal"
          paddingBottom="minus-border"
        >
          <fz-kp
            [model]="model.sozialLernArbeitsverhaltenLernentwicklung.kp"
            [fach]="formular.sozialLernArbeitsverhalten"
          ></fz-kp>
        </fz-deco>
        <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'" paddingTop="normal" paddingBottom="normal">
          <fz-verbal
            [field]="model.sozialLernArbeitsverhaltenLernentwicklung.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-deco>
      </fz-sequence>
    </fz-section>
  </fz-deco>
  <ng-container *ngFor="let fach of model.faecher; trackBy: trackByProperty">
    <fz-deco [fzFormularItem]="fach.key" [border]="formular.showBorder ? borderDirection.all : borderDirection.none">
      <fz-section
        [label]="fach.headerLabel"
        headerClass="Ueberschriften2"
        [headerPadding]="formular.showBorder ? 'normal' : 'none'"
        headerPaddingBottom="none"
      >
        <fz-panel fzSectionHeader [padding]="formular.showBorder ? 'normal' : 'none'" paddingBottom="none">
          <fz-rp-fach-header [model]="fach.header"></fz-rp-fach-header>
        </fz-panel>
        <fz-panel
          fzSectionHeader
          [padding]="formular.showBorder ? 'normal' : 'none'"
          paddingBottom="none"
          class="Ueberschriften2"
        >
          <fz-label>{{ fach.header.labelFortsetzung }} (Fortsetzung)</fz-label>
        </fz-panel>
        <fz-sequence>
          <fz-deco *ngIf="fach.kp != null" paddingTop="normal" paddingBottom="minus-border">
            <fz-kp [model]="fach.kp" [fach]="getFach(fach.key)"></fz-kp>
          </fz-deco>
          <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'" paddingTop="normal" paddingBottom="normal">
            <fz-verbal
              [field]="fach.textField"
              [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
            ></fz-verbal>
          </fz-deco>
        </fz-sequence>
      </fz-section>
    </fz-deco>
  </ng-container>
  <fz-deco
    *ngIf="model.schrift != null"
    fzFormularItem="schrift"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
  >
    <fz-section
      [label]="model.schrift.headerLabel"
      headerClass="Ueberschriften2"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'" paddingTop="none" paddingBottom="normal">
        <fz-verbal
          [field]="model.schrift.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-section>
  </fz-deco>
  <fz-deco fzFormularItem="ziel" [border]="formular.showBorder ? borderDirection.all : borderDirection.none">
    <fz-section
      [label]="model.ziel.headerLabel"
      headerClass="Ueberschriften2"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'" paddingTop="none" paddingBottom="normal">
        <fz-intelli
          [field]="model.ziel.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-deco>
    </fz-section>
  </fz-deco>
  <fz-rp-schulort-protokoll fzFormularItem="schulort" fzContainer [model]="model.schulort"></fz-rp-schulort-protokoll>
  <fz-panel
    fzFormularItem="unterschrift"
    fzContainer
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <div style="display: grid; grid-template-columns: 1fr 113px 1fr; grid-template-rows: 38px auto">
      <fz-panel
        *ngIf="model.unterschrift.klassenleitungField.value !== ''"
        style="grid-row: 1; grid-column: 1"
        [border]="borderDirection.bottom"
      ></fz-panel>
      <fz-text
        style="grid-row: 2; grid-column: 1; justify-self: center"
        class="Unterschriften_Beschriftung"
        [field]="model.unterschrift.klassenleitungField"
        placeholder="Bezeichnung Klassenleitung"
      ></fz-text>
      <fz-panel style="grid-row: 1; grid-column: 3" [border]="borderDirection.bottom"></fz-panel>
      <fz-label style="grid-row: 2; grid-column: 3; justify-self: center" class="Unterschriften_Beschriftung"
        >Erziehungsberechtigte(r)</fz-label
      >
    </div>
    <fz-select
      [field]="model.unterschrift.schuelerSelectField"
      emptyLabel="(Keine Unterschrift Schüler)"
      [emptyValue]="null"
      [suppressNull]="true"
    >
      <fz-panel fzSelectCandidate [fzValue]="true" fzLabel="Unterschrift Schüler">
        <div style="display: grid; grid-template-columns: 1fr 113px 1fr; grid-template-rows: 38px auto">
          <fz-panel style="grid-row: 1; grid-column: 3" [border]="borderDirection.bottom"></fz-panel>
          <fz-label style="grid-row: 2; grid-column: 3; justify-self: center" class="Unterschriften_Beschriftung">{{
            model.unterschrift.schuelerLabel
          }}</fz-label>
        </div>
      </fz-panel>
    </fz-select>
  </fz-panel>
</fz-formular>
