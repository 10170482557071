<div>
  <p-radioButton
    name="notensystem"
    [value]="false"
    [(ngModel)]="formular.zeugnissatz.is15"
    label='Einfaches Notensystem ("sehr gut", "gut", usw.)'
    (onClick)="optionClick.emit()"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="notensystem"
    [value]="true"
    [(ngModel)]="formular.zeugnissatz.is15"
    label='Punktesystem("sehr gut (15/14/13)", "gut (12/11/10)", usw.)'
    (onClick)="optionClick.emit()"
  ></p-radioButton>
</div>
