<fz-rich-text
  #test
  [(model)]="model"
  [field]="field"
  [width]="width"
  [isBlocksatz]="formular.zeugnissatz.zeugniskopflizenz.isBlocksatz"
  (hasFocusChange)="onFocusChange()"
></fz-rich-text>
<fz-textbaustein-editor #editor [appendTo]="'body'"></fz-textbaustein-editor>
<ng-container
  *ngFor="let blockModel of blockModels"
  fzBlock
  [content]="blockModel.richTextBlock.content"
  [backDeco]="blockModel.richTextBlock.backDeco"
  [frontDeco]="frontDeco"
>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div style="position: absolute">
      <ng-container [fzPortalOutlet]="blockModel.richTextBlock.frontDeco"></ng-container>
      <div
        *ngIf="hasFocus && blockModel === blockModels[blockModels.length - 1]"
        fzFocusable
        (fzHasFocusChange)="onFocusChange()"
        [style.top.px]="blockModel.rect.bottom + 6"
        [style.width.px]="blockModel.rect.width"
        [style.height.px]="189"
        class="floating"
        tabindex="0"
      >
        <fz-intelli-box
          [description]="field!.description"
          (selectedText)="onSelectedBemerkung($event)"
          [text]="richText?.text"
          (editorRequested)="editor.show($event)"
        ></fz-intelli-box>
      </div>
    </div>
  </ng-template>
</ng-container>
