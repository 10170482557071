<fz-panel *ngIf="model != null">
  <div *ngIf="model.normal != null">
    <div style="display: grid; grid-template-columns: 76px 1fr 76px; align-items: baseline">
      <div style="grid-column: 2; text-align: center">
        <fz-text class="Schuelername" [field]="model.normal.vornameField" placeholder="Vorname"></fz-text>&nbsp;
        <fz-text class="Schuelername" [field]="model.normal.nameField" placeholder="Name"></fz-text>
      </div>
      <div style="grid-column: 3; text-align: right">
        <fz-dropdown class="Schuelername" [isShadow]="true" [field]="model.normal.geschlechtField"></fz-dropdown>
      </div>
    </div>
  </div>
  <div *ngIf="model.abgang != null">
    <div>
      <fz-label>{{ nomen }}</fz-label
      >&nbsp;<fz-text class="Schuelername" [field]="model.abgang.vornameField" placeholder="Vorname"></fz-text>&nbsp;
      <fz-text class="Schuelername" [field]="model.abgang.nameField" placeholder="Name"></fz-text>&nbsp;
      <fz-dropdown class="Schuelername" [isShadow]="true" [field]="model.abgang.geschlechtField"></fz-dropdown>
    </div>
    <div>
      <fz-label>geboren am </fz-label
      ><fz-date [field]="model.abgang.geburtsdatumField" placeholder="Geburtsdatum"></fz-date><fz-label> in </fz-label
      ><fz-text [field]="model.abgang.geburtsortField" placeholder="Geburtsort"></fz-text>
    </div>
    <div *ngIf="model.abgang.normalerUnterricht != null">
      <fz-label
        >hat am Unterricht der Jahrgangsstufe {{ model.abgang.normalerUnterricht.jahrgangsstufeString }} </fz-label
      ><fz-text [field]="model.abgang.normalerUnterricht.teilgenommenField" placeholder="der/des ..."></fz-text
      ><fz-label>
        teilgenommen, wechselt im laufenden Halbjahr die Schule und erhält deshalb folgendes Zeugnis:</fz-label
      >
    </div>
    <div *ngIf="model.abgang.intensivklasse != null">
      <fz-label>hat am Unterricht </fz-label
      ><fz-dropdown
        placeholder="Intensivklasse/Intensivkurs"
        [field]="model.abgang.intensivklasse.typField"
      ></fz-dropdown
      ><fz-label>
        teilgenommen, wechselt im laufenden Halbjahr die Schule und erhält deshalb folgendes Zeugnis:</fz-label
      >
    </div>
  </div>
</fz-panel>
