import { Component, Input } from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-unterschrift',
  templateUrl: 'sl-unterschrift.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLUnterschriftComponent)],
})
export class SLUnterschriftComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      schulleitungField: formular.zeugnissatz.schulleitung,
      klassenleitungField: formular.zeugnissatz.klassenleitung,
    };
  }

  @Input() model: ReturnType<typeof SLUnterschriftComponent.createModel> | undefined;
  override borderDirection = BorderDirection;
}
