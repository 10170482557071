import { GeistigTyp } from './enums/geistig-typ';
import { LernTyp } from './enums/lern-typ';
import { NormalTyp } from './enums/normal-typ';
import { Zeugnis } from './zeugnis';

export class NIZeugnis extends Zeugnis {
  arbeitsgemeinschaften: string | null = null;
  interessen: string | null = null;
  abgangsdatum: string | null = null;
  abgangsschuljahr: string | null = null;
  anlageLernstandAktiv: boolean = false;
  fontSizeFactorLernstand: number | null = null;
  lernstandEmpfehlungen: string | null = null;
  lernstandSprachfoerderAm: string | null = null;
  lernstandSprachfoerderart: string | null = null;
  lernstandAufnahmeAm: string | null = null;
  bezeichnungPaedagogischerMitarbeiter: string | null = null;
  geistigTyp: GeistigTyp | null = null;
  lernTyp: LernTyp | null = null;
  normalTyp: NormalTyp | null = null;
}
