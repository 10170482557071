import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FocusableDirective } from './focusable.directive';
import { FontDirective } from './font.directive';
import { PortalOutletDirective } from './portal-outlet.directive';
import { PortalDirective } from './portal.directive';
import { PositionDirective } from './position.directive';
import { RectDirective } from './rect.directive';
import { RefDirective } from './ref.directive';
import { RichTextDirective } from './rich-text-directive';
import { SizeDirective } from './size.directive';
import { CheckboxModule } from 'primeng/checkbox';
import { HighlightedRichTextDirective } from './highlighted-rich-text-directive';
import { TemplateDirective } from './template.directive';

@NgModule({
  imports: [ButtonModule, DialogModule, CheckboxModule, FormsModule],
  exports: [
    RefDirective,
    FontDirective,
    PositionDirective,
    SizeDirective,
    RectDirective,
    FocusableDirective,
    PortalDirective,
    PortalOutletDirective,
    RichTextDirective,
    HighlightedRichTextDirective,
    TemplateDirective,
  ],
  declarations: [
    RefDirective,
    FontDirective,
    PositionDirective,
    SizeDirective,
    RectDirective,
    FocusableDirective,
    PortalDirective,
    PortalOutletDirective,
    RichTextDirective,
    HighlightedRichTextDirective,
    TemplateDirective,
  ],
  providers: [],
})
export class SharedModule {}
