import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { SLFormular } from './sl-formular';
import { SLFolgeseiteComponent } from '../projectables/sl-folgeseite.component';
import { SLSeitenfussComponent } from '../projectables/sl-seitenfuss.component';
import { SLFormularCategoryKey } from '../sl-formularsatz';
import { FormularViewType } from '../../formular/formular';
import { SLSchuelerComponent } from '../projectables/sl-schueler.component';
import { SLSchulortComponent } from '../projectables/sl-schulort.component';
import { SLUnterschriftComponent } from '../projectables/sl-unterschrift.component';

export class SL1234hjFreitextFormular extends SLFormular {
  get category(): SLFormularCategoryKey {
    return 'anlage';
  }
  get viewType(): FormularViewType {
    return SL1234hjFreitextFormularComponent;
  }
}

@Component({
  selector: 'fz-sl-1234hj-freitext-formular',
  templateUrl: './sl-1234hj-freitext-formular.component.html',
})
export class SL1234hjFreitextFormularComponent extends FormularComponentBase<SL1234hjFreitextFormular> {
  static createModel(formular: SL1234hjFreitextFormular) {
    return formular.zeugnis.anlageFreitextAktiv
      ? {
          schueler: SLSchuelerComponent.createModel(formular),
          anlageFreitextField: formular.anlageFreitext,
          schulort: SLSchulortComponent.createModel(formular),
          unterschrift: SLUnterschriftComponent.createModel(formular),
        }
      : undefined;
  }

  get model() {
    return SL1234hjFreitextFormularComponent.createModel(this.formular);
  }

  slFolgeseiteComponent = SLFolgeseiteComponent;
  slSeitenfussComponent = SLSeitenfussComponent;
  constructor(formularService: FormularService<SL1234hjFreitextFormular>) {
    super(formularService);
  }
}
