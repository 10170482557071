<fz-panel *ngIf="model != null">
  <div>
    <fz-label class="Zeugnisausgabe_Ort">{{ formular.zeugnissatz.zeugniskopflizenz.schulort }}, den </fz-label>
    <fz-linked-date
      class="Zeugnisausgabe_Datum"
      fieldName="zeugnisausgabedatum"
      [field]="model.zeugnisausgabedatumField"
      [linkedField]="formular.zeugnissatz.zeugnisausgabedatum"
    ></fz-linked-date>
  </div>
  <div
    *ngIf="formular.zeugnis.zeugnisTyp !== 'Abgang'"
    style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-template-rows: 38px auto; column-gap: 38px"
  >
    <fz-panel style="grid-row: 1; grid-column: 1" [border]="borderDirection.bottom"></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 1; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.klassenleitungField"
      placeholder="Bezeichnung Klassenleitung"
    ></fz-text>
    <fz-panel style="grid-row: 1; grid-column: 2" [border]="borderDirection.bottom"></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 2; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.schulleitungField"
      placeholder="Bezeichnung Schulleitung"
    ></fz-text>
    <fz-panel style="grid-row: 1; grid-column: 3" [border]="borderDirection.bottom"></fz-panel>
    <fz-label style="grid-row: 2; grid-column: 3; justify-self: center" class="Unterschriften_Beschriftung"
      >Gesehen (Erziehungsberechtigte(r))</fz-label
    >
  </div>
  <div
    *ngIf="formular.zeugnis.zeugnisTyp === 'Abgang'"
    style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-template-rows: 38px auto; column-gap: 38px"
  >
    <fz-panel style="grid-row: 1; grid-column: 1" [border]="borderDirection.bottom"></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 1; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.klassenleitungField"
      placeholder="Bezeichnung Klassenleitung"
    ></fz-text>
    <div style="grid-row: 1; grid-column: 2; display: grid; justify-content: center; align-content: center">
      <fz-ni-siegel style="grid-row: 1; grid-column: 1; width: 76px; height: 76px"></fz-ni-siegel>
    </div>
    <fz-panel style="grid-row: 1; grid-column: 3" [border]="borderDirection.bottom"></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 3; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.schulleitungField"
      placeholder="Bezeichnung Schulleitung"
    ></fz-text>
  </div>
</fz-panel>
