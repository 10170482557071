<fz-panel *ngIf="model != null">
  <div class="Ueberschriften2"><fz-label>Pflichtunterricht</fz-label></div>
  <div style="margin-top: 8px; display: grid; grid-template-columns: 1fr 1fr; column-gap: 38px">
    <div
      style="grid-column: 1; display: grid; grid-template-columns: 1fr 151px; grid-auto-rows: min-content; row-gap: 8px"
    >
      <div class="Noten_Beschriftung">
        <fz-dropdown [field]="model.religion.labelField"></fz-dropdown>
      </div>
      <div class="Noten_Text">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.religion.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.deutsch.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.deutsch.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.sachunterricht.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.sachunterricht.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.mathematik.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.mathematik.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
    <div
      style="grid-column: 2; display: grid; grid-template-columns: 1fr 151px; grid-auto-rows: min-content; row-gap: 8px"
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.bildendeKunst.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.bildendeKunst.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.musik.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.musik.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung"><fz-dropdown [field]="model.sport.labelField"></fz-dropdown></div>
      <div class="Noten_Text">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.sport.noteField"></fz-note>
        </fz-panel>
      </div>
      <div *ngIf="model.fremdsprache != null">
        <div class="Noten_Beschriftung">
          <fz-linked-text
            [field]="model.fremdsprache.labelField"
            [linkedField]="formular.zeugnissatz.fremdspracheBezeichnung"
            (copyToAll)="copyFremdspracheLabelToAll($event)"
          ></fz-linked-text
          ><fz-label>**)</fz-label>
        </div>
        <div class="Fusstext"><fz-label>(Einführung in eine Fremdsprache)</fz-label></div>
      </div>
      <div class="Noten_Text" *ngIf="model.fremdsprache != null">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.fremdsprache.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
  </div>
</fz-panel>
