import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NIPanelDecoratorBase } from './ni-panel-decorator-base';
import { NIFormular } from '../formulare/ni-formular';

@Component({
  selector: 'fz-ni-zeugnistyp',
  templateUrl: 'ni-zeugnistyp.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, NIZeugnistypComponent)],
})
export class NIZeugnistypComponent extends NIPanelDecoratorBase {
  static createModel(formular: NIFormular) {
    return {
      dropdownField: formular.category === 'zeugnis' ? formular.zeugnisTyp : undefined,
    };
  }

  @Input() model: ReturnType<typeof NIZeugnistypComponent.createModel> | undefined;
}
