import { Halbjahr } from './enums/halbjahr';
import { ZeugniskopflizenzTyp } from './enums/zeugniskopflizenz-typ';
import { StyleDefinition } from './generated/style-definition';
import { Metadata } from './metadata';
import { Schullizenz } from './schullizenz';
import { Zeugniskopflizenz as ZeugniskopflizenzDto } from './generated/zeugniskopflizenz';

export class Zeugniskopflizenz extends Metadata {
  schuljahr: number = 0;
  halbjahr: Halbjahr = 0;
  schulname: string = '';
  schulort: string = '';
  schuladresse: string = '';
  zeugniskopf: string = '';
  type: ZeugniskopflizenzTyp = 'Demo';
  hasKeineTeilkompetenzen: boolean = false;
  isBilingual: boolean = false;
  isSLSprachfoerderschule: boolean = false;
  slSchuleingangsphaseVerbal: boolean = false;
  versteckeTextRahmen: boolean = false;
  isBlocksatz: boolean = false;
  signatur: string = '';
  rahmen: boolean = false;
  suppressBackgroundNote: boolean = false;
  noteInZiffern: boolean = false;
  isBWA4: boolean = false;
  isCloudDeactivated: boolean = false;
  isHE4JNotenAusgeschrieben: boolean = false;
  styles: StyleDefinition[] = [];
  mergedStyles: StyleDefinition[] = [];

  constructor(
    public readonly id: string,
    public readonly schullizenz: Schullizenz
  ) {
    super();
    schullizenz.zeugniskopflizenzen.push(this);
  }

  static override cleanDto(dto: any): any {
    const { id, schullizenz, ...cleanDto } = Metadata.cleanDto(dto);
    return cleanDto;
  }

  static override toDto(zeugniskopflizenz: Zeugniskopflizenz): ZeugniskopflizenzDto {
    return {
      ...Metadata.toDto(zeugniskopflizenz),
      ...this.cleanDto(zeugniskopflizenz),
      id: zeugniskopflizenz.id,
      kundennummer: zeugniskopflizenz.schullizenz.kundennummer,
    };
  }

  static fromDto(dto: ZeugniskopflizenzDto, schullizenz: Schullizenz): Zeugniskopflizenz {
    const zeugniskopflizenz = new Zeugniskopflizenz(dto.id, schullizenz);
    Metadata.assignDto(zeugniskopflizenz, dto);
    return Object.assign(zeugniskopflizenz, this.cleanDto(dto));
  }
}
