import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';

@Component({
  selector: 'fz-sl-seitenfuss',
  templateUrl: 'sl-seitenfuss.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, SLSeitenfussComponent)],
})
export class SLSeitenfussComponent extends SLPanelDecoratorBase {
  @Input() pageIndex = 0;
}
