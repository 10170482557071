import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

export type Size = {
  width: number;
  height: number;
};

@Directive({ selector: '[fzRect]' })
export class RectDirective implements OnChanges {
  @Input('fzRect') rect: DOMRect | undefined;
  constructor(private elementRef: ElementRef<HTMLElement>) {}
  ngOnChanges(): void {
    if (this.rect != null) {
      this.elementRef.nativeElement.style.position = 'absolute';
      this.elementRef.nativeElement.style.left = `${this.rect.left}px`;
      this.elementRef.nativeElement.style.top = `${this.rect.top}px`;
      this.elementRef.nativeElement.style.width = `${this.rect.width}px`;
      this.elementRef.nativeElement.style.height = `${this.rect.height}px`;
    }
  }
}
