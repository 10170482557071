import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Formular, ZeugnissatzViewModel } from '@modules/formular/formular';
import { Fach } from '../../../../models/fach';
import { TransferFile } from '../../../../models/generated/transfer-file';
import { Jahrgang } from '../../../../models/jahrgang';
import { Schueler } from '../../../../models/schueler';
import { Zeugnis } from '../../../../models/zeugnis';
import { JahrgangFactoryService } from '../../../services/jahrgang-factory.service';
import { WorkspaceService } from '../../../services/workspace.service';
import { importModeFormValidator } from '../../../validators/import-mode-form-validator';
import { SystemActionsService } from '../../system-actions/system-actions.service';
import { ImportService } from '../import.service';
import { FormularFactory } from './../../../../modules/formular/formular-factory';

export interface Mapping {
  alt: Schueler | string;
  neu: Schueler;
  label: string;
}

export interface MappingOption {
  label: string;
  value: string | null;
}

const schuelerNeuLabel = 'Als neuen Schüler anlegen';

@Component({
  selector: 'app-import-assi-select-content',
  templateUrl: './import-assi-select-content.component.html',
  styleUrls: ['./import-assi-select-content.component.scss'],
})
export class ImportAssiSelectContentComponent implements OnChanges {
  @Input() header: TemplateRef<any> | undefined;
  @Input() transferFile: TransferFile | undefined;
  @Input() jahrgang: Jahrgang | undefined;
  @Output() importCompleted = new EventEmitter<void>();
  @Output() restart = new EventEmitter<void>();

  jahrgangNeu = false;
  jahrgangMismatch = true;
  faecher: Fach[] = [];
  selectedFaecher: Fach[] = [];
  schuelers: Schueler[] = [];
  selectedSchuelers: Schueler[] = [];

  importmpodeform: UntypedFormGroup;
  ueberschreibenBestaetigungControl: UntypedFormControl;
  appendBeurteilungenControl: UntypedFormControl;
  mappingOptions: MappingOption[] = [];
  allMappingOptions: MappingOption[] = [];
  unmatchedOldZeugnis: Zeugnis[] = [];
  schuelerZuordnungen: Mapping[] = [];
  formular?: Formular;
  zeugnissatzViewModelAlt?: ZeugnissatzViewModel;
  zeugnissatzViewModelNeu?: ZeugnissatzViewModel;
  loading: boolean = false;

  appendText = '';

  constructor(
    private importService: ImportService,
    private workspaceService: WorkspaceService,
    private jahrgangFactory: JahrgangFactoryService,
    public systemActionService: SystemActionsService
  ) {
    this.ueberschreibenBestaetigungControl = new UntypedFormControl(false);
    this.appendBeurteilungenControl = new UntypedFormControl(false);
    this.importmpodeform = new UntypedFormGroup(
      {
        appendBeurteilungen: this.appendBeurteilungenControl,
        faecher: new UntypedFormControl(''),
        importMode: new UntypedFormControl('', Validators.required),
        ueberschreibenBestaetigung: this.ueberschreibenBestaetigungControl,
      },
      { validators: importModeFormValidator(this.jahrgangNeu) }
    );
  }

  fachNotContained(faecher: Fach[], fach: Fach): boolean {
    if (fach.key !== 'regelfach' && fach.key !== 'freiwilligesFach') {
      return faecher.filter((filterfach) => fach.key === filterfach.key).length === 0;
    } else {
      return (
        faecher.filter(
          // TODO: Abfrage überarbeiten
          (filterfach) =>
            fach.key === filterfach.key &&
            Array.from(fach.bereiche)[0].label === Array.from(filterfach.bereiche)[0].label
        ).length === 0
      );
    }
  }
  async ngOnChanges(/*changes: SimpleChanges*/): Promise<void> {
    if (this.jahrgang != null) {
      this.appendText =
        '>>>>>>>>> ANGEHÄNGT aus ' +
        this.transferFile?.fileName +
        ' am ' +
        new Date().getDate() +
        '.' +
        new Date().getMonth() +
        '.' +
        new Date().getFullYear() +
        ' ' +
        new Date().getHours() +
        ':' +
        new Date().getMinutes() +
        'Uhr: ';
      this.jahrgangNeu = !(await this.importService.jahrgangExists(this.jahrgang));
      this.importmpodeform.clearValidators();
      this.importmpodeform.setValidators(importModeFormValidator(this.jahrgangNeu));
      this.importmpodeform.updateValueAndValidity();

      this.jahrgangMismatch =
        this.jahrgang.currentZeugnissatz == null ||
        this.workspaceService.jahrgang?.currentZeugnissatz == null ||
        !this.workspaceService.jahrgang.currentZeugnissatz.canMerge(this.jahrgang.currentZeugnissatz);

      if (!this.jahrgangMismatch) {
        // fächer finden die anzuzeigen sind
        const faecher: Fach[] = [];
        for (const zeugnis of this.jahrgang.currentZeugnissatz?.zeugnisse ?? []) {
          for (const fach of zeugnis.faecher) {
            for (const bereich of fach.bereiche) {
              if (
                (bereich.note != null ||
                  (bereich.text ?? '') !== '' ||
                  (bereich.zusatztext ?? '') !== '' ||
                  bereich.items.hasBenotungen) &&
                this.fachNotContained(faecher, fach)
              ) {
                // TODO: Hat keine Auswirkungen mehr, da Array nicht mit Model verknüpft
                faecher.push(fach);
              }
            }
          }
        }

        // zeugnisse ermitteln, die im import nicht vorkommen
        this.mappingOptions = [{ label: schuelerNeuLabel, value: null }];

        for (const zeugnisAlt of this.workspaceService.jahrgang?.currentZeugnissatz?.zeugnisse ?? []) {
          const zeugnisNeu = (this.jahrgang.currentZeugnissatz?.zeugnisse ?? []).find(
            (z) =>
              z.schuelerName === zeugnisAlt.schuelerName &&
              z.schuelerVorname === zeugnisAlt.schuelerVorname &&
              ((zeugnisAlt.schueler.geburtsdatum == null && z.schueler.geburtsdatum != null) ||
                (zeugnisAlt.schueler.geburtsdatum != null && z.schueler.geburtsdatum == null) ||
                zeugnisAlt.schueler.geburtsdatum === z.schueler.geburtsdatum)
          );
          if (zeugnisNeu == null) {
            this.unmatchedOldZeugnis.push(zeugnisAlt);
            this.mappingOptions.push({
              label: zeugnisAlt.schuelerVorname + ' ' + zeugnisAlt.schuelerName,
              value: zeugnisAlt.schueler.id,
            });
          }
        }

        const zs = this.workspaceService.jahrgang?.currentZeugnissatz?.zeugnisse[0];
        if (zs) {
          this.formular = FormularFactory.getFormularsatz(zs).createFormular(zs, 'zeugnis');
        }

        this.schuelers = (this.jahrgang.currentZeugnissatz?.zeugnisse ?? []).map((z) => z.schueler);
        this.faecher = faecher;
        this.allMappingOptions = this.mappingOptions;
        if (this.workspaceService.jahrgang?.currentZeugnissatz != null) {
          this.zeugnissatzViewModelAlt = new ZeugnissatzViewModel(this.workspaceService.jahrgang.currentZeugnissatz);
        }
        if (this.jahrgang.currentZeugnissatz != null) {
          this.zeugnissatzViewModelNeu = new ZeugnissatzViewModel(this.jahrgang.currentZeugnissatz);
        }
      }
    }
  }

  async importSelected() {
    if (this.importmpodeform.value.importMode === 'komplett' && this.jahrgang != null) {
      try {
        this.loading = true;
        this.workspaceService.close();
        await this.importService.overrideZeugnisse(this.jahrgang);
        this.workspaceService.open(this.jahrgang.id);
        this.importCompleted.next();
      } finally {
        this.loading = false;
      }
    } else {
      if (this.jahrgang?.currentZeugnissatz != null && this.workspaceService.jahrgang?.currentZeugnissatz != null) {
        try {
          this.loading = true;

          const zeugnisseToSave = this.jahrgang.currentZeugnissatz.zeugnisse.filter((z) =>
            this.selectedSchuelers.includes(z.schueler)
          );
          if (zeugnisseToSave) {
            // über alte zeugnisse iterieren und werte aus den neuen übernehmen
            this.workspaceService.jahrgang.currentZeugnissatz.zeugnisse.forEach((zeugnisAlt: any) => {
              let zeugnisNeu = zeugnisseToSave.find(
                (z) =>
                  z.schuelerName === zeugnisAlt.schuelerName &&
                  z.schuelerVorname === zeugnisAlt.schuelerVorname &&
                  ((zeugnisAlt.schueler.geburtsdatum == null && z.schueler.geburtsdatum != null) ||
                    (zeugnisAlt.schueler.geburtsdatum != null && z.schueler.geburtsdatum == null) ||
                    zeugnisAlt.schueler.geburtsdatum === z.schueler.geburtsdatum)
              );
              if (!zeugnisNeu) {
                // prüfen ob es ein mapping gibt
                const mapping = this.schuelerZuordnungen.find((zuordnung) => zuordnung.alt === zeugnisAlt.schueler);
                if (mapping != null) {
                  // mapping existiert, dann zeugnis finden
                  zeugnisNeu = zeugnisseToSave.find((z) => z.schueler === mapping.neu);
                }
              }
              if (zeugnisNeu) {
                for (const fachToReplace of this.selectedFaecher) {
                  const fachListAlt = zeugnisAlt.faecher.getItemList(fachToReplace.key);
                  const fachListNeu = zeugnisNeu.faecher.getItemList(fachToReplace.key);
                  for (const fachNeu of fachListNeu) {
                    const fachAlt = fachListAlt.getIndex(fachNeu.index);
                    fachAlt.merge(fachNeu, this.appendBeurteilungenControl.value, this.appendText);

                    // Sonderfall: Fremdsprachenbezeichnung übernehmen
                    if (
                      fachToReplace.key === 'fremdsprache' &&
                      zeugnisNeu.zeugnissatz.fremdspracheBezeichnung != null &&
                      zeugnisNeu.zeugnissatz.fremdspracheBezeichnung !== ''
                    ) {
                      zeugnisAlt.zeugnissatz.fremdspracheBezeichnung = zeugnisNeu.zeugnissatz.fremdspracheBezeichnung;
                    }
                  }
                }
              }
            });
          }
          const currentZeugnissatz = this.workspaceService.jahrgang.currentZeugnissatz;
          for (const zuordnung of this.schuelerZuordnungen.filter((z) => z.label === schuelerNeuLabel)) {
            const schueler = await this.jahrgangFactory.createSchueler(this.workspaceService.jahrgang);
            Schueler.assign(schueler, zuordnung.neu);
            const zeugnis = await this.jahrgangFactory.createZeugnis(schueler, currentZeugnissatz);
            const zeugnisSrc = zeugnisseToSave.find((z) => z.schueler === zuordnung.neu);
            if (zeugnisSrc != null) Zeugnis.assign(zeugnis, zeugnisSrc);
          }
          await this.workspaceService.saveSelectedZeugnissatz();
          this.importCompleted.next();
        } finally {
          this.loading = false;
        }
      }
    }
  }

  firstStep(): void {
    this.restart.next();
  }

  isSelectedAndNotMatched(schueler: Schueler): boolean {
    const zeugnisNeu = this.workspaceService.jahrgang?.currentZeugnissatz?.zeugnisse.find(
      (z: any) =>
        z.schuelerName === schueler.name &&
        z.schuelerVorname === schueler.vorname &&
        ((schueler.geburtsdatum == null && z.schueler.geburtsdatum != null) ||
          (schueler.geburtsdatum != null && z.schueler.geburtsdatum == null) ||
          schueler.geburtsdatum === z.schueler.geburtsdatum)
    );

    return this.selectedSchuelers.includes(schueler) && zeugnisNeu == null;
  }

  schuelerZuordnungChanged(schueler: Schueler, option: MappingOption): void {
    const alt = this.workspaceService.jahrgang?.schuelers?.find((s) => s.id === option.value);
    if (option.label !== schuelerNeuLabel) {
      this.mappingOptions = this.mappingOptions.filter((m) => m.label !== option.label);
    } else {
      this.schuelerZuordnungen.push({ alt: schuelerNeuLabel, neu: schueler, label: option.label });
    }
    if (alt != null && this.schuelerZuordnungen.filter((z) => z.alt === alt).length === 0) {
      const mapping = { alt, neu: schueler, label: option.label };
      this.schuelerZuordnungen.push(mapping);
    }
  }
  resetZuordnung(schueler: Schueler): void {
    const zuordnungAlt = this.schuelerZuordnungen.find((z) => z.neu === schueler);
    this.schuelerZuordnungen = this.schuelerZuordnungen.filter((z) => z.neu !== schueler);
    if (zuordnungAlt && zuordnungAlt.label !== schuelerNeuLabel) {
      const mappingOption: MappingOption = { value: (zuordnungAlt.alt as Schueler).id, label: zuordnungAlt.label };
      this.mappingOptions.push(mappingOption);
    }
  }

  getMatchingOption(schueler: Schueler): string {
    return this.schuelerZuordnungen.find((z) => z.neu === schueler)?.label ?? '';
  }

  filterAvailable(): void {
    if (this.jahrgang != null) {
      const schuelers: Schueler[] = [];
      const fachKeys = this.selectedFaecher.map((f) => f.key);
      for (const zeugnis of this.jahrgang.currentZeugnissatz?.zeugnisse ?? []) {
        for (const fach of zeugnis.faecher) {
          for (const bereich of fach.bereiche) {
            if (
              (bereich.note != null ||
                (bereich.text ?? '') !== '' ||
                (bereich.zusatztext ?? '') !== '' ||
                bereich.items.hasBenotungen) &&
              fachKeys.includes(fach.key) &&
              !schuelers.includes(zeugnis.schueler)
            ) {
              schuelers.push(zeugnis.schueler);
            }
          }
        }
      }
      this.selectedSchuelers = schuelers;
      this.schuelers =
        (this.jahrgang.currentZeugnissatz?.zeugnisse ?? [])
          .map((z) => z.schueler)
          .filter((s) => !this.selectedSchuelers.includes(s)) ?? [];
    }
  }

  filterZuordnungen(event: any): void {
    if (Array.isArray(event.items)) {
      event.items.forEach((s: Schueler) => this.resetZuordnung(s));
    } else {
      this.resetZuordnung(event.items);
    }
  }

  allUnmatchedAssigned(): boolean {
    let result = true;
    this.selectedSchuelers.forEach((s) => {
      if (this.isSelectedAndNotMatched(s) && this.getMatchingOption(s) === '') {
        result = false;
      }
    });
    return result;
  }

  private hasFachNote(zeugnis: Zeugnis, fachKey: string | null): boolean {
    let result = false;
    const fach = zeugnis.faecher.getItem(fachKey);
    if (fach) {
      Array.from(fach.bereiche).forEach((b) => {
        if (b.note != null || (b.text != null && b.text !== '')) {
          result = true;
        }
      });
    }
    return result;
  }

  getFachLabel(fach: Fach): string {
    if (this.formular) {
      const fachViewModel = (this.formular as any)[fach.key ?? ''];
      if (fachViewModel != null) {
        return fachViewModel.description.label ?? fach.key;
      } else {
        if (fach.key === 'regelfach') {
          return Array.from(fach.bereiche)[0].label ?? fach.key;
        }
        if (fach.key === 'freiwilligesFach') {
          return Array.from(fach.bereiche)[0].label ?? fach.key;
        }
        return fach.key ?? '';
      }
    }
    return fach.key ?? '';
  }
}
