<ng-template fzTemplate="sidebar">
  <fz-rp-schueler-typ></fz-rp-schueler-typ>
  <fz-rp-herkunftssprache></fz-rp-herkunftssprache>
  <fz-rp-abgangszeugnis></fz-rp-abgangszeugnis>
</ng-template>
<fz-formular
  name="RP1jVerbal"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  <fz-rp-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-rp-zeugniskopf>
  <fz-rp-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-rp-zeugnissatz>
  <fz-rp-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-rp-schueler>
  <fz-deco
    fzFormularItem="sps"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
    *ngIf="model.sps != null"
  >
    <fz-rp-sps fzContainer [model]="model.sps"></fz-rp-sps>
  </fz-deco>
  <fz-section
    fzFormularItem="sozialLernArbeitsverhalten"
    [label]="model.sozialLernArbeitsverhalten.headerLabel"
    headerClass="Ueberschriften2"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    headerPaddingBottom="none"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
      paddingBottom="normal"
    >
      <fz-verbal
        [field]="model.sozialLernArbeitsverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-deco>
  </fz-section>
  <ng-container *ngFor="let fach of model.faecher; trackBy: trackByProperty">
    <fz-section [fzFormularItem]="fach.key" [label]="fach.headerLabel">
      <fz-panel
        fzSectionHeader
        [border]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingBottom="none"
      >
        <fz-rp-fach-header [model]="fach.header"></fz-rp-fach-header>
      </fz-panel>
      <fz-panel
        fzSectionHeader
        [border]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingBottom="none"
        class="Ueberschriften2"
      >
        <fz-label>{{ fach.header.labelFortsetzung }} (Fortsetzung)</fz-label>
      </fz-panel>
      <fz-sequence>
        <fz-deco
          [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
          [padding]="formular.showBorder ? 'normal' : 'none'"
          paddingTop="normal"
          paddingBottom="normal"
        >
          <fz-verbal
            [field]="fach.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-deco>
      </fz-sequence>
    </fz-section>
  </ng-container>
  <fz-deco
    fzFormularItem="sprachenportfolio"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-rp-sprachenportfolio fzContainer [model]="model.sprachenportfolio"></fz-rp-sprachenportfolio>
  </fz-deco>
  <fz-rp-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-rp-versaeumnis>
  <fz-section fzFormularItem="bemerkungen" [label]="model.bemerkungen.headerLabel">
    <fz-panel
      fzSectionHeader
      class="Ueberschriften2"
      [border]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingBottom="none"
    >
      <fz-label>{{ model.bemerkungen.headerLabel }}</fz-label>
    </fz-panel>
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
      paddingBottom="normal"
    >
      <fz-intelli
        [field]="model.bemerkungen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-rp-schulort fzFormularItem="schulort" fzContainer [model]="model.schulort"></fz-rp-schulort>
  <fz-rp-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-rp-unterschrift>
  <fz-rp-kenntnis fzFormularItem="kenntnis" fzContainer></fz-rp-kenntnis>
</fz-formular>
