import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

export type Position = {
  left: number;
  top: number;
};

@Directive({ selector: '[fzPosition]' })
export class PositionDirective implements OnChanges {
  @Input('fzPosition') position: Position | undefined;
  constructor(private elementRef: ElementRef<HTMLElement>) {}
  ngOnChanges(): void {
    if (this.position != null) {
      this.elementRef.nativeElement.style.position = 'absolute';
      this.elementRef.nativeElement.style.left = `${this.position.left}px`;
      this.elementRef.nativeElement.style.top = `${this.position.top}px`;
    }
  }
}
