import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KoennensprofilComponent } from './koennensprofil.component';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabMenuModule } from 'primeng/tabmenu';
import { PanelModule } from 'primeng/panel';

import { AccordionModule } from 'primeng/accordion';
import { KpBestaetigungComponent } from './kp-setup/kp-setup.component';
import { KpEditorComponent } from './kp-editor/kp-editor.component';
import { PickListModule } from 'primeng/picklist';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { FormularModule } from '@modules/formular/formular.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { OrderListModule } from 'primeng/orderlist';
import { ListboxModule } from 'primeng/listbox';
import { StepsModule } from 'primeng/steps';

@NgModule({
  declarations: [KoennensprofilComponent, KpBestaetigungComponent, KpEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    PanelModule,
    TabMenuModule,
    SelectButtonModule,
    AccordionModule,
    RadioButtonModule,
    PickListModule,
    DropdownModule,
    ScrollPanelModule,
    SkeletonModule,
    MultiSelectModule,
    OverlayPanelModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    DialogModule,
    CheckboxModule,
    SplitButtonModule,
    TableModule,
    FormularModule,
    ProgressBarModule,
    ToolbarModule,
    TreeModule,
    OrderListModule,
    ListboxModule,
    StepsModule,
  ],
  exports: [KoennensprofilComponent],
})
export class KoennensprofilModule {}
