import { Type } from '@angular/core';
import { FormularCategory, Formularsatz, HalbjahrDescription } from '@modules/formular/formularsatz';
import { HEFormular } from './formulare/he-formular';
import { HE1234hjIntensivFormular } from './formulare/he-1234hj-intensiv-formular.component';
import { HE123hjFlexFormular } from './formulare/he-123hj-flex-formular.component';
import { HE1234hjLernFormular } from './formulare/he-1234hj-lern-formular.component';
import { HE1234hjGeistigFormular } from './formulare/he-1234hj-geistig-formular.component';
import { HE234jStandardFormular } from './formulare/he-234j-standard-formular.component';
import { HE234hjFreitextFormular } from './formulare/he-234hj-freitext-formular.component';
import { HE234hjKPFormular } from './formulare/he-234hj-kp-formular.component';
import HEFachKey from '../../models/generated/he-fach-key';
import { HE234hjKeineanlageFormular } from './formulare/he-234hj-keineanlage-formular.component';
import { BundeslandTyp } from '../../models/enums/bundesland-typ';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { HEZeugnis } from '../../models/he-zeugnis';
import { Zeugnis } from '../../models/zeugnis';
import { NoteCandidateDescription } from '../../models/fields/note-field';
import { HE234hjSavFormular } from './formulare/he-234hj-sav-formular.component';

export type HEFormularCategoryKey = 'zeugnis' | 'anlageDeutsch' | 'anlageSav';

export class HEFormularsatz extends Formularsatz {
  get zeugnisType(): Type<Zeugnis> {
    return HEZeugnis;
  }

  get bundesland(): BundeslandTyp {
    return 'HE';
  }
  getAllHalbjahre(schuljahr: number): HalbjahrDescription[] {
    return [
      {
        text: 'Entwicklungsbericht / Vorklasse',
        klassenstufe: 0,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '1. Klasse, Jahreszeugnis',
        klassenstufe: 1,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '2. Klasse, Jahreszeugnis',
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '3. Klasse, Halbjahreszeugnis',
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '3. Klasse, Jahreszeugnis',
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '4. Klasse, Halbjahreszeugnis',
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '4. Klasse, Jahreszeugnis',
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr,
      },
    ];
  }

  getRemainingHalbjahre(zeugnissatz: Zeugnissatz): HalbjahrDescription[] {
    const halbjahre: HalbjahrDescription[] = [];
    const klassenstufeHalbjahr = zeugnissatz.klassenstufe + zeugnissatz.halbjahr / 2;
    const schuljahr = zeugnissatz.schuljahr - zeugnissatz.klassenstufe;
    if (klassenstufeHalbjahr < 1.5) {
      const newSchuljahr = schuljahr + 1;
      halbjahre.push({
        text: `1. Klasse, Jahreszeugnis ${newSchuljahr - 1}/${newSchuljahr + 0}`,
        klassenstufe: 1,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 2.5) {
      const newSchuljahr = schuljahr + 2;
      halbjahre.push({
        text: `2. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 3.0) {
      const newSchuljahr = schuljahr + 3;
      halbjahre.push({
        text: `3. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 3.5) {
      const newSchuljahr = schuljahr + 3;
      halbjahre.push({
        text: `3. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 4.0) {
      const newSchuljahr = schuljahr + 4;
      halbjahre.push({
        text: `4. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 4.5) {
      const newSchuljahr = schuljahr + 4;
      halbjahre.push({
        text: `4. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }

    return halbjahre;
  }

  getCategories(zeugnis: HEZeugnis): FormularCategory[] {
    const klassenstufe = zeugnis.zeugnissatz.klassenstufe;
    const categories: FormularCategory<HEFormularCategoryKey>[] = [];
    categories.push({ key: 'zeugnis', label: 'Zeugnis' });
    if (klassenstufe >= 2 && zeugnis.schuelerTyp == null)
      categories.push({ key: 'anlageDeutsch', label: 'Anlage Deutsch' }, { key: 'anlageSav', label: 'Anlage ASV' });
    return categories;
  }

  getFormularType(zeugnis: HEZeugnis, category: HEFormularCategoryKey): Type<HEFormular> | null {
    switch (category) {
      case 'zeugnis':
        return this.getZeugnisFormularType(zeugnis);
      case 'anlageDeutsch':
        return zeugnis.schuelerTyp != null
          ? HE234hjKeineanlageFormular
          : zeugnis.anlageTyp === 'Keine'
            ? HE234hjKeineanlageFormular
            : zeugnis.anlageTyp === 'Allgemein'
              ? HE234hjFreitextFormular
              : HE234hjKPFormular;
      case 'anlageSav':
        return HE234hjSavFormular;
      default:
        return null;
    }
  }
  getZeugnisFormularType(zeugnis: HEZeugnis): Type<HEFormular> | null {
    const klassenstufe = zeugnis.zeugnissatz.klassenstufe;
    const schuelerTyp = zeugnis.schuelerTyp;
    if (klassenstufe === 0) {
      return HE123hjFlexFormular;
    } else {
      if (schuelerTyp === 'Intensiv') return HE1234hjIntensivFormular;
      else if (schuelerTyp === 'Schuleingangsphase' && klassenstufe <= 3) return HE123hjFlexFormular;
      else if (schuelerTyp === 'Lernbehindert') return HE1234hjLernFormular;
      else if (schuelerTyp === 'Geistigbehindert') return HE1234hjGeistigFormular;
      else if (klassenstufe === 1) return HE123hjFlexFormular;
      else return HE234jStandardFormular;
    }
  }

  getNoteCandidates(zeugnis: Zeugnis, fachName: string): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    if (fachName === HEFachKey.deutschIntensiv) {
      candidates.push(
        { displayStringShort: 'A1', content: 'A1', value: 1 },
        { displayStringShort: 'A2', content: 'A2', value: 2 },
        { displayStringShort: 'B1', content: 'B1', value: 3 },
        { displayStringShort: 'B2', content: 'B2', value: 4 },
        { displayStringShort: 'C1', content: 'C1', value: 5 },
        { displayStringShort: 'C2', content: 'C2', value: 6 }
      );
      candidates.push(
        { displayStringShort: 'm.g.E.tg.', content: 'm.g.E.tg.', value: 'Befreit' },
        { displayStringShort: 'm.E.tg.', content: 'm.E.tg.', value: 'NichtFeststellbar' },
        { displayStringShort: 'tg.', content: 'tg.', value: 'Teilgenommen' },
        { displayStringShort: '/', content: '/', value: 'Slash' },
        { displayStringShort: 'entfällt', content: 'entfällt', value: 'Fachstreichung' }
      );
    } else if (fachName === HEFachKey.regelfach || fachName === HEFachKey.freiwilligesFach) {
      if (zeugnis.zeugnisTyp === 'Abgang') {
        candidates.push(
          { displayStringShort: '1', content: 'sehr gut', value: 1 },
          { displayStringShort: '2', content: 'gut', value: 2 },
          { displayStringShort: '3', content: 'befriedigend', value: 3 },
          { displayStringShort: '4', content: 'ausreichend', value: 4 },
          { displayStringShort: '5', content: 'mangelhaft', value: 5 },
          { displayStringShort: '6', content: 'ungenügend', value: 6 }
        );
      } else {
        candidates.push(
          { displayStringShort: '1', content: '1', value: 1 },
          { displayStringShort: '2', content: '2', value: 2 },
          { displayStringShort: '3', content: '3', value: 3 },
          { displayStringShort: '4', content: '4', value: 4 },
          { displayStringShort: '5', content: '5', value: 5 },
          { displayStringShort: '6', content: '6', value: 6 }
        );
      }
      candidates.push(
        { displayStringShort: 'm.g.E.tg.', content: 'm.g.E.tg.', value: 'Befreit' },
        { displayStringShort: 'm.E.tg.', content: 'm.E.tg.', value: 'NichtFeststellbar' },
        { displayStringShort: 'tg.', content: 'tg.', value: 'Teilgenommen' },
        { displayStringShort: '/', content: '/', value: 'Slash' }
      );
    } else if (fachName === HEFachKey.herkunftssprache) {
      candidates.push(
        { displayStringShort: 'm.g.E.tg.', content: 'm.g.E.tg.', value: 1 },
        { displayStringShort: 'm.E.tg.', content: 'm.E.tg.', value: 2 },
        { displayStringShort: 'tg.', content: 'tg.', value: 3 }
      );
      candidates.push({ displayStringShort: '/', content: '/', value: 'Slash' });
    } else {
      if (
        zeugnis.zeugnisTyp === 'Abgang' ||
        (zeugnis.zeugnisTyp === null &&
          zeugnis.zeugnissatz.klassenstufe === 4 &&
          zeugnis.zeugnissatz.halbjahr === 1 &&
          zeugnis.zeugnissatz.zeugniskopflizenz.isHE4JNotenAusgeschrieben)
      )
        candidates.push(
          { displayStringShort: '1', content: 'sehr gut', value: 1 },
          { displayStringShort: '2', content: 'gut', value: 2 },
          { displayStringShort: '3', content: 'befriedigend', value: 3 },
          { displayStringShort: '4', content: 'ausreichend', value: 4 },
          { displayStringShort: '5', content: 'mangelhaft', value: 5 },
          { displayStringShort: '6', content: 'ungenügend', value: 6 }
        );
      else {
        candidates.push(
          { displayStringShort: '1', content: '1', value: 1 },
          { displayStringShort: '2', content: '2', value: 2 },
          { displayStringShort: '3', content: '3', value: 3 },
          { displayStringShort: '4', content: '4', value: 4 },
          { displayStringShort: '5', content: '5', value: 5 },
          { displayStringShort: '6', content: '6', value: 6 }
        );
      }
      candidates.push(
        { displayStringShort: 'b', content: 'befreit', value: 'Befreit' },
        { displayStringShort: 'n.f.', content: 'nicht feststellbar', value: 'NichtFeststellbar' },
        { displayStringShort: '/', content: '/', value: 'Slash' }
      );
    }
    return candidates;
  }

  getNoteSuffixCandidates(): string[] {
    return [' (s.Bem.)', '¹', '²', '³'];
  }

  getKopfnoteCandidates(zeugnis: Zeugnis, _fachName: string): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    if (
      zeugnis.zeugnisTyp === 'Abgang' ||
      (zeugnis.zeugnisTyp === null &&
        zeugnis.zeugnissatz.klassenstufe === 4 &&
        zeugnis.zeugnissatz.halbjahr === 1 &&
        zeugnis.zeugnissatz.zeugniskopflizenz.isHE4JNotenAusgeschrieben)
    )
      candidates.push(
        { displayStringShort: '1', content: 'sehr gut', value: 1 },
        { displayStringShort: '2', content: 'gut', value: 2 },
        { displayStringShort: '3', content: 'befriedigend', value: 3 },
        { displayStringShort: '4', content: 'ausreichend', value: 4 },
        { displayStringShort: '5', content: 'mangelhaft', value: 5 },
        { displayStringShort: '6', content: 'ungenügend', value: 6 }
      );
    else {
      candidates.push(
        { displayStringShort: '1', content: '1', value: 1 },
        { displayStringShort: '2', content: '2', value: 2 },
        { displayStringShort: '3', content: '3', value: 3 },
        { displayStringShort: '4', content: '4', value: 4 },
        { displayStringShort: '5', content: '5', value: 5 },
        { displayStringShort: '6', content: '6', value: 6 }
      );
    }
    candidates.push(
      { displayStringShort: 'n.f.', content: 'nicht feststellbar', value: 'NichtFeststellbar' },
      { displayStringShort: '/', content: '/', value: 'Slash' }
    );
    return candidates;
  }

  getKopfnoteSuffixCandidates(): string[] {
    return [' (s.Bem.)', '¹', '²', '³'];
  }

  override getDefaultKPHeaderLabels(_zeugnissatz: Zeugnissatz): string[][] {
    return [
      ['Das kannst', 'du sehr', 'sicher'],
      ['Das', 'kannst', 'du sicher'],
      ['Das kannst', 'du', 'teilweise'],
      ['Das musst', 'du noch', 'lernen'],
    ];
  }
}
