<ngx-loading-bar [fixed]="true" [color]="'green'" [includeSpinner]="false" height="5px"></ngx-loading-bar>
<ng-container *ngIf="isLoggedInPage(); else notAuthorized">
  <div class="flex flex-col h-full w-full">
    <fz-header class="border-b-2 border-gray-200 w-full"> </fz-header>

    <router-outlet></router-outlet>
  </div>
  <fz-navigate-to-zeugnis></fz-navigate-to-zeugnis>
</ng-container>

<ng-template #notAuthorized>
  <fz-system-actions></fz-system-actions>
  <div class="h-full w-full absolute" style="background-image: url('assets/login-back.jpg'); background-size: cover">
    <div class="h-full w-full z-20 absolute bg-gray-200 bg-opacity-25 flex">
      <div class="flex flex-col h-full md:w-login w-full justify-center bg-white">
        <div class="overflow-y-auto transition-all p-5">
          <router-outlet></router-outlet>
        </div>
      </div>
      @if (isProduction) {
        <div id="hinweis-element" class="w-0 flex md:w-full md:h-full justify-center items-center">
          @if (systemActionsService.isElectron) {
            <iframe
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              title="News aus Flinky Zeugnis"
              class="w-11/12 h-3/4 overflow-auto bg-gray-200 bg-opacity-75 rounded-lg"
              src="https://flinky-zeugnis.de/flinky-zeugnis-evo-wichtige-hinweise-v2-desktop/"
              (load)="iframeLoad()"
            ></iframe>
          } @else {
            <iframe
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              title="News aus Flinky Zeugnis"
              class="w-11/12 h-3/4 overflow-auto bg-gray-200 bg-opacity-75 rounded-lg"
              src="https://flinky-zeugnis.de/flinky-zeugnis-evo-wichtige-hinweise-v2-web/"
              (load)="iframeLoad()"
            ></iframe>
          }
          <div *ngIf="loadingNews" class="pi pi-spin pi-spinner absolute" style="font-size: 4rem"></div>
        </div>
      }
    </div>
  </div>
</ng-template>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [closable]="false"
  [baseZIndex]="11000"
  rejectButtonStyleClass="p-button-secondary"
></p-confirmDialog>

<p-toast [baseZIndex]="11000"></p-toast>
<p-toast styleClass="fz-big-toast" position="center" key="errorDlg" (onClose)="onConfirm()" [baseZIndex]="11000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column max-h-screen overflow-auto" style="flex: 1">
      <div class="p-text-center overflow-auto">
        <div class="flex flex-row align-items-center">
          <em class="pi pi-exclamation-triangle" style="font-size: 3rem"></em>
          <div style="user-select: text" class="ml-3 text-xl">{{ message.summary }}</div>
        </div>
        <div style="user-select: text">{{ message.detail }}</div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-offset-3 p-col-6">
          <button type="button" pButton (click)="onConfirm()" label="Ok"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

<fz-signalr-notifications></fz-signalr-notifications>
