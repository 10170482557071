/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { NIFormular } from './ni-formular';
import { NIZeugnisFormular } from './ni-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { NIZeugnistypComponent } from '../projectables/ni-zeugnistyp.component';
import { NIZeugnissatzComponent } from '../projectables/ni-zeugnissatz.component';
import { NISchuelerComponent } from '../projectables/ni-schueler.component';
import { NIVersaeumnisComponent } from '../projectables/ni-versaeumnis.component';
import { NIUnterschriftComponent } from '../projectables/ni-unterschrift.component';

export class NI12hjStandardFormular extends NIZeugnisFormular {
  get viewType(): FormularViewType {
    return NI12hjStandardFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    return noteFields;
  }
}

@Component({
  selector: 'fz-ni-12hj-standard-formular',
  templateUrl: 'ni-12hj-standard-formular.component.html',
})
export class NI12hjStandardFormularComponent extends FormularComponentBase<NI12hjStandardFormular> {
  static createModel(formular: NIFormular) {
    return formular.zeugnis.zeugnissatz.schuljahr >= 2023
      ? {
          zeugnistyp: NIZeugnistypComponent.createModel(formular),
          zeugnissatz: NIZeugnissatzComponent.createModel(formular),
          schueler: NISchuelerComponent.createModel(formular),
          versaeumnis: NIVersaeumnisComponent.createModel(formular),
          interessen: {
            label: formular.interessen.description.label,
            textField: formular.interessen,
          },
          deutsch: {
            label: 'Deutsch Kompetenzbereiche¹',
            bereiche: [
              {
                trackBy: formular.deutsch.sprechenZuhoeren.bereich.key ?? 'gesamt',
                label: formular.deutsch.sprechenZuhoeren.description.label,
                textField: formular.deutsch.sprechenZuhoeren.text,
              },
              {
                trackBy: formular.deutsch.texteVerfassen.bereich.key ?? 'gesamt',
                label: 'Schreiben',
                textField: formular.deutsch.texteVerfassen.text,
              },
              {
                trackBy: formular.deutsch.lesen.bereich.key ?? 'gesamt',
                label: 'Lesen - mit Texten und Medien umgehen',
                textField: formular.deutsch.lesen.text,
              },
            ],
          },
          mathematik: {
            label: 'Mathematik Kompetenzbereiche²',
            bereiche: [
              {
                trackBy: formular.mathematik.zahlenOperationen.bereich.key ?? 'gesamt',
                label: formular.mathematik.zahlenOperationen.description.label,
                textField: formular.mathematik.zahlenOperationen.text,
              },
              {
                trackBy: formular.mathematik.raumForm.bereich.key ?? 'gesamt',
                label: formular.mathematik.raumForm.description.label,
                textField: formular.mathematik.raumForm.text,
              },
              {
                trackBy: formular.mathematik.groessenMessen.bereich.key ?? 'gesamt',
                label: formular.mathematik.groessenMessen.description.label,
                textField: formular.mathematik.groessenMessen.text,
              },
            ],
          },
          lernArbeitsverhalten: {
            label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
          },
          sozialverhalten: {
            label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
          },
          bemerkungen: {
            label: formular.bemerkungen.description.label,
            textField: formular.bemerkungen,
          },
          unterschrift: NIUnterschriftComponent.createModel(formular),
        }
      : {
          zeugnistyp: NIZeugnistypComponent.createModel(formular),
          zeugnissatz: NIZeugnissatzComponent.createModel(formular),
          schueler: NISchuelerComponent.createModel(formular),
          versaeumnis: NIVersaeumnisComponent.createModel(formular),
          interessen: {
            label: formular.interessen.description.label,
            textField: formular.interessen,
          },
          deutsch: {
            label: 'Deutsch*',
            bereiche: [
              {
                label: formular.deutsch.sprechenZuhoeren.description.label,
                textField: formular.deutsch.sprechenZuhoeren.text,
              },
              {
                label: formular.deutsch.lesen.description.label,
                textField: formular.deutsch.lesen.text,
              },
              {
                label: formular.deutsch.texteVerfassen.description.label,
                textField: formular.deutsch.texteVerfassen.text,
              },
            ],
          },
          mathematik: {
            label: 'Mathematik**',
            bereiche: [
              {
                label: formular.mathematik.zahlenOperationen.description.label,
                textField: formular.mathematik.zahlenOperationen.text,
              },
              {
                label: formular.mathematik.groessenMessen.description.label,
                textField: formular.mathematik.groessenMessen.text,
              },
              {
                label: formular.mathematik.raumForm.description.label,
                textField: formular.mathematik.raumForm.text,
              },
            ],
          },
          lernArbeitsverhalten: {
            label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
          },
          sozialverhalten: {
            label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
          },
          bemerkungen: {
            label: formular.bemerkungen.description.label,
            textField: formular.bemerkungen,
          },
          unterschrift: NIUnterschriftComponent.createModel(formular),
        };
  }

  get model() {
    return NI12hjStandardFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<NI12hjStandardFormular>) {
    super(formularService);
  }
}
