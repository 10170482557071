import { PropertyField } from '../../../models/fields/property-field';
import { SLZeugnis } from '../../../models/sl-zeugnis';

export class EmpfehlungField extends PropertyField<SLZeugnis, string | null> {
  constructor(private zeugnis: SLZeugnis) {
    super(
      {
        label: 'Empfehlung',
        labelShort: 'Empf',
        property: 'entwicklungZusammenfassung',
        required: false,
        displayType: 'Table',
      },
      { object: zeugnis }
    );
  }
  get value(): string | null {
    if (
      this.zeugnis.entwicklungZusammenfassung?.includes(
        'bisherigen Leistungsentwicklung der Besuch eines Gymnasiums oder einer Gemeinschaftsschule empfohlen.'
      )
    )
      return 'Gym';
    else if (
      this.zeugnis.entwicklungZusammenfassung?.includes(
        'bisherigen Leistungsentwicklung der Besuch einer Gemeinschaftsschule empfohlen.'
      )
    )
      return 'Gem';
    else return null;
  }
  set value(value: string | null) {}

  override get error(): string | null {
    return null;
  }

  override get displayStringShort() {
    return this.displayString;
  }
}
