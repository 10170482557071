import { HelpInfoModule } from './../help-info/help-info.module';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JahrgangAendernComponent } from './jahrgang-aendern.component';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { StepsModule } from 'primeng/steps';
import { SelectKundennummerComponent } from '../select-kundennummer/select-kundennummer.component';

@NgModule({
  declarations: [JahrgangAendernComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    PanelModule,
    InputTextModule,
    HelpInfoModule,
    StepsModule,
    SelectKundennummerComponent,
  ],
  exports: [JahrgangAendernComponent],
})
export class JahrgangAendernModule {}
