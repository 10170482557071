<span #componentContent style="display: none">
  <ng-content></ng-content>
</span>
<ng-container *ngFor="let word of words">
  <span
    [attr.data-placeholder]="placeholder"
    [attr.data-shadow]="shadow"
    [fzRichText]="word.fragment"
    [dontClone]="true"
  ></span>
</ng-container>
<ng-template #content="fzPortal" fzPortal>
  <ng-container *ngFor="let word of words">
    <fz-string
      #stringElement
      [textRange]="word.range"
      [placeholder]="placeholder"
      [shadow]="formularService.isReadonly ? '' : shadow"
      [style.fontFamily]="word.fontFamily"
      [style.fontWeight]="word.fontWeight"
      [style.fontSize]="word.fontSize"
      [style.color]="color"
      [style.left.px]="word.rect.left"
      [style.top.px]="word.rect.top"
      >{{ word.text }}</fz-string
    >
  </ng-container>
</ng-template>
