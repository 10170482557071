import { WorkspaceService } from '../../../../shared/services';
import { ReportingService } from './../reporting.service';
import { Component, OnInit } from '@angular/core';
import ReportJahrgangSelectionOptionDto from '../../../../models/generated/report-jahrgang-selection-option-dto';

@Component({
  selector: 'fz-report-geschlechter',
  templateUrl: './report-geschlechter.component.html',
  styleUrls: ['./report-geschlechter.component.scss'],
})
export class ReportGeschlechterComponent implements OnInit {
  geschlechterData: any;
  jahrgaenge: ReportJahrgangSelectionOptionDto[] = [];
  selectedJahrgang?: ReportJahrgangSelectionOptionDto;

  constructor(
    private reportingService: ReportingService,
    private schuelerService: WorkspaceService
  ) {}

  async ngOnInit() {
    this.jahrgaenge = await this.reportingService.getJahrgangSelectionOptions();
    this.selectedJahrgang = this.jahrgaenge[0];
    await this.createChart();
  }

  async createChart() {
    if (this.selectedJahrgang) {
      const result = await this.reportingService.getGeschlechter(this.selectedJahrgang.id);
      this.geschlechterData = {
        labels: Object.keys(result),
        datasets: [
          {
            data: Object.values(result),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          },
        ],
      };
    }
  }
}
