import { ZeugnissatzViewModel } from './../../../../modules/formular/formular';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ImportService } from '../import.service';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TransferFile } from '../../../../models/generated/transfer-file';
import { Jahrgang } from '../../../../models/jahrgang';
import { ClientException } from '../../../../models/client-exception';

@Component({
  selector: 'app-import-assi-passwort',
  templateUrl: './import-assi-passwort.component.html',
  styleUrls: ['./import-assi-passwort.component.scss'],
})
export class ImportAssiPasswortComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Input() transferFile: TransferFile | undefined;

  @Output() passwordChecked = new EventEmitter<Jahrgang>();
  @Output() restart = new EventEmitter<void>();

  error = false;
  errorMessage = '';

  passwordform: UntypedFormGroup;

  zeugnissatzViewModelAlt?: ZeugnissatzViewModel;
  zeugnissatzViewModelNeu?: ZeugnissatzViewModel;
  loading: boolean = false;

  constructor(private importService: ImportService) {
    this.passwordform = new UntypedFormGroup({
      password: new UntypedFormControl('', Validators.required),
    });
  }

  async checkPassword() {
    if (this.transferFile?.id != null) {
      try {
        this.loading = true;
        this.error = false;
        const jahrgang = await this.importService.importCheckPassword(
          this.transferFile.id,
          this.passwordform.value.password
        );
        this.passwordChecked.next(jahrgang);
      } catch (ex) {
        if (ex instanceof ClientException) {
          this.error = true;
          this.errorMessage = ex.message;
        } else throw ex;
      } finally {
        this.loading = false;
      }
    }
  }

  firstStep(): void {
    this.restart.next();
  }
}
