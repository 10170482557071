<div class="flex-grow h-0 overflow-auto m-3">
  <div class="flex w-full h-full flex-row">
    <div class="flex flex-col w-1/4">
      <div class="flex h-full">
        <p-tree
          [value]="faecherTree"
          selectionMode="single"
          [(selection)]="selectedFachNode"
          (onNodeSelect)="onFachNodeSelect($event)"
          scrollHeight="flex"
        >
          <ng-template pTemplate="header">
            <div class="flex flex-row gap-2 mb-2">
              <button pButton type="button" label="Alle aufklappen" (click)="expandAll()"></button>
              <button pButton type="button" label="Alle zuklappen" (click)="collapseAll()"></button>
            </div>
          </ng-template>
          <ng-template let-node pTemplate="default">
            {{ node.label }}
          </ng-template>
        </p-tree>
      </div>
    </div>
    <div class="flex w-full h-full">
      <p-table
        #tbEditorTable
        [value]="selectedFachNode?.data?.items ?? []"
        styleClass="tb-table p-datatable-sm"
        class="tb-table table-fixed w-full"
        [loading]="loading"
        [scrollable]="true"
        scrollHeight="flex"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="caption">
          <div class="flex w-full flex-row justify-end">
            <div class="flex flex-row gap-2 w-full">
              <button
                pButton
                type="button"
                icon="pi pi-plus"
                class="p-button-outlined mr-2"
                (click)="addTb()"
                label="Neuen Textbaustein erstellen"
                [disabled]="selectedFachNode == null"
              ></button>
              <button
                pButton
                type="button"
                icon="fa fa-clipboard"
                class="p-button-outlined"
                (click)="pasteTbOpen()"
                [disabled]="selectedFachNode == null"
                label="Mehrzeiliges Einfügen"
              ></button>
              <button
                pButton
                type="button"
                icon="fa fa-clipboard"
                class="p-button-outlined"
                (click)="insertNameToTextField()"
                [disabled]="selectedFachNode == null || currentField == null"
                label="{Name} Einfügen"
              ></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width: 50px; width: 60%" scope="col">Text</th>
            <th style="min-width: 8rem" scope="col">Klassenstufe</th>
            <th style="min-width: 6rem" scope="col">Geschlecht</th>
            <th style="min-width: 4rem" scope="col">Note</th>
            <th style="min-width: 2rem" scope="col"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td style="min-width: 50px; width: 50%">
              <textarea
                pInputTextarea
                rows="1"
                [(ngModel)]="item.text"
                class="p-inputtext-sm w-full mr-2"
                [id]="item.id?.toString()"
                (focus)="fieldFocused($event, item)"
                name="tbField"
              ></textarea>
            </td>
            <td style="min-width: 8rem">
              <div class="flex flex-row gap-2">
                <p-checkbox name="klasse" [(ngModel)]="item.klasse" value="1" label="1"></p-checkbox>
                <p-checkbox name="klasse" [(ngModel)]="item.klasse" value="2" label="2"></p-checkbox>
                <p-checkbox name="klasse" [(ngModel)]="item.klasse" value="3" label="3"></p-checkbox>
                <p-checkbox name="klasse" [(ngModel)]="item.klasse" value="4" label="4"></p-checkbox>
              </div>
            </td>
            <td style="min-width: 6rem">
              <p-dropdown
                [options]="geschlechtOptions"
                [(ngModel)]="item.geschlecht"
                [style]="{ width: '100%' }"
                placeholder="wählen"
                class="p-dropdown-sm"
                appendTo="body"
              ></p-dropdown>
            </td>
            <td style="min-width: 4rem">
              <p-dropdown
                [options]="['1', '2', '3', '4', '5', 'k.A.']"
                [(ngModel)]="item.note"
                [style]="{ width: '100%' }"
                placeholder="wählen"
                class="p-dropdown-sm"
                appendTo="body"
              ></p-dropdown>
            </td>
            <td style="min-width: 2rem; text-align: center">
              <button
                pButton
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-danger"
                (click)="deleteTb(item)"
              ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="5">
              <div class="w-full pl-12" *ngIf="selectedFachNode == null">
                <em class="pi pi-arrow-left"></em> Bitte zuerst eine Kategorie auswählen
              </div>
              <div class="w-full pl-12" *ngIf="selectedFachNode != null">Keine Textbausteine vorhanden</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-dialog
    appendTo="body"
    header="Textbausteine einfügen"
    [(visible)]="pasteDialogVisible"
    [style]="{ width: '75vw' }"
    [baseZIndex]="10000"
    [maximizable]="true"
    [modal]="true"
    [resizable]="true"
    styleClass="p-fluid"
  >
    <div>
      Bitte fügen Sie im Textfeld den kopierten Inhalt (z.B. aus Word) ein oder geben Sie mehrere Zeilen ein und klicken
      Sie auf "In Textbausteine konvertieren".
    </div>

    <div class="flex flex-row gap-2 mt-2 mb-2">
      <p-checkbox name="importAsOne" [binary]="true" [(ngModel)]="importAsOne" inputId="importAsOne"></p-checkbox>
      <label for="importAsOne">
        Als einen einzigen Textbaustein importieren (Zeilenumbrüche führen nicht dazu, dass neue Textbausteine erzeugt
        werden)</label
      >
    </div>

    <textarea
      pInputTextarea
      [(ngModel)]="pasteText"
      name="pasteText"
      rows="5"
      (paste)="pasted($event)"
      *ngIf="pastedItems.length === 0"
    ></textarea>
    <p-table
      [value]="pastedItems"
      styleClass="tb-table p-datatable-sm"
      class="tb-table"
      [loading]="loading"
      [scrollable]="true"
      scrollHeight="300px"
      *ngIf="pastedItems.length > 0"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 50px" scope="col">Text</th>
          <th style="min-width: 12rem; max-width: 12rem" scope="col">Klassenstufe</th>
          <th style="min-width: 8rem; max-width: 8rem" scope="col">Geschlecht</th>
          <th style="min-width: 6rem; max-width: 6rem" scope="col">Note</th>
          <th style="min-width: 3rem; max-width: 3rem" scope="col"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td style="min-width: 50px">
            <textarea
              pInputTextarea
              rows="1"
              autoResize="true"
              [(ngModel)]="item.text"
              class="p-inputtext-sm w-full mr-2"
            ></textarea>
          </td>
          <td style="min-width: 12rem; max-width: 12rem">
            <div class="flex flex-row gap-2">
              <p-checkbox name="klasse" [(ngModel)]="item.klasse" value="1" label="1"></p-checkbox>
              <p-checkbox name="klasse" [(ngModel)]="item.klasse" value="2" label="2"></p-checkbox>
              <p-checkbox name="klasse" [(ngModel)]="item.klasse" value="3" label="3"></p-checkbox>
              <p-checkbox name="klasse" [(ngModel)]="item.klasse" value="4" label="4"></p-checkbox>
            </div>
          </td>
          <td style="min-width: 8rem; max-width: 8rem">
            <p-dropdown
              [options]="geschlechtOptions"
              [(ngModel)]="item.geschlecht"
              [style]="{ width: '100%' }"
              placeholder="wählen"
              class="p-dropdown-sm"
              appendTo="body"
            ></p-dropdown>
          </td>
          <td style="min-width: 6rem; max-width: 6rem">
            <p-dropdown
              [options]="['1', '2', '3', '4', '5', 'k.A.']"
              [(ngModel)]="item.note"
              [style]="{ width: '100%' }"
              placeholder="wählen"
              class="p-dropdown-sm"
              appendTo="body"
            ></p-dropdown>
          </td>
          <td style="min-width: 3rem; max-width: 3rem; text-align: center">
            <button
              pButton
              icon="pi pi-trash"
              class="p-button-rounded p-button-text p-button-danger"
              (click)="deletePastedTb(item)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="flex flex-row w-full gap-2 mt-2">
      <button
        pButton
        type="button"
        label="In Textbausteine konvertieren"
        icon="fa fa-exchange"
        (click)="convert()"
        [disabled]="pasteText.length == 0 || pastedItems.length > 0"
      ></button>
      <button
        pButton
        type="button"
        label="Zurücksetzen"
        icon="pi pi-trash"
        (click)="reset()"
        class="p-button-outlined p-button-danger"
        [disabled]="pastedItems.length == 0"
      ></button>
      <button pButton type="button" label="Abbrechen" icon="pi pi-times" (click)="pasteDialogVisible = false"></button>
      <button
        pButton
        type="button"
        label="Übernehmen"
        icon="pi pi-check"
        (click)="paste()"
        [disabled]="pastedItems.length == 0"
      ></button>
    </div>
  </p-dialog>
</div>
