import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import ImportTbResult from '../../../models/generated/import-tb-result';
import ImportTbResultCode from '../../../models/generated/import-tb-result-code';
import { TextbausteinService } from '../textbaustein/tb-editor/textbaustein.service';

const headlineStep1 = 'Flinky Zeugnis Textbausteinimport';
const headlineStep2 = 'Flinky Zeugnis Textbausteinimport';
const headlineStepNoData = 'Flinky Zeugnis Textbausteinimport';
const headlineStepFinished = 'Flinky Zeugnis Textbausteinimport abgeschlossen';
const headlineStepError = 'Flinky Zeugnis Textbausteinimport fehlgeschlagen';

@Component({
  selector: 'fz-import-tb',
  templateUrl: './import-tb.component.html',
  styleUrls: ['./import-tb.component.scss'],
})
export class ImportTbComponent {
  headlineText = headlineStep1;

  step = '1';
  importTbResult?: ImportTbResult;
  activeStep: number = 0;
  items: MenuItem[];

  constructor(private tbService: TextbausteinService) {
    this.items = [{ label: 'Quelle wählen' }, { label: 'Bestätigung' }, { label: 'Fertig' }];
  }

  internetChecked(data: ImportTbResult): void {
    this.importTbResult = data;
    switch (data.code) {
      case ImportTbResultCode.success:
        this.step = 'selectMode';
        this.headlineText = headlineStep2;
        this.activeStep = 1;
        break;
      case ImportTbResultCode.noData:
        this.step = 'noData';
        this.headlineText = headlineStepNoData;
        this.activeStep = 2;
        break;
      default:
        this.step = 'error';
        this.headlineText = headlineStepError;
        this.activeStep = 2;
    }
  }

  uploadCompleted(data: ImportTbResult): void {
    this.importTbResult = data;
    if (data.code === ImportTbResultCode.success) {
      this.step = 'selectMode';
      this.headlineText = headlineStep2;
      this.activeStep = 1;
    } else {
      this.step = 'error';
      this.headlineText = headlineStepError;
      this.activeStep = 2;
    }
  }

  firstStep() {
    this.step = '1';
    this.headlineText = headlineStep1;
    this.activeStep = 0;
  }

  async importFinished(data: ImportTbResult) {
    this.importTbResult = data;
    if (data.code === ImportTbResultCode.success) {
      this.step = 'finished';
      this.headlineText = headlineStepFinished;
    } else {
      this.step = 'error';
      this.headlineText = headlineStepError;
    }
    this.activeStep = 2;
    await this.tbService.loadTb();
  }
}
