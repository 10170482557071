<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="null"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="Normal"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="'Schuleingangsphase'"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="Schuleingangsphase (Flex)"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="'Lernbehindert'"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="Förderschule Lernen"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="'Geistigbehindert'"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="Förderschule Geistige Entwicklung*"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="'Intensiv'"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="Intensivklasse"
  ></p-radioButton>
</div>
<div class="text-xs">* Nur Jahreszeugnisse</div>
