/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { BWFormular } from './bw-formular';
import { BWZeugnisFormular } from './bw-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { BWZeugnistypComponent } from '../projectables/bw-zeugnistyp.component';
import { BWSchuelerComponent } from '../projectables/bw-schueler.component';
import { BWLeistungenComponent } from '../projectables/bw-leistungen.component';
import { BWUnterschriftComponent } from '../projectables/bw-unterschrift.component';

export class BW34jStandardFormular extends BWZeugnisFormular {
  get viewType(): FormularViewType {
    return BW34jStandardFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    noteFields.push(
      this.religion.gesamt.note,
      this.deutsch.gesamt.note,
      this.mathematik.gesamt.note,
      this.fremdsprache.gesamt.note,
      this.sachunterricht.gesamt.note,
      this.bildendeKunst.gesamt.note,
      this.musik.gesamt.note,
      this.sport.gesamt.note,
      this.schrift.gesamt.note
    );
    return noteFields;
  }
}

@Component({
  selector: 'fz-bw-34j-standard-formular',
  templateUrl: 'bw-34j-standard-formular.component.html',
})
export class BW34jStandardFormularComponent extends FormularComponentBase<BW34jStandardFormular> {
  static createModel(formular: BWFormular) {
    return {
      zeugnistyp: BWZeugnistypComponent.createModel(formular),
      schueler: BWSchuelerComponent.createModel(formular),
      leistungen: BWLeistungenComponent.createModel(formular),
      lernArbeitsverhalten: {
        label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
      },
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      unterschrift: BWUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return BW34jStandardFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<BW34jStandardFormular>) {
    super(formularService);
  }
}
