<p-panel class="flex flex-grow flex-col h-0 overflow-auto" header="Machen Sie bitte einige Angaben zum Druck">
  <div class="p-fluid p-grid">
    <div class="p-field p-col-6 p-md-6 align-middle">
      <div class="flex flex-col gap-3">
        <div class="flex flex-col gap-2">
          <label for="kopfzeileLabel">Kopfzeile</label>
          <input name="kopfzeileLabel" type="text" pInputText [(ngModel)]="kopfzeileLabel" />
        </div>
        <div class="flex flex-col gap-2">
          <label for="printEmpty">Leere Liste</label>
          <p-checkbox
            name="printEmpty"
            label="Als leere Liste zum handschriftlichen Ausfüllen drucken"
            [(ngModel)]="printEmpty"
            [binary]="true"
          ></p-checkbox>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex flex-row justify-between w-full">
            <label for="printEmpty">zusätzliche Spalten</label>
            <div class="w-20rem">
              <p-inputNumber
                [(ngModel)]="numAdditionalColumns"
                [showButtons]="true"
                buttonLayout="horizontal"
                inputId="horizontal"
                spinnerMode="horizontal"
                [step]="1"
                decrementButtonClass="p-button-info"
                incrementButtonClass="p-button-info"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                mode="decimal"
                (ngModelChange)="onNumAdditionalColumnsChange($event)"
                min="0"
              />
            </div>
          </div>
          @for (col of additionalCols; track $index) {
            <div class="flex flex-row gap-2">
              <input
                name="additionalCols[$index].header"
                type="text"
                pInputText
                [(ngModel)]="additionalCols[$index].header"
              />
            </div>
          }
        </div>
      </div>
    </div>
    <div class="p-field p-col-6 p-md-6 align-middle">
      <label for="weitereFelder">Weitere Felder</label>
      <p-listbox
        name="weitereFelder"
        [checkbox]="true"
        [showToggleAll]="false"
        [multiple]="true"
        [options]="weitereFelder"
        [listStyle]="{ 'max-height': '200px' }"
        [filter]="true"
        [(ngModel)]="selectedWeitereFelder"
        optionLabel="label"
      ></p-listbox>
    </div>
  </div>

  <p-table #dt [columns]="displayCols" [value]="data">
    <ng-template pTemplate="caption">
      <div class="p-d-flex justify-between">
        <div class="text-2xl">{{ kopfzeileLabel }}</div>
        <img src="./assets/Flinky-Logo.png" height="32" width="32" alt="Flinky Logo" #image />
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th scope="cols" *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td class="bg-gray-100" *ngFor="let col of columns">
          {{ rowData[col.field] }}
        </td>
      </tr>
      <tr *ngFor="let weiteresFeld of selectedWeitereFelder">
        <td [attr.colspan]="columns.length" class="preWhiteSpaced ml-10">
          {{ weiteresFeld.label }}: {{ rowData[weiteresFeld.field] }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button pButton label="Drucken" class="w-56" icon="pi pi-print" (click)="print()"></button>
      <button pButton label="PDF herunterladen" class="w-56" icon="pi pi-download" (click)="download()"></button>
    </div>
  </ng-template>
</p-panel>
