<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="fz-paragraph-text">
    <p>Der Import der Textbausteine wurde abgeschlossen. Sie können diese nun in Ihren Zeugnissen verwenden.</p>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Zur Zeugnisübersicht"
        class="w-64"
        icon="pi pi-home"
        (click)="zeugnis()"
      ></button>
    </div>
  </ng-template>
</p-panel>
