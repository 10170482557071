import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  QueryList,
  SkipSelf,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableOwnerProvider, ProjectableBase } from './projectable';
import { RichTextSelection } from './rich-text-selection';
import { TextEditorDirective, TextView, TextViewProvider } from './text-editor.directive';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { StringElement } from '@modules/pdl';
import { Font } from '@modules/shared/font.directive';
import { NodeHelper } from '@modules/dom';
import { Field } from '../../models/fields/field';

@Component({
  selector: 'fz-input',
  templateUrl: 'input.component.html',
  providers: [
    provideInterfaceBy(ProjectableProvider, InputComponent),
    provideInterfaceBy(TextViewProvider, InputComponent),
  ],
})
export class InputComponent extends ProjectableBase implements AfterViewInit, Projectable, TextView {
  @Input() placeholder = 'Text';
  @Input() field: Field | undefined;
  @Output() textChange = new EventEmitter<string>();
  @Output() hasFocusChange = new EventEmitter<boolean>();
  @ViewChildren('stringElement') stringElementRef = new QueryList<ElementRef<StringElement>>();
  @ViewChild(TextEditorDirective) editor: TextEditorDirective | undefined;

  #text = '';
  selection = new RichTextSelection();
  fragment = document.createDocumentFragment();
  textNode = document.createTextNode('');
  rect = new DOMRect();
  font?: Font;
  private afterViewInit = false;

  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef,
    private ownerProvider: ProjectableOwnerProvider
  ) {
    super(elementRef, parentProvider);
  }

  @Input() keyFilter: (key: string) => boolean = () => true;

  @Input() get text(): string {
    return this.#text;
  }
  set text(value: string) {
    if (this.#text !== value) {
      this.#text = value;
      this.textChange.emit(value); // muss vor updateSlices ausgefüht werden, da sonst updateSlices über projectableContentChange wieder set text aufruft
      this.updateSlices();
    }
  }

  ngAfterViewInit(): void {
    this.updateSlices();
    this.afterViewInit = true;
  }

  projectPosition(parentRect: DOMRect): void {
    const style = window.getComputedStyle(this.sourceElement);
    const topDiff = NodeHelper.getFontTopDiff(style.fontFamily, style.fontSize, style.fontWeight);
    const element = this.textNode.parentNode as HTMLElement;
    const childRect = element.getBoundingClientRect();
    this.rect = new DOMRect(
      childRect.left - parentRect.left + 0.1, // + 0.1 wegen Bug in Webkit (Caret wird nicht angezeigt bei ganzzahligen x-Positionen)
      childRect.top + topDiff.topDiff - parentRect.top,
      childRect.width,
      topDiff.height
    );
    this.font = { family: style.fontFamily, size: style.fontSize, weight: style.fontWeight };
    this.changeDetector.detectChanges();
  }

  updateSelection(): void {
    this.editor?.updateSelection();
  }

  private updateSlices() {
    this.textNode = document.createTextNode(this.#text);
    this.fragment = document.createDocumentFragment();
    this.fragment.append(this.textNode);
    this.changeDetector.detectChanges();
    if (this.afterViewInit) this.ownerProvider.provided.projectableContentChange(this);
  }
}
