import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-leistungen',
  templateUrl: 'sl-leistungen.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, SLLeistungenComponent)],
})
export class SLLeistungenComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      kopfnoten:
        formular.zeugnis.zeugnissatz.klassenstufe === 4 && formular.zeugnis.zeugnissatz.halbjahr === 0
          ? {
              sozialverhalten: {
                label: 'Sozialverhalten',
                noteField: formular.sozialLernArbeitsverhalten.sozialverhalten.note,
              },
              lernArbeitsverhalten: {
                label: 'Lern- und Arbeitsverhalten',
                noteField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.note,
              },
            }
          : undefined,
      religion:
        formular.zeugnis.zeugnissatz.klassenstufe >= 3 ||
        (formular.zeugnis.zeugnissatz.klassenstufe <= 2 &&
          formular.zeugnis.zeugnissatz.halbjahr === 1 &&
          !(
            formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule &&
            formular.zeugnis.schuelerTyp === 'Lernbehindert'
          ))
          ? {
              label: 'Religion',
              noteField: formular.religion.gesamt.note,
            }
          : undefined,
      deutschGesamt: {
        label: 'Deutsch (Gesamtnote)',
        noteField: formular.deutsch.gesamt.note,
      },
      sprechenZuhoeren: {
        label: 'Sprechen und Zuhören',
        noteField: formular.deutsch.sprechenZuhoeren.note,
      },
      lesen: {
        label: 'Lesen',
        noteField: formular.deutsch.lesen.note,
      },
      texteVerfassen:
        formular.zeugnis.zeugnissatz.klassenstufe >= 3 &&
        (!formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule ||
          formular.zeugnis.schuelerTyp !== 'Lernbehindert')
          ? {
              label: 'Texte verfassen',
              noteField: formular.deutsch.texteVerfassen.note,
            }
          : undefined,
      rechtschreiben: {
        label: 'Rechtschreiben',
        noteField: formular.deutsch.rechtschreiben.note,
      },
      sachunterricht: {
        label: 'Sachunterricht',
        noteField: formular.sachunterricht.gesamt.note,
      },
      mathematik: {
        label: 'Mathematik',
        noteField: formular.mathematik.gesamt.note,
      },
      weitere:
        formular.zeugnis.zeugnissatz.klassenstufe >= 3 ||
        (formular.zeugnis.zeugnissatz.klassenstufe <= 2 &&
          formular.zeugnis.zeugnissatz.halbjahr === 1 &&
          !(
            formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule &&
            formular.zeugnis.schuelerTyp === 'Lernbehindert'
          ))
          ? {
              bildendeKunst: {
                label: 'Bildende Kunst',
                noteField: formular.bildendeKunst.gesamt.note,
              },
              musik: {
                label: 'Musik',
                noteField: formular.musik.gesamt.note,
              },
              sport: {
                label: 'Sport',
                noteField: formular.sport.gesamt.note,
              },
              schrift:
                formular.zeugnis.zeugnissatz.klassenstufe >= 3 ||
                !formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule
                  ? {
                      label: 'Schrift',
                      noteField: formular.schrift.gesamt.note,
                    }
                  : undefined,
            }
          : undefined,
    };
  }

  @Input() model: ReturnType<typeof SLLeistungenComponent.createModel> | undefined;
}
