<ng-template pTemplate="currentPageTitle">
  <div class="flex items-center">
    <div>
      <span>Formulareinrichtung</span>
      <span *ngIf="isRasterUpdated">*</span>
    </div>
    <div class="ml-2">
      <button
        *ngIf="isRasterUpdated"
        pButton
        icon="pi pi-save"
        class="p-button-warning"
        [disabled]="dataLoading"
        [label]="bula === 'rp' ? 'Speichern und ALLEN bereitstellen' : 'Speichern'"
        (click)="saveOrUpload()"
      ></button>
    </div>
  </div>
</ng-template>

<div class="flex flex-row flex-wrap gap-2 pb-3 pl-3 pt-3">
  <p-selectButton
    [options]="items"
    [(ngModel)]="selectedPage"
    (onChange)="askForUpload()"
    [disabled]="dataLoading || hasAnyLoadingItem()"
  >
    <ng-template let-item>
      <div class="preWhiteSpaced">{{ item.label }}</div>
    </ng-template>
  </p-selectButton>

  <p-splitButton
    #copyButton
    appendTo="body"
    styleClass="h-full"
    (onClick)="toggleCopyButton($event)"
    [label]="'Kopieren aus'"
    icon="pi pi-copy"
    [model]="copyButtonItems"
    [disabled]="!selectedPage || dataLoading || hasAnyLoadingItem()"
  ></p-splitButton>

  <ng-container *ngIf="bula !== 'rp'">
    <button
      pButton
      [loading]="dataLoading || hasAnyLoadingItem()"
      label="Hochladen"
      icon="pi pi-upload"
      (click)="uploadRaster()"
      [disabled]="!selectedPage || dataLoading || hasAnyLoadingItem()"
    ></button>
    <button
      pButton
      [loading]="dataLoading || hasAnyLoadingItem()"
      label="Herunterladen"
      icon="pi pi-download"
      (click)="onDownload()"
      [disabled]="!selectedPage || dataLoading || hasAnyLoadingItem()"
    ></button>
  </ng-container>
</div>

<div class="pl-3 pt-5" *ngIf="!selectedPage">
  Zum Bearbeiten der Könnensprofile wählen Sie bitte zuerst ein Formular.
</div>

<div class="pb-3"></div>
<div class="flex-grow h-0 overflow-auto m-3">
  <div class="flex w-full h-full flex-row" *ngIf="selectedPage">
    <div class="flex flex-col w-1/4">
      <div class="flex h-full">
        <p-tree
          [value]="faecherTree"
          selectionMode="single"
          [(selection)]="selectedFachNode"
          (selectionChange)="onFachNodeSelect($event)"
          scrollHeight="flex"
        >
          <ng-template pTemplate="header">
            <div class="flex flex-row gap-2 mb-2">
              <button pButton type="button" label="Alle aufklappen" (click)="expandAll()"></button>
              <button pButton type="button" label="Alle zuklappen" (click)="collapseAll()"></button>
            </div>
          </ng-template>
          <ng-template let-node pTemplate="default">
            {{ getFachLabel(node.label) != node.label ? getFachLabel(node.label) : getBereichLabel(node.label) }}
          </ng-template>
        </p-tree>
      </div>
    </div>
    <div class="flex w-full h-full">
      <p-orderList
        *ngIf="selectedFachNode != null && isKpFach(selectedFachNode.data)"
        [value]="selectedFachNode.data?.items ?? []"
        styleClass="flex h-full w-full"
        class="flex h-full w-full kp-item-list"
        [stripedRows]="true"
        [dragdrop]="!systemActionService.touchDevice"
        (onReorder)="reorder(selectedFachNode.key ?? '')"
      >
        <ng-template pTemplate="header">
          <div class="flex w-full flex-row justify-between">
            <div>Ausgewählte Items</div>
            <div class="flex flex-row gap-2">
              <button
                pButton
                type="button"
                icon="pi pi-file-edit"
                label="Item auswählen"
                class="p-button-info p-button-sm"
                (click)="select(selectedFachNode.key ?? '', $event)"
                [disabled]="this.selectedFachNode == null"
                [loading]="loading"
              ></button>
              <button
                pButton
                type="button"
                icon="pi pi-plus"
                label="Item erstellen"
                class="p-button-info p-button-sm"
                (click)="add(selectedFachNode.key ?? '', $event)"
                [disabled]="this.selectedFachNode == null"
                [loading]="loading"
              ></button>
            </div>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="flex flew-row justify-between h-full">
            <div class="w-full preWhiteSpaced flex items-center h-full">{{ item.text }}</div>
            <button
              pButton
              type="button"
              icon="pi pi-pencil"
              class="fz-oben z-10 fz-zuordnung-delete-btn p-button-rounded p-button-info p-button-text p-button-sm"
              (click)="edit(item, selectedFachNode.key ?? '', $event)"
              [loading]="loading"
            ></button>
            <button
              pButton
              type="button"
              icon="pi pi-trash"
              class="fz-oben z-10 fz-zuordnung-delete-btn p-button-rounded p-button-danger p-button-text p-button-sm"
              (click)="remove(item, selectedFachNode.key ?? '', $event)"
              [loading]="loading"
            ></button>
          </div>
        </ng-template>
        <ng-template pTemplate="empty"> Keine Items verfügbar </ng-template>
      </p-orderList>

      <ng-container *ngIf="selectedFachNode != null && !isKpFach(selectedFachNode.data) && bula != 'rp'">
        <p-panel styleClass="flex flex-col h-full w-full" class="flex flex-col h-full w-full kp-kopf-list">
          <ng-template pTemplate="header">
            <div class="flex w-full flex-row justify-between items-center">
              <div>Kopfbereich</div>
              <button
                pButton
                type="button"
                icon="pi pi-plus"
                label="Spalte hinzufügen"
                class="p-button-info p-button-sm"
                (click)="addKopfSpalte()"
                [disabled]="kopfItems.length >= 6"
                [loading]="loading"
              ></button>
            </div>
          </ng-template>
          <div class="w-full flex flex-row justify-between">
            <div *ngFor="let col of kopfItems" class="flex flex-col justify-between ml-1 mr-1 mb-1">
              <div class="flex flex-col">
                <input
                  pInputText
                  [(ngModel)]="col.line1"
                  class="p-inputtext-sm w-full mb-2"
                  (change)="changeKopfbereich()"
                />
                <input
                  pInputText
                  [(ngModel)]="col.line2"
                  class="p-inputtext-sm w-full mb-2"
                  (change)="changeKopfbereich()"
                />
                <input
                  pInputText
                  [(ngModel)]="col.line3"
                  class="p-inputtext-sm w-full mb-2"
                  (change)="changeKopfbereich()"
                />
                <button
                  pButton
                  type="button"
                  icon="pi pi-trash"
                  label="Spalte löschen"
                  class="p-button-danger p-button-outlined p-button-sm mt-2 w-full"
                  (click)="removeKopfSpalte(col)"
                  [disabled]="kopfItems.length <= 4"
                  [loading]="loading"
                ></button>
              </div>
            </div>
          </div>
        </p-panel>
      </ng-container>
    </div>
  </div>

  <p-overlayPanel #editOp [style]="{ width: '50%' }">
    <ng-template pTemplate>
      <textarea pInputTextarea [(ngModel)]="editItemText" class="w-full" [rows]="3"></textarea>
      <div class="fz-button-group pt-2 flex justify-end w-full">
        <button
          pButton
          type="button"
          label="Abbrechen"
          (click)="cancel()"
          icon="pi pi-times"
          class="p-button-outlined"
        ></button>
        <button
          pButton
          type="button"
          label="Übernehmen"
          (click)="applyText()"
          [disabled]="editItemText.trim() == ''"
          icon="pi pi-save"
        ></button>
      </div>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #addOp [style]="{ width: '50%' }">
    <ng-template pTemplate>
      <p>Im Textfeld können Sie durch Einfügen aus der Zwischenablage Items zum Kompetenzbereich einfügen.</p>
      <p>Jede Zeile erzeugt ein neues Item.</p>
      <div class="flex flex-row gap-5 justify-items-end float-right pb-1">
        <p-radioButton label="Items anhängen" value="append" [(ngModel)]="itemAddMode"></p-radioButton>
        <p-radioButton label="Items ersetzen" value="replace" [(ngModel)]="itemAddMode"></p-radioButton>
      </div>
      <textarea pInputTextarea [(ngModel)]="addItem.text" class="w-full"></textarea>
      <div class="fz-button-group flex w-full justify-end">
        <button
          pButton
          type="button"
          label="Abbrechen"
          (click)="addOp.hide()"
          icon="pi pi-times"
          class="p-button-outlined"
        ></button>
        <button
          pButton
          type="button"
          label="Übernehmen"
          [disabled]="!addItem || !addItem.text || addItem.text.length === 0"
          icon="pi pi-save"
          (click)="applyAdd()"
        ></button>
      </div>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #selectOp [style]="{ width: '50%' }">
    <ng-template pTemplate>
      <div class="flex flex-col gap-2">
        <div class="flex flex-row items-center gap-2">
          <div>Verfügbare&nbsp;Items</div>
          <p-multiSelect
            styleClass="w-full"
            class="w-full"
            selectedItemsLabel="{0} Kategorien ausgewählt"
            [options]="getSourceOptions()"
            [(ngModel)]="selectedSourceTypes[selectedFachNode?.key ?? '']"
            (onChange)="onSourceChanged(selectedFachNode?.key ?? '', $event)"
            [maxSelectedLabels]="1"
          ></p-multiSelect>
        </div>
        <p-listbox
          [options]="getAvailableItems()"
          [(ngModel)]="selectedItems"
          [metaKeySelection]="false"
          [checkbox]="true"
          [filter]="true"
          [multiple]="true"
          optionLabel="name"
          [listStyle]="{ 'max-height': '250px' }"
          [style]="{ width: '100%' }"
        >
          <ng-template let-item pTemplate="item">
            <div class="w-full preWhiteSpaced">{{ item.text }}</div>
          </ng-template>
        </p-listbox>
      </div>

      <div class="fz-button-group pt-2 flex justify-end w-full">
        <button
          pButton
          type="button"
          label="Abbrechen"
          (click)="selectOp.hide()"
          icon="pi pi-times"
          class="p-button-outlined"
        ></button>
        <button
          pButton
          type="button"
          label="Übernehmen"
          [disabled]="selectedItems.length === 0"
          icon="pi pi-save"
          (click)="applySelect()"
        ></button>
      </div>
    </ng-template>
  </p-overlayPanel>

  <p-dialog [(visible)]="cdKonfliktVisible" header="Konflikt" [style]="{ width: '50vw' }">
    Es wurden folgende Bereiche bereits auf dem Server gändert:
    <ul class="p-pl-2 p-ml-2 p-mt-0 p-mb-2">
      <li *ngFor="let item of newer">{{ getFachBereichLabel(item.bereich) }}</li>
    </ul>
    Möchten Sie diese Einträge dennoch auf dem Server überschreiben oder Ihre Änderungen verwerfen?
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        label="Meine verwerfen"
        (click)="cdKonfliktVisible = false; download()"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="Server überschreiben"
        (click)="cdKonfliktVisible = false; uploadRaster(true)"
      ></button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="cdErrorVisible" header="Fehler" [style]="{ width: '50vw' }">
    Bei folgenden Bereichen sind Fehler aufgetreten:
    <ul class="p-pl-2 p-ml-2 p-mt-0 p-mb-2">
      <li *ngFor="let item of uploadError">{{ getFachBereichLabel(item.bereich) }}</li>
      <li *ngFor="let item of overwriteError">{{ getFachBereichLabel(item.bereich) }}</li>
    </ul>
    Bitte versuchen Sie es später erneut.
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        label="Ok"
        (click)="cdErrorVisible = false; getRaster()"
      ></button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="cdResultVisible" header="Ergebnis" [style]="{ width: '50vw' }">
    Folgende Bereiche wurden auf dem Server bereitgestellt:
    <ul class="p-pl-2 p-ml-2 p-mt-0 p-mb-2">
      <li *ngFor="let item of uploaded">{{ getFachBereichLabel(item.bereich) }}</li>
      <li *ngFor="let item of overwritten">{{ getFachBereichLabel(item.bereich) }}</li>
    </ul>
    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="Ok"
        (click)="cdResultVisible = false; getRaster()"
      ></button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="cdNoResultVisible" header="Ergebnis" [style]="{ width: '50vw' }">
    Es wurden keine Änderungen hochgeladen. Alle Items sind bereits aktuell.
    <ng-template pTemplate="footer">
      <button type="button" pButton icon="pi pi-check" label="Ok" (click)="cdNoResultVisible = false"></button>
    </ng-template>
  </p-dialog>
</div>
