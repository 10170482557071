import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IdService {
  private ids: string[] = [];

  constructor(private http: HttpClient) {}

  async getId(): Promise<string> {
    if (this.ids.length === 0) {
      this.ids = await lastValueFrom(this.http.get<string[]>('/api/id/get'));
    }
    return this.ids.pop()!;
  }
}
