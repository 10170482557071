<div class="wrapper">
  <canvas #canvas id="signature-pad" class="signature-pad" width="600" height="150"></canvas>
  <div class="signature-pad-line"></div>
  <div class="signature-pad-name">{{ vorname }} {{ name }}</div>
  <p-colorPicker
    [(ngModel)]="color"
    class="color-picker"
    (onChange)="colorChanged($event)"
    [inline]="true"
  ></p-colorPicker>
</div>
<div class="button-container gap-2">
  <button pButton icon="pi pi-trash" type="button" (click)="trash()"></button>
  <button pButton icon="pi pi-times" type="button" (click)="reset()"></button>
  <button
    pButton
    icon="pi pi-undo"
    type="button"
    (click)="undo()"
    [disabled]="this.signaturePad?.toData()?.length == 0"
  ></button>
</div>
