<fz-panel
  *ngIf="model != null"
  name="Schueler"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div
    *ngIf="model.notBeglaubigt != null"
    style="
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-template-rows: auto auto;
      align-items: baseline;
      column-gap: 19px;
      row-gap: 4px;
    "
  >
    <fz-label class="Allgemeiner_Zeugnis_Text" style="grid-row: 1; grid-column: 1">Vor- und Familienname</fz-label>
    <div class="Schuelername" style="grid-row: 1; grid-column: 2">
      <fz-text [field]="model.notBeglaubigt.vornameField" placeholder="Vorname"></fz-text>&nbsp;<fz-text
        [field]="model.notBeglaubigt.nameField"
        placeholder="Name"
      ></fz-text
      >&nbsp;<fz-dropdown
        class="Schuelername"
        [isShadow]="true"
        [field]="model.notBeglaubigt.geschlechtField"
      ></fz-dropdown>
    </div>
    <fz-label class="Allgemeiner_Zeugnis_Text" style="grid-row: 2; grid-column: 1">Klasse</fz-label>
    <fz-text
      class="Schuljahr"
      style="grid-row: 2; grid-column: 2"
      [field]="model.notBeglaubigt.klassenbezeichnungField"
      placeholder="Klassenbezeichnung"
    ></fz-text>
    <div style="grid-row: 2; grid-column: 3">
      <fz-label class="Allgemeiner_Zeugnis_Text">Schuljahr</fz-label>&nbsp;
      <fz-schuljahr-label class="Schuljahr">{{ model.notBeglaubigt.schuljahrString }}</fz-schuljahr-label>
    </div>
  </div>

  <div
    *ngIf="model.beglaubigt != null"
    style="display: grid; grid-template-columns: auto 1fr auto auto; grid-template-rows: auto auto; auto; align-items: baseline; column-gap: 19px; row-gap: 4px;"
  >
    <fz-label class="Allgemeiner_Zeugnis_Text" style="grid-row: 1; grid-column: 1">Vor- und Familienname</fz-label>
    <div class="Schuelername" style="grid-row: 1; grid-column: 2">
      <fz-text [field]="model.beglaubigt.vornameField" placeholder="Vorname"></fz-text>&nbsp;<fz-text
        [field]="model.beglaubigt.nameField"
        placeholder="Name"
      ></fz-text
      >&nbsp;<fz-dropdown
        class="Schuelername"
        [isShadow]="true"
        [field]="model.beglaubigt.geschlechtField"
      ></fz-dropdown>
    </div>

    <fz-label class="Allgemeiner_Zeugnis_Text" style="grid-row: 2; grid-column: 1">Geburtsdatum</fz-label>
    <fz-date
      class="SchuelerGebDat"
      style="grid-row: 2; grid-column: 2"
      [field]="model.beglaubigt.geburtsdatumField"
      placeholder="Geburtsdatum"
    ></fz-date>

    <fz-label class="Allgemeiner_Zeugnis_Text" style="grid-row: 2; grid-column: 3">Geburtsort</fz-label>
    <fz-text
      class="SchuelerGebOrt"
      style="grid-row: 2; grid-column: 4"
      [field]="model.beglaubigt.geburtsortField"
      placeholder="Geburtsort"
    ></fz-text>

    <fz-label class="Allgemeiner_Zeugnis_Text" style="grid-row: 3; grid-column: 1">Schuljahr</fz-label>
    <fz-label class="Schuljahr" style="grid-row: 3; grid-column: 2">{{ model.beglaubigt.schuljahrString }}</fz-label>

    <fz-label class="Allgemeiner_Zeugnis_Text" style="grid-row: 3; grid-column: 3">Klasse</fz-label>
    <fz-text
      class="Schuljahr"
      style="grid-row: 3; grid-column: 4"
      [field]="model.beglaubigt.klassenbezeichnungField"
      placeholder="Klassenbezeichnung"
    ></fz-text>
  </div>
</fz-panel>
