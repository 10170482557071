import { Component, ElementRef, Input, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableDecoratorBase } from './projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { WorkspaceService } from '../../shared/services';
import { DropdownComponent } from './dropdown.component';
import { DropdownFieldBase } from '../../models/fields/dropdown-field';
import { Field } from '../../models/fields/field';

@Component({
  selector: 'fz-linked-dropdown',
  templateUrl: 'linked-dropdown.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, LinkedDropdownComponent)],
})
export class LinkedDropdownComponent extends ProjectableDecoratorBase implements Projectable {
  @Input() field: DropdownFieldBase<any> | undefined;
  @Input() linkedField: Field | undefined;
  @Input() fieldName: string | undefined;
  @ViewChild(DropdownComponent) dropdown: DropdownComponent | undefined;
  loading: boolean = false;

  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private workspaceService: WorkspaceService
  ) {
    super(elementRef, parentProvider);
  }

  get isDifferent(): boolean {
    return this.field?.value != null && this.field.value !== this.linkedField?.value;
  }

  modelChange(): void {
    if (this.field != null && this.linkedField != null) {
      if (this.linkedField.value == null) {
        this.linkedField.value = this.field?.value;
        this.copyToAll(true);
      }
    }
  }

  async copyToAll(onlyIfEmpty = false) {
    if (this.field != null && this.linkedField != null && this.fieldName != null) {
      try {
        this.loading = true;
        this.linkedField.value = this.field?.value;
        const zeugnisse = this.workspaceService.selectedZeugnisse.filter(
          (z) => z !== this.workspaceService.selectedZeugnis
        );
        for (const zeugnis of zeugnisse) {
          if (!onlyIfEmpty || (zeugnis as any)[this.fieldName] == null)
            (zeugnis as any)[this.fieldName] = this.field.value;
        }
        await this.workspaceService.saveSelectedZeugnissatz();
      } finally {
        this.loading = false;
      }
    }
  }
}
