import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MenuModule } from 'primeng/menu';
import { BlockModule } from '@modules/blocks/block.module';
import { SharedModule } from '@modules/shared/shared.module';
import { IntelliComponent } from './intelli.component';
import { RichTextComponent } from './rich-text.component';
import { VerbalComponent } from './verbal.component';
import { IntelliTextModule } from '../../shared/components/intellibox/intelliText.module';
import { VerbalbeurteilungModule } from '../../shared/components/verbabeurteilung/verbalbeurteilung.module';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TextbausteinEditorModule } from '../../shared/components/textbaustein-editor/textbaustein-editor.module';
import { ToggleButtonModule } from 'primeng/togglebutton';

@NgModule({
  imports: [
    BrowserModule,
    MenuModule,
    SharedModule,
    BlockModule,
    IntelliTextModule,
    VerbalbeurteilungModule,
    ButtonModule,
    MenubarModule,
    DialogModule,
    CheckboxModule,
    FormsModule,
    DropdownModule,
    ContextMenuModule,
    TextbausteinEditorModule,
    ToggleButtonModule,
  ],
  exports: [RichTextComponent, VerbalComponent, IntelliComponent],
  declarations: [RichTextComponent, VerbalComponent, IntelliComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RichTextModule {}
