<fz-panel
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div
    style="
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto auto;
      column-gap: 8px;
      row-gap: 4px 0px;
    "
  >
    <fz-label style="grid-row: 1; grid-column: 1; align-self: end" class="Allgemeiner_Zeugnis_Text"
      >Kenntnis genommen</fz-label
    >
    <fz-panel style="grid-row: 2; grid-column: 2" class="Unterschriften_Beschriftung" [border]="borderDirection.top">
    </fz-panel>
    <fz-label style="grid-row: 3; grid-column: 2; margin-left: 11px" class="Unterschriften_Beschriftung"
      >Ein(e) Sorgeberechtigte(r)</fz-label
    >
  </div>
</fz-panel>
