import { Quantity } from '@modules/dom/quantity';
import { Indexable } from './item-index';
import { UserLayoutFormular } from './user-layout';

export class UserLayoutFormularItem implements Indexable {
  formularItemName: string = '';
  marginBottomM: number | null = null;
  manualPageBreak: boolean | null = null;
  bottomAligned: boolean | null = null;

  get key() {
    return this.formularItemName;
  }

  get marginBottom(): number | null {
    if (this.marginBottomM == null) return null;
    else return Math.round(Quantity.mm2Px(this.marginBottomM));
  }
  set marginBottom(value: number | null) {
    if (value == null) this.marginBottomM = null;
    else this.marginBottomM = Math.round(Quantity.px2Mm(value));
  }

  constructor(
    key: string,
    public layout: UserLayoutFormular
  ) {
    this.formularItemName = key;
  }

  static cleanDto(dto: any): any {
    const { layout, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(item: UserLayoutFormularItem): unknown {
    return { ...this.cleanDto(item) };
  }

  static fromDto(dto: any, layout: UserLayoutFormular): UserLayoutFormularItem {
    return Object.assign(new UserLayoutFormularItem(dto.formularItemName ?? '', layout), this.cleanDto(dto));
  }
}
