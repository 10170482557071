import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { NIFormular } from '../formulare/ni-formular';
import { WorkspaceService } from '../../../shared/services';
import NIFachKey from '../../../models/generated/ni-fach-key';

@Component({
  selector: 'fz-ni-herkunftssprache',
  templateUrl: 'ni-herkunftssprache.component.html',
})
export class NIHerkunftsspracheComponent {
  loading = false;
  constructor(
    private formularService: FormularService<NIFormular>,
    private workspaceService: WorkspaceService
  ) {}

  get formular(): NIFormular {
    return this.formularService.formular;
  }
  get isHerkunftsspracheVisible(): boolean {
    return this.formular.herkunftssprache.gesamt.isVisibleOverrideDefaultTrue;
  }
  set isHerkunftsspracheVisible(value: boolean) {
    this.formular.herkunftssprache.gesamt.isVisibleOverrideDefaultTrue = value;
  }

  async copyHerkunftsspracheVisibleToAll() {
    try {
      this.loading = true;
      const zeugnisse = this.workspaceService.selectedZeugnisse.filter(
        (z) => z !== this.workspaceService.selectedZeugnis
      );
      for (const zeugnis of zeugnisse) {
        zeugnis.faecher.getItem(NIFachKey.herkunftssprache).bereiche.getItem(null).isVisibleOverrideDefaultTrue =
          this.isHerkunftsspracheVisible;
      }
      await this.workspaceService.saveSelectedZeugnissatz();
    } finally {
      this.loading = false;
    }
  }
}
