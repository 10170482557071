import { Directive } from '@angular/core';
import { ProjectableOwnerProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { ContainerDirective } from './container.directive';

@Directive({
  selector: '[fzFachListItem]',
  providers: [provideInterfaceBy(ProjectableOwnerProvider, FachListItemDirective)],
})
export class FachListItemDirective extends ContainerDirective {
  containerHeightChange(): void {
    /* */
  }

  override projectableContentChange(): void {
    if (this.height !== this.prevHeight) {
      this.project();
      this.owner?.invalidate();
    } else this.project();
  }
}
