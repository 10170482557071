/* eslint-disable no-bitwise */
import { FormularService } from './formular.service';
import { Formular } from './formular';
import { BorderDirection, borderWidthNormal } from '@modules/dom/border-direction';
import { Directive, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { TemplateDirective } from '../shared/template.directive';

@Directive()
export class FormularComponentBase<T extends Formular = Formular> {
  @ViewChildren(TemplateDirective) templates = new QueryList<TemplateDirective>();

  borderDirection = BorderDirection;
  borderWidth = borderWidthNormal;
  constructor(private formularService: FormularService<T>) {}

  get formular(): T {
    return this.formularService.formular;
  }

  get isReadonly(): boolean {
    return this.formularService.isReadonly;
  }

  get sidebarTemplate(): TemplateRef<any> | undefined {
    return this.templates.find((d) => d.fzTemplate === 'sidebar')?.ref;
  }

  trackByProperty(_index: number, item: any) {
    return item.trackBy;
  }
}
