import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { BorderDirection } from '@modules/dom/border-direction';
import { SLZeugnisFormular } from './sl-zeugnis-formular';
import { FormularFach } from '../../../models/formular-fach';
import { NoteField } from '../../../models/fields/note-field';
import { FormularViewType } from '../../formular/formular';
import { SLZeugnissatzComponent } from '../projectables/sl-zeugnissatz.component';
import { SLSchuelerComponent } from '../projectables/sl-schueler.component';
import { SLLeistungenComponent } from '../projectables/sl-leistungen.component';
import { SLSprachenportfolioComponent } from '../projectables/sl-sprachenportfolio.component';
import { SLVersaeumnisComponent } from '../projectables/sl-versaeumnis.component';
import { SLSchulortComponent } from '../projectables/sl-schulort.component';
import { SLUnterschriftComponent } from '../projectables/sl-unterschrift.component';

export class SL4hStandardFormular extends SLZeugnisFormular {
  get viewType(): FormularViewType {
    return SL4hStandardFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    faecher.push(
      this.entwicklungSozialverhalten,
      this.entwicklungArbeitshaltung,
      this.entwicklungLernLeistungsentwicklung,
      this.entwicklungDenkvermoegen,
      this.entwicklungAusdrucksfaehigkeit
    );
    return faecher;
  }

  override get noteFields(): NoteField[] {
    return [
      this.sozialLernArbeitsverhalten.sozialverhalten.note,
      this.sozialLernArbeitsverhalten.lernArbeitsverhalten.note,
      this.religion.gesamt.note,
      this.deutsch.gesamt.note,
      this.deutsch.sprechenZuhoeren.note,
      this.deutsch.lesen.note,
      this.deutsch.texteVerfassen.note,
      this.deutsch.rechtschreiben.note,
      this.sachunterricht.gesamt.note,
      this.mathematik.gesamt.note,
      this.bildendeKunst.gesamt.note,
      this.musik.gesamt.note,
      this.sport.gesamt.note,
      this.schrift.gesamt.note,
    ];
  }
}

@Component({
  selector: 'fz-sl-4h-standard-formular',
  templateUrl: './sl-4h-standard-formular.component.html',
})
export class SL4hStandardFormularComponent extends FormularComponentBase<SL4hStandardFormular> {
  static createModel(formular: SL4hStandardFormular) {
    return {
      zeugnissatz: SLZeugnissatzComponent.createModel(formular),
      schueler: SLSchuelerComponent.createModel(formular),
      faecher: formular.faecher.map((fach) => ({
        trackBy: fach.key,
        label: fach.description.label,
        textField: fach.gesamt.text,
      })),
      besondereFaehigkeiten: {
        label: formular.besondereFaehigkeiten.description.label,
        textField: formular.besondereFaehigkeiten,
      },
      leistungen: SLLeistungenComponent.createModel(formular),
      sprachenportfolio: SLSprachenportfolioComponent.createModel(formular),
      bemerkungen: {
        label: 'Bemerkungen',
        textField: formular.bemerkungen,
      },
      entwicklungZusammenfassung: {
        label: formular.entwicklungZusammenfassung.description.label,
        textField: formular.entwicklungZusammenfassung,
        empfehlungField: formular.entwicklungEmpfehlung,
      },
      versaeumnis: SLVersaeumnisComponent.createModel(formular),
      schulort: SLSchulortComponent.createModel(formular),
      unterschrift: SLUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return SL4hStandardFormularComponent.createModel(this.formular);
  }

  override borderDirection = BorderDirection;

  constructor(formularService: FormularService<SL4hStandardFormular>) {
    super(formularService);
  }
}
