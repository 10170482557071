import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Quantity } from '@modules/dom/quantity';
import { Page } from './formular-viewer.component';

@Component({
  selector: 'fz-page-margin',
  templateUrl: 'page-margin.component.html',
  styleUrls: ['page-margin.component.scss'],
})
export class PageMarginComponent implements OnInit, OnDestroy {
  @Input() page: Page | undefined;
  @Input() margin: 'top' | 'bottom' = 'top';
  @Output() layoutChange = new EventEmitter<void>();
  get userLayoutMode(): boolean {
    return this._userLayoutMode;
  }
  @Input() @HostBinding('class.user-layout-mode') set userLayoutMode(value: boolean) {
    if (this._userLayoutMode !== value) {
      this._userLayoutMode = value;
      this.userLayoutModeChange.emit(this._userLayoutMode);
    }
  }
  @Output() userLayoutModeChange = new EventEmitter<boolean>();
  dialogVisible = false;
  @HostBinding('class.mousedown') isMouseDown = false;

  private _userLayoutMode = false;
  private screenY = 0;

  get valueM(): number {
    return Math.round(Quantity.px2Mm(this.value));
  }
  get value(): number {
    if (this.page != null) {
      const formular = this.page.formular;
      if (this.margin === 'top') {
        return this.page.index === 0 ? formular.userPageMarginTopFirst : formular.userPageMarginTop;
      } else {
        return this.page.index > 0 ? formular.userPageMarginBottomLast : formular.userPageMarginBottom;
      }
    } else return 0;
  }
  set value(value: number) {
    if (this.page != null) {
      const formular = this.page.formular;
      if (this.margin === 'top') {
        if (this.page.index === 0) formular.userPageMarginTopFirst = value;
        else formular.userPageMarginTop = value;
      } else {
        if (this.page.index > 0) formular.userPageMarginBottomLast = value;
        else formular.userPageMarginBottom = value;
      }
    }
  }

  constructor(
    private zone: NgZone,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // events explizit in runOutsideAngular definieren, damit keine globale Change Detection ausgelöst wird
    this.zone.runOutsideAngular(() => {
      window.addEventListener('mousemove', this.mousemove);
      window.addEventListener('mouseup', this.mouseup);
    });
  }
  ngOnDestroy(): void {
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseup);
  }

  mousedown(e: MouseEvent): void {
    this.isMouseDown = true;
    this.userLayoutMode = true;
    if (this.margin === 'top') {
      this.screenY = e.screenY - (this.value ?? 0);
    } else {
      this.screenY = e.screenY + (this.value ?? 0);
    }
  }

  // @HostListener('window:mousemove', ['$event']) // event explizit in runOutsideAngular definieren, damit keine globale Change Detection ausgelöst wird
  mousemove = (e: MouseEvent): void => {
    if (this.isMouseDown && this.page != null) {
      if (this.margin === 'top') {
        this.value = e.screenY - this.screenY;
      } else {
        this.value = this.screenY - e.screenY;
      }
      if (this.value < 0) this.value = 0;
    }
    this.changeDetector.detectChanges();
  };
  // @HostListener('window:mouseup', ['$event']) // event explizit in runOutsideAngular definieren, damit keine globale Change Detection ausgelöst wird
  mouseup = (_e: MouseEvent): void => {
    if (this.isMouseDown) {
      this.isMouseDown = false;
      this.layoutChange.emit();
      this.changeDetector.detectChanges();
    }
  };
}
