import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';

@Component({
  selector: 'fz-sl-pflichtunterricht',
  templateUrl: 'sl-pflichtunterricht.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLPflichtunterrichtComponent)],
})
export class SLPflichtunterrichtComponent extends SLPanelDecoratorBase {
  get schuelerLabel(): string {
    if (this.formular.zeugnis.schueler.geschlecht === 'm') return 'Der Schüler';
    if (this.formular.zeugnis.schueler.geschlecht === 'w') return 'Die Schülerin';
    return 'Der Schüler/Die Schülerin';
  }
}
