import { ButtonModule } from 'primeng/button';
import { ImportTbErrorComponent } from './import-tb-error/import-tb-error.component';
import { ImportTbNoDataComponent } from './import-tb-no-data/import-tb-no-data.component';
import { ImportTbStep1Component } from './import-tb-step1/import-tb-step1.component';
import { ImportTbSelectModeComponent } from './import-tb-select-mode/import-tb-select-mode.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportTbComponent } from './import-tb.component';
import { ImportTbFinishedComponent } from './import-tb-finished/import-tb-finished.component';
import { FileUploadModule } from 'primeng/fileupload';
import { PanelModule } from 'primeng/panel';
import { StepsModule } from 'primeng/steps';

@NgModule({
  imports: [CommonModule, FileUploadModule, ButtonModule, PanelModule, StepsModule],
  declarations: [
    ImportTbComponent,
    ImportTbSelectModeComponent,
    ImportTbStep1Component,
    ImportTbNoDataComponent,
    ImportTbErrorComponent,
    ImportTbFinishedComponent,
  ],
  exports: [ImportTbComponent],
})
export class ImportTbModule {}
