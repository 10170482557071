import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FlinkyAuthService } from './../../services/auth/flinky-auth.service';
import { DownloadLicenceService } from './download-licence.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import * as signalR from '@microsoft/signalr';

const initText = 'Geben Sie hier die Lizenznummer (Kundennummer) Ihrer Schule an.';
const initHeadline = 'Lizenzdownload';

const fehlerText = '';
const fehlerHeadline = 'Fehler beim Lizenzdownload';
const fehlerDetailsLizenz = 'Die angegebene Lizenznummer wurde nicht gefunden! Bitte überprüfen Sie die Schreibweise.';
const fehlerDetailsSonstiger = 'Beim Download der Lizenz ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.';

const wartenText = '';
const wartenHeadline = 'Bitte warten ...';
const wartenDetails1 = 'Lizenzdaten werden beim Server angefragt ...';
const wartenDetails2 = 'Lizenzdaten wurden gefunden. Download wird gestartet.';

const erfolgText = '';
const erfolgHeadline = 'Lizenzdownload erfolgreich';
const erfolgDetails1 = 'Die Lizenz wurde heruntergeladen und kann jetzt verwendet werden.';

@Component({
  selector: 'fz-download-licence',
  templateUrl: './download-licence.component.html',
  styleUrls: ['./download-licence.component.scss'],
})
export class DownloadLicenceComponent implements OnInit {
  @Output() licenseLoaded = new EventEmitter<string>();
  headlineText = initHeadline;
  headlineAdditionalText = initText;

  showForm = true;
  detailText = '';

  isError = false;

  showBestaetigungButtons = false;
  showErrorButtons = false;
  showProgress = false;
  showIndicator = false;

  currentValue = 0;

  connection?: signalR.HubConnection;
  licenseform: UntypedFormGroup;
  loading: boolean = false;

  constructor(
    private downloadLicenceService: DownloadLicenceService,
    private flinkyAuth: FlinkyAuthService
  ) {
    this.licenseform = new UntypedFormGroup({
      lizenznummer: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    const baseUrl = document.getElementsByTagName('base')[0].href;

    const url = (baseUrl + '/signalr_download').replace(/([^:]\/)\/+/g, '$1');
    this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();

    this.connection.on('DownloadProgressChanged', (progress: number) => {
      this.currentValue = progress;
      this.isError = false;
    });

    this.connection.on('FzEvoNotAvailableForBuLa', () => {
      this.showViewBuLaError('Für dieses Bundesland steht Flinky Zeugnis Evo noch nicht zur Verfügung.');
      this.isError = true;
      this.loading = false;
    });

    this.connection.on('RequestLicenceInfo', () => {
      this.detailText = wartenDetails1;
      this.isError = false;
    });

    this.connection.on('LicenceInfoFound', () => {
      this.detailText = wartenDetails2;
      this.showIndicator = false;
      this.isError = false;
      this.showProgress = true;
    });

    this.connection.on('NoLicenceFound', () => {
      this.showIndicator = false;
      this.showViewLicenceNotFound();
      this.isError = true;
      this.loading = false;
    });

    this.connection.on('DownloadComplete', () => {
      this.showViewDownloadComplete();
      this.isError = false;
      this.loading = false;
    });

    this.connection.on('DownloadError', (message: string) => {
      this.showViewDownloadError(message);
      this.isError = true;
      this.loading = false;
    });

    this.connection.start().catch((err) => console.log(err));
  }

  format(value: number): string {
    return 'Heruntergeladen: ' + Math.floor(value * 100) + '%';
  }

  showViewWarten(): void {
    this.headlineText = wartenHeadline;
    this.headlineAdditionalText = wartenText;

    this.detailText = wartenDetails1;

    this.showForm = false;
    this.showProgress = false;
    this.showIndicator = true;
    this.showBestaetigungButtons = false;
    this.showErrorButtons = false;
  }

  showViewLicenceNotFound(): void {
    this.headlineText = fehlerHeadline;
    this.headlineAdditionalText = fehlerText;

    this.detailText = fehlerDetailsLizenz;

    this.showForm = false;
    this.showIndicator = false;
    this.showBestaetigungButtons = false;
    this.showErrorButtons = true;
  }

  showViewDownloadComplete(): void {
    this.headlineText = erfolgHeadline;
    this.headlineAdditionalText = erfolgText;

    this.detailText = erfolgDetails1;

    this.showProgress = false;

    this.showForm = false;
    this.showBestaetigungButtons = true;
    this.showErrorButtons = false;

    this.licenseLoaded.next(this.licenseform.value.lizenznummer);
  }

  showViewDownloadError(message: string): void {
    this.headlineText = fehlerHeadline;
    this.headlineAdditionalText = fehlerText;

    this.detailText = fehlerDetailsSonstiger + '\n\n' + (message ?? '');

    this.showProgress = false;

    this.showForm = false;
    this.showBestaetigungButtons = false;
    this.showErrorButtons = true;
  }

  showViewBuLaError(message: string): void {
    this.headlineText = fehlerHeadline;
    this.headlineAdditionalText = fehlerText;

    this.detailText = message;

    this.showProgress = false;

    this.showForm = false;
    this.showBestaetigungButtons = false;
    this.showErrorButtons = true;
  }

  showViewInit(clearLizenz = false): void {
    if (clearLizenz) {
      this.licenseform.patchValue({ lizenznummer: '' });
    }

    this.headlineText = initHeadline;
    this.headlineAdditionalText = initText;

    this.showForm = true;
    this.showBestaetigungButtons = false;
    this.showErrorButtons = false;
  }

  restartDownload(): void {
    this.showViewInit(false);
  }

  downloadLicence(): void {
    this.loading = true;
    this.showViewWarten();

    this.downloadLicenceService.downloadLicense(this.licenseform.value.lizenznummer, this.connection?.connectionId);
  }
}
