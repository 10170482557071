import { PropertyFieldDescription, PropertyFieldRef } from './property-field';
import { TextField } from './text-field';

export class RichTextField<
  T,
  TDesc extends PropertyFieldDescription<T, string | null> = PropertyFieldDescription<T, string | null>,
> extends TextField<T, TDesc> {
  constructor(description: PropertyFieldDescription<T, string | null>, ref: PropertyFieldRef<T, string | null>) {
    super({ displayType: 'Detail', ...description } as any, ref);
  }
  override get isEmpty(): boolean {
    return super.isEmpty || this.value === '<p></p>';
  }
  override get displayString() {
    const div = document.createElement('div');
    div.innerHTML = this.value ?? '';
    return div.innerText;
  }
}
