import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormularFactory } from '@modules/formular/formular-factory';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Subscription } from 'rxjs';
import { StringHelper } from '../../../modules/dom/string-helper';
import { WorkspaceService } from '../../services';
import { SystemActionsService } from '../system-actions/system-actions.service';

@Component({
  selector: 'fz-konferenzliste',
  templateUrl: './konferenzliste.component.html',
  styleUrls: ['./konferenzliste.component.scss'],
})
export class KonferenzlisteComponent implements OnInit, OnDestroy {
  @ViewChild('image') image?: ElementRef;
  exportColumns: { title: any; dataKey: any }[] = [];

  kopfzeileLabel = 'Konferenzliste';
  klassenleitung = '';
  schulleitung = '';
  schulort = '';
  printEmpty = false;
  selectedWeitereFelder: { field: string; header: string; label: string }[] = [];
  subscriptions: Subscription[] = [];
  cols: { field: string; header: string }[] = [];
  weitereFelder: { field: string; header: string; label: string }[] = [];
  data: any[] = [];
  emptyData: any[] = [];
  numAdditionalColumns: number = 0;
  additionalCols: { field: string; header: string }[] = [];
  displayCols: { field: string; header: string }[] = [];

  constructor(
    private workspaceService: WorkspaceService,
    public systemActionsService: SystemActionsService
  ) {}
  async ngOnInit() {
    this.initWeitereFelder();
    this.subscriptions.push(
      this.workspaceService.dataLoadedBehaviour.subscribe(() => {
        this.initWeitereFelder();
        this.initTable();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  initWeitereFelder() {
    this.weitereFelder = [];
    const allZeugnisse = this.workspaceService.selectedZeugnisse.filter((z) => z.schueler.isAktiv);
    const overview = FormularFactory.createOverview(allZeugnisse);

    overview.columns.forEach((zeugnisFeld) => {
      if (zeugnisFeld.description.displayType === 'Detail') {
        this.weitereFelder.push({
          field: zeugnisFeld.key,
          header: zeugnisFeld.description.labelShort,
          label: zeugnisFeld.description.label,
        });
      } else {
        console.log(zeugnisFeld);
      }
    });
  }

  initTable(): void {
    this.emptyData = [];
    this.data = [];

    this.cols = [{ field: 'nameVorname', header: 'Name, Vorname' }];

    const allZeugnisse = this.workspaceService.selectedZeugnisse.filter((z) => z.schueler.isAktiv);
    const overview = FormularFactory.createOverview(allZeugnisse);

    overview.columns.forEach((zeugnisFeld) => {
      if (zeugnisFeld.description.displayType === 'Table') {
        if ((zeugnisFeld.description as any).labelKey != null) {
          // noch was machen
          const additionalField = overview.columns.find((c) => c.key === (zeugnisFeld.description as any).labelKey);
          if (additionalField) {
            this.cols.push({ field: additionalField.key, header: additionalField.description.labelShort });
          }
        }
        this.cols.push({ field: zeugnisFeld.key, header: zeugnisFeld.description.labelShort });
      }
    });

    overview.rows.forEach((zeugnis) => {
      const obj = {};
      Object.assign(obj, {
        nameVorname: StringHelper.removeSpecialCharacters(
          (zeugnis['schuelerName']?.field?.displayString ?? '') +
            ', ' +
            (zeugnis['schuelerVorname']?.field?.displayString ?? '')
        ),
      });

      this.cols.forEach((col) => {
        const data = zeugnis[col.field];
        if (data) {
          Object.assign(obj, { [col.field]: data.field?.displayStringShort });
        }
      });

      this.weitereFelder.forEach((col) => {
        const data = zeugnis[col.field];
        if (data) {
          Object.assign(obj, { [col.field]: data.field?.displayString });
        }
      });

      const emptyObj = {};
      Object.assign(emptyObj, {
        nameVorname: StringHelper.removeSpecialCharacters(
          (zeugnis['schuelerName']?.field?.displayString ?? '') +
            ', ' +
            (zeugnis['schuelerVorname']?.field?.displayString ?? '')
        ),
      });

      this.emptyData.push(emptyObj);

      this.data.push(obj);
    });
    this.data.sort((a, b) => String(a.nameVorname).localeCompare(String(b.nameVorname), 'de-De'));

    this.schulleitung =
      StringHelper.removeSpecialCharacters(this.workspaceService.currentZeugnissatz?.schulleitung ?? '') ?? '';
    this.klassenleitung =
      StringHelper.removeSpecialCharacters(this.workspaceService.currentZeugnissatz?.klassenleitung ?? '') ?? '';
    this.schulort = this.workspaceService.currentZeugnissatz?.zeugniskopflizenz.schulort ?? '';

    this.displayCols = [...this.cols, ...this.additionalCols];
    this.exportColumns = this.displayCols.map((col) => ({ title: col.header, dataKey: col.field }));
  }

  createPdf(): jsPDF {
    const doc = new jsPDF('landscape', 'mm', 'a4');

    autoTable(doc, {
      theme: 'grid',
      margin: { left: 10 },
      headStyles: { halign: 'center', fillColor: [33, 150, 243], textColor: [255, 255, 255] },
      footStyles: { fillColor: undefined, textColor: 0 },
      bodyStyles: { halign: 'center', valign: 'middle' },
      showFoot: 'lastPage',
      head: [
        [
          {
            content: this.kopfzeileLabel,
            colSpan: this.displayCols.length,
            styles: { fillColor: undefined, textColor: 0, fontSize: 18 },
          },
        ],
        this.displayCols.map((c) => c.header),
      ],
      columns: this.exportColumns,
      body: this.printEmpty ? this.getEmptyDataForExport() : this.getDataForExport(),

      foot: [
        [{ content: '', colSpan: this.displayCols.length }],
        [{ content: `${this.schulort}, den`, colSpan: this.displayCols.length }],
        [{ content: '', colSpan: this.displayCols.length }],
        [
          {
            content: `${this.schulleitung}`,
            colSpan: Math.floor(this.displayCols.length / 3),
          },
          {
            content: `${this.klassenleitung}`,
            colSpan: Math.floor(this.displayCols.length / 3),
          },
          {
            content: 'Fachlehrer',
            colSpan: Math.floor(this.displayCols.length / 3),
          },
        ],
      ],
      didParseCell: function (data) {
        if (data.section === 'head') {
          if (data.column.index === 0) {
            data.cell.styles.halign = 'left';
          }
        }
        if (data.section === 'body') {
          if (data.column.index % 2 === 0) {
            data.cell.styles.fillColor = [255, 255, 255];
          } else {
            data.cell.styles.fillColor = [210, 210, 210];
          }
          if (data.column.index === 0) {
            data.cell.styles.halign = 'left';
            data.cell.styles.cellWidth = 50;
          }
          if (data.column.index !== 0) {
            data.cell.styles.halign = 'center';
            data.cell.styles.cellWidth = 230 / (data.table.columns.length - 1);
          }
        }

        if (data.section === 'foot') {
          if (data.row.index === 3) {
            doc.setDrawColor(255, 0, 0);
            doc.line(
              data.cell.getTextPos().x,
              data.cell.getTextPos().y,
              data.cell.getTextPos().x + data.cell.width,
              data.cell.getTextPos().y
            );
          }
        }
      },
      willDrawCell: function (data) {
        if (data.section === 'foot') {
          if (data.row.index === 3) {
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.3);
            doc.line(
              data.cell.getTextPos().x,
              data.cell.getTextPos().y - 2,
              data.cell.getTextPos().x + data.cell.width - 15,
              data.cell.getTextPos().y - 2
            );
          }
        }
      },
    });
    if (this.image?.nativeElement != null) {
      doc.addImage(this.image.nativeElement, 270, 8, 10, 10);
    }
    return doc;
  }

  getDataForExport(): any[] {
    const result: any[] = [];
    this.data.forEach((d) => {
      result.push(d);
      this.selectedWeitereFelder.forEach((f) => {
        result.push([
          {
            content: f.label + ': ' + (d[f.field] ?? ''),
            colSpan: this.displayCols.length,
            styles: { cellPadding: { left: 10, top: 1, right: 1, bottom: 1 }, halign: 'left' },
          },
        ]);
      });
    });

    return result;
  }
  getEmptyDataForExport(): any[] {
    const result: any[] = [];
    this.emptyData.forEach((d) => {
      result.push(d);
      this.selectedWeitereFelder.forEach((f) => {
        result.push([
          {
            content: f.label + ': ',
            colSpan: this.displayCols.length,
            styles: { cellPadding: { left: 10, top: 1, right: 1, bottom: 1 }, halign: 'left' },
          },
        ]);
      });
    });

    return result;
  }

  setPdfTitle(doc: jsPDF): string {
    const title = 'FlinkyZeugnisEvo_Konferenzliste';
    doc.setProperties({
      title,
    });
    return title;
  }
  print(): void {
    const doc = this.createPdf();
    {
      if (doc) {
        this.setPdfTitle(doc);
        doc.autoPrint({ variant: 'non-conform' });
        const blob = doc.output('blob');
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = URL.createObjectURL(blob);
        link.click();

        URL.revokeObjectURL(link.href);
      }
    }
  }

  download(): void {
    const doc = this.createPdf();
    {
      if (doc) {
        doc.save('Konferenzliste.pdf');
      }
    }
  }

  onNumAdditionalColumnsChange(_event: any): void {
    if (this.additionalCols.length < this.numAdditionalColumns) {
      for (let i = 0; i < this.numAdditionalColumns - this.additionalCols.length; i++) {
        this.additionalCols.push({
          field: `additional${this.additionalCols.length + 1}`,
          header: `Zusatzfeld ${this.additionalCols.length + 1}`,
        });
      }
    }
    if (this.additionalCols.length > this.numAdditionalColumns) {
      this.additionalCols = this.additionalCols.slice(0, this.numAdditionalColumns);
    }

    this.displayCols = [...this.cols, ...this.additionalCols];
    this.exportColumns = this.displayCols.map((col) => ({ title: col.header, dataKey: col.field }));
  }
}
