<ng-content></ng-content>
<ng-container
  *ngFor="let sequence of sequences"
  fzBlock
  [content]="content"
  [backDeco]="backDeco"
  [frontDeco]="frontDeco"
>
  <ng-template #content="fzPortal" fzPortal>
    <fz-translate *ngFor="let item of sequence.items" [style.top.px]="item.top || 0">
      <ng-container [fzPortalOutlet]="item.block?.content"></ng-container>
    </fz-translate>
  </ng-template>
  <ng-template #backDeco="fzPortal" fzPortal>
    <div *ngFor="let item of sequence.items" [style.top.px]="item.top || 0" style="position: absolute">
      <ng-container [fzPortalOutlet]="item.block?.backDeco"></ng-container>
    </div>
  </ng-template>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div *ngFor="let item of sequence.items" [style.top.px]="item.top || 0" style="position: absolute">
      <ng-container [fzPortalOutlet]="item.block?.frontDeco"></ng-container>
    </div>
  </ng-template>
</ng-container>
