<fz-panel>
  <div style="display: grid; grid-template-columns: auto 1fr auto">
    <div style="grid-column: 1" class="Allgemeiner_Zeugnis_Text">
      <fz-label>Schuljahr</fz-label>&nbsp;<fz-label class="Schuljahr">{{
        formular.zeugnissatz.schuljahrLabel
      }}</fz-label>
    </div>
    <div style="grid-column: 2; text-align: center" class="Allgemeiner_Zeugnis_Text">
      <fz-label *ngIf="formular.zeugnis.schuelerTyp == null && formular.zeugnis.zeugnissatz.klassenstufe >= 3"
        >{{ formular.zeugnissatz.halbjahrLabel }} </fz-label
      ><ng-container
        *ngIf="formular.zeugnis.schuelerTyp !== 'Intensiv' && formular.zeugnis.zeugnissatz.klassenstufe !== 0"
        ><fz-label>Jahrgangsstufe</fz-label>&nbsp;<fz-label class="Schuljahr">{{ formular.klassenstufeLabel }}</fz-label
        ><fz-label>, </fz-label></ng-container
      ><fz-label>Klasse</fz-label>&nbsp;<fz-label class="Schuljahr">{{
        formular.zeugnis.zeugnissatz.klassenbezeichnung
      }}</fz-label>
      <fz-label> | </fz-label>
      <fz-label class="Schuljahr">{{ formular.zeugnis.schuelerVorname }} {{ formular.zeugnis.schuelerName }}</fz-label>
    </div>
    <div *ngIf="!hidePage" style="grid-column: 3; text-align: right" class="Allgemeiner_Zeugnis_Text">
      <fz-label>Seite {{ pageIndex + 1 }}</fz-label>
    </div>
  </div>
</fz-panel>
