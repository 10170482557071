/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { NILernstandFormular } from './ni-lernstand-formular';
import { FormularViewType } from '../../formular/formular';
import { NIZeugnistypComponent } from '../projectables/ni-zeugnistyp.component';
import { NIZeugnissatzComponent } from '../projectables/ni-zeugnissatz.component';
import { NISchuelerComponent } from '../projectables/ni-schueler.component';
import { FormularBereich } from '../../../models/formular-bereich';

export class NI1234hjLernstandFormular extends NILernstandFormular {
  get viewType(): FormularViewType {
    return NI1234hjLernstandFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    faecher.push(
      this.lernstandMathematik,
      this.lernstandWeitereFaecher,
      this.lernstandMehrsprachigkeit,
      this.lernstandMethodenkompetenzen
    );
    return faecher;
  }

  get lernstandDeutschBereiche(): FormularBereich[] {
    return [
      this.lernstandDeutsch.hoerverstehen,
      this.lernstandDeutsch.lesen,
      this.lernstandDeutsch.texteVerfassen,
      this.lernstandDeutsch.muendlich,
    ];
  }

  get lernstandEnglischBereiche(): FormularBereich[] {
    return [
      this.lernstandEnglisch.hoerverstehen,
      this.lernstandEnglisch.sprechenZuhoeren,
      this.lernstandEnglisch.lesen,
      this.lernstandEnglisch.texteVerfassen,
      this.lernstandEnglisch.sprachermittlung,
    ];
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    return noteFields;
  }
}

@Component({
  selector: 'fz-ni-1234hj-lernstand-formular',
  templateUrl: 'ni-1234hj-lernstand-formular.component.html',
})
export class NI1234hjLernstandFormularComponent extends FormularComponentBase<NI1234hjLernstandFormular> {
  static createModel(formular: NI1234hjLernstandFormular) {
    return formular.zeugnis.anlageLernstandAktiv
      ? {
          zeugnistyp: NIZeugnistypComponent.createModel(formular),
          schueler: NISchuelerComponent.createModel(formular),
          zeugnissatz: NIZeugnissatzComponent.createModel(formular),
          deutschBereiche: formular.lernstandDeutschBereiche.map((bereich) => ({
            trackBy: bereich.key ?? 'gesamt',
            label: bereich.description.label,
            textField: bereich.text,
          })),
          englischBereiche: formular.lernstandEnglischBereiche.map((bereich) => ({
            trackBy: bereich.key ?? 'gesamt',
            label: bereich.description.label,
            textField: bereich.text,
          })),
          faecher: formular.faecher.map((fach) => ({
            trackBy: fach.key,
            key: fach.key,
            label: fach.gesamt.description.label,
            textField: fach.gesamt.text,
          })),
          lernstandEmpfehlungen: {
            label: formular.lernstandEmpfehlungen.description.label,
            textField: formular.lernstandEmpfehlungen,
          },
        }
      : undefined;
  }

  get model() {
    return NI1234hjLernstandFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<NI1234hjLernstandFormular>) {
    super(formularService);
  }
}
