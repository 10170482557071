import { AppInfoService } from './../../shared/services/app-info.service';
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './jahrgang-aendern-page.component.html',
  styleUrls: ['./jahrgang-aendern-page.component.scss'],
})
export class JahrgangAendernPageComponent implements AfterViewInit, OnInit {
  id: string = '';

  constructor(
    private appInfoService: AppInfoService,
    private route: ActivatedRoute
  ) {}
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Jahrgang ändern'));
  }
  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    this.id = routeParams.get('id') ?? '';
  }
}
