<ng-template fzTemplate="sidebar">
  <fz-he-schueler-typ></fz-he-schueler-typ>
  <fz-he-sav-typ></fz-he-sav-typ>
</ng-template>
<fz-formular
  name="HE1234hjIntensiv"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="100"
  [pageMarginBottomLast]="100"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  <fz-he-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-he-zeugniskopf>
  <fz-he-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-he-zeugnissatz>
  <fz-he-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-he-zeugnistyp>
  <fz-he-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-he-schueler>
  <fz-he-intensiv fzFormularItem="intensiv" fzContainer [model]="model.intensiv"></fz-he-intensiv>
  <fz-he-sav *ngIf="model.sav != null" fzFormularItem="sav" fzContainer [model]="model.sav"></fz-he-sav>
  <fz-section
    *ngIf="model.sozialLernArbeitsverhalten != null"
    fzFormularItem="sav"
    [label]="model.sozialLernArbeitsverhalten.label"
    headerClass="Ueberschriften2"
    headerPaddingBottom="normal"
  >
    <fz-verbal [field]="model.sozialLernArbeitsverhalten.textField" [width]="680"></fz-verbal>
  </fz-section>
  <fz-section
    fzFormularItem="massnahmen"
    label="{{ model.allgemeineBeurteilung.label }}:"
    headerClass="Ueberschriften2"
    headerPaddingBottom="normal"
  >
    <fz-verbal [field]="model.allgemeineBeurteilung.textField" [width]="680"></fz-verbal>
  </fz-section>
  <fz-he-leistungen-intensiv
    fzFormularItem="leistungenIntensiv"
    fzContainer
    [manualPageBreak]="true"
    [model]="model.leistungen"
  ></fz-he-leistungen-intensiv>
  <fz-section fzFormularItem="regelfaecher">
    <fz-panel fzSectionHeader class="Ueberschriften2">
      <div>
        <fz-label>Die Teilnahme am Regelunterricht erfolgte in folgenden Fächern:</fz-label>
      </div>
      <div style="display: grid; grid-template-columns: 1fr 1fr">
        <div style="grid-column: 1">
          <fz-label>Fach</fz-label>
        </div>
        <div style="grid-column: 2">
          <fz-label>Note</fz-label>
        </div>
      </div>
    </fz-panel>
    <fz-panel fzSectionHeader class="Ueberschriften2">
      <div>
        <fz-label>Die Teilnahme am Regelunterricht erfolgte in folgenden Fächern (Fortsetzung):</fz-label>
      </div>
      <div style="display: grid; grid-template-columns: 1fr 1fr">
        <div style="grid-column: 1">
          <fz-label>Fach</fz-label>
        </div>
        <div style="grid-column: 2">
          <fz-label>Note</fz-label>
        </div>
      </div>
    </fz-panel>
    <fz-fach-list [model]="model.regelfach" [fachList]="formular.regelfachList"></fz-fach-list>
  </fz-section>
  <fz-section fzFormularItem="freiwilligeFaecher">
    <fz-panel fzSectionHeader class="Ueberschriften2">
      <fz-label>Freiwillige Unterrichtsveranstaltungen:</fz-label>
    </fz-panel>
    <fz-panel fzSectionHeader class="Ueberschriften2">
      <fz-label>Freiwillige Unterrichtsveranstaltungen (Fortsetzung):</fz-label>
    </fz-panel>
    <fz-fach-list [model]="model.freiwilligesFach" [fachList]="formular.freiwilligesFachList"></fz-fach-list>
  </fz-section>
  <fz-he-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-he-versaeumnis>
  <fz-section
    fzFormularItem="bemerkungen"
    [label]="model.bemerkungen.label"
    headerClass="Ueberschriften2"
    headerPaddingBottom="normal"
  >
    <fz-intelli [field]="model.bemerkungen.textField" [width]="680"></fz-intelli>
  </fz-section>
  <fz-he-versetzungsvermerk
    fzFormularItem="versetzungsvermerk"
    fzContainer
    [model]="model.versetzungsvermerk"
  ></fz-he-versetzungsvermerk>
  <fz-he-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-he-unterschrift>
</fz-formular>
