<fz-panel
  [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
  padding="normal"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div style="text-align: center" class="Zeugnistitel">
    <fz-label>Anlage zum {{ formular.zeugnisTypLabel }}</fz-label>
  </div>
  <div style="text-align: center" class="Schuljahr">
    <fz-label>{{ formular.zeugnissatz.klassenstufeLabel }}</fz-label>
  </div>
  <div style="text-align: center" class="Schuljahr">
    <fz-label>Schuljahr {{ formular.zeugnissatz.schuljahrLabel }}</fz-label>
  </div>
</fz-panel>
