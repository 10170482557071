<ng-template fzTemplate="sidebar">
  <fz-apply-kp></fz-apply-kp>
  <fz-he-anlage-inhalt-typ></fz-he-anlage-inhalt-typ>
</ng-template>
<fz-formular
  name="HE234hjSav"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  <ng-container *ngIf="model != null">
    <fz-he-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-he-zeugniskopf>
    <fz-he-seitenkopf fzFormularItem="seitenkopf" fzContainer [hidePage]="true"></fz-he-seitenkopf>
    <fz-section
      *ngIf="model.kpTyp != null"
      fzFormularItem="arbeitsSozialverhaltenAnlage"
      [label]="model.kpTyp.label"
      headerClass="Ueberschriften"
    >
      <fz-deco [border]="formular.showBorder ? borderDirection.all : borderDirection.none">
        <fz-sequence>
          <fz-kp [model]="model.kpTyp.kp" [bereich]="formular.sozialLernArbeitsverhalten.gesamt"></fz-kp>
          <fz-optional [field]="model.kpTyp.verbalVisibleField" label="Verbale Beurteilung">
            <fz-deco
              *ngIf="model.kpTyp.textField != null"
              [padding]="formular.showBorder ? 'normal' : 'none'"
              paddingTop="normal"
              paddingBottom="normal"
            >
              <fz-verbal
                [field]="model.kpTyp.textField"
                [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
              ></fz-verbal>
            </fz-deco>
          </fz-optional>
        </fz-sequence>
      </fz-deco>
    </fz-section>
    <fz-verbal
      *ngIf="model.freitextTyp != null"
      fzFormularItem="freierText"
      [field]="model.freitextTyp.textField"
      [width]="680"
    ></fz-verbal>
    <fz-he-unterschrift-optional
      fzFormularItem="unterschrift"
      fzContainer
      [model]="model.unterschrift"
    ></fz-he-unterschrift-optional>
  </ng-container>
</fz-formular>
