import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-versaeumnis',
  templateUrl: 'sl-versaeumnis.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLVersaeumnisComponent)],
})
export class SLVersaeumnisComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      fehltageEntschuldigtField: formular.fehltageEntschuldigt,
      fehlstundenEntschuldigtField: formular.fehlstundenEntschuldigt,
      fehltageUnentschuldigtField: formular.fehltageUnentschuldigt,
      fehlstundenUnentschuldigtField: formular.fehlstundenUnentschuldigt,
    };
  }

  @Input() model: ReturnType<typeof SLVersaeumnisComponent.createModel> | undefined;
}
