import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { SLFolgeseiteComponent } from '../projectables/sl-folgeseite.component';
import { SLSeitenfussComponent } from '../projectables/sl-seitenfuss.component';
import { SLZeugnisFormular } from './sl-zeugnis-formular';
import { SLFormular } from './sl-formular';
import { FormularViewType } from '../../formular/formular';
import { SLZeugnissatzComponent } from '../projectables/sl-zeugnissatz.component';
import { SLSchulbesuchsjahrComponent } from '../projectables/sl-schulbesuchsjahr.component';
import { SLSchuelerComponent } from '../projectables/sl-schueler.component';
import { SLVersaeumnisComponent } from '../projectables/sl-versaeumnis.component';
import { SLSchulortComponent } from '../projectables/sl-schulort.component';
import { SLUnterschriftComponent } from '../projectables/sl-unterschrift.component';

export class SL1234hjGeistigFormular extends SLZeugnisFormular {
  get viewType(): FormularViewType {
    return SL1234hjGeistigFormularComponent;
  }
}

@Component({
  selector: 'fz-sl-1234hj-geistig-formular',
  templateUrl: './sl-1234hj-geistig-formular.component.html',
})
export class SL1234hjGeistigFormularComponent extends FormularComponentBase<SL1234hjGeistigFormular> {
  static createModel(formular: SLFormular) {
    return formular.zeugnis.zeugnissatz.halbjahr === 1 ||
      (formular.zeugnis.formularTyp != null && formular.zeugnis.formularTyp !== 'Kein')
      ? {
          zeugnissatz: SLZeugnissatzComponent.createModel(formular),
          schulbesuchsjahr: SLSchulbesuchsjahrComponent.createModel(formular),
          schueler: SLSchuelerComponent.createModel(formular),
          sozialverhalten: {
            label: 'Sozialverhalten',
            subLabel:
              '(z.B. Gewissenhaftigkeit, Zuverlässigkeit, Kooperationsbereitschaft, Hilfsbereitschaft, angemessener Umgang mit Konflikten, Selbstkontrolle und -reflexion, Selbstständigkeit, Toleranz, Teamfähigkeit, Einhalten von Regeln)',
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
          },
          lernArbeitsverhalten: {
            label: 'Lern- und Arbeitsverhalten',
            subLabel:
              '(z.B. Anstrengungsbereitschaft, Ausdauer, Aufmerksamkeit, Lernorganisation, Lernreflexion, Sorgfalt)',
            textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
          },
          besondereFaehigkeiten: {
            label: 'Besondere Fähigkeiten/Fertigkeiten',
            textField: formular.besondereFaehigkeiten,
          },
          fachbezogeneLernLeistungsentwicklung: {
            label: 'Fachbezogene Lern- und Leistungsentwicklung',
            textField: formular.fachbezogeneLernLeistungsentwicklung.gesamt.text,
          },
          bemerkungen: {
            label: 'Bemerkungen',
            textField: formular.bemerkungen,
          },
          versaeumnis: SLVersaeumnisComponent.createModel(formular),
          schulort: SLSchulortComponent.createModel(formular),
          unterschrift: SLUnterschriftComponent.createModel(formular),
        }
      : undefined;
  }

  get model() {
    return SL1234hjGeistigFormularComponent.createModel(this.formular);
  }

  slFolgeseiteComponent = SLFolgeseiteComponent;
  slSeitenfussComponent = SLSeitenfussComponent;
  constructor(formularService: FormularService<SL1234hjGeistigFormular>) {
    super(formularService);
  }
}
