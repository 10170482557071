<fz-panel style="height: 95px">
  <div style="display: grid; grid-template-columns: 189px 491px">
    <div style="grid-column: 1; font-size: 12pt">
      <fz-image *ngIf="formular.isAbschluss" [width]="151" src="/assets/laender/bw/Wappen.jpg"></fz-image>
      <fz-label *ngIf="!formular.isAbschluss">Baden-Württemberg</fz-label>
    </div>
    <div style="grid-column: 2">
      <fz-zeugniskopf-image
        [width]="491"
        [src]="formular.zeugnissatz.zeugniskopflizenz.zeugniskopf"
      ></fz-zeugniskopf-image>
    </div>
  </div>
</fz-panel>
