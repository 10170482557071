<fz-panel *ngIf="model != null">
  <div style="display: grid; grid-template-columns: 189px 1fr 189px; column-gap: 19px">
    <div style="grid-column: 1">
      <fz-label class="Allgemeiner_Zeugnis_Text">Schuljahr</fz-label>&nbsp;<fz-label class="Schuljahr">{{
        formular.zeugnissatz.schuljahrLabel
      }}</fz-label>
    </div>
    <div style="grid-column: 2; text-align: center">
      <fz-label class="Allgemeiner_Zeugnis_Text">Jahrgangsstufe/Schulbesuchsjahr</fz-label>&nbsp;<fz-text
        class="Schuljahr"
        placeholder="JS/SBJ"
        [field]="model.schulbesuchsjahrTextField"
      ></fz-text>
    </div>
    <div style="grid-column: 3; text-align: right">
      <fz-dropdown
        class="Allgemeiner_Zeugnis_Text"
        placeholder="Klasse/Lerngr"
        [field]="model.klasseLerngruppeField"
      ></fz-dropdown
      >&nbsp;<fz-text
        class="Schuljahr"
        [field]="model.klassenbezeichnungField"
        placeholder="Klassenbezeichnung"
      ></fz-text>
    </div>
  </div>
</fz-panel>
