import { SLFolgeseiteComponent } from '../projectables/sl-folgeseite.component';
import { SLLegendeComponent } from '../projectables/sl-legende.component';
import { SLSeitenfussComponent } from '../projectables/sl-seitenfuss.component';
import { SLFormularCategoryKey } from '../sl-formularsatz';
import { SLFormular } from './sl-formular';

export abstract class SLZeugnisFormular extends SLFormular {
  readonly page1Items12MarginBottom = 19;
  readonly page1Items34MarginBottom = 38;
  readonly page2ItemsMarginBottom = 38;

  slFolgeseiteComponent = SLFolgeseiteComponent;
  slSeitenfussComponent = SLSeitenfussComponent;
  slLegendeComponent = SLLegendeComponent;

  get category(): SLFormularCategoryKey {
    return 'zeugnis';
  }
}
