<p-dialog
  header="Textbaustein auswählen"
  [(visible)]="dialogVisible"
  [style]="{ width: '75vw' }"
  [baseZIndex]="10000"
  [maximizable]="true"
  [modal]="true"
  [resizable]="true"
  [contentStyle]="{ height: '50vh' }"
  styleClass="p-fluid"
  [appendTo]="'body'"
>
  <div class="flex flex-col lg:flex-row h-full">
    <p-tree
      class="flex-shrink-0 w-96"
      scrollHeight="flex"
      [value]="kategorienTree"
      selectionMode="checkbox"
      [(selection)]="selectedKategorien"
      (onNodeSelect)="nodeSelectKategorie()"
      (onNodeUnselect)="nodeUnSelectKategorie($event)"
      styleClass="fz-tree-node"
      [loading]="loadingKategorien"
    ></p-tree>
    <p-tree
      class="flex-shrink-0"
      scrollHeight="flex"
      [value]="notenTree"
      selectionMode="checkbox"
      [(selection)]="selectedNoten"
      (onNodeSelect)="nodeSelectKategorie()"
      (onNodeUnselect)="nodeSelectKategorie()"
      styleClass="fz-tree-node"
    >
      <ng-template pTemplate="header">
        Noten
        <button pButton type="button" label="Alle" (click)="selectAll()" class="p-button-text p-button-sm"></button>
        <button pButton type="button" label="Keine" (click)="selectNone()" class="p-button-text p-button-sm"></button>
      </ng-template>
      <ng-template let-node pTemplate="default">
        <p-tag
          styleClass="mr-2 w-12"
          [value]="node.label"
          [rounded]="true"
          [class]="getColor(node.label)"
        ></p-tag> </ng-template
    ></p-tree>
    <p-tree
      class="flex-1 w-full"
      scrollHeight="flex"
      [value]="beurteilungenTree"
      selectionMode="single"
      [metaKeySelection]="true"
      [(selection)]="selectedBeurteilung"
      [emptyMessage]="keineErgebnisseMeldung"
      styleClass="fz-tree-node"
      [filter]="true"
      [loading]="loadingBeurteilungen"
      (dblclick)="textSelected($event)"
    >
      <ng-template let-node pTemplate="default">
        <div class="flex flex-row w-full gap-2">
          <p-tag
            styleClass="mr-2 w-12"
            [value]="node.data.note"
            [rounded]="true"
            [class]="getColor(node.data?.note)"
          ></p-tag>
          <div class="border-gray-200 border-b preserve-white-space">{{ node.label }}</div>
        </div>
      </ng-template>
    </p-tree>
  </div>
  <ng-template pTemplate="footer">
    <button
      aria-label="Eigene Textbausteine bearbeiten"
      type="button"
      pButton
      icon="pi pi-pencil"
      (click)="edit()"
      label="Eigene Textbausteine bearbeiten"
      class="float-left p-button-text"
    ></button>
    <!-- <div class="h-full flex flex-col justify-items-center"> -->
    <!-- <p-checkbox class="float-left" label="Nur passende" [(ngModel)]="isSyntax" [binary]="true"></p-checkbox> -->
    <!-- </div> -->
    <button type="button" pButton icon="pi pi-times" (click)="cancel()" label="Schließen"></button>
    <button
      type="button"
      pButton
      icon="pi pi-plus"
      (click)="textSelected($event)"
      label="Einfügen"
      [disabled]="selectedBeurteilung == null"
    ></button>
  </ng-template>
</p-dialog>
<fz-textbaustein-editor #editor (itemsChanged)="onItemsChangedTextbausteine($event)"></fz-textbaustein-editor>
