import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';
import { BWFormular } from '../formulare/bw-formular';

@Component({
  selector: 'fz-bw-gespraech',
  templateUrl: 'bw-gespraech.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, BWGespraechComponent)],
})
export class BWGespraechComponent extends BWPanelDecoratorBase {
  static createModel(formular: BWFormular) {
    return {
      erwuenschtField: formular.gespraechErwuenscht,
    };
  }

  @Input() model: ReturnType<typeof BWGespraechComponent.createModel> | undefined;
}
