<div *ngIf="formular.zeugnis.schuelerTyp != null">
  Die Wahl einer Anlage steht nicht zur Verfügung, da das ausgewählte Zeugnisformular offiziell keine Anlagen zulässt.
</div>
<div *ngIf="formular.zeugnis.schuelerTyp == null">
  <div>
    <p-radioButton [value]="'Keine'" [(ngModel)]="formular.zeugnis.anlageTyp" label="Keine Anlage"></p-radioButton>
  </div>
  <div>
    <p-radioButton [value]="null" [(ngModel)]="formular.zeugnis.anlageTyp" label="Kompetenzbeurteilung"></p-radioButton>
  </div>
  <div>
    <p-radioButton [value]="'Allgemein'" [(ngModel)]="formular.zeugnis.anlageTyp" label="Freitext"></p-radioButton>
  </div>
  @if (formular.zeugnis.anlageTyp == null) {
    <button
      class="mt-2"
      pButton
      type="button"
      [text]="true"
      label="Einrichtung des Kompetenzrasters"
      routerLink="/koennensprofil-editor"
    ></button>
  }
</div>
