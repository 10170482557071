<ng-template fzTemplate="sidebar">
  <fz-he-anlage-typ></fz-he-anlage-typ>
</ng-template>
<fz-formular
  name="HE234hjFreitext"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  <fz-he-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-he-zeugniskopf>
  <fz-he-seitenkopf fzFormularItem="seitenkopf" fzContainer [hidePage]="true"></fz-he-seitenkopf>
  <fz-verbal fzFormularItem="freierText" [field]="model.anlageFreitextField" [width]="680"></fz-verbal>
  <fz-he-unterschrift-optional
    fzFormularItem="unterschrift"
    fzContainer
    [model]="model.unterschrift"
  ></fz-he-unterschrift-optional>
</fz-formular>
