<fz-panel
  *ngIf="model != null"
  name="Zeugnissatz"
  padding="normal"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div style="text-align: center" class="Zeugnistitel">
    <fz-label *ngIf="model.label != null">{{ model.label }}</fz-label>
    <fz-dropdown *ngIf="model.dropDownField != null" [field]="model.dropDownField"></fz-dropdown>
  </div>
</fz-panel>
