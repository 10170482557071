<ng-content></ng-content>
<fz-panel #empty>
  <fz-label shadow="[...]"></fz-label>
</fz-panel>
<ng-container
  *ngFor="let blockModel of blockModels"
  fzBlock
  [content]="content"
  [backDeco]="backDeco"
  [frontDeco]="frontDeco"
>
  <ng-template #content="fzPortal" fzPortal>
    <fz-translate [fzPosition]="blockModel.rect" tabindex="0" #focusableContent fzFocusable>
      <ng-container [fzPortalOutlet]="blockModel.block.content"></ng-container>
      <fz-translate *ngIf="blockModel.block.content == null">
        <ng-container [fzPortalOutlet]="empty.content"></ng-container>
      </fz-translate>
    </fz-translate>
  </ng-template>
  <ng-template #backDeco="fzPortal" fzPortal>
    <div style="position: absolute">
      <ng-container [fzPortalOutlet]="blockModel.block.backDeco"></ng-container>
    </div>
    <!-- <div [fzRect]="blockModel.rect">
      <div class="plausi-ok"></div>
    </div> -->
  </ng-template>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div
      *ngIf="blockModel.isFirst && hasFocus"
      fzFocusable
      style="position: absolute; background-color: white"
      [style.bottom.px]="-blockModel.rect.top"
      [style.left.px]="blockModel.rect.left"
      [style.width.px]="blockModel.rect.width"
      tabindex="0"
      class="floating"
    >
      <p-checkbox [label]="label" [binary]="true" [(ngModel)]="isVisible" (onChange)="onCheckboxChange()"></p-checkbox>
    </div>
    <div style="position: absolute">
      <ng-container [fzPortalOutlet]="blockModel.block.frontDeco"></ng-container>
    </div>
  </ng-template>
</ng-container>
