<fz-panel *ngIf="model != null">
  <fz-select
    placeholder="Versetzungsvermerk"
    emptyLabel="(Bleibt leer - es wird nichts gedruckt)"
    [emptyValue]="'Leer'"
    class="Allgemeiner_Zeugnis_Text"
    [field]="model.selectField"
  >
    <fz-panel fzSelectCandidate [fzValue]="'Versetzt'" fzLabel="Auf Beschluss der Klassenkonferenz ... versetzt.">
      <fz-label>Auf Beschluss der Klassenkonferenz vom </fz-label><fz-date [field]="model.dateField"></fz-date
      ><fz-label> in die Klassenstufe {{ formular.zeugnissatz.klassenstufe + 1 }} versetzt.</fz-label>
    </fz-panel>
    <fz-panel fzSelectCandidate [fzValue]="'FreierText'" fzLabel="(Freier Text)">
      <fz-text placeholder="Freier Text für Versetzungsvermerk" [field]="model.textField"></fz-text>
    </fz-panel>
  </fz-select>
</fz-panel>
