import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-leistungen-intensiv',
  templateUrl: 'he-leistungen-intensiv.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, HELeistungenIntensivComponent)],
})
export class HELeistungenIntensivComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      zuhoeren: {
        label: 'Hören',
        noteField: formular.deutschIntensiv.zuhoeren.note,
      },
      lesen: {
        label: 'Lesen',
        noteField: formular.deutschIntensiv.lesen.note,
      },
      sprechen: {
        label: 'Sprechen',
        noteField: formular.deutschIntensiv.sprechen.note,
      },
      texteVerfassen: {
        label: 'Schreiben',
        noteField: formular.deutschIntensiv.texteVerfassen.note,
      },
    };
  }

  @Input() model: ReturnType<typeof HELeistungenIntensivComponent.createModel> | undefined;
}
