import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-intensiv',
  templateUrl: 'he-intensiv.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, HEIntensivComponent)],
})
export class HEIntensivComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      vonField: formular.intensivklasseVon,
      bisField: formular.intensivklasseBis,
      typField: formular.intensivklasseTyp,
    };
  }

  @Input() model: ReturnType<typeof HEIntensivComponent.createModel> | undefined;
}
