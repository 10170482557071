import jsPDF from 'jspdf';
import { Quantity } from '../dom/quantity';
import { ContentElement } from './content-element';

export class TranslateElement extends ContentElement {
  constructor() {
    super();
  }
  exportPdf(doc: jsPDF, pageRect: DOMRect): void {
    for (const child of Array.from(this.childNodes)) {
      if (child instanceof ContentElement) {
        child.exportPdf(doc, pageRect);
      } else if (child instanceof HTMLImageElement) {
        const nodeRect = child.getBoundingClientRect();
        doc.addImage(
          child,
          '',
          Quantity.px2Mm(nodeRect.left - pageRect.left),
          Quantity.px2Mm(nodeRect.top - pageRect.top),
          Quantity.px2Mm(nodeRect.width),
          Quantity.px2Mm(nodeRect.height)
        );
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'fz-translate': TranslateElement;
  }
}
