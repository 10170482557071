/* eslint-disable no-bitwise */
export enum BorderDirection {
  none = 0,
  top = 1 << 0,
  bottom = 1 << 1,
  left = 1 << 2,
  right = 1 << 3,
  all = ~(~0 << 4),
  cup = bottom | left | right,
  inverseCup = top | left | right,
  leftTop = left | top,
  leftRight = left | right,
  leftOpen = top | right | bottom,
  rightOpen = top | left | bottom,
}

export function isBorder(border: BorderDirection, direction: BorderDirection): boolean {
  return (border & direction) === direction;
}
export const borderWidthNormal = 1;
