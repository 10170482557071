import { AppInfoService } from './../../shared/services/app-info.service';
import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-profile-page',
  templateUrl: 'profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Mein Profil'));
  }
}
