import { SchuelerlisteImportComponent } from './../schuelerliste-import/schuelerliste-import.component';
import { Component, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { WorkspaceService } from '../../../../shared/services';

@Component({
  selector: 'fz-schuelerliste-list',
  templateUrl: './schuelerliste-list.component.html',
  styleUrls: ['./schuelerliste-list.component.scss'],
})
export class SchuelerlisteListComponent {
  @ViewChild(SchuelerlisteImportComponent) importComponent?: SchuelerlisteImportComponent;
  importDialogVisible: boolean = false;

  constructor(public schuelerService: WorkspaceService) {}
  customSort(event: SortEvent): void {
    event.data?.sort((data1, data2) => {
      const aktiv1 = data1.schueler.isAktiv === true ? 0 : 1;
      const aktiv2 = data2.schueler.isAktiv === true ? 0 : 1;

      if (event.field == null || event.field === 'name') {
        return (
          aktiv1 - aktiv2 ||
          (String(data1.schueler.name ?? '').localeCompare(String(data2.schueler.name ?? ''), 'de-DE') ||
            String(data1.schueler.vorname).localeCompare(String(data2.schueler.vorname ?? ''), 'de-DE')) *
            (event.order ?? 1)
        );
      } else {
        return (
          aktiv1 - aktiv2 ||
          (String(data1.schueler.vorname ?? '').localeCompare(String(data2.schueler.vorname ?? ''), 'de-DE') ||
            String(data1.schueler.name ?? '').localeCompare(String(data2.schueler.name ?? ''), 'de-DE')) *
            (event.order ?? 1)
        );
      }
    });
  }

  importStart(): void {
    this.importDialogVisible = true;
    this.importComponent?.restart();
  }
}
