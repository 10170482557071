<ng-template fzTemplate="sidebar">
  <fz-sl-schueler-typ></fz-sl-schueler-typ>
  <fz-sl-formular-typ></fz-sl-formular-typ>
</ng-template>
<fz-formular
  name="SL4hStandard"
  [pageMarginTop]="151"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="113"
  [pageHeader]="formular.slFolgeseiteComponent"
  [pageFooter]="formular.slSeitenfussComponent"
  [lastPageFooter]="formular.slLegendeComponent"
>
  <fz-sl-zeugniskopf
    fzFormularItem="zeugniskopf"
    [marginBottom]="formular.page1Items34MarginBottom"
    fzContainer
  ></fz-sl-zeugniskopf>
  <fz-sl-zeugnissatz
    fzFormularItem="zeugnissatz"
    [marginBottom]="formular.page1Items34MarginBottom"
    fzContainer
    [model]="model.zeugnissatz"
  ></fz-sl-zeugnissatz>
  <fz-sl-schueler
    fzFormularItem="schueler"
    [marginBottom]="formular.page1Items34MarginBottom"
    fzContainer
    [model]="model.schueler"
  ></fz-sl-schueler>
  <fz-section
    fzFormularItem="entwicklungsbericht"
    [manualPageBreak]="true"
    label="Entwicklungsbericht"
    headerClass="Ueberschriften"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="none"
    >
      <fz-sequence>
        <fz-section *ngFor="let fach of model.faecher; trackBy: trackByProperty">
          <fz-panel fzSectionHeader paddingBottom="normal" paddingTop="normal" class="Ueberschriften2">
            <fz-label>{{ fach.label }}</fz-label>
          </fz-panel>
          <fz-panel fzSectionHeader paddingBottom="normal" paddingTop="normal" class="Ueberschriften2">
            <fz-label>{{ fach.label }} (Fortsetzung)</fz-label>
          </fz-panel>
          <fz-verbal
            [field]="fach.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-section>
        <fz-section>
          <fz-panel fzSectionHeader paddingBottom="normal" paddingTop="normal" class="Ueberschriften2">
            <fz-label>f) {{ model.besondereFaehigkeiten.label }}</fz-label>
          </fz-panel>
          <fz-panel fzSectionHeader paddingBottom="normal" paddingTop="normal" class="Ueberschriften2">
            <fz-label>f) {{ model.besondereFaehigkeiten.label }} (Fortsetzung)</fz-label>
          </fz-panel>
          <fz-intelli
            [field]="model.besondereFaehigkeiten.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-intelli>
        </fz-section>
      </fz-sequence>
    </fz-deco>
  </fz-section>
  <fz-deco
    fzFormularItem="leistungen"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-sl-leistungen fzContainer [model]="model.leistungen"></fz-sl-leistungen>
  </fz-deco>
  <fz-deco
    fzFormularItem="sprachenportfolio"
    [marginBottom]="formular.page2ItemsMarginBottom"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-sl-sprachenportfolio fzContainer [model]="model.sprachenportfolio"></fz-sl-sprachenportfolio>
  </fz-deco>
  <fz-section
    fzFormularItem="bemerkungen"
    [marginBottom]="formular.page2ItemsMarginBottom"
    [label]="model.bemerkungen.label"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    headerPaddingBottom="normal"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-intelli
        [field]="model.bemerkungen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="entwicklungZusammenfassung"
    [marginBottom]="formular.page2ItemsMarginBottom"
    [label]="model.entwicklungZusammenfassung.label"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    headerPaddingBottom="normal"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-intelli
        [field]="model.entwicklungZusammenfassung.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-sl-versaeumnis
    fzFormularItem="versaeumnis"
    [marginBottom]="formular.page2ItemsMarginBottom"
    fzContainer
    [model]="model.versaeumnis"
  ></fz-sl-versaeumnis>
  <fz-sl-schulort fzFormularItem="schulort" [marginBottom]="0" fzContainer [model]="model.schulort"></fz-sl-schulort>
  <fz-sl-unterschrift
    fzFormularItem="unterschrift"
    [marginBottom]="formular.page2ItemsMarginBottom"
    fzContainer
    [model]="model.unterschrift"
  ></fz-sl-unterschrift>
</fz-formular>
