import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { WorkspaceService } from '../../services';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import { SortEvent, MenuItem, ConfirmationService } from 'primeng/api';
import { Zeugnis } from '../../../models/zeugnis';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormularFactory } from '../../../modules/formular/formular-factory';

@Component({
  selector: 'fz-sl-schueler-grid',
  templateUrl: './schueler-grid.component.html',
  styleUrls: ['./schueler-grid.component.scss'],
})
export class SchuelerGridComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: Table | undefined;
  @Input() category: string | undefined;
  @Output() initiallySelected = new EventEmitter<void>();
  @Output() needsButtonSpaceChange = new EventEmitter<boolean>();
  appearance: 'normal' | 'narrow' | 'overlay' = 'normal';
  private normalMinimized = false;
  private narrowMinimized = true;
  private overlayMinimized = true;
  splitOptions: MenuItem[] = [];

  private subscriptions: Subscription[] = [];
  deletingItems: Zeugnis[] = [];

  get needsButtonSpace() {
    return this.appearance === 'overlay' && this.minimized;
  }

  constructor(
    public workspaceService: WorkspaceService,
    private confirmationService: ConfirmationService,
    private changeDetector: ChangeDetectorRef,
    private elementRef: ElementRef,
    private breakpointObserver: BreakpointObserver,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    if (sessionStorage.getItem('schuelerGridNormalMinimized') != null) {
      this.normalMinimized = sessionStorage.getItem('schuelerGridNormalMinimized') === 'true';
    }
    if (sessionStorage.getItem('schuelerGridNarrowMinimized') != null) {
      this.narrowMinimized = sessionStorage.getItem('schuelerGridNarrowMinimized') !== 'false';
    }
    if (sessionStorage.getItem('schuelerGridOverlayMinimized') != null) {
      this.overlayMinimized = sessionStorage.getItem('schuelerGridOverlayMinimized') !== 'false';
    }
    this.updateAppearance();
    this.subscriptions.push(
      this.breakpointObserver.observe(['(min-width: 1260px)']).subscribe((_state: BreakpointState) => {
        this.updateAppearance();
      }),
      this.breakpointObserver.observe(['(max-width: 899px)']).subscribe((_state: BreakpointState) => {
        this.updateAppearance();
      })
    );

    this.subscriptions.push(
      this.workspaceService.selectedZeugnissatzChange.subscribe(() => this.changeDetector.detectChanges()),
      this.workspaceService.dataLoadedBehaviour.subscribe(() => {
        this.changeDetector.detectChanges();
        this.workspaceService.selectedZeugnis = this.workspaceService.selectedZeugnisse[0];
        this.initiallySelected.emit();
      }),
      this.workspaceService.schuelerAddedBehaviour.subscribe((zeugnis) => {
        this.changeDetector.detectChanges();
        this.grid?.selectionChange.emit(zeugnis);
        this.grid?.sortSingle();
        const el = document.getElementById(zeugnis.id);
        this.grid?.scrollTo({ top: el?.offsetTop });
        el?.scrollTo();
      })
    );
    this.zone.runOutsideAngular(() => {
      window.addEventListener('click', this.onClick);
    });
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) subscription.unsubscribe();
    window.removeEventListener('click', this.onClick);
  }

  get minimized() {
    switch (this.appearance) {
      case 'narrow':
        return this.narrowMinimized;
      case 'overlay':
        return this.overlayMinimized;
      default:
        return this.normalMinimized;
    }
  }

  set minimized(value) {
    switch (this.appearance) {
      case 'normal':
        this.normalMinimized = value;
        if (value) sessionStorage.setItem('schuelerGridNormalMinimized', 'true');
        else sessionStorage.removeItem('schuelerGridNormalMinimized');
        break;
      case 'narrow':
        this.narrowMinimized = value;
        if (!value) sessionStorage.setItem('schuelerGridNarrowMinimized', 'false');
        else sessionStorage.removeItem('schuelerGridNarrowMinimized');
        break;
      case 'overlay':
        this.overlayMinimized = value;
        if (!value) sessionStorage.setItem('schuelerGridOverlayMinimized', 'false');
        else sessionStorage.removeItem('schuelerGridOverlayMinimized');
        break;
    }
    this.needsButtonSpaceChange.emit(this.needsButtonSpace);
  }

  private updateAppearance() {
    if (this.breakpointObserver.isMatched(['(min-width: 1260px)'])) this.appearance = 'normal';
    else if (this.breakpointObserver.isMatched(['(max-width: 899px)'])) this.appearance = 'overlay';
    else this.appearance = 'narrow';
    this.needsButtonSpaceChange.emit(this.needsButtonSpace);
  }

  onClick = (e: Event): void => {
    if (this.appearance !== 'normal') {
      if (!this.elementRef.nativeElement.contains(e.target)) {
        this.minimized = true;
        this.changeDetector.detectChanges();
      }
    }
  };

  delete(zeugnis: Zeugnis): void {
    this.confirmationService.confirm({
      message: 'Wollen Sie den Schüler wirklich löschen?',
      accept: async () => {
        try {
          this.deletingItems.push(zeugnis);
          await this.workspaceService.deleteSchueler(zeugnis);
        } finally {
          const indx = this.deletingItems.indexOf(zeugnis);
          this.deletingItems.splice(indx, 1);
        }
      },
      header: 'Schüler löschen?',
    });
  }

  isDeleting(zeugnis: Zeugnis) {
    return this.deletingItems.indexOf(zeugnis) >= 0;
  }

  toggleMenuOptions(zeugnis: Zeugnis): void {
    if (zeugnis.schueler.isAktiv) {
      this.splitOptions = [
        {
          label: 'Löschen',
          icon: 'pi pi-trash',
          command: () => {
            this.delete(zeugnis);
          },
        },
        {
          label: 'Nicht weiterführen',
          icon: 'pi pi-times',
          command: () => {
            this.nichtWeiterfuehren(zeugnis);
          },
        },
      ];
    } else {
      this.splitOptions = [
        {
          label: 'Weiterführen',
          icon: 'pi pi-replay',
          command: () => {
            this.weiterfuehren(zeugnis);
          },
        },
      ];
    }
  }

  nichtWeiterfuehren(zeugnis: Zeugnis): void {
    this.confirmationService.confirm({
      message: 'Soll der Schüler wirklich nicht weitergeführt werden?',
      accept: () => {
        this.workspaceService.deactivateSchueler(zeugnis).then(() => {
          if (this.grid) {
            this.grid.sortSingle();
          }
        });
      },
      header: 'Schüler nicht weiterführen?',
    });
  }

  weiterfuehren(zeugnis: Zeugnis): void {
    this.workspaceService.reactivateSchueler(zeugnis).then(() => {
      if (this.grid) {
        this.grid.sortSingle();
      }
    });
  }

  customSort(event: SortEvent): void {
    event.data?.sort((data1, data2) => {
      const aktiv1 = data1.schueler.isAktiv === true ? 0 : 1;
      const aktiv2 = data2.schueler.isAktiv === true ? 0 : 1;

      if (event.field == null || event.field === 'name') {
        return (
          aktiv1 - aktiv2 ||
          (String(data1.schueler.name ?? '').localeCompare(String(data2.schueler.name ?? ''), 'de-DE') ||
            String(data1.schueler.vorname ?? '').localeCompare(String(data2.schueler.vorname ?? ''), 'de-DE')) *
            (event.order ?? 1)
        );
      } else {
        return (
          aktiv1 - aktiv2 ||
          (String(data1.schueler.vorname ?? '').localeCompare(String(data2.schueler.vorname ?? ''), 'de-DE') ||
            String(data1.schueler.name ?? '').localeCompare(String(data2.schueler.name ?? ''), 'de-DE')) *
            (event.order ?? 1)
        );
      }
    });
  }

  onMinMaxClicked(_event: any) {
    this.minimized = !this.minimized;
    localStorage.setItem('schuelerGridMinimized', JSON.stringify(this.minimized));
  }

  isValid(zeugnis: Zeugnis): boolean {
    if (this.category == null) return true;
    if (!zeugnis.schueler.isAktiv) return true;
    return FormularFactory.createFormular(zeugnis, this.category).isValid;
  }

  formularSummary(zeugnis: Zeugnis) {
    if (this.category == null) return undefined;
    if (!zeugnis.schueler.isAktiv) return undefined;
    if (
      !FormularFactory.getFormularsatz(zeugnis)
        .getCategories(zeugnis)
        .map((c) => c.key)
        .includes(this.category)
    )
      return undefined;
    return FormularFactory.createFormular(zeugnis, this.category).summary;
  }

  getInitialen(zeugnis: Zeugnis) {
    return String(zeugnis.schueler.name ?? '').substring(0, 1) + String(zeugnis.schueler.vorname ?? '').substring(0, 1);
  }
}
