/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NodeWalker } from './node-walker';
import itiriri from 'itiriri';
import { TextRange } from '../pdl/text-range';
import { StringHelper, WordRange } from './string-helper';

export type CollapsedRange = { node: Node; offset: number };
export type NodeRange = { node: Node; startOffset: number; endOffset: number };
export type FullRange = {
  startNode: Node;
  startOffset: number;
  endNode: Node;
  endOffset: number;
};

function isCollapsedRange(range: CollapsedRange | NodeRange | FullRange): range is CollapsedRange {
  const collapsedRange = range as CollapsedRange;
  return collapsedRange.node !== undefined && collapsedRange.offset !== undefined;
}

function isNodeRange(range: CollapsedRange | NodeRange | FullRange): range is NodeRange {
  const nodeRange = range as NodeRange;
  return nodeRange.node !== undefined && nodeRange.startOffset !== undefined;
}

function isFullRange(range: CollapsedRange | NodeRange | FullRange): range is FullRange {
  const fullRange = range as FullRange;
  return fullRange.startNode !== undefined;
}

export type TextAtom = {
  rootNode: Node;
  textNode: Text;
  range: WordRange;
};

export class NodeHelper {
  private static readonly range: Range = new Range();
  private static topDiffs = new Map<string, { topDiff: number; height: number }>();

  public static getBoundingClientRect(range: Node | CollapsedRange | NodeRange | FullRange): DOMRect {
    this.updateRange(range);
    const rect = this.range.getBoundingClientRect();
    return rect;
  }

  public static extractContents(range: Node | CollapsedRange | NodeRange | FullRange): DocumentFragment {
    this.updateRange(range);
    return this.range.extractContents();
  }

  public static cloneContents(range: Node | CollapsedRange | NodeRange | FullRange): DocumentFragment {
    this.updateRange(range);
    return this.range.cloneContents();
  }

  public static deleteContents(range: Node | CollapsedRange | NodeRange | FullRange): void {
    this.updateRange(range);
    return this.range.deleteContents();
  }

  public static insertNode(range: Node | CollapsedRange | NodeRange | FullRange, node: Node): void {
    this.updateRange(range);
    this.range.insertNode(node);
  }

  public static join(separator: Node, ...fragments: Node[]): DocumentFragment {
    const fragment = document.createDocumentFragment();
    for (let i = 0; i < fragments.length; i++) {
      fragment.appendChild(fragments[i]);
      if (i < fragments.length - 1) fragment.appendChild(separator.cloneNode(true));
    }
    return fragment;
  }

  public static empty(node: Node): void {
    this.deleteContents(node);
  }

  public static replaceSpaceWithNbsp(node: Node): void {
    const walker = new NodeWalker(node);
    for (const text of walker.textNodes) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      text.textContent = text.textContent!.replace(/[ ]/g, String.fromCharCode(160));
    }
  }

  public static toFragment(html: string): DocumentFragment {
    const element = document.createElement('div');
    element.innerHTML = html;
    return this.extractContents(element);
  }

  public static toHtml(fragment: DocumentFragment): string {
    const element = document.createElement('div');
    element.appendChild(fragment.cloneNode(true));
    return element.innerHTML;
  }

  public static toText(fragment: DocumentFragment): string {
    const div = document.createElement('div');
    div.appendChild(fragment.cloneNode(true));
    return div.innerText;
  }

  // public static getLength(fragment: DocumentFragment): number {
  //   const walker = new NodeWalker(fragment);
  //   return itiriri(walker.textNodes).reduce((acc, curr) => acc + curr.length, 0);
  // }

  public static getLength(fragment: DocumentFragment): number {
    const walker = new NodeWalker(fragment);
    return itiriri(walker.textPositions).last()?.index ?? 0;
  }

  public static splitByChar(fragment: DocumentFragment): DocumentFragment[] {
    const parts: DocumentFragment[] = [];
    const walker = new NodeWalker(fragment);
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const sepPos = itiriri(walker.beforeCharacterPositions).find((p) => p.char === '\n');
      const part =
        sepPos != null
          ? NodeHelper.extractContents({
              startNode: fragment,
              startOffset: 0,
              endNode: sepPos.node,
              endOffset: sepPos.nodeOffset,
            })
          : NodeHelper.extractContents(fragment);
      const firstPos = itiriri(walker.beforeCharacterPositions).first();
      if (firstPos) {
        NodeHelper.extractContents({
          node: firstPos.node,
          startOffset: firstPos.nodeOffset,
          endOffset: firstPos.nodeOffset + 1,
        });
      }
      part.normalize();
      parts.push(part);
      if (sepPos == null) break;
    }
    return parts;
  }

  public static splitByTag(fragment: DocumentFragment, tagName: string): DocumentFragment[] {
    const parts: DocumentFragment[] = [];
    const walker = new NodeWalker(fragment);
    parts.push(fragment);
    const separatorNodes = Array.from(
      itiriri(walker.nodes).filter((n) => n instanceof Element && n.tagName.toLowerCase() === tagName.toLowerCase())
    );
    for (const separatorNode of separatorNodes) {
      const [previousFragment, separatorFragment, nextFragment] = this.splitParent(separatorNode);
      parts.splice(
        parts.indexOf(separatorFragment),
        1,
        ...([previousFragment, separatorFragment, nextFragment].filter((f) => f != null) as DocumentFragment[])
      );
    }
    return parts;
  }

  public static splitAtPosition(node: Text, offset: number): [Text, Text] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const leftTextContent = node.textContent!.slice(0, offset);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const rightTextContent = node.textContent!.slice(
      offset,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      node.textContent!.length
    );
    const leftText = document.createTextNode(leftTextContent);
    node.parentNode?.insertBefore(leftText, node);
    node.textContent = rightTextContent;
    return [leftText, node];
  }

  public static unwrap(node: Node): void {
    while (node.firstChild != null) {
      node.parentNode?.appendChild(node.firstChild);
    }
    node.parentNode?.removeChild(node);
  }

  public static splitByTextNodeAndWhitespace(fragment: DocumentFragment): TextAtom[] {
    const atoms: TextAtom[] = [];
    let textNodeStart = 0;
    const walker = new NodeWalker(fragment);
    for (const textNode of itiriri(walker.textNodes)) {
      const text = textNode.textContent!;
      for (const range of StringHelper.getWordWithSpaceRanges(text)) {
        const rangeTextNode = document.createTextNode(
          text.slice(range.start, range.start + range.length + range.spaceLength)
        );
        let node: Node = textNode;
        let rangeNode: Node = rangeTextNode;
        while (node.parentNode !== fragment) {
          node = node.parentNode!;
          const cloneNode = node.cloneNode(false);
          cloneNode.appendChild(rangeNode);
          rangeNode = cloneNode;
        }
        atoms.push({
          rootNode: rangeNode,
          textNode: rangeTextNode,
          range: {
            start: textNodeStart + range.start,
            length: range.length,
            spaceLength: range.spaceLength,
          },
        });
      }
      textNodeStart += text.length;
    }
    return atoms;
  }

  public static splitByWhitespace(fragment: DocumentFragment): {
    measureFragment: DocumentFragment;
    templateFragment: DocumentFragment;
    textRange: TextRange;
  }[] {
    const words: {
      measureFragment: DocumentFragment;
      templateFragment: DocumentFragment;
      textRange: TextRange;
    }[] = [];
    const walker = new NodeWalker(fragment);
    let start = 0;
    while (itiriri(walker.beforeCharacterPositions).first() != null) {
      const it = walker.beforeCharacterPositions[Symbol.iterator]();
      let result = it.next();
      while (!result.done && result.value.char !== ' ') result = it.next();
      while (!result.done && result.value.char === ' ') result = it.next();
      const length = result.done ? this.getLength(fragment) : result.value.index;
      const word = result.done
        ? NodeHelper.extractContents(fragment)
        : NodeHelper.extractContents({
            startNode: fragment,
            startOffset: 0,
            endNode: result.value.node,
            endOffset: result.value.nodeOffset,
          });
      const wordWithNbsp = word.cloneNode(true) as DocumentFragment;
      NodeHelper.replaceSpaceWithNbsp(wordWithNbsp);
      for (const spacePosition of itiriri(new NodeWalker(word).beforeCharacterPositionsReverse)
        .filter((pos) => pos.char === ' ')
        .skip(1)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let textContent = spacePosition.node.textContent!;
        textContent =
          textContent.slice(0, spacePosition.nodeOffset) +
          String.fromCharCode(160) +
          textContent.slice(spacePosition.nodeOffset + 1);
        spacePosition.node.textContent = textContent;
      }
      words.push({
        measureFragment: word,
        templateFragment: wordWithNbsp,
        textRange: { start, length },
      });
      start += length;
    }
    for (let i = 0; i < words.length - 1; i++) {
      words[i].textRange.length--;
    }
    return words;
  }

  static getFontTopDiff(fontFamily: string, fontSize: string, fontWeight: string): { topDiff: number; height: number } {
    const key = `${fontFamily};${fontSize};${fontWeight}`;
    if (this.topDiffs.has(key)) return this.topDiffs.get(key)!;
    else {
      const div = document.createElement('div');
      div.style.fontFamily = fontFamily;
      div.style.fontSize = fontSize;
      div.style.fontWeight = fontWeight;
      div.style.lineHeight = '1';
      const span = document.createElement('span');
      span.style.fontFamily = fontFamily;
      span.style.fontSize = fontSize;
      span.style.fontWeight = fontWeight;
      span.style.lineHeight = '1';
      const text = document.createTextNode('x');
      span.appendChild(text);
      div.appendChild(span);
      document.body.appendChild(div);
      const divRect = div.getBoundingClientRect();
      const topDiff = divRect.top - span.getBoundingClientRect().top;
      const height = divRect.height;
      document.body.removeChild(div);
      this.topDiffs.set(key, { topDiff, height });
      return { topDiff, height };
    }
  }

  static getInlineTopDiff(element: HTMLElement): number {
    const div = document.createElement('div');
    div.style.fontSize = '0';
    div.style.lineHeight = '1';
    const clonedSliceElement = element.cloneNode(true) as HTMLElement;
    const style = window.getComputedStyle(element);
    clonedSliceElement.style.fontSize = style.fontSize;
    // clonedSliceElement.style.lineHeight = style.lineHeight; // spielt für inline keine Rolle
    div.appendChild(clonedSliceElement);
    element.parentNode?.appendChild(div);
    const divRect = div.getBoundingClientRect();
    const inlineRect = clonedSliceElement.getBoundingClientRect();
    element.parentNode?.removeChild(div);
    return divRect.top - inlineRect.top;
  }

  static getTopDiff(element: HTMLElement): number {
    if (window.getComputedStyle(element).display === 'inline') {
      return this.getInlineTopDiff(element);
    } else return 0;
  }

  private static updateRange(range: Node | CollapsedRange | NodeRange | FullRange) {
    if (range instanceof Node) {
      this.range.selectNodeContents(range);
    } else if (isCollapsedRange(range)) {
      this.range.setStart(range.node, range.offset);
    } else if (isNodeRange(range)) {
      this.range.setStart(range.node, range.startOffset);
      this.range.setEnd(range.node, range.endOffset);
    } else if (isFullRange(range)) {
      this.range.setStart(range.startNode, range.startOffset);
      this.range.setEnd(range.endNode, range.endOffset);
    }
  }

  private static splitParent(
    node: Node,
    previousNode?: Node | null,
    nextNode?: Node | null
  ): [DocumentFragment | null, DocumentFragment, DocumentFragment | null] {
    if (node.parentNode == null) {
      if (!(node instanceof DocumentFragment)) throw new Error('root node muss DocumentFragment sein');
      return [(previousNode as DocumentFragment) ?? null, node, (nextNode as DocumentFragment) ?? null];
    }

    const previousParentNode =
      node.previousSibling != null || previousNode != null ? node.parentNode.cloneNode(false) : null;
    if (previousParentNode != null) {
      if (previousNode != null) previousParentNode.appendChild(previousNode);
      while (node.previousSibling != null) {
        previousParentNode.insertBefore(node.previousSibling, previousParentNode.firstChild);
      }
    }

    const nextParentNode = node.nextSibling != null || nextNode != null ? node.parentNode.cloneNode(false) : null;
    if (nextParentNode != null) {
      if (nextNode != null) nextParentNode.appendChild(nextNode);
      while (node.nextSibling != null) {
        nextParentNode.appendChild(node.nextSibling);
      }
    }

    return this.splitParent(node.parentNode, previousParentNode, nextParentNode);
  }

  // private static getPreviousNode(node: Node): Node | null {
  //   if (node.previousSibling != null) return node.previousSibling;
  //   if (node.parentNode == null) return null;
  //   return this.getPreviousNode(node.parentNode);
  // }

  // private static getNextNode(node: Node): Node | null {
  //   if (node.nextSibling != null) return node.nextSibling;
  //   if (node.parentNode == null) return null;
  //   return this.getNextNode(node.parentNode);
  // }

  // private static getFragment(node: Node): DocumentFragment {
  //   if (node.parentNode == null) {
  //     if (node instanceof DocumentFragment) return node;
  //     else throw 'node has no fragment';
  //   } else return this.getFragment(node.parentNode);
  // }
}
