<ng-template fzTemplate="sidebar">
  <fz-sl-schueler-typ></fz-sl-schueler-typ>
  <fz-sl-formular-typ></fz-sl-formular-typ>
  <fz-sl-zeugnislevel></fz-sl-zeugnislevel>
</ng-template>
<fz-formular
  name="SL12jVerbal"
  [pageMarginTop]="151"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="113"
  [pageHeader]="slFolgeseiteComponent"
  [pageFooter]="slSeitenfussComponent"
  [lastPageFooter]="slLegendeComponent"
>
  <fz-sl-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-sl-zeugniskopf>
  <fz-panel
    *ngIf="
      formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule &&
      formular.zeugnis.schuelerTyp === 'Lernbehindert' &&
      !(
        formular.zeugnis.zeugnissatz.halbjahr === 1 &&
        formular.zeugnis.zeugnissatz.klassenstufe <= 2 &&
        formular.zeugnis.zeugnislevel === 1
      )
    "
    fzFormularItem="bildungsgangLernen"
    fzContainer
    paddingLeft="normal"
  >
    <fz-label class="Zeugnistitel">Bildungsgang Lernen</fz-label>
  </fz-panel>
  <fz-sl-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-sl-zeugnissatz>
  <fz-sl-schulbesuchsjahr
    fzFormularItem="schulbesuchsjahr"
    fzContainer
    [model]="model.schulbesuchsjahr"
  ></fz-sl-schulbesuchsjahr>
  <fz-sl-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-sl-schueler>
  <fz-panel
    *ngIf="
      formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule &&
      formular.zeugnis.zeugnissatz.halbjahr === 1 &&
      !(
        formular.zeugnis.zeugnissatz.halbjahr === 1 &&
        formular.zeugnis.zeugnissatz.klassenstufe <= 2 &&
        formular.zeugnis.zeugnislevel === 1
      )
    "
    fzFormularItem="unterricht"
    fzContainer
    paddingLeft="normal"
  >
    <fz-label *ngIf="formular.zeugnis.schuelerTyp !== 'Lernbehindert'"
      >Der Unterricht wurde nach dem Lehrplan für die Grundschule erteilt.</fz-label
    >
    <fz-label *ngIf="formular.zeugnis.schuelerTyp == 'Lernbehindert'"
      >Der Unterricht wurde nach den Lehrplänen für den Unterricht in der Förderschule Lernen erteilt.</fz-label
    >
  </fz-panel>
  <fz-section
    fzFormularItem="sozialverhalten"
    [label]="model.sozialverhalten.label"
    [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-panel
      fzSectionHeader
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <div class="Ueberschriften2">
        <fz-label>{{ model.sozialverhalten.label }}</fz-label>
      </div>
      <div class="Ueberschriften3">
        <fz-label>
          {{ model.sozialverhalten.subLabel }}
        </fz-label>
      </div>
    </fz-panel>
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
    >
      <fz-verbal
        [field]="model.sozialverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="lernArbeitsverhalten"
    [label]="model.lernArbeitsverhalten.label"
    [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-panel
      fzSectionHeader
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <div class="Ueberschriften2">
        <fz-label>{{ model.lernArbeitsverhalten.label }}</fz-label>
      </div>
      <div class="Ueberschriften3">
        <fz-label>
          {{ model.lernArbeitsverhalten.subLabel }}
        </fz-label>
      </div>
    </fz-panel>
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
    >
      <fz-verbal
        [field]="model.lernArbeitsverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="besondereFaehigkeiten"
    [label]="model.besondereFaehigkeiten.label"
    [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
    >
      <fz-intelli
        [field]="model.besondereFaehigkeiten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="fachbezogeneLernUndLeistungsentwicklung"
    [label]="model.fachbezogeneLernLeistungsentwicklung.label"
    [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
    >
      <fz-verbal
        [field]="model.fachbezogeneLernLeistungsentwicklung.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-deco>
  </fz-section>
  <fz-deco
    *ngIf="model.sprachenportfolio != null"
    fzFormularItem="sprachenportfolio"
    paddingLeft="normal"
    paddingRight="normal"
  >
    <fz-sl-sprachenportfolio fzContainer [model]="model.sprachenportfolio"></fz-sl-sprachenportfolio>
  </fz-deco>
  <fz-section
    fzFormularItem="bemerkungen"
    [label]="model.bemerkungen.label"
    [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
    >
      <fz-intelli
        [field]="model.bemerkungen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-sl-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-sl-versaeumnis>
  <fz-sl-schulort fzFormularItem="schulort" [marginBottom]="0" fzContainer [model]="model.schulort"></fz-sl-schulort>
  <fz-sl-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-sl-unterschrift>
  <fz-sl-kenntnis fzFormularItem="kenntnis" fzContainer></fz-sl-kenntnis>
</fz-formular>
