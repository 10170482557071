<fz-input
  [field]="field"
  [(text)]="text"
  [placeholder]="placeholder"
  [keyFilter]="keyFilter"
  (hasFocusChange)="onHasFocusChange($event)"
></fz-input>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div
    *ngIf="hasFocus"
    fzFocusable
    tabindex="0"
    style="position: absolute; border-style: solid; border-width: 1px"
    [style.left.px]="input?.rect?.left"
    [style.top.px]="input?.rect?.bottom"
    class="floating fz-date"
  >
    <p-calendar [inline]="true" [ngModel]="today" (onSelect)="setCalendarDate($event)"></p-calendar>
  </div>
</ng-template>
