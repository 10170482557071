import { ChangeDetectorRef, Component, ElementRef, Input, Optional, SkipSelf, ViewChild } from '@angular/core';
import { Projectable, ProjectableBase, ProjectableProvider } from '@modules/projectables/projectable';

@Component({
  selector: 'fz-he-siegel',
  styles: [':host { height: 100%; display: grid; justify-items: center; align-items: center }'],
  templateUrl: 'he-siegel.component.html',
})
export class HESiegelComponent extends ProjectableBase implements Projectable {
  @Input() color = 'grey';
  @ViewChild('circle') circle?: ElementRef<HTMLElement>;
  rect = new DOMRect();
  circleRect = new DOMRect();
  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef
  ) {
    super(elementRef, parentProvider);
  }

  projectPosition(parentRect: DOMRect): void {
    const childRect = this.sourceElement.getBoundingClientRect();
    this.rect = new DOMRect(
      childRect.left - parentRect.left,
      childRect.top - parentRect.top,
      childRect.width,
      childRect.height
    );
    if (this.circle != null) {
      const circleRect = this.circle.nativeElement.getBoundingClientRect();
      this.circleRect = new DOMRect(
        circleRect.left - childRect.left,
        circleRect.top - childRect.top,
        circleRect.width,
        circleRect.height
      );
      this.changeDetector.detectChanges();
    }
  }
}
