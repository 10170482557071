import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { FlinkyAuthService } from './auth/flinky-auth.service';
import { Jahrgang } from '../../models/jahrgang';
import { Zeugnis } from '../../models/zeugnis';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { JahrgangService } from './jahrgang.service';
import { Schueler } from '../../models/schueler';
import { NotificationService } from './notification/notification.service';
import { JahrgangScopeDto } from '../../models/generated/jahrgang-scope-dto';
import { StopWatch } from '@modules/shared/stop-watch';
import _ from 'lodash-es';
import { FZConfirmationService } from './fz-confirmation.service';
import { FormularFactory } from '../../modules/formular/formular-factory';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  // schuelerAddedBehaviour darf kein BehaviorSubject sein, sonst wird es jedesmal gefeuert, wenn sich eine Ansicht erzeugt wrid, die diesen Service verwendet
  schuelerAddedBehaviour = new Subject<Zeugnis>();
  dataLoadedBehaviour: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  selectedZeugnissatzChange = new Subject<void>();
  zoomed = new Subject<number>();
  saveCompleted = new Subject<void>();
  kpChange = new Subject<void>();

  jahrgang: Jahrgang | undefined;
  waitDialogVisible: boolean = false;
  isSaving = false;
  persistedScope: JahrgangScopeDto | undefined;
  canEditZeugnisOverride = false;
  zeugnissatzUnlocked = false;
  private _selectedZeugnissatz: Zeugnissatz | undefined;
  private _selectedZeugnis: Zeugnis | undefined;

  get isZeugnissatzInZukunft() {
    if (this.selectedZeugnissatz != null) {
      return new Date() < this.selectedZeugnissatz.dateStart;
    } else return false;
  }
  get isReadonly(): boolean {
    return !this.canEditZeugnis || this.isZeugnissatzLocked;
  }
  get canEditZeugnis(): boolean {
    return this.isCurrentZeugnissatzSelected || this.canEditZeugnisOverride;
  }
  get isZeugnissatzLocked(): boolean {
    if (!this.isCurrentZeugnissatzSelected) return false;
    const zeugnissatz = this.currentZeugnissatz;
    if (zeugnissatz != null) {
      //if (zeugnissatz.License.Type == ZeugniskopfLicenseType.Demo || zeugnissatz.Jahrgang.License.Kundennummer.ToLower().Contains("sentation")) return false;
      if (zeugnissatz.zeugniskopflizenz.type === 'Demo') return false;
      if (this.zeugnissatzUnlocked) return false;
      if (
        this.selectedZeugnis != null &&
        FormularFactory.getFormularsatz(zeugnissatz).overrideLock(this.selectedZeugnis)
      ) {
        return false;
      }
      return !(zeugnissatz.dateStart <= new Date() && new Date() <= zeugnissatz.dateEnd);
    } else return false;
  }
  get selectedZeugnissatz(): Zeugnissatz | undefined {
    return this._selectedZeugnissatz;
  }
  get selectedZeugnis(): Zeugnis | undefined {
    return this._selectedZeugnis;
  }
  set selectedZeugnis(value: Zeugnis | undefined) {
    if (this._selectedZeugnis !== value) {
      this.saveSelectedZeugnissatz();
      this._selectedZeugnis = value;
      this.canEditZeugnisOverride = false;
    }
  }

  get currentJahrgangId(): string | undefined {
    return this.jahrgang?.id;
  }

  get currentZeugnissatz(): Zeugnissatz | undefined {
    return this.jahrgang?.currentZeugnissatz;
  }

  get isCurrentZeugnissatzSelected(): boolean {
    return this.currentZeugnissatz != null && this._selectedZeugnissatz === this.currentZeugnissatz;
  }

  get currentZeugnisse(): Zeugnis[] {
    return this.currentZeugnissatz?.zeugnisse ?? [];
  }

  get selectedZeugnisse(): Zeugnis[] {
    return this.selectedZeugnissatz?.zeugnisse ?? [];
  }

  get isDirty(): boolean {
    try {
      StopWatch.resetAll();
      StopWatch.start('isDirty');
      const scope = this.selectedZeugnissatz?.toScopeIncludeZeugnisse();
      return !_.isEqual(scope, this.persistedScope);
      // return JSON.stringify(scope) !== JSON.stringify(this.persistedScope);
    } finally {
      StopWatch.stop('isDirty');
      // console.log(StopWatch.toString());
    }
  }

  constructor(
    private router: Router,
    private authService: FlinkyAuthService,
    private confirmationService: FZConfirmationService,
    private notificationService: NotificationService,
    private jahrgangService: JahrgangService
  ) {
    this.authService.loginStateChanged.subscribe(async (_result) => {
      if (this.authService.getUser() != null) await this.openLatest();
    });
    this.openLastest();
  }

  async openLastest() {
    if (this.authService.getUser() != null) await this.openLatest();
  }

  async switchSelectedZeugnissatz(value: Zeugnissatz | undefined) {
    if (this._selectedZeugnissatz !== value) {
      await this.saveSelectedZeugnissatz();
      this._selectedZeugnissatz = value;
      this.persistedScope = this.selectedZeugnissatz?.toScopeIncludeZeugnisse();
      if (this._selectedZeugnis != null) {
        this._selectedZeugnis = this._selectedZeugnissatz?.zeugnisse.find(
          (z: any) => z.schueler === this._selectedZeugnis?.schueler
        );
      }
      this.selectedZeugnissatzChange.next();
      this.canEditZeugnisOverride = false;
      this.zeugnissatzUnlocked = false;
    }
  }

  async saveSelectedZeugnissatz() {
    if (this.selectedZeugnissatz != null && this.isDirty && !this.isSaving) {
      try {
        this.isSaving = true;
        await this.jahrgangService.updateZeugnissatzMitZeugnissen(this.selectedZeugnissatz);
        this.persistedScope = this.selectedZeugnissatz?.toScopeIncludeZeugnisse();
        this.saveCompleted.next();
      } finally {
        this.isSaving = false;
      }
    }
  }

  async reactivateSchueler(zeugnis: Zeugnis) {
    zeugnis.schueler.isAktiv = true;
    await this.saveSelectedZeugnissatz();
  }

  async deactivateSchueler(zeugnis: Zeugnis) {
    zeugnis.schueler.isAktiv = false;
    await this.saveSelectedZeugnissatz();
  }

  async deleteSchueler(zeugnis: Zeugnis) {
    if (this.isCurrentZeugnissatzSelected) {
      await this.jahrgangService.deleteSchueler(zeugnis.schueler);
      if (this.selectedZeugnis === zeugnis) {
        // selectedZeugnis darf nur geändert werden, wenn selectedZeugnis gelöscht wird
        this._selectedZeugnis = undefined;
        this.dataLoadedBehaviour.next(true);
      }
      if (this.currentZeugnisse.length === 0) {
        this.createSchueler();
      }
    }
  }

  async weiterfuehren(zeugnissatz: Zeugnissatz) {
    await this.jahrgangService.weiterfuehren(zeugnissatz);
    this.switchSelectedZeugnissatz(this.jahrgang?.currentZeugnissatz);
    // nur temporär um alle Daten neu zu laden
    await this.open(zeugnissatz.jahrgang.id);
  }

  async zeugnisseLoeschen() {
    if (this.currentZeugnissatz != null) {
      if (
        await this.confirmationService.confirm({
          header: 'Zeugnisse löschen?',
          message: 'Wollen Sie die aktuellen Zeugnisse wirklich löschen?',
        })
      ) {
        if (this.jahrgang != null) {
          await this.saveSelectedZeugnissatz();
          if (this._selectedZeugnis != null) {
            this._selectedZeugnis = undefined;
          }
          await this.jahrgangService.deleteCurrentZeugnissatz(this.jahrgang);
          this._selectedZeugnissatz = undefined;
          this.switchSelectedZeugnissatz(this.jahrgang.currentZeugnissatz);
          if (this.jahrgang.currentZeugnissatz != null) {
            this.dataLoadedBehaviour.next(true);
            this.router.navigate(['/main']);
          } else {
            this.router.navigate(['/jahrgang-wiederfuellen']);
          }
        }
      }
    }
  }

  async createSchueler(): Promise<void> {
    if (this.jahrgang != null) {
      this.selectedZeugnis = await this.jahrgangService.createSchueler(this.jahrgang);
      this.schuelerAddedBehaviour.next(this.selectedZeugnis);
    }
  }

  async createMultipleSchueler(schuelers: Schueler[]) {
    if (this.jahrgang != null) {
      const zeugnisse = await this.jahrgangService.createMultipleSchueler(this.jahrgang, schuelers);
      for (const zeugnis of zeugnisse) this.schuelerAddedBehaviour.next(zeugnis);
    }
  }

  async openJahrgang(idJahrgang: string) {
    await this.open(idJahrgang);
    this.router.navigate(['/main']);
  }

  async openLatest() {
    await this.open(undefined);
  }

  async reopen() {
    if (this.jahrgang != null) {
      await this.saveSelectedZeugnissatz();
      await this.open(this.jahrgang.id);
    }
  }

  async canApplyKP(): Promise<string | undefined> {
    if (this.jahrgang != null && this.currentZeugnissatz != null && this.currentZeugnissatz.canHaveKP) {
      if (
        this.jahrgang.bundesland === 'RP' &&
        (await this.jahrgangService.checkForSchulconfigUpdate(this.currentZeugnissatz.id))
      ) {
        return 'Es gibt Aktualisierungen für die Schulkonfiguration. Wollen Sie diese jetzt anwenden?';
      }
      if (
        await this.jahrgangService.checkForRasterUpdate(
          this.currentZeugnissatz.id,
          this.jahrgang.bundesland,
          this.jahrgang.kundennummer
        )
      ) {
        return 'Die Könnensprofile wurden aktualisiert. Sollen die Änderungen auf die aktuellen Zeugnisse übernommen werden?';
      }
    }
    return undefined;
  }

  async applyKP() {
    try {
      this.waitDialogVisible = true;
      if (this.jahrgang != null && this.currentZeugnissatz != null && this.currentZeugnissatz.canHaveKP) {
        await this.saveSelectedZeugnissatz();
        await this.jahrgangService.updateZeugnissatzRaster(
          this.currentZeugnissatz.id,
          this.jahrgang.bundesland,
          this.jahrgang.kundennummer
        );
        this.jahrgang = await this.jahrgangService.getJahrgang(this.jahrgang.id);
        this._selectedZeugnissatz = this.jahrgang?.currentZeugnissatz;
        this.persistedScope = this.selectedZeugnissatz?.toScopeIncludeZeugnisse();
        this.authService.updateCurrentLizenz(this.jahrgang?.kundennummer);
        this.dataLoadedBehaviour.next(true);
        this.selectedZeugnissatzChange.next();
      }
    } finally {
      this.waitDialogVisible = false;
    }
  }

  async open(jahrgangId: string | undefined) {
    try {
      this.waitDialogVisible = true;
      this.jahrgang = await this.jahrgangService.getJahrgang(jahrgangId);
      this._selectedZeugnissatz = this.jahrgang?.currentZeugnissatz;
      this.persistedScope = this.selectedZeugnissatz?.toScopeIncludeZeugnisse();
      this.authService.updateCurrentLizenz(this.jahrgang?.kundennummer);
      if (this.jahrgang != null) {
        if (this.jahrgang.currentZeugnissatz == null) this.router.navigate(['/jahrgang-wiederfuellen']);
        else {
          this.dataLoadedBehaviour.next(true);
          this.selectedZeugnissatzChange.next();
        }
      }
      this.canEditZeugnisOverride = false;
      this.zeugnissatzUnlocked = false;
    } finally {
      this.waitDialogVisible = false;
    }
  }

  async close() {
    await this.saveSelectedZeugnissatz();
    this.jahrgang = undefined;
    this._selectedZeugnissatz = undefined;
    this.persistedScope = undefined;
    this.dataLoadedBehaviour.next(true);
    this.selectedZeugnissatzChange.next();
  }
}
