import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-zeugnissatz',
  templateUrl: 'he-zeugnissatz.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, HEZeugnissatzComponent)],
})
export class HEZeugnissatzComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      klassenstufeFlexField:
        formular.zeugnis.schuelerTyp === 'Schuleingangsphase' ? formular.klassenstufeFlex : undefined,
      klassenbezeichnungField: formular.zeugnissatz.klassenbezeichnung,
    };
  }

  @Input() model: ReturnType<typeof HEZeugnissatzComponent.createModel> | undefined;
}
