<fz-panel>
  <div class="Allgemeiner_Zeugnis_Text" style="margin-right: 6px; text-align: right">
    <fz-label>Seite {{ pageIndex + 1 }} für </fz-label>
    <fz-label class="Schuelername">{{ formular.zeugnis.schuelerVorname }} {{ formular.zeugnis.schuelerName }}</fz-label>
    <fz-label
      >, Klasse {{ formular.zeugnis.zeugnissatz.klassenbezeichnung }},
      {{ formular.zeugnisTypLabel }}
      {{ formular.zeugnissatz.schuljahrLabel }}
    </fz-label>
  </div>
</fz-panel>
