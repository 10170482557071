import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { PdlModule } from '@modules/pdl/pdl.module';
import { SharedModule } from '@modules/shared/shared.module';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ListboxModule } from 'primeng/listbox';
import { CheckboxComponent } from './checkbox.component';
import { DateComponent } from './date.component';
import { DropdownComponent } from './dropdown.component';
import { ImageComponent } from './image.component';
import { InputComponent } from './input.component';
import { LabelComponent } from './label.component';
import { LinkedDateComponent } from './linked-date.component';
import { LinkedDropdownComponent } from './linked-dropdown.component';
import { LinkedTextComponent } from './linked-text.component';
import { NoteComponent } from './note.component';
import { NumberComponent } from './number.component';
import { PanelComponent } from './panel.component';
import { ProjectableOutletComponent } from './projectable-outlet.component';
import { SchuljahrLabelComponent } from './schuljahr-label.component';
import { SelectCandidateDirective } from './select-candidate.directive';
import { SelectComponent } from './select.component';
import { TestContainerComponent } from './test-container.component';
import { TextEditorDirective } from './text-editor.directive';
import { TextInputDirective } from './text-input.directive';
import { TextComponent } from './text.component';
import { ZeugniskopfImageComponent } from './zeugniskopf-image.component';

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    PdlModule,
    BrowserModule,
    FormsModule,
    ListboxModule,
    CalendarModule,
    ContextMenuModule,
  ],
  exports: [
    TextComponent,
    LabelComponent,
    TextInputDirective,
    PanelComponent,
    TestContainerComponent,
    ImageComponent,
    DateComponent,
    TextEditorDirective,
    InputComponent,
    NumberComponent,
    NoteComponent,
    DropdownComponent,
    SelectComponent,
    SelectCandidateDirective,
    ProjectableOutletComponent,
    LinkedDateComponent,
    LinkedDropdownComponent,
    LinkedTextComponent,
    CheckboxComponent,
    SchuljahrLabelComponent,
    ZeugniskopfImageComponent,
  ],
  declarations: [
    TextComponent,
    LabelComponent,
    TextInputDirective,
    PanelComponent,
    TestContainerComponent,
    ImageComponent,
    DateComponent,
    TextEditorDirective,
    InputComponent,
    NumberComponent,
    NoteComponent,
    DropdownComponent,
    SelectComponent,
    SelectCandidateDirective,
    ProjectableOutletComponent,
    LinkedDateComponent,
    LinkedDropdownComponent,
    LinkedTextComponent,
    CheckboxComponent,
    SchuljahrLabelComponent,
    ZeugniskopfImageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class ProjectableModule {}
