import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { Subscription } from 'rxjs';
import { WorkspaceService } from '../../../shared/services';
import { environment } from 'src/environments/environment';
import { DiffViewerModule } from '../diff-viewer/diff-viewer.module';
import { Option } from '../../../modules/shared/option';

@Component({
  standalone: true,
  selector: 'fz-workspace-title',
  templateUrl: 'workspace-title.component.html',
  imports: [CommonModule, FormsModule, ButtonModule, DropdownModule, DialogModule, DiffViewerModule],
})
export class WorkspaceTitleComponent implements OnInit, OnDestroy {
  @Input() title = '';
  isDirty = false;
  diffVisible = false;
  objectPersisted: any;
  object: any;
  environment = environment;

  smallScreen = false;

  private timers: NodeJS.Timeout[] = [];
  private subscriptions: Subscription[] = [];
  private switching = false;
  private lastCheckTime = 0;

  get selectedZeugnissatz(): string | undefined {
    if (this.switching) return undefined;
    else return this.workspaceService.selectedZeugnissatz?.id;
  }

  constructor(
    public workspaceService: WorkspaceService,
    public changeDetector: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private readonly zone: NgZone
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.breakpointObserver
        .observe(['(min-width: 1024px)'])
        .subscribe((state: BreakpointState) => (this.smallScreen = !state.matches))
    );
    this.zone.runOutsideAngular(() => {
      this.timers.push(
        setInterval(() => {
          if (this.lastCheckTime < Date.now() - 1900) {
            this.isDirty = this.workspaceService.isDirty;
            this.changeDetector.detectChanges();
            this.lastCheckTime = Date.now();
          }
        }, 2000),
        setInterval(async () => {
          try {
            if (environment.production && this.isDirty) {
              await this.workspaceService.saveSelectedZeugnissatz();
              this.changeDetector.detectChanges();
            }
          } catch (_ex) {
            // bei Interval-Speichern keine Fehlermeldung zeigen
          }
        }, 60000)
      );
    });
    this.subscriptions.push(
      this.workspaceService.saveCompleted.subscribe(() => (this.isDirty = this.workspaceService.isDirty))
    );
  }

  ngOnDestroy() {
    for (const timer of this.timers) clearInterval(timer);
    for (const subscription of this.subscriptions) subscription.unsubscribe();
  }

  get zeugnissaetze(): Option<string>[] {
    return (this.workspaceService.jahrgang?.zeugnissaetzeOrdered ?? []).map((zs) => ({
      label: zs.klasseSchuljahrHalbjahr,
      value: zs.id,
    }));
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent): void | string {
    if (this.isDirty) {
      const message = 'Ihre Änderungen sind noch nicht gespeichert.';
      if (event != null) {
        // event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = message;
      }
      return message;
    }
  }

  async switchSelectedZeugnissatz(zeugnissatzId: string | undefined) {
    const zeugnissatz = this.workspaceService.jahrgang?.zeugnissaetze?.find((zs) => zs.id === zeugnissatzId);
    if (zeugnissatz != null && zeugnissatz !== this.workspaceService.selectedZeugnissatz) {
      try {
        this.switching = true;
        this.changeDetector.detectChanges();
        await this.workspaceService.switchSelectedZeugnissatz(zeugnissatz);
      } finally {
        this.switching = false;
        this.changeDetector.detectChanges();
      }
    }
  }

  diff() {
    this.diffVisible = true;
    this.objectPersisted = this.workspaceService.persistedScope;
    this.object = this.workspaceService.selectedZeugnissatz?.toScopeIncludeZeugnisse();
    // this.objectPersisted = JSON.parse(
    //   this.workspaceService.persistedScope?.zeugnisse.find((z) => z.id === this.workspaceService.selectedZeugnis?.id)
    //     ?.contentString ?? ''
    // );
    // this.object = JSON.parse(
    //   this.workspaceService.jahrgang
    //     ?.toScopeIncludeZeugnisse()
    //     .zeugnisse.find((z) => z.id === this.workspaceService.selectedZeugnis?.id)?.contentString ?? ''
    // );
  }
}
