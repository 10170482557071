import { Component, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import ExportTbResult from '../../../../models/generated/export-tb-result';
import ExportTbResultCode from '../../../../models/generated/export-tb-result-code';
import { FlinkyAuthService } from '../../../services/auth/flinky-auth.service';
import { ExportTbService } from '../export-tb.service';

@Component({
  selector: 'app-export-tb-step1',
  templateUrl: './export-tb-step1.component.html',
  styleUrls: ['./export-tb-step1.component.scss'],
})
export class ExportTbStep1Component {
  @Input() header: TemplateRef<any> | undefined;
  @Output() exportManuellFinished = new EventEmitter<ExportTbResult>();
  @Output() exportInternetChecked = new EventEmitter<ExportTbResult>();

  constructor(
    private exportTbService: ExportTbService,
    private flinkyAuth: FlinkyAuthService
  ) {}

  async internet() {
    this.exportInternetChecked.next(
      await this.exportTbService.getExportInfo(this.flinkyAuth.lizenz?.kundennummer ?? '')
    );
  }

  async manuell() {
    const data = await this.exportTbService.exportFile();
    if (data && data.size > 0) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = 'textbausteine.fztb';
      link.click();

      const exportResult: ExportTbResult = { code: ExportTbResultCode.success };
      this.exportManuellFinished.next(exportResult);
    } else if (data && data.size === 0) {
      const exportResult: ExportTbResult = {
        code: ExportTbResultCode.noData,
        text: 'Es sind keine Textbausteine vorhanden.',
      };
      this.exportManuellFinished.next(exportResult);
    } else {
      const exportResult: ExportTbResult = { code: ExportTbResultCode.fail };
      this.exportManuellFinished.next(exportResult);
    }
  }
}
