import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import ExportTbResult from '../../../models/generated/export-tb-result';

@Injectable({
  providedIn: 'root',
})
export class ExportTbService {
  constructor(private http: HttpClient) {}

  getExportInfo(kundennummer: string): Promise<ExportTbResult> {
    const params = new HttpParams().append('kundennummer', kundennummer);

    return lastValueFrom(this.http.get<ExportTbResult>('/api/Textbaustein/getExportInfo', { params }));
  }

  exportToInternet(kundennummer: string): Promise<ExportTbResult> {
    const params = new HttpParams().append('kundennummer', kundennummer);

    return lastValueFrom(this.http.get<ExportTbResult>('/api/Textbaustein/exportToInternet', { params }));
  }

  public exportFile(): Promise<Blob> {
    const httpParams = new HttpParams();

    const options = {
      params: httpParams,
      responseType: 'blob' as const,
    };

    return lastValueFrom(this.http.get('/api/Textbaustein/exportToLocal', options));
  }
}
