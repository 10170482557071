import { PanelModule } from 'primeng/panel';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JahrgangWeiterfuehrenComponent } from './jahrgang-weiterfuehren.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PickListModule } from 'primeng/picklist';
import { HelpInfoModule } from '../help-info/help-info.module';
import { AutoFocusModule } from '../../auto-focus.module';

@NgModule({
  declarations: [JahrgangWeiterfuehrenComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    PanelModule,
    PickListModule,
    HelpInfoModule,
    AutoFocusModule,
  ],
  exports: [JahrgangWeiterfuehrenComponent],
})
export class JahrgangWeiterfuehrenModule {}
