<div>
  <p-radioButton [value]="null" [(ngModel)]="formular.zeugnis.lernTyp" label="Standardfächer"></p-radioButton>
</div>
<div>
  <p-radioButton
    [value]="'Fachtexte'"
    [(ngModel)]="formular.zeugnis.lernTyp"
    label="Frei definierbare Fächer"
  ></p-radioButton>
</div>
