<div>
  <p-radioButton [value]="null" [(ngModel)]="formular.zeugnis.beilageTyp" label="Keine Beilage"></p-radioButton>
</div>
<div>
  <p-radioButton
    [value]="'KP'"
    [(ngModel)]="formular.zeugnis.beilageTyp"
    label="Beilage Kompentenzraster"
  ></p-radioButton>
</div>
@if (formular.zeugnis.beilageTyp == 'KP') {
  <button
    class="mt-2"
    pButton
    type="button"
    [text]="true"
    label="Einrichtung des Kompetenzrasters"
    routerLink="/koennensprofil-editor"
  ></button>
}
