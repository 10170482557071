import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WorkspaceService } from '../../../../shared/services';
import { ReportingService } from '../reporting.service';

@Component({
  selector: 'fz-report-formulartypen',
  templateUrl: './report-formulartypen.component.html',
  styleUrls: ['./report-formulartypen.component.scss'],
})
export class ReportFormulartypenComponent implements OnInit, OnDestroy {
  formulartypenData: any;
  basicOptions: any;

  subscriptions: Subscription[] = [];

  constructor(
    private reportingService: ReportingService,
    private schuelerService: WorkspaceService
  ) {}

  ngOnInit() {
    this.basicOptions = {
      legend: { display: false },
      scales: {
        xAxes: [
          {
            ticks: {
              min: 0,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
            },
          },
        ],
      },
    };

    this.subscriptions.push(
      this.schuelerService.dataLoadedBehaviour.subscribe(async () => {
        if (this.schuelerService.currentJahrgangId) {
          const result = await this.reportingService.getFormulartypen(this.schuelerService.currentJahrgangId ?? '');
          this.formulartypenData = {
            labels: Object.keys(result),
            datasets: [
              {
                data: Object.values(result),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
              },
            ],
          };
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
