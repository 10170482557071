import { AbgangszeugnisTyp } from './enums/abgangszeugnis-typ';
import { AnlageTyp } from './enums/anlage-typ';
import { GespraechsatzTyp } from './enums/gespraechsatz-typ';
import { SprachenportfolioSprache } from './enums/sprachenportfolio-sprache';
import { SprachenportfolioTyp } from './enums/sprachenportfolio-typ';
import { SpsTyp } from './enums/sps-typ';
import { Zeugnis } from './zeugnis';

export class RPZeugnis extends Zeugnis {
  anlageTyp: AnlageTyp | null = null;
  beteiligtePersonen: string | null = null;
  protokolltext: string | null = null;
  ziel: string | null = null;
  empfehlungRealschule: boolean | null = null;
  empfehlungGymnasium: boolean | null = null;
  protokolldatum: string | null = null;
  gespraechsatzTyp: GespraechsatzTyp | null = null;
  spsTyp: SpsTyp | null = null;
  useKPProtokoll: boolean = false;
  abgangUebergangsfrist: boolean = false;
  klassenbezeichnungUebergangsfrist: string | null = null;
  abgangszeugnisTyp: AbgangszeugnisTyp | null = null;
  beteiligtePersonenVisibleOverride: boolean | null = null;
  unterschriftSchuelerVisibleOverride: boolean | null = null;
  spsText: string | null = null;
  fontSizeFactorProtokoll: number | null = null;
  ausnahmeVerbal: boolean = false;
  sprachenportfolio: SprachenportfolioTyp | null = null;
  sprachenportfolioSprache: SprachenportfolioSprache | null = null;
}
