import { Component, OnInit } from '@angular/core';
import { ReportingService } from '../reporting.service';
import { FormularFactory } from '@modules/formular/formular-factory';
import { Formular } from '@modules/formular/formular';
import ReportJahrgangSelectionOptionDto from '../../../../models/generated/report-jahrgang-selection-option-dto';
import { WorkspaceService } from '../../../../shared/services';
import { Zeugnis } from '../../../../models/zeugnis';
import { Zeugnissatz } from '../../../../models/zeugnissatz';

@Component({
  selector: 'fz-report-noten-faecher',
  templateUrl: './report-noten-faecher.component.html',
  styleUrls: ['./report-noten-faecher.component.scss'],
})
export class ReportNotenFaecherComponent implements OnInit {
  options: any;
  data: any;
  jahrgaenge: ReportJahrgangSelectionOptionDto[] = [];
  selectedJahrgang?: ReportJahrgangSelectionOptionDto;

  constructor(
    private reportingService: ReportingService,
    private schuelerService: WorkspaceService
  ) {}

  async ngOnInit() {
    this.options = {
      legend: {
        labels: {
          fontColor: '#495057',
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: '#495057',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: '#495057',
            },
          },
        ],
      },
    };
    this.jahrgaenge = await this.reportingService.getJahrgangSelectionOptions();
    this.selectedJahrgang = this.jahrgaenge[0];
    this.createChart();
  }

  async createChart(): Promise<void> {
    if (this.selectedJahrgang) {
      const data = await this.reportingService.getJahrgang(this.selectedJahrgang.id);
      const datasets: any[] = [];
      let labels: string[] = [];
      let keys: string[] = [];
      let index = 0;
      data.zeugnisse.forEach((zeugnis) => {
        const zsVm = FormularFactory.getFormularsatz(zeugnis).createFormular(zeugnis, 'zeugnis');
        zsVm.noteFields.forEach((nf) => {
          labels.push(this.getFachLabel(nf.description.fachKey, zsVm));
          keys.push(nf.description.fachKey);
        });
      });

      labels = [...new Set(labels)];
      keys = [...new Set(keys)];

      // jetzt für alle gefundenen Fächer die Werte finden
      data.zeugnissaetze.forEach((zs) => {
        datasets.push({
          type: 'bar',
          label: zs.schuljahr + ' ' + (zs.halbjahr + 1) + '. Hj',
          data: this.getNotenData(zs, data.zeugnisse, keys),
          fill: false,
          backgroundColor: this.reportingService.getColor(index),
        });
        index++;
      });

      this.data = {
        labels: labels,
        datasets: datasets,
      };
    }
  }
  private getColor(index: number): string {
    const backgroundColors = ['#FF6384', '#4BC0C0', '#FFCE56', '#E7E9ED', '#36A2EB'];
    return backgroundColors[index];
  }

  private getNotenData(zeugnissatz: Zeugnissatz, zeugnisse: Zeugnis[], keys: string[]): number[] {
    // alle fächer
    const notenschnitte: number[] = [];
    keys.forEach((key) => {
      let countNote = 0;
      let summeNoten = 0;
      let notenschnitt = 0;
      zeugnisse
        .filter((z) => z.zeugnissatz.id === zeugnissatz.id)
        .forEach((z) => {
          const zsVm = FormularFactory.getFormularsatz(z).createFormular(z, 'zeugnis');
          const noteNumber = zsVm.noteFields.find((nf) => nf.description.fachKey === key)?.value as number;
          if (noteNumber != null) {
            summeNoten += noteNumber;
            countNote++;
          }
        });
      if (countNote > 0) {
        notenschnitt = summeNoten / countNote;
      }
      notenschnitte.push(notenschnitt);
    });
    return notenschnitte;
  }

  getFachLabel(fachKey: string, formular: Formular): string {
    const fachViewModel = (formular as any)[fachKey ?? ''];
    if (fachViewModel != null) {
      return fachViewModel.description.label ?? fachKey;
    }
    return fachKey;
  }
}
