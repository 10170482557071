import { ZeugnisTyp } from '../../../models/enums/zeugnis-typ';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { Zeugnis } from '../../../models/zeugnis';

export class AbschlusszeugnisTypField extends DropdownField<Zeugnis, ZeugnisTyp | null> {
  constructor(private zeugnis: Zeugnis) {
    super(
      {
        label: 'Abschlusszeugnistyp',
        labelShort: 'AbschlZTyp',
        required: false,
        property: 'zeugnisTyp',
        candidates: [
          { displayStringShort: 'Abschlusszeugnis', value: null, content: 'Abschlusszeugnis der Grundschule' },
          { displayStringShort: 'Jahreszeugnis', value: 'Wiederholung', content: 'Jahreszeugnis der Grundschule' },
        ],
      },
      { object: zeugnis }
    );
  }
}
