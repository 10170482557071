import { Directive } from '@angular/core';
import { Formular } from '@modules/formular/formular';
import { FormularService } from '@modules/formular/formular.service';

@Directive()
export class OptionsBase<T extends Formular> {
  constructor(private formularService: FormularService<T>) {}

  get formular(): T {
    return this.formularService.formular;
  }
}
