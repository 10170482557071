import { AfterViewInit, Component } from '@angular/core';
import { AppInfoService } from '../../shared/services';

@Component({
  selector: 'app-schuelerliste-page',
  templateUrl: './schuelerliste-page.component.html',
  styleUrls: ['./schuelerliste-page.component.scss'],
})
export class SchuelerlistePageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Schülerliste'));
  }
}
