import { FormularFactory } from '@modules/formular/formular-factory';
import { BeschraenkungTyp } from './enums/beschraenkung-typ';
import { SchuelerTyp } from './enums/schueler-typ';
import { VersetzungsvermerkTyp } from './enums/versetzungsvermerk-typ';
import { ZeugnisTyp } from './enums/zeugnis-typ';
import { Fach } from './fach';
import { JahrgangScopeDto } from './generated/jahrgang-scope-dto';
import { ZeugnisContainer } from './generated/zeugnis-container';
import { Helper } from './helper';
import { ItemIndex } from './item-index';
import { ItemListIndex } from './item-list-index';
import { Jahrgang } from './jahrgang';
import { Metadata } from './metadata';
import { Schueler } from './schueler';
import { UserLayoutFormular } from './user-layout';
import { Zeugnissatz } from './zeugnissatz';

export class Zeugnis extends Metadata {
  readonly faecher: ItemListIndex<Fach, Zeugnis> = new ItemListIndex(Fach, this as Zeugnis);
  readonly layouts: ItemIndex<UserLayoutFormular, Zeugnis> = new ItemIndex(UserLayoutFormular, this as Zeugnis);
  schuelerName: string | null = null;
  schuelerVorname: string | null = null;
  schuelerTyp: SchuelerTyp | null = null;
  fehltageEntschuldigt: number | null = null;
  fehltageUnentschuldigt: number | null = null;
  fehlstundenEntschuldigt: number | null = null;
  fehlstundenUnentschuldigt: number | null = null;
  zeugnisausgabedatum: string | null = null;
  versetzungsvermerkTyp: VersetzungsvermerkTyp | null = null;
  versetzungsvermerkText: string | null = null;
  bemerkungen: string | null = null;
  schulbesuchsjahr: number | null = null;
  zeugnisTyp: ZeugnisTyp | null = null;
  beschraenkung: BeschraenkungTyp | null = null;
  fontSizeFactor: number | null = null;

  constructor(
    public readonly id: string,
    public readonly schueler: Schueler,
    public readonly zeugnissatz: Zeugnissatz
  ) {
    super();
    schueler.zeugnisse.push(this);
    zeugnissatz.zeugnisse.push(this);
  }

  remove() {
    this.schueler.zeugnisse.splice(this.schueler.zeugnisse.indexOf(this), 1);
    this.zeugnissatz.zeugnisse.splice(this.zeugnissatz.zeugnisse.indexOf(this), 1);
  }

  toScope(): JahrgangScopeDto {
    return {
      jahrgaenge: [Jahrgang.toDto(this.zeugnissatz.jahrgang)],
      schuelers: [Schueler.toDto(this.schueler)],
      zeugnissaetze: [Zeugnissatz.toDto(this.zeugnissatz)],
      zeugnisse: [Zeugnis.toDto(this)],
    };
  }

  static override cleanDto(dto: any): any {
    const { id, schueler, zeugnissatz, layouts, isLoading, ...cleanDto } = Metadata.cleanDto(dto);
    return cleanDto;
  }

  static override toDto(zeugnis: Zeugnis): ZeugnisContainer {
    return {
      ...Metadata.toDto(zeugnis),
      id: zeugnis.id,
      schuelerId: zeugnis.schueler.id,
      zeugnissatzId: zeugnis.zeugnissatz.id,
      contentString: JSON.stringify(
        {
          ...this.cleanDto(zeugnis),
          faecher: ItemListIndex.toDto(Fach, zeugnis.faecher),
          layouts: ItemIndex.toDto(UserLayoutFormular, zeugnis.layouts),
        },
        Helper.jsonNullReplacer
      ),
    };
  }

  static fromDto(dto: ZeugnisContainer, schueler: Schueler, zeugnissatz: Zeugnissatz): Zeugnis {
    const contentDto = JSON.parse(dto.contentString);
    const formularsatz = FormularFactory.getFormularsatz(zeugnissatz.jahrgang.bundesland);
    const zeugnis = new formularsatz.zeugnisType(dto.id, schueler, zeugnissatz);
    Metadata.assignDto(zeugnis, dto);
    return Object.assign(zeugnis, {
      ...this.cleanDto(contentDto),
      faecher: ItemListIndex.fromDto(Fach, contentDto.faecher, zeugnis),
      layouts: ItemIndex.fromDto(UserLayoutFormular, contentDto.layouts, zeugnis),
    });
  }

  static assign(dst: Zeugnis, src: Zeugnis) {
    Object.assign(dst, {
      ...this.cleanDto(src),
      faecher: ItemListIndex.fromDto(Fach, ItemListIndex.toDto(Fach, src.faecher), dst),
      layouts: ItemIndex.fromDto(UserLayoutFormular, ItemIndex.toDto(UserLayoutFormular, src.layouts), dst),
    });
  }
}
