/* eslint-disable @typescript-eslint/no-non-null-assertion */
import itiriri from 'itiriri';
import jsPDF from 'jspdf';
import { NodeHelper, NodeWalker } from '../dom';
import { Quantity } from '../dom/quantity';
import { StringHelper } from '../dom/string-helper';
import { TextElement } from './text-element';

export class LineElement extends TextElement {
  constructor() {
    super();
  }

  public static override is(node: Node): node is LineElement {
    return node instanceof HTMLElement && node.tagName.toLowerCase() === 'fz-line';
  }

  exportPdf(doc: jsPDF, pageRect: DOMRect): void {
    const walker = new NodeWalker(this);
    for (const textNode of itiriri(walker.textNodes)) {
      const text = textNode.textContent /*?.replace(/\t/g, ' ')*/ ?? ''; // tab kann nicht vorkommen, da in rich-text.component schon verhindert
      const style = window.getComputedStyle(textNode.parentElement!);
      const fontSize = Quantity.px2Pt(parseFloat(style.fontSize));
      const fontFamily = style.fontFamily;
      const rgb = (style.color.match(/\d+(\.\d+)?/g) ?? ['0', '0', '0']).map((c) => parseFloat(c));
      doc.setTextColor(rgb[0], rgb[1], rgb[2]);
      for (const wordRange of StringHelper.getWordRanges(text)) {
        const stringRect = NodeHelper.getBoundingClientRect({
          node: textNode,
          startOffset: wordRange.start,
          endOffset: wordRange.start + wordRange.length,
        });
        const word = text.slice(wordRange.start, wordRange.start + wordRange.length);
        const span = document.createElement('span');
        span.appendChild(document.createTextNode(word));
        textNode.parentElement!.appendChild(span);
        const topDiff = NodeHelper.getTopDiff(span);
        textNode.parentElement!.removeChild(span);
        this.exportWord(
          doc,
          word,
          fontFamily,
          style.fontWeight,
          fontSize,
          Quantity.px2Mm(stringRect.left - pageRect.left),
          Quantity.px2Mm(stringRect.top + topDiff - pageRect.top)
        );
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'fz-line': LineElement;
  }
}
