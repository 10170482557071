/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { NIZeugnisFormular } from './ni-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { NIZeugnistypComponent } from '../projectables/ni-zeugnistyp.component';
import { NIZeugnissatzComponent } from '../projectables/ni-zeugnissatz.component';
import { NISchuelerComponent } from '../projectables/ni-schueler.component';
import { NIVersaeumnisComponent } from '../projectables/ni-versaeumnis.component';
import { NIUnterschriftComponent } from '../projectables/ni-unterschrift.component';
import { FormularBereich } from '../../../models/formular-bereich';

export class NI34hjBerichtFormular extends NIZeugnisFormular {
  get viewType(): FormularViewType {
    return NI34hjBerichtFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    faecher.push(this.religion, this.musik, this.bildendeKunst, this.werken, this.gestalten, this.sport);
    return faecher;
  }

  get deutschBereiche(): FormularBereich[] {
    return this.zeugnis.zeugnissatz.schuljahr >= 2023
      ? [this.deutsch.sprechenZuhoeren, this.deutsch.texteVerfassen, this.deutsch.lesen]
      : [this.deutsch.sprechenZuhoeren, this.deutsch.lesen, this.deutsch.texteVerfassen];
  }

  get mathematikBereiche(): FormularBereich[] {
    return this.zeugnis.zeugnissatz.schuljahr >= 2023
      ? [
          this.mathematik.zahlenOperationen,
          this.mathematik.groessenMessen,
          this.mathematik.raumForm,
          this.mathematik.datenZufall,
        ]
      : [this.mathematik.zahlenOperationen, this.mathematik.groessenMessen, this.mathematik.raumForm];
  }

  get sachunterrichtBereiche(): FormularBereich[] {
    return this.zeugnis.zeugnissatz.schuljahr >= 2023
      ? [
          this.sachunterricht.technik,
          this.sachunterricht.natur,
          this.sachunterricht.raum,
          this.sachunterricht.gesellschaftPolitik,
          this.sachunterricht.zeitGeschichte,
        ]
      : [
          this.sachunterricht.zeitGeschichte,
          this.sachunterricht.gesellschaftPolitik,
          this.sachunterricht.raum,
          this.sachunterricht.natur,
          this.sachunterricht.technik,
        ];
  }

  get fremdspracheBereiche(): FormularBereich[] {
    return this.zeugnis.zeugnissatz.schuljahr >= 2023
      ? [this.fremdsprache.hoerverstehen, this.fremdsprache.muendlich, this.fremdsprache.lesen]
      : [
          this.fremdsprache.kommunikativeFertigkeiten,
          this.fremdsprache.verfuegungSprachlicheMittel,
          this.fremdsprache.interkulturelleKompetenzen,
          this.fremdsprache.methodenkompetenzen,
        ];
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    return noteFields;
  }
}

@Component({
  selector: 'fz-ni-34hj-bericht-formular',
  templateUrl: 'ni-34hj-bericht-formular.component.html',
})
export class NI34hjBerichtFormularComponent extends FormularComponentBase<NI34hjBerichtFormular> {
  static createModel(formular: NI34hjBerichtFormular) {
    return formular.zeugnis.zeugnissatz.schuljahr >= 2023
      ? {
          zeugnistyp: NIZeugnistypComponent.createModel(formular),
          zeugnissatz: NIZeugnissatzComponent.createModel(formular),
          schueler: NISchuelerComponent.createModel(formular),
          versaeumnis: NIVersaeumnisComponent.createModel(formular),
          deutsch: {
            label: 'Deutsch Kompetenzbereiche¹',
            bereiche: formular.deutschBereiche.map((bereich) => ({
              trackBy: bereich.key ?? 'gesamt',
              label: bereich.description.label,
              textField: bereich.text,
            })),
          },
          mathematik: {
            label: 'Mathematik Kompetenzbereiche²',
            bereiche: formular.mathematikBereiche.map((bereich) => ({
              trackBy: bereich.key ?? 'gesamt',
              label: bereich.description.label,
              textField: bereich.text,
            })),
          },
          sachunterricht: {
            label: 'Sachunterricht Kompetenzbereiche',
            bereiche: formular.sachunterrichtBereiche.map((bereich) => ({
              trackBy: bereich.key ?? 'gesamt',
              label: bereich.description.label,
              textField: bereich.text,
            })),
          },
          fremdsprache: {
            label: 'Fremdsprache Kompetenzbereiche',
            bereiche: formular.fremdspracheBereiche.map((bereich) => ({
              trackBy: bereich.key ?? 'gesamt',
              label: bereich.description.label,
              textField: bereich.text,
            })),
          },
          faecher: formular.faecher.map((fach) => ({
            trackBy: fach.key,
            key: fach.key,
            label: fach.gesamt.description.label,
            textField: fach.gesamt.text,
          })),
          herkunftssprache:
            formular.zeugnis.zeugnissatz.schuljahr >= 2023 &&
            formular.herkunftssprache.gesamt.isVisibleOverrideDefaultTrue
              ? {
                  label: 'Herkunftssprachlicher Unterricht',
                  labelTextField: formular.herkunftssprache.gesamt.label,
                  textField: formular.herkunftssprache.gesamt.text,
                }
              : undefined,
          arbeitsgemeinschaften: {
            label: formular.arbeitsgemeinschaften.description.label,
            textField: formular.arbeitsgemeinschaften,
          },
          interessen: {
            label: 'Besondere Interessen und Fähigkeiten',
            textField: formular.interessen,
          },
          lernArbeitsverhalten: {
            label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
          },
          sozialverhalten: {
            label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
          },
          bemerkungen: {
            label: formular.bemerkungen.description.label,
            textField: formular.bemerkungen,
          },
          unterschrift: NIUnterschriftComponent.createModel(formular),
        }
      : {
          zeugnistyp: NIZeugnistypComponent.createModel(formular),
          zeugnissatz: NIZeugnissatzComponent.createModel(formular),
          schueler: NISchuelerComponent.createModel(formular),
          versaeumnis: NIVersaeumnisComponent.createModel(formular),
          interessen: {
            label: formular.interessen.description.label,
            textField: formular.interessen,
          },
          deutsch: {
            label: formular.deutsch.gesamt.description.label,
            bereiche: formular.deutschBereiche.map((bereich) => ({
              trackBy: bereich.key ?? 'gesamt',
              label: bereich.description.label,
              textField: bereich.text,
            })),
          },
          mathematik: {
            label: formular.mathematik.gesamt.description.label,
            bereiche: formular.mathematikBereiche.map((bereich) => ({
              trackBy: bereich.key ?? 'gesamt',
              label: bereich.description.label,
              textField: bereich.text,
            })),
          },
          sachunterricht: {
            label: formular.sachunterricht.gesamt.description.label,
            bereiche: formular.sachunterrichtBereiche.map((bereich) => ({
              trackBy: bereich.key ?? 'gesamt',
              label: bereich.description.label,
              textField: bereich.text,
            })),
          },
          fremdsprache: {
            label: formular.fremdsprache.gesamt.description.label,
            bereiche: formular.fremdspracheBereiche.map((bereich) => ({
              trackBy: bereich.key ?? 'gesamt',
              label: bereich.description.label,
              textField: bereich.text,
            })),
          },
          faecher: formular.faecher.map((fach) => ({
            trackBy: fach.key,
            key: fach.key,
            label: fach.gesamt.description.label,
            textField: fach.gesamt.text,
          })),
          lernArbeitsverhalten: {
            label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
          },
          sozialverhalten: {
            label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
          },
          bemerkungen: {
            label: formular.bemerkungen.description.label,
            textField: formular.bemerkungen,
          },
          unterschrift: NIUnterschriftComponent.createModel(formular),
        };
  }

  get model() {
    return NI34hjBerichtFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<NI34hjBerichtFormular>) {
    super(formularService);
  }
}
