import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MenuModule } from 'primeng/menu';
import { BlockModule } from '@modules/blocks/block.module';
import { SharedModule } from '@modules/shared/shared.module';
import { KPBlockFactoryComponent } from './kp-block-factory.component';
import { RadioComponent } from './radio.component';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    MenuModule,
    SharedModule,
    BlockModule,
    ListboxModule,
    RadioButtonModule,
    DropdownModule,
  ],
  exports: [KPBlockFactoryComponent],
  declarations: [KPBlockFactoryComponent, RadioComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class KPModule {}
