<div>
  <div>
    <p-checkbox
      name="schuelerTyp"
      [value]="null"
      [(ngModel)]="formular.isAbgang"
      [binary]="true"
      label="Abgangszeugnis"
    ></p-checkbox>
  </div>
  <div
    *ngIf="formular.zeugnis.zeugnissatz.klassenstufe >= 3 && formular.isAbgang && formular.zeugnis.schuelerTyp === null"
  >
    <div>
      <p-radioButton
        name="abgangszeugnisTyp"
        [value]="null"
        [(ngModel)]="formular.zeugnis.abgangszeugnisTyp"
        label="Standard"
      ></p-radioButton>
    </div>
    <div>
      <p-radioButton
        name="abgangszeugnisTyp"
        [value]="'KP'"
        [(ngModel)]="formular.zeugnis.abgangszeugnisTyp"
        label="Könnensprofil zum Ankreuzen"
      ></p-radioButton>
    </div>
    <div>
      <p-radioButton
        name="abgangszeugnisTyp"
        [value]="'KPVerbal'"
        [(ngModel)]="formular.zeugnis.abgangszeugnisTyp"
        label="Könnensprofil zum Ankreuzen mit Verbalbeurteilung"
      ></p-radioButton>
    </div>
    <div>
      <p-radioButton
        name="abgangszeugnisTyp"
        [value]="'Noten'"
        [(ngModel)]="formular.zeugnis.abgangszeugnisTyp"
        label="Noten"
      ></p-radioButton>
    </div>
    <div>
      <p-radioButton
        name="abgangszeugnisTyp"
        [value]="'Verbal'"
        [(ngModel)]="formular.zeugnis.abgangszeugnisTyp"
        label="Verbal"
      ></p-radioButton>
    </div>
  </div>
</div>
