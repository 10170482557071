/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, ElementRef, Input } from '@angular/core';
import { PanelComponent } from './panel.component';

@Directive({ selector: '[fzSelectCandidate]' })
export class SelectCandidateDirective {
  @Input('fzLabel') label = '';
  @Input('fzValue') value: unknown;
  constructor(
    public container: PanelComponent,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  set show(value: boolean) {
    this.elementRef.nativeElement.style.display = value ? 'block' : 'none';
  }
}
