<fz-panel *ngIf="model != null">
  <div
    *ngIf="formular.zeugnis.zeugnisTyp !== 'Abgang'"
    style="
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto 38px auto 38px auto;
      column-gap: 38px;
    "
  >
    <div style="grid-row: 1; grid-column: 1 / span 2">
      <fz-label class="Zeugnisausgabe_Ort">{{ formular.zeugnissatz.zeugniskopflizenz.schulort }}, den </fz-label>
      <fz-linked-date
        class="Zeugnisausgabe_Datum"
        fieldName="zeugnisausgabedatum"
        [field]="model.zeugnisausgabedatumField"
        [linkedField]="formular.zeugnissatz.zeugnisausgabedatum"
      ></fz-linked-date>
    </div>
    <fz-panel
      *ngIf="model.schulleitungField.value !== ''"
      style="grid-row: 4; grid-column: 1"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 5; grid-column: 1"
      class="Unterschriften_Beschriftung"
      [field]="model.schulleitungField"
      placeholder="Bezeichnung Schulleitung"
    ></fz-text>
    <fz-panel
      *ngIf="model.klassenleitungField.value !== ''"
      style="grid-row: 4; grid-column: 2"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 5; grid-column: 2"
      class="Unterschriften_Beschriftung"
      [field]="model.klassenleitungField"
      placeholder="Bezeichnung Klassenleitung"
    ></fz-text>
    <div style="grid-row: 1; grid-column: 3">
      <fz-label class="Unterschriften_Beschriftung">Kenntnis genommen</fz-label>
    </div>
    <fz-panel style="grid-row: 2; grid-column: 3" [border]="borderDirection.bottom"></fz-panel>
    <fz-label style="grid-row: 3; grid-column: 3" class="Unterschriften_Beschriftung">Datum</fz-label>
    <fz-panel style="grid-row: 4; grid-column: 3" [border]="borderDirection.bottom"></fz-panel>
    <fz-label style="grid-row: 5; grid-column: 3" class="Unterschriften_Beschriftung"
      >Erziehungsberechtigte Person</fz-label
    >
  </div>
  <div
    *ngIf="formular.zeugnis.zeugnisTyp === 'Abgang'"
    style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-template-rows: auto 57px auto; column-gap: 38px"
  >
    <div style="grid-row: 1; grid-column: 1 / span 2">
      <fz-label class="Zeugnisausgabe_Ort">{{ formular.zeugnissatz.zeugniskopflizenz.schulort }}, den </fz-label>
      <fz-date class="Zeugnisausgabe_Datum" [field]="model.zeugnisausgabedatumField"></fz-date>
    </div>
    <fz-panel
      *ngIf="model.schulleitungField.value !== ''"
      style="grid-row: 2; grid-column: 1"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 3; grid-column: 1"
      class="Unterschriften_Beschriftung"
      [field]="model.schulleitungField"
      placeholder="Bezeichnung Schulleitung"
    ></fz-text>
    <div style="grid-row: 1 / span 3; grid-column: 2; display: grid; justify-content: center; align-content: center">
      <fz-he-siegel style="grid-row: 1; grid-column: 1; width: 76px; height: 76px"></fz-he-siegel>
    </div>
    <fz-panel
      *ngIf="model.klassenleitungField.value !== ''"
      style="grid-row: 2; grid-column: 3"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 3; grid-column: 3"
      class="Unterschriften_Beschriftung"
      [field]="model.klassenleitungField"
      placeholder="Bezeichnung Klassenleitung"
    ></fz-text>
  </div>
</fz-panel>
