import { NgModule } from '@angular/core';
import { DiffViewerComponent } from './diff-viewer.component';
import { NgxObjectDiffComponent } from './ngx-object-diff.component';

@NgModule({
  declarations: [NgxObjectDiffComponent, DiffViewerComponent],
  imports: [],
  exports: [DiffViewerComponent],
})
export class DiffViewerModule {}
