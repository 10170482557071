/* eslint-disable no-bitwise */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  Input,
  Optional,
  SkipSelf,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BorderDirection, borderWidthNormal } from '@modules/dom/border-direction';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Distance } from '../dom/distance';
import { getDistanceOverride, DistanceOverride } from '../dom/distance-override';
import { Projectable, ProjectableBase, ProjectableDecoratorBase, ProjectableProvider } from './projectable';

export type ContainerChild = {
  x: number;
  y: number;
  template: TemplateRef<void>;
};

@Component({
  selector: 'fz-panel',
  templateUrl: 'panel.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, PanelComponent)],
})
export class PanelComponent extends ProjectableBase implements AfterViewInit, Projectable {
  @Input() name = '';
  @Input() border: BorderDirection = BorderDirection.none;
  @Input() borderColor = 'black';
  @Input() backgroundColor?: string;
  @Input() padding: Distance = 'none';
  @Input() paddingTop: DistanceOverride = 'use-fallback';
  @Input() paddingBottom: DistanceOverride = 'use-fallback';
  @Input() paddingLeft: DistanceOverride = 'use-fallback';
  @Input() paddingRight: DistanceOverride = 'use-fallback';
  @Input() inline: boolean = false;

  borderDirection = BorderDirection;
  rect: DOMRect = new DOMRect();
  borderWidth = borderWidthNormal;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef
  ) {
    super(elementRef, parentProvider);
  }
  get isBorderTop(): boolean {
    return (this.border & BorderDirection.top) === BorderDirection.top;
  }
  get isBorderBottom(): boolean {
    return (this.border & BorderDirection.bottom) === BorderDirection.bottom;
  }
  get isBorderLeft(): boolean {
    return (this.border & BorderDirection.left) === BorderDirection.left;
  }

  get isBorderRight(): boolean {
    return (this.border & BorderDirection.right) === BorderDirection.right;
  }
  get paddingTopPx(): number {
    return getDistanceOverride(this.paddingTop, this.padding);
  }

  get paddingBottomPx(): number {
    return getDistanceOverride(this.paddingBottom, this.padding);
  }

  get paddingLeftPx(): number {
    return getDistanceOverride(this.paddingLeft, this.padding);
  }

  get paddingRightPx(): number {
    return getDistanceOverride(this.paddingRight, this.padding);
  }

  get height(): number {
    return this.sourceElement.getBoundingClientRect().height;
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges(); // initialisiere border
  }

  onFocusChange(): void {
    this.changeDetector.detectChanges();
  }

  focus(): void {
    this.focusables.get(0)?.focus();
  }

  override project(): void {
    const childRect = this.sourceElement.getBoundingClientRect();
    this.rect = new DOMRect(this.rect.x, this.rect.y, childRect.width, childRect.height);
    this.sortChildren();
    this.changeDetector.detectChanges();
    for (const child of this.children) {
      child.project();
      child.projectPosition(this.sourceElement.getBoundingClientRect());
    }
  }

  projectPosition(parentRect: DOMRect): void {
    const childRect = this.sourceElement.getBoundingClientRect();
    this.rect = new DOMRect(
      childRect.left - parentRect.left,
      childRect.top - parentRect.top,
      this.rect.width,
      this.rect.height
    );
    this.changeDetector.detectChanges();
  }
}

@Directive()
export class PanelDecoratorBase extends ProjectableDecoratorBase {
  @ViewChild(PanelComponent) panel: PanelComponent | undefined;
  borderDirection = BorderDirection;
}
