import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-import-tb-no-data',
  templateUrl: './import-tb-no-data.component.html',
  styleUrls: ['./import-tb-no-data.component.scss'],
})
export class ImportTbNoDataComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Output() restartSelected = new EventEmitter<boolean>();

  restart() {
    this.restartSelected.next(true);
  }
}
