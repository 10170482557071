import { ChangeDetectorRef, Component, ElementRef, Input, Optional, SkipSelf, ViewChild } from '@angular/core';
import {
  Projectable,
  ProjectableBase,
  ProjectableOwnerProvider,
  ProjectableProvider,
} from '@modules/projectables/projectable';
import { ValueField } from '../../models/fields/value-field';

@Component({
  selector: 'fz-checkbox',
  styles: [':host { height: 100%; display: grid; justify-items: center; align-items: center }'],
  templateUrl: 'checkbox.component.html',
})
export class CheckboxComponent extends ProjectableBase implements Projectable {
  @Input() field: ValueField<any, boolean | null> | undefined;
  @ViewChild('cross') cross?: ElementRef<HTMLElement>;
  rect = new DOMRect();
  crossRect = new DOMRect();
  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef,
    private ownerProvider: ProjectableOwnerProvider
  ) {
    super(elementRef, parentProvider);
  }

  onClick(_e: MouseEvent): boolean {
    if (this.field != null) {
      this.field.value = !(this.field.value ?? false);
      this.ownerProvider.provided.projectableContentChange(this);
    }
    return false;
  }

  projectPosition(parentRect: DOMRect): void {
    const childRect = this.sourceElement.getBoundingClientRect();
    this.rect = new DOMRect(
      childRect.left - parentRect.left,
      childRect.top - parentRect.top,
      childRect.width,
      childRect.height
    );
    if (this.cross != null) {
      const crossRect = this.cross.nativeElement.getBoundingClientRect();
      this.crossRect = new DOMRect(
        crossRect.left - childRect.left,
        crossRect.top - childRect.top,
        crossRect.width,
        crossRect.height
      );
      this.changeDetector.detectChanges();
    }
  }
}
