<fz-panel *ngIf="model != null">
  <div class="Ueberschriften2">
    <ng-container *ngIf="model.dropdownField != null">
      <fz-dropdown [field]="model.dropdownField"></fz-dropdown>
    </ng-container>
    <ng-container *ngIf="model.label != null">
      <fz-label>{{ model.label }}</fz-label>
    </ng-container>
  </div>
</fz-panel>
