import { Component } from '@angular/core';
import { OptionsBase } from '../../formular/options-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-sav-visible',
  templateUrl: 'he-sav-visible.component.html',
})
export class HESavVisibleComponent extends OptionsBase<HEFormular> {
  get savVisible(): boolean {
    return this.formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideItemsDefaultFalse;
  }
  set savVisible(value: boolean) {
    this.formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideItemsDefaultFalse = value;
  }
}
