/* eslint-disable no-console */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  // console.log = () => {};
  Sentry.init({
    debug: !environment.production,
    release: 'flinky-zeugnis-evo@' + environment.clientVersion,
    dsn: 'https://22e9e46b6797d08d54ad36877ff10372@o4507369747316736.ingest.de.sentry.io/4507369756033104',
    integrations: [
      Sentry.breadcrumbsIntegration({ console: false }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: false,
        //mask: ['fz-string', 'fz-line'],
        mutationLimit: 0, // The maximum number of mutations to replay. Default is 1000. - 0 means no limit.
      }),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    // beforeSend(event, _hint) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception && event.event_id) {
    //     Sentry.showReportDialog({ eventId: event.event_id, lang: 'de', includeReplay: true });
    //   }
    //   return event;
    // },
    tracesSampleRate: !environment.production ? 1.0 : 0.1, // This sets the sample rate at 100%. You may want to change it to 0.1 or 0.01 for production.
    // Session Replay
    replaysSessionSampleRate: !environment.production ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    profilesSampleRate: 1.0,
  });
}

Sentry.startSpan(
  {
    name: 'bootstrap-angular-application',
    op: 'ui.angular.bootstrap',
  },
  async () => {
    await platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  }
);
