import { Component } from '@angular/core';
import moment from 'moment';
import { WorkspaceService } from '../../../shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'fz-formular-lock',
  templateUrl: './formular-lock.component.html',
  styleUrls: ['./formular-lock.component.scss'],
})
export class FormularLockComponent {
  constructor(
    public workspace: WorkspaceService,
    public router: Router
  ) {}
  get currentDate() {
    return moment(new Date()).format('DD.MM.YYYY');
  }
}
