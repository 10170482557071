import { Fach } from './fach';
import { ListIndexable } from './item-list-index';
import { KPBereich } from './kp-bereich';
import { KPItemBenotungIndex } from './kp-item-benotung';
import { Note } from './note';

export class Bereich implements ListIndexable {
  index: number = 0;
  label: string | null = null;
  text: string | null = null;
  note: Note | null = null;
  items: KPItemBenotungIndex = new KPItemBenotungIndex(this);
  version: number | null = null;
  zusatztext: string | null = null;
  isVisibleOverride: boolean | null = null;
  isVisibleOverrideNote: boolean | null = null;
  isVisibleOverrideText: boolean | null = null;
  isVisibleOverrideItems: boolean | null = null;
  isVisibleOverrideZusatztext: boolean | null = null;

  get isVisibleOverrideDefaultFalse(): boolean {
    return this.isVisibleOverride ?? false;
  }
  set isVisibleOverrideDefaultFalse(value: boolean) {
    this.isVisibleOverride = value ? true : null;
  }
  get isVisibleOverrideDefaultTrue(): boolean {
    return this.isVisibleOverride ?? true;
  }
  set isVisibleOverrideDefaultTrue(value: boolean) {
    this.isVisibleOverride = value ? null : false;
  }
  get isVisibleOverrideNoteDefaultFalse(): boolean {
    return this.isVisibleOverrideNote ?? false;
  }
  set isVisibleOverrideNoteDefaultFalse(value: boolean) {
    this.isVisibleOverrideNote = value ? true : null;
  }
  get isVisibleOverrideNoteDefaultTrue(): boolean {
    return this.isVisibleOverrideNote ?? true;
  }
  set isVisibleOverrideNoteDefaultTrue(value: boolean) {
    this.isVisibleOverrideNote = value ? null : false;
  }
  get isVisibleOverrideTextDefaultFalse(): boolean {
    return this.isVisibleOverrideText ?? false;
  }
  set isVisibleOverrideTextDefaultFalse(value: boolean) {
    this.isVisibleOverrideText = value ? true : null;
  }
  get isVisibleOverrideTextDefaultTrue(): boolean {
    return this.isVisibleOverrideText ?? true;
  }
  set isVisibleOverrideTextDefaultTrue(value: boolean) {
    this.isVisibleOverrideText = value ? null : false;
  }
  get isVisibleOverrideZusatztextDefaultFalse(): boolean {
    return this.isVisibleOverrideZusatztext ?? false;
  }
  set isVisibleOverrideZusatztextDefaultFalse(value: boolean) {
    this.isVisibleOverrideZusatztext = value ? true : null;
  }
  get isVisibleOverrideZusatztextDefaultTrue(): boolean {
    return this.isVisibleOverrideZusatztext ?? true;
  }
  set isVisibleOverrideZusatztextDefaultTrue(value: boolean) {
    this.isVisibleOverrideZusatztext = value ? null : false;
  }

  get rasterBereich(): KPBereich {
    return this.fach.rasterFach.bereiche.getItemList(this.key).getIndex(this.index);
  }

  constructor(
    public readonly key: string | null,
    public readonly fach: Fach
  ) {}

  merge(source: Bereich, append: boolean = false, appendText: string = '') {
    // nur ersetzen, falls neues Zeugnis eine Note enthält
    this.label = source.label ?? this.label;
    this.note = source.note ?? this.note;
    if (!append) {
      this.text = source.text ?? this.text;
    } else {
      if (source.text != null && source.text !== '') this.text = (this.text ?? '') + appendText + (source.text ?? '');
    }
    this.zusatztext = source.zusatztext ?? this.zusatztext;
    this.items.merge(source.items);

    this.isVisibleOverride = source.isVisibleOverride;
    this.isVisibleOverrideNote = source.isVisibleOverrideNote;
    this.isVisibleOverrideText = source.isVisibleOverrideText;
    this.isVisibleOverrideZusatztext = source.isVisibleOverrideZusatztext;
    this.isVisibleOverrideItems = source.isVisibleOverrideItems;
  }

  static cleanDto(dto: any): any {
    const { key, fach, items, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(bereich: Bereich): unknown {
    return {
      ...this.cleanDto(bereich),
      key: bereich.key,
      items: KPItemBenotungIndex.toDto(bereich.items),
      note: bereich.note?.toDto() ?? null,
    };
  }

  static fromDto(dto: any, fach: Fach): Bereich {
    const bereich = new Bereich(dto.key ?? null, fach);
    return Object.assign(bereich, {
      ...this.cleanDto(dto),
      note: dto.note == null ? null : Note.fromDto(dto.note),
      items: KPItemBenotungIndex.fromDto(dto.items, bereich),
    });
  }
}
