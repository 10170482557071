<fz-panel *ngIf="model != null">
  <div>
    <fz-label class="Ueberschriften2">Leistungen in den einzelnen Fächern und Fächerverbünden</fz-label>
  </div>
  <div style="margin-top: 8px; display: grid; grid-template-columns: 1fr 1fr; column-gap: 19px">
    <div
      style="
        grid-column: 1;
        display: grid;
        grid-template-columns: 1fr 151px;
        grid-auto-rows: min-content;
        row-gap: 19px;
      "
    >
      <div class="Noten_Beschriftung">
        <div>
          <fz-label>{{ model.religion.label }}</fz-label>
        </div>
        <div class="Fusstext" style="position: absolute" *ngIf="model.religion.labelField != null">
          <fz-dropdown [field]="model.religion.labelField"></fz-dropdown>
        </div>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.religion.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.deutsch.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.deutsch.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.mathematik.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.mathematik.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-dropdown [field]="model.fremdsprache.labelField"></fz-dropdown>
      </div>
      <div class="Noten_Text" style="align-self: center">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.fremdsprache.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.sachunterricht.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.sachunterricht.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
    <div
      style="
        grid-column: 2;
        display: grid;
        grid-template-columns: 1fr 151px;
        grid-auto-rows: min-content;
        row-gap: 19px;
      "
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.bildendeKunst.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.bildendeKunst.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.musik.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.musik.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.sport.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.sport.noteField"></fz-note>
        </fz-panel>
      </div>
      <ng-container *ngIf="model.schrift != null">
        <div class="Noten_Beschriftung">
          <fz-label>{{ model.schrift.label }}</fz-label>
        </div>
        <div class="Noten_Text">
          <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
            <fz-note [field]="model.schrift.noteField"></fz-note>
          </fz-panel>
        </div>
      </ng-container>
    </div>
  </div>
</fz-panel>
