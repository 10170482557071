import { Component } from '@angular/core';
import { ProjectableProvider } from './projectable';
import { PanelDecoratorBase } from './panel.component';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
@Component({
  selector: 'fz-test-container',
  templateUrl: 'test-container.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, TestContainerComponent)],
})
export class TestContainerComponent extends PanelDecoratorBase {}
