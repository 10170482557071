import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { SLFolgeseiteComponent } from '../projectables/sl-folgeseite.component';
import { SLSeitenfussComponent } from '../projectables/sl-seitenfuss.component';
import { SLLegendeComponent } from '../projectables/sl-legende.component';
import { SLZeugnisFormular } from './sl-zeugnis-formular';
import { NoteField } from '../../../models/fields/note-field';
import { FormularViewType } from '../../formular/formular';
import { SLZeugnissatzComponent } from '../projectables/sl-zeugnissatz.component';
import { SLSchuelerComponent } from '../projectables/sl-schueler.component';
import { SLSchulortComponent } from '../projectables/sl-schulort.component';
import { SLUnterschriftComponent } from '../projectables/sl-unterschrift.component';
import { SLSchulbesuchsjahrComponent } from '../projectables/sl-schulbesuchsjahr.component';
import { SLLeistungenComponent } from '../projectables/sl-leistungen.component';
import { SLSprachenportfolioComponent } from '../projectables/sl-sprachenportfolio.component';
import { SLVersaeumnisComponent } from '../projectables/sl-versaeumnis.component';

export class SL12hjNotenFormular extends SLZeugnisFormular {
  get viewType(): FormularViewType {
    return SL12hjNotenFormularComponent;
  }
  override get noteFields(): NoteField[] {
    return [
      this.religion.gesamt.note,
      this.deutsch.gesamt.note,
      this.deutsch.sprechenZuhoeren.note,
      this.deutsch.lesen.note,
      this.deutsch.rechtschreiben.note,
      this.sachunterricht.gesamt.note,
      this.mathematik.gesamt.note,
      this.bildendeKunst.gesamt.note,
      this.musik.gesamt.note,
      this.sport.gesamt.note,
      this.schrift.gesamt.note,
    ];
  }
}

@Component({
  selector: 'fz-sl-12hj-noten-formular',
  templateUrl: './sl-12hj-noten-formular.component.html',
})
export class SL12hjNotenFormularComponent extends FormularComponentBase<SL12hjNotenFormular> {
  static createModel(formular: SL12hjNotenFormular) {
    return {
      zeugnissatz: SLZeugnissatzComponent.createModel(formular),
      schulbesuchsjahr: SLSchulbesuchsjahrComponent.createModel(formular),
      schueler: SLSchuelerComponent.createModel(formular),
      sozialverhalten: {
        label: 'Sozialverhalten',
        subLabel:
          '(z.B. Gewissenhaftigkeit, Zuverlässigkeit, Kooperationsbereitschaft, Hilfsbereitschaft, angemessener Umgang mit Konflikten, Selbstkontrolle und -reflexion, Selbstständigkeit, Toleranz, Teamfähigkeit, Einhalten von Regeln)',
        textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
      },
      lernArbeitsverhalten: {
        label: 'Lern- und Arbeitsverhalten',
        subLabel:
          '(z.B. Anstrengungsbereitschaft, Ausdauer, Aufmerksamkeit, Lernorganisation, Lernreflexion, Sorgfalt)',
        textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
      },
      fachbezogeneLernLeistungsentwicklung: !formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule
        ? {
            label: 'Fachbezogene Lern- und Leistungsentwicklung',
            textField: formular.fachbezogeneLernLeistungsentwicklung.gesamt.text,
          }
        : undefined,
      besondereFaehigkeiten: {
        label: 'Besondere Fähigkeiten/Fertigkeiten',
        textField: formular.besondereFaehigkeiten,
      },
      leistungen: SLLeistungenComponent.createModel(formular),
      sprachenportfolio: formular.zeugnis.zeugnissatz.zeugniskopflizenz.isBilingual
        ? SLSprachenportfolioComponent.createModel(formular)
        : undefined,
      bemerkungen: {
        label: 'Bemerkungen',
        textField: formular.bemerkungen,
      },
      versaeumnis: SLVersaeumnisComponent.createModel(formular),
      schulort: SLSchulortComponent.createModel(formular),
      unterschrift: SLUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return SL12hjNotenFormularComponent.createModel(this.formular);
  }

  slFolgeseiteComponent = SLFolgeseiteComponent;
  slSeitenfussComponent = SLSeitenfussComponent;
  slLegendeComponent = SLLegendeComponent;

  constructor(formularService: FormularService<SL12hjNotenFormular>) {
    super(formularService);
  }
}
