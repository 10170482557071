<fz-panel *ngIf="model != null">
  <div style="margin-top: 8px; display: grid; grid-template-columns: 1fr 1fr; column-gap: 38px">
    <div
      style="grid-column: 1; display: grid; grid-template-columns: 1fr 151px; grid-auto-rows: min-content; row-gap: 8px"
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.lernArbeitsverhalten.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.lernArbeitsverhalten.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
    <div
      style="grid-column: 2; display: grid; grid-template-columns: 1fr 151px; grid-auto-rows: min-content; row-gap: 8px"
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.sozialverhalten.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.sozialverhalten.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
  </div>
</fz-panel>
