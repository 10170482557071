import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  QueryList,
  SkipSelf,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableOwnerProvider, ProjectableBase } from './projectable';
import { RichTextSelection } from './rich-text-selection';
import { TextEditorDirective, TextView, TextViewProvider } from './text-editor.directive';
import { TextRange } from '@modules/pdl/text-range';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { StringElement } from '@modules/pdl';
import { NodeHelper } from '@modules/dom';
import { StringHelper } from '@modules/dom/string-helper';
import { TextField } from '../../models/fields/text-field';
import { ContextMenu } from 'primeng/contextmenu';
import { TextViewAccessor } from './text-view-accessor';
import { getPlatformShortcut } from './platform';

type Word = {
  fragment: DocumentFragment;
  textNode: Text;
  text: string;
  range: TextRange;
  rect: DOMRect;
  fontFamily: string;
  fontWeight: string;
  fontSize: string;
  isFirst: boolean;
  isLast: boolean;
};

@Component({
  selector: 'fz-text',
  templateUrl: 'text.component.html',
  providers: [
    provideInterfaceBy(ProjectableProvider, TextComponent),
    provideInterfaceBy(TextViewProvider, TextComponent),
  ],
})
export class TextComponent extends ProjectableBase implements DoCheck, AfterViewInit, Projectable, TextView {
  @Input() placeholder = 'Text';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() field: TextField<any> | undefined;
  @Output() modelChange = new EventEmitter<string | null>();
  @ViewChildren('stringElement') stringElementRef = new QueryList<ElementRef<StringElement>>();
  @ViewChildren(TextEditorDirective) editors = new QueryList<TextEditorDirective>();
  @ViewChild(ContextMenu) contextMenu: ContextMenu | undefined;

  readonly selection = new RichTextSelection();
  #text = '';
  words: Word[] = [];
  afterInit = false;
  contextMenuItems = [
    {
      label: `Ausschneiden${getPlatformShortcut('x')}`,
      command: (): void => {
        this.cut();
      },
    },
    {
      label: `Kopieren${getPlatformShortcut('c')}`,
      command: (): void => {
        this.copy();
      },
    },
    {
      label: `Einfügen${getPlatformShortcut('v')}`,
      command: (): void => {
        this.paste();
      },
    },
    { separator: true },
    {
      label: `Alles markieren${getPlatformShortcut('a')}`,
      command: (): void => {
        this.selectAll();
      },
    },
  ];

  private get stringElements(): StringElement[] {
    return this.stringElementRef.toArray().map((ref) => ref.nativeElement);
  }

  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef,
    private ownerProvider: ProjectableOwnerProvider
  ) {
    super(elementRef, parentProvider);
    this.selection.changed.subscribe(() => {
      this.changeDetector.detectChanges(); // tabindex
    });
  }

  @Input()
  get model(): string | null {
    if (this.text === '') return null;
    else return this.text;
  }
  set model(text: string | null) {
    this.text = text ?? '';
  }

  get text(): string {
    return this.#text;
  }
  set text(value: string) {
    if (this.#text !== value) {
      this.#text = value;
      this.modelChange.emit(this.model);
      if (this.field != null && this.field.value !== value) this.field.value = value;
      this.updateSlices();
      if (this.afterInit) this.ownerProvider.provided.projectableContentChange(this);
    }
  }

  isWordSelected(word: Word): boolean {
    return word.range.start <= this.selection.focus && this.selection.focus <= word.range.start + word.range.length;
  }
  ngDoCheck(): void {
    if (this.field != null && this.field.value !== this.model) this.model = this.field.value;
  }
  ngAfterViewInit(): void {
    this.afterInit = true;
    this.updateSlices();
  }

  projectPosition(parentRect: DOMRect): void {
    const style = window.getComputedStyle(this.sourceElement);
    const topDiff = NodeHelper.getFontTopDiff(style.fontFamily, style.fontSize, style.fontWeight);
    for (const word of this.words) {
      const element = word.textNode.parentNode as HTMLElement;
      const childRect = element.getBoundingClientRect();
      word.rect = new DOMRect(
        childRect.left - parentRect.left,
        childRect.top + topDiff.topDiff - parentRect.top,
        childRect.width,
        topDiff.height
      );
      word.fontFamily = style.fontFamily;
      word.fontSize = style.fontSize;
      word.fontWeight = style.fontWeight;
    }
    this.changeDetector.detectChanges();
  }

  keyFilter(e: string): boolean {
    return e !== '\n' && e !== '\r';
  }

  onContextmenu(e: MouseEvent): boolean {
    this.contextMenu?.toggle(e);
    return false;
  }

  cut(): void {
    document.execCommand('cut');
  }
  copy(): void {
    document.execCommand('copy');
  }
  paste(): void {
    navigator.clipboard.readText().then((pasteText) => {
      pasteText = StringHelper.removeSpecialCharacters(pasteText) ?? '';
      if (pasteText.length > 0) {
        this.text = `${this.text.slice(0, this.selection.start)}${pasteText}${this.text.slice(this.selection.end)}`;
        this.selection.setPosition(this.selection.start + pasteText.length);
        this.updateSlices();
      }
    });
  }
  selectAll() {
    this.selection.setBaseAndExtend(0, this.text.length);
    this.updateSelection();
  }

  updateSelection(): void {
    const textView = new TextViewAccessor(this.stringElements);
    textView.updateSelection(this.selection.anchor, this.selection.focus, false);
  }

  private updateSlices() {
    if (this.afterInit) {
      const words = StringHelper.getWordWithSpaceRanges(this.#text);
      if (words.length === 0) {
        words.push({
          start: 0,
          length: 0,
          spaceLength: 0,
        });
      }
      this.words.splice(0);
      this.words.push(
        ...words.map((word) => {
          const text = this.#text.slice(word.start, word.start + word.length + word.spaceLength);
          const nonBreakingHyphen = text.replace(/-/g, String.fromCharCode(8209));
          const textNode = document.createTextNode(nonBreakingHyphen);
          const fragment = document.createDocumentFragment();
          fragment.append(textNode);
          return {
            fragment,
            textNode,
            text,
            range: {
              start: word.start,
              length:
                word === words[words.length - 1] ? word.length + word.spaceLength : word.length + word.spaceLength - 1,
            },
            rect: new DOMRect(),
            fontFamily: '',
            fontSize: '',
            fontWeight: '',
            isFirst: word === words[0],
            isLast: word === words[this.words.length - 1],
          };
        })
      );
      this.changeDetector.detectChanges();
    }
  }
}
