<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="flex flex-col">
    Der Export wurde erfolgreich erstellt und die Datei gespeichert. Bitte übergeben Sie diese sowie das angegebene
    Passwort an den Empfänger.
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Zurück zur Zeugnisübersicht"
        class="w-80"
        icon="pi pi-home"
        (click)="navigateToZeugnisse()"
      ></button>
    </div>
  </ng-template>
</p-panel>
