import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CircleElement } from './circle-element';
import { CrossElement } from './cross-element';
import { ImageElement } from './image-element';
import { LineElement } from './line-element';
import { RectElement } from './rect-element';
import { StringElement } from './string-element';
import { TranslateElement } from './translate-element';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class PdlModule {}

customElements.define('fz-line', LineElement);
customElements.define('fz-image', ImageElement);
customElements.define('fz-string', StringElement);
customElements.define('fz-rect', RectElement);
customElements.define('fz-translate', TranslateElement);
customElements.define('fz-cross', CrossElement);
customElements.define('fz-circle', CircleElement);
