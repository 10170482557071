import { ClientExceptionDto } from './generated/client-exception-dto';

export class ClientException {
  type = '';

  constructor(
    public message: string = '',
    public title: string = ''
  ) {}

  static fromDto(dto: ClientExceptionDto): ClientException {
    const clientException = new ClientException();
    return Object.assign(clientException, dto);
  }
}
