import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JahrgangOeffnenComponent } from './jahrgang-oeffnen.component';

@NgModule({
  declarations: [JahrgangOeffnenComponent],
  imports: [CommonModule, TableModule, ButtonModule],
  exports: [JahrgangOeffnenComponent],
})
export class JahrgangOeffnenModule {}
