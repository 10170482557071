<ng-template fzTemplate="sidebar">
  <fz-apply-kp></fz-apply-kp>
  <fz-he-anlage-typ></fz-he-anlage-typ>
  <fz-he-sav-visible *ngIf="formular.zeugnis.zeugnissatz.schuljahr < 2023"></fz-he-sav-visible>
</ng-template>
<fz-formular
  name="HE234hjKP"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  <fz-he-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-he-zeugniskopf>
  <fz-he-seitenkopf fzFormularItem="seitenkopf" fzContainer [hidePage]="true"></fz-he-seitenkopf>
  <fz-section
    *ngIf="model.sozialLernArbeitsverhalten != null"
    fzFormularItem="arbeitsSozialverhaltenAnlage"
    [label]="model.sozialLernArbeitsverhalten.label"
    headerClass="Ueberschriften"
  >
    <fz-deco [border]="formular.showBorder ? borderDirection.all : borderDirection.none">
      <fz-kp
        [model]="model.sozialLernArbeitsverhalten.kp"
        [bereich]="formular.sozialLernArbeitsverhalten.gesamt"
      ></fz-kp>
    </fz-deco>
  </fz-section>
  <fz-panel fzFormularItem="deutsch" fzContainer class="Ueberschriften" [marginBottom]="0">
    <fz-label>{{ formular.deutsch.gesamt.description.label }}</fz-label>
  </fz-panel>
  <fz-deco
    *ngFor="let bereich of model.deutschBereiche; last as isLast; trackBy: trackByProperty"
    [fzFormularItem]="bereich.key"
    [marginBottom]="isLast ? 4 : -borderWidth"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
  >
    <fz-section
      [label]="bereich.label"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingTop="normal"
      headerPaddingBottom="none"
    >
      <fz-sequence>
        <fz-deco paddingTop="normal" paddingBottom="minus-border">
          <fz-kp [model]="bereich.kp" [bereich]="getDeutschBereich(bereich.key)"></fz-kp>
        </fz-deco>
        <fz-optional [field]="bereich.verbalVisibleField" label="Verbale Beurteilung">
          <fz-deco
            *ngIf="bereich.textField != null"
            [padding]="formular.showBorder ? 'normal' : 'none'"
            paddingTop="normal"
            paddingBottom="normal"
          >
            <fz-verbal
              [field]="bereich.textField"
              [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
            ></fz-verbal>
          </fz-deco>
        </fz-optional>
      </fz-sequence>
    </fz-section>
  </fz-deco>
  <fz-he-unterschrift-optional
    fzFormularItem="unterschrift"
    fzContainer
    [model]="model.unterschrift"
  ></fz-he-unterschrift-optional>
</fz-formular>
