export class UserLayoutFormularProperties {
  pageMarginTopM: number | null = null;
  pageMarginBottomM: number | null = null;
  followingPageMarginTopM: number | null = null;
  followingPageMarginBottomM: number | null = null;

  static toDto(properties: UserLayoutFormularProperties): unknown {
    return { ...properties };
  }

  static fromDto(dto: any): UserLayoutFormularProperties {
    return Object.assign(new UserLayoutFormularProperties(), dto);
  }
}
