<div *ngIf="formular.zeugnis.schuelerTyp === 'Geistigbehindert'">
  <div *ngIf="formular.zeugnis.zeugnissatz.halbjahr === 0">
    <p-radioButton name="formularTyp" [value]="'Kein'" [(ngModel)]="formularTyp" label="Kein"></p-radioButton>
  </div>
  <div>
    <p-radioButton name="formularTyp" [value]="'Verbal'" [(ngModel)]="formularTyp" label="Verbal"></p-radioButton>
  </div>
  <div>
    <p-radioButton name="formularTyp" [value]="'Regel'" [(ngModel)]="formularTyp" label="Regel"></p-radioButton>
  </div>
  <div *ngIf="formular.zeugnis.zeugnissatz.halbjahr === 0" class="text-xs">
    Zu den Halbjahreszeugnisse erhalten G-Schüler bevorzugt kein Zeugnis
  </div>
</div>
