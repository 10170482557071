import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, lastValueFrom } from 'rxjs';

import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { environment } from 'src/environments/environment';
import AvailableLicense from '../../../models/generated/available-license';
import { Benutzer } from '../../../models/generated/benutzer';
import { JahrgangScopeDto } from '../../../models/generated/jahrgang-scope-dto';
import { LizenzScope } from '../../../models/generated/lizenz-scope';
import LoginCredentials from '../../../models/generated/login-credentials';
import RegisterCredentials from '../../../models/generated/register-credentials';
import { Helper } from '../../../models/helper';
import { Schullizenz } from '../../../models/schullizenz';
import { SystemActionsService } from '../../components/system-actions/system-actions.service';
import { NotificationService } from '../notification/notification.service';
import { WorkspaceService } from '../workspace.service';
import { LoginResponse } from './login-response';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';

@Injectable({
  providedIn: 'root',
})
export class FlinkyAuthService {
  loginStateChanged: Subject<boolean> = new Subject<boolean>();
  benutzerDeleted: EventEmitter<Benutzer> = new EventEmitter<Benutzer>();
  _lizenz: Schullizenz | undefined;
  lizenzen: Schullizenz[] = [];
  lizenzChanged: Subject<boolean> = new Subject<boolean>();
  intervallId: any;
  isRefreshing: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private injector: Injector,
    private systemActionsService: SystemActionsService,
    private notificationService: NotificationService
  ) {
    this.handleAutoLogoutFromElectron();
    this.activateSilentRefresh();
  }
  handleAutoLogoutFromElectron() {
    if (this.systemActionsService.isElectron) {
      (window as any).electronAPI.onLogout(async () => {
        try {
          if (this.isLoggedIn()) await this.logout();
        } catch {
          /* */
        }
        console.log('handleAutoLogoutFromElectron() - window.close()');
        window.close();
      });
    }
  }

  async login(credentials: LoginCredentials, hasDisclaimerConfirmed: boolean) {
    return lastValueFrom(
      this.http.post<LoginResponse>('/api/Auth/signin', credentials, {
        params: new HttpParams().append('hasDisclaimerConfirmed', hasDisclaimerConfirmed),
      })
    );
  }

  async checkLogin() {
    const user = this.getUser();
    if (user != null) this.loginStateChanged.next(true);
  }

  async setLoginData(response: LoginResponse) {
    let result = false;
    if (response != null) {
      window.sessionStorage.removeItem(USER_KEY);
      window.sessionStorage.setItem(USER_KEY, JSON.stringify(response.benutzer));
      this.saveToken(response.jwtToken);
      this.saveRefreshToken(response.refreshToken);
      result = true;
    }
    await this.fetchLizenz(); // hier können wir shconmal die letzte genutzte laden
    this.loginStateChanged.next(result);
  }

  async logout(silent: boolean = false) {
    if (!silent) {
      await this.injector.get(WorkspaceService).close();
      await lastValueFrom(this.http.post('/api/onclose/logout', {}));
    }
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.removeItem(TOKEN_KEY);
    this.lizenz = undefined;
    this.loginStateChanged.next(false);
    this.router.navigate(['/login-form']);
  }

  registerConnectionId(connectionId: string) {
    lastValueFrom(
      this.http.get('/api/auth/registerConnectionId', { params: new HttpParams().append('connectionId', connectionId) })
    );
  }

  set lizenz(value: Schullizenz | undefined) {
    this._lizenz = value;
    this.lizenzChanged.next(true);
  }

  get lizenz() {
    return this._lizenz;
  }

  register(registerCredentials: RegisterCredentials) {
    return lastValueFrom(this.http.post<Benutzer>('/api/Auth/register', registerCredentials));
  }

  changeRegisterEmail(registerCredentials: RegisterCredentials) {
    return lastValueFrom(this.http.post<Benutzer>('/api/Auth/changeRegisterEmail', registerCredentials));
  }

  getAvailableUsers() {
    return lastValueFrom(this.http.get<Benutzer[]>('/api/Auth/availableUsers'));
  }

  getAvailableLicenses() {
    return this.http.get<AvailableLicense[]>('/api/Auth/availableLicenses');
  }

  public getToken() {
    return window.sessionStorage.getItem(TOKEN_KEY) ?? '';
  }

  public isLoggedIn() {
    return (
      window.sessionStorage.getItem(TOKEN_KEY) != null ||
      (environment.fallbackToken != null && environment.fallbackToken !== '')
    );
  }

  public getUser(): Benutzer | null {
    const userString = window.sessionStorage.getItem(USER_KEY);
    if (userString != null) {
      return JSON.parse(userString);
    } else {
      return null;
    }
  }

  public setUser(benutzer: Benutzer): void {
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(benutzer));
  }

  updateCurrentLizenz(kundennummer: string | undefined) {
    const user = this.getUser();
    if (user != null && kundennummer != null) {
      user.kundennummer = kundennummer;
      this.setUser(user);
      this.fetchLizenz();
    }
  }

  public updateToken(token: string): void {
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  private async fetchLizenz() {
    const user = this.getUser();
    if (user?.kundennummer != null && user?.kundennummer !== '') {
      if (this.lizenz?.kundennummer !== user.kundennummer) {
        this.lizenz = Schullizenz.fromScope(
          await lastValueFrom(
            this.http.get<LizenzScope>('/api/lizenz/getLizenz', {
              params: new HttpParams().append('kundennummer', user.kundennummer),
            })
          )
        )[0];
        if (this.lizenz != null)
          this.lizenz.zeugniskopflizenzen.sort(
            (a, b) => Helper.getSchuljahrHalbjahr(b) - Helper.getSchuljahrHalbjahr(a)
          );
      }
    }
  }

  async loadLizenzenIfRequired(scope: JahrgangScopeDto) {
    for (const j of scope.jahrgaenge) {
      if (this.lizenzen.find((l) => l.kundennummer === j.kundennummer) == null && j.kundennummer) {
        this.lizenzen.push(
          Schullizenz.fromScope(
            await lastValueFrom(
              this.http.get<LizenzScope>('/api/lizenz/getLizenz', {
                params: new HttpParams().append('kundennummer', j.kundennummer),
              })
            )
          )[0]
        );
      }
    }
  }

  async loadLizenz(kundennummer: string) {
    if (this.lizenzen.find((l) => l.kundennummer === kundennummer) == null) {
      this.lizenzen.push(
        Schullizenz.fromScope(
          await lastValueFrom(
            this.http.get<LizenzScope>('/api/lizenz/getLizenz', {
              params: new HttpParams().append('kundennummer', kundennummer),
            })
          )
        )[0]
      );
    }
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
  }
  public getRefreshToken(): string | null {
    return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
  }

  refreshToken() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
    };
    const url = '/api/Auth/refresh-token';
    return this.http.post<any>(
      url,
      {
        refreshToken: this.getRefreshToken(),
        token: this.getToken(),
      },
      httpOptions
    );
  }

  activateSilentRefresh() {
    if (this.intervallId) clearInterval(this.intervallId);
    this.intervallId = setInterval(async () => {
      const token = this.getToken();
      if (token && token !== 'undefined') {
        if (this.isTokenExpiring(token) && !this.isRefreshing) {
          console.log('Token is expiring, refreshing token');
          this.isRefreshing = true;
          try {
            const res = await lastValueFrom(this.refreshToken());
            this.saveToken(res.jwtToken);
          } catch (error) {
            if (error instanceof HttpErrorResponse && error.status !== 0) {
              this.logout(true);
              this.notificationService.showPermanentInfo(
                'Ihre Sitzung ist abgelaufen oder ungültig. Bitte melden Sie sich erneut an.',
                'Sitzung abgelaufen'
              );
            }
          } finally {
            this.isRefreshing = false;
          }
        }
      }
    }, 1000);
  }

  isTokenExpiring(token: string): boolean {
    if (token && token !== 'undefined') {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const exp = payload.exp;
      return Date.now() >= exp * 1000 + 10000;
    }
    return true;
  }
}
