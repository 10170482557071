<fz-panel *ngIf="model != null">
  <div style="display: grid; grid-template-columns: auto 1fr; column-gap: 4px">
    <fz-panel style="grid-column: 1" [border]="borderDirection.all">
      <fz-checkbox [field]="model.erwuenschtField"></fz-checkbox>
    </fz-panel>
    <fz-label style="grid-column: 2; align-self: center"
      >Ein Gespräch ist erwünscht. Nehmen Sie bitte Verbindung mit der Klassenlehrerin/dem Klassenlehrer auf.</fz-label
    >
  </div>
</fz-panel>
