import { NotificationService } from './../../services/notification/notification.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { AppInfoService } from '../../services';
import AvailableLicense from '../../../models/generated/available-license';
import RegisterCredentials from '../../../models/generated/register-credentials';
import { matchingValidator } from '../../validators/must-match-validator';
import FlinkyVersionInfo from '../../../models/generated/flinky-version-info';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as signalR from '@microsoft/signalr';
import PasswordRecoveryRegistrationState from '../../../models/generated/password-recovery-registration-state';
import { PasswordrecoveryService } from '../passwordrecovery/passwordrecovery.service';
import { Benutzer } from '../../../models/generated/benutzer';
import {
  passwordHelp,
  emailRegex,
  passwordRegexString,
  passwordKomplexRegex,
  passwordMinLength,
  activateText,
} from '../../../consts';
import { environment } from 'src/environments/environment';
import { DisclaimerComponent } from '../passwordrecovery/disclaimer/disclaimer.component';
import { SystemActionsService } from '../system-actions/system-actions.service';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
})
export class RegisterFormComponent implements AfterViewInit {
  @ViewChild(DisclaimerComponent) disclaimer: DisclaimerComponent | undefined;
  environment = environment;
  availableLicences: AvailableLicense[] = [];
  registerform: UntypedFormGroup;
  changeform: UntypedFormGroup;
  selectedLicense?: AvailableLicense;
  activeState: boolean[] = [true, false];
  passwordHelpList = passwordHelp;
  info?: FlinkyVersionInfo;
  emailRegex = emailRegex;
  state = 'initial';
  connection: signalR.HubConnection | null = null;
  recoveryState: string = '';
  recoverStateSverity: 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined = undefined;
  passwordRegexString = passwordRegexString;
  timeToRedirect: number = 6;
  infoDialogVisible = false;
  userId: string = '';
  activateText = activateText;

  isLoading = false;

  constructor(
    private flinkyAuth: FlinkyAuthService,
    private router: Router,
    public appInfo: AppInfoService,
    private notificationService: NotificationService,
    private http: HttpClient,
    private passwordRecoveryService: PasswordrecoveryService,
    private route: ActivatedRoute,
    public systemActionsService: SystemActionsService
  ) {
    this.registerform = new UntypedFormGroup(
      {
        login: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(passwordKomplexRegex),
          Validators.minLength(passwordMinLength),
        ]),
        passwordComparison: new UntypedFormControl(''),
        accept: new UntypedFormControl('', Validators.requiredTrue),
      },
      { validators: matchingValidator('password', 'passwordComparison') }
    );
    this.changeform = new UntypedFormGroup({
      login: new UntypedFormControl('', Validators.required),
      accept: new UntypedFormControl('', Validators.requiredTrue),
    });
  }

  async ngAfterViewInit() {
    this.info = await lastValueFrom(this.http.get<FlinkyVersionInfo>('/api/Info'));
    if (history.state.state) {
      this.state = history.state.state;
      this.userId = history.state.userId;
      const baseUrl = document.getElementsByTagName('base')[0].href;

      const url = (baseUrl + '/signalr_passwordrecovery').replace(/([^:]\/)\/+/g, '$1');
      this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();

      this.connection.start().then(async () => {
        if (this.connection != null) {
          this.state = 'waitForActivation';
          this.initRecoveryState(this.userId);

          this.connection.on('RecoveryRequestCompleted', (_message: string, result: boolean) => {
            if (result) {
              this.state = 'success';
              this.timeToRedirect = 6;
              this.redirect();
            } else {
              this.state = 'error';
            }
          });
        }
      });
    }
  }

  async register() {
    this.isLoading = true;
    try {
      if ((await this.disclaimer?.show()) ?? false) {
        const baseUrl = document.getElementsByTagName('base')[0].href;

        const url = (baseUrl + '/signalr_passwordrecovery').replace(/([^:]\/)\/+/g, '$1');
        this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();

        this.connection.start().then(async () => {
          const registerCredentials: RegisterCredentials = {
            username: this.registerform.value.login,
            password: this.registerform.value.password,
            kundennummer: this.selectedLicense?.kundennummer as string,
            connectionId: this.connection?.connectionId ?? '',
          };

          const res = await this.flinkyAuth.register(registerCredentials);
          if (res && this.connection != null) {
            this.state = 'waitForActivation';
            this.initRecoveryState(res.id);
            this.userId = res.id;

            this.connection.on('RecoveryRequestCompleted', (_message: string, result: boolean) => {
              if (result) {
                this.state = 'success';
                this.timeToRedirect = 6;
                this.redirect();
              } else {
                this.state = 'error';
              }
            });
          } else {
            this.notificationService.showPermanentError(
              'Bitte versuchen Sie es erneut. Sollte der Fehler weiterhin bestehen, wenden Sie sich bitte an den Flinky Support.',
              'Die Registrierung war nicht erfolgreich!'
            );
          }
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  async initRecoveryState(id: string) {
    const user = await this.passwordRecoveryService.getRecoveryInfoRegister(id);
    this.registerform.patchValue({ login: user.benutzername });
    this.processUserInfo(user);
  }

  async processUserInfo(user: Benutzer) {
    switch (user.passwordRecoveryRegistrationState) {
      case PasswordRecoveryRegistrationState.declinedByUser:
        this.recoveryState = 'Passwortwiederherstellung nicht gewünscht';
        this.recoverStateSverity = 'info';
        break;
      case PasswordRecoveryRegistrationState.finished:
        this.recoveryState = 'Passwortwiederherstellung verfügbar';
        this.recoverStateSverity = 'success';

        this.state = 'success';
        this.timeToRedirect = 6;
        this.redirect();
        break;
      case PasswordRecoveryRegistrationState.signaturePending:
        this.recoveryState = 'E-Mail-Adresse noch nicht mit geändertem Passwort verbunden';
        this.recoverStateSverity = 'warning';
        // TODO: was macht man denn da überhaupt?
        break;
      case PasswordRecoveryRegistrationState.validationPending:
        this.recoveryState = 'Aktivierungslink noch nicht aufgerufen';
        this.recoverStateSverity = 'warning';
        await this.passwordRecoveryService.waitForActivationRegister(
          user.id,
          user.benutzername,
          this.connection?.connectionId
        );
        break;
      case PasswordRecoveryRegistrationState.notConfigured:
      case PasswordRecoveryRegistrationState.neverLoggedIn:
        this.recoveryState = 'Noch nicht konfiguriert';
        this.recoverStateSverity = 'danger';
        break;
      default:
        this.recoveryState = '';
        this.recoverStateSverity = undefined;
    }
  }

  redirect() {
    if (this.timeToRedirect > 0) {
      this.timeToRedirect--;
      setTimeout(() => {
        this.redirect();
      }, 1000);
    } else {
      this.router.navigate(['/login-form'], { state: { email: this.registerform.get('login')?.value } });
    }
  }

  async resendActivation() {
    this.isLoading = true;
    try {
      const pass = this.registerform.get('password')?.value
        ? this.registerform.get('password')?.value
        : history.state.password;
      const user = await this.passwordRecoveryService.registerRecovery(
        this.userId,
        this.registerform.get('login')?.value,
        pass,
        this.connection?.connectionId ?? ''
      );
      if (user != null) this.processUserInfo(user);
    } finally {
      this.isLoading = false;
    }
  }

  changeEmail() {
    this.state = 'changeEmail';
    this.changeform.patchValue({ login: this.registerform.get('login')?.value });
  }

  async doChangeEmail() {
    // request an Server schicken
    const pass = this.registerform.get('password')?.value
      ? this.registerform.get('password')?.value
      : history.state.password;

    const baseUrl = document.getElementsByTagName('base')[0].href;

    const url = (baseUrl + '/signalr_passwordrecovery').replace(/([^:]\/)\/+/g, '$1');
    this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();

    this.connection.start().then(async () => {
      const registerCredentials: RegisterCredentials = {
        username: this.changeform.value.login,
        password: pass,
        kundennummer: this.selectedLicense?.kundennummer as string,
        connectionId: this.connection?.connectionId ?? '',
        userId: this.userId,
      };

      const res = await this.flinkyAuth.changeRegisterEmail(registerCredentials);
      if (res && this.connection != null) {
        this.state = 'waitForActivation';
        this.initRecoveryState(res.id);
        this.userId = res.id;

        this.connection.on('RecoveryRequestCompleted', (_message: string, result: boolean) => {
          if (result) {
            this.state = 'success';
            this.timeToRedirect = 6;
            this.redirect();
          } else {
            this.state = 'error';
          }
        });
      } else {
        this.notificationService.showPermanentError(
          'Bitte versuchen Sie es erneut. Sollte der Fehler weiterhin bestehen, wenden Sie sich bitte an den Flinky Support.',
          'Die Registrierung war nicht erfolgreich!'
        );
      }
    });
  }
}
