import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NIPanelDecoratorBase } from './ni-panel-decorator-base';

@Component({
  selector: 'fz-ni-seitenfuss',
  templateUrl: 'ni-seitenfuss.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, NISeitenfussComponent)],
})
export class NISeitenfussComponent extends NIPanelDecoratorBase {
  @Input() pageIndex = 0;
}
