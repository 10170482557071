import { Component, AfterViewInit } from '@angular/core';
import { AppInfoService } from '../../shared/services/app-info.service';

@Component({
  selector: 'fz-jahrgang-wiederfuellen-page',
  templateUrl: './jahrgang-wiederfuellen-page.component.html',
  styleUrls: ['./jahrgang-wiederfuellen-page.component.scss'],
})
export class JahrgangWiederfuellenPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Jahrgang neu erstellen'));
  }
}
