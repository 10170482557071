import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-schueler',
  templateUrl: 'he-schueler.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, HESchuelerComponent)],
})
export class HESchuelerComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      normal:
        formular.zeugnis.zeugnisTyp !== 'Abgang'
          ? {
              vornameField: formular.schuelerVorname,
              nameField: formular.schuelerName,
              geschlechtField: formular.schueler.geschlecht,
            }
          : undefined,
      abgang:
        formular.zeugnis.zeugnisTyp === 'Abgang'
          ? {
              vornameField: formular.schuelerVorname,
              nameField: formular.schuelerName,
              geschlechtField: formular.schueler.geschlecht,
              geburtsdatumField: formular.schueler.geburtsdatum,
              geburtsortField: formular.schueler.geburtsort,
              normalerUnterricht:
                formular.zeugnis.schuelerTyp !== 'Intensiv'
                  ? {
                      jahrgangsstufeString: formular.klassenstufeLabel,
                      teilgenommenField: formular.teilgenommen,
                    }
                  : undefined,
              intensivklasse:
                formular.zeugnis.schuelerTyp === 'Intensiv'
                  ? {
                      typField: formular.intensivklasseTypGenitiv,
                    }
                  : undefined,
            }
          : undefined,
    };
  }

  @Input() model: ReturnType<typeof HESchuelerComponent.createModel> | undefined;
  get nomen(): string {
    if (this.formular.zeugnis.schueler.geschlecht === 'm') return 'Der Schüler';
    if (this.formular.zeugnis.schueler.geschlecht === 'w') return 'Die Schülerin';
    return 'Der Schüler/Die Schülerin';
  }
}
