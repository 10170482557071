import { TextElement } from '@modules/pdl/text-element';
import _ from 'lodash-es';
import { PointTarget } from './text-input.directive';

export class TextViewAccessor {
  #textElements: TextElement[] = [];

  constructor(textElements: TextElement[]) {
    this.#textElements = textElements;
  }

  get textElements(): TextElement[] {
    return this.#textElements;
  }

  findTextElement(node: EventTarget | null): TextElement | null {
    if (node == null) return null;
    if (!(node instanceof Node)) return null;
    if (TextElement.is(node)) return node;
    else return this.findTextElement(node.parentNode);
  }

  getIndexByPoint(pointTarget: PointTarget): number | null {
    const textElement = this.findTextElement(pointTarget.target);
    if (textElement != null) {
      let index = textElement.getNearestIndexWrtClient(pointTarget.clientX);
      if (index > textElement.textRange.length) index = textElement.textRange.length;
      return textElement.textRange.start + index;
    } else return null;
  }

  getPrevTextElement(textElement: TextElement): TextElement | null {
    const index = this.#textElements.indexOf(textElement);
    if (index > 0) {
      return this.#textElements[index - 1];
    }
    return null;
  }

  getNextTextElement(textElement: TextElement): TextElement | null {
    const index = this.#textElements.indexOf(textElement);
    if (index >= 0 && index < this.#textElements.length - 1) {
      return this.#textElements[index + 1];
    }
    return null;
  }

  getTextElementByIndex(position: number): TextElement | undefined {
    return _.find(
      this.#textElements,
      (te) => te.textRange.start <= position && position <= te.textRange.start + te.textRange.length
    );
  }

  getTextElementsBetween(start: number, end: number) {
    return this.#textElements.filter(
      (te) => te.textRange.start < end && start < te.textRange.start + te.textRange.length
    );
  }

  updateSelection(anchor: number, focus: number, scrollIntoView?: boolean | null): void {
    const selection = document.getSelection();
    if (selection != null) {
      if (anchor === focus) {
        const textElement = this.getTextElementByIndex(focus);
        if (textElement != null) {
          const { node, offset } = textElement.getNodeAndOffsetByIndex(focus - textElement.textRange.start) ?? {};
          if (node != null && offset != null) {
            selection.setBaseAndExtent(document.body, 0, document.body, 0); // es kommt vor, dass das Caret nicht mehr an der Stelle blinkt, und trotzdem setBaseAndExtend keine Wirkung hat
            selection.setBaseAndExtent(node, offset, node, offset); // Firefox hat Problem mit setPosition, wenn auf anderer Page
            // selection.setPosition(node, offset);
            if (scrollIntoView != null && !this.isScrolledIntoView(textElement))
              textElement.scrollIntoView(scrollIntoView);
          }
        }
      } else {
        // selection.addRange wird nicht korrekt unterstützt von Chrome und Firefox

        // const start = Math.min(anchor, focus);
        // const end = Math.max(anchor, focus);
        // const stringElements = this.getStringElementsBetween(start, end);
        // const ranges: Range[] = [];
        // for (const stringElement of stringElements) {
        //   const stringElementStart = Math.max(start, stringElement.textRange.start);
        //   const stringElementEnd = Math.min(end, stringElement.textRange.start + stringElement.textRange.length);
        //   const range = document.createRange();
        //   const { node: startNode, offset: startOffset } =
        //     stringElement.getNodeAndOffsetByIndex(stringElementStart - stringElement.textRange.start) ?? {};
        //   const { node: endNode, offset: endOffset } =
        //     stringElement.getNodeAndOffsetByIndex(stringElementEnd - stringElement.textRange.start) ?? {};
        //   if (startNode != null && startOffset != null && endNode != null && endOffset != null) {
        //     range.setStart(startNode, startOffset);
        //     range.setEnd(endNode, endOffset);
        //     ranges.push(range);
        //   }
        // }
        // selection.removeAllRanges();
        // for (const range of ranges) selection.addRange(range);

        // const stringElementFocus = this.getStringElementByIndex(focus);
        // if (stringElementFocus != null && scrollIntoView != null && !this.isScrolledIntoView(stringElementFocus))
        //   stringElementFocus.scrollIntoView(scrollIntoView);

        const textElementAnchor = this.getTextElementByIndex(anchor);
        const textElementFocus = this.getTextElementByIndex(focus);
        if (textElementAnchor != null && textElementFocus != null) {
          const { node: anchorNode, offset: anchorOffset } =
            textElementAnchor.getNodeAndOffsetByIndex(anchor - textElementAnchor.textRange.start) ?? {};
          const { node: focusNode, offset: focusOffset } =
            textElementFocus.getNodeAndOffsetByIndex(focus - textElementFocus.textRange.start) ?? {};
          if (anchorNode != null && anchorOffset != null && focusNode != null && focusOffset != null) {
            selection.setBaseAndExtent(document.body, 0, document.body, 0); // es kommt vor, dass das Caret nicht mehr an der Stelle blinkt, und trotzdem setBaseAndExtend keine Wirkung hat
            selection.setBaseAndExtent(anchorNode, anchorOffset, focusNode, focusOffset);
            if (scrollIntoView != null && !this.isScrolledIntoView(textElementFocus))
              textElementFocus.scrollIntoView(scrollIntoView);
          }
        }
      }
    }
  }

  private isScrolledIntoView(el: Element) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }
}
