<fz-panel *ngIf="model != null">
  <div class="Ueberschriften2">
    <fz-label>Erreichtes Niveau entsprechend dem Gemeinsamen europäischen Referenzrahmen für Sprachen(GeR):</fz-label>
  </div>
  <div
    style="margin-top: 8px; display: grid; grid-template-columns: 1fr 1fr; grid-auto-rows: min-content; row-gap: 8px"
  >
    <div class="Ueberschriften2">
      <fz-label>Fertigkeitsbereiche</fz-label>
    </div>
    <div class="Ueberschriften2" style="grid-column: 2">
      <fz-label>Erreichtes Niveau in Deutsch</fz-label>
    </div>
    <div class="Noten_Beschriftung">
      <fz-label>{{ model.zuhoeren.label }}</fz-label>
    </div>
    <div class="Noten_Text">
      <fz-panel style="grid-column: 2; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
        <fz-note [field]="model.zuhoeren.noteField"></fz-note>
      </fz-panel>
    </div>
    <div class="Noten_Beschriftung">
      <fz-label>{{ model.lesen.label }}</fz-label>
    </div>
    <div class="Noten_Text">
      <fz-panel style="grid-column: 2; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
        <fz-note [field]="model.lesen.noteField"></fz-note>
      </fz-panel>
    </div>
    <div class="Noten_Beschriftung">
      <fz-label>{{ model.sprechen.label }}</fz-label>
    </div>
    <div class="Noten_Text">
      <fz-panel style="grid-column: 2; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
        <fz-note [field]="model.sprechen.noteField"></fz-note>
      </fz-panel>
    </div>
    <div class="Noten_Beschriftung">
      <fz-label>{{ model.texteVerfassen.label }}</fz-label>
    </div>
    <div class="Noten_Text">
      <fz-panel style="grid-column: 2; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
        <fz-note [field]="model.texteVerfassen.noteField"></fz-note>
      </fz-panel>
    </div>
  </div>
</fz-panel>
