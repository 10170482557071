<fz-cross
  #cross
  style="width: 8px; height: 8px"
  [style.visibility]="selectedValue === value ? 'visible' : 'hidden'"
></fz-cross>
<ng-template #content="fzPortal" fzPortal>
  <fz-translate [fzRect]="rect" (click)="onClick($event)">
    <fz-cross *ngIf="selectedValue === value" [fzRect]="crossRect"></fz-cross>
    <fz-string
      [style.top.px]="crossRect.top - 1"
      [style.left.px]="crossRect.left"
      [shadow]="formularService.isReadonly ? '' : shadow"
    ></fz-string>
  </fz-translate>
</ng-template>
<ng-template #backDeco="fzPortal" fzPortal>
  <div [fzRect]="rect"></div>
</ng-template>
