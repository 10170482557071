<form [formGroup]="jahrgangform" (ngSubmit)="onSubmit()" class="flex-grow h-0 overflow-auto">
  <p-panel class="fz-panel-header-padding" header="Schuljahr und zu erstellendes Zeugnisformular auswählen">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12">
        <label for="schuljahr">vorherige Zeugnisse:</label>
        {{ zsVm?.zeugnisTypLabel }} {{ zsVm?.klassenstufeLabel }} Schuljar {{ zsVm?.schuljahrLabel }},
        Klassenbezeichnung: {{ prevZeugnissatz?.klassenbezeichnung }}
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label for="zeugnis">Zeugnisformular</label>
        <p-dropdown
          fzAutofocus
          placeholder="Zeugnisformular wählen"
          [options]="zeugnisse"
          optionLabel="text"
          formControlName="halbjahrDescription"
          (onChange)="zeugnisseChanged()"
          scrollHeight="500px"
        ></p-dropdown>
      </div>
      <div class="p-field p-col-12 p-md-6"></div>
      <div class="p-field p-col-12 p-md-6">
        <label for="klassenleitung">Klassenleitung</label>
        <input id="klassenleitung" type="text" pInputText formControlName="klassenleitung" />
        <div
          *ngIf="jahrgangform.get('klassenleitung')?.invalid && jahrgangform.get('klassenleitung')?.dirty"
          class="fz-validation-error"
        >
          <div *ngIf="jahrgangform.get('klassenleitung')?.errors?.['required']">KlassenLeitung wird benötigt</div>
        </div>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label for="schulleitung">Schulleitung</label>
        <input id="schulleitung" type="text" pInputText formControlName="schulleitung" />
        <div
          *ngIf="jahrgangform.get('schulleitung')?.invalid && jahrgangform.get('schulleitung')?.dirty"
          class="fz-validation-error"
        >
          <div *ngIf="jahrgangform.get('schulleitung')?.errors?.['required']">Schulleitung wird benötigt</div>
        </div>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label for="klassenbezeichnung">Klassenbezeichnung</label>
        <fz-help-info [key]="'jahrgangKlassenbezeichnung'"></fz-help-info>
        <input id="klassenbezeichnung" type="text" pInputText formControlName="klassenbezeichnung" />
        <div
          *ngIf="jahrgangform.get('klassenbezeichnung')?.invalid && jahrgangform.get('klassenbezeichnung')?.dirty"
          class="fz-validation-error"
        >
          <div *ngIf="jahrgangform.get('klassenbezeichnung')?.errors?.['required']">
            Klassenbezeichnung wird benötigt
          </div>
        </div>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label for="rufname">Rufname der Klasse</label>
        <fz-help-info [key]="'jahrgangRufname'"></fz-help-info>

        <input id="rufname" type="text" pInputText formControlName="rufname" />
      </div>

      <div class="p-field p-col-12 p-md-12" *ngIf="isSL">
        <p-pickList
          [source]="availableSchuelers"
          [target]="selectedSchuelers"
          sourceHeader="Nicht weiterführen"
          targetHeader="Weiterführen"
          [dragdrop]="!systemActionService.touchDevice"
          [responsive]="true"
          [sourceStyle]="{ height: '15rem' }"
          [targetStyle]="{ height: '15rem' }"
          [showSourceControls]="false"
          [showTargetControls]="false"
        >
          <ng-template let-schueler pTemplate="item">
            <div class="flex gap-5">
              <div class="flex-grow">
                <i class="pi pi-id-card"></i>&nbsp;&nbsp;{{ schueler.vorname }} {{ schueler.name }}
              </div>
              <div class="flex-grow-0">{{ schueler.geburtsdatum }}</div>
              <div class="flex-grow-0">{{ schueler.geschlecht ? schueler.geschlecht : ' ' }}</div>
            </div>
          </ng-template>
        </p-pickList>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="fz-button-group p-2">
        <button
          pButton
          [loading]="isLoading"
          label="Anlegen"
          class="w-64"
          type="submit"
          [disabled]="!jahrgangform.valid || isLoading"
        ></button>
      </div>
    </ng-template>
  </p-panel>
</form>
