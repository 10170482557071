import { Directive, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Projectable, ProjectableOwner } from '@modules/projectables/projectable';

@Directive()
export abstract class ContainerBaseDirective implements OnInit, OnDestroy, ProjectableOwner {
  protected prevHeight = 0;
  protected mutationObserver: MutationObserver | undefined;

  constructor(private zone: NgZone) {
    this.zone.runOutsideAngular(() => {
      this.mutationObserver = new MutationObserver((records) => {
        const filteredRecords = records.filter(
          (record) =>
            !(record.type === 'attributes' && record.attributeName?.startsWith('ng-')) &&
            record.target.nodeType !== Node.COMMENT_NODE &&
            !(
              record.type === 'attributes' &&
              record.attributeName === 'class' &&
              (record.oldValue ?? '') === '' &&
              (record.target as HTMLElement).className === 'ng-star-inserted'
            ) &&
            !(
              record.type === 'attributes' &&
              record.attributeName === 'style' &&
              (record.oldValue ?? '') === (record.target as HTMLElement).style.cssText
            )
        );

        if (filteredRecords.length > 0) {
          // eslint-disable-next-line no-console
          console.log(`warning: MutationObserver ${this.projectable?.sourceElement?.tagName}`);
          this.projectableContentChange();
        }
      });
    });
  }
  ngOnInit(): void {
    if (this.projectable) {
      this.mutationObserver?.observe(this.projectable.sourceElement, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true,
        attributeOldValue: true,
      });
    }
  }
  ngOnDestroy(): void {
    this.mutationObserver?.disconnect();
  }
  abstract get projectable(): Projectable | undefined;
  get height(): number {
    return this.projectable?.height ?? 0;
  }
  project(): void {
    this.projectable?.project();
    this.prevHeight = this.height;
    this.mutationObserver?.takeRecords();
  }

  abstract projectableContentChange(): void;

  private logProjectable(): void {
    if (this.projectable != null) {
      this.projectable.log();
    }
  }
}
