import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ImportService } from './../import.service';
import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TransferFile } from '../../../../models/generated/transfer-file';
import { ClientException } from '../../../../models/client-exception';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { FlinkyAuthService } from '../../../services/auth/flinky-auth.service';

@Component({
  selector: 'app-import-internet-pin',
  templateUrl: './import-internet-pin.component.html',
  styleUrls: ['./import-internet-pin.component.scss'],
})
export class ImportInternetPinComponent implements AfterViewInit {
  @Input() header: TemplateRef<any> | undefined;
  @Output() pinChecked = new EventEmitter<TransferFile>();
  @Output() restart = new EventEmitter<void>();

  error = '';

  pinError = false;
  importpinform: UntypedFormGroup;
  loading: boolean = false;

  constructor(
    private importService: ImportService,
    public authService: FlinkyAuthService,
    private notificationService: NotificationService
  ) {
    this.importpinform = new UntypedFormGroup({
      pin: new UntypedFormControl('', Validators.required),
      kundennummer: new UntypedFormControl('', Validators.required),
    });
  }

  ngAfterViewInit(): void {
    if (this.authService.lizenz != null)
      this.importpinform.patchValue({ kundennummer: this.authService.lizenz.kundennummer });
  }

  async importInternet() {
    try {
      this.loading = true;
      this.pinError = false;
      const data = await this.importService.importFromInternet(
        this.importpinform.value.pin,
        this.importpinform.value.kundennummer
      );
      this.pinChecked.next(data);
    } catch (ex) {
      if (ex instanceof ClientException) {
        this.pinError = true;
        this.error = ex.message;
      } else throw ex;
    } finally {
      this.loading = false;
    }
  }

  get lizenzImage() {
    return this.authService.lizenz?.findZeugniskopflizenz({
      schuljahr: this.importpinform.value.schuljahr,
      halbjahr: this.importpinform.value.halbjahrDescription?.halbjahr,
    })?.zeugniskopf;
  }

  firstStep(): void {
    this.restart.next();
  }
}
