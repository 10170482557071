import _ from 'lodash-es';

export class StopWatch {
  private static timers: {
    [name: string]: { start: number; sum: number; count: number };
  } = {};

  public static resetAll(): void {
    this.timers = {};
  }

  public static start(name: string): void {
    if (this.timers[name] == null) {
      this.timers[name] = { start: 0, sum: 0, count: 0 };
    }
    const timer = this.timers[name];
    if (timer.start !== 0) {
      throw new Error(`Timer ${name} ist bereits gestartet.`);
    }
    timer.start = performance.now();
  }

  public static stop(name: string): void {
    const timer = this.timers[name];
    if (timer == null || timer.start === 0) {
      throw new Error(`Timer ${name} wurde vorher nicht gestartet.`);
    }
    timer.count++;
    timer.sum += performance.now() - timer.start;
    timer.start = 0;
  }

  public static toString(): string {
    return _.join(
      _.map(
        _.toPairs(this.timers),
        ([name, timer]) => `${name}: ${timer.start === 0 ? `${timer.sum.toFixed(2)}ms (${timer.count}x)` : 'läuft'}`
      ),
      ', '
    );
  }
}
