import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-export-manuell-finished',
  templateUrl: './export-manuell-finished.component.html',
  styleUrls: ['./export-manuell-finished.component.scss'],
})
export class ExportManuellFinishedComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Input() pin = '';
  @Output() restartClicked = new EventEmitter<boolean>();

  constructor(private router: Router) {}
  firstStep(): void {
    this.restartClicked.next(true);
  }
  navigateToZeugnisse(): void {
    this.router.navigate(['/main']);
  }
}
