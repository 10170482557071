import { AfterViewInit, Component } from '@angular/core';

import { AppInfoService } from '../../shared/services';

@Component({
  selector: 'page-login',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Login'));
  }
}
