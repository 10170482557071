<fz-panel
  *ngIf="model != null"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div style="display: grid; grid-template-columns: 1fr 113px 1fr; grid-template-rows: 38px auto">
    <fz-panel
      *ngIf="model.schulleitungField.value !== ''"
      style="grid-row: 1; grid-column: 1"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 1; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.schulleitungField"
      placeholder="Bezeichnung Schulleitung"
    ></fz-text>
    <div
      style="grid-row: 1 span 2; grid-column: 2; display: grid; justify-content: center; align-content: center"
      *ngIf="formular.isBeglaubigt"
    >
      <fz-rp-siegel style="grid-row: 1; grid-column: 1; width: 76px; height: 76px"></fz-rp-siegel>
    </div>
    <fz-panel
      *ngIf="model.klassenleitungField.value !== ''"
      style="grid-row: 1; grid-column: 3"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 3; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.klassenleitungField"
      placeholder="Bezeichnung Klassenleitung"
    ></fz-text>
  </div>
</fz-panel>
