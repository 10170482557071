import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';

@Component({
  selector: 'fz-rp-grundschulziel',
  templateUrl: 'rp-grundschulziel.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPGrundschulzielComponent)],
})
export class RPGrundschulzielComponent extends RPPanelDecoratorBase {
  get pronomen(): string {
    switch (this.formular.zeugnis.schueler.geschlecht) {
      case 'm':
        return 'Er';
      case 'w':
        return 'Sie';
      default:
        return 'Er/Sie';
    }
  }
}
