import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { SLFormular } from './sl-formular';
import { SLFolgeseiteComponent } from '../projectables/sl-folgeseite.component';
import { SLSeitenfussComponent } from '../projectables/sl-seitenfuss.component';
import { SLFormularCategoryKey } from '../sl-formularsatz';
import { FormularViewType } from '../../formular/formular';
import { SLSchuelerComponent } from '../projectables/sl-schueler.component';

export class SL234hjErlaeuterungenFormular extends SLFormular {
  get category(): SLFormularCategoryKey {
    return 'erlaeuterungen';
  }
  get viewType(): FormularViewType {
    return SL234hjErlaeuterungenFormularComponent;
  }
}

@Component({
  selector: 'fz-sl-234hj-erlaeuterungen-formular',
  templateUrl: './sl-234hj-erlaeuterungen-formular.component.html',
})
export class SL234hjErlaeuterungenFormularComponent extends FormularComponentBase<SL234hjErlaeuterungenFormular> {
  static createModel(formular: SL234hjErlaeuterungenFormular) {
    return formular.zeugnis.erlaeuterungenAktiv
      ? {
          schueler: SLSchuelerComponent.createModel(formular),
          erlaeuterungenField: formular.erlaeuterungen,
        }
      : undefined;
  }

  get model() {
    return SL234hjErlaeuterungenFormularComponent.createModel(this.formular);
  }

  slFolgeseiteComponent = SLFolgeseiteComponent;
  slSeitenfussComponent = SLSeitenfussComponent;
  constructor(formularService: FormularService<SL234hjErlaeuterungenFormular>) {
    super(formularService);
  }
}
