import { Directive, Input, NgZone, Type } from '@angular/core';
import { ContainerBaseDirective } from '@modules/blocks/container-base.directive';
import { Projectable, ProjectableOwnerProvider } from '@modules/projectables/projectable';
import { InterfaceProvider, provideInterfaceBy } from '@modules/shared/interface-provider';

export type HeaderFooter = {
  pageIndex: number;
  type: 'header' | 'footer';
  component: Type<unknown>;
};

export interface HeaderFooterOwner {
  invalidateHeaderFooter(): void;
}

export class HeaderFooterOwnerProvider implements InterfaceProvider<HeaderFooterOwner> {
  constructor(public provided: HeaderFooterOwner) {}
}

@Directive({
  selector: '[fzHeaderFooter]',
  providers: [provideInterfaceBy(ProjectableOwnerProvider, HeaderFooterDirective)],
})
export class HeaderFooterDirective extends ContainerBaseDirective {
  @Input() headerFooter?: HeaderFooter;
  get projectable(): Projectable | undefined {
    return this._projectable;
  }
  @Input()
  set projectable(value: Projectable | undefined) {
    this._projectable = value;
  }

  private _projectable: Projectable | undefined;

  constructor(
    zone: NgZone,
    private ownerProvider: HeaderFooterOwnerProvider
  ) {
    super(zone);
  }

  projectableContentChange(): void {
    if (this.height !== this.prevHeight) {
      this.project();
      this.ownerProvider.provided.invalidateHeaderFooter();
    } else {
      this.project();
    }
  }
}
