<fz-panel *ngIf="model != null">
  <fz-select
    placeholder="Auswahl: Unterricht des bilingualen Zuges"
    emptyLabel="(Bleibt leer - es wird nichts gedruckt)"
    [emptyValue]="'Leer'"
    class="Allgemeiner_Zeugnis_Text"
    [field]="model.sprachenportfolioField"
    [suppressNull]="true"
  >
    <fz-panel fzSelectCandidate [fzValue]="'FranzEng'" fzLabel="Integrierte Fremdsprachenarbeit">
      <fz-label>Die Lern- und Leistungsentwicklung in der Integrierten Fremdsprachenarbeit in </fz-label>
      <fz-linked-dropdown
        [field]="model.sprachenportfolioSpracheField"
        [linkedField]="formular.zeugnissatz.sprachenportfolioSprache"
        fieldName="sprachenportfolioSprache"
      ></fz-linked-dropdown>
      <fz-label> ist im Sprachenportfolio dokumentiert.</fz-label>
    </fz-panel>
    <fz-panel fzSelectCandidate [fzValue]="'Bilingual'" fzLabel="Bilingualer Unterricht">
      <fz-label>Die Lern- und Leistungsentwicklung auf der sprachlichen Ebene im bilingualen Unterricht </fz-label>
      <fz-linked-dropdown
        [field]="model.sprachenportfolioSpracheField"
        [linkedField]="formular.zeugnissatz.sprachenportfolioSprache"
        fieldName="sprachenportfolioSprache"
      ></fz-linked-dropdown>
      <fz-label> ist im Sprachenportfolio dokumentiert.</fz-label>
    </fz-panel>
  </fz-select>
</fz-panel>
