<div class="flex flex-col gap-2 pt-5">
  <div class="flex flex-ro gap-2 items-center">
    Zu importierende Klasse:
    <p-dropdown [options]="klassen" [(ngModel)]="selectedKlasse" (onChange)="klasseChange()"></p-dropdown>
  </div>
  <p-table
    #grid
    [value]="filteredSchuelers"
    sortMode="single"
    sortField="name"
    [scrollable]="true"
    scrollHeight="300px"
    [(selection)]="selectedSchuelers"
    styleClass="p-datatable-sm"
    responsiveLayout="scroll"
  >
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" style="width: 1rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th scope="col" pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
        <th scope="col" pSortableColumn="vorname">Vorname <p-sortIcon field="vorname"></p-sortIcon></th>
        <th scope="col" pSortableColumn="geschlecht">Geschlecht <p-sortIcon field="geschlecht"></p-sortIcon></th>
        <th scope="col" pSortableColumn="geburtsdatum">Geburtsdatum <p-sortIcon field="geburtsdatum"></p-sortIcon></th>
        <th scope="col" pSortableColumn="geburtsort">Geburtsort <p-sortIcon field="geburtsort"></p-sortIcon></th>
        <th scope="col" pSortableColumn="geburtsort">Klasse <p-sortIcon field="klasse"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-schueler let-rowIndex="rowIndex">
      <tr>
        <td>
          <p-tableCheckbox [value]="schueler"></p-tableCheckbox>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="schueler.name" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ schueler.name }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="schueler.vorname" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ schueler.vorname }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="schueler.geschlecht" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ schueler.geschlecht }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn [ngClass]="{ 'fz-gebdat-invalid': !isValidDate(schueler.geburtsdatum) }">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="schueler.geburtsdatum" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ schueler.geburtsdatum }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="schueler.geburtsort" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ schueler.geburtsort }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="schueler.klasse" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ schueler.klasse }}
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="fz-button-group pt-2">
    <button pButton (click)="back.emit()" class="w-40" icon="pi pi-caret-left" label="Zurück"></button>
    <button
      pButton
      [disabled]="selectedSchuelers.length === 0"
      (click)="next.emit(selectedSchuelers)"
      class="w-40"
      icon="pi pi-caret-right"
      label="Weiter"
    ></button>
  </div>
</div>
