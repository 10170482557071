<header class="flex justify-between items-center w-full">
  <div class="flex items-center gap-3">
    <button
      id="appMenu"
      type="button"
      pButton
      icon="pi pi-bars"
      (click)="tieredMenu.toggle($event)"
      [label]="labelMenu"
      class="bg-green-600"
    ></button>
    <img
      alt="FlinkyZeugnisEvo-Logo"
      src="assets/Flinky-Logo.svg"
      class="w-8 h-8 cursor-pointer"
      (click)="showInfoDialog()"
    />
    <div class="hidden lg:block text-lg">{{ appInfo.title }}</div>
  </div>
  <div *ngIf="appInfo.currentPageTitleTemplate == null" class="text-base">{{ appInfo.currentPageTitle }}</div>
  <ng-container *ngIf="appInfo.currentPageTitleTemplate != null">
    <ng-container *ngTemplateOutlet="appInfo.currentPageTitleTemplate"></ng-container>
  </ng-container>
  <div id="userMenu" class="cursor-pointer flex items-center gap-4" (click)="tieredUserMenu.toggle($event)">
    <div class="hidden md:block">{{ benutzername }}</div>
    <p-avatar icon="pi pi-user" shape="circle" class="mr-1 lg:mr-3 cursor-pointer"></p-avatar>
  </div>
</header>
<p-messages
  [value]="notificationService.headerMessages"
  [enableService]="false"
  styleClass="p-0"
  class="fz-messages-small"
></p-messages>
<p-tieredMenu
  #tieredMenu
  styleClass="fz-menu-overlay"
  class="fz-menu-overlay"
  [model]="items"
  menuWidth="200"
  [popup]="true"
></p-tieredMenu>
<p-tieredMenu
  #tieredUserMenu
  styleClass="fz-usermenu-overlay"
  class="fz-usermenu-overlay"
  [model]="userMenuItems"
  menuWidth="200"
  [popup]="true"
></p-tieredMenu>
<fz-info-dialog [infoDialogVisible]="infoDialogVisible" (infoDialogClosed)="infoDialogClosed()"></fz-info-dialog>

<p-dialog
  header="Datensicherung"
  [(visible)]="backupDialogVisible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div class="flex flex-col" *ngIf="backupCreating">
    <p>Die Datensicherung wird erstellt. Bitte haben Sie einen Moment Geduld.</p>
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
  </div>
  <div class="flex flex-col" *ngIf="!backupCreating">
    <p>Die Datensicherung wurde erstellt. Bitte merken oder notieren Sie sich ihr aktuelles Passwort.</p>
    <p>Dieses benötigen Sie gegebenenfalls zur Wiederherstellung dieser Sicherung.</p>
    <p>
      💡 Eine Datensicherung ist nicht für den Austausch von Daten innerhalb des Kollegiums gedacht. Verwenden Sie dazu
      den Export/Import von Zeugnissen im Menü links oben!
    </p>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="backupDialogVisible = false"
      label="Ok"
      [disabled]="backupCreating"
    ></p-button>
  </ng-template>
</p-dialog>
