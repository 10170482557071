<fz-panel *ngIf="model != null">
  <div>
    <fz-label class="Zeugnisausgabe_Ort">{{ formular.zeugnissatz.zeugniskopflizenz.schulort }}, den </fz-label>
    <fz-linked-date
      class="Zeugnisausgabe_Datum"
      fieldName="zeugnisausgabedatum"
      [field]="model.zeugnisausgabedatumField"
      [linkedField]="formular.zeugnissatz.zeugnisausgabedatum"
    ></fz-linked-date>
  </div>
  <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-template-rows: 38px auto 38px; column-gap: 38px">
    <fz-panel style="grid-row: 1; grid-column: 1" [border]="borderDirection.bottom"></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 1; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.klassenleitungField"
      placeholder="Bezeichnung Klassenleitung"
    ></fz-text>
    <fz-panel
      *ngIf="model.paedagogischerMitarbeiterField.value ?? '' !== ''"
      style="grid-row: 1; grid-column: 2"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 2; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.paedagogischerMitarbeiterField"
      placeholder="Bezeichnung Pädagogischer Mitarbeiter"
    ></fz-text>
    <fz-panel style="grid-row: 1; grid-column: 3" [border]="borderDirection.bottom"></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 3; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.schulleitungField"
      placeholder="Bezeichnung Schulleitung"
    ></fz-text>
    <fz-panel style="grid-row: 3; grid-column: 2" [border]="borderDirection.bottom"></fz-panel>
    <fz-label style="grid-row: 3; grid-column: 1; align-self: end" class="Unterschriften_Beschriftung"
      >Gesehen (Erziehungsberechtigte(r))</fz-label
    >
  </div>
</fz-panel>
