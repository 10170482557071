import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';

@Component({
  selector: 'fz-bw-zeugniskopf',
  templateUrl: 'bw-zeugniskopf.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, BWZeugniskopfComponent)],
})
export class BWZeugniskopfComponent extends BWPanelDecoratorBase {}
