import { SystemActionsModule } from './shared/components/system-actions/system-actions.module';
import localeDe from '@angular/common/locales/de';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { registerLocaleData, CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { SplitterModule } from 'primeng/splitter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { AuthInterceptor } from './shared/interceptors/auth/auth.interceptor';
import { HttpErrorInterceptor } from './shared/interceptors/http-error-interceptor';
import { NavigateToZeugnisModule } from './shared/components/navigate-to-zeugnis/navigate-to-zeugnis.module';
import { HeaderModule } from './shared/components/header/header.module';
import { AppComponent } from './app.component';
import { LoginFormModule } from './shared/components';
import { AppRoutingModule } from './app-routing.module';

import { RippleModule } from 'primeng/ripple';
import { TagModule } from 'primeng/tag';
import { PanelModule } from 'primeng/panel';
import { BadgeModule } from 'primeng/badge';
import { KeyFilterModule } from 'primeng/keyfilter';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { lastValueFrom } from 'rxjs';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { SignalRNotificationsModule } from './shared/components/signalr-notifications/signalr-notifications.module';
import { TextbausteinModule } from './shared/components/textbaustein/textbaustein.module';
import { SpellCheckerService } from './modules/shared/spell-checker.service';

import { Router } from '@angular/router';

import * as Sentry from '@sentry/angular';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
export function initializeAppTranslate(translate: TranslateService) {
  return (): Promise<any> => {
    translate.setDefaultLang('de');
    return lastValueFrom(translate.use('de'));
  };
}

export function initializeAppFonts() {
  return () => document.fonts.ready;
}

export function initializeSpellChecker(spellChecker: SpellCheckerService) {
  return () => spellChecker.init();
}

registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HeaderModule,
    NavigateToZeugnisModule,
    SystemActionsModule,
    ConfirmDialogModule,
    LoginFormModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    SplitterModule,
    ToastModule,
    RippleModule,
    TagModule,
    PanelModule,
    BadgeModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    KeyFilterModule,
    SignalRNotificationsModule,
    TextbausteinModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ConfirmationService,
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppTranslate,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFonts,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSpellChecker,
      deps: [SpellCheckerService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
