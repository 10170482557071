<div>
  <p-radioButton [value]="null" [(ngModel)]="formular.anlageSavTyp" label="Keine Anlage"></p-radioButton>
</div>
<div>
  <p-radioButton [value]="'KP'" [(ngModel)]="formular.anlageSavTyp" label="Kompetenzbeurteilung"></p-radioButton>
</div>
<div>
  <p-radioButton [value]="'Freitext'" [(ngModel)]="formular.anlageSavTyp" label="Freitext"></p-radioButton>
</div>
@if (formular.anlageSavTyp == 'KP') {
  <button
    class="mt-2"
    [text]="true"
    pButton
    type="button"
    label="Einrichtung des Kompetenzrasters"
    routerLink="/koennensprofil-editor"
  ></button>
}
