/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

import BereichKey from './bereich-key';

class HEBereichKey extends BereichKey {
  public static readonly sprechen: string = 'sprechen';
  public static readonly zuhoeren: string = 'zuhoeren';
}
export default HEBereichKey;
