import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZeugnissatzDruckenComponent } from './zeugnissatz-drucken.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FormularModule } from '@modules/formular/formular.module';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { AngularResizeEventModule } from '../../angular-resize/angular-resize-event.module';
import { BadgeModule } from 'primeng/badge';
import { SafePipe } from 'safe-pipe';

@NgModule({
  declarations: [ZeugnissatzDruckenComponent],
  imports: [
    CommonModule,
    PanelModule,
    ButtonModule,
    TableModule,
    CheckboxModule,
    FormsModule,
    TriStateCheckboxModule,
    FormularModule,
    DialogModule,
    ProgressBarModule,
    DropdownModule,
    InputTextModule,
    AngularResizeEventModule,
    BadgeModule,
    SafePipe,
  ],
  exports: [ZeugnissatzDruckenComponent],
})
export class ZeugnissatzDruckenModule {}
