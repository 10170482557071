<fz-panel
  *ngIf="model != null"
  name="Schueler"
  class="Versaeumnisse_Beschriftung"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div
    style="
      display: grid;
      grid-template-columns: auto auto auto auto auto auto 1fr;
      grid-template-rows: auto auto;
      column-gap: 8px;
    "
  >
    <fz-label style="grid-row: 1; grid-column: 1">Versäumnisse im Schuljahr:</fz-label>

    <fz-label style="grid-row: 1; grid-column: 2; justify-self: end">entschuldigt:</fz-label>
    <fz-number
      style="grid-row: 1; grid-column: 3; justify-self: end"
      class="Versaeumnisse_Text"
      [field]="model.fehltageEntschuldigtField"
    ></fz-number>
    <fz-label style="grid-row: 1; grid-column: 4">{{
      model.fehltageEntschuldigtField.value === 1 ? 'Tag' : 'Tage'
    }}</fz-label>
    <fz-number
      style="grid-row: 1; grid-column: 5; justify-self: end"
      class="Versaeumnisse_Text"
      [field]="model.fehlstundenEntschuldigtField"
    ></fz-number>
    <fz-label style="grid-row: 1; grid-column: 6">{{
      model.fehlstundenEntschuldigtField.value === 1 ? 'Stunde' : 'Stunden'
    }}</fz-label>

    <fz-label style="grid-row: 2; grid-column: 2; justify-self: end">unentschuldigt:</fz-label>
    <fz-number
      style="grid-row: 2; grid-column: 3; justify-self: end"
      class="Versaeumnisse_Text"
      [field]="model.fehltageUnentschuldigtField"
    ></fz-number>
    <fz-label style="grid-row: 2; grid-column: 4">{{
      model.fehltageUnentschuldigtField.value === 1 ? 'Tag' : 'Tage'
    }}</fz-label>
    <fz-number
      style="grid-row: 2; grid-column: 5; justify-self: end"
      class="Versaeumnisse_Text"
      [field]="model.fehlstundenUnentschuldigtField"
    ></fz-number>
    <fz-label style="grid-row: 2; grid-column: 6">{{
      model.fehlstundenUnentschuldigtField.value === 1 ? 'Stunde' : 'Stunden'
    }}</fz-label>
  </div>
</fz-panel>
