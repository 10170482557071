import { Injectable } from '@angular/core';

import { WorkspaceService } from '../../../shared/services';
import { FlinkyAuthService } from '../../../shared/services/auth/flinky-auth.service';
import { NotificationService } from '../../services/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class WorkspacePageCanDeactivate {
  constructor(
    private workspace: WorkspaceService,
    private flinkyAuth: FlinkyAuthService,
    private notificationService: NotificationService
  ) {}

  async canDeactivate(): Promise<boolean> {
    if (this.flinkyAuth.getUser()) {
      if (this.workspace.isDirty) {
        try {
          await this.workspace.saveSelectedZeugnissatz();
          return true;
        } catch (_ex) {
          this.notificationService.showPermanentWarning(
            'Ihre Änderungen konnten nicht gespeichert werden. Bitte prüfen Sie zunächst Ihre Internetverbindung.',
            'Achtung'
          );
          return false;
        }
      } else return true;
    } else {
      return true;
    }
  }
}
