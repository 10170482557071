import { DateField } from '../../../models/fields/date-field';
import { TextField } from '../../../models/fields/text-field';
import { ValueField } from '../../../models/fields/value-field';
import { Jahrgang } from '../../../models/jahrgang';
import { RPZeugnis } from '../../../models/rp-zeugnis';
import { Schueler } from '../../../models/schueler';
import { RPFormular } from './rp-formular';
import { RPFormularCategoryKey } from '../rp-formularsatz';

export abstract class RPEmpfehlungFormular extends RPFormular {
  anschriftSchule = new TextField<Jahrgang>(
    {
      label: 'Anschrift Schule',
      labelShort: 'AnschSchul',
      property: 'anschriftSchule',
      keyPrefix: 'zeugnissatz.jahrgang',
    },
    { object: this.zeugnis.zeugnissatz.jahrgang }
  );
  anschriftEltern = new TextField<Schueler>(
    {
      label: 'Anschrift Eltern',
      labelShort: 'AnschElt',
      property: 'anschriftEltern',
      keyPrefix: 'schueler',
    },
    { object: this.zeugnis.schueler }
  );
  empfehlungRealschule = new ValueField<RPZeugnis, boolean | null>(
    {
      label: 'Empfehlung Realschule',
      labelShort: 'Real',
      property: 'empfehlungRealschule',
      displayType: 'Table',
      required: false,
      ignoreReset: true,
    },
    { object: this.zeugnis }
  );
  empfehlungGymnasium = new ValueField<RPZeugnis, boolean | null>(
    {
      label: 'Empfehlung Gymnasium',
      labelShort: 'Gym',
      property: 'empfehlungGymnasium',
      displayType: 'Table',
      required: false,
      ignoreReset: true,
    },
    { object: this.zeugnis }
  );
  empfehlungausgabedatum = new DateField<Jahrgang>(
    {
      label: 'Ausgabedatum Empfehlung',
      labelShort: 'AusgDatEmpf',
      property: 'empfehlungausgabedatum',
      keyPrefix: 'zeugnissatz.jahrgang',
    },
    { object: this.zeugnis.zeugnissatz.jahrgang }
  );

  get category(): RPFormularCategoryKey {
    return 'empfehlung';
  }
  get zeugnisTypLabel(): string {
    return 'Empfehlung';
  }
}
