<fz-panel>
  <div style="display: grid; grid-template-columns: auto 1fr">
    <div style="grid-column: 1" *ngIf="formular.category === 'zeugnis'">
      <fz-label
        >Zeugnis für {{ formular.zeugnis.schuelerVorname }} {{ formular.zeugnis.schuelerName }} vom
        {{ formular.zeugnis.zeugnisausgabedatum }}</fz-label
      >
    </div>
    <div style="grid-column: 1" *ngIf="formular.category === 'lernstand'">
      <fz-label
        >Lernstands- und Kompetenzbeschreibung für {{ formular.zeugnis.schuelerVorname }}
        {{ formular.zeugnis.schuelerName }}</fz-label
      >
    </div>
    <div style="grid-column: 2; text-align: right">
      <fz-label>Seite {{ pageIndex + 1 }}</fz-label>
    </div>
  </div>
</fz-panel>
