<p-dialog
  [(visible)]="infoDialogVisible"
  [modal]="true"
  [style]="{ width: '90vw', height: '90vh' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  (onHide)="onHide()"
>
  <ng-template pTemplate="header"
    ><div class="text-xl">Nutzungsbedingungen, Datenschutzerklärung und Impressum</div></ng-template
  >
  <div class="flex flex-col gap-5">
    <p-panel header="Nutzungsbedingungen" [toggleable]="true" [collapsed]="false">
      <div style="white-space: pre-wrap">{{ nutzungsbedingungen }}</div>
    </p-panel>
    <p-panel header="Datenschutzerklärung" [toggleable]="true" [collapsed]="false">
      <div style="white-space: pre-wrap">{{ datenschutz }}</div>
    </p-panel>
    <p-panel header="Impressum" [toggleable]="true" [collapsed]="true">
      <div style="white-space: pre-wrap">{{ impressum }}</div>
    </p-panel>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-grow">
        <div class="flex justify-items-center gap-3 mt-5">
          <p-checkbox
            [(ngModel)]="nutzungsbedingungenGelesen"
            [binary]="true"
            inputId="nutzungsbedingungen"
          ></p-checkbox>
          <label for="nutzungsbedingungen">Ich habe die Nutzungsbedingungen gelesen und verstanden</label>
        </div>
      </div>
      <div class="self-end">
        <button
          pButton
          icon="pi pi-times"
          (click)="infoDialogVisible = false"
          label="Abbrechen"
          class="p-button-secondary"
        ></button>
        <button pButton [disabled]="!nutzungsbedingungenGelesen" icon="pi pi-check" (click)="ok()" label="Ok"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
