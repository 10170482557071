import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { SchuelerGridComponent } from '..';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';

@NgModule({
  imports: [CommonModule, RouterModule, TableModule, ButtonModule, TieredMenuModule, AvatarModule, BadgeModule],
  declarations: [SchuelerGridComponent],
  exports: [SchuelerGridComponent],
})
export class SchuelerGridModule {}
