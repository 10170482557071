import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormularFactory } from '@modules/formular/formular-factory';
import { FormularViewerComponent } from '@modules/formular/formular-viewer.component';
import { BookletPageOrder } from '@modules/shared/booklet-page-order';
import download from 'downloadjs';
import jsPDF, { Matrix } from 'jspdf';
import JSZip from 'jszip';
import { SelectItemGroup, SortEvent } from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { Table } from 'primeng/table';
import { TriStateCheckboxChangeEvent } from 'primeng/tristatecheckbox';
import { FormularSummary } from '../../../modules/formular/formular-summary';
import { JSPdfHelper } from '../../../modules/formular/js-pdf-helper';
import { WorkspaceService } from '../../services';
import { SystemActionsService } from './../system-actions/system-actions.service';
import { Zeugnis } from '../../../models/zeugnis';
import { Subscription } from 'rxjs';
import { Font, FontService } from '../../services/font.service';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface PrintElement {
  zeugnis: Zeugnis;
  categoryKey: string;
  summary: FormularSummary | undefined;
}

export interface PrintCategory {
  key: string;
  label: string;
  indx: number;
}

export type PagesPerSheet = '1' | '2' | 'broschuere';

export type PageSizeOption = {
  key: string;
  targetFormat: 'A3' | 'A4' | 'A5';
  orientation: 'p' | 'l';
  pageHeight: number;
  pageWidth: number;
  pagesPerSheet: PagesPerSheet;
};
export const lochrand = 5;

@Component({
  selector: 'fz-zeugnissatz-drucken',
  templateUrl: './zeugnissatz-drucken.component.html',
  styleUrls: ['./zeugnissatz-drucken.component.scss'],
})
export class ZeugnissatzDruckenComponent implements OnInit, OnDestroy {
  @ViewChild(FormularViewerComponent) formularViewer?: FormularViewerComponent;
  @ViewChild('dt') table?: Table;
  categories: PrintCategory[] = [];
  printElements: PrintElement[] = [];

  printProgress = 0;

  catVals: (string[] | null)[] = [];
  catAll: any[] = [];
  dialogVisible = false;
  isDuplex = false;

  tableHeight: string = '250px';

  pageSizeOptions: SelectItemGroup[]; // Option<PageSizeOption>[];
  selectedPageSize: PageSizeOption;
  scaleDownFactor = 0.7071;
  scaleUpFactor = 1.4142;

  deckblattText = 'Schulbericht';
  hasDeckblatt = false;
  isBW: boolean | null = null;
  isBWA4: boolean | undefined;
  hasLochrand = false;
  ignoreErrors = false;
  individualPdfs = false;
  subscriptions: Subscription[] = [];
  pdfIframe: HTMLIFrameElement | null = null;
  pdfAsDataUri: string | null = null;

  private readonly A4PageSizeOption: PageSizeOption = {
    key: 'A4',
    targetFormat: 'A4',
    orientation: 'p',
    pageWidth: 210,
    pageHeight: 297,
    pagesPerSheet: '1',
  };
  private readonly A3_2PageSizeOption: PageSizeOption = {
    key: 'A3_2',
    targetFormat: 'A3',
    orientation: 'l',
    pageWidth: 420,
    pageHeight: 297,
    pagesPerSheet: '2',
  };
  private readonly A3_BPageSizeOption: PageSizeOption = {
    key: 'A3_B',
    targetFormat: 'A3',
    orientation: 'l',
    pageWidth: 420,
    pageHeight: 297,
    pagesPerSheet: 'broschuere',
  };

  private readonly A5PageSizeOption: PageSizeOption = {
    key: 'A5',
    targetFormat: 'A5',
    orientation: 'p',
    pageWidth: 148,
    pageHeight: 210,
    pagesPerSheet: '1',
  };
  private readonly A4_2PageSizeOption: PageSizeOption = {
    key: 'A4_2',
    targetFormat: 'A4',
    orientation: 'l',
    pageWidth: 297,
    pageHeight: 210,
    pagesPerSheet: '2',
  };
  private readonly A4_BPageSizeOption: PageSizeOption = {
    key: 'A4_B',
    targetFormat: 'A4',
    orientation: 'l',
    pageWidth: 297,
    pageHeight: 210,
    pagesPerSheet: 'broschuere',
  };

  constructor(
    public workspaceService: WorkspaceService,
    public systemActionsService: SystemActionsService,
    private fontService: FontService,
    private deviceService: DeviceDetectorService,
    private elRef: ElementRef
  ) {
    this.pageSizeOptions = [
      {
        label: 'A4 - Grundformat',
        items: [
          {
            label: '1 A4-Seite -> A4 Hochformat',
            value: this.A4PageSizeOption,
          },
          {
            label: '2 A4-Seiten -> A3 Querformat',
            value: this.A3_2PageSizeOption,
          },
          {
            label: '4 A4-Seiten -> Broschüre (A3 Querformat, duplex)',
            value: this.A3_BPageSizeOption,
          },
        ],
      },
      {
        label: 'A5 - Grundformat',
        items: [
          {
            label: '1 A5-Seite -> A5 Hochformat',
            value: this.A5PageSizeOption,
          },
          {
            label: '2 A5-Seiten -> A4 Querformat',
            value: this.A4_2PageSizeOption,
          },
          {
            label: '4 A5-Seiten -> Broschüre (A4 Querformat, duplex)',
            value: this.A4_BPageSizeOption,
          },
        ],
      },
    ];
    this.selectedPageSize = this.A4PageSizeOption;
  }

  async ngOnInit() {
    this.subscriptions.push(this.workspaceService.dataLoadedBehaviour.subscribe(() => this.initOptions()));

    this.setTableHeight();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    if (this.pdfIframe) {
      this.elRef.nativeElement.removeChild(this.pdfIframe);
    }
    if (this.pdfAsDataUri) {
      URL.revokeObjectURL(this.pdfAsDataUri);
    }
  }

  setTableHeight() {
    const elems = document.getElementsByClassName('p-panel-content');

    const newHeight = `${elems[0].clientHeight - 40}px`;
    if (newHeight !== this.tableHeight) {
      this.tableHeight = newHeight;
    }
  }

  onResized(_event: any) {
    this.setTableHeight();
  }
  initOptions(): void {
    this.categories = [];
    let i = 1;
    this.printElements = [];
    this.workspaceService.selectedZeugnisse.forEach((zeugnis) => {
      if (zeugnis.schueler.isAktiv) {
        const formularSatz = FormularFactory.getFormularsatz(zeugnis);
        const categories = formularSatz.getCategories(zeugnis);

        this.isBW = formularSatz.bundesland === 'BW';
        this.isBWA4 = this.workspaceService.currentZeugnissatz?.zeugniskopflizenz.isBWA4;
        if (this.isBW && this.isBWA4 === false) {
          this.selectedPageSize = this.A5PageSizeOption;
        }

        categories.forEach((c) =>
          this.printElements.push({
            zeugnis,
            categoryKey: c.key,
            summary: FormularFactory.createFormular(zeugnis, c.key).summary,
          })
        );
        const printCategories: PrintCategory[] = categories.map((c) => ({ key: c.key, label: c.label, indx: 0 }));
        printCategories.forEach((pc) => {
          if (this.categories.find((c) => c.key === pc.key) == null) {
            pc.indx = i;
            this.categories.push(pc);
            i++;
          }
        });
      }
    });
  }

  hasSummary(zeugnis: Zeugnis, categoryKey: string): boolean {
    return (
      this.printElements.find((pe) => pe.categoryKey === categoryKey && pe.zeugnis.id === zeugnis.id)?.summary != null
    );
  }

  getSummary(zeugnis: Zeugnis, categoryKey: string) {
    return this.printElements.find((pe) => pe.categoryKey === categoryKey && pe.zeugnis.id === zeugnis.id)?.summary;
  }

  getSummaryText(zeugnis: Zeugnis, categoryKey: string): string {
    const summary = this.getSummary(zeugnis, categoryKey);
    if (summary == null) return '';
    const text =
      summary.errorCount > 0
        ? summary.errorCount?.toString()
        : summary.warningCount > 0
          ? summary.warningCount.toString()
          : ' ';

    return text;
  }

  getSummarySeverity(zeugnis: Zeugnis, categoryKey: string) {
    const summary = this.getSummary(zeugnis, categoryKey);
    if (summary == null) return null;

    return summary.errorCount > 0 ? 'danger' : summary.warningCount > 0 ? 'warning' : 'success';
  }

  hasCategory(zeugnis: Zeugnis, categoryKey: string): boolean {
    return this.printElements.filter((pe) => pe.categoryKey === categoryKey && pe.zeugnis.id === zeugnis.id).length > 0;
  }

  alleChanged(indx: number, categoryKey: string, event: TriStateCheckboxChangeEvent): void {
    if (event.value === true) {
      this.catVals[indx] = null;
      this.printElements
        .filter((pe) => pe.categoryKey === categoryKey)
        .forEach((pe) => {
          if (this.hasSummary(pe.zeugnis, categoryKey)) {
            if (this.catVals[indx]) {
              this.catVals[indx]?.push(pe.zeugnis.id.toString());
            } else {
              this.catVals[indx] = [pe.zeugnis.id.toString()];
            }
          }
        });
    }
    if (event.value == null || event.value === false) {
      this.catVals[indx] = null;
      this.catAll[indx] = null;
    }
  }

  isSomethingSelected(): boolean {
    let result = false;

    this.catVals.forEach((c) => {
      if (c && c.length > 0) {
        result = true;
      }
    });
    return result;
  }

  setTriState(indx: number, _categoryKey: string, _event: CheckboxChangeEvent): void {
    if (this.catVals[indx] == null || this.catVals[indx]?.length === 0) {
      this.catAll[indx] = null;
    } else if (
      this.catVals[indx] != null &&
      this.catVals[indx]?.length === this.workspaceService.selectedZeugnisse.length
    ) {
      this.catAll[indx] = true;
    } else {
      this.catAll[indx] = false;
    }
  }

  private getPrintItems() {
    const items = this.catVals
      .map((v, index) => {
        return {
          category: this.categories[index - 1],
          categoryIndex: index - 1,
          zeugnisse: (v ?? []).map(
            (id: string) =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.printElements.find(
                (e) => e.categoryKey === this.categories[index - 1].key && e.zeugnis.id.toString() === id
              )!.zeugnis
          ),
        };
      })
      .filter((_cz, index) => index != null)
      .reduce(
        (prev: { category: PrintCategory; categoryIndex: number; zeugnis: Zeugnis }[], curr) => [
          ...prev,
          ...curr.zeugnisse.map((z) => ({
            category: curr.category,
            categoryIndex: curr.categoryIndex,
            zeugnis: z,
          })),
        ],
        []
      );

    items.sort((data1, item2) => {
      if (this.table?.sortField === 'schuelerName') {
        return (
          (String(data1.zeugnis.schuelerName).localeCompare(String(item2.zeugnis.schuelerName), 'de-DE') ||
            String(data1.zeugnis.schuelerVorname).localeCompare(String(item2.zeugnis.schuelerVorname), 'de-DE')) *
            (this.table?.sortOrder ?? 1) || data1.categoryIndex - item2.categoryIndex
        );
      } else {
        return (
          (String(data1.zeugnis.schuelerVorname).localeCompare(String(item2.zeugnis.schuelerVorname), 'de-DE') ||
            String(data1.zeugnis.schuelerName).localeCompare(String(item2.zeugnis.schuelerName), 'de-DE')) *
            (this.table?.sortOrder ?? 1) || data1.categoryIndex - item2.categoryIndex
        );
      }
    });
    return items;
  }

  private addItemToPdf(item: { category: PrintCategory; categoryIndex: number; zeugnis: Zeugnis }, doc: jsPDF) {
    if (this.formularViewer) {
      this.formularViewer.category = item.category.key;
      this.formularViewer.zeugnis = item.zeugnis;
      const pageCount = this.formularViewer.preparePdf();

      switch (this.selectedPageSize.key) {
        case 'A4':
        case 'A5':
          this.printSimple(doc, pageCount);
          break;
        case 'A4_2':
        case 'A3_2':
          this.print2PagesOnOne(doc, pageCount);
          break;
        case 'A4_B':
        case 'A3_B':
          this.printBooklet(doc, pageCount, item.category.key);
          break;
        default:
          throw new Error('Unbekanntes Format');
      }
    }
  }

  private addFontsToPdf(fonts: Font[], doc: jsPDF) {
    for (const font of fonts) {
      const family = font.family.replace(/ /g, '_');
      const filename = `${family}_${font.weight}.tts`;
      doc.addFileToVFS(filename, font.data);
      doc.addFont(filename, family, font.weight);
    }
  }

  private printSimple(doc: jsPDF, pageCount: number) {
    for (let index = 1; index <= pageCount; index++) {
      const odd = index % 2 === 1;
      this.addPage(doc);
      doc.saveGraphicsState();
      let matrix =
        this.selectedPageSize.targetFormat === 'A5'
          ? this.scaleAtOrigin(doc, this.scaleDownFactor)
          : JSPdfHelper.noTransform(doc);
      if (this.hasLochrand) matrix = JSPdfHelper.translate(doc, odd ? lochrand : -lochrand, 0).multiply(matrix);
      doc.setCurrentTransformationMatrix(matrix);
      this.formularViewer?.exportPdfPage(doc, index - 1);
      doc.restoreGraphicsState();
    }
    if (pageCount % 2 === 1 && this.isDuplex) this.addPage(doc);
  }

  private print2PagesOnOne(doc: jsPDF, pageCount: number) {
    let posLeft = 0;
    for (let index = 1; index <= pageCount; index++) {
      const odd = index % 2 === 1;
      if (odd) {
        this.addPage(doc);
        posLeft = 0;
      } else {
        posLeft = this.selectedPageSize.pageWidth / 2;
      }
      doc.saveGraphicsState();
      let matrix =
        this.selectedPageSize.targetFormat === 'A4'
          ? this.scaleAtOrigin(doc, this.scaleDownFactor)
          : JSPdfHelper.noTransform(doc);
      matrix = JSPdfHelper.translate(doc, posLeft, 0).multiply(matrix);
      if (this.hasLochrand) matrix = JSPdfHelper.translate(doc, odd ? -5 : 5, 0).multiply(matrix);
      doc.setCurrentTransformationMatrix(matrix);
      this.formularViewer?.exportPdfPage(doc, index - 1);
      doc.restoreGraphicsState();
    }
  }

  private printBooklet(doc: jsPDF, pageCount: number, category: string) {
    if (this.hasDeckblatt && category === 'zeugnis') pageCount++;
    const bookletInfo = BookletPageOrder.calc(pageCount);
    pageCount += bookletInfo.countOfEmptyPagesToAdd;
    let posLeft = 0;
    for (let i = 1; i <= pageCount; i++) {
      if (i % 2 === 1) {
        this.addPage(doc);
        posLeft = 0 - (this.hasLochrand ? lochrand : 0);
      } else {
        posLeft = this.selectedPageSize.pageWidth / 2 + (this.hasLochrand ? lochrand : 0);
      }
      const currentPageIndex = bookletInfo.duplex[i - 1];
      doc.saveGraphicsState();
      let matrix =
        this.selectedPageSize.targetFormat === 'A4'
          ? this.scaleAtOrigin(doc, this.scaleDownFactor)
          : JSPdfHelper.noTransform(doc);
      matrix = JSPdfHelper.translate(doc, posLeft, 0).multiply(matrix);
      doc.setCurrentTransformationMatrix(matrix);
      this.exportPdfPageOrCover(doc, currentPageIndex, category);
      doc.restoreGraphicsState();
    }
  }

  private exportPdfPageOrCover(doc: jsPDF, i: number, category: string) {
    if (this.hasDeckblatt && category === 'zeugnis') {
      if (i === 1) this.insertCover(doc);
      else this.formularViewer?.exportPdfPage(doc, i - 2);
    } else {
      this.formularViewer?.exportPdfPage(doc, i - 1);
    }
  }

  private insertCover(doc: jsPDF) {
    doc.setFont('Arial', 'normal', 700);
    doc.setFontSize(24);
    doc.text(this.deckblattText, 105, 148.5, { align: 'center' });
  }

  private addPage(doc: jsPDF) {
    doc.addPage(this.selectedPageSize.targetFormat, this.selectedPageSize.orientation);
  }

  scaleAtOrigin(doc: jsPDF, factor: number): Matrix {
    return JSPdfHelper.translate(doc, 0, -this.selectedPageSize.pageHeight)
      .multiply(JSPdfHelper.scale(doc, factor))
      .multiply(JSPdfHelper.translate(doc, 0, this.selectedPageSize.pageHeight));
  }

  timeout(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), ms);
    });
  }

  getPdfTitle(): string {
    const schuljahr: number = this.workspaceService.selectedZeugnissatz?.schuljahr ?? 0;
    const schuljahrPlus1 = schuljahr + 1;
    return `FlinkyZeugnisEvo_Schuljahr_${schuljahr}_${schuljahrPlus1}_Klasse_${this.workspaceService.selectedZeugnissatz?.klassenbezeichnung ?? 0}`;
  }

  async zip() {
    if (!JSZip.support.uint8array) throw new Error('JSZip: uint8array not supported.');
    try {
      this.dialogVisible = true;
      this.printProgress = 0;
      if (this.formularViewer != null) {
        let count = 1;
        const fonts = await this.fontService.getFonts();
        const items = this.getPrintItems();
        const title = this.getPdfTitle();
        const zip = new JSZip();
        for (const item of items) {
          const doc = new jsPDF();
          doc.deletePage(1);
          this.addFontsToPdf(fonts, doc);
          this.addItemToPdf(item, doc);
          this.printProgress = Math.floor((count / items.length) * 100);
          doc.setProperties({ title: title });
          const pdfBytes = new Uint8Array(doc.output('arraybuffer'));
          if (pdfBytes != null) {
            zip.file(
              `Klasse_${item.zeugnis.zeugnissatz.klassenbezeichnung}_${item.zeugnis.schuelerName}_${item.zeugnis.schuelerVorname}_${item.category.label}.pdf`,
              pdfBytes
            );
          }
          count++;
          await this.timeout(10);
        }
        const bytes = await zip.generateAsync({ type: 'uint8array' });
        download(bytes, `${encodeURI(title)}.zip`, 'application/x-zip');
      }
    } finally {
      this.printProgress = 0;
      this.dialogVisible = false;
    }
  }

  async print(startDownload: boolean = false) {
    try {
      this.dialogVisible = true;
      this.printProgress = 0;
      if (this.formularViewer != null) {
        let count = 1;
        const doc = new jsPDF();
        doc.deletePage(1);
        const fonts = await this.fontService.getFonts();
        this.addFontsToPdf(fonts, doc);
        const items = this.getPrintItems();
        for (const item of items) {
          this.addItemToPdf(item, doc);
          this.printProgress = Math.floor((count / items.length) * 100);
          count++;
          await this.timeout(10);
        }
        const title = this.getPdfTitle();
        doc.setProperties({ title: title });
        const pdfBytes = new Uint8Array(doc.output('arraybuffer'));
        if (pdfBytes != null) {
          if (startDownload) {
            download(pdfBytes, `${encodeURI(title)}.pdf`, 'application/pdf');
          } else {
            this.openPdf(pdfBytes, title);
          }
        }
      }
    } finally {
      this.printProgress = 0;
      this.dialogVisible = false;
    }
  }

  openPdf(pdfBytes: Uint8Array, _windowTitle: string = 'FlinkyZeugnisEvo-Dokument') {
    // ist ipad or iphone
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      download(pdfBytes, `${encodeURI(_windowTitle)}.pdf`, 'application/pdf');
    } else {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      if (this.pdfAsDataUri) {
        URL.revokeObjectURL(this.pdfAsDataUri);
        this.pdfAsDataUri = null;
      }
      this.pdfAsDataUri = URL.createObjectURL(blob);

      if (this.pdfIframe) {
        this.pdfIframe.onload = null;
        this.elRef.nativeElement.removeChild(this.pdfIframe);
        this.pdfIframe = null;
      }

      this.pdfIframe = document.createElement('iframe', {});
      this.pdfIframe.onload = (_event) => setTimeout(() => this.pdfIframe?.contentWindow?.print());
      this.pdfIframe.style.width = '0';
      this.pdfIframe.style.height = '0';
      this.pdfIframe.src = this.pdfAsDataUri;
      this.elRef.nativeElement.appendChild(this.pdfIframe);
    }
  }

  hasSummaries() {
    let hasErrors = false;
    this.catVals.forEach((c, index) => {
      c?.forEach((id) => {
        const x = this.printElements.find(
          (pe) =>
            pe.categoryKey === this.categories[index - 1].key &&
            pe.zeugnis.id === id &&
            pe.summary != null &&
            pe.summary?.errorCount + pe.summary?.warningCount > 0
        );
        if (x != null) hasErrors = true;
      });
    });

    return hasErrors;
  }

  hasErrors() {
    if (this.ignoreErrors) return false;
    return this.hasSummaries();
  }

  customSort(event: SortEvent): void {
    event.data?.sort((data1, data2) => {
      if (event.field == null || event.field === 'schuelerName') {
        return (
          (String(data1.schuelerName ?? '').localeCompare(String(data2.schuelerName ?? ''), 'de-DE') ||
            String(data1.schuelerVorname ?? '').localeCompare(String(data2.schuelerVorname ?? ''), 'de-DE')) *
          (event.order ?? 1)
        );
      } else {
        return (
          (String(data1.schuelerVorname ?? '').localeCompare(String(data2.schuelerVorname ?? ''), 'de-DE') ||
            String(data1.schuelerName ?? '').localeCompare(String(data2.schuelerName ?? ''), 'de-DE')) *
          (event.order ?? 1)
        );
      }
    });
  }
}
