import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signpad',
  templateUrl: './signpad.component.html',
  styleUrls: ['./signpad.component.scss'],
})
export class SignpadComponent implements AfterViewInit, OnChanges {
  @ViewChild('canvas', { static: true }) canvas?: ElementRef;
  @Input() currentSignature: any;
  @Input() vorname?: string;
  @Input() name?: string;

  color = '#000000';

  isDeleted = false;

  signaturePad?: SignaturePad;

  resizeCanvas(): void {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    const canvasEl = this.canvas?.nativeElement;
    if (canvasEl != null && canvasEl.offsetHeight !== 0 && canvasEl.offsetWidth !== 0) {
      canvasEl.width = canvasEl.offsetWidth * ratio;
      canvasEl.height = canvasEl.offsetHeight * ratio;
      canvasEl.getContext('2d').scale(ratio, ratio);
      this.signaturePad?.clear();
      this.signaturePad?.fromDataURL(this.currentSignature);
    } else {
      this.signaturePad?.clear();
      this.signaturePad?.fromDataURL(this.currentSignature);
    }
  }

  ngAfterViewInit(): void {
    if (this.canvas) {
      this.signaturePad = new SignaturePad(this.canvas.nativeElement);
      // this.signaturePad.backgroundColor = 'transparent';
      setTimeout(() => this.resizeCanvas(), 0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentSignature']) {
      this.resizeCanvas();
    }
  }

  undo(): void {
    const data = this.signaturePad?.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad?.fromData(data);
      if (!this.isDeleted) this.signaturePad?.fromDataURL(this.currentSignature);
    }
  }

  reset(): void {
    this.signaturePad?.clear();
    this.signaturePad?.fromDataURL(this.currentSignature);
    this.isDeleted = false;
  }

  trash(): void {
    this.signaturePad?.clear();
    this.isDeleted = true;
  }

  colorChanged(event: any): void {
    if (this.signaturePad != null) {
      this.signaturePad.penColor = event.value;
    }
  }
}
