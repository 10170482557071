import { ReportingService } from './../reporting.service';
import { Component, OnInit } from '@angular/core';
import { FormularFactory } from '@modules/formular/formular-factory';
import { WorkspaceService } from '../../../../shared/services';
import { Zeugnis } from '../../../../models/zeugnis';
import { Jahrgang } from '../../../../models/jahrgang';

@Component({
  selector: 'fz-report-notenschnitt',
  templateUrl: './report-notenschnitt.component.html',
  styleUrls: ['./report-notenschnitt.component.scss'],
})
export class ReportNotenschnittComponent implements OnInit {
  options: any;
  data: any;

  constructor(
    private reportingService: ReportingService,
    private schuelerService: WorkspaceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.options = {
      legend: {
        labels: {
          fontColor: '#495057',
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: '#495057',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: '#495057',
            },
          },
        ],
      },
    };

    this.data = {
      labels: ['1.1', '1.2', '2.1', '2.2', '3.1', '3.2', '4.1', '4.2'],
      datasets: this.getDatasets(await this.reportingService.getAlleJahrgaenge()),
    };
  }

  private getDatasets(reportData: Jahrgang[]): any {
    const datasets: any[] = [];
    let index = 0;
    reportData.forEach((j) => {
      const notenschnitte: any[] = [];
      notenschnitte.push(this.getZeugnissatzSchnitt(1, 0, j));
      notenschnitte.push(this.getZeugnissatzSchnitt(1, 1, j));
      notenschnitte.push(this.getZeugnissatzSchnitt(2, 0, j));
      notenschnitte.push(this.getZeugnissatzSchnitt(2, 1, j));
      notenschnitte.push(this.getZeugnissatzSchnitt(3, 0, j));
      notenschnitte.push(this.getZeugnissatzSchnitt(3, 1, j));
      notenschnitte.push(this.getZeugnissatzSchnitt(4, 0, j));
      notenschnitte.push(this.getZeugnissatzSchnitt(4, 1, j));
      index++;
      datasets.push({
        label: j.rufname !== '' ? j.rufname : j.id,
        data: notenschnitte,
        fill: false,
        borderColor: this.reportingService.getColor(index),
      });
    });

    return datasets;
  }
  private getZeugnissatzSchnitt(klassenstufe: number, halbjahr: number, j: Jahrgang): any {
    const zeugnissatz = j.zeugnissaetze.find((zs) => zs.klassenstufe === klassenstufe && zs.halbjahr === halbjahr);
    let notenschnitt = 0;
    if (zeugnissatz) {
      let countNote = 0;
      let summeNoten = 0;
      // alle zeugnisse dieses satzes finden ...
      j.zeugnisse
        .filter((z) => z.zeugnissatz.id === zeugnissatz.id)
        .forEach((z) => {
          summeNoten += this.getNotenschnitt(z);
          countNote++;
        });
      if (countNote > 0) {
        notenschnitt = summeNoten / countNote;
      }
    }

    return notenschnitt;
  }

  private getNotenschnitt(zeugnis: Zeugnis): number {
    let notenschnitt = 0;
    let countNote = 0;
    let summeNoten = 0;

    const zsVM = FormularFactory.getFormularsatz(zeugnis).createFormular(zeugnis, 'zeugnis');
    zsVM.noteFields.forEach((nf) => {
      const noteNumber = nf.value as number;
      if (noteNumber != null) {
        summeNoten += noteNumber;
        countNote++;
      }
    });
    if (countNote > 0) {
      notenschnitt = summeNoten / countNote;
    }
    return notenschnitt;
  }
}
