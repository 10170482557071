<fz-panel *ngIf="model != null">
  <div
    style="
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: auto auto 1fr;
      column-gap: 19px;
      align-items: baseline;
    "
  >
    <div style="grid-row: 1; grid-column: 1; align-self: center">
      <fz-label>für</fz-label>
    </div>
    <div style="grid-row: 1; grid-column: 2">
      <fz-text class="Schuelername" [field]="model.vornameField" placeholder="Vorname"></fz-text>&nbsp;
      <fz-text class="Schuelername" [field]="model.nameField" placeholder="Name"></fz-text>&nbsp;
      <fz-dropdown class="Schuelername" [isShadow]="true" [field]="model.geschlechtField"></fz-dropdown>
    </div>
    <div style="grid-row: 2; grid-column: 1; align-self: center">
      <fz-label>geboren am</fz-label>
    </div>
    <div style="grid-row: 2; grid-column: 2">
      <fz-date class="SchuelerGebDat" [field]="model.geburtsdatumField" placeholder="Geburtsdatum"></fz-date>
    </div>
    <div style="grid-row: 2; grid-column: 3; justify-self: end">
      <fz-label>in</fz-label>&nbsp;
      <fz-text class="SchuelerGebOrt" [field]="model.geburtsortField" placeholder="Name"></fz-text>
    </div>
  </div>
  <div *ngIf="model.abgang != null">
    <fz-label>hat die Schule bis zum </fz-label>
    <fz-text [field]="model.abgang.datumField" placeholder="Datum"></fz-text>
    <fz-label> besucht und wurde aus dem </fz-label>
    <fz-text [field]="model.abgang.schuljahrField" placeholder="Schuljahrgang"></fz-text>
    <fz-label> Schuljahrgang entlassen.</fz-label>
  </div>
</fz-panel>
