<fz-panel #shadow>
  <fz-label shadow="[...]" style="position: absolute; left: -32px"></fz-label>
</fz-panel>
<ng-container *ngIf="model != null">
  <fz-fach-list-item
    fzFachListItem
    #fachComponent
    *ngFor="let fach of model.faecher; trackBy: trackByProperty"
    [model]="fach"
    (delete)="deleteFach(fach.index)"
  >
  </fz-fach-list-item>
</ng-container>

<ng-container
  *ngFor="let block of blocks; last as isLast"
  fzBlock
  [content]="content"
  [backDeco]="backDeco"
  [frontDeco]="frontDeco"
>
  <ng-template #content="fzPortal" fzPortal>
    <fz-translate fzFocusable tabindex="0">
      <fz-translate [style.top.px]="block.rect.bottom">
        <ng-container [fzPortalOutlet]="shadow.content"></ng-container>
      </fz-translate>
      <fz-translate *ngFor="let row of block.rows" [fzPosition]="row.rect">
        <ng-container [fzPortalOutlet]="row.projectable.content"></ng-container>
      </fz-translate>
    </fz-translate>
  </ng-template>
  <ng-template #backDeco="fzPortal" fzPortal>
    <div style="position: absolute">
      <fz-translate [style.top.px]="block.rect.bottom">
        <ng-container [fzPortalOutlet]="shadow.backDeco"></ng-container>
      </fz-translate>
      <div *ngFor="let row of block.rows" style="position: absolute" [fzRect]="row.rect">
        <ng-container [fzPortalOutlet]="row.projectable.backDeco"></ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div style="position: absolute">
      <div
        *ngIf="hasFocus && isLast"
        fzFocusable
        [style.top.px]="block.rect.bottom"
        [style.left.px]="block.rect.left"
        class="floating fz-note"
        tabindex="0"
      >
        <div class="flex gap-2">
          <p-button icon="pi pi-plus" label="Fach&nbsp;hinzufügen" (onClick)="addFach()"></p-button>
          <p-button label="Fächer&nbsp;für&nbsp;alle&nbsp;Schüler&nbsp;übernehmen" (onClick)="copyFaecher()"></p-button>
        </div>
      </div>
      <div *ngFor="let row of block.rows" style="position: absolute" [fzPosition]="row.rect">
        <ng-container [fzPortalOutlet]="row.projectable.frontDeco"></ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
