<img #img [src]="src" [style.width.px]="width" (load)="onLoad()" alt="" />
<ng-template #content="fzPortal" fzPortal>
  <img
    [src]="src"
    style="display: block; position: absolute; max-width: unset"
    [style.left.px]="rect.left"
    [style.top.px]="rect.top"
    [style.width.px]="width"
    alt=""
  />
</ng-template>
