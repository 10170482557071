import { Directive, ElementRef, Optional, SkipSelf } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { PanelDecoratorBase } from '@modules/projectables/panel.component';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { RPFormular } from '../formulare/rp-formular';

@Directive()
export class RPPanelDecoratorBase extends PanelDecoratorBase {
  constructor(
    elementRef: ElementRef<HTMLElement>,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private formularService: FormularService<RPFormular>
  ) {
    super(elementRef, parentProvider);
  }
  get formular(): RPFormular {
    return this.formularService.formular;
  }
}
