import { Provider, Type } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export interface InterfaceProvider<T> {
  provided: T;
}

export const provideInterfaceBy = <T>(providerType: Type<InterfaceProvider<T>>, type: Type<T>): Provider => ({
  provide: providerType,
  useFactory: (component: T) => new providerType(component),
  deps: [type],
});

export class ControlValueAccessorProvider implements InterfaceProvider<ControlValueAccessor> {
  constructor(public provided: ControlValueAccessor) {}
}
