import _ from 'lodash-es';
import { BundeslandTyp } from './enums/bundesland-typ';
import { JahrgangContainer } from './generated/jahrgang-container';
import { JahrgangScopeDto } from './generated/jahrgang-scope-dto';
import { Schullizenz } from './schullizenz';
import { Helper } from './helper';
import { Metadata } from './metadata';
import { Schueler } from './schueler';
import { Zeugnis } from './zeugnis';
import { Zeugnissatz } from './zeugnissatz';

export class Jahrgang extends Metadata {
  static currentVersion = 0;
  readonly zeugnissaetze: Zeugnissatz[] = [];
  readonly schuelers: Schueler[] = [];

  schulleitung: string | null = null;
  klassenleitung: string | null = null;
  rufname: string | null = null;
  schulort: string | null = null;
  zeugniskopfZeile1: string | null = null;
  zeugniskopfZeile2: string | null = null;
  anschriftSchule: string | null = null;
  empfehlungausgabedatum: string | null = null;

  get kundennummer(): string {
    return this.schullizenz.kundennummer;
  }

  get bundesland(): BundeslandTyp {
    return this.schullizenz.bundesland;
  }

  get zeugnisse(): Zeugnis[] {
    return this.zeugnissaetze.reduce((prev: Zeugnis[], curr: Zeugnissatz) => [...prev, ...curr.zeugnisse], []);
  }

  get currentZeugnissatz(): Zeugnissatz | undefined {
    return _.orderBy(this.zeugnissaetze, [(zs) => zs.schuljahr, (zs) => zs.halbjahr], ['desc', 'desc'])[0];
  }

  get currentZeugnissatzSchuljahrHalbjahr(): number | undefined {
    return this.currentZeugnissatz?.schuljahrHalbjahr;
  }

  get zeugnissaetzeOrdered(): Zeugnissatz[] {
    return _.orderBy(this.zeugnissaetze, [(zs) => zs.schuljahr, (zs) => zs.halbjahr], ['desc', 'desc']);
  }

  get currentKlassenbezeichnung() {
    return this.currentZeugnissatz?.klassenbezeichnung;
  }

  get currentSchuljahrHalbjahr() {
    return `${this.currentZeugnissatz?.schuljahr}/${(this.currentZeugnissatz?.schuljahr ?? -1) + 1} ${
      (this.currentZeugnissatz?.halbjahr ?? -1) + 1
    }. Hj`;
  }

  get currentKlassenleitung() {
    return this.currentZeugnissatz?.klassenleitung;
  }

  constructor(
    public readonly id: string,
    public readonly guid: string,
    public schullizenz: Schullizenz,
    public version: number = Jahrgang.currentVersion
  ) {
    super();
  }

  toScope(): JahrgangScopeDto {
    return {
      jahrgaenge: [Jahrgang.toDto(this)],
      schuelers: [],
      zeugnissaetze: [],
      zeugnisse: [],
    };
  }

  toScopeIncludeSchuelers(schuelers?: Schueler[]): JahrgangScopeDto {
    return {
      jahrgaenge: [Jahrgang.toDto(this)],
      schuelers: this.schuelers
        .filter((s) => schuelers == null || schuelers.map((s2) => s2.id).includes(s.id))
        .map((s) => Schueler.toDto(s)),
      zeugnissaetze: [],
      zeugnisse: [],
    };
  }

  toScopeIncludeZeugnisse(): JahrgangScopeDto {
    return {
      jahrgaenge: [Jahrgang.toDto(this)],
      schuelers: this.schuelers.map((s) => Schueler.toDto(s)),
      zeugnissaetze: this.zeugnissaetze.map((zs) => Zeugnissatz.toDto(zs)),
      zeugnisse: this.zeugnisse.map((z) => Zeugnis.toDto(z)),
    };
  }

  static override cleanDto(dto: any): any {
    const { id, schullizenz, guid, schuelers, zeugnissaetze, kundennummer, bundesland, ...cleanDto } =
      Metadata.cleanDto(dto);
    return cleanDto;
  }

  static override toDto(jahrgang: Jahrgang): JahrgangContainer {
    return {
      ...Metadata.toDto(jahrgang),
      id: jahrgang.id,
      guid: jahrgang.guid,
      kundennummer: jahrgang.kundennummer,
      bundesland: jahrgang.bundesland,
      contentString: JSON.stringify({ ...this.cleanDto(jahrgang) }, Helper.jsonNullReplacer),
    };
  }

  static fromDto(dto: JahrgangContainer, schullizenz: Schullizenz): Jahrgang {
    const content = this.cleanDto(JSON.parse(dto.contentString));
    const jahrgang = new Jahrgang(dto.id, dto.guid, schullizenz, content.version ?? 0);
    Metadata.assignDto(jahrgang, dto);
    return Object.assign(jahrgang, content);
  }

  static fromScope(scope: JahrgangScopeDto, schullizenzen: Schullizenz[]): Jahrgang[] {
    const jahrgaenge = scope.jahrgaenge.map((dto) => {
      const lizenz = schullizenzen.find((l) => l.kundennummer === dto.kundennummer);
      if (lizenz == null) throw new Error(`Lizenz ${dto.kundennummer} nicht verfügbar.`);
      return Jahrgang.fromDto(dto, lizenz);
    });
    for (const jahrgang of jahrgaenge) {
      for (const schuelerDto of scope.schuelers.filter((s) => s.jahrgangId === jahrgang.id)) {
        Schueler.fromDto(schuelerDto, jahrgang);
      }
      for (const zeugnissatzDto of scope.zeugnissaetze.filter((zs) => zs.jahrgangId === jahrgang.id)) {
        const zeugnissatz = Zeugnissatz.fromDto(zeugnissatzDto, jahrgang);
        for (const zeugnisDto of scope.zeugnisse.filter((z) => z.zeugnissatzId === zeugnissatz.id)) {
          Zeugnis.fromDto(zeugnisDto, jahrgang.schuelers.filter((s) => s.id === zeugnisDto.schuelerId)[0], zeugnissatz);
        }
      }
    }
    return jahrgaenge;
  }

  static mergeAdded(jahrgang: Jahrgang, scope: JahrgangScopeDto) {
    for (const dto of scope.schuelers) {
      if (dto.jahrgangId === jahrgang.id) {
        if (!jahrgang.schuelers.some((s) => s.id === dto.id)) {
          Schueler.fromDto(dto, jahrgang);
        }
      }
    }
    for (const dto of scope.zeugnissaetze) {
      if (dto.jahrgangId === jahrgang.id) {
        if (!jahrgang.zeugnissaetze.some((zs) => zs.id === dto.id)) {
          Zeugnissatz.fromDto(dto, jahrgang);
        }
      }
    }

    for (const dto of scope.zeugnisse) {
      const zeugnissatz = jahrgang.zeugnissaetze.find((zs) => zs.id === dto.zeugnissatzId);
      const schueler = jahrgang.schuelers.find((s) => s.id === dto.schuelerId);
      if (zeugnissatz != null && schueler != null) {
        if (!zeugnissatz.zeugnisse.some((z) => z.id === dto.id)) {
          Zeugnis.fromDto(dto, schueler, zeugnissatz);
        }
      }
    }
  }
}
