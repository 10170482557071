<div
  class="right-0 flex-shrink-0 transition-all z-20 bg-white h-full border-solid border-0 border-gray-200"
  [ngClass]="{
    'w-96': sidebarVisible,
    'w-24': !sidebarVisible && sidebarNarrow,
    'w-0': !sidebarVisible && !sidebarNarrow,
    'border-l-2': sidebarVisible || sidebarNarrow,
    static: !sidebarOverlay,
    absolute: sidebarOverlay,
  }"
>
  <div class="relative">
    <div class="absolute" style="right: 0.5rem; bottom: 0.5rem">
      <button
        pButton
        class="p-button-rounded p-button-outlined"
        [icon]="sidebarVisible ? 'pi pi-arrow-right' : 'pi pi-arrow-left'"
        (click)="sidebarVisible = !sidebarVisible"
      ></button>
    </div>
  </div>
  <div class="w-96">
    <div class="flex flex-col gap-2 m-1">
      <ng-container *ngIf="workspace.canEditZeugnis && !workspace.isZeugnissatzLocked">
        <div class="text-2xl">Formularvariante</div>
        <ng-container [ngTemplateOutlet]="formularComponent?.sidebarTemplate ?? null"></ng-container>
        <div class="mt-2">
          <div class="text-2xl">Weitere Aktionen</div>
          <div class="flex items-center">
            <button pButton [text]="true" (click)="reset()" label="Formular zurücksetzen"></button>
            <fz-help-info [key]="'formularReset'"></fz-help-info>
          </div>
          <div class="flex items-center">
            <button pButton [text]="true" (click)="reset(true)" label="Noten zurücksetzen"></button>
            <fz-help-info [key]="'formularResetNoten'"></fz-help-info>
          </div>
        </div>
      </ng-container>
      <fz-formular-lock
        class="flex flex-col gap-2"
        *ngIf="workspace.isZeugnissatzLocked && workspace.currentZeugnissatz != null"
      ></fz-formular-lock>
      <ng-container *ngIf="!workspace.canEditZeugnis">
        <p>
          Ausgewählte Zeugnisse:<br /><strong>{{ workspace.selectedZeugnissatz?.klasseSchuljahrHalbjahr }}</strong>
        </p>
        <p>
          Aktuelle Zeugnisse:<br /><strong>{{ workspace.currentZeugnissatz?.klasseSchuljahrHalbjahr }}</strong>
        </p>
        <button
          pButton
          label="Zu den aktuellen Zeugnissen"
          (click)="workspace.switchSelectedZeugnissatz(workspace.currentZeugnissatz)"
        ></button>
        <button
          pButton
          class="p-button-secondary"
          label="Trotzdem bearbeiten"
          (click)="workspace.canEditZeugnisOverride = true"
        ></button>
      </ng-container>
    </div>
  </div>
</div>
