<ng-template fzTemplate="sidebar">
  <fz-he-schueler-typ></fz-he-schueler-typ>
</ng-template>
<fz-formular
  name="HE1234hjLern"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="138"
  [pageMarginBottomLast]="138"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLastFoerderschwerpunkt"
>
  <fz-he-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-he-zeugniskopf>
  <fz-he-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-he-zeugnistyp>
  <fz-he-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-he-schueler>
  <fz-he-zeugnissatz-foerderschwerpunkt
    fzFormularItem="zeugnissatz"
    fzContainer
    [model]="model.zeugnissatz"
  ></fz-he-zeugnissatz-foerderschwerpunkt>
  <fz-section fzFormularItem="entwicklungsstand" label="Leistungs- und Entwicklungsstand" headerClass="Ueberschriften">
    <fz-sequence gap="minus-border">
      <fz-deco
        *ngFor="let bereich of model.bereiche; first as isFirst; trackBy: trackByProperty"
        [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
        paddingBottom="normal"
      >
        <fz-section [label]="bereich.label" headerClass="Ueberschriften2" headerPaddingBottom="normal">
          <fz-panel fzSectionHeader paddingBottom="normal">
            <fz-he-bereich-header [model]="bereich.header"></fz-he-bereich-header>
          </fz-panel>
          <fz-panel fzSectionHeader paddingBottom="normal" class="Ueberschriften2">
            <fz-label>{{ bereich.header.labelFortsetzung }} (Fortsetzung)</fz-label>
          </fz-panel>
          <fz-verbal
            [field]="bereich.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-section>
      </fz-deco>
    </fz-sequence>
  </fz-section>
  <fz-section
    fzFormularItem="wahlunterricht"
    label="Wahlunterricht/Freiwillige Unterrichtsveranstaltungen"
    headerClass="Ueberschriften"
  >
    <fz-sequence gap="minus-border">
      <fz-deco
        [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
        paddingBottom="normal"
      >
        <fz-verbal
          [field]="model.herkunftssprache.textField"
          placeholder="Kurs/Unterricht in der Herkunftssprache"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
      <fz-deco
        [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
        paddingBottom="normal"
      >
        <fz-verbal
          [field]="model.sprachkompetenz.textField"
          placeholder="Kurs/Förderung der Sprachkompetenz**"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-sequence>
  </fz-section>
  <fz-section fzFormularItem="bemerkungen" [label]="model.bemerkungen.label" headerClass="Ueberschriften">
    <fz-deco
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
      paddingBottom="normal"
    >
      <fz-intelli
        [field]="model.bemerkungen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-he-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-he-versaeumnis>
  <fz-he-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-he-unterschrift>
</fz-formular>
