import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEUnterschriftComponent } from './he-unterschrift.component';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-unterschrift-optional',
  templateUrl: 'he-unterschrift-optional.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, HEUnterschriftOptionalComponent)],
})
export class HEUnterschriftOptionalComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      selectField: formular.anlageKeineUnterschriften,
      unterschrift:
        formular.anlageKeineUnterschriften.value === false ? HEUnterschriftComponent.createModel(formular) : undefined,
    };
  }

  @Input() model: ReturnType<typeof HEUnterschriftOptionalComponent.createModel> | undefined;
}
