import { Paragraph } from './paragraph';
import { Line } from './line';
import { ContentProperties } from './content-properties';
import itiriri from 'itiriri';
import { NodeWalker } from '@modules/dom';

export enum ParseNewLineMode {
  lineFeedOnly,
  paragraphTagAndLineFeed,
}

export class Content {
  public readonly paragraphs: Paragraph[];
  public readonly lines: Line[];
  public readonly height: number;

  constructor(paragraphs: Paragraph[]) {
    this.paragraphs = paragraphs;
    this.lines = Array.from(this.paragraphs)
      .map((p) => Array.from(p.lines))
      .reduce((prev, curr) => prev.concat(curr), []);
    this.height = this.lines.map((l) => l.height).reduce((prev, curr) => prev + curr, 0);
  }

  public static from(fragment: DocumentFragment, properties: ContentProperties, hostElement: HTMLElement): Content {
    const walker = new NodeWalker(fragment);
    const paragraphs = itiriri(walker.childNodes)
      .filter((c) => c instanceof HTMLParagraphElement)
      .toArray() as HTMLParagraphElement[];
    const content = new Content(
      paragraphs.reduce(
        (prev, curr) =>
          prev.concat(
            Paragraph.from(curr, prev.slice(-1)[0], properties, hostElement, curr === paragraphs[paragraphs.length - 1])
          ),
        [] as Paragraph[]
      )
    );
    return content;
  }
}
