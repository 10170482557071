<ng-template fzTemplate="sidebar">
  <p-checkbox
    label="Anlage mit Freitext aktivieren"
    [binary]="true"
    [(ngModel)]="formular.zeugnis.anlageFreitextAktiv"
  ></p-checkbox>
</ng-template>

<fz-formular
  name="SL1234hjFreitext"
  [pageMarginTop]="151"
  [pageMarginTopFirst]="57"
  [pageHeader]="slFolgeseiteComponent"
  [pageFooter]="slSeitenfussComponent"
>
  <ng-container *ngIf="model != null">
    <fz-sl-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-sl-zeugniskopf>
    <fz-sl-zeugnissatz-anlage fzFormularItem="zeugnissatz" fzContainer></fz-sl-zeugnissatz-anlage>
    <fz-sl-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-sl-schueler>
    <fz-deco
      fzFormularItem="anlageFreitext"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-verbal
        [field]="model.anlageFreitextField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-deco>
    <fz-sl-schulort fzFormularItem="schulort" fzContainer [model]="model.schulort"></fz-sl-schulort>
    <fz-sl-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-sl-unterschrift>
    <fz-sl-kenntnis fzFormularItem="kenntnis" fzContainer></fz-sl-kenntnis>
  </ng-container>
</fz-formular>
