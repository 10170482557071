import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WorkspaceService } from '../../shared/services';

@Component({
  selector: 'fz-apply-kp',
  templateUrl: 'apply-kp.component.html',
})
export class ApplyKPComponent implements OnInit, OnDestroy {
  text: string | undefined;
  private subscriptions: Subscription[] = [];

  constructor(private workspaceService: WorkspaceService) {}

  async ngOnInit() {
    await this.updateText();
    this.subscriptions.push(
      this.workspaceService.kpChange.subscribe(() => {
        this.updateText();
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) subscription.unsubscribe();
  }

  private async updateText() {
    this.text = await this.workspaceService.canApplyKP();
  }

  async apply() {
    await this.workspaceService.applyKP();
    this.text = undefined;
  }
}
