/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { Distance } from '@modules/dom/distance';
import { DistanceOverride } from '@modules/dom/distance-override';
import { Projectable } from '@modules/projectables/projectable';
import { InterfaceProvider, provideInterfaceBy } from '@modules/shared/interface-provider';
import { Block, BlockFactory, BlockFactoryOwnerProvider, BlockFactoryProvider, BlockRange } from './block-factory';
import { BlockDirective } from './block.directive';

export interface SectionHeader {
  project(): void;
  height: number;
  projectable: Projectable | undefined;
}

export class SectionHeaderProvider implements InterfaceProvider<SectionHeader> {
  constructor(public provided: SectionHeader) {}
}

export type SectionBlockModel = {
  header: Projectable | undefined;
  contentPosition: DOMRect;
  contentBlock: Block;
  rect: DOMRect;
};

@Component({
  selector: 'fz-section',
  templateUrl: 'section-block-factory.component.html',
  providers: [provideInterfaceBy(BlockFactoryProvider, SectionBlockFactoryComponent)],
})
export class SectionBlockFactoryComponent implements BlockFactory {
  @Input() label = '';
  @Input() headerClass = 'Ueberschriften2';
  @Input() headerBorder: BorderDirection = BorderDirection.none;
  @Input() headerPadding: Distance = 'none';
  @Input() headerPaddingTop: DistanceOverride = 'use-fallback';
  @Input() headerPaddingBottom: DistanceOverride = 'use-fallback';
  @Input() headerPaddingLeft: DistanceOverride = 'use-fallback';
  @Input() headerPaddingRight: DistanceOverride = 'use-fallback';
  @ContentChild(BlockFactoryProvider) blockFactoryProvider?: BlockFactoryProvider;
  @ContentChildren(SectionHeaderProvider) headerProviders = new QueryList<SectionHeaderProvider>();
  @ViewChildren(SectionHeaderProvider) fallbackHeaderProviders = new QueryList<SectionHeaderProvider>();
  @ViewChildren(BlockDirective) pageItems = new QueryList<BlockDirective>();
  blockModels: SectionBlockModel[] = [];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private ownerProvider: BlockFactoryOwnerProvider
  ) {}
  get blockFactory(): BlockFactory | undefined {
    return this.blockFactoryProvider?.provided;
  }
  get headers(): SectionHeader[] {
    return this.headerProviders.map((p) => p.provided);
  }
  get fallBackHeaders(): SectionHeader[] {
    return this.fallbackHeaderProviders.map((p) => p.provided);
  }

  get header(): SectionHeader | undefined {
    return this.headers[0] ?? this.fallBackHeaders[0];
  }
  get headerFollowing(): SectionHeader | undefined {
    return this.headers[1] ?? this.fallBackHeaders[1];
  }
  project(): void {
    for (const header of this.headers) {
      header.project();
    }
    for (const header of this.fallBackHeaders) {
      header.project();
    }
    this.blockFactory?.project();
  }
  getBlockCount(): number {
    return this.blockFactory?.getBlockCount() ?? 0;
  }
  measureHeight(range: BlockRange): number {
    return (
      (this.blockFactory?.measureHeight(range) ?? 0) +
      (range.start === 0 ? this.header?.height ?? 0 : this.headerFollowing?.height ?? 0)
    );
  }
  layout(ranges: BlockRange[]): Block[] {
    this.blockModels =
      this.blockFactory?.layout(ranges).map((block, index) => ({
        header: index === 0 ? this.header?.projectable : this.headerFollowing?.projectable,
        contentPosition: new DOMRect(0, index === 0 ? this.header?.height : this.headerFollowing?.height ?? 0),
        contentBlock: block,
        rect: new DOMRect(0, 0),
      })) ?? [];
    this.changeDetector.detectChanges();
    return this.pageItems.toArray();
  }
}
