<fz-panel *ngIf="model != null">
  <div
    style="
      display: grid;
      grid-template-columns: 1fr 0.6fr 1.2fr 1.2fr;
      grid-template-rows: auto auto;
      column-gap: 19px;
      row-gap: 4px;
    "
  >
    <div style="grid-row: 1; grid-column: 1">
      <fz-label class="Ueberschriften2">Versäumnisse:</fz-label>
    </div>
    <div style="grid-row: 1; grid-column: 2">
      <fz-label class="Versaeumnisse_Text">{{ formular.fehltageGesamt }}</fz-label
      ><fz-label class="Versaeumnisse_Beschriftung"
        >&nbsp;{{ formular.fehltageGesamt === 1 ? 'Tag' : 'Tage' }}</fz-label
      >
    </div>
    <div style="grid-row: 1; grid-column: 3">
      <fz-label>(</fz-label><fz-number class="Versaeumnisse_Text" [field]="model.fehltageEntschuldigtField"></fz-number
      ><fz-label class="Versaeumnisse_Beschriftung"
        >&nbsp;{{ model.fehltageEntschuldigtField.value === 1 ? 'Tag' : 'Tage' }} entschuldigt/</fz-label
      >
    </div>
    <div style="grid-row: 1; grid-column: 4">
      <fz-number class="Versaeumnisse_Text" [field]="model.fehltageUnentschuldigtField"></fz-number
      ><fz-label class="Versaeumnisse_Beschriftung"
        >&nbsp;{{ model.fehltageUnentschuldigtField.value === 1 ? 'Tag' : 'Tage' }} unentschuldigt)</fz-label
      >
    </div>
    <div style="grid-row: 2; grid-column: 2">
      <fz-label class="Versaeumnisse_Text">{{ formular.fehlstundenGesamt }}</fz-label
      ><fz-label class="Versaeumnisse_Beschriftung"
        >&nbsp;{{ formular.fehlstundenGesamt === 1 ? 'Stunde' : 'Stunden' }}</fz-label
      >
    </div>
    <div style="grid-row: 2; grid-column: 3">
      <fz-label>(</fz-label
      ><fz-number class="Versaeumnisse_Text" [field]="model.fehlstundenEntschuldigtField"></fz-number
      ><fz-label class="Versaeumnisse_Beschriftung"
        >&nbsp;{{ model.fehlstundenEntschuldigtField.value === 1 ? 'Stunde' : 'Stunden' }} entschuldigt/</fz-label
      >
    </div>
    <div style="grid-row: 2; grid-column: 4">
      <fz-number class="Versaeumnisse_Text" [field]="model.fehlstundenUnentschuldigtField"></fz-number
      ><fz-label class="Versaeumnisse_Beschriftung"
        >&nbsp;{{ model.fehlstundenUnentschuldigtField.value === 1 ? 'Stunde' : 'Stunden' }} unentschuldigt)</fz-label
      >
    </div>
  </div>
</fz-panel>
