import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-schulort',
  templateUrl: 'sl-schulort.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLSchulortComponent)],
})
export class SLSchulortComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      schulortString: formular.zeugnissatz.zeugniskopflizenz.schulort,
      zeugnisausgabedatumField: formular.zeugnisausgabedatum,
    };
  }

  @Input() model: ReturnType<typeof SLSchulortComponent.createModel> | undefined;
}
