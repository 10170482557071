import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
  AfterViewChecked,
  OnInit,
  ViewChildren,
  QueryList,
  OnDestroy,
} from '@angular/core';
import { FormularFactory } from '@modules/formular/formular-factory';
import { PrimeTemplate } from 'primeng/api';
import { TabView } from 'primeng/tabview';
import { AppInfoService, WorkspaceService } from '../../shared/services';
import { FzeGuidedTourService } from '../../shared/services/fze-guided-tour.service';
import { JahrgangService } from '../../shared/services/jahrgang.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('tabView') tabView: TabView | undefined;
  @ViewChildren(PrimeTemplate) templates = new QueryList<PrimeTemplate>();

  activeIndex = 0;
  currentCategory = 'akte';

  #categoryKeys: string[] = [];

  countAllJahrgaenge: number = 0;
  sidebarVisible = false;

  get categoryKeys(): string[] {
    if (this.workspaceService.selectedZeugnis == null || !this.workspaceService.selectedZeugnis.schueler.isAktiv)
      return [];
    return FormularFactory.getFormularsatz(this.workspaceService.selectedZeugnis)
      .getCategories(this.workspaceService.selectedZeugnis)
      .map((c) => c.key);
  }

  constructor(
    private appInfoService: AppInfoService,
    public workspaceService: WorkspaceService,
    private jahrgangService: JahrgangService,
    public changeDetectorRef: ChangeDetectorRef,
    private fzeTour: FzeGuidedTourService
  ) {}
  ngOnDestroy(): void {
    this.fzeTour.close();
  }

  async ngOnInit() {
    this.countAllJahrgaenge = await this.jahrgangService.getJahrgangCount();
  }

  getLabel(key: string): string {
    if (this.workspaceService.selectedZeugnis == null || !this.workspaceService.selectedZeugnis.schueler.isAktiv)
      return '';
    return (
      FormularFactory.getFormularsatz(this.workspaceService.selectedZeugnis)
        .getCategories(this.workspaceService.selectedZeugnis)
        .filter((c) => c.key === key)[0]?.label ?? ''
    );
  }

  ngAfterViewInit(): void {
    this.appInfoService.currentPageTitleTemplate = this.templates.find((t) => t.name === 'currentPageTitle')?.template;
    this.changeDetectorRef.detectChanges();

    this.fzeTour.addSteps([
      {
        attachTo: {
          element: '#userMenu',
          on: 'bottom',
        },
        title: 'Benutzermenü',
        text: [
          'Klicken Sie auf das Benutzersymbol, um Funktionen wie Passwortändern, Datensicherung und -wiederherstellung aufzurufen.',
        ],
        buttons: this.fzeTour.buttonsCloseNext,
      },
      {
        attachTo: {
          element: '#appMenu',
          on: 'bottom',
        },
        title: 'Anwendungsmenü',
        text: [
          'Im Anwendungsmenü finden Sie Funktionen zum Erstellen neuer Zeugnisse, weiterführen in ein neues Halbjahr/Schuljahr, Drucken usw.',
        ],
        buttons: this.fzeTour.buttonsCloseBack,
      },
    ]);

    // this.fzeTour.start();
  }
  ngAfterViewChecked(): void {
    // Falls aktiver Tab bei Zeugniswechsel verschwindet gäbe es einen ExpressionChangedAfterItHasBeenCheckedError
    if (this.#categoryKeys.length !== this.categoryKeys.length) {
      this.#categoryKeys = this.categoryKeys;
      this.changeDetectorRef.detectChanges();
    }
  }

  gridInitiallySelected() {
    if (this.tabView != null) {
      this.changeDetectorRef.detectChanges();
      this.activeIndex = this.tabView.tabs.length - 1;
      this.changeDetectorRef.detectChanges();
      this.activeIndex = 0;
    }
  }

  handleChange(event: any) {
    this.currentCategory = this.categoryKeys[event.index] ?? 'akte';
  }

  sidebarVisibleChange(visible: boolean) {
    // setTimeout: Wichtig, sonst Absturz bei Import Jahrgang mit anderem Zeugnissatz
    // TODO: bessere Lösung suchen
    setTimeout(() => {
      this.sidebarVisible = visible;
      this.changeDetectorRef.detectChanges();
    });
  }

  get selectedCategory(): string | undefined {
    const categoryKeys = this.categoryKeys;
    if (this.activeIndex < categoryKeys.length) return categoryKeys[this.activeIndex];
    else return undefined;
  }
}
