import { Component } from '@angular/core';
import { ProjectableDecoratorBase, ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';

@Component({
  selector: 'fz-unavailable',
  templateUrl: 'unavailable.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, UnavailableComponent)],
})
export class UnavailableComponent extends ProjectableDecoratorBase {}
