import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';

@Component({
  selector: 'fz-rp-kenntnis',
  templateUrl: 'rp-kenntnis.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPKenntnisComponent)],
})
export class RPKenntnisComponent extends RPPanelDecoratorBase {}
