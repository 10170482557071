import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FormularFactory } from '@modules/formular/formular-factory';
import { FormularModule } from '@modules/formular/formular.module';
import { RichTextModule } from '@modules/rich-text/rich-text.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NIFachtexteBlockFactoryComponent } from './blocks/ni-fachtexte-block-factory.component';
import { NIFachtexteItemBlockFactoryComponent } from './blocks/ni-fachtexte-item-block-factory.component';
import { NI1234hjFreitextFormularComponent } from './formulare/ni-1234hj-freitext-formular.component';
import { NI1234hjGeistigFormularComponent } from './formulare/ni-1234hj-geistig-formular.component';
import { NI1234hjLernstandFormularComponent } from './formulare/ni-1234hj-lernstand-formular.component';
import { NI12hjStandardFormularComponent } from './formulare/ni-12hj-standard-formular.component';
import { NI34hjBerichtFormularComponent } from './formulare/ni-34hj-bericht-formular.component';
import { NI34hjFachtexteFormularComponent } from './formulare/ni-34hj-fachtexte-formular.component';
import { NI34hjStandardFormularComponent } from './formulare/ni-34hj-standard-formular.component';
import { NIFormularsatz } from './ni-formularsatz';
import { NILernTypComponent } from './options/ni-lern-typ.component';
import { NINormalTypComponent } from './options/ni-normal-typ.component';
import { NISchuelerTypComponent } from './options/ni-schueler-typ.component';
import { NILabelContainerComponent } from './projectables/ni-label-container.component';
import { NILeistungenComponent } from './projectables/ni-leistungen.component';
import { NISchuelerComponent } from './projectables/ni-schueler.component';
import { NISeitenfussLastComponent } from './projectables/ni-seitenfuss-last.component';
import { NISeitenfussComponent } from './projectables/ni-seitenfuss.component';
import { NISeitenkopfComponent } from './projectables/ni-seitenkopf.component';
import { NISiegelComponent } from './projectables/ni-siegel.component';
import { NIUnterschriftGeistigComponent } from './projectables/ni-unterschrift-geistig.component';
import { NIUnterschriftComponent } from './projectables/ni-unterschrift.component';
import { NIVersaeumnisComponent } from './projectables/ni-versaeumnis.component';
import { NIVersetzungsvermerkComponent } from './projectables/ni-versetzungsvermerk.component';
import { NIZeugniskopfComponent } from './projectables/ni-zeugniskopf.component';
import { NIZeugnissatzComponent } from './projectables/ni-zeugnissatz.component';
import { NIZeugnistypComponent } from './projectables/ni-zeugnistyp.component';
import { NIHerkunftsspracheComponent } from './options/ni-herkunftssprache.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ButtonModule,
    MenuModule,
    CheckboxModule,
    RadioButtonModule,
    FormularModule,
    RichTextModule,
  ],
  declarations: [
    NISchuelerTypComponent,
    NINormalTypComponent,
    NILernTypComponent,
    NIHerkunftsspracheComponent,
    NI12hjStandardFormularComponent,
    NI34hjStandardFormularComponent,
    NI34hjBerichtFormularComponent,
    NI34hjFachtexteFormularComponent,
    NI1234hjLernstandFormularComponent,
    NI1234hjGeistigFormularComponent,
    NI1234hjFreitextFormularComponent,
    NIZeugniskopfComponent,
    NIZeugnissatzComponent,
    NISchuelerComponent,
    NIVersaeumnisComponent,
    NIVersetzungsvermerkComponent,
    NIUnterschriftComponent,
    NIUnterschriftGeistigComponent,
    NILabelContainerComponent,
    NISeitenkopfComponent,
    NISeitenfussComponent,
    NISeitenfussLastComponent,
    NILeistungenComponent,
    NIZeugnistypComponent,
    NISiegelComponent,
    NIFachtexteBlockFactoryComponent,
    NIFachtexteItemBlockFactoryComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class NIModule {}

FormularFactory.registerFormularsatz(new NIFormularsatz());
