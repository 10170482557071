import { Component, EventEmitter, Output, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import-tb-finished',
  templateUrl: './import-tb-finished.component.html',
  styleUrls: ['./import-tb-finished.component.scss'],
})
export class ImportTbFinishedComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Output() restartSelected = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  zeugnis() {
    this.router.navigate(['/main']);
  }

  restart(): void {
    this.restartSelected.next(true);
  }
}
