import { borderWidthNormal } from '@modules/dom/border-direction';

export type Distance = 'none' | 'normal' | 'inside-border' | 'minus-border' | number;
export const distanceNormal = 6;

export function getDistance(distance: Distance): number {
  if (typeof distance === 'number') return distance;
  switch (distance) {
    case 'none':
      return 0;
    case 'normal':
      return distanceNormal;
    case 'inside-border':
      return distanceNormal;
    case 'minus-border':
      return -borderWidthNormal;
  }
}
