<p-dialog
  header="Flinky Zeugnis"
  [(visible)]="infoDialogVisible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="onHide()"
  (onShow)="onShow()"
>
  <div class="flex flex-row">
    <img class="w-40 h-40 my-auto pr-5" src="assets/Flinky-Logo.svg" alt="Flinky Logo" />
    <div class="flex flex-col">
      <p>
        Version:
        <span>{{ environment.clientVersion }}</span>
      </p>
      <p>
        Copyright &copy;
        <span>{{ info?.copyright }}</span>
      </p>
      <p>[DS'n'] software - Dirk Wegmann und Siegfried Scholl GbR</p>
      <p>in Kooperation mit Michael Ewen</p>

      <p>Weitere Informationen finden Sie unter:</p>
      <p>
        <a rel="noopener" href="{{ info?.infoUrl }}" target="_blank">Flinky im Internet</a>
      </p>
      <p>
        Hotline:
        <span>{{ info?.hotline }}</span>
      </p>
      <p>
        <a href="mailto:{{ info?.supportMail }}">E-Mail an {{ info?.supportMail }}</a>
      </p>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="infoDialogVisible = false" label="Ok"></p-button>
  </ng-template>
</p-dialog>
