<form [formGroup]="importpinform" (ngSubmit)="importInternet()">
  <p-panel>
    <ng-template pTemplate="header">
      <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
    </ng-template>
    <div class="flex flex-col">
      <label for="lizenz">Lizenz*</label>
      <div *ngIf="this.authService.lizenz" class="flex w-full gap-2">
        <img width="50%" src="{{ lizenzImage }}" alt="Zeugniskopf {{ this.authService.lizenz.kundennummer }}" />
      </div>

      <p class="mt-4">
        Geben Sie hier die PIN der im Internet bereitgestellten Transferdatei ein. Diese PIN erhalten Sie, wie das im
        nächsten Schritt erorderliche Export-Passwort, von der Lehrkraft aus Ihrem Kollegium, die Ihnen diese
        Transferdatei bereitgestellt hat.
      </p>
      <div class="flex flex-row pt-2">
        <em class="pi pi-key" style="font-size: xx-large; padding-right: 20px"></em>
        <input fzAutofocus pInputText type="text" id="pin" formControlName="pin" placeholder="PIN" />
      </div>
      <p *ngIf="pinError" class="preWhiteSpaced fz-validation-error mt-2">
        {{ error }}
      </p>
    </div>
    <ng-template pTemplate="footer">
      <div class="fz-button-group">
        <button
          pButton
          type="button"
          label="Abbrechen"
          icon="pi pi-times"
          class="w-64 p-button-secondary"
          [loading]="loading"
          (click)="firstStep()"
        ></button>
        <button
          pButton
          type="submit"
          label="Weiter"
          icon="pi pi-caret-right"
          class="w-64"
          [loading]="loading"
          [disabled]="!importpinform.valid"
        ></button>
      </div>
    </ng-template>
  </p-panel>
</form>
