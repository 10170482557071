import { Type } from '@angular/core';
import { FormularCategory, Formularsatz, HalbjahrDescription } from '@modules/formular/formularsatz';
import { SL1234hjGeistigFormular } from './formulare/sl-1234hj-geistig-formular.component';
import { SL12hProtokollFormular } from './formulare/sl-12h-protokoll-formular.component';
import { SL12hjNotenFormular } from './formulare/sl-12hj-noten-formular.component';
import { SL12jVerbalFormular } from './formulare/sl-12j-verbal-formular.component';
import { SL34hjStandardFormular } from './formulare/sl-34hj-standard-formular.component';
import { SL4hStandardFormular } from './formulare/sl-4h-standard-formular.component';
import { SLFormular } from './formulare/sl-formular';
import { SL1234hjFreitextFormular } from './formulare/sl-1234hj-freitext-formular.component';
import { SL234hjErlaeuterungenFormular } from './formulare/sl-234hj-erlaeuterungen-formular.component';
import { SL12jNullFormular } from './formulare/sl-12j-null-formular.component';
import { SL124hMitteilungFormular } from './formulare/sl-124h-mitteilung-formular.component';
import { BundeslandTyp } from '../../models/enums/bundesland-typ';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { SLZeugnis } from '../../models/sl-zeugnis';
import { Zeugnis } from '../../models/zeugnis';
import { NoteCandidateDescription } from '../../models/fields/note-field';

export type SLFormularCategoryKey = 'zeugnis' | 'anlage' | 'erlaeuterungen' | 'mitteilung';

export class SLFormularsatz extends Formularsatz {
  get zeugnisType(): Type<Zeugnis> {
    return SLZeugnis;
  }

  get bundesland(): BundeslandTyp {
    return 'SL';
  }

  getAllHalbjahre(schuljahr: number): HalbjahrDescription[] {
    return [
      {
        text: 'Schuleingangsphase 1/2, Halbjahreszeugnis/Protokoll',
        klassenstufe: 2,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: 'Schuleingangsphase 1/2, Jahreszeugnis',
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '3. Klasse, Halbjahreszeugnis',
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '3. Klasse, Jahreszeugnis',
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '4. Klasse, Halbjahreszeugnis',
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '4. Klasse, Jahreszeugnis',
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr,
      },
    ];
  }

  getRemainingHalbjahre(zeugnissatz: Zeugnissatz): HalbjahrDescription[] {
    const halbjahre: HalbjahrDescription[] = [];
    let klassenstufe = zeugnissatz.klassenstufe;
    const halbjahr = zeugnissatz.halbjahr;
    const schuljahr = zeugnissatz.schuljahr + 2 - klassenstufe;
    if (klassenstufe <= 2) klassenstufe = 2; // Schuleingangsphase normalisieren auf Klassenstufe 2
    if (klassenstufe === 2 && halbjahr === 0) {
      halbjahre.push({
        text: `Schuleingangsphase 1/2, Jahreszeugnis ${schuljahr}/${schuljahr + 1}`,
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr,
      });
    }
    if (klassenstufe < 3) {
      halbjahre.push({
        text: `Schuleingangsphase 1/2, Halbjahreszeugnis/Protokoll ${schuljahr + 1}/${schuljahr + 2}`,
        klassenstufe: 2,
        halbjahr: 0,
        schuljahr: schuljahr + 1,
      });
      halbjahre.push({
        text: `Schuleingangsphase 1/2, Jahreszeugnis ${schuljahr + 1}/${schuljahr + 2}`,
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr: schuljahr + 1,
      });
      halbjahre.push({
        text: `3. Klasse, Halbjahreszeugnis ${schuljahr + 1}/${schuljahr + 2}`,
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr: schuljahr + 1,
      });
    }
    if (klassenstufe < 3 || (klassenstufe === 3 && halbjahr === 0)) {
      halbjahre.push({
        text: `3. Klasse, Jahreszeugnis ${schuljahr + 1}/${schuljahr + 2}`,
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr: schuljahr + 1,
      });
    }
    if (klassenstufe < 4) {
      halbjahre.push({
        text: `4. Klasse, Halbjahreszeugnis ${schuljahr + 2}/${schuljahr + 3}`,
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr: schuljahr + 2,
      });
    }
    if (klassenstufe < 4 || (klassenstufe === 4 && halbjahr === 0)) {
      halbjahre.push({
        text: `4. Klasse, Jahreszeugnis ${schuljahr + 2}/${schuljahr + 3}`,
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr: schuljahr + 2,
      });
    }
    return halbjahre;
  }

  getCategories(zeugnis: SLZeugnis): FormularCategory[] {
    const categories: FormularCategory<SLFormularCategoryKey>[] = [];
    categories.push({ key: 'zeugnis', label: 'Zeugnis' });
    if (
      zeugnis.zeugnissatz.klassenstufe >= 3 ||
      (zeugnis.zeugnissatz.klassenstufe < 3 &&
        zeugnis.zeugnislevel === 2 &&
        !zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal)
    ) {
      categories.push({ key: 'erlaeuterungen', label: 'Ergänzende Erläuterungen' });
    }
    if (
      zeugnis.zeugnissatz.halbjahr === 0 &&
      (zeugnis.zeugnissatz.klassenstufe === 4 ||
        (zeugnis.zeugnissatz.klassenstufe <= 2 &&
          (zeugnis.zeugnislevel === 1 || zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal)))
    ) {
      categories.push({ key: 'mitteilung', label: 'Schriftliche Mitteilung' });
    }
    categories.push({ key: 'anlage', label: 'Anlage mit Freitext' });
    return categories;
  }

  getFormularType(zeugnis: SLZeugnis, category: SLFormularCategoryKey): Type<SLFormular> | null {
    switch (category) {
      case 'zeugnis':
        return this.getZeugnisFormularType(zeugnis);
      case 'erlaeuterungen':
        return SL234hjErlaeuterungenFormular;
      case 'mitteilung':
        return SL124hMitteilungFormular;
      case 'anlage':
        return SL1234hjFreitextFormular;
      default:
        return null;
    }
  }
  getZeugnisFormularType(zeugnis: SLZeugnis): Type<SLFormular> | null {
    const klassenstufe = zeugnis.zeugnissatz.klassenstufe;
    const halbjahr = zeugnis.zeugnissatz.halbjahr;
    const sepVerbal = zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal;
    const zeugnislevel = zeugnis.zeugnislevel;
    const formularTyp = zeugnis.formularTyp;
    const schuelerTyp = zeugnis.schuelerTyp;
    const zeugnisTyp = zeugnis.zeugnisTyp;
    const isSprachfoerderschule = zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule;

    if (schuelerTyp === 'Geistigbehindert') {
      if (klassenstufe <= 2) {
        if (zeugnislevel === null) return SL12jNullFormular;
        else if (zeugnislevel === 1) {
          if (halbjahr === 0) return SL12hProtokollFormular;
          if (halbjahr === 1) return SL1234hjGeistigFormular;
        } else if (zeugnislevel === 2) {
          if (halbjahr === 0) {
            if (sepVerbal) return SL12hProtokollFormular;
            else {
              return formularTyp === 'Regel'
                ? SL12hjNotenFormular
                : formularTyp === 'Verbal'
                  ? SL1234hjGeistigFormular
                  : SL1234hjGeistigFormular;
            }
          }
          if (halbjahr === 1) {
            if (sepVerbal) return SL1234hjGeistigFormular;
            else {
              return formularTyp === 'Regel' ? SL12hjNotenFormular : SL1234hjGeistigFormular;
            }
          }
        } else return null;
      } else {
        if (formularTyp === 'Regel') {
          if (isSprachfoerderschule) return SL34hjStandardFormular;
          else {
            if (klassenstufe === 3 && halbjahr === 0) return SL34hjStandardFormular;
            if (klassenstufe === 3 && halbjahr === 1) return SL34hjStandardFormular;
            if (klassenstufe === 4 && halbjahr === 0) return SL4hStandardFormular;
            if (klassenstufe === 4 && halbjahr === 1) return SL34hjStandardFormular;
          }
        } else if (formularTyp === 'Verbal') {
          return SL1234hjGeistigFormular;
        } else {
          return SL1234hjGeistigFormular;
        }
      }
    } else {
      if (klassenstufe <= 2) {
        if (zeugnislevel === null && !sepVerbal) return SL12jNullFormular;
        else if (zeugnislevel === null && sepVerbal) {
          if (halbjahr === 0) return zeugnisTyp !== 'Abgang' ? SL12hProtokollFormular : SL12jVerbalFormular;
          else return SL12jVerbalFormular;
        } else if (zeugnislevel === 1) {
          if (halbjahr === 0) {
            if (zeugnisTyp === 'Abgang') return SL12jVerbalFormular;
            else return SL12hProtokollFormular;
          }
          if (halbjahr === 1) return SL12jVerbalFormular;
        } else if (zeugnislevel === 2) {
          if (halbjahr === 0) {
            if (sepVerbal) return zeugnisTyp !== 'Abgang' ? SL12hProtokollFormular : SL12jVerbalFormular;
            else return SL12hjNotenFormular;
          }
          if (halbjahr === 1) return sepVerbal ? SL12jVerbalFormular : SL12hjNotenFormular;
        } else return null;
      }
      if (isSprachfoerderschule) return SL34hjStandardFormular;
      else {
        if (klassenstufe === 3 && halbjahr === 0) return SL34hjStandardFormular;
        if (klassenstufe === 3 && halbjahr === 1) return SL34hjStandardFormular;
        if (klassenstufe === 4 && halbjahr === 0) return SL4hStandardFormular;
        if (klassenstufe === 4 && halbjahr === 1) return SL34hjStandardFormular;
      }
    }
    return null;
  }

  getNoteCandidates(zeugnis: Zeugnis, fachName: string): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    if (zeugnis.zeugnissatz.is15) {
      candidates.push(
        { displayStringShort: '15', content: 'sehr gut (15)', value: 15 },
        { displayStringShort: '14', content: 'sehr gut (14)', value: 14 },
        { displayStringShort: '13', content: 'sehr gut (13)', value: 13 },
        { displayStringShort: '12', content: 'gut (12)', value: 12 },
        { displayStringShort: '11', content: 'gut (11)', value: 11 },
        { displayStringShort: '10', content: 'gut (10)', value: 10 },
        { displayStringShort: '09', content: 'befriedigend (09)', value: 9 },
        { displayStringShort: '08', content: 'befriedigend (08)', value: 8 },
        { displayStringShort: '07', content: 'befriedigend (07)', value: 7 },
        { displayStringShort: '06', content: 'ausreichend (06)', value: 6 },
        { displayStringShort: '05', content: 'ausreichend (05)', value: 5 },
        { displayStringShort: '04', content: 'ausreichend (04)', value: 4 },
        { displayStringShort: '03', content: 'mangelhaft (03)', value: 3 },
        { displayStringShort: '02', content: 'mangelhaft (02)', value: 2 },
        { displayStringShort: '01', content: 'mangelhaft (01)', value: 1 },
        { displayStringShort: '00', content: 'ungenügend (00)', value: 0 }
      );
    } else {
      candidates.push(
        { displayStringShort: '1', content: 'sehr gut', value: 1 },
        { displayStringShort: '2', content: 'gut', value: 2 },
        { displayStringShort: '3', content: 'befriedigend', value: 3 },
        { displayStringShort: '4', content: 'ausreichend', value: 4 },
        { displayStringShort: '5', content: 'mangelhaft', value: 5 },
        { displayStringShort: '6', content: 'ungenügend', value: 6 }
      );
    }
    if (fachName === 'sport') {
      candidates.push({ displayStringShort: 'b', content: 'befreit', value: 'Befreit' });
    }
    candidates.push(
      { displayStringShort: 'n.f.', content: 'nicht feststellbar', value: 'NichtFeststellbar' },
      { displayStringShort: '/', content: '/', value: 'Slash' },
      { displayStringShort: '*', content: '*', value: 'Stern' }
    );
    return candidates;
  }

  getNoteSuffixCandidates(): string[] {
    return ['*', '¹', '²', '³'];
  }

  getKopfnoteCandidates(): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    candidates.push(
      { displayStringShort: '1', content: 'sehr gut', value: 1 },
      { displayStringShort: '2', content: 'gut', value: 2 },
      { displayStringShort: '3', content: 'befriedigend', value: 3 },
      { displayStringShort: '4', content: 'nicht immer befriedigend', value: 4 },
      { displayStringShort: '5', content: 'unbefriedigend', value: 5 }
    );
    candidates.push({ displayStringShort: '*', content: '*', value: 'Stern' });
    return candidates;
  }

  getKopfnoteSuffixCandidates(): string[] {
    return [];
  }
}
