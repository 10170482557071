<ng-template fzTemplate="sidebar">
  <fz-sl-schueler-typ></fz-sl-schueler-typ>
  <fz-sl-zeugnislevel></fz-sl-zeugnislevel>
</ng-template>
<fz-formular
  name="SL12hProtokoll"
  [pageMarginTop]="151"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="113"
  [pageHeader]="formular.slFolgeseiteComponent"
  [pageFooter]="formular.slSeitenfussComponent"
>
  <fz-sl-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-sl-zeugniskopf>
  <fz-sl-zeugnissatz-protokoll
    fzFormularItem="zeugnissatz"
    fzContainer
    [model]="model.zeugnissatz"
  ></fz-sl-zeugnissatz-protokoll>
  <fz-sl-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-sl-schueler>
  <fz-section
    fzFormularItem="erziehungsberechtigte"
    [label]="model.erziehungsberechtigte.label"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    headerPaddingBottom="normal"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="none"
    >
      <fz-intelli
        [field]="model.erziehungsberechtigte.field"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="anschrift"
    [label]="model.anschrift.label"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    headerPaddingBottom="normal"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="none"
    >
      <fz-intelli
        [field]="model.anschrift.field"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="teilnehmer"
    [label]="model.teilnehmer.label"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    headerPaddingBottom="normal"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="none"
    >
      <fz-intelli
        [field]="model.teilnehmer.field"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="gespraechsinhalte"
    [label]="model.gespraechsinhalte.label"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    headerPaddingBottom="normal"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="none"
    >
      <fz-verbal
        [field]="model.gespraechsinhalte.field"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="weitereBesprechungspunkte"
    [label]="model.weitereBesprechungspunkte.label"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    headerPaddingBottom="normal"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="none"
    >
      <fz-intelli
        [field]="model.weitereBesprechungspunkte.field"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="vereinbarungen"
    [label]="model.vereinbarungen.label"
    [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
    headerPaddingBottom="normal"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="none"
    >
      <fz-intelli
        [field]="model.vereinbarungen.field"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-sl-schulort fzFormularItem="schulort" [marginBottom]="0" fzContainer [model]="model.schulort"></fz-sl-schulort>
  <fz-sl-unterschrift-protokoll
    fzFormularItem="unterschrift"
    fzContainer
    [model]="model.unterschrift"
  ></fz-sl-unterschrift-protokoll>
  <fz-sl-kenntnis fzFormularItem="kenntnis" fzContainer></fz-sl-kenntnis>
  <fz-sl-gespraechssatz
    fzFormularItem="gespraechssatz"
    fzContainer
    [model]="model.gespraechssatz"
  ></fz-sl-gespraechssatz>
</fz-formular>
