import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[fzAutofocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.elementRef.nativeElement instanceof HTMLInputElement) this.elementRef.nativeElement.focus();
    else {
      const treewalker = document.createTreeWalker(
        this.elementRef.nativeElement as HTMLElement,
        NodeFilter.SHOW_ELEMENT
      );
      while (treewalker.nextNode() != null) {
        if (treewalker.currentNode instanceof HTMLInputElement) {
          queueMicrotask(() => (treewalker.currentNode as HTMLInputElement).focus());
          break;
        }
      }
    }
  }
}
