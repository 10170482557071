<p-panel header="Bitte wählen Sie, in welcher Richtung die Noten erfasst werden sollen">
  <div class="pb-5">
    <p-selectButton
      [options]="stateOptions"
      [(ngModel)]="value1"
      optionLabel="label"
      optionValue="value"
    ></p-selectButton>
  </div>
  <form [formGroup]="notenform">
    <div #tableDiv [style.height]="calculatedHeight">
      <p-table
        #dt
        [columns]="cols"
        [value]="data"
        [autoLayout]="true"
        [scrollable]="true"
        scrollDirection="both"
        scrollHeight="flex"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <ng-container *ngFor="let col of columns; let i = index">
              <th style="min-width: 130px" scope="cols" *ngIf="i === 0" pFrozenColumn>
                {{ col.header }}
              </th>
              <th style="min-width: 130px" scope="cols" *ngIf="i !== 0">
                {{ col.header }}
              </th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr>
            <ng-container *ngFor="let col of columns; let colIndex = index">
              <td *ngIf="col.field === 'nameVorname'" pFrozenColumn>
                <div>
                  {{ rowData[col.field] }}
                </div>
              </td>
              <td *ngIf="col.field !== 'nameVorname'">
                <div class="flex flex-row gap-1">
                  <p-dropdown
                    *ngIf="
                      col.field != 'nameVorname' &&
                      rowData[col.field + '_visible'] == true &&
                      rowData[col.field + '_name']
                    "
                    [(ngModel)]="rowData[col.field + '_value']"
                    [options]="rowData[col.field + '_options']"
                    [formControlName]="rowData[col.field + '_name']"
                    optionLabel="label"
                    optionValue="value"
                    styleClass="w-noteneingabe"
                    #dropdowns
                    [editable]="false"
                    id="{{ rowIndex }}_{{ colIndex - 1 }}"
                    inputId="{{ rowIndex }}_{{ colIndex - 1 }}"
                    (onChange)="
                      inputChange(
                        $event,
                        rowData,
                        col.field,
                        rowIndex + '_' + (colIndex - 1),
                        rowData[col.field + '_hasSuffix']
                      )
                    "
                  >
                    <ng-template pTemplate="selectedItem">
                      <div class="flex align-items-center gap-2">
                        <div>{{ rowData[col.field + '_value'] ?? '&nbsp;' }}</div>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <div>{{ item.label }}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <p-dropdown
                    *ngIf="
                      col.field != 'nameVorname' &&
                      rowData[col.field + '_visible'] == true &&
                      rowData[col.field + '_hasSuffix']
                    "
                    [options]="rowData[col.field + '_suffix']"
                    [formControlName]="rowData[col.field + '_name_suffix']"
                    optionLabel="label"
                    optionValue="value"
                    styleClass="w-noteneingabe"
                    #dropdowns
                    id="{{ rowIndex }}_{{ colIndex - 1 }}_suffix"
                    inputId="{{ rowIndex }}_{{ colIndex - 1 }}_suffix"
                    (onChange)="
                      inputChangeSuffix($event, rowData, col.field, rowIndex + '_' + (colIndex - 1) + '_suffix')
                    "
                  ></p-dropdown>
                </div>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </form>
</p-panel>
