import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { WorkspaceService } from '../../../shared/services';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { SystemActionsService } from '../system-actions/system-actions.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fz-blankoliste',
  templateUrl: './blankoliste.component.html',
  styleUrls: ['./blankoliste.component.scss'],
})
export class BlankolisteComponent implements OnInit, OnDestroy {
  @ViewChild('image') image?: ElementRef;
  kopfzeileLabel = 'Blankoliste';
  spaltenAnzahl = 4;
  data: any[] = [];
  cols: { field: string; header: string }[] = [];
  exportColumns: { title: any; dataKey: any }[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private workspaceService: WorkspaceService,
    public systemActionsService: SystemActionsService
  ) {}
  async ngOnInit() {
    this.subscriptions.push(
      this.workspaceService.dataLoadedBehaviour.subscribe(() => {
        this.initTable();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  initTable(): void {
    this.data = [];

    this.cols = [{ field: 'nameVorname', header: 'Name, Vorname' }];

    this.workspaceService.selectedZeugnisse
      .filter((z) => z.schueler.isAktiv)
      .forEach((zeugnis) => {
        const obj = {};
        Object.assign(obj, { nameVorname: (zeugnis.schuelerName ?? '') + ', ' + (zeugnis.schuelerVorname ?? '') });

        for (let index = 0; index < this.spaltenAnzahl; index++) {
          Object.assign(obj, { ['col_' + index]: '' });
        }

        this.data.push(obj);
      });
    for (let index = 0; index < this.spaltenAnzahl; index++) {
      this.cols.push({ field: 'col_' + index, header: '' });
    }
    this.data.sort((a, b) => String(a.nameVorname).localeCompare(String(b.nameVorname), 'de-De'));
    this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
  }

  createPdf(): jsPDF {
    const doc = new jsPDF('landscape', 'mm', [297, 210]);
    autoTable(doc, {
      theme: 'grid',
      headStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0], lineWidth: 0.5 },
      footStyles: { fillColor: undefined, textColor: 0 },
      bodyStyles: { halign: 'center', valign: 'middle' },
      showFoot: 'lastPage',
      head: [
        [
          {
            content: this.kopfzeileLabel,
            colSpan: this.cols.length,
            styles: { fillColor: undefined, textColor: 0, fontSize: 18, lineWidth: 0 },
          },
        ],
        this.cols.map((c) => c.header),
      ],
      columns: this.exportColumns,
      body: this.data,
      didParseCell: function (data) {
        if (data.section === 'body') {
          // if (data.column.index % 2 === 0) {
          //   data.cell.styles.fillColor = [255, 255, 255];
          // } else {
          //   data.cell.styles.fillColor = [210, 210, 210];
          // }
          if (data.column.index === 0) {
            data.cell.styles.halign = 'left';
            data.cell.styles.cellWidth = 50;
          }
        }
      },
    });

    doc.addImage(this.image?.nativeElement, 270, 8, 10, 10);

    return doc;
  }

  setPdfTitle(doc: jsPDF): string {
    const title = 'FlinkyZeugnisEvo_Blankoliste';
    doc.setProperties({
      title,
    });
    return title;
  }

  print(): void {
    const doc = this.createPdf();
    {
      if (doc) {
        this.setPdfTitle(doc);
        doc.autoPrint({ variant: 'non-conform' });
        const blob = doc.output('blob');
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = URL.createObjectURL(blob);
        link.click();

        URL.revokeObjectURL(link.href);
      }
    }
  }

  download(): void {
    const doc = this.createPdf();
    {
      if (doc) {
        doc.save('Blankoliste.pdf');
      }
    }
  }
}
