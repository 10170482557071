import ExportTbResult from '../../../../models/generated/export-tb-result';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-export-tb-error',
  templateUrl: './export-tb-error.component.html',
  styleUrls: ['./export-tb-error.component.scss'],
})
export class ExportTbErrorComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Input() exportTbResult?: ExportTbResult;
  @Output() restartSelected = new EventEmitter<boolean>();

  restart() {
    this.restartSelected.next(true);
  }
}
