<p-panel class="flex-grow h-0 overflow-auto">
  <ng-template pTemplate="header">
    <p-steps class="w-full fz-steps-transparent" [model]="steps" [readonly]="true" [activeIndex]="activeStep"></p-steps>
  </ng-template>
  <div class="flex flex-col" *ngIf="isConfigError">
    Beim Laden der Konfiguration ist ein Fehler aufgetreten. Die Könnensprofileinrichtung ist nicht verfügbar. Bitte
    versuchen Sie es später noch einmal. Falls dieses Problem weiterhin besteht, wenden Sie sich bitte an den Flinky
    Support.
  </div>
  <div class="flex flex-col" *ngIf="!isConfigError">
    <ng-container *ngIf="activeStep === 0">
      <div class="font-bold text-lg pt-1">Achtung</div>
      <div class="m-1">
        Im folgenden werden schulweite Einstellungen vorgenommen. Tun Sie dies nur, wenn Sie von der Schulleitung dazu
        beauftragt worden sind.
      </div>
      <div class="m-1">Diese Einstellungen können selbstverständlich jedoch jederzeit wieder geändert werden.</div>
      <div class="m-1">Bestätigen Sie dies durch die Eingabe von 'ja' im Eingabefeld.</div>
      <div class="m-1">
        <input pInputText type="text" [(ngModel)]="bestaetigung" />
      </div>
    </ng-container>

    <ng-container *ngIf="activeStep === 1">
      <p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
      <div class="font-bold text-lg pt-5">Art der Zeugnisse</div>
      <div class="m-1">
        <p-radioButton
          name="zeugnisart"
          [value]="true"
          label="Könnensprofile"
          [(ngModel)]="schulConfig.isKPActive"
        ></p-radioButton>
      </div>
      <div class="m-1 pl-5">
        Könnensprofilzeugnisse können schulweit einheitlich in den Jahreszeugnissen der 3. und 4. Klasse ab dem
        Schuljahr 2013/2014 verwendet werden.
      </div>
      <div class="m-1 pl-5">
        <p-checkbox
          name="groupname"
          [binary]="true"
          [(ngModel)]="schulConfig.kpItemsPublic"
          label="Die Items veröffentlichen und anderen Schulen zugänglich machen."
        ></p-checkbox>
      </div>
      <div class="m-1">
        <p-radioButton
          name="zeugnisart"
          [value]="false"
          label="Verbal"
          [(ngModel)]="schulConfig.isKPActive"
        ></p-radioButton>
      </div>
      <div class="m-1 pl-5">
        Alternativ zu den Könnensprofilzeugnissen. Diese können schulweit einheitlich weiterhin im Schuljahr 2013/2014
        eingesetzt werden.
      </div>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <!-- <button pButton label="Abbrechen" icon="pi pi-times" class="w-64 p-button-secondary" (click)="cancel()"></button> -->
      <button
        *ngIf="activeStep === 0"
        pButton
        label="Weiter"
        icon="pi pi-caret-right"
        class="w-64"
        (click)="activeStep = 1"
        [disabled]="bestaetigung.trim().toLowerCase() !== 'ja' || isConfigError"
      ></button>
      <button
        *ngIf="activeStep === 1"
        pButton
        label="Weiter"
        icon="pi pi-caret-right"
        class="w-64"
        (click)="ok()"
        [disabled]="isConfigError"
      ></button>
    </div>
  </ng-template>
</p-panel>
