<fz-panel *ngIf="model != null">
  <div style="display: grid; grid-template-columns: 1fr 1fr 49px; margin-right: -49px; padding-top: 4px">
    <div style="grid-column: 1" class="Noten_Beschriftung">
      <fz-text [field]="model.labelField" placeholder="Fachbezeichnung"></fz-text>
    </div>
    <div style="grid-column: 2" class="Noten_Text">
      <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
        <fz-note [field]="model.noteField"></fz-note>
      </fz-panel>
    </div>
  </div>
</fz-panel>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div style="position: absolute">
    <ng-container [fzPortalOutlet]="panel?.frontDeco"></ng-container>
  </div>
  <div
    *ngIf="hasFocus"
    fzFocusable
    [style.bottom.px]="panel?.rect?.top"
    [style.left.px]="panel?.rect?.left"
    class="floating fz-note"
    tabindex="0"
  >
    <p-button
      icon="pi pi-minus"
      label="Fach&nbsp;&quot;{{ model?.label }}&quot;&nbsp;entfernen"
      (onClick)="deleteFach()"
    ></p-button>
  </div>
</ng-template>
