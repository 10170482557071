<div>
  <p-radioButton name="formularTyp" [value]="null" [(ngModel)]="formularTyp" label="Normal"></p-radioButton>
</div>
<div>
  <p-radioButton
    name="formularTyp"
    [value]="'Lernbehindert'"
    [(ngModel)]="formularTyp"
    label="Förderschule Lernen{{
      formular.zeugnis.zeugnissatz.klassenstufe < 3 ? '(identisch mit Standardformular seit 2018/19)' : ''
    }}"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="formularTyp"
    [value]="'Geistig'"
    [(ngModel)]="formularTyp"
    label="Förderschule Geistige Entwicklung"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="formularTyp"
    [value]="'Geistigbehindert'"
    [(ngModel)]="formularTyp"
    label="Freies Formular"
  ></p-radioButton>
</div>
