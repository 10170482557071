import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import FlinkyVersionInfo from '../../models/generated/flinky-version-info';
import { SystemActionsService } from '../components/system-actions/system-actions.service';

@Injectable({ providedIn: 'root' })
export class VersionService {
  serverUpdateAvailable: boolean = false;
  clientUpdateAvailable: boolean = false;

  updateAvailableChange = new BehaviorSubject<void>(undefined);

  constructor(
    private http: HttpClient,
    private systemActionsService: SystemActionsService
  ) {
    if (this.systemActionsService.isElectron) {
      try {
        (window as any).electronAPI.onUpdateAvailable(() => {
          this.serverUpdateAvailable = true;
          this.updateAvailableChange.next();
        });
      } catch {
        /**/
      }
    }
  }

  isDbVersionCompatible(): Promise<boolean> {
    return lastValueFrom(this.http.get<boolean>('/api/version/isDbVersionCompatible'));
  }

  checkForLicenseUpdate(connectionId: string | null | undefined) {
    const httpParams = new HttpParams().append('connectionId', connectionId ?? '');

    const options = { params: httpParams };

    return lastValueFrom(this.http.get<void>('/api/update/checkForLicenseUpdate', options));
  }

  getVersionInfo() {
    return lastValueFrom(this.http.get<FlinkyVersionInfo>('/api/Info'));
  }
}
