import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';

@Component({
  selector: 'fz-he-zeugniskopf',
  templateUrl: 'he-zeugniskopf.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, HEZeugniskopfComponent)],
})
export class HEZeugniskopfComponent extends HEPanelDecoratorBase {}
