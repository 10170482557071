import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SchuelerlisteService {
  constructor(private datePipe: DatePipe) {}

  getDisplayValue(value: any): string {
    if (value instanceof Date && value != null) {
      return this.datePipe.transform(value, 'dd.MM.yyyy') ?? value.toString();
    }

    return value;
  }
}
