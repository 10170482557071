import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import ImportTbResult from '../../../../models/generated/import-tb-result';

@Component({
  selector: 'app-import-tb-error',
  templateUrl: './import-tb-error.component.html',
  styleUrls: ['./import-tb-error.component.scss'],
})
export class ImportTbErrorComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Input() importTbResult?: ImportTbResult;
  @Output() restartSelected = new EventEmitter<boolean>();

  restart() {
    this.restartSelected.next(true);
  }
}
