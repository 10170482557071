import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { lastValueFrom } from 'rxjs';
import { SystemActionsService } from '../../system-actions/system-actions.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  standalone: true,
  imports: [DialogModule, ButtonModule, FormsModule, PanelModule, CheckboxModule],
})
export class DisclaimerComponent implements OnInit {
  infoDialogVisible = false;
  impressum: string | undefined;
  impressumGelesen: boolean = false;
  nutzungsbedingungen: string | undefined;
  nutzungsbedingungenGelesen: boolean = false;
  datenschutz: string | undefined;

  okClicked: boolean = false;

  resolve: ((value: boolean | PromiseLike<boolean>) => void) | undefined;

  constructor(
    private http: HttpClient,
    private systemActionsService: SystemActionsService
  ) {}
  async ngOnInit() {
    this.impressum = await lastValueFrom(this.http.get('assets/disclaimer/impressum.txt', { responseType: 'text' }));
    this.nutzungsbedingungen = await lastValueFrom(
      this.http.get('assets/disclaimer/nutzungsbedingungen.txt', { responseType: 'text' })
    );
    this.datenschutz = await lastValueFrom(
      this.http.get(`assets/disclaimer/datenschutz-${this.systemActionsService.isElectron ? 'app' : 'webapp'}.txt`, {
        responseType: 'text',
      })
    );
  }

  onHide() {
    if (this.resolve != null) {
      this.resolve(this.nutzungsbedingungenGelesen && this.okClicked);
      this.resolve = undefined;
      this.okClicked = false;
      this.nutzungsbedingungenGelesen = false;
    }
  }

  ok(): void {
    this.okClicked = true;
    this.infoDialogVisible = false;
  }

  async show(): Promise<boolean> {
    this.infoDialogVisible = true;
    return new Promise((resolve, _reject) => {
      this.resolve = resolve;
    });
  }
}
