<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="flex flex-col fz-paragraph-text">
    <p>Die verschlüsselte Transferdatei wurde erfolgreich hochgeladen.</p>
    <p>Weiteres Vorgehen:</p>
    <ol class="list-decimal list-inside">
      <li>Notieren Sie sich die angezeigte PIN.</li>
      <li>Teilen Sie dem Empfänger der Transferdatei diese PIN mit und nennen Sie ihm Ihr Export-Passwort.</li>
      <li>Der Empfänger kann jetzt Ihre Daten mit Ihrer PIN und Ihrem Export-Passwort importieren.</li>
    </ol>
    <p>Hinweis: Diese PIN ist 14 Tage gültig. Ihre hochgeladenen Daten werden danach automatisch gelöscht.</p>

    <div class="flex flex-row gap-5 pt-5">
      <em class="pi pi-key" style="font-size: xx-large; padding-right: 20px"></em>
      <input pInputText [value]="pin" [readOnly]="true" width="100px" />
      <button pButton type="button" icon="pi pi-copy" [cdkCopyToClipboard]="pin" label="Kopieren"></button>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Zur Zeugnisübersicht"
        class="w-64"
        icon="pi pi-home"
        (click)="navigateToZeugnisse()"
      ></button>
    </div>
  </ng-template>
</p-panel>
