import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  QueryList,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableOwnerProvider, ProjectableBase } from './projectable';
import { PanelComponent } from './panel.component';
import { SelectCandidateDirective } from './select-candidate.directive';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Field } from '../../models/fields/field';

@Component({
  selector: 'fz-select',
  templateUrl: 'select.component.html',
  styles: [':host {display: block}'],
  providers: [provideInterfaceBy(ProjectableProvider, SelectComponent)],
})
export class SelectComponent extends ProjectableBase implements AfterViewInit, AfterViewChecked, Projectable {
  @Input() placeholder = 'Auswahl';
  @Input() emptyValue?: unknown;
  @Input() emptyLabel?: string;
  @Input() suppressNull = false;
  @Input() defaultValue: unknown | null = null;
  @Input() field?: Field;
  @Output() hasFocusChange = new EventEmitter<boolean>();
  @ContentChildren(SelectCandidateDirective) candidateList = new QueryList<SelectCandidateDirective>();
  @ViewChild('focusableContent') focusableContent?: ElementRef<HTMLElement>;
  @ViewChild('nullCandidate', { read: SelectCandidateDirective }) nullCandidate?: SelectCandidateDirective;
  @ViewChild('emptyCandidate', { read: SelectCandidateDirective }) emptyCandidate?: SelectCandidateDirective;

  #value: unknown | null = null;
  projectedPosition = new DOMRect();
  selectedContainer?: PanelComponent;
  private afterViewInit = false;

  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef,
    private ownerProvider: ProjectableOwnerProvider
  ) {
    super(elementRef, parentProvider);
  }

  get candidates(): SelectCandidateDirective[] {
    const candidates: SelectCandidateDirective[] = [];
    if (this.nullCandidate != null) {
      candidates.push(this.nullCandidate);
    }
    candidates.push(...this.candidateList.toArray());
    if (this.emptyCandidate != null) {
      candidates.push(this.emptyCandidate);
    }
    return candidates;
  }

  get emptyContainer(): PanelComponent | undefined {
    return this.emptyCandidate?.container;
  }

  get value(): unknown | null {
    return this.#value ?? this.defaultValue;
  }
  set value(value: unknown | null) {
    this.#value = value;
    if (this.field != null) this.field.value = value;
    this.updateSelectedContainer();
  }
  get rect(): DOMRect {
    const leftOffset = this.selectedContainer === this.emptyContainer ? -38 : 0;
    return new DOMRect(
      this.projectedPosition.left + leftOffset,
      this.projectedPosition.top,
      this.projectedPosition.width,
      this.selectedContainer?.rect?.height ?? 0
    );
  }

  ngAfterViewInit(): void {
    for (const candidate of this.candidates) {
      candidate.show = false;
    }
    this.projectPosition(this.sourceElement.getBoundingClientRect());
    this.updateSelectedContainer();
    this.afterViewInit = true;
  }
  ngAfterViewChecked(): void {
    if (this.field != null && this.field.value !== this.value) this.value = this.field.value;
  }

  projectPosition(parentRect: DOMRect): void {
    const childRect = this.sourceElement.getBoundingClientRect();
    this.projectedPosition = new DOMRect(
      childRect.left - parentRect.left,
      childRect.top - parentRect.top,
      childRect.width,
      0
    );
    this.changeDetector.detectChanges();
  }

  override project(): void {
    this.selectedContainer?.project();
  }

  candidateClick(): void {
    this.focusableContent?.nativeElement?.focus();
  }

  private updateSelectedContainer() {
    const container: PanelComponent | undefined = this.candidates
      .filter((c) => c.value === this.value)
      .map((c) => c.container)[0];
    if (this.selectedContainer !== container) {
      this.selectedContainer = container;
      for (const candidate of this.candidates) {
        candidate.show = candidate.container === container; // && container !== this.emptyContainer;
      }
      this.changeDetector.detectChanges();
      if (this.afterViewInit) this.ownerProvider.provided.projectableContentChange(this);
    }
  }
}
