/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

import BereichKey from './bereich-key';

class SLBereichKey extends BereichKey {

}
export default SLBereichKey;
