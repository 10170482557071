import { BeilageTyp } from './enums/beilage-typ';
import { Religionsunterricht } from './enums/religionsunterricht';
import { Zeugnis } from './zeugnis';

export class BWZeugnis extends Zeugnis {
  hinweise: string | null = null;
  religionsunterricht: Religionsunterricht | null = null;
  beilageTyp: BeilageTyp | null = null;
  gespraechErwuenscht: boolean | null = null;
  fontSizeFactorBeilage: number | null = null;
}
