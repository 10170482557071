import { Directive, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { NodeHelper } from '@modules/dom';

@Directive({ selector: '[fzRichText]' })
export class RichTextDirective implements OnChanges {
  @Input() fzRichText: DocumentFragment = document.createDocumentFragment();
  @Input() dontClone = false;
  private readonly element: Element;
  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
  }

  ngOnChanges(_changes: SimpleChanges): void {
    NodeHelper.empty(this.element);
    if (this.dontClone) {
      this.element.appendChild(this.fzRichText);
    } else {
      this.element.appendChild(this.fzRichText.cloneNode(true));
    }
  }
}
