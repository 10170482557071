/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

import FachKey from './fach-key';

class BWFachKey extends FachKey {
  public static readonly lernen: string = 'lernen';
  public static readonly allgemeineBeurteilung: string = 'allgemeineBeurteilung';
  public static readonly fremdsprache: string = 'fremdsprache';
}
export default BWFachKey;
