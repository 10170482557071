import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IntelliDescription } from '../../../models/fields/intelli-field';
import { TbEditorTableComponent } from '../textbaustein/tb-editor-table/tb-editor-table.component';

@Component({
  selector: 'fz-textbaustein-editor',
  templateUrl: './textbaustein-editor.component.html',
  styleUrls: ['./textbaustein-editor.component.scss'],
})
export class TextbausteinEditorComponent {
  @Input() appendTo: any = 'body';
  @Output() itemsChanged: EventEmitter<boolean> = new EventEmitter(true);

  @ViewChild('editor') editor: TbEditorTableComponent | undefined;

  dialogVisible: boolean = false;

  async ok() {
    await this.editor?.save();
    this.dialogVisible = false;
  }

  cancel(): void {
    this.dialogVisible = false;
  }

  show(intelliDescriptions: IntelliDescription<any>[]) {
    this.dialogVisible = true;

    if (this.editor != null) {
      this.editor.loadData(intelliDescriptions.map((d) => d.textbausteinKey));
    }
  }

  tbSaved(event: boolean) {
    if (event === true) {
      this.itemsChanged.emit(true);
    }
  }
}
