import { AppInfoService } from '../../shared/services';
import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'fz-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.scss'],
})
export class ReportingPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Auswertungen'));
  }
}
