import _ from 'lodash-es';

export interface ListIndexable {
  key: string | null;
  index: number;
}

export type IndexableConstructor<TItem, TParent> = new (key: string | null, parent: TParent) => TItem;

export class IndexedList<TItem extends ListIndexable, TParent> implements Iterable<TItem> {
  *[Symbol.iterator](): Generator<TItem, void, unknown> {
    for (const item of this.items) yield item;
  }

  constructor(
    private readonly type: IndexableConstructor<TItem, TParent>,
    public readonly key: string | null,
    public readonly parent: TParent,
    private readonly items: TItem[] = []
  ) {}

  first(): TItem {
    return this.getIndex(0);
  }

  getIndex(index: number): TItem {
    return (this.items[index] ??= this.createIndex(index));
  }

  addNew(): TItem {
    const item = new this.type(this.key, this.parent);
    this.add(item);
    return item;
  }

  add(item: TItem): void {
    if (item.key !== this.key) throw new Error('key of item does not match');
    this.items.push(item);
    this.updateIndex();
  }

  remove(item: TItem): void {
    this.items.splice(this.items.indexOf(item), 1);
    this.updateIndex();
  }

  clear(): void {
    this.items.splice(0, this.items.length);
  }

  private createIndex(index: number): TItem {
    const item = new this.type(this.key, this.parent);
    item.index = index;
    return item;
  }

  private updateIndex(): void {
    this.items.forEach((i, index) => (i.index = index));
  }
}

export class ItemListIndex<TItem extends ListIndexable, TParent> implements Iterable<TItem> {
  private lists = new Map<string | null, IndexedList<TItem, TParent>>();

  *[Symbol.iterator](): Generator<TItem, void, unknown> {
    for (const list of this.lists.values()) {
      for (const item of list) yield item;
    }
  }

  constructor(
    private readonly type: IndexableConstructor<TItem, TParent>,
    private readonly parent: TParent,
    readonly items: TItem[] = []
  ) {
    for (const item of items) {
      if (!this.lists.has(item.key)) this.lists.set(item.key, new IndexedList(type, item.key, parent));
      this.lists.get(item.key)?.add(item);
    }
  }

  getItemList(key: string | null): IndexedList<TItem, TParent> {
    let list = this.lists.get(key);
    if (list == null) {
      list = new IndexedList(this.type, key, this.parent);
      this.lists.set(key, list);
    }
    return list;
  }

  getItem(key: string | null): TItem {
    return this.getItemList(key).first();
  }

  static toDto<TItem extends ListIndexable, TParent>(
    type: IndexableConstructor<TItem, TParent> & { toDto(item: TItem): unknown },
    index: ItemListIndex<TItem, TParent>
  ): unknown[] {
    return Array.from(index)
      .filter((item) => {
        const emptyItem = new type(item.key, index.parent);
        emptyItem.index = item.index;
        const dto = type.toDto(item);
        const emptyDto = type.toDto(emptyItem);
        return !_.isEqual(dto, emptyDto);
      })
      .map((item) => type.toDto(item));
  }

  static fromDto<TItem extends ListIndexable, TParent>(
    type: IndexableConstructor<TItem, TParent> & { fromDto(dto: unknown, parent: TParent): TItem },
    dtos: any[],
    parent: TParent
  ): ItemListIndex<TItem, TParent> {
    return new ItemListIndex(
      type,
      parent,
      (dtos ?? []).map((dto) => type.fromDto(dto, parent))
    );
  }
}
