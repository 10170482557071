import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartModule } from 'primeng/chart';

import { ReportGeschlechterComponent } from './report-geschlechter/report-geschlechter.component';
import { ReportFormulartypenComponent } from './report-formulartypen/report-formulartypen.component';
import { ReportNotenschnittComponent } from './report-notenschnitt/report-notenschnitt.component';
import { ReportNotenFaecherComponent } from './report-noten-faecher/report-noten-faecher.component';
import { ReportNotenFaecher1Component } from './report-noten-faecher-1/report-noten-faecher-1.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ReportGeschlechterComponent,
    ReportFormulartypenComponent,
    ReportNotenschnittComponent,
    ReportNotenFaecherComponent,
    ReportNotenFaecher1Component,
  ],
  imports: [CommonModule, ChartModule, DropdownModule, FormsModule],
  exports: [
    ReportGeschlechterComponent,
    ReportFormulartypenComponent,
    ReportNotenschnittComponent,
    ReportNotenFaecherComponent,
    ReportNotenFaecher1Component,
  ],
})
export class ReportingModule {}
