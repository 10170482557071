import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { FormularTyp } from '../../../models/enums/formular-typ';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-formular-typ',
  templateUrl: 'sl-formular-typ.component.html',
})
export class SLFormularTypComponent {
  constructor(private formularService: FormularService<SLFormular>) {}

  get formular(): SLFormular {
    return this.formularService.formular;
  }

  get formularTyp(): FormularTyp {
    if (this.formular.zeugnis.zeugnissatz.halbjahr === 1) {
      if (this.formular.zeugnis.formularTyp !== 'Regel') return 'Verbal';
      else return 'Regel';
    } else {
      if (this.formular.zeugnis.formularTyp == null) return 'Kein';
      else return this.formular.zeugnis.formularTyp;
    }
  }
  set formularTyp(value: FormularTyp) {
    this.formular.zeugnis.formularTyp = value;
  }
}
