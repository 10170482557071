<p-table
  [value]="jahrgaenge"
  sortMode="multiple"
  [multiSortMeta]="[
    { field: 'currentSchuljahrHalbjahr', order: -1 },
    { field: 'currentKlassenbezeichnung', order: -1 },
  ]"
  class="flex-grow h-0 overflow-auto"
  [scrollable]="true"
  scrollDirection="both"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex p-ai-center p-jc-between">Bitte wählen Sie den zu öffnenden Jahrgang aus</div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width: 10rem" scope="col" pSortableColumn="currentSchuljahrHalbjahr">
        <div class="flex">Schuljahr <p-sortIcon class="flex" field="currentSchuljahrHalbjahr"></p-sortIcon></div>
      </th>
      <th scope="col" style="min-width: 10rem" pSortableColumn="currentKlassenbezeichnung">
        <div class="flex">Klassenbez. <p-sortIcon class="flex" field="currentKlassenbezeichnung"></p-sortIcon></div>
      </th>
      <th scope="col" style="min-width: 10rem" pSortableColumn="rufname">
        <div class="flex">Rufname <p-sortIcon class="flex" field="rufname"></p-sortIcon></div>
      </th>
      <th scope="col" style="min-width: 12rem" pSortableColumn="currentKlassenleitung">
        <div class="flex">Klassenleitung <p-sortIcon class="flex" field="currentKlassenleitung"></p-sortIcon></div>
      </th>
      <th scope="col" style="min-width: 10rem" pSortableColumn="kundennummer">
        <div class="flex">Kundennr<p-sortIcon class="flex" field="kundennummer"></p-sortIcon></div>
      </th>
      <th scope="col" style="min-width: 10rem" pSortableColumn="lastUpdate">
        <div class="flex">Letzte Änderung <p-sortIcon class="flex" field="lastUpdate"></p-sortIcon></div>
      </th>
      <th scope="col" style="min-width: 220px"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-jahrgang>
    <tr>
      <td style="min-width: 10rem">
        {{ jahrgang.currentSchuljahrHalbjahr }}
      </td>
      <td style="min-width: 10rem">{{ jahrgang.currentKlassenbezeichnung }}</td>
      <td style="min-width: 10rem">{{ jahrgang.rufname }}</td>
      <td style="min-width: 12rem">{{ jahrgang.currentKlassenleitung }}</td>
      <td style="min-width: 10rem">{{ jahrgang.kundennummer }}</td>
      <td style="text-align: right; min-width: 10rem">{{ jahrgang.lastUpdate | date: 'mediumDate' }}</td>
      <td class="flex justify-end" style="min-width: 220px">
        <div class="fz-button-group">
          <button
            type="button"
            pButton
            icon="pi pi-check"
            (click)="openJahrgang(jahrgang.id)"
            label="Öffnen"
            [disabled]="loading"
          ></button>
          <button
            type="button"
            pButton
            icon="pi pi-trash"
            class="p-button-rounded p-button-outlined p-button-danger"
            (click)="deleteJahrgang(jahrgang.id)"
            [disabled]="loading"
          ></button>
          <button
            type="button"
            pButton
            icon="pi pi-pencil"
            class="p-button-rounded p-button-outlined p-button-warning"
            (click)="editJahrgang(jahrgang.id)"
            [disabled]="loading"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
