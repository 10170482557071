import { SprachenportfolioTyp } from '../../../models/enums/sprachenportfolio-typ';
import { PropertyField } from '../../../models/fields/property-field';
import { RPZeugnis } from '../../../models/rp-zeugnis';

export class SprachenportfolioField extends PropertyField<RPZeugnis, SprachenportfolioTyp | null> {
  constructor(private zeugnis: RPZeugnis) {
    super(
      {
        label: 'Sprachenportfolio',
        labelShort: 'SprPort',
        required: false,
        property: 'sprachenportfolio',
      },
      { object: zeugnis }
    );
  }
  get value(): SprachenportfolioTyp | null {
    return this.zeugnis.sprachenportfolio ?? 'FranzEng';
  }
  set value(value: SprachenportfolioTyp | null) {
    if (value === 'FranzEng') this.zeugnis.sprachenportfolio = null;
    else this.zeugnis.sprachenportfolio = value;
  }
}
