<div class="flex flex-col gap-2 pt-5">
  <div class="flex flex-row justify-between items-center">
    <div *ngIf="currentIndex < availableColums.length">
      Bitte wählen Sie die Spalte für <em>{{ currentColumn }}</em> durch Klicken des Buttons 'Auswählen' im
      entsprechenden Spaltenkopf.
    </div>
    <div *ngIf="currentIndex >= availableColums.length">Alle Spalten sind zugeordnet.</div>
    <div class="flex flex-row gap-2">
      <button pButton (click)="restart()" class="w-40" icon="pi pi-refresh" label="Neu bestimmen"></button>
      <button
        pButton
        (click)="skip()"
        class="w-40"
        label="Überspringen"
        icon="pi pi-forward"
        [disabled]="currentIndex >= availableColums.length"
      ></button>
    </div>
  </div>
  <div>
    <p-table
      styleClass="p-datatable-sm"
      [value]="lines"
      *ngIf="lines && lines.length > 0"
      sortMode="single"
      sortField="name"
      [scrollable]="true"
      scrollHeight="300px"
    >
      <ng-template pTemplate="header" let-rowData>
        <tr>
          <th scope="col" *ngFor="let key of Object.keys(lines[0]); let i = index">
            <button
              pButton
              [label]="selectedHeaders[i] != null ? selectedHeaders[i] : 'Auswählen'"
              [disabled]="selectedHeaders[i] != null || currentIndex >= availableColums.length"
              (click)="select(i)"
            ></button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td *ngFor="let key of Object.keys(rowData)">
            {{ schuelerlisteService.getDisplayValue(rowData[key]) }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="fz-button-group pt-2">
      <button pButton (click)="back.emit()" class="w-40" icon="pi pi-caret-left" label="Zurück"></button>
      <button
        pButton
        (click)="continue()"
        class="w-40"
        icon="pi pi-caret-right"
        label="Weiter"
        [disabled]="currentIndex < availableColums.length"
      ></button>
    </div>
  </div>
</div>
