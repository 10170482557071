import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NIPanelDecoratorBase } from './ni-panel-decorator-base';
import { NIFormular } from '../formulare/ni-formular';

@Component({
  selector: 'fz-ni-leistungen',
  templateUrl: 'ni-leistungen.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, NILeistungenComponent)],
})
export class NILeistungenComponent extends NIPanelDecoratorBase {
  static createModel(formular: NIFormular) {
    return {
      deutsch: {
        label: 'Deutsch',
        noteField: formular.deutsch.gesamt.note,
      },
      fremdsprache: {
        label: 'Fremdsprache',
        labelDropdownField: formular.sprachenportfolioSprache,
        noteField: formular.fremdsprache.gesamt.note,
      },
      sachunterricht: {
        label: 'Sachunterricht',
        noteField: formular.sachunterricht.gesamt.note,
      },
      religion: {
        label: 'Religion',
        noteField: formular.religion.gesamt.note,
      },
      mathematik: {
        label: 'Mathematik',
        noteField: formular.mathematik.gesamt.note,
      },
      musik: {
        label: 'Musik',
        noteField: formular.musik.gesamt.note,
      },
      bildendeKunst: {
        label: 'Kunst',
        noteField: formular.bildendeKunst.gesamt.note,
      },
      werken: {
        label: 'Gestaltendes Werken',
        noteField: formular.werken.gesamt.note,
      },
      gestalten: {
        label: 'Textiles Gestalten',
        noteField: formular.gestalten.gesamt.note,
      },
      sport: {
        label: 'Sport',
        noteField: formular.sport.gesamt.note,
      },
      herkunftssprache: formular.herkunftssprache.gesamt.isVisibleOverrideDefaultTrue
        ? {
            label: 'Herkunftssprache',
            labelTextField: formular.herkunftssprache.gesamt.label,
            noteField: formular.herkunftssprache.gesamt.note,
          }
        : undefined,
    };
  }

  @Input() model: ReturnType<typeof NILeistungenComponent.createModel> | undefined;
}
