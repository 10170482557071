import { Component, Output, EventEmitter, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-export-select-target',
  templateUrl: './export-select-target.component.html',
  styleUrls: ['./export-select-target.component.scss'],
})
export class ExportSelectTargetComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Input() loading: boolean = false;
  @Output() targetSelected: EventEmitter<string> = new EventEmitter<string>();

  internet(): void {
    this.targetSelected.next('internet');
  }

  manuell(): void {
    this.targetSelected.next('manuell');
  }
}
