<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="fz-paragraph-text">
    <p *ngIf="exporttyp == 'manuell'">Die Textbausteine wurden erfolgreich exportiert.</p>
    <p *ngIf="exporttyp != 'manuell'">Die Textbausteine wurden erfolgreich auf dem Server bereitgestellt.</p>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Zur Zeugnisübersicht"
        class="w-64"
        icon="pi pi-home"
        (click)="navigateToZeugnisse()"
      ></button>
    </div>
  </ng-template>
</p-panel>
