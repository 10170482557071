import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Table } from 'primeng/table';
import { TreeNodeSelectEvent } from 'primeng/tree';
import BenutzerTextbaustein from '../../../../models/generated/benutzer-textbaustein';
import { FlinkyAuthService } from '../../../../shared/services/auth/flinky-auth.service';
import { IntelliTextService } from '../../../../shared/services/intelliText.service';
import { IntelliTextItem } from '../../intellibox/intelliText';
import { FachKategorie, FachSearcher } from '../tb-editor/fach-searcher';
import { TextbausteinService } from '../tb-editor/textbaustein.service';

@Component({
  selector: 'fz-tb-editor-table',
  templateUrl: './tb-editor-table.component.html',
  styleUrls: ['./tb-editor-table.component.scss'],
})
export class TbEditorTableComponent {
  loading: boolean = false;
  fachKategorien: FachKategorie[] = [];

  pasteDialogVisible: boolean = false;
  pasteText: string = '';
  pastedItems: IntelliTextItem[] = [];
  @Output() tbSaved = new EventEmitter(false);
  @ViewChild('tbEditorTable') table: Table | undefined;

  geschlechtOptions = [
    { label: 'Männlich', value: 'm' },
    { label: 'Weiblich', value: 'w' },
    { label: 'Divers', value: 'd' },
    { label: 'neutral', value: 'n' },
  ];
  tableHeight: string = '250px';
  tableScrollHeight: string = '250px';

  selectedFach: BenutzerTextbaustein | undefined;

  faecherTree: TreeNode[] = [];
  selectedFachNode: any | null = null;

  currentField: any = null;
  currentItem: IntelliTextItem | null = null;

  importAsOne: boolean = false;

  constructor(
    private authService: FlinkyAuthService,
    private tbService: TextbausteinService,
    private intelliService: IntelliTextService
  ) {}

  async loadData(kategorien: (string | undefined)[] | null = null) {
    this.selectedFachNode = null;
    const fachKategorien = [];
    this.fachKategorien = [];
    try {
      this.loading = true;
      const tbs = await this.tbService.getAll();

      if (this.authService.lizenz?.bundesland != null) {
        const faecher = FachSearcher.getFaecher(this.authService.lizenz?.bundesland).sort((a, b) =>
          a.key > b.key ? 1 : -1
        );

        const groups = [...new Set(faecher.map((f) => f.group))];
        const treeItems: TreeNode[] = [];

        groups.forEach((g) => {
          const filtered = faecher.filter((f) => f.group === g);
          const fachNode = { label: filtered.length > 1 ? g : filtered[0].label, children: [] } as TreeNode;
          if (filtered.length > 1) {
            filtered.forEach((fk) => {
              const fachChildNode = { label: fk.label, children: [], data: fk } as TreeNode;
              if (kategorien?.includes(fk.key) || kategorien == null) {
                fachNode.children?.push(fachChildNode);
              }
            });
            if ((fachNode.children != null && fachNode.children.length > 0) || kategorien == null) {
              treeItems.push(fachNode);
              if (kategorien != null) {
                fachNode.expanded = true;
              }
            }
          } else {
            fachNode.data = filtered[0];
            if (kategorien?.includes(filtered[0].key) || kategorien == null) {
              treeItems.push(fachNode);
            }
          }
        });

        this.faecherTree = treeItems;

        for (const fach of faecher) {
          let tb = tbs.find((el) => el.feld === `Textbausteine-${fach.key}.txt`);
          if (tb != null) {
            for (const tbEl of tb.inhalt.split('\r\n')) {
              if (tbEl.trim() !== '') {
                if (fach.items == null) fach.items = [];
                fach.items?.push(this.intelliService.convertStringToIntelliTextItem(tbEl, false));
              }
            }
          } else {
            tb = { feld: `Textbausteine-${fach.key}.txt`, inhalt: '' } as BenutzerTextbaustein;
          }
          fach.tb = tb;
          fachKategorien.push(fach);
        }
        this.fachKategorien = fachKategorien;
      }
    } finally {
      this.loading = false;
    }
  }

  addTb() {
    if (this.selectedFachNode?.data?.tb != null) {
      const newItem = {
        geschlecht: 'n',
        note: 'k.A.',
        text: 'Neuer Eintrag mit {Name} als Platzhalter für den Vornamen des Schülers.',
        id: Math.floor(Math.random() * 10000),
      } as IntelliTextItem;
      if (this.selectedFachNode.data.items == null) {
        this.selectedFachNode.data.items = [];
      }
      this.selectedFachNode.data.items = [...this.selectedFachNode.data.items, newItem];
      setTimeout(() => {
        this.table?.scrollTo({ top: 1000000 });
        const el = document.getElementById((newItem.id ?? '').toString());
        el?.focus();
        (el as any)?.setSelectionRange(0, newItem.text.length);
      });
    }
  }

  async save() {
    let saved = false;
    const currentCategory = this.selectedFachNode?.data.key;
    try {
      this.loading = true;
      for (const fachKategorie of this.fachKategorien) {
        const textbaustein = this.intelliService.convertToOldTextbausteine(fachKategorie.items ?? []);
        // vergleich ob alt = neu
        if (fachKategorie.tb?.inhalt !== textbaustein) {
          await this.tbService.saveTextbaustein(textbaustein, fachKategorie.key);
          await this.tbService.loadTb();
          saved = true;
          // if (fachKategorie.tb != null) fachKategorie.tb.inhalt = textbaustein;
        }
      }
    } finally {
      await this.loadData();
      // selektierte Kategorie wieder selektieren
      this.faecherTree.forEach((f) => {
        this.setSelectedRecursive(f, currentCategory);
      });
      this.loading = false;
    }
    if (saved === true) {
      this.tbSaved.emit(true);
    }
  }

  private setSelectedRecursive(node: TreeNode, keyToSelect: string) {
    if (node.data?.key === keyToSelect) {
      this.selectedFachNode = node;
    } else if (node.children) {
      node.children.forEach((childNode) => {
        this.setSelectedRecursive(childNode, keyToSelect);
      });
    }
  }

  isDirty() {
    let dirty = false;
    for (const fachKategorie of this.fachKategorien) {
      if (fachKategorie.tb?.inhalt !== this.intelliService.convertToOldTextbausteine(fachKategorie.items ?? [])) {
        dirty = true;
      }
    }
    return dirty;
  }

  deleteTb(item: IntelliTextItem) {
    if (this.selectedFachNode?.data.items != null) {
      const indx = this.selectedFachNode.data.items.indexOf(item);
      this.selectedFachNode.data.items.splice(indx, 1);
    }
  }

  pasteTbOpen() {
    this.reset();
    this.pasteDialogVisible = true;
  }

  reset() {
    this.pasteText = '';
    this.pastedItems = [];
  }

  pasted(event: any) {
    this.pasteText = event.clipboardData.getData('text');
    this.convert();
  }

  convert() {
    if (this.selectedFachNode?.data?.tb != null) {
      let splitted: string[] = [];
      if (!this.importAsOne) {
        splitted = this.pasteText.split('\n');
      } else {
        splitted = [this.pasteText];
      }

      for (const splitItem of splitted) {
        const exists = this.selectedFachNode.data.items?.find((i: any) => i.text.trim() === splitItem.trim());
        if (exists == null && splitItem.trim().length > 0) {
          const newItem = {
            geschlecht: 'n',
            note: 'k.A.',
            text: splitItem.trim(),
            id: null,
          } as IntelliTextItem;

          this.pastedItems.push(newItem);
        }
      }
      this.pastedItems = this.pastedItems.slice(0);
    }
  }

  paste() {
    if (this.selectedFachNode?.data?.tb != null) {
      for (const pastedItem of this.pastedItems) {
        // in den tree einfügen

        const fachKategorie = this.fachKategorien.find((el) => el.key === this.selectedFachNode?.data?.key);
        if (fachKategorie != null) {
          if (fachKategorie.items == null) fachKategorie.items = [];
          fachKategorie.items.push(pastedItem);
        }
      }
      this.fachKategorien = this.fachKategorien.slice(0);

      this.pasteDialogVisible = false;
    }
  }

  deletePastedTb(el: IntelliTextItem) {
    const indx = this.pastedItems.indexOf(el);

    this.pastedItems.splice(indx, 1);
    this.pastedItems = this.pastedItems.slice(0);
    if (this.pastedItems.length === 0) this.pasteText = '';
  }

  expandAll() {
    this.faecherTree.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  collapseAll() {
    this.faecherTree.forEach((node) => {
      this.expandRecursive(node, false);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  onFachNodeSelect(event: TreeNodeSelectEvent) {
    if (
      event.node != null &&
      event.node.children != null &&
      event.node.children.length > 0 &&
      event.node.children[0] != null
    ) {
      event.node.expanded = true;
      this.selectedFachNode = event.node.children[0];
    }
    event.originalEvent.stopPropagation();
    return false;
  }

  fieldFocused(event: FocusEvent, item: IntelliTextItem) {
    this.currentField = event.target;
    this.currentItem = item;
    this.currentField.setSelectionRange(0, this.currentField.value.length);
  }

  get isTbFieldActive() {
    return (document.activeElement as any).name === 'tbField';
  }

  insertNameToTextField() {
    if (this.currentField.name === 'tbField' && this.currentItem != null) {
      try {
        const insertAt = this.currentField.selectionStart;
        const selectionEnd = this.currentField.selectionEnd;
        const currentValue = this.currentItem.text;
        let newValue = currentValue.slice(0, insertAt) + currentValue.slice(selectionEnd);
        newValue = newValue.slice(0, insertAt) + '{Name}' + newValue.slice(insertAt);
        this.currentItem.text = newValue;
        setTimeout(() => {
          this.currentField.focus();
          this.currentField.setSelectionRange(insertAt + 6, insertAt + 6);
        });

        // this.currentField.value = newValue;
        // this.currentField.dispatchEvent(new Event('input'));
        // setTimeout(() => {
        // });
      } catch {
        /* ignorieren */
      }
    }
  }
}
