import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { AppInfoService } from '../../shared/services';

@Component({
  templateUrl: './noteneingabeliste-page.component.html',
  styleUrls: ['./noteneingabeliste-page.component.scss'],
})
export class NoteneingabelistePageComponent implements AfterViewInit {
  @ViewChildren(PrimeTemplate) templates = new QueryList<PrimeTemplate>();

  constructor(private appInfoService: AppInfoService) {}

  ngAfterViewInit(): void {
    this.appInfoService.currentPageTitleTemplate = this.templates.find((t) => t.name === 'currentPageTitle')?.template;
  }
}
