<ng-template #header>
  <p-steps class="fz-steps-transparent w-full p-2" [model]="steps" [(activeIndex)]="activeStep"></p-steps>
</ng-template>
<div class="flex-grow h-0 overflow-auto">
  <fz-select-kundennummer
    *ngIf="activeStep === 0"
    (kundennummerChecked)="activeStep = 1"
    class="flex-grow h-0 overflow-auto"
    [header]="header"
    [showCancel]="false"
  >
  </fz-select-kundennummer>
  <form *ngIf="activeStep === 1" [formGroup]="jahrgangform" (ngSubmit)="onSubmit()">
    <p-panel>
      <ng-template pTemplate="header">
        <ng-container [ngTemplateOutlet]="header"></ng-container>
      </ng-template>
      <div class="p-fluid p-formgrid p-grid w-full">
        <div class="p-field p-col-12 p-md-6">
          <label for="lizenz">Lizenz*</label>
          <div class="flex w-full gap-2">
            <img src="{{ lizenzImage }}" alt="Zeugniskopf {{ this.authService.lizenz?.kundennummer }}" />
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6"></div>

        <div class="p-field p-col-12 p-md-6">
          <label for="schuljahr">Schuljahr*</label>
          <input pInputText [value]="this.zeugnissatz?.schuljahr" formControlName="schuljahr" />
          <div
            *ngIf="jahrgangform.get('schuljahr')?.invalid && jahrgangform.get('schuljahr')?.dirty"
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('schuljahr')?.errors?.['required']">Schuljahr wird benötigt</div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6"></div>
        <div class="p-field p-col-12 p-md-6">
          <label for="zeugnis">Zeugnisformular</label>
          <input pInputText [value]="this.zsVm?.zeugnisTypLabel" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-6"></div>
        <div class="p-field p-col-12 p-md-6">
          <label for="klassenleitung">Klassenleitung*</label>
          <input id="klassenleitung" type="text" pInputText formControlName="klassenleitung" />
          <div
            *ngIf="jahrgangform.get('klassenleitung')?.invalid && jahrgangform.get('klassenleitung')?.dirty"
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('klassenleitung')?.errors?.['required']">Klassenleitung wird benötigt</div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="schulleitung">Schulleitung*</label>
          <input id="schulleitung" type="text" pInputText formControlName="schulleitung" />
          <div
            *ngIf="jahrgangform.get('schulleitung')?.invalid && jahrgangform.get('schulleitung')?.dirty"
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('schulleitung')?.errors?.['required']">Schulleitung wird benötigt</div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="klassenbezeichnung">Klassenbezeichnung* </label>
          <fz-help-info [key]="'jahrgangKlassenbezeichnung'"></fz-help-info>
          <input id="klassenbezeichnung" type="text" pInputText formControlName="klassenbezeichnung" />
          <div
            *ngIf="jahrgangform.get('klassenbezeichnung')?.invalid && jahrgangform.get('klassenbezeichnung')?.dirty"
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('klassenbezeichnung')?.errors?.['required']">
              Klassenbezeichnung wird benötigt
            </div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="rufname">Rufname der Klasse/des Jahrgangs</label>
          <fz-help-info [key]="'jahrgangRufname'"></fz-help-info>
          <input id="rufname" type="text" pInputText formControlName="rufname" />
        </div>
        <div
          class="p-field p-col-12 p-md-12 fz-validation-error"
          *ngIf="jahrgangform.invalid && jahrgangform.errors?.['uniqueJahrgang']"
        >
          <div *ngIf="jahrgangform.errors?.['uniqueJahrgang']">
            Es existiert bereits ein Jahrgang/Zeugnissatz mit diesen Daten!
          </div>
        </div>
        <!-- <div
          class="p-field p-col-12 p-md-6 fz-validation-error"
          *ngIf="jahrgangform.invalid && jahrgangform.errors?.uniqueJahrgang"
        >
          <button pButton type="button" label="Diesen Zeugnissatz öffnen" (click)="openExistingZeugnissatz()"></button>
        </div> -->
      </div>
      <ng-template pTemplate="footer">
        <div class="fz-button-group">
          <button
            pButton
            [loading]="isLoading"
            label="Abbrechen"
            icon="pi pi-times"
            type="button"
            class="w-64 p-button-secondary"
            (click)="cancel()"
            [disabled]="isLoading"
          ></button>
          <button
            pButton
            [loading]="isLoading"
            label="Speichern"
            class="w-64"
            icon="pi pi-check"
            type="submit"
            [disabled]="!jahrgangform.valid || isLoading"
          ></button>
        </div>
      </ng-template>
    </p-panel>
  </form>
</div>
