import { BundeslandTyp } from './enums/bundesland-typ';
import { Zeugniskopflizenz } from './zeugniskopflizenz';
import { Schullizenz as SchullizenzDto } from './generated/schullizenz';
import { LizenzScope } from './generated/lizenz-scope';
import { Helper } from './helper';
import _ from 'lodash-es';

export class Schullizenz {
  bundesland: BundeslandTyp = 'SL';
  zeugniskopflizenzen: Zeugniskopflizenz[] = [];
  contentVersion: number = 0;

  constructor(public readonly kundennummer: string) {}

  public findZeugniskopflizenz(
    zeugnissatz:
      | {
          schuljahr: number | undefined;
          halbjahr: number | undefined;
        }
      | undefined
  ): Zeugniskopflizenz {
    const schuljahr = zeugnissatz?.schuljahr ?? 2100;
    const halbjahr = zeugnissatz?.halbjahr ?? 1;
    return _.orderBy(this.zeugniskopflizenzen, [(zk) => zk.schuljahr, (zk) => zk.halbjahr], ['desc', 'desc']).filter(
      (l) => Helper.getSchuljahrHalbjahr(l) <= Helper.getSchuljahrHalbjahr({ schuljahr, halbjahr })
    )[0];
  }

  static cleanDto(dto: any): any {
    const { kundennummer, zeugniskopflizenz, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(schullizenz: Schullizenz): SchullizenzDto {
    return {
      ...Schullizenz.cleanDto(schullizenz),
      kundennummer: schullizenz.kundennummer,
    };
  }

  static fromDto(dto: SchullizenzDto): Schullizenz {
    const schullizenz = new Schullizenz(dto.kundennummer);
    return Object.assign(schullizenz, this.cleanDto(dto));
  }

  static fromScope(scope: LizenzScope): Schullizenz[] {
    const schullizenzen = scope.schullizenzen.map((dto) => Schullizenz.fromDto(dto));
    for (const schullizenz of schullizenzen) {
      for (const zeugniskopflizenzDto of scope.zeugniskopflizenzen.filter(
        (zl) => zl.kundennummer === schullizenz.kundennummer
      )) {
        Zeugniskopflizenz.fromDto(zeugniskopflizenzDto, schullizenz);
      }
    }
    return schullizenzen;
  }
}
