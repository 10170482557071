/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { RPZeugnisFormular } from './rp-zeugnis-formular';
import { RPZeugnissatzComponent } from '../projectables/rp-zeugnissatz.component';
import { RPSchuelerComponent } from '../projectables/rp-schueler.component';
import { RPSpsComponent } from '../projectables/rp-sps.component';
import { RPFachHeaderComponent } from '../projectables/rp-fach-header.component';
import { RPSprachenportfolioComponent } from '../projectables/rp-sprachenportfolio.component';
import { RPVersaeumnisComponent } from '../projectables/rp-versaeumnis.component';
import { RPSchulortComponent } from '../projectables/rp-schulort.component';
import { RPUnterschriftComponent } from '../projectables/rp-unterschrift.component';
import { FormularViewType } from '../../formular/formular';

export class RP34hStandardFormular extends RPZeugnisFormular {
  get viewType(): FormularViewType {
    return RP34hStandardFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    faecher.push(
      this.religion,
      this.deutsch,
      this.mathematik,
      this.sachunterricht,
      this.musik,
      this.sport,
      this.bildendeKunst
    );
    if (this.herkunftssprache.gesamt.isVisibleOverrideDefaultFalse) faecher.push(this.herkunftssprache);
    if (this.herkunftssprache2.gesamt.isVisibleOverrideDefaultFalse) faecher.push(this.herkunftssprache2);
    return faecher;
  }
  override get noteFields(): NoteField[] {
    return this.faecher.map((f) => f.gesamt).map((b) => b.note);
  }
}

@Component({
  selector: 'fz-rp-34h-standard-formular',
  templateUrl: 'rp-34h-standard-formular.component.html',
})
export class RP34hStandardFormularComponent extends FormularComponentBase<RP34hStandardFormular> {
  static createModel(formular: RP34hStandardFormular) {
    return {
      zeugnissatz: RPZeugnissatzComponent.createModel(formular),
      schueler: RPSchuelerComponent.createModel(formular),
      sps: formular.zeugnis.schuelerTyp === 'Lernbehindert' ? RPSpsComponent.createModel(formular) : undefined,
      sozialLernArbeitsverhalten: {
        headerLabel: formular.sozialLernArbeitsverhalten.gesamt.description.label,
        textField: formular.sozialLernArbeitsverhalten.gesamt.text,
      },
      faecher: formular.faecher.map((fach) => ({
        trackBy: fach.key,
        key: fach.key,
        headerLabel: fach.gesamt.description.label || '',
        header: RPFachHeaderComponent.createModel(fach, formular),
        verbalVisibleField: fach.gesamt.isVisibleOverrideTextDefaultFalseField,
        textField: fach.gesamt.isVisibleOverrideTextDefaultFalse ? fach.gesamt.text : undefined,
      })),
      gespraechsatz:
        formular.zeugnis.zeugnisTyp !== 'Abgang'
          ? {
              selectField: formular.gespraechsatzTyp,
              protokolldatumField: formular.zeugnis.gespraechsatzTyp == null ? formular.protokolldatum : undefined,
            }
          : undefined,
      sprachenportfolio: RPSprachenportfolioComponent.createModel(formular),
      schrift: {
        headerLabel: formular.schrift.gesamt.description.label,
        textField: formular.schrift.gesamt.text,
      },
      versaeumnis: RPVersaeumnisComponent.createModel(formular),
      bemerkungen: {
        headerLabel: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      schulort: RPSchulortComponent.createModel(formular),
      unterschrift: RPUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return RP34hStandardFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<RP34hStandardFormular>) {
    super(formularService);
  }
}
