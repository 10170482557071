import { AfterViewInit, Component } from '@angular/core';
import { AppInfoService } from '../../shared/services';

@Component({
  selector: 'fz-jahrgang-weiterfuehren-page',
  templateUrl: './jahrgang-weiterfuehren-page.component.html',
  styleUrls: ['./jahrgang-weiterfuehren-page.component.scss'],
})
export class JahrgangWeiterfuehrenPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Neues Halbjahr erstellen'));
  }
}
