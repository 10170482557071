import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { VerbalbeurteilungService } from './verbalbeurteilung.service';
import { TreeNode } from 'primeng/api';
import Kategorie from '../../../models/generated/kategorie';
import { IntelliTextService } from '../../services/intelliText.service';
import { IntelliTextOptions } from '../intellibox/intelliTextOptions';
import { TextbausteinEditorComponent } from '../textbaustein-editor/textbaustein-editor.component';
import { NotificationService } from '../../services/notification/notification.service';
import { EigeneTextbausteinDescription } from '../../../models/fields/verbal-field';
import { Geschlecht } from '../../../models/enums/geschlecht';
import { IntelliDescription } from '../../../models/fields/intelli-field';
import { TreeNodeUnSelectEvent } from 'primeng/tree';

enum VerbalSource {
  Vorgegebene = 'Vorgegebene',
  Eigene = 'Eigene',
}

@Component({
  selector: 'fz-verbalbeurteilung',
  templateUrl: './verbalbeurteilung.component.html',
  styleUrls: ['./verbalbeurteilung.component.scss'],
})
export class VerbalbeurteilungComponent {
  @ViewChild(TextbausteinEditorComponent) editor!: TextbausteinEditorComponent;
  @Input() text?: string;
  @Input() kategorien?: string;
  @Input() eigeneTextbausteinDescriptions: EigeneTextbausteinDescription[] = [];
  @Input() klassenStufe: number = 0;
  @Input() geschlecht: Geschlecht | null | 'n' = 'n';
  @Input() schuelerVorname: string | null = '';
  @Input() schuelerName: string | null = '';
  @Output() selectedText: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();
  kategorienTree: TreeNode<Kategorie>[] = [];
  selectedKategorien: any = [];

  selectedEigene: TreeNode<Kategorie>[] = [];

  isSyntax: boolean = false;

  intelliDescription?: IntelliDescription<any>;

  isInitialized: boolean = false;
  initializedName: string | null = null;
  initializedVorname: string | null = null;
  initializedGeschlecht: Geschlecht | null | 'n' = 'n';

  constructor(
    private verbalBeurteilungService: VerbalbeurteilungService,
    private intelliTextService: IntelliTextService,
    private notify: NotificationService
  ) {}

  notenTree: TreeNode<string>[] = [
    { label: '1' },
    { label: '2' },
    { label: '3' },
    { label: '4' },
    { label: '5' },
    { label: 'k.A.' },
  ];
  selectedNoten: any; // TreeNode<string>[] = [];

  beurteilungenTree: TreeNode[] = [];
  selectedBeurteilung?: any;

  dialogVisible: boolean = false;

  loadingKategorien: boolean = false;
  loadingBeurteilungen: boolean = false;
  keineErgebnisseMeldung: string = '';

  init() {
    this.selectedKategorien = [];
    this.selectedEigene = [];
    this.selectedNoten = [];
    this.loadingKategorien = true;
    this.beurteilungenTree = [];

    let childrenVorgebene: TreeNode<Kategorie>[] = [];
    let childrenEigene: TreeNode<Kategorie>[] = [];

    this.kategorienTree = [];
    this.kategorienTree.push({ label: VerbalSource.Vorgegebene, expanded: true });
    this.kategorienTree.push({ label: VerbalSource.Eigene, expanded: true });

    this.verbalBeurteilungService.getKategorienByKategorienSatz(this.kategorien).subscribe(
      (vorgegebene) => {
        childrenVorgebene = [];
        childrenEigene = [];
        vorgegebene.forEach((k) => childrenVorgebene.push({ label: k.bezeichnung, data: k }));

        if (childrenVorgebene.length > 0) {
          const foundVorgebene = this.kategorienTree.find((t) => t.label === VerbalSource.Vorgegebene);
          if (foundVorgebene != null) foundVorgebene.children = childrenVorgebene;
        }

        this.eigeneTextbausteinDescriptions.forEach((item, index) => {
          childrenEigene.push({
            label: item.label,
            data: { id: index, bezeichnung: item.eigeneTextbausteinKey } as Kategorie,
          });
        });
        const foundEigene = this.kategorienTree.find((t) => t.label === VerbalSource.Eigene);
        if (foundEigene != null && childrenEigene.length > 0) foundEigene.children = childrenEigene;

        this.isInitialized = true;
        this.initializedVorname = this.schuelerVorname;
        this.initializedName = this.schuelerName;
        this.initializedGeschlecht = this.geschlecht;
        this.loadingKategorien = false;
        this.keineDatenMeldungUpdate();
      },

      (_err) => {
        this.loadingKategorien = false;
        this.keineDatenMeldungUpdate();
      }
    );
  }

  nodeUnSelectKategorie(_event: TreeNodeUnSelectEvent): void {
    this.selectedEigene = this.selectedKategorien?.filter(
      (k: { parent: { label: VerbalSource } }) => k.parent?.label === VerbalSource.Eigene
    );
    this.updateBeurteilung();
  }
  nodeSelectKategorie(): void {
    this.selectedEigene = this.selectedKategorien?.filter(
      (k: { parent: { label: VerbalSource } }) => k.parent?.label === VerbalSource.Eigene
    );
    this.updateBeurteilung();
  }

  noteChange(_event: any): void {
    this.updateBeurteilung();
  }

  async updateBeurteilung() {
    this.loadingBeurteilungen = true;
    this.selectedBeurteilung = undefined;
    this.beurteilungenTree = [];
    const klassenstufe = this.klassenStufe === 0 ? 1 : this.klassenStufe;

    const noten =
      (this.selectedNoten as TreeNode<string>[])
        .filter((n: any) => n.children == null)
        .map<string>((n: any) => n.label ?? '') ?? [];
    if (this.selectedEigene != null && this.selectedEigene.length > 0) {
      const res = await this.intelliTextService.getEigeneVerbalTexte(
        {
          klassenstufe: klassenstufe ?? null,
          geschlecht: this.geschlecht,
          schuelervorname: this.schuelerVorname,
          schuelername: this.schuelerName,
        } as IntelliTextOptions,
        this.selectedEigene.map((k) => k.data?.bezeichnung)
      );
      res
        .filter((i) => i.note == null || this.verbalBeurteilungService.notenMatch(noten, i.note))
        .forEach((item) => {
          this.beurteilungenTree.push({ label: item.text, data: item, key: 'key_' + item.id });
        });
    }

    const kategorien =
      (this.selectedKategorien as TreeNode<Kategorie>[])
        .filter(
          (k: TreeNode<Kategorie>) =>
            k.children == null && k.label !== VerbalSource.Eigene && k.parent?.label !== VerbalSource.Eigene
        )
        .map<Kategorie>((node: any) => node.data as Kategorie) ?? [];
    if (kategorien != null) {
      this.verbalBeurteilungService
        .getBeurteilungen(kategorien, klassenstufe ?? null, noten, this.geschlecht, this.schuelerVorname)
        .subscribe(
          (res) => {
            res.forEach((b) =>
              this.beurteilungenTree.push({ label: b.label, data: { note: b.note }, key: 'key_' + b.label })
            );

            this.loadingBeurteilungen = false;
            this.keineDatenMeldungUpdate();
          },
          (_err) => {
            this.loadingBeurteilungen = false;
            this.keineDatenMeldungUpdate();
          }
        );
    } else {
      this.loadingBeurteilungen = false;
      this.keineDatenMeldungUpdate();
    }
  }

  keineDatenMeldungUpdate(): void {
    if (
      this.selectedKategorien?.find((k: any) => k.label === VerbalSource.Eigene) == null ||
      this.selectedKategorien?.length > 1
    ) {
      this.keineErgebnisseMeldung = 'Keine Ergebnisse: ';
      if (this.schuelerVorname == null || this.schuelerVorname === '') this.keineErgebnisseMeldung += 'Vorname fehlt, ';
      if (this.geschlecht == null) this.keineErgebnisseMeldung += 'Kein Geschlecht gewählt, ';
      if (this.selectedKategorien == null || this.selectedKategorien?.length === 0)
        this.keineErgebnisseMeldung += 'Keine Kategorie gewählt, ';
      if (this.selectedNoten == null || this.selectedNoten?.length === 0)
        this.keineErgebnisseMeldung += 'Keine Note gewählt, ';
      this.keineErgebnisseMeldung = this.keineErgebnisseMeldung.substring(0, this.keineErgebnisseMeldung.length - 2);
    } else {
      this.keineErgebnisseMeldung = 'Es sind keine eigenen Textbausteine zu diesem Bereich vorhanden';
    }
  }
  show(): void {
    this.dialogVisible = true;
    if (
      !this.isInitialized ||
      this.schuelerVorname !== this.initializedVorname ||
      this.schuelerName !== this.initializedName ||
      this.geschlecht !== this.initializedGeschlecht
    ) {
      this.init();
    } else {
      this.keineDatenMeldungUpdate();
    }
  }

  edit(): void {
    const intelliDescriptions: IntelliDescription<any>[] = [];
    this.eigeneTextbausteinDescriptions.forEach((d) => {
      const desc = {
        label: d.label,
        labelShort: d.labelShort,
        textbausteinKey: d.eigeneTextbausteinKey,
      } as IntelliDescription<any>;
      intelliDescriptions.push(desc);
    });

    this.editor.show(intelliDescriptions);
  }
  onItemsChangedTextbausteine(event: boolean): void {
    if (event === true) {
      const foundEigene = this.kategorienTree.find((t) => t.label === VerbalSource.Eigene);

      this.selectedKategorien = [];
      if (foundEigene != null) {
        this.selectRecursiveKategorie(foundEigene);
      }
      this.selectAll();
    }
  }

  private selectRecursiveKategorie(node: TreeNode) {
    this.selectedKategorien?.push(node);
    if (node.children) {
      node.children.forEach((childNode) => {
        this.selectRecursiveKategorie(childNode);
      });
    }
  }

  cancel(): void {
    this.dialogVisible = false;
  }

  textSelected(event: MouseEvent) {
    if (!(event.target instanceof HTMLInputElement)) {
      this.selectedText.next(this.selectedBeurteilung?.label);
    }
  }

  getColor(note: string | number | null) {
    note = note ?? '';
    switch (note.toString()) {
      case '1':
        return 'note-1';
      case '2':
        return 'note-2';
      case '3':
        return 'note-3';
      case '4':
        return 'note-4';
      case '5':
        return 'note-5';
      case 'k.a.':
        return 'note-ka';
      default:
        return 'note-default';
    }
  }

  selectAll() {
    this.selectedNoten = [];
    this.notenTree.forEach((node) => {
      this.selectedNoten?.push(node);
    });
    this.nodeSelectKategorie();
  }

  selectNone() {
    this.selectedNoten = [];
    this.nodeSelectKategorie();
  }
}
