import { PropertyField, PropertyFieldDescription } from './property-field';
import { OnlyPropsOfType } from './types';

export class TextField<
  T,
  TDesc extends PropertyFieldDescription<T, string | null> = PropertyFieldDescription<T, string | null>,
> extends PropertyField<T, string | null, TDesc> {
  get value(): string | null {
    return this.propertyValue;
  }
  set value(value: string | null) {
    this.propertyValue = value;
  }
  override get isEmpty(): boolean {
    return super.isEmpty || this.value === '';
  }
  get fieldKey(): keyof OnlyPropsOfType<T, string | null> {
    return this.description.property;
  }
}
