<fz-panel
  *ngIf="model != null"
  name="Zeugnissatz"
  [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
  padding="normal"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div style="text-align: center" class="Zeugnistitel">
    <fz-dropdown [field]="model.zeugnisTypField"></fz-dropdown>
  </div>
  <div style="text-align: center" class="Schuljahr">
    <fz-label>{{ model.klassenstufeLabel }}</fz-label>
  </div>
  <div style="text-align: center" class="Schuljahr">
    <fz-schuljahr-label>{{ model.schuljahrLabel }}</fz-schuljahr-label>
  </div>
</fz-panel>
