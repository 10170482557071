import { ZeugnisTyp } from '../../../models/enums/zeugnis-typ';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { Zeugnis } from '../../../models/zeugnis';

export class ZeugnisTypField extends DropdownField<Zeugnis, ZeugnisTyp | null> {
  constructor(private zeugnis: Zeugnis) {
    super(
      {
        label: 'Zeugnistyp',
        labelShort: 'ZTyp',
        required: false,
        property: 'zeugnisTyp',
        candidates: [
          { displayStringShort: 'AZ', value: null, content: 'Abschlusszeugnis der Grundschule' },
          { displayStringShort: 'Z', value: 'Wiederholung', content: 'Zeugnis der Grundschule' },
        ],
      },
      { object: zeugnis }
    );
  }
}
