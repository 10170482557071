<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="fz-paragraph-text">
    <p>Dieser Assistent unterstützt Sie beim Einlesen von Flinky Zeugnis Transfer Dateien. Hiermit können Sie:</p>
    <ul class="list-disc list-inside">
      <li>Fach einer/s Fachlehrerin/Fachlehrers einlesen in die aktuell geöffnete Datei.</li>
      <li>Ganze Datei z.B. einer/r Klassenlehrerin/Klassenlehrers einlesen.</li>
    </ul>
    <p class="mt-2">
      Dieser Programmteil dient NICHT dem Einlesen von Schülerlisten oder anderer Fremddateien (Excel oder CSV-Dateien)!
    </p>

    <br />

    <p>Sie haben folgende Möglichkeiten:</p>

    <ol class="list-decimal list-inside">
      <li>Übertragung über Internet (empfohlen)</li>
      <li>
        Schließen Sie jetzt Ihren USB-Speicherstick oder ext. Festplatte an Ihren Computer an und klicken Sie auf die
        Schaltfläche "Manuell", um einen Speicherort auszuwählen
      </li>
    </ol>

    <p class="mt-3 font-bold">
      WICHTIG: Ihre Daten sind immer mit dem Export-Passwort verschlüsselt bevor diese Ihren PC verlassen bzw.
      erreichen!<br />
      Das Passwort ist zwingende Voraussetzung, um die Daten lesen zu können.
    </p>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Über Internet"
        class="w-64"
        [loading]="loading"
        (click)="internet()"
        icon="pi pi-globe"
      ></button>

      <p-fileUpload
        #uploader
        mode="basic"
        [url]="uploadUrl"
        [headers]="headers"
        (onUpload)="uploadComplete($event)"
        (onError)="uploadError()"
        (onBeforeUpload)="uploadStart()"
        chooseIcon="pi pi-upload"
        accept=".fzt"
        [auto]="true"
        chooseLabel="Manuell"
        [disabled]="loading"
        styleClass="w-64"
      ></p-fileUpload>
    </div>
  </ng-template>
</p-panel>
