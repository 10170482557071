import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpInfoComponent } from './help-info.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [HelpInfoComponent],
  imports: [CommonModule, OverlayPanelModule, ButtonModule],
  exports: [HelpInfoComponent],
})
export class HelpInfoModule {}
