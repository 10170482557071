import { KlassenstufeFlexTyp } from '../../../models/enums/klassenstufe-flex-typ';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { HEZeugnis } from '../../../models/he-zeugnis';

export class KlassenstufeFlexField extends DropdownField<HEZeugnis, KlassenstufeFlexTyp | null> {
  constructor(private zeugnis: HEZeugnis) {
    super(
      {
        label: 'KlassensstufeFlex',
        labelShort: 'KlFlex',
        property: 'klassenstufeFlex',
        candidates: [
          { displayStringShort: '1/2', value: '1/2', content: '1/2' },
          { displayStringShort: '1', value: '1', content: '1' },
          { displayStringShort: '2', value: '2', content: '2' },
          { displayStringShort: '3', value: '3', content: '3' },
        ],
      },
      { object: zeugnis }
    );
  }
  override get value(): KlassenstufeFlexTyp {
    return this.zeugnis.klassenstufeFlex ?? (this.zeugnis.zeugnissatz.klassenstufe.toString() as KlassenstufeFlexTyp);
  }
  override set value(value: KlassenstufeFlexTyp) {
    this.zeugnis.klassenstufeFlex = value === this.zeugnis.zeugnissatz.klassenstufe.toString() ? null : value;
  }
}
