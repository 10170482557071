<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col">Bitte geben Sie den Dateinamen an, der für die Exportdatei genutzt werden soll.</div>
    <div class="flex flex-col">
      <p-inputGroup>
        <p-inputGroupAddon>
          <i class="pi pi-file"></i>
        </p-inputGroupAddon>
        <input pInputText [(ngModel)]="filename" />
        <p-inputGroupAddon>.fzt</p-inputGroupAddon>
      </p-inputGroup>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Speichern"
        class="w-80"
        icon="pi pi-save"
        (click)="doExport()"
        [disabled]="!filename"
      ></button>
    </div>
  </ng-template>
</p-panel>
