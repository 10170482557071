import { Component } from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';

@Component({
  selector: 'fz-sl-kenntnis',
  templateUrl: 'sl-kenntnis.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLKenntnisComponent)],
})
export class SLKenntnisComponent extends SLPanelDecoratorBase {
  override borderDirection = BorderDirection;
}
