import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';
import { BWFormular } from '../formulare/bw-formular';

@Component({
  selector: 'fz-bw-unterschrift-halbjahr',
  templateUrl: 'bw-unterschrift-halbjahr.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, BWUnterschriftHalbjahrComponent)],
})
export class BWUnterschriftHalbjahrComponent extends BWPanelDecoratorBase {
  static createModel(formular: BWFormular) {
    return {
      zeugnisausgabedatumField: formular.zeugnisausgabedatum,
      klassenleitungField: formular.zeugnissatz.klassenleitung,
    };
  }

  @Input() model: ReturnType<typeof BWUnterschriftHalbjahrComponent.createModel> | undefined;
}
