/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

import BereichKey from './bereich-key';

class BWBereichKey extends BereichKey {
  public static readonly zusammenarbeit: string = 'zusammenarbeit';
  public static readonly eigenstaendigesArbeiten: string = 'eigenstaendigesArbeiten';
  public static readonly sorgfalt: string = 'sorgfalt';
  public static readonly zahlen: string = 'zahlen';
  public static readonly groessen: string = 'groessen';
  public static readonly raum: string = 'raum';
  public static readonly daten: string = 'daten';
}
export default BWBereichKey;
