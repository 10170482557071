<div
  [style.paddingTop.px]="paddingTopPx"
  [style.paddingBottom.px]="paddingBottomPx"
  [style.paddingLeft.px]="paddingLeftPx"
  [style.paddingRight.px]="paddingRightPx"
  [style.backgroundColor]="backgroundColor"
  style="height: 100%"
  [style.display]="inline ? 'inline-block' : 'block'"
>
  <ng-content></ng-content>
</div>
<ng-template #content="fzPortal" fzPortal>
  <fz-translate fzFocusable (fzHasFocusChange)="onFocusChange()" [fzPosition]="rect">
    <fz-rect
      [width]="borderWidth"
      [topStyle]="isBorderTop ? 'solid' : 'none'"
      [bottomStyle]="isBorderBottom ? 'solid' : 'none'"
      [leftStyle]="isBorderLeft ? 'solid' : 'none'"
      [rightStyle]="isBorderRight ? 'solid' : 'none'"
      [style.width.px]="rect.width"
      [style.height.px]="rect.height"
      [style.borderColor]="borderColor"
      [style.backgroundColor]="backgroundColor"
    ></fz-rect>
    <fz-translate *ngFor="let child of children">
      <!-- fz-translate, damit die Focus-Reihenfolge stimmt. Wenn ng-container benutzt wird, wird das Kind ungeordnet projeziert -->
      <ng-container [fzPortalOutlet]="child.content"></ng-container>
    </fz-translate>
  </fz-translate>
</ng-template>
<ng-template #backDeco="fzPortal" fzPortal>
  <div [fzPosition]="rect">
    <ng-container *ngFor="let child of children">
      <ng-container [fzPortalOutlet]="child.backDeco"></ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div [fzPosition]="rect">
    <ng-container *ngFor="let child of children">
      <ng-container [fzPortalOutlet]="child.frontDeco"></ng-container>
    </ng-container>
  </div>
</ng-template>
