import { Directive, Injector, NgZone } from '@angular/core';
import { Projectable, ProjectableProvider } from '@modules/projectables/projectable';
import { BlockFactoryOwner, BlockFactoryOwnerProvider } from './block-factory';
import { ContainerBaseDirective } from './container-base.directive';

@Directive()
export abstract class ContainerDirective extends ContainerBaseDirective {
  constructor(
    zone: NgZone,
    private injector: Injector
  ) {
    super(zone);
  }
  get projectable(): Projectable | undefined {
    return this.injector.get(ProjectableProvider)?.provided;
  }
  get owner(): BlockFactoryOwner | undefined {
    return this.injector.get(BlockFactoryOwnerProvider)?.provided;
  }

  projectableContentChange(): void {
    if (this.height !== this.prevHeight) {
      this.owner?.invalidate();
    } else this.project();
  }
}
