<button
  pButton
  type="button"
  icon="pi pi-cog"
  class="fz-system-actions p-button-rounded p-button-secondary p-button-raised"
  (click)="menu.toggle($event)"
></button>

<p-tieredMenu #menu [popup]="true" [model]="items"></p-tieredMenu>

<p-fileUpload
  [styleClass]="'fz-system-restore-uploader'"
  #uploader
  mode="basic"
  [url]="uploadUrl"
  [headers]="headers"
  chooseIcon=""
  accept=".zip"
  [auto]="true"
  class="h-0 w-0"
></p-fileUpload>

<fz-info-dialog [infoDialogVisible]="infoDialogVisible" (infoDialogClosed)="infoDialogClosed()"></fz-info-dialog>

<p-dialog
  header="Benutzerverwaltung"
  [(visible)]="userManagemenetDiaoigVisible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onShow)="onShowBenutzerverwaltung()"
>
  <p-table
    [value]="users"
    sortField="benutzername"
    sortMode="single"
    class="flex-grow h-0 overflow-auto"
    [autoLayout]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">Benutzername</th>
        <th scope="col">Lizenz</th>
        <th scope="col">Letzter Login</th>
        <th scope="col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td>{{ user.benutzername }}</td>
        <td>{{ user.kundennummer }}</td>
        <td>{{ user.lastLogin | date: ' dd.MM.yyyy HH:mm' }}</td>
        <td>
          <div class="fz-button-group">
            <button
              type="button"
              pButton
              icon="pi pi-trash"
              class="p-button-rounded p-button-outlined p-button-danger"
              (click)="deleteBenutzer(user)"
            ></button>
            <button
              type="button"
              pButton
              icon="pi pi-info"
              class="p-button-rounded p-button-outlined"
              (click)="showUserinfo(user); userinfoOp.toggle($event)"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="4">Keine Benutzer vorhanden</td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="userManagemenetDiaoigVisible = false" label="Ok"></p-button>
  </ng-template>
</p-dialog>

<p-overlayPanel #userinfoOp [baseZIndex]="10001">
  <ng-template pTemplate>
    <div [cdkCopyToClipboard]="selectedUser ? dataPath + '/benutzer' + selectedUser.realmFileName + '.realm' : ''">
      RealmFile: {{ selectedUser?.realmFileName }}
    </div>
    <br />
    Passwortregistrierung: {{ selectedUser?.passwordRecoveryRegistrationState }}
  </ng-template>
</p-overlayPanel>
