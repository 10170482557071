import { Component, ElementRef, Input, Optional, SkipSelf } from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableDecoratorBase } from './projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Router } from '@angular/router';
import { WorkspaceService } from '../../shared/services';
import { ImageComponent } from './image.component';
import { FormularService } from '@modules/formular/formular.service';
import { Formular } from '@modules/formular/formular';

@Component({
  selector: 'fz-zeugniskopf-image',
  templateUrl: 'zeugniskopf-image.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, ZeugniskopfImageComponent)],
})
export class ZeugniskopfImageComponent extends ProjectableDecoratorBase implements Projectable {
  @Input() src = '';
  @Input() width = 680;

  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private router: Router,
    private workspaceService: WorkspaceService,
    public formularService: FormularService<Formular>
  ) {
    super(elementRef, parentProvider);
  }

  get image() {
    return this.projectable as ImageComponent | undefined;
  }

  goEditJahrgang() {
    this.router.navigate(['jahrgang-aendern', this.workspaceService.currentJahrgangId]);
  }
}
