import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SignpadComponent } from '../signpad/signpad.component';
import { ProfileService } from './profile.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Benutzer } from '../../../models/generated/benutzer';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import AvailableLicense from '../../../models/generated/available-license';
import { emailRegex } from '../../../consts';
import { ClientException } from '../../../models/client-exception';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('signpad') signpad?: SignpadComponent;
  benutzer?: Benutzer | null;

  avatarSize = 80;
  profileform: UntypedFormGroup;
  selectedLicense?: AvailableLicense;
  availableLicences: AvailableLicense[] = [];
  emailEditDialogVisible: boolean = false;
  newEmail: string | number = '';
  currentPassword: string = '';
  emailRegex = emailRegex;
  constructor(
    private flinkyAuthService: FlinkyAuthService,
    private profileService: ProfileService,
    private notificationService: NotificationService
  ) {
    this.profileform = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.email),
      benutzername: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      vorname: new UntypedFormControl(''),
      kundennummer: new UntypedFormControl({ value: '', disabled: true }),
    });
  }

  async ngOnInit() {
    this.benutzer = this.flinkyAuthService.getUser();
    if (this.benutzer) {
      this.profileform.patchValue({
        email: this.benutzer.email,
        vorname: this.benutzer.vorname,
        name: this.benutzer.name,
        benutzername: this.benutzer.benutzername,
        kundennummer: this.benutzer.kundennummer,
      });
    }
  }

  async onSubmit(): Promise<boolean> {
    if (this.signpad != null && this.benutzer != null) {
      this.benutzer.unterschrift = this.signpad.signaturePad?.toDataURL();
    }
    const data = await this.profileService.saveProfileData(
      this.profileform.value.vorname,
      this.profileform.value.name,
      this.profileform.value.email,
      this.benutzer?.unterschrift ?? undefined,
      this.benutzer?.kundennummer
    );

    this.flinkyAuthService.setUser(data);
    return true;
  }

  async changeEmail() {
    try {
      if ((await this.profileService.updateEmail(this.newEmail as string, this.currentPassword)) === true) {
        this.flinkyAuthService.logout();
        this.notificationService.showPermanentSuccess(
          'Ihre E-Mail-Adressse wurde geändert und Ihnen eine E-Mail geschickt. Bitte überprüfen Sie Ihren Posteingang und bestätigen die Änderung.',
          'Erfolg'
        );
      } else {
        this.notificationService.showPermanentError(
          'Beim Ändern der E-Mail-Adresse ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
          'Fehler'
        );
      }
    } catch (ex) {
      if (ex instanceof ClientException) {
        this.notificationService.showPermanentError(ex.message, 'Fehler');
      } else throw ex;
    }
  }
}
