import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import ExportResult from '../../../models/generated/export-result';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(private http: HttpClient) {}

  getExportInfo(idJahrgang: string, kundennummer: string): Promise<ExportResult> {
    const params = new HttpParams().append('idJahrgang', idJahrgang).append('kundennummer', kundennummer);

    return lastValueFrom(this.http.get<ExportResult>('/api/Export/getExportInfo', { params }));
  }

  exportToInternet(name: string, vorname: string, passwort: string, idJahrgang: string, kundennummer: string) {
    return lastValueFrom(
      this.http.post<ExportResult>('/api/Export/exportToInternet', {
        name,
        vorname,
        passwort,
        idJahrgang,
        kundennummer,
      })
    );
  }

  public exportFile(name: string, vorname: string, passwort: string, idJahrgang: string) {
    return lastValueFrom(
      this.http.post(
        '/api/Export/exportToLocal',
        { name, vorname, passwort, idJahrgang },
        {
          responseType: 'blob' as const,
        }
      )
    );
  }
}
