<fz-deco
  *ngIf="model != null"
  [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
  [padding]="formular.showBorder ? 'normal' : 'none'"
>
  <fz-section [label]="model.labelField.value ?? ''" headerClass="Ueberschriften2" headerPaddingBottom="normal">
    <fz-panel fzSectionHeader class="Ueberschriften2" paddingBottom="normal">
      <fz-text [field]="model.labelField"></fz-text>
    </fz-panel>
    <fz-verbal
      [field]="model.textField"
      [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
    ></fz-verbal>
  </fz-section>
</fz-deco>

<ng-container
  *ngFor="let block of blocks; first as first"
  fzBlock
  [content]="content"
  [backDeco]="backDeco"
  [frontDeco]="frontDeco"
>
  <ng-template #content="fzPortal" fzPortal>
    <fz-translate fzFocusable tabindex="0">
      <ng-container [fzPortalOutlet]="block.content"></ng-container>
    </fz-translate>
  </ng-template>
  <ng-template #backDeco="fzPortal" fzPortal>
    <fz-translate>
      <ng-container [fzPortalOutlet]="block.backDeco"></ng-container>
    </fz-translate>
  </ng-template>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div
      *ngIf="hasFocus && first"
      fzFocusable
      [style.bottom.px]="0"
      class="floating fz-note"
      tabindex="0"
      [style.left.px]="isFirstFach ? 189 : 0"
    >
      <p-button
        icon="pi pi-minus"
        label="Fach&nbsp;&quot;{{ model?.label }}&quot;&nbsp;entfernen"
        (onClick)="delete.emit()"
      ></p-button>
    </div>
    <fz-translate>
      <ng-container [fzPortalOutlet]="block.frontDeco"></ng-container>
    </fz-translate>
  </ng-template>
</ng-container>
