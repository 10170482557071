<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="flex flex-col fz-paragraph-text">
    <p><strong>Dies ist KEINE Datensicherung - nur zum Transfer verwenden.</strong></p>
    <p>Sie haben folgende Möglichkeiten:</p>
    <ol class="list-decimal list-inside">
      <li>Übertragung über Internet (empfohlen)</li>
      <li>Schließen Sie jetzt Ihren USB-Speicherstick oder ext. Festplatte an Ihren Computer an</li>
      <li>Klicken Sie auf die Schaltfläche "Manuell", um einen Speicherort von Hand auszuwählen</li>
    </ol>
    <p>
      Für den Fall 2 haben Sie einen Augenblick Geduld bis das Gerät erkannt worden ist. Danach werden Sie ggf.
      automatisch zu entsprechenden Aktionen aufgefordert.
    </p>
    <p>
      WICHTIG: Ihre Daten sind immer mit dem Export-Passwort verschlüsselt bevor diese Ihren PC verlassen bzw.
      erreichen! Das Passwort ist zwingende Voraussetzung, um die Daten lesen zu können.
    </p>
  </div>

  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Über Internet"
        icon="pi pi-globe"
        class="w-64"
        (click)="internet()"
        [loading]="loading"
      ></button>
      <button
        pButton
        type="button"
        label="Manuell"
        icon="pi pi-download"
        class="w-64"
        [loading]="loading"
        (click)="manuell()"
      ></button>
    </div>
  </ng-template>
</p-panel>
