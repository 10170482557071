@if (formular.isKP) {
  <button
    class="mt-2"
    pButton
    type="button"
    [text]="true"
    label="Einrichtung des Kompetenzrasters"
    routerLink="/koennensprofil-editor"
  ></button>
}
