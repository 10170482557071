import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { WorkspaceService } from '../../services/workspace.service';
import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { FormularFactory } from '@modules/formular/formular-factory';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { HalbjahrDescription } from '@modules/formular/formularsatz';
import { ZeugnissatzViewModel } from '@modules/formular/formular';
import { Subscription } from 'rxjs';
import { Zeugnissatz } from '../../../models/zeugnissatz';
import { Schueler } from '../../../models/schueler';
import { Router } from '@angular/router';
import { JahrgangFactoryService } from '../../services/jahrgang-factory.service';
import { SystemActionsService } from '../system-actions/system-actions.service';

@Component({
  selector: 'fz-jahrgang-weiterfuehren',
  templateUrl: './jahrgang-weiterfuehren.component.html',
  styleUrls: ['./jahrgang-weiterfuehren.component.scss'],
})
export class JahrgangWeiterfuehrenComponent implements AfterViewInit, OnDestroy {
  isSL = false;
  zeugnislevels: ({ text: string; wert: number } | { text: string; wert: undefined })[] = [];
  schuljahre: { text: string; wert: number }[] = [];
  zeugnisse: HalbjahrDescription[] = [];
  jahrgangform: UntypedFormGroup;
  schuljahr = '';

  prevZeugnissatz?: Zeugnissatz;

  availableSchuelers: Schueler[] = [];
  selectedSchuelers: Schueler[] = [];
  zsVm?: ZeugnissatzViewModel;

  isLoading: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private authService: FlinkyAuthService,
    private workspaceService: WorkspaceService,
    private jahrgangFactory: JahrgangFactoryService,
    private router: Router,
    public systemActionService: SystemActionsService
  ) {
    this.jahrgangform = new UntypedFormGroup({
      schulleitung: new UntypedFormControl('', Validators.required),
      klassenbezeichnung: new UntypedFormControl('', Validators.required),
      klassenleitung: new UntypedFormControl('', Validators.required),
      halbjahrDescription: new UntypedFormControl('', Validators.required),
      rufname: new UntypedFormControl(''),
    });

    const currentYear = new Date().getFullYear();

    this.schuljahre = [
      { text: `${currentYear - 1}/${currentYear}`, wert: currentYear - 1 },
      { text: `${currentYear}/${currentYear + 1}`, wert: currentYear },
      { text: `${currentYear + 1}/${currentYear + 2}`, wert: currentYear + 1 },
      { text: `${currentYear + 2}/${currentYear + 3}`, wert: currentYear + 2 },
      { text: `${currentYear + 3}/${currentYear + 4}`, wert: currentYear + 3 },
      { text: `${currentYear + 4}/${currentYear + 5}`, wert: currentYear + 4 },
    ];

    this.zeugnislevels = [
      { text: 'Formulare Stufe 1', wert: 1 },
      { text: 'Formulare Stufe 2', wert: 2 },
      { text: 'Gemischt', wert: undefined },
    ];

    this.initValues();

    this.subscriptions.push(
      this.workspaceService.dataLoadedBehaviour.subscribe(() => {
        this.initValues();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngAfterViewInit(): void {
    this.prevZeugnissatz = this.workspaceService.currentZeugnissatz;
  }

  initValues(): void {
    this.prevZeugnissatz = this.workspaceService.currentZeugnissatz;
    if (this.prevZeugnissatz) {
      this.zsVm = new ZeugnissatzViewModel(this.prevZeugnissatz);
    }
    this.selectedSchuelers =
      this.workspaceService.currentZeugnisse.map((z) => z.schueler).filter((s) => s.isAktiv) ?? [];
    this.availableSchuelers =
      this.workspaceService.currentZeugnisse.map((z) => z.schueler).filter((s) => !s.isAktiv) ?? [];

    this.jahrgangform.value.schuljahr = new Date().getFullYear();
    if (this.authService.lizenz != null && this.workspaceService.currentZeugnissatz) {
      this.zeugnisse = FormularFactory.getFormularsatz(this.authService.lizenz.bundesland).getRemainingHalbjahre(
        this.workspaceService.currentZeugnissatz
      );
      this.isSL = this.authService.lizenz.bundesland === 'SL';
    }
    const jahrgang = this.workspaceService.currentZeugnissatz?.jahrgang;
    this.jahrgangform.patchValue({
      klassenleitung: jahrgang?.klassenleitung,
      schulleitung: jahrgang?.schulleitung,
      klassenbezeichnung: this.workspaceService.currentZeugnissatz?.klassenbezeichnung,
      rufname: jahrgang?.rufname,
    });
  }

  zeugnisseChanged(): void {
    const jahr = this.jahrgangform.value.halbjahrDescription.schuljahr;
    this.schuljahr = jahr + '/' + (jahr + 1);
    let alteKlassenbezeichnung = this.workspaceService.currentZeugnissatz?.klassenbezeichnung;
    if (this.prevZeugnissatz != null && this.prevZeugnissatz.klassenstufe != null)
      alteKlassenbezeichnung = alteKlassenbezeichnung?.replace(
        /\d/,
        this.jahrgangform.value.halbjahrDescription.klassenstufe.toString()
      );
    this.jahrgangform.patchValue({ klassenbezeichnung: alteKlassenbezeichnung });
  }

  async onSubmit() {
    if (this.workspaceService.jahrgang != null) {
      try {
        this.isLoading = true;
        const formValue = this.jahrgangform.value;

        this.workspaceService.jahrgang.rufname = formValue.rufname;

        const zeugnissatz = await this.jahrgangFactory.createZeugnissatz(
          this.workspaceService.jahrgang,
          formValue.halbjahrDescription.schuljahr,
          formValue.halbjahrDescription.halbjahr,
          formValue.halbjahrDescription.klassenstufe
        );
        const currentZeugnissatz = this.prevZeugnissatz;
        if (
          currentZeugnissatz != null &&
          zeugnissatz.klassenstufe <= 2 &&
          zeugnissatz.klassenstufe !== currentZeugnissatz.schuljahr &&
          currentZeugnissatz.zeugnislevel === 1
        ) {
          zeugnissatz.zeugnislevel = 2;
        } else {
          zeugnissatz.zeugnislevel = currentZeugnissatz?.zeugnislevel ?? null;
        }
        zeugnissatz.klassenbezeichnung = formValue.klassenbezeichnung;
        zeugnissatz.klassenleitung = formValue.klassenleitung;
        zeugnissatz.schulleitung = formValue.schulleitung;
        zeugnissatz.jahrgang.klassenleitung = zeugnissatz.klassenleitung;
        zeugnissatz.jahrgang.schulleitung = zeugnissatz.schulleitung;

        for (const schueler of this.workspaceService.jahrgang.schuelers) {
          schueler.isAktiv = this.selectedSchuelers.includes(schueler);
        }

        await this.workspaceService.weiterfuehren(zeugnissatz);
        this.router.navigate(['/main']);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
