<ng-template fzTemplate="sidebar">
  <fz-sl-schueler-typ></fz-sl-schueler-typ>
  <fz-sl-formular-typ></fz-sl-formular-typ>
</ng-template>
<fz-formular
  name="SL34hjStandard"
  [pageMarginTop]="151"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="113"
  [pageHeader]="slFolgeseiteComponent"
  [pageFooter]="slSeitenfussComponent"
  [lastPageFooter]="slLegendeComponent"
>
  <fz-sl-zeugniskopf
    fzFormularItem="zeugniskopf"
    [marginBottom]="formular.page1Items34MarginBottom"
    fzContainer
  ></fz-sl-zeugniskopf>
  <fz-panel
    *ngIf="
      formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule &&
      formular.zeugnis.schuelerTyp === 'Lernbehindert'
    "
    fzFormularItem="bildungsgangLernen"
    fzContainer
    paddingLeft="normal"
  >
    <fz-label class="Zeugnistitel">Bildungsgang Lernen</fz-label>
  </fz-panel>
  <fz-sl-zeugnissatz
    fzFormularItem="zeugnissatz"
    [marginBottom]="formular.page1Items34MarginBottom"
    fzContainer
    [model]="model.zeugnissatz"
  ></fz-sl-zeugnissatz>
  <fz-sl-schueler
    fzFormularItem="schueler"
    [marginBottom]="formular.page1Items34MarginBottom"
    fzContainer
    [model]="model.schueler"
  ></fz-sl-schueler>
  <fz-panel
    *ngIf="
      formular.zeugnis.zeugnissatz.zeugniskopflizenz.isSLSprachfoerderschule &&
      formular.zeugnis.zeugnissatz.halbjahr === 1
    "
    fzFormularItem="unterricht"
    fzContainer
    paddingLeft="normal"
  >
    <fz-label *ngIf="formular.zeugnis.schuelerTyp !== 'Lernbehindert'"
      >Der Unterricht wurde nach dem Lehrplan für die Grundschule erteilt.</fz-label
    >
    <fz-label *ngIf="formular.zeugnis.schuelerTyp == 'Lernbehindert'"
      >Der Unterricht wurde nach den Lehrplänen für den Unterricht in der Förderschule Lernen erteilt.</fz-label
    >
  </fz-panel>
  <fz-section
    fzFormularItem="sozialverhalten"
    [marginBottom]="formular.page1Items34MarginBottom"
    [label]="model.sozialverhalten.label"
    [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-panel
      fzSectionHeader
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <div class="Ueberschriften2">
        <fz-label>{{ model.sozialverhalten.label }}</fz-label>
      </div>
      <div class="Ueberschriften3">
        <fz-label>
          {{ model.sozialverhalten.subLabel }}
        </fz-label>
      </div>
    </fz-panel>
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
    >
      <fz-sequence>
        <fz-verbal
          [field]="model.sozialverhalten.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
        <fz-deco paddingTop="normal">
          <fz-panel fzContainer>
            <div>
              <fz-label class="Noten_Beschriftung">Note: </fz-label
              ><fz-note class="Noten_Text" [field]="model.sozialverhalten.noteField"></fz-note>
            </div>
          </fz-panel>
        </fz-deco>
      </fz-sequence>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="lernArbeitsverhalten"
    [marginBottom]="formular.page1Items34MarginBottom"
    [label]="model.lernArbeitsverhalten.label"
    [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-panel
      fzSectionHeader
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <div class="Ueberschriften2">
        <fz-label>{{ model.lernArbeitsverhalten.label }}</fz-label>
      </div>
      <div class="Ueberschriften3">
        <fz-label>
          {{ model.lernArbeitsverhalten.subLabel }}
        </fz-label>
      </div>
    </fz-panel>
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
    >
      <fz-sequence>
        <fz-verbal
          [field]="model.lernArbeitsverhalten.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
        <fz-deco paddingTop="normal">
          <fz-panel fzContainer>
            <div>
              <fz-label class="Noten_Beschriftung">Note: </fz-label
              ><fz-note class="Noten_Text" [field]="model.lernArbeitsverhalten.noteField"></fz-note>
            </div>
          </fz-panel>
        </fz-deco>
      </fz-sequence>
    </fz-deco>
  </fz-section>
  <fz-section
    fzFormularItem="besondereFaehigkeiten"
    [manualPageBreak]="true"
    [label]="model.besondereFaehigkeiten.label"
    [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
    >
      <fz-intelli
        [field]="model.besondereFaehigkeiten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-deco fzFormularItem="leistungen" paddingLeft="normal" paddingRight="normal">
    <fz-sl-leistungen fzContainer [model]="model.leistungen"></fz-sl-leistungen>
  </fz-deco>
  <fz-deco
    fzFormularItem="sprachenportfolio"
    [marginBottom]="formular.page2ItemsMarginBottom"
    paddingLeft="normal"
    paddingRight="normal"
  >
    <fz-sl-sprachenportfolio fzContainer [model]="model.sprachenportfolio"></fz-sl-sprachenportfolio>
  </fz-deco>
  <fz-section
    fzFormularItem="bemerkungen"
    [marginBottom]="formular.page2ItemsMarginBottom"
    [label]="model.bemerkungen.label"
    [headerBorder]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [headerPadding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-deco
      [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
      paddingTop="normal"
    >
      <fz-intelli
        [field]="model.bemerkungen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-deco>
  </fz-section>
  <fz-sl-versaeumnis
    fzFormularItem="versaeumnis"
    [marginBottom]="formular.page2ItemsMarginBottom"
    fzContainer
    [model]="model.versaeumnis"
  ></fz-sl-versaeumnis>
  <fz-deco
    *ngIf="model.versetzungsvermerk != null"
    fzFormularItem="versetzungsvermerk"
    [marginBottom]="formular.page2ItemsMarginBottom"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-sl-versetzungsvermerk fzContainer [model]="model.versetzungsvermerk"></fz-sl-versetzungsvermerk>
  </fz-deco>
  <fz-sl-schulort fzFormularItem="schulort" [marginBottom]="0" fzContainer [model]="model.schulort"></fz-sl-schulort>
  <fz-sl-unterschrift
    fzFormularItem="unterschrift"
    [marginBottom]="formular.page2ItemsMarginBottom"
    fzContainer
    [model]="model.unterschrift"
  ></fz-sl-unterschrift>
  <fz-sl-kenntnis
    fzFormularItem="kenntnis"
    [marginBottom]="formular.page2ItemsMarginBottom"
    fzContainer
  ></fz-sl-kenntnis>
</fz-formular>
