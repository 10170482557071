import { KPRaster } from './kp-raster';
import { Metadata } from './metadata';

export class BenutzerKPRaster extends Metadata {
  id: string = '';
  raster: KPRaster = new KPRaster();
  kundennummer: string = '';
  klassenstufe: string = '';
  uploadTime: Date = new Date();
  version?: string;

  static override cleanDto(dto: any): any {
    const { raster, ...cleanDto } = dto;
    return cleanDto;
  }

  static override toDto(benutzerRaster: BenutzerKPRaster): unknown {
    return {
      ...this.cleanDto(benutzerRaster),
      raster: KPRaster.toDto(benutzerRaster.raster),
    };
  }

  static fromDto(dto: any): BenutzerKPRaster {
    return Object.assign(new BenutzerKPRaster(), {
      ...this.cleanDto(dto),
      raster: dto.raster == null ? new KPRaster() : KPRaster.fromDto(dto.raster),
    });
  }
}
