import { Type } from '@angular/core';
import { FormularCategory, Formularsatz, HalbjahrDescription } from '@modules/formular/formularsatz';
import { RPFormular } from './formulare/rp-formular';
import { RP1jVerbalFormular } from './formulare/rp-1j-verbal-formular.component';
import { RP34hStandardFormular } from './formulare/rp-34h-standard-formular.component';
import { RP234jaStandardFormular } from './formulare/rp-234ja-standard-formular.component';
import { RP234hProtokollFormular } from './formulare/rp-234h-protokoll-formular.component';
import { RP4hEmpfehlungFormular } from './formulare/rp-4h-empfehlung-formular.component';
import { BundeslandTyp } from '../../models/enums/bundesland-typ';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { RPZeugnis } from '../../models/rp-zeugnis';
import { Zeugnis } from '../../models/zeugnis';
import { NoteCandidateDescription } from '../../models/fields/note-field';
import moment from 'moment';

export type RPFormularCategoryKey = 'zeugnis' | 'protokoll' | 'empfehlung';

export class RPFormularsatz extends Formularsatz {
  get zeugnisType(): Type<Zeugnis> {
    return RPZeugnis;
  }

  get bundesland(): BundeslandTyp {
    return 'RP';
  }
  getAllHalbjahre(schuljahr: number): HalbjahrDescription[] {
    return [
      {
        text: '1. Klasse, Jahreszeugnis',
        klassenstufe: 1,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '2. Klasse, Halbjahresprotokoll',
        klassenstufe: 2,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '2. Klasse, Jahreszeugnis',
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '3. Klasse, Halbjahreszeugnis',
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '3. Klasse, Jahreszeugnis',
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '4. Klasse, Halbjahreszeugnis',
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '4. Klasse, Jahreszeugnis',
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr,
      },
    ];
  }

  getRemainingHalbjahre(zeugnissatz: Zeugnissatz): HalbjahrDescription[] {
    const halbjahre: HalbjahrDescription[] = [];
    const klassenstufeHalbjahr = zeugnissatz.klassenstufe + zeugnissatz.halbjahr / 2;
    const schuljahr = zeugnissatz.schuljahr - zeugnissatz.klassenstufe;
    if (klassenstufeHalbjahr < 2) {
      const newSchuljahr = schuljahr + 2;
      halbjahre.push({
        text: `2. Klasse, Halbjahresprotokoll ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 2,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 2.5) {
      const newSchuljahr = schuljahr + 2;
      halbjahre.push({
        text: `2. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 3.0) {
      const newSchuljahr = schuljahr + 3;
      halbjahre.push({
        text: `3. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 3.5) {
      const newSchuljahr = schuljahr + 3;
      halbjahre.push({
        text: `3. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 4.0) {
      const newSchuljahr = schuljahr + 4;
      halbjahre.push({
        text: `4. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 4.5) {
      const newSchuljahr = schuljahr + 4;
      halbjahre.push({
        text: `4. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }

    return halbjahre;
  }

  getCategories(zeugnis: RPZeugnis): FormularCategory[] {
    const klassenstufe = zeugnis.zeugnissatz.klassenstufe;
    const halbjahr = zeugnis.zeugnissatz.halbjahr;
    const zeugnisTyp = zeugnis.zeugnisTyp;

    const categories: FormularCategory<RPFormularCategoryKey>[] = [];
    if (zeugnisTyp === 'Abgang') {
      categories.push({ key: 'zeugnis', label: 'Zeugnis' });
      if (klassenstufe >= 3 && halbjahr === 0) categories.push({ key: 'protokoll', label: 'Protokoll' });
    } else {
      if (!(klassenstufe === 2 && halbjahr === 0)) categories.push({ key: 'zeugnis', label: 'Zeugnis' });
      if (klassenstufe >= 2 && halbjahr === 0) categories.push({ key: 'protokoll', label: 'Protokoll' });
    }
    if (klassenstufe === 4 && halbjahr === 0) categories.push({ key: 'empfehlung', label: 'Empfehlung' });
    return categories;
  }

  getFormularType(zeugnis: RPZeugnis, category: RPFormularCategoryKey): Type<RPFormular> | null {
    switch (category) {
      case 'zeugnis':
        return this.getZeugnisFormularType(zeugnis);
      case 'protokoll':
        return RP234hProtokollFormular;
      case 'empfehlung':
        return RP4hEmpfehlungFormular;
      default:
        return null;
    }
  }
  getZeugnisFormularType(zeugnis: RPZeugnis): Type<RPFormular> | null {
    const klassenstufe = zeugnis.zeugnissatz.klassenstufe;
    const halbjahr = zeugnis.zeugnissatz.halbjahr;
    const schuelerTyp = zeugnis.schuelerTyp;
    const zeugnisTyp = zeugnis.zeugnisTyp;
    const abgangszeugnisTyp = zeugnis.abgangszeugnisTyp;
    if (klassenstufe === 1) {
      return RP1jVerbalFormular;
    } else {
      if (schuelerTyp === 'Lernbehindert') return RP234jaStandardFormular;
      if (schuelerTyp === 'Schuleingangsphase' && klassenstufe === 3) return RP234jaStandardFormular;
      if (halbjahr === 0) {
        if (zeugnisTyp !== 'Abgang' || abgangszeugnisTyp === 'Noten') return RP34hStandardFormular;
        else return RP234jaStandardFormular;
      } else {
        if (zeugnisTyp === 'Abgang' && abgangszeugnisTyp === 'Noten') return RP34hStandardFormular;
        else return RP234jaStandardFormular;
      }
    }
  }

  getNoteCandidates(_zeugnis: Zeugnis, _fachName: string): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    candidates.push(
      { displayStringShort: '1', content: 'sehr gut', value: 1 },
      { displayStringShort: '2', content: 'gut', value: 2 },
      { displayStringShort: '3', content: 'befriedigend', value: 3 },
      { displayStringShort: '4', content: 'ausreichend', value: 4 },
      { displayStringShort: '5', content: 'mangelhaft', value: 5 },
      { displayStringShort: '6', content: 'ungenügend', value: 6 }
    );
    candidates.push(
      { displayStringShort: 'b', content: 'befreit', value: 'Befreit' },
      { displayStringShort: 'n.f.', content: 'nicht feststellbar', value: 'NichtFeststellbar' },
      { displayStringShort: '/', content: '/', value: 'Slash' }
    );
    return candidates;
  }

  getNoteSuffixCandidates(): string[] {
    return [];
  }

  getKopfnoteCandidates(): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    return candidates;
  }

  getKopfnoteSuffixCandidates(): string[] {
    return [];
  }

  override getDefaultKPHeaderLabels(zeugnissatz: Zeugnissatz): string[][] {
    if (zeugnissatz.halbjahr === 0) {
      return [
        ['++', '', ''],
        ['+', '', ''],
        ['+/-', '', ''],
        ['-', '', ''],
      ];
    } else {
      return [
        ['Das kannst', 'du sehr', 'sicher'],
        ['Das', 'kannst', 'du sicher'],
        ['Das kannst', 'du', 'teilweise'],
        ['Das musst', 'du noch', 'lernen'],
      ];
    }
  }

  override get overviewCategories() {
    return ['zeugnis', 'empfehlung'];
  }

  override overrideLock(zeugnis: RPZeugnis): boolean {
    if (zeugnis.zeugnissatz.klassenstufe === 1) {
      // Bei 1. Klasse Zeugnis auch im ersten Halbjahr entsperren, da evtl. Abgangszeugnis geschrieben werden muss
      return (
        new Date(zeugnis.zeugnissatz.schuljahr, 8, 1) <= new Date() &&
        new Date() <=
          moment(new Date(zeugnis.zeugnissatz.schuljahr + 1, 1, 15))
            .add(-1, 'days')
            .toDate()
      );
    }
    if (
      zeugnis.zeugnisTyp === 'Abgang' &&
      zeugnis.abgangUebergangsfrist &&
      zeugnis.zeugnissatz.halbjahr === 1 &&
      zeugnis.zeugnissatz.klassenstufe < 4
    ) {
      if (zeugnis.zeugnissatz.zeugnisausgabedatum != null) {
        const zeugnisausgabedatum = moment(zeugnis.zeugnissatz.zeugnisausgabedatum, 'DD.MM.YYYY');
        const ende = zeugnisausgabedatum.add(105, 'days').toDate();
        const start = zeugnisausgabedatum.toDate();
        return start < new Date() && new Date() < ende;
      } else return true;
    }
    if (zeugnis.zeugnisTyp === 'Abgang' && zeugnis.zeugnissatz.halbjahr === 0) {
      if (zeugnis.zeugnissatz.zeugnisausgabedatum != null) {
        const zeugnisausgabedatum = moment(zeugnis.zeugnissatz.zeugnisausgabedatum, 'DD.MM.YYYY');
        const ende = zeugnisausgabedatum.add(56, 'days').toDate();
        const start = zeugnisausgabedatum.toDate();
        return start < new Date() && new Date() < ende;
      } else return true;
    }
    return false;
  }
}
