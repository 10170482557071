<ng-template fzTemplate="sidebar">
  <fz-he-schueler-typ></fz-he-schueler-typ>
</ng-template>
<fz-formular
  name="HE1234hjGeistig"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="176"
  [pageMarginBottomLast]="176"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLastFoerderschwerpunkt"
>
  <ng-container *ngIf="model != null">
    <fz-he-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-he-zeugniskopf>
    <fz-he-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-he-zeugnistyp>
    <fz-he-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-he-schueler>
    <fz-he-zeugnissatz-foerderschwerpunkt
      fzFormularItem="zeugnissatz"
      fzContainer
      [model]="model.zeugnissatz"
    ></fz-he-zeugnissatz-foerderschwerpunkt>
    <fz-section
      fzFormularItem="entwicklungsstand"
      [label]="model.allgemeineBeurteilung.label"
      headerClass="Ueberschriften"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
        paddingBottom="normal"
      >
        <fz-verbal
          [field]="model.allgemeineBeurteilung.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="wahlunterricht"
      label="Wahlunterricht/Freiwillige Unterrichtsveranstaltungen"
      headerClass="Ueberschriften"
    >
      <fz-sequence gap="minus-border">
        <fz-deco
          [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
          [padding]="formular.showBorder ? 'normal' : 'none'"
          paddingTop="normal"
          paddingBottom="normal"
        >
          <fz-verbal
            [field]="model.herkunftssprache.textField"
            placeholder="Kurs"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-deco>
        <fz-deco
          [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
          [padding]="formular.showBorder ? 'normal' : 'none'"
          paddingTop="normal"
          paddingBottom="normal"
        >
          <fz-verbal
            [field]="model.sprachkompetenz.textField"
            placeholder="Kurs"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-deco>
      </fz-sequence>
    </fz-section>
    <fz-section fzFormularItem="bemerkungen" [label]="model.bemerkungen.label" headerClass="Ueberschriften">
      <fz-deco
        [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="normal"
        paddingBottom="normal"
      >
        <fz-intelli
          [field]="model.bemerkungen.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-deco>
    </fz-section>
    <fz-he-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-he-versaeumnis>
    <fz-he-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-he-unterschrift>
  </ng-container>
</fz-formular>
