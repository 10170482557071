import { ChangeDetectorRef, Component, ElementRef, Input, Optional, SkipSelf, ViewChild } from '@angular/core';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Projectable, ProjectableBase, ProjectableOwnerProvider, ProjectableProvider } from './projectable';

@Component({
  selector: 'fz-image',
  templateUrl: 'image.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, ImageComponent)],
})
export class ImageComponent extends ProjectableBase implements Projectable {
  @Input() src = '';
  @Input() width = 680;
  @ViewChild('img') img!: ElementRef<HTMLElement>;
  rect = new DOMRect();

  constructor(
    elementRef: ElementRef<HTMLElement>,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private ownerProvider: ProjectableOwnerProvider,
    private changeDetector: ChangeDetectorRef
  ) {
    super(elementRef, parentProvider);
  }

  onLoad(): void {
    this.ownerProvider.provided.projectableContentChange(this);
  }

  projectPosition(parentRect: DOMRect): void {
    const childRect = this.img.nativeElement.getBoundingClientRect();
    this.rect = new DOMRect(
      childRect.left - parentRect.left,
      childRect.top - parentRect.top,
      childRect.width,
      childRect.height
    );
    this.changeDetector.detectChanges();
  }
}
