import { Indexable, ItemIndex } from './item-index';
import { UserLayoutFormularItem } from './user-layout-item';
import { UserLayoutFormularProperties } from './user-layout-properties';
import { Zeugnis } from './zeugnis';

export class UserLayoutFormular implements Indexable {
  formularKey: string = '';
  readonly properties: UserLayoutFormularProperties = new UserLayoutFormularProperties();
  readonly items: ItemIndex<UserLayoutFormularItem, UserLayoutFormular> = new ItemIndex(
    UserLayoutFormularItem,
    this as UserLayoutFormular
  );

  get key(): string {
    return this.formularKey;
  }

  constructor(
    key: string,
    public zeugnis: Zeugnis
  ) {
    this.formularKey = key;
  }

  static cleanDto(dto: any): any {
    const { zeugnis, properties, items, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(layout: UserLayoutFormular): unknown {
    return {
      ...this.cleanDto(layout),
      properties: UserLayoutFormularProperties.toDto(layout.properties),
      items: ItemIndex.toDto(UserLayoutFormularItem, layout.items),
    };
  }

  static fromDto(dto: any, zeugnis: Zeugnis): UserLayoutFormular {
    const layout = new UserLayoutFormular(dto.formularKey ?? '', zeugnis);
    return Object.assign(layout, {
      ...this.cleanDto(dto),
      properties: UserLayoutFormularProperties.fromDto(dto.properties),
      items: ItemIndex.fromDto(UserLayoutFormularItem, dto.items, layout),
    });
  }
}
