import { Directive, Input } from '@angular/core';
import { PortalDirective } from '@modules/shared/portal.directive';
import { Block } from './block-factory';

@Directive({ selector: '[fzBlock]' })
export class BlockDirective implements Block {
  @Input() content: PortalDirective | undefined;
  @Input() backDeco: PortalDirective | undefined;
  @Input() frontDeco: PortalDirective | undefined;
}
