import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-export-tb-finished',
  templateUrl: './export-tb-finished.component.html',
  styleUrls: ['./export-tb-finished.component.scss'],
})
export class ExportTbFinishedComponent {
  constructor(private router: Router) {}
  @Input() header: TemplateRef<any> | undefined;
  @Input() exporttyp = '';

  @Output() restartSelected = new EventEmitter<boolean>();

  restart() {
    this.restartSelected.next(true);
  }

  navigateToZeugnisse(): void {
    this.router.navigate(['/main']);
  }
}
