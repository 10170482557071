<fz-rich-text
  #test
  [(model)]="model"
  [field]="field"
  [width]="width"
  [isBlocksatz]="formular.zeugnissatz.zeugniskopflizenz.isBlocksatz"
  (hasFocusChange)="onFocusChange()"
  [placeholder]="placeholder"
></fz-rich-text>
<fz-verbalbeurteilung
  [text]="richText?.text"
  [kategorien]="field!.kategorien"
  [geschlecht]="formular.zeugnis.schueler.geschlecht"
  [klassenStufe]="formular.zeugnis.zeugnissatz.klassenstufe"
  [schuelerVorname]="formular.zeugnis.schuelerVorname"
  [schuelerName]="formular.zeugnis.schuelerName"
  [eigeneTextbausteinDescriptions]="field!.eigeneTextbausteinDescriptions"
  (selectedText)="onSelectedText($event)"
></fz-verbalbeurteilung>
<ng-container
  *ngFor="let blockModel of blockModels"
  fzBlock
  [content]="blockModel.richTextBlock.content"
  [backDeco]="blockModel.richTextBlock.backDeco"
  [frontDeco]="frontDeco"
>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div style="position: absolute">
      <ng-container [fzPortalOutlet]="blockModel.richTextBlock.frontDeco"></ng-container>
      <div
        *ngIf="hasFocus && blockModel === blockModels[blockModels.length - 1]"
        fzFocusable
        (fzHasFocusChange)="onFocusChange()"
        class="floating"
        tabindex="0"
        [style.top.px]="blockModel.rect.bottom + 6"
      >
        <button type="button" label="Textbausteine" pButton (click)="showVerbalComponent($event)"></button>
      </div>
    </div>
  </ng-template>
</ng-container>
