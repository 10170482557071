/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { SLFolgeseiteComponent } from '../projectables/sl-folgeseite.component';
import { SLLegendeComponent } from '../projectables/sl-legende.component';
import { SLSeitenfussComponent } from '../projectables/sl-seitenfuss.component';
import { SLZeugnisFormular } from './sl-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';

export class SL12jNullFormular extends SLZeugnisFormular {
  get viewType(): FormularViewType {
    return SL12jNullFormularComponent;
  }
}

@Component({
  selector: 'fz-sl-12j-null-formular',
  templateUrl: 'sl-12j-null-formular.component.html',
})
export class SL12jNullFormularComponent extends FormularComponentBase<SL12jNullFormular> {
  static createModel(_formular: SL12jNullFormular) {
    return undefined;
  }

  get model() {
    return SL12jNullFormularComponent.createModel(this.formular);
  }
  slFolgeseiteComponent = SLFolgeseiteComponent;
  slSeitenfussComponent = SLSeitenfussComponent;
  slLegendeComponent = SLLegendeComponent;

  constructor(formularService: FormularService<SL12jNullFormular>) {
    super(formularService);
  }
}
