import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { TransferFile } from '../../../../models/generated/transfer-file';

@Component({
  selector: 'app-import-select-source',
  templateUrl: './import-select-source.component.html',
  styleUrls: ['./import-select-source.component.scss'],
})
export class ImportSelectSourceComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Output() uploadCompleted = new EventEmitter<TransferFile>();
  @Output() internetSelected = new EventEmitter<boolean>();
  @ViewChild('uploader') uploader?: FileUpload;

  uploadUrl = '';
  headers: HttpHeaders;
  loading: boolean = false;

  constructor() {
    this.headers = new HttpHeaders().append('ignoreContentType', 'true');
    this.uploadUrl = '/api/import/upload';
  }

  uploadComplete(event: any): void {
    const result = event.originalEvent.body as TransferFile;
    this.uploadCompleted.next(result);
    this.loading = false;
  }

  uploadStart() {
    this.loading = true;
  }

  uploadError(): void {
    if (this.uploader) {
      this.uploader.clear();
    }
    this.loading = false;
  }

  internet(): void {
    this.internetSelected.next(true);
  }
}
