import { StepsModule } from 'primeng/steps';
import { DropdownModule } from 'primeng/dropdown';
import { PickListModule } from 'primeng/picklist';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { PanelModule } from 'primeng/panel';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { ImportAssiSelectContentComponent } from './import-assi-select-content/import-assi-select-content.component';
import { ImportAssiPasswortComponent } from './import-assi-passwort/import-assi-passwort.component';
import { ImportInternetPinComponent } from './import-internet-pin/import-internet-pin.component';
import { ImportSelectSourceComponent } from './import-select-source/import-select-source.component';

import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';

import { ImportComponent } from './import.component';
import { InputTextModule } from 'primeng/inputtext';
import { AutoFocusModule } from '../../auto-focus.module';
import { SelectKundennummerComponent } from '../select-kundennummer/select-kundennummer.component';

@NgModule({
  declarations: [
    ImportComponent,
    ImportSelectSourceComponent,
    ImportInternetPinComponent,
    ImportAssiPasswortComponent,
    ImportAssiSelectContentComponent,
  ],
  exports: [ImportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    FileUploadModule,
    PanelModule,
    PasswordModule,
    ReactiveFormsModule,
    RadioButtonModule,
    ListboxModule,
    CheckboxModule,
    PickListModule,
    DropdownModule,
    StepsModule,
    InputTextModule,
    AutoFocusModule,
    SelectKundennummerComponent,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class ImportModule {}
