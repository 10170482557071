<fz-panel #shadow>
  <fz-label
    *ngIf="candidates.length > 0 || hasTeilnahme"
    shadow="[...]"
    style="position: absolute; left: -32px"
  ></fz-label>
</fz-panel>
<fz-panel #header *ngFor="let header of headers">
  <div
    style="
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-columns: 49px;
      grid-auto-flow: column;
      font-family: Arial;
      font-size: 9px;
      line-height: 11px;
    "
  >
    <div></div>
    <div style="text-align: center" *ngFor="let headerLabel of headerLabels">
      <div *ngFor="let line of headerLabel">
        <fz-label>{{ line }}</fz-label>
      </div>
    </div>
  </div>
</fz-panel>
<fz-panel #row *ngFor="let row of rows">
  <div
    style="
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-columns: 49px;
      grid-auto-flow: column;
      margin-right: -49px;
    "
  >
    <fz-panel [border]="borderDirection.top" borderColor="LightGray" class="Koennensprofil_Text" padding="normal">
      <div *ngFor="let line of row.lines">
        <fz-label [html]="line"></fz-label>
      </div>
    </fz-panel>
    <fz-panel
      [border]="borderDirection.leftTop"
      borderColor="LightGray"
      *ngFor="let item of [].constructor(headerLabels.length); let i = index"
    >
      <fz-radio [value]="i + 1" [(selectedValue)]="row.value"></fz-radio>
    </fz-panel>
    <fz-panel style="margin-top: 1px; margin-left: 1px">
      <fz-radio [value]="0" [(selectedValue)]="row.value" shadow="/"></fz-radio>
    </fz-panel>
  </div>
</fz-panel>

<ng-container *ngFor="let block of blocks" fzBlock [content]="content" [backDeco]="backDeco" [frontDeco]="frontDeco">
  <ng-template #content="fzPortal" fzPortal>
    <fz-translate fzFocusable tabindex="0">
      <fz-translate [fzPosition]="block.rectRows">
        <ng-container [fzPortalOutlet]="block.shadow?.content"></ng-container>
      </fz-translate>
      <ng-container [fzPortalOutlet]="block.header?.content"></ng-container>
      <fz-translate *ngFor="let row of block.rows" [fzPosition]="row.rect">
        <ng-container [fzPortalOutlet]="row.projectable.content"></ng-container>
      </fz-translate>
      <fz-rect
        *ngIf="rows.length > 0"
        [width]="borderWidth"
        [style.borderColor]="'LightGray'"
        [topStyle]="'solid'"
        [bottomStyle]="'none'"
        [style.top.px]="block.rectRows.y + block.rectRows.height"
        [style.width.px]="block.width"
        [style.height.px]="borderWidth"
      ></fz-rect>
    </fz-translate>
  </ng-template>
  <ng-template #backDeco="fzPortal" fzPortal>
    <div style="position: absolute">
      <div [fzPosition]="block.rectRows">
        <ng-container [fzPortalOutlet]="block.shadow?.backDeco"></ng-container>
      </div>
      <ng-container [fzPortalOutlet]="block.header?.backDeco"></ng-container>
      <div *ngFor="let row of block.rows" style="position: absolute" [fzRect]="row.rectRadios" class="plausi-inside">
        <div
          [ngClass]="{
            'plausi-ok': row.field.error == null && row.field.warning == null,
            'plausi-error': row.field.error,
            'plausi-warning': row.field.warning,
          }"
        ></div>
        <ng-container [fzPortalOutlet]="row.projectable.backDeco"></ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div style="position: absolute">
      <div
        *ngIf="hasFocus && block === blocks[0] && (candidates.length > 0 || hasTeilnahme)"
        fzFocusable
        [style.bottom.px]="-block.rectRows.top"
        [style.left.px]="block.rectRows.left"
        [style.width.px]="block.rectRows.width"
        class="floating fz-note"
        tabindex="0"
      >
        <div [style.display]="hasTeilnahme ? 'block' : 'none'">
          <span class="text-xl m-2">Teilnahme: </span>
          <p-dropdown [options]="teilnahmeCandidates" [(ngModel)]="teilnahme"></p-dropdown>
          <!-- <div *ngFor="let candidate of teilnahmeCandidates">
            <p-radioButton [value]="candidate.value" [(ngModel)]="teilnahme" [label]="candidate.label"></p-radioButton>
          </div> -->
        </div>
        <div [style.display]="candidates.length > 0 ? 'block' : 'none'">
          <div class="text-xl m-2">Ausgeblendete Items</div>
          <p-listbox
            styleClass="Koennensprofil_Text"
            [listStyle]="{ 'max-height': '227px' }"
            [options]="candidates || []"
            (onClick)="candidateClick($event)"
          ></p-listbox>
        </div>
      </div>
      <ng-container [fzPortalOutlet]="block.header?.frontDeco"></ng-container>
      <div *ngFor="let row of block.rows" style="position: absolute" [fzPosition]="row.rect">
        <ng-container [fzPortalOutlet]="row.projectable.frontDeco"></ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
