import { AnlageTyp } from '../../../models/enums/anlage-typ';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { RPZeugnis } from '../../../models/rp-zeugnis';

export class AnlageTypField extends DropdownField<RPZeugnis, AnlageTyp | null> {
  constructor(private zeugnis: RPZeugnis) {
    super(
      {
        label: 'Anlagentyp',
        labelShort: 'AnlTyp',
        required: false,
        property: 'anlageTyp',
        candidates: [
          { displayStringShort: 'keine Anlage', value: null, content: '' },
          { displayStringShort: '1', value: 'Allgemein', content: '- es folgt eine Anlage -' },
          { displayStringShort: '2', value: 'Mehrere', content: '- es folgen Anlagen -' },
        ],
      },
      { object: zeugnis }
    );
  }
  override get value(): AnlageTyp | null {
    return this.zeugnis.anlageTyp !== 'Keine' ? this.zeugnis.anlageTyp : null;
  }
  override set value(value: AnlageTyp | null) {
    this.zeugnis.anlageTyp = value;
  }
}
