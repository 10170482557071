import { Component } from '@angular/core';
import { Formular, FormularViewType } from './formular';
import { FormularComponentBase } from './formular-component-base';

export class NullFormular extends Formular {
  get viewType(): FormularViewType {
    return NullFormularComponent;
  }
}

@Component({
  selector: 'fz-null-formular',
  templateUrl: 'null-formular.component.html',
})
export class NullFormularComponent extends FormularComponentBase<NullFormular> {
  static createModel(_formular: NullFormular) {
    return undefined;
  }

  get model() {
    return NullFormularComponent.createModel(this.formular);
  }
}
