export type FieldDisplayType = 'Table' | 'Detail';
export type FieldDescription = {
  label: string;
  labelShort: string;
  required?: false;
  warn?: true;
  displayType?: FieldDisplayType;
  ignoreReset?: true;
  defaultValue?: unknown;
};

export abstract class Field<TDesc extends FieldDescription = FieldDescription> {
  abstract get key(): string;
  abstract value: unknown;
  abstract displayString: string;
  abstract displayStringShort: string;

  constructor(public description: TDesc) {}
  get error(): string | null {
    if (this.description.required !== false && this.isEmpty) return 'Feld darf nicht leer sein.';
    return null;
  }
  get isEmpty(): boolean {
    return this.value == null;
  }
  get warning(): string | null {
    if (this.description.required === false && this.description.warn === true && this.isEmpty)
      return 'Bitte prüfen, ob Feld leer sein soll.';
    return null;
  }

  reset() {
    this.value = this.description.defaultValue ?? null;
  }
}
