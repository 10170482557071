import { FlinkyAuthService } from './../../services/auth/flinky-auth.service';
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as signalR from '@microsoft/signalr';

import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';

import { AppInfoService } from '../../../shared/services';
import { ChangePasswordService } from './change-password.service';
import { matchingValidator } from '../../validators/must-match-validator';
import { AutoFocusModule } from '../../auto-focus.module';
import { passwordHelp, passwordKomplexRegex, passwordMinLength, passwordRegexString } from '../../../consts';
import { notEqualsValidator } from '../../validators/not-equals-validator';
import { ClientException } from '../../../models/client-exception';
import { NotificationService } from '../../services/notification/notification.service';

const wartenWiederherstellungText = '';
const wartenWiederherstellungHeadline = 'Warte auf Bestätigung der E-Mail-Wiederherstellung';
const wartenWiederherstellungDetails =
  'Bitte warten Sie, während die E-Mail-Wiederherstellung auf das neue Passwort umgestellt wird.';

const erfolgText = '';
const erfolgHeadline = 'Passwortänderung erfolgreich';
const erfolgDetails = 'Ihr Passwort wurde erfolgreich geändert.';

const fehlerText = '';
const fehlerHeadline = 'Passwortänderung nicht erfolgreich';
const fehlerDetails = 'Ihr Passwort konnte nicht geändert werden.';

const erfolgMitWiederherstellungText = '';
const erfolgMitWiederherstellungHeadline = 'Passwortänderung mit Passwortwiederherstellung erfolgreich';
const erfolgMitWiederherstellungDetails1 =
  'Ihr Passwort wurde geändert.\n\nDie Aktualisierung der Passwortwiederherstellung für die E-Mail-Adresse ';
const erfolgMitWiederherstellungDetails2 =
  ' wurde erfolgreich abgeschlossen.\nDie Passwortwiederherstellung ist nun verfügbar.';

const fehlerMitWiederherstellungText = 'Problem!!';
const fehlerMitWiederherstellungHeadline = 'Passwortänderung mit Passwortwiederherstellung nicht erfolgreich';
const fehlerMitWiederherstellungDetails1 =
  'Ihr Passwort wurde geändert.\nDie Aktualisierung der Passwortwiederherstellung für die E-Mail-Adresse ';
const fehlerMitWiederherstellungDetails2 = ' wurde nicht erfolgreich abgeschlossen.';
const fehlerMitWiederherstellungDetails3 =
  'Die Passwortwiederherstellung ist nicht mehr verfügbar!\nBitte führen Sie eine erneute Registrierung durch.';

const initialHeadline = 'Passwort ändern';
const specialChars = '1234567890!@#$%^&*()_+|~-=`{}[]:;<>?,./';
const initialText =
  'Aus Gründen des Datenschutzes muss das Passwort:\n- mindestens 6 Zeichen lang sein und\n- mindestens eines der folgenden Zeichen enthalten: ' +
  specialChars;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  formVisible = true;
  changedText = '';

  passwordHeadline = initialHeadline;
  passwordText = initialText;

  recoveryMail = '';
  connection?: signalR.HubConnection;
  passwordform: UntypedFormGroup;
  passwordHelpList = passwordHelp;

  passwordRegexString = passwordRegexString;
  constructor(
    public appInfo: AppInfoService,
    private changePasswordService: ChangePasswordService,
    private flinkyAuth: FlinkyAuthService,
    private notificationService: NotificationService
  ) {
    this.passwordform = new UntypedFormGroup(
      {
        oldPassword: new UntypedFormControl('', Validators.required),
        newPassword: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(passwordKomplexRegex),
          Validators.minLength(passwordMinLength),
        ]),
        passwordComparison: new UntypedFormControl(''),
      },
      {
        validators: [
          matchingValidator('newPassword', 'passwordComparison'),
          notEqualsValidator('oldPassword', 'newPassword'),
        ],
      }
    );
    this.formVisible = true;
  }

  ngOnInit(): void {
    const baseUrl = document.getElementsByTagName('base')[0].href;

    const url = (baseUrl + '/signalr_passwordrecovery').replace(/([^:]\/)\/+/g, '$1');

    this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();

    this.connection.on('PasswordChangeSignatureRequested', (message: string, result: boolean) => {
      if (result === true) {
        this.passwordHeadline = erfolgMitWiederherstellungHeadline;
        this.passwordText = erfolgMitWiederherstellungText;
        this.changedText = erfolgMitWiederherstellungDetails1 + this.recoveryMail + erfolgMitWiederherstellungDetails2;
      } else {
        this.passwordHeadline = fehlerMitWiederherstellungHeadline;
        this.passwordText = fehlerMitWiederherstellungText;
        this.changedText =
          fehlerMitWiederherstellungDetails1 +
          this.recoveryMail +
          fehlerMitWiederherstellungDetails2 +
          '\n(Meldung des Servers: ' +
          message +
          ')\n\n' +
          fehlerMitWiederherstellungDetails3;
      }
    });

    this.connection.start().catch((err) => console.log(err));
  }

  async onSubmit() {
    try {
      const result = await this.changePasswordService.changePassword(
        this.passwordform.value.oldPassword,
        this.passwordform.value.newPassword,
        this.connection?.connectionId
      );

      this.formVisible = false;
      if (result.passwordChanged) {
        this.flinkyAuth.updateToken(result.token);
      }
      if (result.recoveryRegistered === false) {
        if (result.passwordChanged === true) {
          this.passwordHeadline = erfolgHeadline;
          this.passwordText = erfolgText;
          this.changedText = erfolgDetails;
        } else {
          this.passwordHeadline = fehlerHeadline;
          this.passwordText = fehlerText + '\n' + result.errorMessage;
          this.changedText = fehlerDetails;
        }
      } else {
        this.recoveryMail = result.recoveryEmail;

        this.passwordHeadline = wartenWiederherstellungHeadline;
        this.passwordText = wartenWiederherstellungText;

        this.changedText = wartenWiederherstellungDetails;
      }
    } catch (ex) {
      if (ex instanceof ClientException) {
        this.notificationService.showPermanentError(ex.message, 'Fehler');
      } else throw ex;
    }
  }

  repeat(): void {
    this.formVisible = true;
    this.passwordHeadline = initialHeadline;
    this.passwordText = initialText;
    this.passwordform.reset();
  }
}

@NgModule({
  imports: [
    CommonModule,
    PasswordModule,
    ReactiveFormsModule,
    PanelModule,
    DividerModule,
    ButtonModule,
    AutoFocusModule,
  ],
  declarations: [ChangePasswordComponent],
  exports: [ChangePasswordComponent],
})
export class ChangePasswordModule {}
