<ng-template fzTemplate="sidebar">
  <p-checkbox
    label="Schriftliche Mitteilung aktivieren"
    [binary]="true"
    [(ngModel)]="formular.isMitteilungAktiv"
  ></p-checkbox>
</ng-template>
<fz-formular
  name="SL124hMitteilung"
  [pageMarginTop]="151"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="113"
  [pageHeader]="formular.slFolgeseiteComponent"
  [pageFooter]="formular.slSeitenfussComponent"
>
  <ng-container *ngIf="model != null">
    <fz-sl-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-sl-zeugniskopf>
    <fz-sl-zeugnissatz-mitteilung fzFormularItem="zeugnissatz" fzContainer></fz-sl-zeugnissatz-mitteilung>
    <fz-sl-schueler
      fzFormularItem="schueler"
      fzContainer
      [isFuerHidden]="true"
      [model]="model.schueler"
    ></fz-sl-schueler>
    <fz-section
      fzFormularItem="erziehungsberechtigte"
      [label]="model.erziehungsberechtigte.label"
      [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="none"
      >
        <fz-intelli
          [field]="model.erziehungsberechtigte.field"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="anschrift"
      [label]="model.anschrift.label"
      [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="none"
      >
        <fz-intelli
          [field]="model.anschrift.field"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="teilnehmer"
      [label]="model.teilnehmer.label"
      [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="none"
      >
        <fz-intelli
          [field]="model.teilnehmer.field"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="vorgeseheneGespraechsinhalte"
      [label]="model.vorgeseheneGespraechsinhalte.label"
      [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="none"
      >
        <fz-verbal
          [field]="model.vorgeseheneGespraechsinhalte.field"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="lernentwicklungsbericht"
      [label]="model.lernentwicklungsbericht.label"
      [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="none"
      >
        <fz-verbal
          [field]="model.lernentwicklungsbericht.field"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-section>
    <fz-section
      fzFormularItem="schriftlicheMitteilungHinweise"
      [label]="model.schriftlicheMitteilungHinweise.label"
      [headerBorder]="formular.showBorder ? borderDirection.inverseCup : borderDirection.none"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco
        [border]="formular.showBorder ? borderDirection.cup : borderDirection.none"
        [padding]="formular.showBorder ? 'normal' : 'none'"
        paddingTop="none"
      >
        <fz-intelli
          [field]="model.schriftlicheMitteilungHinweise.field"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-deco>
    </fz-section>
    <fz-sl-schulort fzFormularItem="schulort" [marginBottom]="0" fzContainer [model]="model.schulort"></fz-sl-schulort>
    <fz-sl-unterschrift-protokoll
      fzFormularItem="unterschrift"
      fzContainer
      [model]="model.unterschrift"
    ></fz-sl-unterschrift-protokoll>
    <fz-sl-kenntnis fzFormularItem="kenntnis" fzContainer></fz-sl-kenntnis>
    <fz-panel fzFormularItem="schriftlicheMitteilungSatz" fzContainer>
      <div class="Schuelername">
        <fz-label
          >Hinweis: Ein persönliches Gespräch über die Lern- und Leistungsentwicklung Ihres Kindes ist weitaus
          ausführlicher und effektiver als diese Mitteilung.</fz-label
        >
      </div>
    </fz-panel>
  </ng-container>
</fz-formular>
