import { Directive, Injector, NgZone } from '@angular/core';
import { ProjectableOwnerProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { ContainerDirective } from './container.directive';
import { SectionHeader, SectionHeaderProvider } from './section-block-factory.component';

@Directive({
  selector: '[fzSectionHeader]',
  providers: [
    provideInterfaceBy(ProjectableOwnerProvider, SectionHeaderDirective),
    provideInterfaceBy(SectionHeaderProvider, SectionHeaderDirective),
  ],
})
export class SectionHeaderDirective extends ContainerDirective implements SectionHeader {
  constructor(zone: NgZone, injector: Injector) {
    super(zone, injector);
  }
}
