import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { SLZeugnisFormular } from './sl-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { SLZeugnissatzProtokollComponent } from '../projectables/sl-zeugnissatz-protokoll.component';
import { SLSchuelerComponent } from '../projectables/sl-schueler.component';
import { SLSchulortComponent } from '../projectables/sl-schulort.component';
import { SLUnterschriftProtokollComponent } from '../projectables/sl-unterschrift-protokoll.component';
import { SLGespraechssatzComponent } from '../projectables/sl-gespraechssatz.component';

export class SL12hProtokollFormular extends SLZeugnisFormular {
  get viewType(): FormularViewType {
    return SL12hProtokollFormularComponent;
  }
}

@Component({
  selector: 'fz-sl-12h-protokoll-formular',
  templateUrl: './sl-12h-protokoll-formular.component.html',
})
export class SL12hProtokollFormularComponent extends FormularComponentBase<SL12hProtokollFormular> {
  static createModel(formular: SL12hProtokollFormular) {
    return {
      zeugnissatz: SLZeugnissatzProtokollComponent.createModel(formular),
      schueler: SLSchuelerComponent.createModel(formular),
      erziehungsberechtigte: {
        label: formular.erziehungsberechtigte.description.label,
        field: formular.erziehungsberechtigte,
      },
      anschrift: { label: formular.anschrift.description.label, field: formular.anschrift },
      teilnehmer: { label: formular.teilnehmer.description.label, field: formular.teilnehmer },
      gespraechsinhalte: { label: formular.gespraechsinhalte.description.label, field: formular.gespraechsinhalte },
      weitereBesprechungspunkte: {
        label: formular.weitereBesprechungspunkte.description.label,
        field: formular.weitereBesprechungspunkte,
      },
      vereinbarungen: {
        label: formular.vereinbarungen.description.label,
        field: formular.vereinbarungen,
      },
      schulort: SLSchulortComponent.createModel(formular),
      unterschrift: SLUnterschriftProtokollComponent.createModel(formular),
      gespraechssatz: SLGespraechssatzComponent.createModel(formular),
    };
  }

  get model() {
    return SL12hProtokollFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<SL12hProtokollFormular>) {
    super(formularService);
  }
}
