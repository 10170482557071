import { Directive, ElementRef, HostListener, Input, OnDestroy, QueryList } from '@angular/core';
import { StringElement } from '@modules/pdl';
import { InterfaceProvider } from '@modules/shared/interface-provider';
import { Subscription } from 'rxjs';
import { RichTextSelection } from './rich-text-selection';
import { CaretDirection, PointTarget, TextInputDirective } from './text-input.directive';
import { TextViewAccessor } from './text-view-accessor';
import { TextInputReplaceEvent } from './text-input-replace-event';

export interface TextView {
  stringElementRef: QueryList<ElementRef<StringElement>>;
  text: string;
  selection: RichTextSelection;
}

export class TextViewProvider implements InterfaceProvider<TextView> {
  constructor(public provided: TextView) {}
}

@Directive({ selector: '[fzTextEditor]', exportAs: 'fzTextEditor' })
export class TextEditorDirective implements OnDestroy {
  readonly #textView: TextView;
  readonly #subscriptions: Subscription[] = [];

  constructor(
    textViewProvider: TextViewProvider,
    private textInput: TextInputDirective
  ) {
    this.#textView = textViewProvider.provided;
    this.#subscriptions.push(
      textInput.editableCopy.subscribe((e) => this.onCopy(e)),
      textInput.editablePaste.subscribe((e) => this.onPaste(e)),
      textInput.insert.subscribe((e) => this.onInsert(e)),
      textInput.replace.subscribe((e) => this.onReplace(e)),
      textInput.delete.subscribe(() => this.onDelete()),
      textInput.backspace.subscribe(() => this.onBackspace()),
      textInput.selectAll.subscribe(() => this.onSelectAll()),
      textInput.selectWord.subscribe((e) => this.onSelectWord(e)),
      textInput.moveCaret.subscribe((e) => this.onMoveCaret(e)),
      textInput.pointCaret.subscribe((e) => this.onPointCaret(e)),
      textInput.moveSelection.subscribe((e) => this.onMoveSelection(e))
    );
  }
  @Input() fzKeyFilter: (key: string) => boolean = () => true;

  @HostListener('focusin', ['$event']) onFocusin(_e: FocusEvent): void {
    this.updateSelection();
  }

  ngOnDestroy(): void {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
  }

  get text(): string {
    return this.#textView.text;
  }
  set text(value: string) {
    if (this.#textView.text !== value) {
      this.#textView.text = value;
    }
  }

  get selection(): RichTextSelection {
    return this.#textView.selection;
  }

  onCopy(e: DataTransfer): void {
    if (this.selection.length > 0) {
      e.clearData();
      e.setData('text/plain', this.text.slice(this.selection.start, this.selection.end));
    }
  }

  onPaste(e: DataTransfer): void {
    const data = e.getData('text/plain');
    let pasteText = '';
    for (const char of data) {
      if (this.fzKeyFilter(char)) pasteText += char;
    }
    if (pasteText !== '') {
      this.text = `${this.text.slice(0, this.selection.start)}${pasteText}${this.text.slice(this.selection.end)}`;
      this.selection.setPosition(this.selection.start + pasteText.length);
      this.updateSelection();
    }
  }

  onInsert(e: string): void {
    if (this.fzKeyFilter(e)) {
      this.text = `${this.text.slice(0, this.selection.start)}${e}${this.text.slice(this.selection.end)}`;
      this.selection.setPosition(this.selection.start + e.length);
      this.updateSelection();
    }
  }
  onReplace(e: TextInputReplaceEvent): void {
    if (this.fzKeyFilter(e.text)) {
      this.text = e.text;
      this.selection.setPosition(e.text.length);
      this.updateSelection();
    }
  }

  onDelete(): void {
    if (this.selection.length > 0) {
      this.text = `${this.text.slice(0, this.selection.start)}${this.text.slice(this.selection.end)}`;
    } else if (this.selection.focus < this.text.length) {
      this.text = `${this.text.slice(0, this.selection.focus)}${this.text.slice(this.selection.focus + 1)}`;
    } else return;
    this.selection.setPosition(this.selection.start);
    this.updateSelection();
  }

  onBackspace(): void {
    if (this.selection.length > 0) {
      this.text = `${this.text.slice(0, this.selection.start)}${this.text.slice(this.selection.end)}`;
      this.selection.setFocus(this.selection.start);
    } else if (this.selection.focus > 0) {
      this.text = `${this.text.slice(0, this.selection.focus - 1)}${this.text.slice(this.selection.focus)}`;
      this.selection.setFocus(this.selection.start - 1);
    } else return;
    this.selection.collapseToFocus();
    this.updateSelection();
  }

  onSelectAll(): void {
    this.selection.setBaseAndExtend(0, this.text.length);
    this.updateSelection();
  }

  onSelectWord(e: PointTarget): void {
    const textView = new TextViewAccessor(this.stringElements);
    const index = textView.getIndexByPoint(e);
    if (index != null) {
      let endIndex = index;
      while (endIndex < this.text.length && this.text[endIndex] !== ' ') endIndex++;
      let startIndex = index;
      while (startIndex > 0 && this.text[startIndex - 1] !== ' ') startIndex--;
      this.selection.setBaseAndExtend(startIndex, endIndex);
      this.updateSelection();
    }
  }

  onMoveCaret(e: { direction: CaretDirection; isSelect: boolean }): void {
    const [index, left] = this.getIndexByDirection(e.direction);
    if (left != null) this.selection.setFocusIndexAndLeft(index, left);
    else this.selection.setFocus(index);
    if (!e.isSelect) this.selection.collapseToFocus();
    this.updateSelection();
  }

  onPointCaret(e: PointTarget & { isSelect: boolean }): void {
    const textView = new TextViewAccessor(this.stringElements);
    const index = textView.getIndexByPoint(e);
    if (index != null) {
      this.selection.setFocus(index);
      if (!e.isSelect) this.selection.collapseToFocus();
      this.updateSelection();
    }
  }

  onMoveSelection(e: PointTarget): void {
    const textView = new TextViewAccessor(this.stringElements);
    const textElement = textView.findTextElement(e.target);
    if (textElement != null) {
      let dragIndex = textElement.textRange.start + textElement.getNearestIndexWrtClient(e.clientX);
      if (dragIndex > this.selection.start + this.selection.length) dragIndex -= this.selection.length;
      const moveText = this.text.slice(this.selection.start, this.selection.length);
      this.text = `${this.text.slice(0, this.selection.start)}${this.text.slice(this.selection.end)}`;
      this.text = `${this.text.slice(0, dragIndex)}${moveText}${this.text.slice(dragIndex)}`;
      this.selection.setPosition(dragIndex + this.selection.length);
      this.updateSelection();
    }
  }

  updateSelection(): void {
    const textView = new TextViewAccessor(this.stringElements);
    textView.updateSelection(this.selection.anchor, this.selection.focus, false);
    // this.changeDetector.detectChanges(); // für tabindex
  }

  private get stringElements(): StringElement[] {
    return this.#textView.stringElementRef.toArray().map((ref) => ref.nativeElement);
  }

  private getIndexByDirection(direction: CaretDirection): [number, number?] {
    switch (direction) {
      case CaretDirection.right:
        return this.selection.focus < this.text.length ? [this.selection.focus + 1] : [this.selection.focus];
      // case CaretDirection.WordRight: return [this.getNextWordIndex()];
      case CaretDirection.left:
        return this.selection.focus > 0 ? [this.selection.focus - 1] : [this.selection.focus];
      // case CaretDirection.WordLeft: return [this.getPrevWordIndex()];
      case CaretDirection.contentHome:
        return [0];
      case CaretDirection.lineHome:
        return [0];
      case CaretDirection.contentEnd:
        return [this.text.length];
      case CaretDirection.lineEnd:
        return [this.text.length];
      default:
        return [this.selection.focus];
    }
  }
}
