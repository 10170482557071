import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ProjectableModule as ProjectableModule } from '@modules/projectables/projectable.module';
import { SharedModule } from '@modules/shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { BlockDirective } from './block.directive';
import { DecoBlockFactoryComponent } from './deco-block-factory.component';
import { ContainerBlockFactoryDirective } from './container-block-factory.directive';
import { FachListBlockFactoryComponent } from './fach-list-block-factory.component';
import { FachListItemComponent } from './fach-list-item.component';
import { FachListItemDirective } from './fach-list-item.directive';
import { OptionalBlockFactoryComponent } from './optional-block-factory.component';
import { SectionBlockFactoryComponent } from './section-block-factory.component';
import { SectionHeaderDirective } from './section-header.directive';
import { SequenceBlockFactoryComponent } from './sequence-block-factory.component';

@NgModule({
  imports: [BrowserModule, FormsModule, CheckboxModule, SharedModule, ProjectableModule, ButtonModule],
  exports: [
    ProjectableModule,
    BlockDirective,
    ContainerBlockFactoryDirective,
    SectionBlockFactoryComponent,
    SectionHeaderDirective,
    DecoBlockFactoryComponent,
    SequenceBlockFactoryComponent,
    OptionalBlockFactoryComponent,
    FachListBlockFactoryComponent,
  ],
  declarations: [
    BlockDirective,
    ContainerBlockFactoryDirective,
    SectionBlockFactoryComponent,
    SectionHeaderDirective,
    DecoBlockFactoryComponent,
    SequenceBlockFactoryComponent,
    OptionalBlockFactoryComponent,
    FachListBlockFactoryComponent,
    FachListItemComponent,
    FachListItemDirective,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BlockModule {}
