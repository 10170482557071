import { Subject } from 'rxjs';
import { Formular } from './formular';
import { WorkspaceService } from '../../shared/services';
import { Injectable } from '@angular/core';

@Injectable()
export class FormularService<T extends Formular> {
  formular!: T;
  styleChanged: Subject<boolean> = new Subject<boolean>();
  fitRequested = new Subject<number>();
  recalculateHeightRequested = new Subject<void>();

  constructor(private workspace: WorkspaceService) {}

  setFormular(formular: T): void {
    this.formular = formular;
  }

  raiseStyleChange(): void {
    this.styleChanged.next(true);
  }

  requestFit(pageCount: number): void {
    this.fitRequested.next(pageCount);
  }

  requestRecalculateHeight(): void {
    this.recalculateHeightRequested.next();
  }

  get isReadonly(): boolean {
    return this.workspace.isReadonly;
  }
}
