/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularFach } from '../../../models/formular-fach';
import { HEZeugnisFormular } from './he-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { HEZeugnistypComponent } from '../projectables/he-zeugnistyp.component';
import { HESchuelerComponent } from '../projectables/he-schueler.component';
import { HEZeugnissatzFoerderschwerpunktComponent } from '../projectables/he-zeugnissatz-foerderschwerpunkt.component';
import { HEVersaeumnisComponent } from '../projectables/he-versaeumnis.component';
import { HEUnterschriftComponent } from '../projectables/he-unterschrift.component';

export class HE1234hjGeistigFormular extends HEZeugnisFormular {
  get viewType(): FormularViewType {
    return HE1234hjGeistigFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }
}

@Component({
  selector: 'fz-he-1234hj-geistig-formular',
  templateUrl: 'he-1234hj-geistig-formular.component.html',
})
export class HE1234hjGeistigFormularComponent extends FormularComponentBase<HE1234hjGeistigFormular> {
  static createModel(formular: HE1234hjGeistigFormular) {
    return formular.zeugnis.zeugnissatz.halbjahr === 1
      ? {
          zeugnistyp: HEZeugnistypComponent.createModel(formular),
          schueler: HESchuelerComponent.createModel(formular),
          zeugnissatz: HEZeugnissatzFoerderschwerpunktComponent.createModel(formular),
          allgemeineBeurteilung: {
            label: 'Kompetenzen',
            textField: formular.allgemeineBeurteilung.gesamt.text,
          },
          herkunftssprache: {
            textField: formular.herkunftssprache.gesamt.text,
          },
          sprachkompetenz: {
            textField: formular.sprachkompetenz.gesamt.text,
          },
          bemerkungen: {
            label: formular.bemerkungen.description.label,
            textField: formular.bemerkungen,
          },
          versaeumnis: HEVersaeumnisComponent.createModel(formular),
          unterschrift: HEUnterschriftComponent.createModel(formular),
        }
      : undefined;
  }

  get model() {
    return HE1234hjGeistigFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<HE1234hjGeistigFormular>) {
    super(formularService);
  }
}
