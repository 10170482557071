import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordrecoveryRecoverComponent } from './passwordrecovery-recover/passwordrecovery-recover.component';
import { TagModule } from 'primeng/tag';
import { AutoFocusModule } from '../../auto-focus.module';
import { CheckboxModule } from 'primeng/checkbox';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    PanelModule,
    InputTextModule,
    PasswordModule,
    DialogModule,
    DropdownModule,
    TagModule,
    AutoFocusModule,
    CheckboxModule,
  ],
  declarations: [PasswordrecoveryRecoverComponent],
  exports: [PasswordrecoveryRecoverComponent],
})
export class PasswordrecoveryModule {}
