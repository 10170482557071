/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { getDistance, Distance } from '@modules/dom/distance';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Block, BlockFactory, BlockFactoryOwnerProvider, BlockFactoryProvider, BlockRange } from './block-factory';
import { BlockDirective } from './block.directive';

export type SequenceItem = {
  blockFactory: BlockFactory;
  range: BlockRange;
  top?: number;
  block?: Block;
};
export type Sequence = {
  items: SequenceItem[];
};

@Component({
  selector: 'fz-sequence',
  templateUrl: 'sequence-block-factory.component.html',
  providers: [provideInterfaceBy(BlockFactoryProvider, SequenceBlockFactoryComponent)],
})
export class SequenceBlockFactoryComponent implements AfterContentInit, BlockFactory {
  @Input() gap: Distance = 'none';
  @ContentChildren(BlockFactoryProvider) blockFactoryProviders = new QueryList<BlockFactoryProvider>();
  @ViewChildren(BlockDirective) blocks = new QueryList<BlockDirective>();
  sequences: Sequence[] = [];
  #suppressHeightChange = 0;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private ownerProvider: BlockFactoryOwnerProvider
  ) {}
  get blockFactories(): BlockFactory[] {
    return this.blockFactoryProviders.map((bfp) => bfp.provided);
  }

  ngAfterContentInit(): void {
    this.blockFactoryProviders.changes.subscribe(() => {
      this.#suppressHeightChange++;
      this.ownerProvider.provided.invalidate();
      this.#suppressHeightChange--;
    });
  }

  project(): void {
    for (const blockFactory of this.blockFactories) {
      blockFactory.project();
    }
  }
  getBlockCount(): number {
    return this.blockFactories.reduce(
      (count: number, blockFactory: BlockFactory) => count + blockFactory.getBlockCount(),
      0
    );
  }
  measureHeight(blockRange: BlockRange): number {
    const sequenceItems = this.getSequenceItems(blockRange);
    const gapsHeight = sequenceItems.length > 0 ? (sequenceItems.length - 1) * getDistance(this.gap) : 0;
    return (
      gapsHeight +
      sequenceItems
        .map(({ blockFactory, range }) => blockFactory.measureHeight(range))
        .reduce((sum, height) => sum + height, 0)
    );
  }
  layout(ranges: BlockRange[]): Block[] {
    this.sequences = ranges.map((blockRange) => {
      let top = 0;
      return {
        items: this.getSequenceItems(blockRange).map(({ blockFactory, range }) => {
          const item = { blockFactory, range, top };
          top += blockFactory.measureHeight(range) + getDistance(this.gap);
          return item;
        }),
      };
    });
    for (const blockFactory of this.blockFactories) {
      const blocks = blockFactory.layout(
        this.sequences
          .map((s) => s.items.filter((i) => i.blockFactory === blockFactory).map((i) => i.range))
          .reduce((prev, curr) => [...prev, ...curr], [])
      );
      let index = 0;
      for (const sequence of this.sequences) {
        for (const item of sequence.items) {
          if (item.blockFactory === blockFactory) {
            item.block = blocks[index];
            index++;
          }
        }
      }
    }

    this.detectChanges();
    return this.blocks.toArray();
  }

  private getSequenceItems(range: BlockRange): SequenceItem[] {
    const items: SequenceItem[] = [];
    let index = 0;
    for (const blockFactory of this.blockFactories) {
      const count = blockFactory.getBlockCount();
      if (count === 0) {
        if (range.start <= index && index <= range.start + range.length) {
          items.push({ blockFactory, range: { start: 0, length: 0 } });
        }
      } else {
        for (let i = 0; i < count; i++) {
          if (range.start <= index && index < range.start + range.length) {
            let item = items.find((b) => b.blockFactory === blockFactory);
            if (item == null) {
              item = { blockFactory, range: { start: i, length: 1 } };
              items.push(item);
            } else item.range.length++;
          }
          index++;
        }
      }
    }
    return items;
  }
  private detectChanges() {
    this.#suppressHeightChange++;
    this.changeDetector.detectChanges();
    this.#suppressHeightChange--;
  }
}
