import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

import { RestoreUserBackupComponent } from './restore-user-backup.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { HelpInfoModule } from '../help-info/help-info.module';
import { StepsModule } from 'primeng/steps';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PasswordModule } from 'primeng/password';
import { AutoFocusModule } from '../../auto-focus.module';

@NgModule({
  declarations: [RestoreUserBackupComponent],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    FileUploadModule,
    PanelModule,
    ConfirmDialogModule,
    DialogModule,
    InputTextModule,
    FormsModule,
    CardModule,
    HelpInfoModule,
    StepsModule,
    RadioButtonModule,
    PasswordModule,
    AutoFocusModule,
  ],
  exports: [RestoreUserBackupComponent],
})
export class RestoreUserBackupModule {}
