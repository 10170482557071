<ng-template fzTemplate="sidebar">
  <p-checkbox
    label="Ergänzende Erläuterungen verwenden (ZVO §4 Abs. 5)"
    [binary]="true"
    [(ngModel)]="formular.zeugnis.erlaeuterungenAktiv"
  ></p-checkbox>
</ng-template>
<fz-formular
  name="SL234hjErlaeuterungen"
  [pageMarginTop]="151"
  [pageMarginTopFirst]="57"
  [pageHeader]="slFolgeseiteComponent"
  [pageFooter]="slSeitenfussComponent"
>
  <ng-container *ngIf="model != null">
    <fz-sl-zeugnissatz-erlaeuterungen fzFormularItem="zeugnissatz" fzContainer></fz-sl-zeugnissatz-erlaeuterungen>
    <fz-sl-schueler
      fzFormularItem="schueler"
      fzContainer
      [isFuerHidden]="true"
      [model]="model.schueler"
    ></fz-sl-schueler>
    <fz-deco
      fzFormularItem="erlaeuterungen"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-verbal
        [field]="model.erlaeuterungenField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-deco>
  </ng-container>
</fz-formular>
