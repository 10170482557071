import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import ExportResult from '../../../models/generated/export-result';
import { WorkspaceService } from '../../../shared/services';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { ExportData } from './export-start/export-start.component';
import { ExportService } from './export.service';

const headlineInitial = 'Datentransfer vorbereiten';
const headlineStep2 = 'Flinky Zeugnis Export';
const headlineStepInternet = 'Flinky Cloud - Transfer';
const headlineStepManuellFilename = 'Dateiname für den Export wählen';
const headlineStepManuell = 'Export abgeschlossen';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent implements OnInit {
  step = '1';
  headlineText = headlineInitial;

  currentZeugnis = '';

  exportData: ExportData | null = null;

  exportResult: ExportResult | null = null;

  pin = '';
  items: MenuItem[] = [];
  activeStep: number = 0;
  loading: boolean = false;

  constructor(
    private exportService: ExportService,
    private schuelerService: WorkspaceService,
    private flinkyAuth: FlinkyAuthService
  ) {
    this.items = [
      { label: 'Vorbereitung' },
      { label: 'Ziel auswählen' },
      { label: 'Dateiname wählen' },
      { label: 'Fertig' },
    ];
  }

  async ngOnInit() {
    await this.firstStep();
  }

  async firstStep() {
    if (this.schuelerService.currentJahrgangId) {
      const data = await this.exportService.getExportInfo(
        this.schuelerService.currentJahrgangId,
        this.flinkyAuth.lizenz?.kundennummer ?? ''
      );
      this.step = '1';
      this.activeStep = 0;
      this.headlineText = headlineInitial;

      this.currentZeugnis = data.aktuellesZeugnis;
    }
  }

  export(event: ExportData): void {
    this.exportData = event;
    this.step = '2';
    this.activeStep = 1;
    this.headlineText = headlineStep2;
  }

  targetSelected(target: string): void {
    if (target === 'internet') {
      this.internet();
      this.activeStep = 3;
    } else if (target === 'manuell') {
      this.manuellAskForFilename();
      this.activeStep = 2;
    }
  }

  filenameSelected(filename: string): void {
    this.manuell(filename);
    this.activeStep = 3;
  }

  async internet() {
    if (this.exportData && this.schuelerService.currentJahrgangId) {
      try {
        this.loading = true;
        const data = await this.exportService.exportToInternet(
          this.exportData.name,
          this.exportData.vorname,
          this.exportData.passwort,
          this.schuelerService.currentJahrgangId,
          this.flinkyAuth.lizenz?.kundennummer ?? ''
        );
        this.step = '3';
        this.headlineText = headlineStepInternet;
        this.exportResult = data;
        this.pin = this.exportResult.pin;
        this.activeStep = 3;
      } finally {
        this.loading = false;
      }
    }
  }

  async manuellAskForFilename() {
    this.step = '4';
    this.headlineText = headlineStepManuellFilename;
  }

  async manuell(filename: string = 'transfer.fzt') {
    this.step = '5';
    this.headlineText = headlineStepManuell;
    if (this.exportData && this.schuelerService.currentJahrgangId) {
      try {
        this.loading = true;
        const data = await this.exportService.exportFile(
          this.exportData.name,
          this.exportData.vorname,
          this.exportData.passwort,
          this.schuelerService.currentJahrgangId
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = filename;
        link.click();
        this.activeStep = 3;
      } finally {
        this.loading = false;
      }
    }
  }
}
