import { AfterViewInit, Component } from '@angular/core';

import { AppInfoService } from '../../shared/services';

@Component({
  selector: 'page-delete-account',
  templateUrl: './delete-account-page.component.html',
  styleUrls: ['./delete-account-page.component.scss'],
})
export class DeleteAccountPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Benutzeraccount löschen'));
  }
}
