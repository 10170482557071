import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { KoennensprofilComponent } from '../../shared/components/koennensprofil-editor/koennensprofil.component';
import { CanComponentDeactivate } from '../../shared/guards/can-component-deactivate.guard';
import { AppInfoService } from '../../shared/services';

@Component({
  templateUrl: './koennensprofil-editor-page.component.html',
  styleUrls: ['./koennensprofil-editor-page.component.scss'],
})
export class KoennensprofilEditorPageComponent implements CanComponentDeactivate {
  @ViewChild('kpEditor') kpEditor?: KoennensprofilComponent;
  constructor(private appInfoService: AppInfoService) {}

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.kpEditor ? this.kpEditor.canDeactivate() : true;
  }
}
