import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NIPanelDecoratorBase } from './ni-panel-decorator-base';
import { NIFormular } from '../formulare/ni-formular';

@Component({
  selector: 'fz-ni-versaeumnis',
  templateUrl: 'ni-versaeumnis.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, NIVersaeumnisComponent)],
})
export class NIVersaeumnisComponent extends NIPanelDecoratorBase {
  static createModel(formular: NIFormular) {
    return {
      fehltageGesamtField: formular.fehltageGesamt,
      fehltageUnentschuldigtField: formular.fehltageUnentschuldigt,
    };
  }

  @Input() model: ReturnType<typeof NIVersaeumnisComponent.createModel> | undefined;
}
