import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-versetzungsvermerk',
  templateUrl: 'he-versetzungsvermerk.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, HEVersetzungsvermerkComponent)],
})
export class HEVersetzungsvermerkComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      selectField: formular.versetzungsvermerkTyp,
      klassenkonferenzdatumField:
        formular.zeugnis.zeugnissatz.klassenstufe > 0 &&
        formular.zeugnis.schuelerTyp === 'Intensiv' &&
        (formular.zeugnis.versetzungsvermerkTyp === 'Versetzt' ||
          formular.zeugnis.versetzungsvermerkTyp === 'NichtVersetzt')
          ? formular.zeugnissatz.klassenkonferenzdatum
          : undefined,
      freitextField:
        formular.zeugnis.versetzungsvermerkTyp === 'FreierText' ? formular.versetzungsvermerkText : undefined,
    };
  }

  @Input() model: ReturnType<typeof HEVersetzungsvermerkComponent.createModel> | undefined;

  get labelVersetzt(): string {
    if (
      this.formular.zeugnis.zeugnissatz.klassenstufe < 2 ||
      (this.formular.zeugnis.zeugnissatz.klassenstufe <= 3 &&
        this.formular.zeugnis.schuelerTyp === 'Schuleingangsphase')
    ) {
      return `${this.formular.schueler.personalPronomen} rückt in die Jahrgangsstufe ${
        this.formular.zeugnis.zeugnissatz.klassenstufe + 1
      } vor.`;
    } else {
      return `${this.formular.schueler.personalPronomen} wird in die Jahrgangsstufe ${
        this.formular.zeugnis.zeugnissatz.klassenstufe + 1
      } versetzt.`;
    }
  }

  get labelNichtVersetzt(): string {
    if (
      this.formular.zeugnis.zeugnissatz.klassenstufe < 2 ||
      (this.formular.zeugnis.zeugnissatz.klassenstufe <= 3 &&
        this.formular.zeugnis.schuelerTyp === 'Schuleingangsphase')
    ) {
      return `${this.formular.schueler.personalPronomen} rückt in die Jahrgangsstufe ${
        this.formular.zeugnis.zeugnissatz.klassenstufe + 1
      } nicht vor.`;
    } else {
      return `${this.formular.schueler.personalPronomen} wird in die Jahrgangsstufe ${
        this.formular.zeugnis.zeugnissatz.klassenstufe + 1
      } nicht versetzt.`;
    }
  }
}
