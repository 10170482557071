import { jsPDF, Matrix } from 'jspdf';
import { Quantity } from '../dom/quantity';

export class JSPdfHelper {
  static noTransform(doc: jsPDF) {
    return doc.Matrix(1, 0, 0, 1, 0, 0);
  }

  static translate(doc: jsPDF, x: number, y: number): Matrix {
    return doc.Matrix(1, 0, 0, 1, Quantity.mm2Pt(x), -Quantity.mm2Pt(y));
  }

  static scale(doc: jsPDF, factor: number): Matrix {
    return doc.Matrix(factor, 0, 0, factor, 0, 0);
  }
}
