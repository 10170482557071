import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NIPanelDecoratorBase } from './ni-panel-decorator-base';

@Component({
  selector: 'fz-ni-seitenkopf',
  templateUrl: 'ni-seitenkopf.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, NISeitenkopfComponent)],
})
export class NISeitenkopfComponent extends NIPanelDecoratorBase {
  @Input() pageIndex = 0;
  @Input() hidePage = false;
}
