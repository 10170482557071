/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

 enum PasswordRecoveryRegistrationState {
  neverLoggedIn = 'NeverLoggedIn',
  notConfigured = 'NotConfigured',
  declinedByUser = 'DeclinedByUser',
  validationPending = 'ValidationPending',
  signaturePending = 'SignaturePending',
  finished = 'Finished',
  started = 'Started',
}
export default PasswordRecoveryRegistrationState;
