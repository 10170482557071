import { TextRange } from './text-range';
import itiriri from 'itiriri';
import { NodeHelper, NodeWalker } from '../dom';
import { ContentElement } from './content-element';
import jsPDF from 'jspdf';

export abstract class TextElement extends ContentElement {
  missingCharactorInSaar = ['¹', '²', '³'];
  #textRange: TextRange = { start: 0, length: 0 };
  isTextElement = true;
  implicitLastChar = '';

  constructor() {
    super();
  }

  static is(node: Node): node is TextElement {
    return node instanceof HTMLElement && (node as TextElement).isTextElement === true;
  }

  get textRange(): TextRange {
    return this.#textRange;
  }
  set textRange(textRange: TextRange) {
    this.#textRange = textRange;
  }

  get placeholder(): string | undefined {
    return this.getAttribute('data-placeholder') ?? '';
  }
  set placeholder(placeholder: string | undefined) {
    if (placeholder != null) this.setAttribute('data-placeholder', placeholder);
    else this.removeAttribute('data-placeholder');
  }

  get shadow(): string | undefined {
    return this.getAttribute('data-shadow') ?? '';
  }
  set shadow(value: string | undefined) {
    if (value != null) this.setAttribute('data-shadow', value);
    else this.removeAttribute('data-shadow');
  }

  getNodeAndOffsetByIndex(index: number): { node: Node; offset: number } | null {
    const walker = new NodeWalker(this);
    const textPosition = itiriri(walker.textPositions).find((p) => p.index === index);
    if (textPosition == null) return null;
    return { node: textPosition.node, offset: textPosition.nodeOffset };
  }

  getIndexByNodeAndOffset(node: Node, offset: number): number {
    const walker = new NodeWalker(this);
    return itiriri(walker.textPositions).find((p) => p.node === node && p.nodeOffset === offset)?.index ?? 0;
  }

  getLeftByNodeAndOffset(node: Node, offset: number): number {
    const walker = new NodeWalker(this);
    const first = itiriri(walker.textPositions).first();
    if (first != null) {
      return NodeHelper.getBoundingClientRect({
        startNode: first.node,
        startOffset: first.nodeOffset,
        endNode: node,
        endOffset: offset,
      }).width;
    }
    return 0;
  }

  getLeftByIndex(index: number): number {
    const { node, offset } = this.getNodeAndOffsetByIndex(index) ?? {};
    if (node != null && offset != null) {
      return this.getLeftByNodeAndOffset(node, offset);
    }
    return 0;
  }

  getNearestIndex(left: number): number {
    const walker = new NodeWalker(this);
    const first = itiriri(walker.textPositions).first();
    if (first) {
      const position = itiriri(walker.textPositions)
        .map((p) => ({
          dist: Math.abs(left - this.getLeftByNodeAndOffset(p.node, p.nodeOffset)),
          pos: p,
        }))
        .sort((pos1, pos2) => (pos1.dist === pos2.dist ? 0 : pos1.dist < pos2.dist ? -1 : 1))
        .map(({ pos }) => pos)
        .first();
      if (position != null) {
        return position.index;
      }
    }
    return 0;
  }

  getNearestIndexWrtClient(clientLeft: number): number {
    return this.getNearestIndex(clientLeft - this.getBoundingClientRect().left);
  }

  exportWord(
    doc: jsPDF,
    text: string,
    fontFamily: string,
    fontWeight: string,
    fontSize: number,
    left: number,
    top: number
  ): void {
    fontFamily = fontFamily.replace(/ /g, '_').replace(/"/g, '');
    if (fontFamily.toLowerCase() === 'saar-regular' && this.missingCharactorInSaar.some((mc) => text.includes(mc))) {
      // Sonderbehandlung für Zeichen, die im Font nicht definiert sind (¹²³). Diese werden als Arial gedruckt. Times New Roman zeigte falsche Zeichenbreite.
      let x = 0;
      for (const c of text) {
        if (this.missingCharactorInSaar.some((mc) => mc === c)) {
          doc.setFont('Arial', 'normal', fontWeight);
        } else {
          doc.setFont(fontFamily, 'normal', fontWeight);
        }
        doc.setFontSize(fontSize);
        doc.text(c, x + left, top, {
          align: 'left',
          baseline: 'top',
        });
        x += (doc.getStringUnitWidth(c) * fontSize) / (72 / 25.4);
      }
    } else {
      doc.setFont(fontFamily, 'normal', fontWeight);
      doc.setFontSize(fontSize);
      doc.text(text, left, top, {
        align: 'left',
        baseline: 'top',
        // lineHeightFactor: 1,
      });
    }
  }
}
