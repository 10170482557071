<div #intelliDiv data-style="max-height: '189px'; min-height: '189px'" *ngIf="!loading">
  <p-listbox
    #items
    [listStyle]="{ 'max-height': '189px', 'min-height': '189px' }"
    class="h-0"
    [options]="texte"
    (onDblClick)="textSelected()"
    [group]="true"
    optionGroupChildren="texte"
    emptyMessage="- keine Inhalte -"
    [(ngModel)]="selectedTextbaustein"
  >
    <ng-template let-gruppe pTemplate="group">
      <div class="font-bold text-sm border-b-2 border-gray-300 border-solid">{{ gruppe.anmerkung }}</div>
    </ng-template>
    <ng-template let-text pTemplate="item">
      <div>{{ text }}</div>
    </ng-template>
  </p-listbox>
  <button
    pButton
    type="button"
    label="Eigene Textbausteine bearbeiten"
    (click)="editorRequested.emit([description])"
  ></button>
  <button
    class="float-right"
    icon="pi pi-plus"
    label="Einfügen"
    pButton
    type="button"
    (click)="textSelected()"
    [disabled]="selectedTextbaustein == null"
  ></button>
</div>
<div *ngIf="loading" class="custom-skeleton p-p-4" style="max-height: '189px'; min-height: '189px'">
  <ul class="p-m-0 p-p-0">
    <li class="p-mb-3">
      <div class="p-d-flex">
        <!-- <p-skeleton shape="circle" size="4rem" styleClass="p-mr-2"></p-skeleton> -->
        <div style="flex: 1">
          <p-skeleton width="100%" styleClass="p-mb-2"></p-skeleton>
          <p-skeleton width="75%"></p-skeleton>
        </div>
      </div>
    </li>
    <li class="p-mb-3">
      <div class="p-d-flex">
        <!-- <p-skeleton shape="circle" size="4rem" styleClass="p-mr-2"></p-skeleton> -->
        <div style="flex: 1">
          <p-skeleton width="100%" styleClass="p-mb-2"></p-skeleton>
          <p-skeleton width="75%"></p-skeleton>
        </div>
      </div>
    </li>
    <li class="p-mb-3">
      <div class="p-d-flex">
        <!-- <p-skeleton shape="circle" size="4rem" styleClass="p-mr-2"></p-skeleton> -->
        <div style="flex: 1">
          <p-skeleton width="100%" styleClass="p-mb-2"></p-skeleton>
          <p-skeleton width="75%"></p-skeleton>
        </div>
      </div>
    </li>
  </ul>
</div>
