<ng-container *ngIf="workspace.isZeugnissatzLocked && workspace.currentZeugnissatz != null">
  <p>
    Datum: <strong>{{ currentDate }}</strong>
    <br />
    Aktuelle Zeugnisse:
    <strong
      >{{ workspace.currentZeugnissatz.halbjahr + 1 }}. Hj. | {{ workspace.currentZeugnissatz.schuljahr }}/
      {{ workspace.currentZeugnissatz.schuljahr + 1 }}</strong
    >
  </p>
  <p>Das aktuelle Datum passt nicht zu den aktuell dargestellten Zeugnissen.</p>
  <p>Was möchten Sie tun?</p>
  <button
    *ngIf="workspace.isZeugnissatzInZukunft"
    pButton
    label="Zeugnisse löschen"
    (click)="workspace.zeugnisseLoeschen()"
  ></button>
  <ng-container *ngIf="!workspace.isZeugnissatzInZukunft">
    <button
      *ngIf="workspace.currentZeugnissatz.canWeiterfuehren"
      pButton
      (click)="router.navigate(['/jahrgang-weiter'])"
    >
      <span class="p-button-label">Nächstes Halbjahr erstellen<br />(auch für Abgangszeugnis)</span>
    </button>
    <p class="mt-5">Als Lehrer die Schule gewechselt?</p>
    <button
      *ngIf="workspace.currentZeugnissatz.canWeiterfuehren"
      pButton
      class="p-button-secondary"
      (click)="router.navigate(['/jahrgang-neu'])"
    >
      <span class="p-button-label">Neuen Jahrgang erstellen<br />und neue Lizenz verwenden</span>
    </button>
    <button
      *ngIf="!workspace.currentZeugnissatz.canWeiterfuehren"
      pButton
      label="Neuen Jahrgang erstellen"
      (click)="router.navigate(['/jahrgang-neu'])"
    ></button>
  </ng-container>
  <p class="mt-5">Korrektur nötig?</p>
  <button
    pButton
    class="p-button-secondary"
    label="Trotzdem bearbeiten"
    (click)="workspace.zeugnissatzUnlocked = true"
  ></button>
</ng-container>
