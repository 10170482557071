import { SLFolgeseiteComponent } from '../projectables/sl-folgeseite.component';
import { SLSeitenfussComponent } from '../projectables/sl-seitenfuss.component';
import { SLFormularCategoryKey } from '../sl-formularsatz';
import { SLFormular } from './sl-formular';

export abstract class SLMitteilungFormular extends SLFormular {
  slFolgeseiteComponent = SLFolgeseiteComponent;
  slSeitenfussComponent = SLSeitenfussComponent;

  get category(): SLFormularCategoryKey {
    return 'mitteilung';
  }
}
