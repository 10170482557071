<fz-panel
  *ngIf="model != null"
  name="Schueler"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div
    style="
      margin-right: -57px;
      display: grid;
      grid-template-columns: auto 1fr 1fr 1fr 1fr 57px;
      grid-template-rows: auto auto;
      align-items: baseline;
    "
  >
    <fz-label *ngIf="!isFuerHidden" class="Schuelerelement_Beschriftung" style="grid-row: 1; grid-column: 1"
      >für&nbsp;</fz-label
    >
    <fz-text
      class="Schuelername"
      style="grid-row: 1; grid-column: 2"
      [field]="model.schuelerNameField"
      placeholder="Name"
    ></fz-text>
    <fz-panel class="Unterschriften_Beschriftung" style="grid-row: 2; grid-column: 2" [border]="borderDirection.top">
      <fz-label>{{ model.schuelerNameLabel }}</fz-label>
    </fz-panel>
    <fz-text
      class="Schuelername"
      style="grid-row: 1; grid-column: 3"
      [field]="model.schuelerVornameField"
      placeholder="Vorname"
    ></fz-text>
    <fz-panel class="Unterschriften_Beschriftung" style="grid-row: 2; grid-column: 3" [border]="borderDirection.top">
      <fz-label>{{ model.schuelerVornameLabel }}</fz-label>
    </fz-panel>
    <fz-date
      class="Schuelername"
      style="grid-row: 1; grid-column: 4"
      [field]="model.schuelerGeburtsdatumField"
      placeholder="Geburtsdatum"
    ></fz-date>
    <fz-panel class="Unterschriften_Beschriftung" style="grid-row: 2; grid-column: 4" [border]="borderDirection.top">
      <fz-label>{{ model.schuelerGeburtsdatumLabel }}</fz-label>
    </fz-panel>
    <fz-text
      class="Schuelername"
      style="grid-row: 1; grid-column: 5"
      [field]="model.klassenbezeichnungField"
      placeholder="Klassenbezeichnung"
    ></fz-text>
    <fz-panel class="Unterschriften_Beschriftung" style="grid-row: 2; grid-column: 5" [border]="borderDirection.top">
      <fz-label>{{ model.klassenbezeichnungLabel }}</fz-label>
    </fz-panel>
    <fz-dropdown
      class="Schuelername"
      style="grid-row: 1; grid-column: 6"
      [isShadow]="true"
      [field]="model.geschlechtField"
    ></fz-dropdown>
  </div>
</fz-panel>
