import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'fz-schuelerliste-import-fileselection',
  templateUrl: './schuelerliste-import-fileselection.component.html',
})
export class SchuelerlisteImportFileselectionComponent {
  @Output() next: EventEmitter<File> = new EventEmitter<File>();
  @ViewChild('uploader') uploader?: FileUpload;

  reset() {
    this.uploader?.clearInputElement();
    this.uploader?.clearIEInput();
    this.uploader?.clear();
  }

  openFileSelectionDialog(): void {
    this.uploader?.clearInputElement();
    this.uploader?.clearIEInput();
    this.uploader?.clear();
    setTimeout(() => {
      const input: HTMLInputElement = this.uploader?.basicFileInput?.nativeElement;
      input.click();
    });
  }
}
