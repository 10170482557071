import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { Router } from '@angular/router';
import { ZeugnissatzViewModel } from '@modules/formular/formular';
import { NotificationService } from '../../services/notification/notification.service';
import { WorkspaceService } from '../../services';
import { Zeugnissatz } from '../../../models/zeugnissatz';
import { Jahrgang } from '../../../models/jahrgang';
import { JahrgangService } from '../../services/jahrgang.service';

@Component({
  selector: 'fz-jahrgang-aendern',
  templateUrl: './jahrgang-aendern.component.html',
  styleUrls: ['./jahrgang-aendern.component.scss'],
})
export class JahrgangAendernComponent implements OnChanges {
  @Input() id: string = '';
  steps = [{ label: 'Lizenz auswählen' }, { label: 'Daten ändern' }];
  activeStep = 0;
  jahrgangform: UntypedFormGroup;
  isLoading: boolean = false;
  isSL: boolean = false;
  zeugnissatz?: Zeugnissatz;
  zsVm?: ZeugnissatzViewModel;

  get jahrgang(): Jahrgang | undefined {
    return this.zeugnissatz?.jahrgang;
  }

  constructor(
    public authService: FlinkyAuthService,
    private jahrgangService: JahrgangService,
    private router: Router,
    private notificationService: NotificationService,
    private schuelerService: WorkspaceService
  ) {
    this.jahrgangform = new UntypedFormGroup({
      schulleitung: new UntypedFormControl('', Validators.required),
      klassenbezeichnung: new UntypedFormControl('', Validators.required),
      klassenleitung: new UntypedFormControl('', Validators.required),
      rufname: new UntypedFormControl(''),
      kundennummer: new UntypedFormControl(''),
      schuljahr: new UntypedFormControl('', Validators.required),
    });
    this.isSL = this.authService.lizenz?.bundesland === 'SL';
  }

  async ngOnChanges(): Promise<void> {
    if (this.id) {
      const jahrgang = await this.jahrgangService.getJahrgang(this.id);
      this.zeugnissatz = jahrgang?.currentZeugnissatz;
      if (this.zeugnissatz != null) {
        this.jahrgangform.patchValue({
          schulleitung: this.zeugnissatz.schulleitung,
          klassenbezeichnung: this.zeugnissatz.klassenbezeichnung,
          klassenleitung: this.zeugnissatz.klassenleitung,
          rufname: this.zeugnissatz.jahrgang.rufname,
          schuljahr: this.zeugnissatz.schuljahr,
        });
        this.zsVm = new ZeugnissatzViewModel(this.zeugnissatz);
      }
    }
  }

  cancel(): void {
    this.router.navigate(['/jahrgang-oeffnen']);
  }
  async onSubmit() {
    if (this.zeugnissatz != null) {
      const formValue = this.jahrgangform.value;
      this.zeugnissatz.schulleitung = formValue.schulleitung;
      this.zeugnissatz.klassenbezeichnung = formValue.klassenbezeichnung;
      this.zeugnissatz.klassenleitung = formValue.klassenleitung;
      this.zeugnissatz.jahrgang.rufname = formValue.rufname;
      const diff = this.zeugnissatz.schuljahr - formValue.schuljahr;
      for (const zeugnissatz of this.zeugnissatz.jahrgang.zeugnissaetze) {
        zeugnissatz.schuljahr -= diff;
      }
      if (this.authService.lizenz != null) {
        this.zeugnissatz.jahrgang.schullizenz = this.authService.lizenz;
      }
      try {
        this.isLoading = true;
        await this.jahrgangService.updateJahrgang(this.zeugnissatz.jahrgang);
        this.notificationService.showInfo('Die Änderungen wurden gespeichert.', 'Erfolg');
        if (this.zeugnissatz.jahrgang.id === this.schuelerService.currentJahrgangId) {
          await this.schuelerService.reopen();
        }
        this.router.navigate(['/jahrgang-oeffnen']);
      } catch {
        this.notificationService.showPermanentError('Die Änderungen konnten nicht gespeichert werden.', 'Fehler');
      } finally {
        this.isLoading = false;
      }
    }
  }

  get lizenzImage() {
    return this.authService.lizenz?.findZeugniskopflizenz(this.zeugnissatz)?.zeugniskopf;
  }
}
