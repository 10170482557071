import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Input, Output, ViewChild, Component, TemplateRef } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import ImportTbResult from '../../../../models/generated/import-tb-result';
import { ImportTbService } from '../import-tb.service';

@Component({
  selector: 'app-import-tb-step1',
  templateUrl: './import-tb-step1.component.html',
  styleUrls: ['./import-tb-step1.component.scss'],
})
export class ImportTbStep1Component {
  @Input() header: TemplateRef<any> | undefined;
  @Output() uploadCompleted = new EventEmitter<ImportTbResult>();
  @Output() internetSelected = new EventEmitter<ImportTbResult>();
  @Output() restartSelected = new EventEmitter<boolean>();
  @ViewChild('uploader')
  uploader?: FileUpload;
  uploadUrl = '';
  headers: HttpHeaders;
  loading: boolean = false;

  constructor(private importTbService: ImportTbService) {
    this.uploadUrl = '/api/textbaustein/uploadAsync';
    this.headers = new HttpHeaders().append('ignoreContentType', 'true');
  }

  uploadComplete(event: any) {
    const data = event.originalEvent.body;

    this.uploadCompleted.next(data);
  }

  uploadError(): void {
    if (this.uploader) {
      this.uploader.clear();
    }
  }

  async internet() {
    try {
      this.loading = true;
      const data = await this.importTbService.getServerTbInfo();
      this.internetSelected.emit(data);
    } finally {
      this.loading = false;
    }
  }
}
