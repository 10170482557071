<fz-panel
  *ngIf="model != null"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div style="display: grid; grid-template-columns: 1fr 1fr; grid-template-rows: 38px auto; column-gap: 113px">
    <fz-panel
      *ngIf="model.klassenleitungField.value !== ''"
      style="grid-row: 1; grid-column: 1"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 1; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.klassenleitungField"
      placeholder="Bezeichnung Klassenleitung"
    ></fz-text>
    <fz-panel
      *ngIf="model.foerderschullehrerField.value !== ''"
      style="grid-row: 1; grid-column: 2"
      [border]="borderDirection.bottom"
    ></fz-panel>
    <fz-text
      style="grid-row: 2; grid-column: 2; justify-self: center"
      class="Unterschriften_Beschriftung"
      [field]="model.foerderschullehrerField"
      placeholder="ggf. Bezeichnung FörderschullehrerIn"
    ></fz-text>
  </div>
</fz-panel>
