import { Formular } from '@modules/formular/formular';
import { Fach } from './fach';
import { KPRowField } from './fields/kp-item-benotung-field';
import { BereichDescription, FormularBereich } from './formular-bereich';

export type FachDescription = BereichDescription & {
  labelKey?: string;
  linkedFaecher?: FormularFach[];
  kpZeugnissatzFachKey?: string;
  teilnahmeCandidates?: { label: string; value: string | null }[];
  orderedKPBereichKeys?: string[];
};

export class FormularFach {
  fach: Fach;
  bereiche: FormularBereich[] = [];
  gesamt: FormularBereich;
  kpRows: KPRowField[] = [];
  constructor(
    public formular: Formular,
    fachOrKey: Fach | string,
    public readonly description: FachDescription
  ) {
    this.fach = fachOrKey instanceof Fach ? fachOrKey : formular.zeugnis.faecher.getItem(fachOrKey);
    this.gesamt = this.add(new FormularBereich(formular, this, null, description));
    const rasterFach =
      description.kpZeugnissatzFachKey != null
        ? this.fach.getRasterFach(description.kpZeugnissatzFachKey)
        : this.fach.rasterFach;
    this.kpRows = (description.orderedKPBereichKeys ?? [null])
      .map((bereichKey) => rasterFach.bereiche.getItem(bereichKey))
      .map((b) =>
        Array.from(b.items)
          .filter((item) => (item.text?.trim() ?? '').length > 0)
          .map(
            (item) =>
              new KPRowField(
                {
                  ...description,
                  required: this.formular.areKPItemsOptional ? false : undefined,
                  warn: this.formular.warnKPItemsIfOptional ? true : undefined,
                },
                { benotung: this.gesamt.bereich.items.getBenotung(item), bereich: b }
              )
          )
      )
      .reduce((prev: KPRowField[], curr: KPRowField[]) => [...prev, ...curr], []);
  }

  get key(): string {
    return this.fach.key ?? '';
  }

  get kpRowsVisible(): KPRowField[] {
    return this.kpRows
      .filter((r) => this.fach.teilnahmeBereichKey == null || r.bereich?.key === this.fach.teilnahmeBereichKey)
      .filter(
        (r) =>
          (this.formular.zeugnis.zeugnissatz.halbjahr === 0 || this.gesamt.bereich.note?.typ !== 'Slash') &&
          r.value !== 0
      );
  }
  get kpRowsHidden(): KPRowField[] {
    if (this.formular.zeugnis.zeugnissatz.halbjahr !== 0 && this.gesamt.bereich.note?.typ === 'Slash') return [];
    else
      return this.kpRows
        .filter((r) => this.fach.teilnahmeBereichKey == null || r.bereich?.key === this.fach.teilnahmeBereichKey)
        .filter((r) => r.value === 0);
  }

  add(bereich: FormularBereich): FormularBereich {
    this.bereiche.push(bereich);
    return bereich;
  }
}
