<fz-panel *ngIf="model != null">
  <div style="margin-bottom: 8px; display: grid; grid-template-columns: 1fr 1fr" *ngIf="model.kopfnoten != null">
    <div
      style="
        grid-column: 1;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-auto-rows: min-content;
        row-gap: 8px;
        column-gap: 4px;
      "
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.kopfnoten.sozialverhalten.label }}:</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-note [field]="model.kopfnoten.sozialverhalten.noteField"></fz-note>
      </div>
    </div>
    <div
      style="
        grid-column: 2;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-auto-rows: min-content;
        row-gap: 8px;
        column-gap: 4px;
      "
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.kopfnoten.lernArbeitsverhalten.label }}:</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-note [field]="model.kopfnoten.lernArbeitsverhalten.noteField"></fz-note>
      </div>
    </div>
  </div>
  <div class="Ueberschriften2"><fz-label>Leistungen</fz-label></div>
  <div style="margin-top: 8px; display: grid; grid-template-columns: 1fr 1fr">
    <div
      style="grid-column: 1; display: grid; grid-template-columns: 189px 1fr; grid-auto-rows: min-content; row-gap: 8px"
    >
      <ng-container *ngIf="model.religion != null"
        ><!--SEP zweites Halbjahr oder 3/4 Klasse -->
        <div class="Noten_Beschriftung">
          <fz-label>{{ model.religion.label }}:</fz-label>
        </div>
        <div class="Noten_Text"><fz-note [field]="model.religion.noteField"></fz-note></div>
      </ng-container>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.deutschGesamt.label }}:</fz-label>
      </div>
      <div class="Noten_Text"><fz-note [field]="model.deutschGesamt.noteField"></fz-note></div>
      <div class="Noten_Beschriftung" style="margin-left: 19px">
        <fz-label>{{ model.sprechenZuhoeren.label }}:</fz-label>
      </div>
      <div class="Noten_Text"><fz-note [field]="model.sprechenZuhoeren.noteField"></fz-note></div>
      <div class="Noten_Beschriftung" style="margin-left: 19px">
        <fz-label>{{ model.lesen.label }}:</fz-label>
      </div>
      <div class="Noten_Text"><fz-note [field]="model.lesen.noteField"></fz-note></div>
      <ng-container *ngIf="model.texteVerfassen != null"
        ><!--3/4 Klasse -->
        <div class="Noten_Beschriftung" style="margin-left: 19px">
          <fz-label>{{ model.texteVerfassen.label }}:</fz-label>
        </div>
        <div class="Noten_Text"><fz-note [field]="model.texteVerfassen.noteField"></fz-note></div>
      </ng-container>
      <div class="Noten_Beschriftung" style="margin-left: 19px">
        <fz-label>{{ model.rechtschreiben.label }}:</fz-label>
      </div>
      <div class="Noten_Text"><fz-note [field]="model.rechtschreiben.noteField"></fz-note></div>
    </div>
    <div
      style="grid-column: 2; display: grid; grid-template-columns: 1fr 1fr; grid-auto-rows: min-content; row-gap: 8px"
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.sachunterricht.label }}:</fz-label>
      </div>
      <div class="Noten_Text"><fz-note [field]="model.sachunterricht.noteField"></fz-note></div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.mathematik.label }}:</fz-label>
      </div>
      <div class="Noten_Text"><fz-note [field]="model.mathematik.noteField"></fz-note></div>
      <ng-container *ngIf="model.weitere != null"
        ><!--SEP zweites Halbjahr oder 3/4 Klasse -->
        <div class="Noten_Beschriftung">
          <fz-label>{{ model.weitere.bildendeKunst.label }}:</fz-label>
        </div>
        <div class="Noten_Text"><fz-note [field]="model.weitere.bildendeKunst.noteField"></fz-note></div>
        <div class="Noten_Beschriftung">
          <fz-label>{{ model.weitere.musik.label }}:</fz-label>
        </div>
        <div class="Noten_Text"><fz-note [field]="model.weitere.musik.noteField"></fz-note></div>
        <div class="Noten_Beschriftung">
          <fz-label>{{ model.weitere.sport.label }}:</fz-label>
        </div>
        <div class="Noten_Text"><fz-note [field]="model.weitere.sport.noteField"></fz-note></div>
        <ng-container *ngIf="model.weitere.schrift != null">
          <div class="Noten_Beschriftung">
            <fz-label>{{ model.weitere.schrift.label }}:</fz-label>
          </div>
          <div class="Noten_Text"><fz-note [field]="model.weitere.schrift.noteField"></fz-note></div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</fz-panel>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div style="position: absolute">
    <ng-container [fzPortalOutlet]="panel?.frontDeco"></ng-container>
  </div>
  <div [fzPosition]="panel?.rect" fzFocusable tabindex="0">
    <fz-sl-notensystem
      *ngIf="hasFocus"
      [style.bottom.px]="panel?.rect?.top || 0"
      [style.left.px]="panel?.rect?.left || 0"
      [style.width.px]="panel?.rect?.width || 0"
      style="display: block"
      class="floating fz-note"
    >
    </fz-sl-notensystem>
  </div>
</ng-template>
