import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigateToZeugnisComponent } from './navigate-to-zeugnis.component';

@NgModule({
  declarations: [NavigateToZeugnisComponent],
  imports: [CommonModule, ButtonModule, RouterModule],
  exports: [NavigateToZeugnisComponent],
})
export class NavigateToZeugnisModule {}
