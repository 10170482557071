import { Type } from '@angular/core';
import { BundeslandTyp } from '../../models/enums/bundesland-typ';
import { Zeugnis } from '../../models/zeugnis';
import { Formularsatz } from './formularsatz';
import { FormularOverview, FormularOverviewColumn, FormularOverviewRow } from './formular-overview';
import { Field } from '../../models/fields/field';
import { Zeugnissatz } from '../../models/zeugnissatz';

export class FormularFactory {
  static readonly formularsaetze: Formularsatz[] = [];
  static registerFormularsatz(formularsatz: Formularsatz): void {
    this.formularsaetze.push(formularsatz);
  }
  static getFormularsatz(bundeslandOrZeugnisOrZeugnissatz: BundeslandTyp | Zeugnis | Zeugnissatz): Formularsatz {
    const bundesland =
      typeof bundeslandOrZeugnisOrZeugnissatz === 'string'
        ? bundeslandOrZeugnisOrZeugnissatz
        : bundeslandOrZeugnisOrZeugnissatz instanceof Zeugnis
          ? bundeslandOrZeugnisOrZeugnissatz.zeugnissatz.jahrgang?.bundesland
          : bundeslandOrZeugnisOrZeugnissatz.jahrgang?.bundesland;
    if (bundesland != null) {
      return this.formularsaetze.filter((s) => s.bundesland === bundesland)[0];
    } else return this.formularsaetze[0];
  }

  static createFormular(zeugnis: Zeugnis, category: string) {
    return FormularFactory.getFormularsatz(zeugnis).createFormular(zeugnis, category);
  }

  static createOverview(zeugnisse: Zeugnis[]): FormularOverview {
    const categories = zeugnisse.length > 0 ? this.getFormularsatz(zeugnisse[0]).overviewCategories : ['zeugnis'];
    const rows = zeugnisse.map((z) =>
      categories
        .map((cat) => this.createFormular(z, cat).getOverviewRow())
        .reduce((prev: FormularOverviewRow, curr) => (curr != null ? { ...prev, ...curr } : prev), {})
    );
    const columns = categories
      .map((cat) =>
        zeugnisse
          .map((z) => this.createFormular(z, cat).getOverviewRow())
          .map((row) => FormularFactory.createOverviewColumns(row))
          .reduce((cs, csAdd) => FormularFactory.insertIfNotExists(cs, csAdd), [])
      )
      .reduce((cs, csAdd) => FormularFactory.appendIfNotExists(cs, csAdd), []);
    return { columns, rows };
  }

  static createOverviewColumns(row: FormularOverviewRow): FormularOverviewColumn[] {
    return Object.keys(row).map((key) => ({
      key,
      type: row[key].field.constructor as Type<Field>,
      description: row[key].field.description,
    }));
  }

  static appendIfNotExists(columns: FormularOverviewColumn[], columnsAdd: FormularOverviewColumn[]) {
    return columnsAdd.reduce((cs, column) => (cs.find((c) => c.key === column.key) ? cs : [...cs, column]), columns);
  }

  static insertIfNotExists(columns: FormularOverviewColumn[], columnsAdd: FormularOverviewColumn[]) {
    return columnsAdd.reduce(
      ({ columns: cols, index }, column) =>
        cols.find((c) => c.key === column.key)
          ? {
              columns: cols,
              index:
                Math.max(
                  index,
                  cols.findIndex((c) => c.key === column.key)
                ) + 1,
            }
          : { columns: [...cols.slice(0, index), column, ...cols.slice(index)], index: index + 1 },
      { columns, index: 0 }
    ).columns;
  }
}
