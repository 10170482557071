import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NIPanelDecoratorBase } from './ni-panel-decorator-base';
import { NIFormular } from '../formulare/ni-formular';

@Component({
  selector: 'fz-ni-zeugnissatz',
  templateUrl: 'ni-zeugnissatz.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, NIZeugnissatzComponent)],
})
export class NIZeugnissatzComponent extends NIPanelDecoratorBase {
  static createModel(formular: NIFormular) {
    return {
      zeugnis:
        formular.category === 'zeugnis'
          ? {
              klassenbezeichnungField: formular.zeugnissatz.klassenbezeichnung,
            }
          : undefined,
      lernstand:
        formular.category === 'lernstand'
          ? {
              aufnahmeAmField: formular.lernstandAufnahmeAm,
              sprachfoerderAmField: formular.lernstandSprachfoerderAm,
              sprachfoerderartField: formular.lernstandSprachfoerderart,
              klassenbezeichnungField: formular.zeugnissatz.klassenbezeichnung,
            }
          : undefined,
    };
  }

  @Input() model: ReturnType<typeof NIZeugnissatzComponent.createModel> | undefined;
}
