import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppInfoService {
  private _currentPageTitle = '';
  private _currentPageTitleTemplate: TemplateRef<any> | undefined;

  public get title(): string {
    return 'Flinky Zeugnis Evo';
  }

  public get currentPageTitle() {
    return this._currentPageTitle;
  }
  public set currentPageTitle(value) {
    this._currentPageTitle = value;
    this._currentPageTitleTemplate = undefined;
  }

  public get currentPageTitleTemplate(): TemplateRef<any> | undefined {
    return this._currentPageTitleTemplate;
  }
  public set currentPageTitleTemplate(value: TemplateRef<any> | undefined) {
    this._currentPageTitle = '';
    this._currentPageTitleTemplate = value;
  }
}
