import { Schueler } from '../schueler';
import { Zeugnis } from '../zeugnis';
import { PropertyFieldDescription, PropertyFieldRef } from './property-field';
import { TextField } from './text-field';
import { OnlyPropsOfType } from './types';

export type SchuelerTextFieldDescription = PropertyFieldDescription<Zeugnis, string | null> & {
  schuelerProperty: keyof OnlyPropsOfType<Schueler, string | null>;
};

export class SchuelerTextField extends TextField<Zeugnis, SchuelerTextFieldDescription> {
  zeugnis: Zeugnis;
  schueler: OnlyPropsOfType<Schueler, string | null>;

  constructor(description: SchuelerTextFieldDescription, ref: PropertyFieldRef<Zeugnis, string | null>) {
    super(description, ref);
    this.zeugnis = ref.object as Zeugnis;
    this.schueler = this.zeugnis.schueler;
  }
  override get value(): string | null {
    return super.value;
  }
  override set value(value: string | null) {
    super.value = value;
    if (this.zeugnis.zeugnissatz === this.zeugnis.zeugnissatz.jahrgang.currentZeugnissatz) {
      this.schueler[this.description.schuelerProperty] = value;
    }
  }
}
