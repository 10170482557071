<div class="flex">
  <p-checkbox
    class="flex-grow"
    [(ngModel)]="isHerkunftsspracheVisible"
    [binary]="true"
    label="Unterricht in der Herkunftssprache"
  ></p-checkbox>
  <button
    pButton
    type="button"
    label="Für alle übernehmen"
    [loading]="loading"
    (click)="copyHerkunftsspracheVisibleToAll()"
  ></button>
</div>
