import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { BlankolisteComponent } from './blankoliste.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [BlankolisteComponent],
  imports: [CommonModule, FormsModule, PanelModule, TableModule, ButtonModule, InputTextModule, InputNumberModule],
  exports: [BlankolisteComponent],
})
export class BlankolisteModule {}
