import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { ColorPickerModule } from 'primeng/colorpicker';

import { SignpadComponent } from './signpad.component';
@NgModule({
  imports: [CommonModule, ButtonModule, ColorPickerModule, FormsModule],
  declarations: [SignpadComponent],
  exports: [SignpadComponent],
})
export class SignpadModule {}
