<fz-panel *ngIf="!suppressNull" #nullCandidate fzSelectCandidate [fzValue]="null" fzLabel="&nbsp;">
  <fz-label [placeholder]="placeholder"></fz-label>
</fz-panel>
<ng-content></ng-content>
<fz-panel
  *ngIf="emptyLabel != null"
  #emptyCandidate
  fzSelectCandidate
  [fzValue]="emptyValue"
  [fzLabel]="emptyLabel"
  style="position: absolute"
>
  <fz-label shadow="[...]"></fz-label>
</fz-panel>
<ng-template #content="fzPortal" fzPortal>
  <fz-translate [fzPosition]="rect" tabindex="0" fzFocusable #focusableContent>
    <ng-container [fzPortalOutlet]="selectedContainer?.content"></ng-container>
  </fz-translate>
</ng-template>
<ng-template #backDeco="fzPortal" fzPortal>
  <div [fzRect]="rect">
    <div
      [ngClass]="{
        'plausi-none': field == null,
        'plausi-ok': suppressNull || (field != null && field.error == null && field.warning == null),
        'plausi-error': !suppressNull && field?.error,
        'plausi-warning': !suppressNull && field?.warning,
      }"
    ></div>
    <ng-container [fzPortalOutlet]="selectedContainer?.backDeco"></ng-container>
  </div>
</ng-template>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div style="position: absolute">
    <div
      *ngIf="hasFocus"
      fzFocusable
      [style.top.px]="rect.bottom"
      [style.left.px]="rect.left"
      [style.minWidth.px]="rect.width"
      class="floating fz-note"
      tabindex="0"
    >
      <p-listbox
        [listStyle]="{ 'max-height': '227px' }"
        [options]="candidates || []"
        [(ngModel)]="value"
        (onClick)="candidateClick()"
      ></p-listbox>
    </div>
    <ng-container [fzPortalOutlet]="selectedContainer?.frontDeco"></ng-container>
  </div>
</ng-template>
