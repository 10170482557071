import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[fzTemplate]',
  exportAs: 'fzTemplate',
})
export class TemplateDirective {
  @Input() fzTemplate: string | undefined;

  constructor(public ref: TemplateRef<any>) {}
}
