import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';

@Component({
  selector: 'fz-he-seitenfuss-last',
  templateUrl: 'he-seitenfuss-last.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, HESeitenfussLastComponent)],
})
export class HESeitenfussLastComponent extends HEPanelDecoratorBase {}
