import { BWFormularCategoryKey } from '../bw-formularsatz';
import { BWFormular } from './bw-formular';

export abstract class BWBeilageFormular extends BWFormular {
  get category(): BWFormularCategoryKey {
    return 'beilage';
  }

  override get fontSizeFactor(): number | null {
    return this.zeugnis.fontSizeFactorBeilage;
  }
  override set fontSizeFactor(value: number | null) {
    this.zeugnis.fontSizeFactorBeilage = value;
  }

  override get areKPItemsOptional(): boolean {
    return true;
  }
  override get warnKPItemsIfOptional(): boolean {
    return true;
  }
}
