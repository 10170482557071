<form [formGroup]="form" (ngSubmit)="loadLicense()">
  <p-panel>
    <ng-template pTemplate="header">
      <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
    </ng-template>
    <p>Ihre Lizenznummer beginnt normalerweise mit "20".</p>
    <div class="flex flex-col mt-2">
      <label for="lizenz">Lizenz*</label>
      <div class="flex w-full gap-2">
        <input pInputText formControlName="kundennummer" class="w-96" />
      </div>
    </div>
    <div *ngIf="showDemoLizenzen" class="fz-paragraph-text">
      <p>Folgende Lizenznummer können zum Testen der Anwendung verwendet werden:</p>
      <ul>
        <li>_Saar-Demo</li>
        <li>_RLP-Demo</li>
        <li>_Hessen-Demo</li>
        <li>_BW-Demo</li>
        <li>_NDS-Demo</li>
      </ul>
    </div>
    <ng-template pTemplate="footer">
      <div class="fz-button-group">
        <button
          *ngIf="showCancel"
          pButton
          type="button"
          label="Abbrechen"
          icon="pi pi-times"
          class="w-64 p-button-secondary"
          [loading]="loading"
          (click)="firstStep()"
        ></button>
        <button pButton type="submit" label="Weiter" icon="pi pi-caret-right" class="w-64" [loading]="loading"></button>
      </div>
    </ng-template>
  </p-panel>
</form>
