import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NIPanelDecoratorBase } from './ni-panel-decorator-base';

@Component({
  selector: 'fz-ni-zeugniskopf',
  templateUrl: 'ni-zeugniskopf.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, NIZeugniskopfComponent)],
})
export class NIZeugniskopfComponent extends NIPanelDecoratorBase {}
