<ng-template fzTemplate="sidebar">
  <fz-apply-kp></fz-apply-kp>
  <fz-bw-beilage-typ></fz-bw-beilage-typ>
  <div *ngIf="formular.zeugnis.beilageTyp === 'KP'">
    <p-multiSelect
      dataKey="ref"
      [filter]="false"
      [displaySelectedLabel]="false"
      placeholder="Sichtbarkeit"
      [options]="formular.visibleOptions"
      [ngModel]="formular.visibleSelected"
      (onChange)="formular.visibleChange($event.itemValue)"
    ></p-multiSelect>
    <button
      pButton
      type="button"
      label="Für alle übernehmen"
      [loading]="loading"
      (click)="applyVisibleToAll()"
    ></button>
  </div>
  <fz-rp-abgangszeugnis></fz-rp-abgangszeugnis>
</ng-template>
<fz-formular
  name="BW1234hjBeilage"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
>
  <ng-container *ngIf="model != null">
    <fz-bw-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-bw-zeugniskopf>
    <fz-bw-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-bw-zeugnistyp>
    <fz-bw-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-bw-schueler>
    <fz-deco
      fzFormularItem="sozialverhalten"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    >
      <fz-section
        [label]="model.sozialverhalten.label"
        headerClass="Ueberschriften"
        [headerPadding]="formular.showBorder ? 'normal' : 'none'"
        headerPaddingBottom="none"
      >
        <fz-sequence>
          <fz-deco paddingTop="normal" paddingBottom="minus-border">
            <fz-kp
              [model]="model.sozialverhalten.kp"
              [bereich]="formular.sozialLernArbeitsverhalten.sozialverhalten"
            ></fz-kp>
          </fz-deco>
          <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'">
            <fz-verbal
              [field]="model.sozialverhalten.textField"
              [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
            ></fz-verbal>
          </fz-deco>
        </fz-sequence>
      </fz-section>
    </fz-deco>
    <ng-container *ngFor="let fach of model.hauptFaecher; trackBy: trackByProperty">
      <fz-deco [fzFormularItem]="fach.key" [border]="formular.showBorder ? borderDirection.all : borderDirection.none">
        <fz-section
          [label]="fach.label ?? fach.hauptBereich.label"
          headerClass="Ueberschriften"
          [headerPadding]="formular.showBorder ? 'normal' : 'none'"
          headerPaddingBottom="none"
        >
          <fz-sequence>
            <fz-deco *ngFor="let bereich of fach.bereiche; trackBy: trackByProperty">
              <fz-section
                [label]="bereich.label"
                headerClass="Ueberschriften2"
                [headerPadding]="formular.showBorder ? 'normal' : 'none'"
                headerPaddingBottom="none"
              >
                <fz-deco paddingTop="normal" paddingBottom="minus-border">
                  <fz-kp [model]="bereich.kp" [bereich]="getBereichFromHauptFach(fach.key, bereich.key)"></fz-kp>
                </fz-deco>
              </fz-section>
            </fz-deco>
            <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'">
              <fz-verbal
                [field]="fach.hauptBereich.textField"
                [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
              ></fz-verbal>
            </fz-deco>
          </fz-sequence>
        </fz-section>
      </fz-deco>
    </ng-container>
    <fz-deco
      *ngIf="model.fremdsprache != null"
      fzFormularItem="fremdsprache"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    >
      <fz-section
        [label]="model.fremdsprache.label"
        headerClass="Ueberschriften"
        [headerPadding]="formular.showBorder ? 'normal' : 'none'"
        headerPaddingBottom="none"
      >
        <fz-sequence>
          <fz-deco paddingTop="normal" paddingBottom="minus-border">
            <fz-kp [model]="model.fremdsprache.kp" [bereich]="formular.fremdsprache.gesamt"></fz-kp>
          </fz-deco>
          <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'">
            <fz-verbal
              [field]="model.fremdsprache.textField"
              [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
            ></fz-verbal>
          </fz-deco>
        </fz-sequence>
      </fz-section>
    </fz-deco>
    <ng-container *ngFor="let fach of model.restFaecher; trackBy: trackByProperty">
      <fz-deco [fzFormularItem]="fach.key" [border]="formular.showBorder ? borderDirection.all : borderDirection.none">
        <fz-section
          [label]="fach.label"
          headerClass="Ueberschriften"
          [headerPadding]="formular.showBorder ? 'normal' : 'none'"
          headerPaddingBottom="none"
        >
          <fz-sequence>
            <fz-deco paddingTop="normal" paddingBottom="minus-border">
              <fz-kp [model]="fach.kp" [bereich]="getBereichFromRestFach(fach.key)"></fz-kp>
            </fz-deco>
            <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'">
              <fz-verbal
                [field]="fach.textField"
                [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
              ></fz-verbal>
            </fz-deco>
          </fz-sequence>
        </fz-section>
      </fz-deco>
    </ng-container>
    <fz-bw-unterschrift
      fzFormularItem="unterschrift"
      fzContainer
      *ngIf="model.unterschrift != null"
      [model]="model.unterschrift"
    ></fz-bw-unterschrift>
    <fz-bw-unterschrift-halbjahr
      fzFormularItem="unterschrift"
      fzContainer
      *ngIf="model.unterschriftHalbjahr != null"
      [model]="model.unterschriftHalbjahr"
    ></fz-bw-unterschrift-halbjahr>
  </ng-container>
</fz-formular>
