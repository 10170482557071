<fz-panel *ngIf="model != null">
  <div style="text-align: center" class="Zeugnistitel" *ngIf="model.dropdownField">
    <fz-dropdown [field]="model.dropdownField"></fz-dropdown>
  </div>
  <div style="text-align: center" class="Ueberschriften" *ngIf="formular.category === 'lernstand'">
    <div>
      <fz-label>Lernstands- und Kompetenzbeschreibung für neu zugewanderte</fz-label>
    </div>
    <div>
      <fz-label>Schülerinnen und Schüler ohne ausreichende Deutschkenntnisse</fz-label>
    </div>
  </div>
</fz-panel>
