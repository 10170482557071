import { WorkspaceService } from '../../../shared/services';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fz-navigate-to-zeugnis',
  templateUrl: './navigate-to-zeugnis.component.html',
  styleUrls: ['./navigate-to-zeugnis.component.scss'],
})
export class NavigateToZeugnisComponent {
  constructor(
    public router: Router,
    public schuelerService: WorkspaceService
  ) {}
}
