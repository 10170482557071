<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="fz-paragraph-text">
    <p *ngIf="importTbResult?.transferId == null">
      Die Textbausteine wurden am
      {{ importTbResult?.uploadedTbInfo?.uploadTime }} von
      {{ importTbResult?.uploadedTbInfo?.uploadOrigin }} bereitgestellt.
    </p>
    <p>Wollen Sie die neuen Textbausteine anhängen oder überschreiben?</p>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Abbrechen"
        class="w-64 p-button-secondary"
        icon="pi pi-times"
        [loading]="loading"
        (click)="restart()"
      ></button>
      <button
        pButton
        type="button"
        label="Anhängen"
        class="w-64"
        icon="pi pi-copy"
        [loading]="loading"
        (click)="append()"
      ></button>
      <button
        pButton
        type="button"
        label="Überschreiben"
        class="w-64"
        icon="pi pi-file"
        [loading]="loading"
        (click)="override()"
      ></button>
    </div>
  </ng-template>
</p-panel>
