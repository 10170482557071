import { ItemListIndex, ListIndexable } from './item-list-index';
import { KPBereich } from './kp-bereich';

export class KPFach implements ListIndexable {
  index: number = 0;
  label: string | null = null;
  readonly bereiche: ItemListIndex<KPBereich, this> = new ItemListIndex(KPBereich, this);

  constructor(public readonly key: string | null) {}

  static cleanDto(dto: any): any {
    const { key, zeugnissatz, bereiche, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(fach: KPFach): unknown {
    return { ...this.cleanDto(fach), key: fach.key, bereiche: ItemListIndex.toDto(KPBereich, fach.bereiche) };
  }

  static fromDto(dto: any): KPFach {
    const fach = new KPFach(dto.key ?? null);
    return Object.assign(fach, {
      ...this.cleanDto(dto),
      label: dto.label === '' ? null : dto.label,
      bereiche: ItemListIndex.fromDto(KPBereich, dto.bereiche, fach),
    });
  }
}
