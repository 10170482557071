import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';
import { RPFormular } from '../formulare/rp-formular';

@Component({
  selector: 'fz-rp-unterschrift',
  templateUrl: 'rp-unterschrift.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPUnterschriftComponent)],
})
export class RPUnterschriftComponent extends RPPanelDecoratorBase {
  static createModel(formular: RPFormular) {
    return {
      schulleitungField: formular.zeugnissatz.schulleitung,
      klassenleitungField: formular.zeugnissatz.klassenleitung,
    };
  }

  @Input() model: ReturnType<typeof RPUnterschriftComponent.createModel> | undefined;
}
