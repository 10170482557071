/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

import FachKey from './fach-key';

class RPFachKey extends FachKey {
  public static readonly herkunftssprache: string = 'herkunftssprache';
  public static readonly herkunftssprache2: string = 'herkunftssprache2';
  public static readonly deutschSachunterricht: string = 'deutschSachunterricht';
  public static readonly musikSportBildendeKunst: string = 'musikSportBildendeKunst';
}
export default RPFachKey;
