import { Component, ElementRef, Optional, SkipSelf } from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableDecoratorBase } from './projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Router } from '@angular/router';
import { WorkspaceService } from '../../shared/services';
import { LabelComponent, LabelWord } from './label.component';

@Component({
  selector: 'fz-schuljahr-label',
  templateUrl: 'schuljahr-label.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SchuljahrLabelComponent)],
})
export class SchuljahrLabelComponent extends ProjectableDecoratorBase implements Projectable {
  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private router: Router,
    private workspaceService: WorkspaceService
  ) {
    super(elementRef, parentProvider);
  }

  get words(): LabelWord[] {
    return (this.projectable as LabelComponent | undefined)?.words ?? [];
  }

  get lastWord(): LabelWord | undefined {
    return this.words[this.words.length - 1];
  }

  goEditJahrgang() {
    this.router.navigate(['jahrgang-aendern', this.workspaceService.currentJahrgangId]);
  }
}
