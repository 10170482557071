import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { TbEditorComponent } from '../../shared/components/textbaustein/tb-editor/tb-editor.component';
import { CanComponentDeactivate } from '../../shared/guards/can-component-deactivate.guard';
import { AppInfoService } from '../../shared/services';
import { FlinkyAuthService } from '../../shared/services/auth/flinky-auth.service';

@Component({
  templateUrl: './textbaustein-editor-page.component.html',
  styleUrls: ['./textbaustein-editor-page.component.scss'],
})
export class TextbausteinEditorPageComponent implements AfterViewInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('tbEditor') tbEditor?: TbEditorComponent;
  subscriptions: Subscription[] = [];

  constructor(
    private appInfoService: AppInfoService,
    private confirmationService: ConfirmationService,
    private authService: FlinkyAuthService
  ) {}
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Textbausteinverwaltung'));
    this.subscriptions.push(this.authService.lizenzChanged.subscribe(() => this.tbEditor?.loadData()));
    this.tbEditor?.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.tbEditor?.canDeactivate() === false) {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          header: 'Frage',
          message: 'Es gibt ungespeicherte Änderungen. Wollen Sie die Textbausteinverwaltung wirklich verlassen?',
          accept: async () => {
            resolve(true);
          },
          reject: () => resolve(false),
        });
      });
    } else {
      return true;
    }
  }
}
