import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom } from 'rxjs';
import { Benutzer } from '../../../models/generated/benutzer';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { NotificationService } from '../../services/notification/notification.service';
import { SystemActionsService } from './system-actions.service';

@Component({
  selector: 'fz-system-actions',
  templateUrl: './system-actions.component.html',
  styleUrls: ['./system-actions.component.scss'],
})
export class SystemActionsComponent implements OnInit {
  @ViewChild('uploader') uploader?: FileUpload;

  items: MenuItem[] = [];
  headers: HttpHeaders;
  uploadUrl: string;
  infoDialogVisible = false;
  userManagemenetDiaoigVisible = false;
  backupDialogVisible = false;
  backupCreating = false;
  users: Benutzer[] = [];
  selectedUser?: Benutzer;
  dataPath: string = '';

  constructor(
    private systemActionsService: SystemActionsService,
    private flinkyAuth: FlinkyAuthService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService
  ) {
    this.headers = new HttpHeaders().append('ignoreContentType', 'true');
    this.uploadUrl = '/api/backup/restoreSystemBackup';
  }

  async ngOnInit() {
    this.createMenu(this.systemActionsService.isElectron);
  }

  async onShowBenutzerverwaltung() {
    this.users = await this.flinkyAuth.getAvailableUsers();

    this.dataPath = await lastValueFrom(this.http.get('/api/system/getSystemInfo', { responseType: 'text' as const }));
  }

  deleteBenutzer(user: Benutzer): void {
    this.confirmationService.confirm({
      header: 'Frage',
      message: 'Wollen Sie diesen Benutzer wirklich löschen?',
      accept: async () => {
        const httpParams = new HttpParams().append('idUser', user.id);
        const options = { params: httpParams };
        await lastValueFrom(this.http.delete('/api/benutzer/delete', options));
        this.notificationService.showInfo('Benutzer wurde gelöscht', 'Erfolg');
        this.users = this.users.filter((u) => u.id !== user.id);
        this.flinkyAuth.benutzerDeleted.emit(user);
      },
    });
  }
  showUserinfo(user: Benutzer): void {
    this.selectedUser = user;
  }

  createMenu(isElectron: boolean) {
    this.items = [
      {
        label: 'Systeminformationen',
        command: () => (this.infoDialogVisible = true),
        icon: 'pi pi-fw pi-info-circle',
      },
      {
        label: 'Benutzerverwaltung',
        command: () => (this.userManagemenetDiaoigVisible = true),
        icon: 'pi pi-fw pi-users',
        visible: isElectron,
      },
      { separator: true },
      {
        label: 'TeamViewer Fernwartung',
        icon: 'fa fa-arrows-h',
        command: async () => {
          if (this.systemActionsService.isElectron) await lastValueFrom(this.http.get('/api/system/startTeamViewer'));
          else {
            switch (this.systemActionsService.os) {
              case 'Windows':
                window.open('https://download.teamviewer.com/download/TeamViewerQS.exe', '_blank', 'noopener');
                break;
              case 'MacOS':
                window.open('https://download.teamviewer.com/download/TeamViewerQS.dmg', '_blank', 'noopener');
                break;
              case 'IOS':
                window.open('https://apps.apple.com/us/app/teamviewer-quicksupport/id661649585', '_blank', 'noopener');
                break;
            }
          }
        },
      },
    ];
  }

  infoDialogClosed(): void {
    this.infoDialogVisible = false;
  }
}
