import moment from 'moment';
import { PropertyField } from './property-field';

export class DateField<T> extends PropertyField<T, string | null> {
  get value(): Date | null {
    if (this.propertyValue == null) return null;
    else return moment(this.propertyValue, 'DD.MM.YYYY').toDate();
  }
  set value(value: Date | null) {
    if (value == null) this.propertyValue = null;
    else this.propertyValue = moment(value).format('DD.MM.YYYY');
  }
  override get displayString(): string {
    return this.value != null ? moment(this.value).format('DD.MM.YYYY') : '';
  }
  override get displayStringShort(): string {
    return this.value != null ? moment(this.value).format('DD.MM.YY') : '';
  }
}
