/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

 enum ImportTbResultCode {
  unknown = 'Unknown',
  success = 'Success',
  fail = 'Fail',
  failNoUser = 'FailNoUser',
  noData = 'NoData',
}
export default ImportTbResultCode;
