import { AfterViewInit, Directive, EmbeddedViewRef, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[fzPortal]',
  exportAs: 'fzPortal',
})
export class PortalDirective implements OnDestroy, AfterViewInit {
  view: EmbeddedViewRef<HTMLElement>;
  constructor(
    private template: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef
  ) {
    this.view = viewContainer.createEmbeddedView(template);
  }
  ngOnDestroy(): void {
    const index = this.viewContainer.indexOf(this.view);
    if (index !== -1) {
      this.viewContainer.remove(index);
    }
  }
  ngAfterViewInit(): void {
    for (const node of this.view.rootNodes as Node[]) {
      node.parentNode?.removeChild(node);
    }
  }
}
