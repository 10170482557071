export class Metadata {
  created: Date = new Date();
  lastUpdate: Date = new Date();
  updateCount: number = 0;

  constructor() {
    Object.defineProperty(this, 'created', { value: this.created, enumerable: false });
    Object.defineProperty(this, 'lastUpdate', { value: this.lastUpdate, enumerable: false });
    Object.defineProperty(this, 'updateCount', { value: this.updateCount, enumerable: false });
  }

  static cleanDto(dto: any): any {
    const { created, lastUpdate, updateCount, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(metadata: Metadata): any {
    return {
      created: metadata.created,
      lastUpdate: metadata.lastUpdate,
      updateCount: metadata.updateCount,
    };
  }

  static assignDto(metadata: Metadata, dto: any): void {
    metadata.created = dto.created;
    metadata.lastUpdate = dto.lastUpdate;
    metadata.updateCount = dto.updateCount;
  }
}
