import _ from 'lodash-es';
import { Bereich } from './bereich';
import { Fach } from './fach';
import { KPItem } from './kp-item';
import { Zeugnis } from './zeugnis';
import { Zeugnissatz } from './zeugnissatz';
import { StringHelper } from '../modules/dom/string-helper';

export class KPItemBenotung {
  note: number | null = null;

  constructor(
    public item: KPItem,
    public readonly bereich: Bereich
  ) {}

  static cleanDto(dto: any): any {
    const { item, bereich, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(benotung: KPItemBenotung): unknown {
    return { ...this.cleanDto(benotung), item: KPItem.toDto(benotung.item) };
  }

  static fromDto(dto: any, bereich: Bereich): KPItemBenotung {
    return Object.assign(new KPItemBenotung(KPItem.fromDto(dto.item), bereich), { ...this.cleanDto(dto) });
  }
}

export class KPItemBenotungIndex {
  private guids = new Map<string, KPItemBenotung>();
  private texts = new Map<string, KPItemBenotung>();

  get fach(): Fach {
    return this.bereich.fach;
  }

  get zeugnis(): Zeugnis {
    return this.fach.zeugnis;
  }

  get zeugnissatz(): Zeugnissatz {
    return this.zeugnis.zeugnissatz;
  }

  *[Symbol.iterator]() {
    for (const item of this.bereich.rasterBereich.items) yield this.getBenotung(item);
  }

  constructor(
    public bereich: Bereich,
    private benotungen: KPItemBenotung[] = []
  ) {
    for (const benotung of benotungen) {
      this.guids.set(StringHelper.normalizeGuid(benotung.item.guid), benotung);
      this.texts.set(benotung.item.text, benotung);
    }
  }

  getBenotung(item: KPItem): KPItemBenotung {
    let benotung = this.guids.get(StringHelper.normalizeGuid(item.guid));
    if (benotung == null) {
      benotung = this.texts.get(item.text);
      if (benotung == null) {
        benotung = new KPItemBenotung(item, this.bereich);
        this.benotungen.push(benotung);
        this.guids.set(StringHelper.normalizeGuid(benotung.item.guid), benotung);
        this.texts.set(benotung.item.text, benotung);
      }
    }
    benotung.item = item;
    return benotung;
  }

  merge(sourceItems: KPItemBenotungIndex) {
    for (const sourceBenotung of sourceItems.benotungen) {
      if (sourceBenotung.note != null) this.getBenotung(sourceBenotung.item).note = sourceBenotung.note;
    }
  }

  get hasBenotungen() {
    return this.benotungen.length !== 0;
  }

  static cleanDto(dto: any): any {
    const { bereich, benotungen, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(index: KPItemBenotungIndex): unknown[] {
    // Durch das groupBy werden bei mehreren Einträgen für eine GUID nur die letzte verwendet -> Bugfix
    return _.chain(index.benotungen.filter((b) => b.note != null))
      .groupBy((b) => b.item.guid)
      .values()
      .value()
      .map((g) => _.last(g) as KPItemBenotung)
      .map((b) => KPItemBenotung.toDto(b));
  }

  static fromDto(dtos: any[], bereich: Bereich): KPItemBenotungIndex {
    return new KPItemBenotungIndex(
      bereich,
      (dtos ?? []).map((d) => KPItemBenotung.fromDto(d, bereich))
    );
  }
}
