import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';

@Component({
  selector: 'fz-sl-folgeseite',
  templateUrl: 'sl-folgeseite.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, SLFolgeseiteComponent)],
})
export class SLFolgeseiteComponent extends SLPanelDecoratorBase {
  @Input() pageIndex = 0;

  get description(): string {
    let label = '';
    if (this.formular.zeugnis.zeugnissatz.klassenstufe <= 2) {
      label += ` im ${this.formular.zeugnis.schulbesuchsjahr}. Schulbesuchsjahr in`;
    }
    label += ` der ${this.formular.zeugnissatz.klassenstufeLabel}`;
    switch (this.formular.category) {
      case 'zeugnis':
        if (this.formular.isProtokoll) return 'des Protokolls zum Entwicklungsgespräch';
        else if (this.formular.zeugnis.zeugnisTyp === 'Abgang') return `des Abgangszeugnisses${label}`;
        else return `des ${this.formular.zeugnissatz.zeugnisTypLabelGenetiv}${label}`;
      case 'erlaeuterungen':
        return `der ergänzenden Erläuterungen zum ${this.formular.zeugnisTypLabel}${label}`;
      case 'mitteilung':
        return 'der schriftlichen Mitteilung zum Entwicklungsgespräch';
      case 'anlage':
        return `der Anlage zum ${this.formular.zeugnisTypLabel}${label}`;
      default:
        return '';
    }
  }
}
