import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import ImportTbResult from '../../../models/generated/import-tb-result';

@Injectable({
  providedIn: 'root',
})
export class ImportTbService {
  constructor(private http: HttpClient) {}

  getServerTbInfo() {
    return lastValueFrom(this.http.get<ImportTbResult>('/api/Textbaustein/checkImportTextbausteine'));
  }

  importInternetAppend() {
    return lastValueFrom(this.http.get<ImportTbResult>('/api/Textbaustein/importInternetAppend'));
  }

  importInternetOverride() {
    return lastValueFrom(this.http.get<ImportTbResult>('/api/Textbaustein/importInternetOverride'));
  }

  importManuellAppend(transferId: string) {
    const httpParams = new HttpParams().append('transferId', transferId);

    const options = { params: httpParams };

    return lastValueFrom(this.http.get<ImportTbResult>('/api/Textbaustein/importManuellAppend', options));
  }

  importManuellOverride(transferId: string) {
    const httpParams = new HttpParams().append('transferId', transferId);

    const options = { params: httpParams };

    return lastValueFrom(this.http.get<ImportTbResult>('/api/Textbaustein/importManuellOverride', options));
  }
}
