import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FormularFactory } from '@modules/formular/formular-factory';
import { FormularModule } from '@modules/formular/formular.module';
import { RichTextModule } from '@modules/rich-text/rich-text.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BWFormularsatz } from './bw-formularsatz';
import { BW1234hjBeilageFormularComponent } from './formulare/bw-1234hj-beilage-formular.component';
import { BW12hjStandardFormularComponent } from './formulare/bw-12hj-standard-formular.component';
import { BW34hStandardFormularComponent } from './formulare/bw-34h-standard-formular.component';
import { BW34jStandardFormularComponent } from './formulare/bw-34j-standard-formular.component';
import { BWBeilageTypComponent } from './options/bw-beilage-typ.component';
import { BWGespraechComponent } from './projectables/bw-gespraech.component';
import { BWLabelContainerComponent } from './projectables/bw-label-container.component';
import { BWLeistungenDeMaComponent } from './projectables/bw-leistungen-de-ma.component';
import { BWLeistungenComponent } from './projectables/bw-leistungen.component';
import { BWSchuelerComponent } from './projectables/bw-schueler.component';
import { BWSeitenfussLastComponent } from './projectables/bw-seitenfuss-last.component';
import { BWSeitenfussComponent } from './projectables/bw-seitenfuss.component';
import { BWSeitenkopfComponent } from './projectables/bw-seitenkopf.component';
import { BWSiegelComponent } from './projectables/bw-siegel.component';
import { BWUnterschriftHalbjahrComponent } from './projectables/bw-unterschrift-halbjahr.component';
import { BWUnterschriftComponent } from './projectables/bw-unterschrift.component';
import { BWZeugniskopfComponent } from './projectables/bw-zeugniskopf.component';
import { BWZeugnistypComponent } from './projectables/bw-zeugnistyp.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ButtonModule,
    MenuModule,
    CheckboxModule,
    RadioButtonModule,
    FormularModule,
    RichTextModule,
    MultiSelectModule,
  ],
  declarations: [
    BWBeilageTypComponent,
    BW12hjStandardFormularComponent,
    BW34hStandardFormularComponent,
    BW34jStandardFormularComponent,
    BW1234hjBeilageFormularComponent,
    BWZeugniskopfComponent,
    BWSchuelerComponent,
    BWUnterschriftComponent,
    BWUnterschriftHalbjahrComponent,
    BWLabelContainerComponent,
    BWSeitenkopfComponent,
    BWSeitenfussComponent,
    BWSeitenfussLastComponent,
    BWLeistungenComponent,
    BWLeistungenDeMaComponent,
    BWZeugnistypComponent,
    BWSiegelComponent,
    BWGespraechComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class BWModule {}

FormularFactory.registerFormularsatz(new BWFormularsatz());
