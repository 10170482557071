<fz-formular
  name="RP4hEmpfehlung"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
>
  <fz-rich-text
    fzFormularItem="anschriftSchule"
    [marginBottom]="19"
    [width]="680"
    placeholder="Name und Anschrift der Schule"
    [field]="model.anschriftSchuleField"
    [(model)]="model.anschriftSchuleField.value"
  ></fz-rich-text>
  <fz-rich-text
    fzFormularItem="anschriftEltern"
    [marginBottom]="57"
    [width]="680"
    placeholder="Name und Anschrift der Eltern"
    [field]="model.anschriftElternField"
    [(model)]="model.anschriftElternField.value"
  ></fz-rich-text>
  <fz-panel fzFormularItem="empfehlungTitel" [marginBottom]="38" fzContainer>
    <div class="Zeugnistitel">
      <fz-label>Empfehlung</fz-label>
    </div>
    <div>
      <fz-label>für den weiteren Schulbesuch in der Orientierungsstufe Ihres Kindes: </fz-label>
      <fz-label class="Schuelername"
        >{{ formular.zeugnis.schuelerVorname }} {{ formular.zeugnis.schuelerName }}</fz-label
      >
    </div>
  </fz-panel>
  <fz-panel fzFormularItem="empfehlungInhalt" [marginBottom]="38" fzContainer>
    <div>
      <fz-label>Sehr geehrte Eltern,</fz-label>
    </div>
    <div>&nbsp;</div>
    <div>
      <fz-label
        >nach den Bestimmungen des § 16 der Grundschulordnung erhalten alle Schülerinnen und Schüler, die
        voraussichtlich das Ziel der Grundschule erreichen, eine Empfehlung für den weiteren Schulbesuch in der
        Orientierungsstufe.</fz-label
      >
    </div>
    <div>&nbsp;</div>
    <div>
      <fz-label
        >Alle Lehrerinnen und Lehrer, die Ihr Kind unterrichten, haben gemeinsam beraten, wo sich Ihr Kind
        voraussichtlich am besten entwickeln kann. Aufgrund der längerfristigen Beobachtungen der bisherigen
        Entwicklung, des Lern- und Arbeitsverhaltens und der Leistungen empfiehlt die Grundschule für die weitere
        Laufbahn Ihres Kindes den Besuch der Orientierungsstufe an folgender Schulart:</fz-label
      >
    </div>
    <div>&nbsp;</div>
    <div style="display: grid; grid-template-columns: auto 1fr; column-gap: 4px">
      <fz-panel style="grid-column: 1" [border]="borderDirection.all">
        <fz-checkbox [field]="model.empfehlungRealschuleField"></fz-checkbox>
      </fz-panel>
      <fz-label style="grid-column: 2; align-self: center"
        >Realschule plus, Integrierte Gesamtschule (oder Hauptschule, Realschule in freier Trägerschaft)</fz-label
      >
    </div>
    <div>&nbsp;</div>
    <div style="display: grid; grid-template-columns: auto 1fr; column-gap: 4px">
      <fz-panel style="grid-column: 1" [border]="borderDirection.all">
        <fz-checkbox [field]="model.empfehlungGymnasiumField"></fz-checkbox>
      </fz-panel>
      <fz-label style="grid-column: 2; align-self: center">Gymnasium, Integrierte Gesamtschule</fz-label>
    </div>
    <div>&nbsp;</div>
    <div>
      <fz-label
        >Mit dieser Empfehlung können Sie Ihr Kind auch an einer schulartübergreifenden Orientierungsstufe anmelden,
        wenn ein solches Angebot in Ihrer Nähe besteht. Die Aufnahme an der IGS ist nur im Rahmen der vorhandenen
        Kapazität möglich. Es besteht, wie auch an Schulen in freier Trägerschaft, kein Aufnahmeanspruch.</fz-label
      >
    </div>
    <div>&nbsp;</div>
    <div>
      <fz-label
        >Sehr geehrte Eltern, wir bitten Sie, unsere Empfehlung, die wir nach bestem Wissen abgegeben haben, sehr
        ernsthaft in lhre Über­legungen einzubeziehen. Gebunden daran sind Sie nicht. Sie können frei entscheiden,
        welche Schulart Sie für Ihr Kind wählen. Sie müssen Ihr Kind unter Verwendung des beigefügten Anmeldeformulars
        bis zum 1. März an den von den Schulen genannten Terminen anmelden. Für Integrierte Gesamtschulen gibt es
        abweichende Anmeldefristen, über die die jeweilige Schule Auskunft gibt. Bei der Anmeldung ist auch das letzte
        Halbjahreszeugnis der Grundschule vorzulegen. Hingegen sind Sie nicht verpflichtet, die aufnehmende Schule über
        die Empfehlung der Grundschule zu unterrichten. Allerdings kann es sein, dass an einzelnen Schulen mehr Kinder
        angemeldet werden, als diese Schulen aufnehmen können. Sie sollten sich daher auch überlegen, welche weiteren
        Schulen für Ihr Kind in Frage kommen können. Bei der Wahl der Schulart und der Schule ist auch zu beachten,
        welche Schulwege Ihrem Kind zumutbar sind.</fz-label
      >
    </div>
    <div>&nbsp;</div>
    <div>
      <fz-label
        >Sehr geehrte Eltern, Sie wissen, welche Bedeutung für die weitere Entwicklung Ihres Kindes die richtige Wahl
        der Schullaufbahn haben kann. Wenn Sie Fragen zu unserer Empfehlung haben, steht Ihnen die Klassenlehrerin oder
        der Klassenlehrer Ihres Kindes gerne für ein weiteres Beratungsgespräch zur Verfügung. Sie können sich aber auch
        von allen weiterführenden Schulen in Fragen der Schullaufbahnwahl beraten lassen.</fz-label
      >
    </div>
    <div>&nbsp;</div>
    <div>
      <fz-label>Mit freundlichen Grüßen</fz-label>
    </div>
  </fz-panel>
  <fz-panel fzFormularItem="empfehlungUnterschrift" fzContainer>
    <fz-date [field]="model.empfehlungausgabedatumField"></fz-date>
    <fz-panel [border]="borderDirection.top"></fz-panel>
    <div style="display: grid; grid-template-columns: 113px 1fr">
      <fz-label style="grid-column: 1">Datum</fz-label>
      <fz-text
        style="grid-column: 2"
        [field]="model.schulleitungField"
        placeholder="Bezeichnung Schulleitung"
      ></fz-text>
    </div>
  </fz-panel>
</fz-formular>
