import { AfterViewInit, Component } from '@angular/core';
import { AppInfoService } from '../../shared/services';

@Component({
  selector: 'fz-blankoliste-page',
  templateUrl: './blankoliste-page.component.html',
  styleUrls: ['./blankoliste-page.component.scss'],
})
export class BlankolistePageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Blankoliste'));
  }
}
