<ng-template #header>
  <p-steps class="w-full fz-steps-transparent" [model]="items" [readonly]="true" [activeIndex]="activeStep"></p-steps>
</ng-template>
<div class="flex-grow h-0 overflow-auto">
  <app-export-start
    *ngIf="step === '1'"
    [header]="header"
    [currentZeugnis]="currentZeugnis"
    (exportDataSet)="export($event)"
    class="flex-grow h-0 overflow-auto"
  ></app-export-start>

  <app-export-select-target
    *ngIf="step === '2'"
    [header]="header"
    [loading]="loading"
    (targetSelected)="targetSelected($event)"
    class="flex-grow h-0 overflow-auto"
  ></app-export-select-target>

  <app-export-finished
    *ngIf="step === '3'"
    [pin]="pin"
    [header]="header"
    (restartClicked)="firstStep()"
    class="flex-grow h-0 overflow-auto"
  ></app-export-finished>

  <app-export-manuell-filename
    *ngIf="step === '4'"
    [header]="header"
    [exportData]="exportData"
    (filenameSelected)="filenameSelected($event)"
    (restartClicked)="firstStep()"
    class="flex-grow h-0 overflow-auto"
  ></app-export-manuell-filename>

  <app-export-manuell-finished
    *ngIf="step === '5'"
    [header]="header"
    (restartClicked)="firstStep()"
    class="flex-grow h-0 overflow-auto"
  ></app-export-manuell-finished>
</div>
