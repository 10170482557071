import { SchuelerlisteImportFinishedComponent } from './schuelerliste-import/schuelerliste-import-finished/schuelerliste-import-finished.component';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { StepsModule } from 'primeng/steps';
import { SchuelerlisteImportComponent } from './schuelerliste-import/schuelerliste-import.component';
import { SchuelerlisteListComponent } from './schuelerliste-list/schuelerliste-list.component';
import { SchuelerlisteImportFileselectionComponent } from './schuelerliste-import/schuelerliste-import-fileselection/schuelerliste-import-fileselection.component';
import { SchuelerlisteImportPreviewComponent } from './schuelerliste-import/schuelerliste-import-preview/schuelerliste-import-preview.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule } from '@angular/forms';
import { SchuelerlisteImportAssignComponent } from './schuelerliste-import/schuelerliste-import-assign/schuelerliste-import-assign.component';
import { SchuelerlisteImportFilterComponent } from './schuelerliste-import/schuelerliste-import-filter/schuelerliste-import-filter.component';
import { DropdownModule } from 'primeng/dropdown';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    SchuelerlisteImportComponent,
    SchuelerlisteListComponent,
    SchuelerlisteImportFileselectionComponent,
    SchuelerlisteImportPreviewComponent,
    SchuelerlisteImportAssignComponent,
    SchuelerlisteImportFilterComponent,
    SchuelerlisteImportFinishedComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    TableModule,
    StepsModule,
    DialogModule,
    FileUploadModule,
    InputNumberModule,
    DropdownModule,
    InputTextModule,
  ],
  exports: [SchuelerlisteListComponent, SchuelerlisteImportComponent],
  providers: [DatePipe],
})
export class SchuelerlisteModule {}
