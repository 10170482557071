import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Type,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import jsPDF from 'jspdf';
import { FormularService } from './formular.service';
import { FormularComponent, FormularItemInstance, FormularOwner, FormularOwnerProvider } from './formular.component';
import { Formular } from './formular';
import { PageComponent } from './page.component';
import { FormularFactory } from './formular-factory';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Block, BlockFactoryOwner, BlockFactoryOwnerProvider } from '@modules/blocks/block-factory';

import { WorkspaceService } from '../../shared/services';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { HeaderFooterOwner, HeaderFooterOwnerProvider } from './header-footer.directive';
import { Zeugnis } from '../../models/zeugnis';
import { FormularComponentBase } from './formular-component-base';

export type PageItem = {
  pageIndex: number;
  position: DOMRect;
  block: Block;
};
export type Page = {
  formular: FormularComponent;
  index: number;
  items: PageItem[];
  formularItemInstances: FormularItemInstance[];
  isLast: boolean;
};

@Component({
  selector: 'fz-formular-viewer',
  templateUrl: './formular-viewer.component.html',
  styleUrls: ['./formular-viewer.component.scss'],
  providers: [
    FormularService,
    provideInterfaceBy(FormularOwnerProvider, FormularViewerComponent),
    provideInterfaceBy(BlockFactoryOwnerProvider, FormularViewerComponent),
    provideInterfaceBy(HeaderFooterOwnerProvider, FormularViewerComponent),
  ],
})
export class FormularViewerComponent
  implements OnDestroy, DoCheck, AfterViewInit, FormularOwner, BlockFactoryOwner, HeaderFooterOwner
{
  @Input() hidden = false;
  @Input() zeugnis?: Zeugnis;
  @Input() category?: string;
  @Output() sidebarVisibleChange = new EventEmitter<boolean>();
  @ViewChildren(PageComponent) pageComponents = new QueryList<PageComponent>();
  @ViewChild('formularContainer', { read: ViewContainerRef }) formularContainerRef: ViewContainerRef | undefined;
  pageHeight = 1123; // 297mm
  pageWidth = 794; // 210mm
  pages: Page[] = [];
  styleString = '';
  zoomlevel = 100;
  formulars: FormularComponent[] = [];
  userLayoutMode = false;
  formularComponent: FormularComponentBase<Formular> | undefined;
  #formularComponentType: Type<FormularComponentBase<Formular>> | undefined;
  #formularType: Type<Formular> | null = null;
  #suppressUpdatePages = 0;
  #inChangeDetection = 0;
  private updatePagesAndProjectScheduled = false;
  loading: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private formularService: FormularService<Formular>,
    private workspaceService: WorkspaceService,
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) {
    this.subscriptions.push(
      this.formularService.styleChanged.subscribe(() => {
        this.updateStylesheet();
        this.updatePages();
      })
    );
    this.subscriptions.push(this.formularService.fitRequested.subscribe((pageCount) => this.fit(pageCount)));
  }

  get sidebarVisible() {
    return (
      this.formularComponent?.sidebarTemplate != null ||
      !this.workspaceService.canEditZeugnis ||
      this.workspaceService.isZeugnissatzLocked
    );
  }

  get formularComponentType(): Type<FormularComponentBase<Formular>> | undefined {
    return this.#formularComponentType;
  }
  set formularComponentType(value: Type<FormularComponentBase<Formular>> | undefined) {
    if (this.#formularComponentType !== value) {
      this.#formularComponentType = value;
      this.updateFormularComponent();
    }
  }

  private updateFormularComponent() {
    if (this.#formularComponentType != null) {
      if (this.formularComponent != null) this.formularContainerRef?.remove(0);
      this.formularComponent = this.formularContainerRef?.createComponent(this.#formularComponentType).instance;
      this.changeDetector.detectChanges();
      this.changeDetector.detectChanges(); // zweites Mal weil Sidebar-Template erst nach Bindung verfügbar
      this.sidebarVisibleChange.emit(this.sidebarVisible);
    }
  }

  get zoomedPageWidth(): number {
    return (this.pageWidth * this.zoomlevel) / 100;
  }

  get zoomedPageHeight(): number {
    return (this.pageHeight * this.zoomlevel) / 100;
  }

  get scaleTransform(): string {
    return `scale(${this.zoomlevel / 100})`;
  }

  get formular(): Formular {
    return this.formularService.formular;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) subscription.unsubscribe();
    this.sidebarVisibleChange.emit(false);
  }
  ngDoCheck(): void {
    //console.log('FormularViewer: Change Detection', Zone.currentTask?.source);
    this.#inChangeDetection++;
    this.updateFormular();
    this.#inChangeDetection--;
  }
  ngAfterViewInit(): void {
    if (this.#formularComponentType != null) {
      this.updateFormularComponent();
    }
    if (!this.hidden) {
      const zoomlevel = +(localStorage.getItem('fz-zoomlevel') ?? 100);
      if (zoomlevel) {
        queueMicrotask(() => (this.zoomlevel = zoomlevel as number));
      } else {
        localStorage.setItem('fz-zoomlevel', this.zoomlevel.toString());
      }
    }

    this.workspaceService.zoomed.subscribe((delta: number) => {
      if (this.zoomlevel + delta > 30 && this.zoomlevel + delta < 300) {
        this.zoomlevel += delta;
        this.zoomlevel = Math.round(this.zoomlevel);
        localStorage.setItem('fz-zoomlevel', this.zoomlevel.toString());
      }
    });
    this.sidebarVisibleChange.emit(this.sidebarVisible);
  }
  async applyLayoutToAll() {
    try {
      this.loading = true;
      const zeugnisse = this.workspaceService.selectedZeugnisse.filter((z) => z !== this.zeugnis);
      for (const formular of this.formulars) {
        formular.applyLayoutToAll(zeugnisse);
      }
      await this.workspaceService.saveSelectedZeugnissatz();
    } finally {
      this.loading = false;
    }
  }

  updatePages(): void {
    if (this.#suppressUpdatePages === 0) {
      this.changeDetector.detectChanges();
      for (const formular of this.formulars) {
        formular.updatePages();
      }
      this.pages.splice(0);
      this.pages.push(
        ...this.formulars.reduce((prev: Page[], curr: FormularComponent) => [...prev, ...curr.pages], [])
      );
      this.changeDetector.detectChanges();
      for (const pageComponent of this.pageComponents) {
        pageComponent.setStylesheet(this.styleString);
      }
    }
  }

  register(formular: FormularComponent): void {
    this.formulars.push(formular);
  }
  unregister(formular: FormularComponent): void {
    this.formulars.splice(this.formulars.indexOf(formular), 1);
  }

  invalidate(): void {
    // detectChanges evtl. kritisch: Aufruf von ChangeDetection während ChangeDetection, löst aber ExpressionChangedAfterItHasBeenCheckedError, wenn z.B. auf rp-34-standard-formular Herkunftssprache dazugeschaltet wird
    this.changeDetector.detectChanges();
    this.scheduleUpdatePagesAndProject();
  }

  invalidateHeaderFooter(): void {
    for (const formular of this.formulars) {
      formular.positionHeader();
    }
  }

  preparePdf(): number {
    this.updateFormular();
    return this.pageComponents.length;
  }

  exportPdfPage(doc: jsPDF, pageIndex: number): void {
    if (!this.formular.isValid) {
      doc.setTextColor('#D3D3D3');
      doc.setFont('Arial');
      doc.setFontSize(180);
      doc.text('Entwurf', 75, 250, {
        angle: -300,
      });
    }
    const pageComponent = Array.from(this.pageComponents)[pageIndex];
    pageComponent?.exportPdf(doc);
  }

  onSliderChange(event: any): void {
    if (event.value > 90 && event.value < 110) {
      this.zoomlevel = 100;
    }
    if (!this.hidden) localStorage.setItem('fz-zoomlevel', this.zoomlevel.toString());
  }

  private updateFormular() {
    if (this.zeugnis == null || this.category == null) {
      this.#formularType = null;
      this.changeDetector.detectChanges();
      // this.formularService.setFormular(new NullFormular(this.zeugnis))
      this.updatePages();
    } else {
      const formularsatz = FormularFactory.getFormularsatz(this.zeugnis);
      const formularType = formularsatz.getFormularType(this.zeugnis, this.category);
      if (this.#formularType !== formularType) {
        this.#formularType = formularType;
        this.updateZeugnis(true);
      } else {
        this.updateZeugnis();
      }
    }
  }

  private updateZeugnis(force = false): void {
    if (this.zeugnis != null && this.category != null) {
      if (force || this.formularService.formular?.zeugnis !== this.zeugnis) {
        const formularsatz = FormularFactory.getFormularsatz(this.zeugnis);
        this.formularService.setFormular(formularsatz.createFormular(this.zeugnis, this.category));
        this.formularComponentType = this.formularService.formular.viewType;
        this.updateStylesheet();
        this.#suppressUpdatePages++;
        this.changeDetector.detectChanges();
        this.#suppressUpdatePages--;
        this.updatePagesAndProject();
      }
    }
  }

  private updatePagesAndProject() {
    if (this.#inChangeDetection) {
      this.scheduleUpdatePagesAndProject();
    } else {
      this.updatePages();
      for (const formular of this.formulars) formular.project();
    }
  }

  private scheduleUpdatePagesAndProject() {
    if (!this.updatePagesAndProjectScheduled) {
      this.updatePagesAndProjectScheduled = true;
      queueMicrotask(() => {
        this.updatePagesAndProjectScheduled = false;
        this.updatePages();
        for (const formular of this.formulars) formular.project();
      });
    }
  }

  private getZeugnisStyle(fontSizeFactor: number | undefined = undefined): string {
    let styleString = '';
    if (this.zeugnis != null) {
      const styleDefs = this.zeugnis.zeugnissatz.zeugniskopflizenz.mergedStyles;
      if (styleDefs) {
        for (const styleDef of styleDefs) {
          let fontSize =
            styleDef.fontSizeUnit === 'pt' ? Math.round((styleDef.fontSize / 3) * 4) : Math.round(styleDef.fontSize);
          if (styleDef.name === 'Verbalbeurteilung_Text' || styleDef.name === 'Koennensprofil_Text') {
            fontSize = Math.round((fontSize * (fontSizeFactor ?? this.formular.fontSizeFactor ?? 100)) / 100);
          }
          let lineHeight = Math.round(fontSize * 1.2);
          if (styleDef.fontWeight === 'bold') lineHeight++; // für Firefox, da dort bei Kombination von normal und bold in einer Zeile die baseline leicht verschoben ist
          styleString += `.${styleDef.name} { font-family: '${styleDef.fontFamily}'; font-size: ${fontSize}px; font-weight: ${styleDef.fontWeight}; line-height: ${lineHeight}px; }\n`;
        }
      }
    }
    return styleString;
  }

  private updateStylesheet(fontSizeFactor: number | undefined = undefined) {
    if (this.zeugnis != null) {
      this.styleString = this.getZeugnisStyle(fontSizeFactor);
      const el1 = document.getElementById('style123');
      el1?.remove();
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'style123';
      style.innerHTML = this.styleString;

      document.getElementsByTagName('head')[0].appendChild(style);
      this.formularService.requestRecalculateHeight();
    }
  }

  private fit(pageCount: number) {
    if (this.zeugnis != null) {
      let success = false;
      for (let fontSizeFactor = 100; fontSizeFactor >= 70; fontSizeFactor -= 5) {
        this.updateStylesheet(fontSizeFactor);
        this.updatePages();
        if (this.pages.length <= pageCount) {
          this.formular.fontSizeFactor = fontSizeFactor;
          this.confirmationService.confirm({
            header: 'Hinweis',
            message: `Der Inhalt wurde auf ${pageCount} ${
              pageCount === 1 ? 'Seite' : 'Seiten'
            } eingepasst. Der Vorgang muss gegebenfalls bei Änderungen am Zeugnis wiederholt werden.`,
            rejectVisible: false,
            acceptLabel: 'Ok',
          });
          success = true;
          break;
        }
      }
      this.updateStylesheet();
      this.updatePages();
      if (!success) {
        this.confirmationService.confirm({
          header: 'Hinweis',
          message: `Der Inhalt konnte nicht auf ${pageCount} ${
            pageCount === 1 ? 'Seite' : 'Seiten'
          } eingepasst werden. Der zur Verfügung stehende Platz reicht für den Inhalt nicht aus. Möglicherweise hilft eine Verkleinerung der Abstände und Seitenränder.`,
          rejectVisible: false,
          acceptLabel: 'Ok',
        });
      }
    }
  }
}
