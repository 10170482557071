import { BeschraenkungTyp } from './enums/beschraenkung-typ';
import { FormularTyp } from './enums/formular-typ';
import { GespraechEinladungAnzahlTyp } from './enums/gespraech-einladung-anzahl-typ';
import { SprachenportfolioTyp } from './enums/sprachenportfolio-typ';
import { Zeugnis } from './zeugnis';

export class SLZeugnis extends Zeugnis {
  besondereFaehigkeiten: string | null = null;
  sprachenportfolio: SprachenportfolioTyp | null = null;
  zeugnislevel: number | null = null;
  formularTyp: FormularTyp | null = null;
  anlageFreitext: string | null = null;
  anlageFreitextAktiv: boolean = false;
  erlaeuterungen: string | null = null;
  erlaeuterungenAktiv: boolean = false;
  beschraenkungAnlage: BeschraenkungTyp | null = null;
  beschraenkungAnlageFreitext: BeschraenkungTyp | null = null;
  erziehungsberechtigte: string | null = null;
  anschrift: string | null = null;
  teilnehmer: string | null = null;
  gespraechsinhalte: string | null = null;
  vereinbarungen: string | null = null;
  weitereBesprechungspunkte: string | null = null;
  gespraechGefuehrtVon: string | null = null;
  foerderschullehrer: string | null = null;
  gespraechEinladungAm: string | null = null;
  gespraechEinladungAnzahl: GespraechEinladungAnzahlTyp | null = null;
  vorgeseheneGespraechsinhalte: string | null = null;
  lernentwicklungsbericht: string | null = null;
  schriftlicheMitteilungHinweise: string | null = null;
  entwicklungZusammenfassung: string | null = null;
}
