<fz-label><ng-content></ng-content></fz-label>
<ng-template #content="fzPortal" fzPortal>
  <fz-translate fzFocusable tabindex="0">
    <ng-container [fzPortalOutlet]="projectable?.content"></ng-container>
  </fz-translate>
</ng-template>
<ng-template #backDeco="fzPortal" fzPortal>
  <ng-container *ngFor="let word of words">
    <div [fzRect]="word.rect">
      <div class="plausi-ok"></div>
    </div>
  </ng-container>
</ng-template>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div
    *ngIf="hasFocus"
    fzFocusable
    tabindex="0"
    style="position: absolute; border-style: solid; border-width: 1px"
    [style.left.px]="lastWord?.rect?.right"
    [style.bottom.px]="-(lastWord?.rect?.bottom ?? 0)"
    class="floating"
  >
    <button pButton label="Schuljahr korrigieren" style="white-space: nowrap" (click)="goEditJahrgang()"></button>
  </div>
</ng-template>
