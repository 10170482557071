import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';
import { BWFormular } from '../formulare/bw-formular';

@Component({
  selector: 'fz-bw-zeugnistyp',
  templateUrl: 'bw-zeugnistyp.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, BWZeugnistypComponent)],
})
export class BWZeugnistypComponent extends BWPanelDecoratorBase {
  static createModel(formular: BWFormular) {
    return {
      label:
        formular.category === 'zeugnis' &&
        formular.zeugnis.zeugnissatz.klassenstufe === 4 &&
        formular.zeugnis.zeugnissatz.halbjahr === 1
          ? undefined
          : formular.category === 'beilage'
            ? formular.beilageTitel
            : formular.zeugnis.zeugnissatz.klassenstufe < 3
              ? 'Schulbericht der Grundschule'
              : formular.zeugnis.zeugnissatz.klassenstufe >= 3 && formular.zeugnis.zeugnissatz.halbjahr === 0
                ? 'Halbjahresinformation der Grundschule'
                : 'Zeugnis der Grundschule',
      dropdownField:
        formular.category === 'zeugnis' &&
        formular.zeugnis.zeugnissatz.klassenstufe === 4 &&
        formular.zeugnis.zeugnissatz.halbjahr === 1
          ? formular.zeugnisTyp
          : undefined,
    };
  }

  @Input() model: ReturnType<typeof BWZeugnistypComponent.createModel> | undefined;
}
