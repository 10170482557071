import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NIPanelDecoratorBase } from './ni-panel-decorator-base';
import { NIFormular } from '../formulare/ni-formular';

@Component({
  selector: 'fz-ni-schueler',
  templateUrl: 'ni-schueler.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, NISchuelerComponent)],
})
export class NISchuelerComponent extends NIPanelDecoratorBase {
  static createModel(formular: NIFormular) {
    return {
      vornameField: formular.schuelerVorname,
      nameField: formular.schuelerName,
      geschlechtField: formular.schueler.geschlecht,
      geburtsdatumField: formular.schueler.geburtsdatum,
      geburtsortField: formular.schueler.geburtsort,
      abgang:
        formular.zeugnis.zeugnisTyp === 'Abgang' && formular.category === 'zeugnis'
          ? {
              datumField: formular.abgangsdatum,
              schuljahrField: formular.abgangsschuljahr,
            }
          : undefined,
    };
  }

  @Input() model: ReturnType<typeof NISchuelerComponent.createModel> | undefined;
}
