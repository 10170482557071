import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ListboxModule } from 'primeng/listbox';
import { SkeletonModule } from 'primeng/skeleton';
import { TextbausteinEditorModule } from '../textbaustein-editor/textbaustein-editor.module';
import { IntelliTextComponent } from './intelliText.component';

@NgModule({
  declarations: [IntelliTextComponent],
  exports: [IntelliTextComponent],
  imports: [CommonModule, ListboxModule, FormsModule, SkeletonModule, ButtonModule, TextbausteinEditorModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class IntelliTextModule {}
