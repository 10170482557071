/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/prefer-for-of */
import { TextPosition } from './text-position';
import { CharPosition } from './char-position';

export class NodeWalker {
  public readonly nodes: Iterable<Node>;
  public readonly childNodes: Iterable<Node>;
  public readonly textNodes: Iterable<Text>;
  public readonly textNodesReverse: Iterable<Text>;
  public readonly beforeCharacterPositions: Iterable<CharPosition>;
  public readonly beforeCharacterPositionsReverse: Iterable<CharPosition>;
  public readonly textPositions: Iterable<TextPosition>;
  public readonly textPositionsReverse: Iterable<TextPosition>;

  public readonly node: Node;

  constructor(node: Node) {
    this.node = node;
    this.nodes = { [Symbol.iterator]: this.generateNodes.bind(this) };
    this.childNodes = { [Symbol.iterator]: this.generateChildNodes.bind(this) };
    this.textNodes = { [Symbol.iterator]: this.generateTextNodes.bind(this) };
    this.textNodesReverse = {
      [Symbol.iterator]: this.generateTextNodesReverse.bind(this),
    };
    this.beforeCharacterPositions = {
      [Symbol.iterator]: this.generateBeforeCharacterPositions.bind(this),
    };
    this.beforeCharacterPositionsReverse = {
      [Symbol.iterator]: this.generateBeforeCharacterPositionsReverse.bind(this),
    };
    this.textPositions = {
      [Symbol.iterator]: this.generateTextPositions.bind(this),
    };
    this.textPositionsReverse = {
      [Symbol.iterator]: this.generateTextPositionsReverse.bind(this),
    };
  }

  public static getNodesForRange(startNode: Node, endNode: Node): Iterable<Node> {
    return {
      [Symbol.iterator]: this.generateNodesForRange.bind(this, startNode, endNode),
    };
  }

  public static getTextNodesForRange(startNode: Node, endNode: Node): Iterable<Node> {
    return {
      [Symbol.iterator]: this.generateTextNodesForRange.bind(this, startNode, endNode),
    };
  }

  private static *generateTextNodesForRange(startNode: Node, endNode: Node): Generator<Node> {
    for (const node of this.generateNodesForRange(startNode, endNode)) {
      if (node instanceof Text) yield node;
    }
  }

  private static *generateNodesForRange(startNode: Node, endNode: Node): Generator<Node> {
    for (const node of this.generateNodesUntil(startNode, endNode)) {
      yield node;
      if (node === endNode) return;
    }
    if (startNode !== endNode) {
      let currentNode: Node | null = startNode;
      while (currentNode != null && currentNode.nextSibling == null) currentNode = currentNode.parentNode;
      if (currentNode != null) {
        for (const node of this.generateNodesForRange(currentNode.nextSibling!, endNode)) {
          yield node;
          if (node === endNode) return;
        }
      }
    }
  }

  private static *generateNodesUntil(startNode: Node, endNode: Node): Generator<Node> {
    const walker = new NodeWalker(startNode);
    for (const node of walker.nodes) {
      yield node;
      if (node === endNode) return;
    }
  }

  public *generateBeforeCharacterPositionsStartingWith(startIndex: number): Generator<CharPosition> {
    for (const pos of this.beforeCharacterPositions) {
      if (pos.index >= startIndex) yield pos;
    }
  }

  public *generateBeforeCharacterPositionsReverseStartingWith(startIndex: number): Generator<CharPosition> {
    for (const pos of this.beforeCharacterPositionsReverse) {
      if (pos.index >= startIndex) yield pos;
    }
  }

  private *generateNodes(): Generator<Node, void, undefined> {
    yield this.node;
    for (let i = 0; i < this.node.childNodes.length; i++) {
      const child = this.node.childNodes[i];
      const childWalker = new NodeWalker(child);
      yield* childWalker.nodes;
    }
  }

  private *generateChildNodes(): Generator<Node> {
    for (let i = 0; i < this.node.childNodes.length; i++) {
      yield this.node.childNodes[i];
    }
  }

  private *generateTextNodes() {
    for (let i = 0; i < this.node.childNodes.length; i++) {
      const child = this.node.childNodes[i];
      if (child instanceof Text) yield child;
      else {
        const childWalker = new NodeWalker(child);
        yield* childWalker.textNodes;
      }
    }
  }

  private *generateTextNodesReverse() {
    for (let i = this.node.childNodes.length - 1; i >= 0; i--) {
      const child = this.node.childNodes[i];
      if (child instanceof Text) yield child;
      else {
        const childWalker = new NodeWalker(child);
        yield* childWalker.textNodesReverse;
      }
    }
  }

  private *generateBeforeCharacterPositions() {
    let index = 0;
    for (const textNode of this.textNodes) {
      const textContent = textNode.textContent!; // Zwischenspeichern wichtig für Performance in Firefox
      for (let i = 0; i < textContent.length; i++) {
        if (textContent[i] !== String.fromCharCode(8203)) {
          yield {
            index,
            char: textContent[i],
            // get char() {
            //   return textNode.textContent![i];
            // },
            node: textNode,
            nodeOffset: i,
          };
          index++;
        }
      }
    }
  }

  private *generateBeforeCharacterPositionsReverse() {
    let index = 0;
    for (const textNode of this.textNodesReverse) {
      const textContent = textNode.textContent!; // Zwischenspeichern wichtig für Performance in Firefox
      for (let i = textContent.length - 1; i >= 0; i--) {
        if (textContent[i] !== String.fromCharCode(8203)) {
          yield {
            index,
            char: textContent[i],
            node: textNode,
            nodeOffset: i,
          };
          index++;
        }
      }
    }
  }

  private *generateTextPositions() {
    let index = 0;
    for (const textNode of this.textNodes) {
      const textContent = textNode.textContent!; // Zwischenspeichern wichtig für Performance in Firefox
      for (let i = 0; i < textContent.length; i++) {
        if (textContent[i] !== String.fromCharCode(8203)) {
          yield { index, node: textNode, nodeOffset: i };
          index++;
        }
      }
      yield { index, node: textNode, nodeOffset: textContent.length };
    }
  }

  private *generateTextPositionsReverse() {
    let index = 0;
    for (const textNode of this.textNodesReverse) {
      const textContent = textNode.textContent!; // Zwischenspeichern wichtig für Performance in Firefox
      yield { index, node: textNode, nodeOffset: textContent.length };
      for (let i = textContent.length - 1; i >= 0; i--) {
        if (textContent[i] !== String.fromCharCode(8203)) {
          index++;
          yield { index, node: textNode, nodeOffset: i };
        }
      }
    }
  }
}
