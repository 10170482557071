<fz-panel *ngIf="model != null">
  <div class="Ueberschriften2"><fz-label>Wahlunterricht/Freiwillige Unterrichtsveranstaltungen</fz-label></div>
  <div
    style="margin-top: 8px; display: grid; grid-template-columns: 1fr 151px; grid-auto-rows: min-content; row-gap: 8px"
  >
    <div class="Noten_Beschriftung">
      <fz-label>Unterricht in der Herkunftssprache </fz-label
      ><fz-text placeholder="Sprache" [field]="model.herkunftssprache.labelField"></fz-text>
    </div>
    <div class="Noten_Text">
      <fz-panel style="grid-column: 3; text-align: center" backgroundColor="rgba(0,0,0,0.15)">
        <fz-note [field]="model.herkunftssprache.noteField"></fz-note>
      </fz-panel>
    </div>
  </div>
</fz-panel>
