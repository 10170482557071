<span
  [attr.data-placeholder]="placeholder"
  [fzRichText]="fragment"
  [dontClone]="true"
  style="white-space: pre-wrap"
></span>
<ng-template #content="fzPortal" fzPortal>
  <fz-string
    class="select-auto"
    #stringElement
    [shadow]="textShadow"
    fzTextInput
    (pointCaret)="onPointCaret()"
    (insert)="onInsert($event)"
    (replace)="onReplace($event)"
    (delete)="onDelete()"
    (backspace)="onDelete()"
    (focusin)="onFocusin()"
    fzFocusable
    [placeholder]="text.length === 0 ? placeholder : undefined"
    [textRange]="{ start: 0, length: text.length }"
    [fzFont]="font"
    [fzPosition]="rect"
    >{{ textNormal }}</fz-string
  >
</ng-template>
<ng-template #backDeco="fzPortal" fzPortal>
  <div [fzRect]="rect">
    <div
      [ngClass]="{
        'plausi-none': field == null,
        'plausi-ok': field != null && field.error == null && field.warning == null,
        'plausi-error': field?.error,
        'plausi-warning': field?.warning,
      }"
    ></div>
  </div>
</ng-template>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div style="position: absolute">
    <div
      *ngIf="hasFocus"
      fzFocusable
      [style.top.px]="rect.bottom"
      [style.left.px]="rect.left"
      class="floating fz-note"
      tabindex="0"
    >
      <p-listbox
        [listStyle]="{ 'max-height': '227px' }"
        [options]="field?.candidates || []"
        [(ngModel)]="value"
        (onClick)="candidateClick()"
      >
        <ng-template let-candidate pTemplate="item">
          <div class="whitespace-nowrap">{{ candidate.displayString }}</div>
        </ng-template>
      </p-listbox>
    </div>
  </div>
</ng-template>
