import { Component, Input, OnChanges } from '@angular/core';
import { helpInfoTexts } from './help-info-texts';

@Component({
  selector: 'fz-help-info',
  templateUrl: './help-info.component.html',
  styleUrls: ['./help-info.component.scss'],
})
export class HelpInfoComponent implements OnChanges {
  @Input() key: string = '';
  text: string = '';

  ngOnChanges(): void {
    this.text = helpInfoTexts[this.key];
  }
}
