import { AppInfoService } from '../../shared/services';
import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'fz-zeugnissatz-drucken-page',
  templateUrl: './zeugnissatz-drucken-page.component.html',
  styleUrls: ['./zeugnissatz-drucken-page.component.scss'],
})
export class ZeugnissatzDruckenPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Zeugnissatz drucken'));
  }
}
