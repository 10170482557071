import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import ImportTbResult from '../../../../models/generated/import-tb-result';
import { ImportTbService } from '../import-tb.service';

@Component({
  selector: 'app-import-tb-select-mode',
  templateUrl: './import-tb-select-mode.component.html',
  styleUrls: ['./import-tb-select-mode.component.scss'],
})
export class ImportTbSelectModeComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Input() importTbResult?: ImportTbResult;
  @Output() importFinished = new EventEmitter<ImportTbResult>();
  @Output() restartSelected = new EventEmitter<boolean>();

  loading: boolean = false;

  constructor(private importTbService: ImportTbService) {}

  async append() {
    if (this.importTbResult?.transferId != null) {
      try {
        this.loading = true;
        const data = await this.importTbService.importManuellAppend(this.importTbResult.transferId);
        this.importFinished.next(data);
      } finally {
        this.loading = false;
      }
    } else {
      try {
        this.loading = true;
        const data = await this.importTbService.importInternetAppend();
        this.importFinished.next(data);
      } finally {
        this.loading = false;
      }
    }
  }

  async override() {
    if (this.importTbResult?.transferId != null) {
      try {
        this.loading = true;
        const data = await this.importTbService.importManuellOverride(this.importTbResult.transferId);
        this.importFinished.next(data);
      } finally {
        this.loading = false;
      }
    } else {
      try {
        this.loading = true;
        const data = await this.importTbService.importInternetOverride();
        this.importFinished.next(data);
      } finally {
        this.loading = false;
      }
    }
  }

  restart() {
    this.restartSelected.next(true);
  }
}
