<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="fz-paragraph-text">
    <p>Beim Import der Textbausteine ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.</p>
    <p class="preWhiteSpaced">
      {{ importTbResult?.text }}
    </p>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        class="w-64"
        icon="pi pi-replay"
        label="Erneut importieren"
        (click)="restart()"
      ></button>
    </div>
  </ng-template>
</p-panel>
