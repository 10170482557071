/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { NIFormular } from './ni-formular';
import { NIZeugnisFormular } from './ni-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { NIZeugnistypComponent } from '../projectables/ni-zeugnistyp.component';
import { NIZeugnissatzComponent } from '../projectables/ni-zeugnissatz.component';
import { NISchuelerComponent } from '../projectables/ni-schueler.component';
import { NIVersaeumnisComponent } from '../projectables/ni-versaeumnis.component';
import { NIUnterschriftComponent } from '../projectables/ni-unterschrift.component';
import { NIFachtexteBlockFactoryComponent } from '../blocks/ni-fachtexte-block-factory.component';

export class NI34hjFachtexteFormular extends NIZeugnisFormular {
  get viewType(): FormularViewType {
    return NI34hjFachtexteFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    return noteFields;
  }
}

@Component({
  selector: 'fz-ni-34hj-fachtexte-formular',
  templateUrl: 'ni-34hj-fachtexte-formular.component.html',
})
export class NI34hjFachtexteFormularComponent extends FormularComponentBase<NI34hjFachtexteFormular> {
  static createModel(formular: NIFormular) {
    return {
      zeugnistyp: NIZeugnistypComponent.createModel(formular),
      zeugnissatz: NIZeugnissatzComponent.createModel(formular),
      schueler: NISchuelerComponent.createModel(formular),
      versaeumnis: NIVersaeumnisComponent.createModel(formular),
      interessen: {
        label: 'Besondere Interessen und Fähigkeiten',
        textField: formular.interessen,
      },
      lernFachList: NIFachtexteBlockFactoryComponent.createModel(formular.lernFachList),
      lernArbeitsverhalten: {
        label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
      },
      sozialverhalten: {
        label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
      },
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      unterschrift: NIUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return NI34hjFachtexteFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<NI34hjFachtexteFormular>) {
    super(formularService);
  }
}
