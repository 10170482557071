import { Component, ElementRef, Input, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableDecoratorBase } from './projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { DateComponent } from './date.component';
import { WorkspaceService } from '../../shared/services';
import moment from 'moment';
import { DateField } from '../../models/fields/date-field';

@Component({
  selector: 'fz-linked-date',
  templateUrl: 'linked-date.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, LinkedDateComponent)],
})
export class LinkedDateComponent extends ProjectableDecoratorBase implements Projectable {
  @Input() field: DateField<any> | undefined;
  @Input() linkedField: DateField<any> | undefined;
  @Input() fieldName: string | undefined;
  @ViewChild(DateComponent) date: DateComponent | undefined;
  loading: boolean = false;

  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private workspaceService: WorkspaceService
  ) {
    super(elementRef, parentProvider);
  }

  get isDifferent(): boolean {
    return this.field?.value != null && this.field.value.getTime() !== this.linkedField?.value?.getTime();
  }

  modelChange(): void {
    if (this.field != null && this.linkedField != null) {
      if (this.linkedField.value == null) {
        this.linkedField.value = this.field?.value;
        this.copyToAll(true);
      }
    }
  }

  async copyToAll(onlyIfEmpty = false) {
    if (this.field != null && this.linkedField != null && this.fieldName != null) {
      try {
        this.loading = true;
        this.linkedField.value = this.field?.value;
        if (this.field?.value != null) {
          const zeugnisse = this.workspaceService.selectedZeugnisse.filter(
            (z) => z !== this.workspaceService.selectedZeugnis
          );
          for (const zeugnis of zeugnisse) {
            if (!onlyIfEmpty || (zeugnis as any)[this.fieldName] == null) {
              (zeugnis as any)[this.fieldName] = moment(this.field.value).format('DD.MM.YYYY');
            }
          }
          await this.workspaceService.saveSelectedZeugnissatz();
        }
      } finally {
        this.loading = false;
      }
    }
  }
}
