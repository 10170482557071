import { Component, OnInit } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'fz-signalr-notifications',
  templateUrl: './signalr-notifications.component.html',
})
export class SignalRNotificationsComponent implements OnInit {
  connection?: signalR.HubConnection;

  constructor(
    private authService: FlinkyAuthService,
    private notificationService: NotificationService
  ) {
    const baseUrl = document.getElementsByTagName('base')[0].href;

    const url = (baseUrl + '/signalr_notifications').replace(/([^:]\/)\/+/g, '$1');

    this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();

    this.connection.on('OtherSessionStarted', (_message: string, _result: boolean) => {
      this.notificationService.showPermanentInfo(
        'Ihre Sitzung wurde beendet, da ein Login von einem anderen Computer mit Ihrem Benutzerccount durchgeführt wurde!',
        'Sitzung beendet'
      );
      this.authService.logout(true);
    });

    this.authService.loginStateChanged.subscribe(async (result) => {
      if (result === true) {
        if (this.connection != null) {
          // Verbindung zum Hub aufbauen bei login
          this.connection
            .start()
            .then(() => {
              if (this.connection?.connectionId != null) {
                this.authService.registerConnectionId(this.connection.connectionId);
              }
            })
            .catch((err: any) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      } else {
        // Verbindung zum Hub trennen bei logout
        this.connection?.stop();
      }
    });
  }

  ngOnInit(): void {
    // eslint-disable-next-line no-console
    console.log('notification init');
  }
}
