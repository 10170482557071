import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';

@Component({
  selector: 'fz-sl-zeugniskopf',
  templateUrl: 'sl-zeugniskopf.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLZeugniskopfComponent)],
})
export class SLZeugniskopfComponent extends SLPanelDecoratorBase {}
