/* eslint-disable no-bitwise */
import { ChangeDetectorRef, Component, ContentChild, Input, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { Block, BlockFactory, BlockFactoryProvider, BlockRange } from './block-factory';
import { BlockDirective } from './block.directive';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BorderDirection, borderWidthNormal, isBorder } from '@modules/dom/border-direction';
import { Distance } from '@modules/dom/distance';
import { getDistanceOverride, DistanceOverride } from '@modules/dom/distance-override';

export type DecoBlockModel = {
  block: Block;
  contentPosition: DOMRect;
  rect: DOMRect;
};

@Component({
  selector: 'fz-deco',
  templateUrl: 'deco-block-factory.component.html',
  providers: [provideInterfaceBy(BlockFactoryProvider, DecoBlockFactoryComponent)],
})
export class DecoBlockFactoryComponent implements BlockFactory {
  @Input() border: BorderDirection = BorderDirection.none;
  @Input() borderColor = 'black';
  @Input() padding: Distance = 'none';
  @Input() paddingTop: DistanceOverride = 'use-fallback';
  @Input() paddingBottom: DistanceOverride = 'use-fallback';
  @Input() paddingLeft: DistanceOverride = 'use-fallback';
  @Input() paddingRight: DistanceOverride = 'use-fallback';
  @ContentChild(BlockFactoryProvider) blockFactoryProvider!: BlockFactoryProvider;
  @ViewChildren(BlockDirective) pageItems = new QueryList<BlockDirective>();

  blockModels: DecoBlockModel[] = [];
  borderWidth = borderWidthNormal;
  borderDirection = BorderDirection;
  isBorder = isBorder;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetector: ChangeDetectorRef
  ) {}
  get blockFactory(): BlockFactory {
    return this.blockFactoryProvider.provided;
  }

  get borderWidthTop(): number {
    return isBorder(this.border, BorderDirection.top) ? this.borderWidth : 0;
  }
  get borderWidthBottom(): number {
    return isBorder(this.border, BorderDirection.bottom) ? this.borderWidth : 0;
  }
  get borderWidthLeft(): number {
    return isBorder(this.border, BorderDirection.left) ? this.borderWidth : 0;
  }
  get borderWidthRight(): number {
    return isBorder(this.border, BorderDirection.right) ? this.borderWidth : 0;
  }

  get paddingTopPx(): number {
    return getDistanceOverride(this.paddingTop, this.padding);
  }
  get paddingBottomPx(): number {
    return getDistanceOverride(this.paddingBottom, this.padding);
  }
  get paddingLeftPx(): number {
    return getDistanceOverride(this.paddingLeft, this.padding);
  }
  get paddingRightPx(): number {
    return getDistanceOverride(this.paddingRight, this.padding);
  }

  project(): void {
    this.blockFactory.project();
  }
  getBlockCount(): number {
    return this.blockFactory.getBlockCount();
  }
  measureHeight(range: BlockRange): number {
    return this.paddingTopPx + this.blockFactory.measureHeight(range) + this.paddingBottomPx;
  }
  layout(ranges: BlockRange[]): Block[] {
    this.blockModels = this.blockFactory.layout(ranges).map((block, index) => {
      return {
        block,
        contentPosition: new DOMRect(this.paddingLeftPx, this.paddingTopPx),
        rect: new DOMRect(
          0,
          0,
          this.elementRef.nativeElement.getBoundingClientRect().width,
          this.measureHeight(ranges[index])
        ),
      };
    });

    this.changeDetector.detectChanges();
    return this.pageItems.toArray();
  }
}
