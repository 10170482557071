import jsPDF from 'jspdf';
import { Quantity } from '../dom/quantity';
import { ContentElement } from './content-element';

export class CrossElement extends ContentElement {
  constructor() {
    super();
    //   const shadowRoot = this.attachShadow({ mode: 'open' });
    //   shadowRoot.innerHTML = `<style>:host { display: block; position: relative }</style>
    //   <svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100' style="position: absolute; width: 100%; height: 100%">
    //   <line x1='0' y1='0' x2='100' y2='100' stroke='black' vector-effect='non-scaling-stroke'/>
    //   <line x1='0' y1='100' x2='100' y2='0' stroke='black' vector-effect='non-scaling-stroke'/>
    // </svg>`;
  }

  connectedCallback() {
    const style = window.getComputedStyle(this);
    if (style.visibility !== 'hidden') {
      this.style.display = 'block';
      this.style.position = 'relative';
      this.innerHTML = `
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100' style="position: absolute; width: 100%; height: 100%">
    <line x1='0' y1='0' x2='100' y2='100' stroke='black' vector-effect='non-scaling-stroke'/>
    <line x1='0' y1='100' x2='100' y2='0' stroke='black' vector-effect='non-scaling-stroke'/>
  </svg>`;
    }
  }
  exportPdf(doc: jsPDF, pageRect: DOMRect): void {
    const nodeRect = this.getBoundingClientRect();
    const left = Quantity.px2Mm(nodeRect.left - pageRect.left);
    const top = Quantity.px2Mm(nodeRect.top - pageRect.top);
    const right = Quantity.px2Mm(nodeRect.right - pageRect.left);
    const bottom = Quantity.px2Mm(nodeRect.bottom - pageRect.top);
    doc.setLineWidth(0.25);
    doc.setDrawColor(0, 0, 0);
    doc.line(left, top, right, bottom);
    doc.line(right, top, left, bottom);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'fz-cross': CrossElement;
  }
}
