<p-steps [model]="items" [activeIndex]="activeStep" class="pb-5"></p-steps>

<ng-container [ngSwitch]="activeStep">
  <fz-schuelerliste-import-fileselection
    *ngSwitchCase="0"
    (next)="onFileSelected($event)"
    (back)="back()"
  ></fz-schuelerliste-import-fileselection>

  <fz-schuelerliste-import-preview
    *ngSwitchCase="1"
    [lines]="fileLines"
    (next)="onLinesSelected($event)"
    (back)="back()"
  ></fz-schuelerliste-import-preview>

  <fz-schuelerliste-import-assign
    *ngSwitchCase="2"
    [lines]="selectedLines"
    (next)="onDataAssigned($event)"
    (back)="back()"
  ></fz-schuelerliste-import-assign>

  <fz-schuelerliste-import-filter
    *ngSwitchCase="3"
    [schuelers]="schuelers"
    (back)="back()"
    (next)="onSchuelersSelected($event)"
  ></fz-schuelerliste-import-filter>
  <fz-schuelerliste-import-finished *ngSwitchCase="4" (next)="close()"></fz-schuelerliste-import-finished>
</ng-container>
