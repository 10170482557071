import { SprachenportfolioSprache } from '../../../models/enums/sprachenportfolio-sprache';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { RPZeugnis } from '../../../models/rp-zeugnis';

export class SprachenportfolioSpracheField extends DropdownField<RPZeugnis, SprachenportfolioSprache | null> {
  constructor(private zeugnis: RPZeugnis) {
    super(
      {
        label: 'Sprachenportfoliosprache',
        labelShort: 'SprPortSpr',
        property: 'sprachenportfolioSprache',
        candidates: [
          { displayStringShort: 'Fr', value: 'Franzoesisch', content: 'Französisch' },
          { displayStringShort: 'En', value: 'Englisch', content: 'Englisch' },
        ],
      },
      { object: zeugnis }
    );
  }
}
