import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalRNotificationsComponent } from './signalr-notifications.component';

@NgModule({
  declarations: [SignalRNotificationsComponent],
  imports: [CommonModule],
  exports: [SignalRNotificationsComponent],
})
export class SignalRNotificationsModule {}
