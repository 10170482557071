import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';

@Component({
  selector: 'fz-rp-zeugniskopf',
  templateUrl: 'rp-zeugniskopf.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPZeugniskopfComponent)],
})
export class RPZeugniskopfComponent extends RPPanelDecoratorBase {}
