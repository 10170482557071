import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';
import { RPFormular } from '../formulare/rp-formular';

@Component({
  selector: 'fz-rp-schulort-protokoll',
  templateUrl: 'rp-schulort-protokoll.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPSchulortProtokollComponent)],
})
export class RPSchulortProtokollComponent extends RPPanelDecoratorBase {
  static createModel(formular: RPFormular) {
    return {
      schulortString: formular.zeugnissatz.zeugniskopflizenz.schulort,
      protokolldatumField: formular.protokolldatum,
    };
  }

  @Input() model: ReturnType<typeof RPSchulortProtokollComponent.createModel> | undefined;
}
