<ng-template fzTemplate="sidebar">
  <fz-apply-kp></fz-apply-kp>
  <fz-rp-schueler-typ></fz-rp-schueler-typ>
  <fz-rp-herkunftssprache></fz-rp-herkunftssprache>
  <fz-rp-abgangszeugnis></fz-rp-abgangszeugnis>
  <fz-rp-kp-button></fz-rp-kp-button>
</ng-template>
<fz-formular
  name="RP234jaStandard"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  <fz-rp-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-rp-zeugniskopf>
  <fz-rp-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-rp-zeugnissatz>
  <fz-rp-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-rp-schueler>
  <fz-deco fzFormularItem="sps" paddingLeft="normal" paddingRight="normal" *ngIf="model.sps != null">
    <fz-rp-sps fzContainer [model]="model.sps"></fz-rp-sps>
  </fz-deco>
  <fz-deco
    fzFormularItem="sozialLernArbeitsverhalten"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
  >
    <fz-section
      [label]="model.sozialLernArbeitsverhalten.headerLabel"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'" paddingTop="none" paddingBottom="normal">
        <fz-verbal
          [field]="model.sozialLernArbeitsverhalten.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-section>
  </fz-deco>
  <ng-container *ngFor="let fach of model.faecher; trackBy: trackByProperty">
    <fz-deco [fzFormularItem]="fach.key" [border]="formular.showBorder ? borderDirection.all : borderDirection.none">
      <fz-section
        [label]="fach.headerLabel"
        headerClass="Ueberschriften2"
        [headerPadding]="formular.showBorder ? 'normal' : 'none'"
        headerPaddingBottom="none"
      >
        <fz-panel fzSectionHeader [padding]="formular.showBorder ? 'normal' : 'none'" paddingBottom="none">
          <fz-rp-fach-header [model]="fach.header"></fz-rp-fach-header>
        </fz-panel>
        <fz-panel
          fzSectionHeader
          [padding]="formular.showBorder ? 'normal' : 'none'"
          paddingBottom="none"
          class="Ueberschriften2"
        >
          <fz-label>{{ fach.header.labelFortsetzung }} (Fortsetzung)</fz-label>
        </fz-panel>
        <fz-sequence>
          <fz-deco *ngIf="fach.kp != null" paddingTop="normal" paddingBottom="minus-border">
            <fz-kp [model]="fach.kp" [fach]="getFach(fach.key)"></fz-kp>
          </fz-deco>
          <fz-deco
            *ngIf="fach.textField != null"
            [padding]="formular.showBorder ? 'normal' : 'none'"
            paddingTop="normal"
            paddingBottom="normal"
          >
            <fz-verbal
              [field]="fach.textField"
              [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
            ></fz-verbal>
          </fz-deco>
        </fz-sequence>
      </fz-section>
    </fz-deco>
  </ng-container>
  <fz-deco
    fzFormularItem="sprachenportfolio"
    [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
    [paddingRight]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-rp-sprachenportfolio fzContainer [model]="model.sprachenportfolio"></fz-rp-sprachenportfolio>
  </fz-deco>
  <fz-deco fzFormularItem="schrift" [border]="formular.showBorder ? borderDirection.all : borderDirection.none">
    <fz-section
      [label]="model.schrift.headerLabel"
      headerClass="Ueberschriften2"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'" paddingTop="none" paddingBottom="normal">
        <fz-verbal
          [field]="model.schrift.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-deco>
    </fz-section>
  </fz-deco>
  <fz-rp-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-rp-versaeumnis>
  <fz-deco fzFormularItem="bemerkungen" [border]="formular.showBorder ? borderDirection.all : borderDirection.none">
    <fz-section
      fzFormularItem="bemerkungen"
      [label]="model.bemerkungen.headerLabel"
      headerClass="Ueberschriften2"
      [headerPadding]="formular.showBorder ? 'normal' : 'none'"
      headerPaddingBottom="normal"
    >
      <fz-deco [padding]="formular.showBorder ? 'normal' : 'none'" paddingTop="none" paddingBottom="normal">
        <fz-intelli
          [field]="model.bemerkungen.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-intelli>
      </fz-deco>
    </fz-section>
  </fz-deco>
  <fz-rp-grundschulziel
    fzFormularItem="grundschulziel"
    fzContainer
    *ngIf="model.grundschulziel != null"
  ></fz-rp-grundschulziel>
  <fz-rp-schulort fzFormularItem="schulort" fzContainer [model]="model.schulort"></fz-rp-schulort>
  <fz-rp-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-rp-unterschrift>
  <fz-rp-kenntnis fzFormularItem="kenntnis" fzContainer></fz-rp-kenntnis>
</fz-formular>
