<fz-panel
  *ngIf="model != null"
  padding="normal"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div style="text-align: center" class="Zeugnistitel">
    <fz-dropdown [field]="model.zeugnisTypField"></fz-dropdown>
  </div>
  <div class="Schuelername">
    <fz-label>{{ model.gespraechGefuehrtVonLabel }} </fz-label
    ><fz-text [field]="model.gespraechGefuehrtVonField"></fz-text>
  </div>
</fz-panel>
