<fz-panel *ngIf="model != null">
  <div style="margin-top: 8px; display: grid; grid-template-columns: 1fr 1fr; column-gap: 19px">
    <div
      style="grid-column: 1; display: grid; grid-template-columns: 1fr 197px; grid-auto-rows: min-content; row-gap: 8px"
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.deutsch.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.deutsch.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.fremdsprache.label }} (</fz-label
        ><fz-dropdown [field]="model.fremdsprache.labelDropdownField"></fz-dropdown><fz-label>)</fz-label>
      </div>
      <div class="Noten_Text" style="align-self: center">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.fremdsprache.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.sachunterricht.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.sachunterricht.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.religion.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.religion.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.mathematik.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.mathematik.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
    <div
      style="grid-column: 2; display: grid; grid-template-columns: 1fr 197px; grid-auto-rows: min-content; row-gap: 8px"
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.musik.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.musik.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.bildendeKunst.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.bildendeKunst.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.werken.label }}</fz-label>
      </div>
      <div class="Noten_Text" style="align-self: center">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.werken.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.gestalten.label }}</fz-label>
      </div>
      <div class="Noten_Text" style="align-self: center">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.gestalten.noteField"></fz-note>
        </fz-panel>
      </div>
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.sport.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.sport.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
  </div>
  <div
    style="margin-top: 8px; display: grid; grid-template-columns: 1fr 1fr; column-gap: 19px"
    *ngIf="model.herkunftssprache != null"
  >
    <div
      style="grid-column: 1; display: grid; grid-template-columns: 1fr 197px; grid-auto-rows: min-content; row-gap: 8px"
    >
      <div class="Noten_Beschriftung" style="grid-column: 1 / span 2">
        <fz-label>Herkunftssprachlicher Unterricht:</fz-label>
      </div>
    </div>
    <div
      style="grid-column: 2; display: grid; grid-template-columns: 1fr 197px; grid-auto-rows: min-content; row-gap: 8px"
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.herkunftssprache.label }} (</fz-label
        ><fz-text [field]="model.herkunftssprache.labelTextField" placeholder="Herkunftssprache"></fz-text
        ><fz-label>)</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.herkunftssprache.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
  </div>
</fz-panel>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div style="position: absolute">
    <ng-container [fzPortalOutlet]="panel?.frontDeco"></ng-container>
  </div>
  <div [fzPosition]="panel?.rect" fzFocusable tabindex="0">
    <fz-ni-herkunftssprache
      *ngIf="hasFocus"
      [style.bottom.px]="panel?.rect?.top || 0"
      [style.left.px]="panel?.rect?.left || 0"
      [style.width.px]="panel?.rect?.width || 0"
      style="display: block"
      class="floating fz-note"
    >
    </fz-ni-herkunftssprache>
  </div>
</ng-template>
