import { AnlageTyp } from '../../../models/enums/anlage-typ';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { HEZeugnis } from '../../../models/he-zeugnis';

export class AnlageTypField extends DropdownField<HEZeugnis, AnlageTyp | null> {
  constructor(private zeugnis: HEZeugnis) {
    super(
      {
        label: 'Anlagentyp',
        labelShort: 'AnlTyp',
        required: false,
        property: 'anlageTyp',
        candidates: [
          { displayStringShort: 'keine', value: 'Keine', content: '' },
          { displayStringShort: 'Deutsch', value: null, content: '- es folgt eine Anlage -' },
          { displayStringShort: 'Freitext', value: 'Allgemein', content: '- es folgt eine Anlage -' },
        ],
      },
      { object: zeugnis }
    );
  }
}
