<fz-panel
  padding="normal"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div style="text-align: center" class="Zeugnistitel">
    <fz-label>Ergänzende Erläuterungen</fz-label>
  </div>
  <div style="text-align: center" class="Schuelername">
    <fz-label>zum {{ formular.zeugnisTypLabel }} der {{ formular.zeugnissatz.klassenstufeLabel }}</fz-label>
  </div>
</fz-panel>
