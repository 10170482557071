import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import ChangePasswordResult from '../../../models/generated/change-password-result';

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordService {
  constructor(private http: HttpClient) {}

  changePassword(oldPassword: string, newPassword: string, connectionId?: string | null) {
    const data = {
      oldPassword,
      newPassword,
      connectionId,
    };

    return lastValueFrom(this.http.put<ChangePasswordResult>('/api/Auth/changepassword', data));
  }
}
