import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';
import { BWFormular } from '../formulare/bw-formular';

@Component({
  selector: 'fz-bw-schueler',
  templateUrl: 'bw-schueler.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, BWSchuelerComponent)],
})
export class BWSchuelerComponent extends BWPanelDecoratorBase {
  static createModel(formular: BWFormular) {
    return {
      normal:
        !formular.isAbschluss || formular.category !== 'zeugnis'
          ? {
              klassenbezeichnungField:
                formular.zeugnis.zeugnisTyp !== 'Abgang' ? formular.zeugnissatz.klassenbezeichnung : undefined,
              vornameField: formular.schuelerVorname,
              nameField: formular.schuelerName,
              geschlechtField: formular.schueler.geschlecht,
            }
          : undefined,
      abgang:
        formular.isAbschluss && formular.category === 'zeugnis'
          ? {
              vornameField: formular.schuelerVorname,
              nameField: formular.schuelerName,
              geschlechtField: formular.schueler.geschlecht,
              geburtsdatumField: formular.schueler.geburtsdatum,
              geburtsortField: formular.schueler.geburtsort,
            }
          : undefined,
    };
  }

  @Input() model: ReturnType<typeof BWSchuelerComponent.createModel> | undefined;
}
