<div
  class="h-full transition-all relative"
  [ngClass]="{
    'schueler-grid-normal': !minimized,
    'schueler-grid-narrow': minimized && appearance !== 'overlay',
    'schueler-grid-collapsed': minimized && appearance === 'overlay',
  }"
>
  <div class="absolute z-30" style="left: 0.5rem; top: 0.5rem">
    <button
      pButton
      class="p-button-outlined p-button-rounded"
      [icon]="minimized ? 'pi pi-arrow-right' : 'pi pi-arrow-left'"
      (click)="minimized = !minimized"
    ></button>
  </div>
  <p-table
    #grid
    [value]="workspaceService.selectedZeugnisse"
    selectionMode="single"
    [(selection)]="workspaceService.selectedZeugnis"
    (sortFunction)="customSort($event)"
    sortField="name"
    [customSort]="true"
    [scrollable]="true"
    scrollHeight="flex"
    class="h-full"
    styleClass="p-datatable-striped p-datatable-sm"
    dataKey="id"
    [resizableColumns]="false"
    [reorderableColumns]="false"
  >
    <ng-template pTemplate="header">
      <tr style="height: 51px">
        <th class="pl-2 pr-2 w-14" style="min-width: 56px" scope="col"></th>

        <ng-container *ngIf="!minimized">
          <th scope="col" class="w-36" pSortableColumn="name">
            <div class="flex">Name <p-sortIcon class="flex" field="name"></p-sortIcon></div>
          </th>
          <th scope="col" class="w-32" pSortableColumn="vorname">
            <div class="flex">Vorname <p-sortIcon class="flex" field="vorname"></p-sortIcon></div>
          </th>
          <th scope="col" class="pt-0 pb-0 w-14">
            <button
              *ngIf="!workspaceService.isReadonly"
              type="button"
              pButton
              icon="pi pi-plus"
              (click)="workspaceService.createSchueler()"
              class="p-button-rounded shadow-md"
            ></button>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-zeugnis let-rowIndex="rowIndex">
      <tr
        [id]="zeugnis.id"
        [pSelectableRow]="zeugnis"
        [pSelectableRowDisabled]="zeugnis.id == workspaceService.selectedZeugnis?.id || zeugnis.isLoading"
        class="h-20"
        [ngClass]="{
          'fz-inaktiv': zeugnis.schueler.isAktiv === false,
          'fz-highlight': zeugnis?.id === workspaceService.selectedZeugnis?.id,
        }"
      >
        <td class="pr-2 w-14" style="min-width: 56px">
          <span class="p-overlay-badge">
            <p-avatar
              shape="circle"
              [style]="{ 'background-color': '#2196F3', color: '#ffffff' }"
              [label]="getInitialen(zeugnis)"
            ></p-avatar>
            <p-badge
              class="ml-1"
              *ngIf="formularSummary(zeugnis) as formularSummary"
              [value]="
                formularSummary.errorCount > 0
                  ? formularSummary.errorCount.toString()
                  : formularSummary.warningCount > 0
                    ? formularSummary.warningCount.toString()
                    : ' '
              "
              [severity]="
                formularSummary.errorCount > 0 ? 'danger' : formularSummary.warningCount > 0 ? 'warning' : 'success'
              "
            >
            </p-badge>
          </span>
        </td>
        <ng-container *ngIf="!minimized">
          <td class="w-36 break-words" style="max-width: 9rem">{{ zeugnis.schueler.name }}</td>
          <td class="w-32 break-words" style="max-width: 8rem">{{ zeugnis.schueler.vorname }}</td>
          <td class="w-14">
            <ng-container *ngIf="!workspaceService.isReadonly">
              <button
                *ngIf="zeugnis.schueler.isAktiv"
                class="p-button-rounded p-button-outlined p-button-sm p-button-danger"
                id="btn{{ rowIndex }}"
                type="button"
                pButton
                icon="pi pi-trash"
                (click)="toggleMenuOptions(zeugnis); schuelerMenu.toggle($event)"
                [loading]="isDeleting(zeugnis)"
              ></button>
              <button
                *ngIf="!zeugnis.schueler.isAktiv"
                class="p-button-rounded p-button-outlined p-button-sm"
                id="btn{{ rowIndex }}"
                type="button"
                pButton
                icon="pi pi-replay"
                (click)="toggleMenuOptions(zeugnis); schuelerMenu.toggle($event)"
              ></button>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-tieredMenu #schuelerMenu [model]="splitOptions" [appendTo]="'body'" [popup]="true"></p-tieredMenu>
