import { DialogModule } from 'primeng/dialog';
import { InfoDialogModule } from './../info-dialog/info-dialog.component';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemActionsComponent } from './system-actions.component';
import { TableModule } from 'primeng/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [SystemActionsComponent],
  imports: [
    CommonModule,
    ButtonModule,
    MenuModule,
    FileUploadModule,
    InfoDialogModule,
    DialogModule,
    TableModule,
    TieredMenuModule,
    ClipboardModule,
    OverlayPanelModule,
    ConfirmDialogModule,
  ],
  exports: [SystemActionsComponent],
})
export class SystemActionsModule {}
