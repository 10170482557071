<div *ngIf="formVisible; else changed" class="flex-grow h-0 overflow-auto">
  <form [formGroup]="passwordform" (ngSubmit)="onSubmit()">
    <p-panel header="Passwort ändern">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
          <label for="altesPasswort">altes Passwort</label>
          <p-password
            fzAutofocus
            id="altesPasswort"
            formControlName="oldPassword"
            [toggleMask]="true"
            [feedback]="false"
            styleClass="p-input-icon-right"
          ></p-password>
        </div>

        <div class="p-field p-col-12 p-md-12">
          <label for="neuesPasswort">neues Passwort</label>
          <p-password
            formControlName="newPassword"
            [toggleMask]="true"
            weakLabel="zu unsicher"
            mediumLabel="ausreichend"
            strongLabel="sicher"
            promptLabel="Passwort eingeben"
            [mediumRegex]="passwordRegexString"
            [strongRegex]="passwordRegexString"
            styleClass="p-input-icon-right"
          >
            <ng-template pTemplate="header">
              <h6>Passwort wählen</h6>
            </ng-template>
            <ng-template pTemplate="footer">
              <p-divider></p-divider>
              <p class="p-mt-2">Vorgaben</p>
              <ul class="p-pl-2 p-ml-2 p-mt-0 p-mb-2">
                <li *ngFor="let helpItem of passwordHelpList">{{ helpItem }}</li>
              </ul>
            </ng-template>
          </p-password>
          <div
            class="p-error"
            *ngIf="
              passwordform.get('passwordComparison')?.value != '' &&
              passwordform.get('passwordComparison')?.value != null &&
              passwordform.get('newPassword')?.value != '' &&
              passwordform.get('newPassword')?.value != null &&
              passwordform.errors?.['notmatched']
            "
          >
            Passwörter stimmen nicht überein
          </div>
          <div
            class="p-error"
            *ngIf="
              passwordform.get('oldPassword')?.value != '' &&
              passwordform.get('oldPassword')?.value != null &&
              passwordform.get('newPassword')?.value != '' &&
              passwordform.get('newPassword')?.value != null &&
              passwordform.errors?.['matched']
            "
          >
            neues Passwort darf nicht gleich dem alten Passwort sein
          </div>
          <div
            class="p-error"
            *ngIf="
              passwordform.get('newPassword')?.errors?.['pattern'] &&
              passwordform.get('passwordComparison')?.value != '' &&
              passwordform.get('passwordComparison')?.value != null &&
              passwordform.get('newPassword')?.value != '' &&
              passwordform.get('newPassword')?.value != null
            "
          >
            Passwort ist nicht komplex genug
          </div>
          <div
            class="p-error"
            *ngIf="
              passwordform.get('newPassword')?.errors?.['minlength'] &&
              passwordform.get('passwordComparison')?.value != '' &&
              passwordform.get('newPassword')?.value != '' &&
              passwordform.get('passwordComparison')?.value != null &&
              passwordform.get('newPassword')?.value != null
            "
          >
            Passwort ist nicht lang genug! Aktuelle Länge:
            {{ passwordform.get('newPassword')?.errors?.['minlength']?.actualLength }} Erforderliche Länge:
            {{ passwordform.get('newPassword')?.errors?.['minlength']?.requiredLength }}
          </div>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label for="passwortwiederholung">Passwortwiederholung</label>
          <p-password
            id="passwortwiederholung"
            [toggleMask]="true"
            formControlName="passwordComparison"
            [feedback]="false"
            styleClass="p-input-icon-right"
          >
          </p-password>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <div class="fz-button-group">
          <button pButton label="Speichern" icon="pi pi-check" type="submit" [disabled]="!passwordform.valid"></button>
        </div>
      </ng-template>
    </p-panel>
  </form>
</div>

<ng-template #changed class="flex-grow h-0 overflow-auto">
  <p-panel [header]="passwordHeadline">
    <div class="flex flex-col">
      <p class="preWhiteSpaced">{{ changedText }}</p>
      <button pButton type="button" label="Ok" width="100%" (click)="repeat()"></button>
    </div>
  </p-panel>
</ng-template>
