<fz-formular-viewer style="z-index: -10; opacity: 0" [hidden]="true"></fz-formular-viewer>
<p-panel
  class="fz-flex-panel"
  header="Bitte wählen Sie die zu druckenden Elemente"
  styleClass="flex-grow flex flex-col"
>
  <div (resized)="onResized($event)" class="flex-grow flex flex-col">
    <div [style.height]="tableHeight">
      <p-table
        #dt
        [value]="workspaceService.selectedZeugnisse"
        sortField="schuelerName"
        [scrollable]="true"
        scrollHeight="flex"
        [customSort]="true"
        (sortFunction)="customSort($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" pSortableColumn="schuelerName">
              <div class="flex">Name <p-sortIcon class="flex" field="schuelerName"></p-sortIcon></div>
            </th>
            <th scope="col" pSortableColumn="schuelerVorname">
              <div class="flex">Vorname <p-sortIcon class="flex" field="schuelerVorname"></p-sortIcon></div>
            </th>
            <th scope="col" *ngFor="let cat of categories">
              <p-triStateCheckbox
                name="select{{ cat.key }}"
                [(ngModel)]="catAll[cat.indx]"
                checkboxFalseIcon="pi pi-minus"
                (onChange)="alleChanged(cat.indx, cat.key, $event)"
                label="{{ cat.label }}"
              ></p-triStateCheckbox>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-zeugnis>
          <tr *ngIf="zeugnis.schueler.isAktiv">
            <td>{{ zeugnis.schuelerName }}</td>
            <td>{{ zeugnis.schuelerVorname }}</td>
            <td *ngFor="let cat of categories">
              <p-checkbox
                name="{{ cat.key }}"
                value="{{ zeugnis.id.toString() }}"
                [(ngModel)]="catVals[cat.indx]"
                (onChange)="setTriState(cat.indx, cat.key, $event)"
                *ngIf="hasCategory(zeugnis, cat.key) && hasSummary(zeugnis, cat.key)"
              ></p-checkbox>
              <p-badge
                class="ml-1"
                *ngIf="hasSummary(zeugnis, cat.key)"
                [value]="getSummaryText(zeugnis, cat.key)"
                [severity]="getSummarySeverity(zeugnis, cat.key)"
              >
              </p-badge>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="grid gap-2 lg:grid-flow-col">
      <div class="fz-button-group mb-2 p-align-center flex-auto">
        <div>Seitenformat</div>
        <p-dropdown
          [options]="pageSizeOptions"
          [group]="true"
          [(ngModel)]="selectedPageSize"
          optionLabel="label"
          optionValue="value"
          placeholder="Papierformat"
          (ngModelChange)="isDuplex = isDuplex ? selectedPageSize.pagesPerSheet === '1' : isDuplex"
        ></p-dropdown>
      </div>
      <div *ngIf="selectedPageSize.pagesPerSheet === '1'" class="fz-button-group mb-2 p-align-center flex-auto">
        <p-checkbox
          [disabled]="selectedPageSize.pagesPerSheet !== '1'"
          [binary]="true"
          [(ngModel)]="isDuplex"
          label="Für Duplexdruck aufbereiten (Einfügen von Leerseiten)"
        ></p-checkbox>
      </div>
      <div
        *ngIf="isBW && selectedPageSize.pagesPerSheet === 'broschuere'"
        class="fz-button-group mb-2 p-align-center flex-auto"
      >
        <p-checkbox [binary]="true" [(ngModel)]="hasDeckblatt" label="Deckblatt drucken? - Text"></p-checkbox>
        <input pInputText [disabled]="!hasDeckblatt" [(ngModel)]="deckblattText" />
      </div>
      <div class="fz-button-group mb-2 p-align-center flex-auto">
        <p-checkbox [binary]="true" [(ngModel)]="hasLochrand" label="Lochrand"></p-checkbox>
      </div>
      <div class="fz-button-group mb-2 p-align-center flex-auto">
        <p-checkbox [binary]="true" [(ngModel)]="individualPdfs" label="Einzelne PDFs"></p-checkbox>
      </div>
      <div class="fz-button-group mb-2 p-align-center flex-auto" *ngIf="hasSummaries()">
        <p-checkbox [binary]="true" [(ngModel)]="ignoreErrors" label="Fehler/Warnungen ignorieren"></p-checkbox>
      </div>
      <div class="fz-button-group flex-auto justify-end">
        @if (!this.individualPdfs) {
          <button
            pButton
            label="Drucken"
            icon="pi pi-print"
            (click)="print()"
            [disabled]="!isSomethingSelected() || hasErrors()"
          ></button>
        }
        <button
          pButton
          [label]="individualPdfs ? 'Zip herunterladen' : 'PDF herunterladen'"
          icon="pi pi-download"
          (click)="individualPdfs ? zip() : print(true)"
          [disabled]="!isSomethingSelected() || hasErrors()"
        ></button>
      </div>
    </div>
  </ng-template>
</p-panel>
<p-dialog
  header="Dokumente werden erstellt"
  styleClass="fz-dialog-header"
  [(visible)]="dialogVisible"
  [modal]="true"
  [closable]="false"
  [baseZIndex]="1010"
>
  <div class="flex justify-center items-center mb-4">
    <div class="pi pi-spin pi-spinner" style="font-size: 10rem"></div>
    <div class="absolute">{{ printProgress }}%</div>
  </div>
  Bitte warten Sie, während die Dokumente erstellt werden.
</p-dialog>
