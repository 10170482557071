import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';
import { RPFormular } from '../formulare/rp-formular';

@Component({
  selector: 'fz-rp-sps',
  templateUrl: 'rp-sps.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPSpsComponent)],
})
export class RPSpsComponent extends RPPanelDecoratorBase {
  static createModel(formular: RPFormular) {
    return {
      spsSelectField: formular.spsTyp,
    };
  }

  @Input() model: ReturnType<typeof RPSpsComponent.createModel> | undefined;

  lernen =
    'Der Schüler/Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang LERNEN unterrichtet. Er/Sie nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';
  lernenM =
    'Der Schüler hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang LERNEN unterrichtet. Er nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';
  lernenW =
    'Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang LERNEN unterrichtet. Sie nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';

  ganzheitlicheEntwicklung =
    'Der Schüler/Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang GANZHEITLICHE ENTWICKLUNG unterrichtet. Er/Sie nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';
  ganzheitlicheEntwicklungM =
    'Der Schüler hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang GANZHEITLICHE ENTWICKLUNG unterrichtet. Er nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';
  ganzheitlicheEntwicklungW =
    'Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang GANZHEITLICHE ENTWICKLUNG unterrichtet. Sie nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';

  sprache =
    'Der Schüler/Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang SPRACHE unterrichtet. Er/Sie nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';
  spracheM =
    'Der Schüler hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang SPRACHE unterrichtet. Er nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';
  spracheW =
    'Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang SPRACHE unterrichtet. Sie nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';

  freiPrefix =
    'Der Schüler/Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang ';
  freiPrefixM =
    'Der Schüler hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang ';
  freiPrefixW =
    'Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang ';
  freiSuffix =
    ' unterrichtet. Er/Sie nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';
  freiSuffixM =
    ' unterrichtet. Er nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';
  freiSuffixW =
    ' unterrichtet. Sie nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';
  get lernenLabel(): string {
    switch (this.formular.zeugnis.schueler.geschlecht) {
      case 'm':
        return this.lernenM;
      case 'w':
        return this.lernenW;
      default:
        return this.lernen;
    }
  }

  get ganzheitlicheEntwicklungLabel(): string {
    switch (this.formular.zeugnis.schueler.geschlecht) {
      case 'm':
        return this.ganzheitlicheEntwicklungM;
      case 'w':
        return this.ganzheitlicheEntwicklungW;
      default:
        return this.ganzheitlicheEntwicklung;
    }
  }

  get spracheLabel(): string {
    switch (this.formular.zeugnis.schueler.geschlecht) {
      case 'm':
        return this.spracheM;
      case 'w':
        return this.spracheW;
      default:
        return this.sprache;
    }
  }

  get freiPrefixLabel(): string {
    switch (this.formular.zeugnis.schueler.geschlecht) {
      case 'm':
        return this.freiPrefixM;
      case 'w':
        return this.freiPrefixW;
      default:
        return this.freiPrefix;
    }
  }

  get freiSuffixLabel(): string {
    switch (this.formular.zeugnis.schueler.geschlecht) {
      case 'm':
        return this.freiSuffixM;
      case 'w':
        return this.freiSuffixW;
      default:
        return this.freiSuffix;
    }
  }
}
