<form [formGroup]="passwordform" (ngSubmit)="checkPassword()">
  <p-panel>
    <ng-template pTemplate="header">
      <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
    </ng-template>
    <p class="mb-2">
      Bitte geben Sie das Transferpasswort für <em>{{ transferFile?.fileName }}</em> an.
    </p>
    <p-password
      fzAutofocus
      id="transferpassword"
      styleClass="p-input-icon-right"
      [toggleMask]="true"
      formControlName="password"
      [feedback]="false"
    >
    </p-password>
    <p *ngIf="error" class="pt-5 fz-validation-error">
      {{ errorMessage }}
    </p>
    <ng-template pTemplate="footer">
      <div class="fz-button-group">
        <button
          pButton
          type="button"
          label="Abbrechen"
          icon="pi pi-times"
          class="w-64 p-button-secondary"
          [loading]="loading"
          (click)="firstStep()"
        ></button>
        <button
          pButton
          type="submit"
          label="Weiter"
          icon="pi pi-caret-right"
          class="w-64"
          [loading]="loading"
          [disabled]="!passwordform.valid"
        ></button>
      </div>
    </ng-template>
  </p-panel>
</form>
