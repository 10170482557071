import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';

@Component({
  selector: 'fz-bw-seitenfuss',
  templateUrl: 'bw-seitenfuss.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, BWSeitenfussComponent)],
})
export class BWSeitenfussComponent extends BWPanelDecoratorBase {
  @Input() pageIndex = 0;
}
