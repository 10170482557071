import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { NIPanelDecoratorBase } from './ni-panel-decorator-base';

@Component({
  selector: 'fz-ni-versetzungsvermerk',
  templateUrl: 'ni-versetzungsvermerk.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, NIVersetzungsvermerkComponent)],
})
export class NIVersetzungsvermerkComponent extends NIPanelDecoratorBase {
  get labelVersetzt(): string {
    if (
      this.formular.zeugnis.zeugnissatz.klassenstufe < 2 ||
      (this.formular.zeugnis.zeugnissatz.klassenstufe <= 3 &&
        this.formular.zeugnis.schuelerTyp === 'Schuleingangsphase')
    ) {
      return `${this.formular.schueler.personalPronomen} rückt in die Jahrgangsstufe ${
        this.formular.zeugnis.zeugnissatz.klassenstufe + 1
      } vor.`;
    } else {
      return `${this.formular.schueler.personalPronomen} wird in die Jahrgangsstufe ${
        this.formular.zeugnis.zeugnissatz.klassenstufe + 1
      } versetzt.`;
    }
  }

  get labelNichtVersetzt(): string {
    if (
      this.formular.zeugnis.zeugnissatz.klassenstufe < 2 ||
      (this.formular.zeugnis.zeugnissatz.klassenstufe <= 3 &&
        this.formular.zeugnis.schuelerTyp === 'Schuleingangsphase')
    ) {
      return `${this.formular.schueler.personalPronomen} rückt in die Jahrgangsstufe ${
        this.formular.zeugnis.zeugnissatz.klassenstufe + 1
      } nicht vor.`;
    } else {
      return `${this.formular.schueler.personalPronomen} wird in die Jahrgangsstufe ${
        this.formular.zeugnis.zeugnissatz.klassenstufe + 1
      } nicht versetzt.`;
    }
  }
}
