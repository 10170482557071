<ng-container *ngIf="item != null">
  <ng-container *ngIf="!item.userManualPageBreak">
    <div class="anchor" *ngIf="userLayoutMode">
      <div class="button" (click)="dialogVisible = true">{{ item.userMarginBottomM }}mm</div>
    </div>
    <div class="dragger" [style.top.px]="item.userMarginBottom" (mousedown)="mousedown($event)">
      <!-- {{ instance.item.name }} -->
    </div>
  </ng-container>
  <div
    *ngIf="item.userManualPageBreak && userLayoutMode"
    class="manual-page-break"
    [style.top.px]="4"
    (click)="dialogVisible = true"
  >
    Seitenumbruch
  </div>
  <p-dialog
    header="Layouteinstellungen"
    [(visible)]="dialogVisible"
    [modal]="true"
    [style]="{ width: '24rem' }"
    (onHide)="layoutChange.emit()"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="abstand">Abstand</label>
        <div class="p-inputgroup">
          <p-inputNumber
            id="abstand"
            [(ngModel)]="item.userMarginBottomM"
            [min]="0"
            [allowEmpty]="false"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">mm</span>
        </div>
      </div>
      <div class="p-field p-col-12">
        <p-checkbox [binary]="true" label="Manueller Seitenumbruch" [(ngModel)]="item.userManualPageBreak"></p-checkbox>
      </div>
      <div class="p-field p-col-12" *ngIf="bottomAlignedVisible">
        <p-checkbox
          [binary]="true"
          label="Ab hier ans Seitenende verschieben"
          [(ngModel)]="item.userBottomAligned"
        ></p-checkbox>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <button pButton label="Zurücksetzen" (click)="item.reset()" class="p-button-secondary"></button>
      <button pButton label="Ok" (click)="dialogVisible = false"></button>
    </ng-template>
  </p-dialog>
</ng-container>
