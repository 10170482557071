<div class="flex items-center gap-1">
  <div *ngIf="!smallScreen">
    <span>{{ title }}</span>
    <span *ngIf="workspaceService.currentZeugnissatz != null && isDirty">*</span>
  </div>
  <div [ngClass]="!smallScreen ? 'ml-2' : ''" *ngIf="workspaceService.jahrgang != null">
    <p-dropdown
      class="fz-dropdown-primary"
      [appendTo]="'body'"
      [options]="zeugnissaetze"
      [autoDisplayFirst]="false"
      [ngModel]="selectedZeugnissatz"
      (ngModelChange)="switchSelectedZeugnissatz($event)"
    >
    </p-dropdown>
  </div>
  <div class="flex items-center">
    <button
      *ngIf="workspaceService.currentZeugnissatz != null && isDirty"
      pButton
      icon="pi pi-save"
      class="p-button-warning"
      [disabled]="workspaceService.isSaving"
      label="Speichern"
      (click)="workspaceService.saveSelectedZeugnissatz()"
    ></button>
    <button
      *ngIf="!environment.production && isDirty"
      pButton
      icon="pi pi-question-circle"
      class="p-button-text"
      (click)="diff()"
    ></button>
  </div>
</div>

<p-dialog [(visible)]="diffVisible" header="Diff" [style]="{ maxWidth: '90vw' }">
  <fz-diff-viewer [object1]="objectPersisted" [object2]="object"></fz-diff-viewer>
</p-dialog>
