import jsPDF from 'jspdf';
import { Quantity } from '../dom/quantity';
import { TextElement } from './text-element';
import { StringHelper } from '../dom/string-helper';

export class StringElement extends TextElement {
  constructor() {
    super();
  }
  static override is(node: Node): node is StringElement {
    return node instanceof HTMLElement && node.tagName.toLowerCase() === 'fz-string';
  }
  exportPdf(doc: jsPDF, pageRect: DOMRect): void {
    const text = StringHelper.removeSpecialCharacters(this.textContent ?? '') ?? '';
    const style = window.getComputedStyle(this);
    const fontSize = Quantity.px2Pt(parseFloat(style.fontSize));
    const stringRect = this.getBoundingClientRect();
    const fontFamily = style.fontFamily;
    const rgb = (style.color.match(/\d+(\.\d+)?/g) ?? ['0', '0', '0']).map((c) => parseFloat(c));
    doc.setTextColor(rgb[0], rgb[1], rgb[2]);
    this.exportWord(
      doc,
      text,
      fontFamily,
      style.fontWeight,
      fontSize,
      Quantity.px2Mm(stringRect.left - pageRect.left),
      Quantity.px2Mm(stringRect.top - pageRect.top)
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'fz-string': StringElement;
  }
}
