import { PropertyField, PropertyFieldDescription, PropertyFieldRef } from './property-field';

export class NumberField<T> extends PropertyField<T, number | null> {
  constructor(description: PropertyFieldDescription<T, number | null>, ref: PropertyFieldRef<T, number | null>) {
    super({ displayType: 'Table', ...description }, ref);
  }
  get value(): number | null {
    return this.propertyValue;
  }
  set value(value: number | null) {
    this.propertyValue = value;
  }
}
