<p-panel>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
  </ng-template>
  <div class="fz-paragraph-text">
    <p>
      Die Textbausteine wurden am
      {{ exportTbResult?.uploadedTbInfo?.uploadTime }} von
      {{ exportTbResult?.uploadedTbInfo?.uploadOrigin }} bereitgestellt.
    </p>
    <p>Wollen Sie den Textbausteine überschreiben?</p>
  </div>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button
        pButton
        type="button"
        label="Abbrechen"
        (click)="restart()"
        icon="pi pi-times"
        class="p-button-secondary w-64"
      ></button>
      <button pButton type="button" class="w-64" label="Überschreiben" (click)="ok()" icon="pi pi-check"></button>
    </div>
  </ng-template>
</p-panel>
