import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListboxModule } from 'primeng/listbox';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';

import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { KonferenzlisteComponent } from './konferenzliste.component';

@NgModule({
  declarations: [KonferenzlisteComponent],
  imports: [
    CommonModule,
    FormsModule,
    PanelModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    ListboxModule,
    InputNumberModule,
  ],
  exports: [KonferenzlisteComponent],
})
export class KonferenzlisteModule {}
