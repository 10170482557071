import { FlinkyAuthService } from '../services/auth/flinky-auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private flinkyAuth: FlinkyAuthService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const navigateToLogin = () => this.router.navigate(['/login-form']);
    if (this.flinkyAuth.isLoggedIn()) {
      return true;
    } else {
      navigateToLogin();
      return false;
    }
  }
}
