import { Component } from '@angular/core';
import { Formular } from '@modules/formular/formular';
import { FormularService } from '@modules/formular/formular.service';

@Component({
  selector: 'fz-sl-schueler-typ',
  templateUrl: 'sl-schueler-typ.component.html',
})
export class SLSchuelerTypComponent {
  constructor(private formularService: FormularService<Formular>) {}

  get formular(): Formular {
    return this.formularService.formular;
  }
}
