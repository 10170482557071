import { Component } from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';

@Component({
  selector: 'fz-sl-zeugnissatz-erlaeuterungen',
  templateUrl: 'sl-zeugnissatz-erlaeuterungen.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLZeugnissatzErlaeuterungenComponent)],
})
export class SLZeugnissatzErlaeuterungenComponent extends SLPanelDecoratorBase {
  override borderDirection = BorderDirection;
}
