import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FormularFactory } from '@modules/formular/formular-factory';
import { FormularModule } from '@modules/formular/formular.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { HE1234hjGeistigFormularComponent } from './formulare/he-1234hj-geistig-formular.component';
import { HE1234hjIntensivFormularComponent } from './formulare/he-1234hj-intensiv-formular.component';
import { HE1234hjLernFormularComponent } from './formulare/he-1234hj-lern-formular.component';
import { HE123hjFlexFormularComponent } from './formulare/he-123hj-flex-formular.component';
import { HE234hjFreitextFormularComponent } from './formulare/he-234hj-freitext-formular.component';
import { HE234hjKeineanlageFormularComponent } from './formulare/he-234hj-keineanlage-formular.component';
import { HE234hjKPFormularComponent } from './formulare/he-234hj-kp-formular.component';
import { HE234jStandardFormularComponent } from './formulare/he-234j-standard-formular.component';
import { HEFormularsatz } from './he-formularsatz';
import { HEAnlageTypComponent } from './options/he-anlage-typ.component';
import { HESavTypComponent } from './options/he-sav-typ.component';
import { HESchuelerTypComponent } from './options/he-schueler-typ.component';
import { HEBereichHeaderComponent } from './projectables/he-bereich-header.component';
import { HEIntensivComponent } from './projectables/he-intensiv.component';
import { HELeistungenWahlComponent } from './projectables/he-leistungen-wahl.component';
import { HELeistungenIntensivComponent } from './projectables/he-leistungen-intensiv.component';
import { HELeistungenComponent } from './projectables/he-leistungen.component';
import { HESavComponent } from './projectables/he-sav.component';
import { HESchuelerComponent } from './projectables/he-schueler.component';
import { HESeitenfussLastFoerderschwerpunktComponent } from './projectables/he-seitenfuss-last-foerderschwerpunkt.component';
import { HESeitenfussLastComponent } from './projectables/he-seitenfuss-last.component';
import { HESeitenfussComponent } from './projectables/he-seitenfuss.component';
import { HESeitenkopfComponent } from './projectables/he-seitenkopf.component';
import { HESiegelComponent } from './projectables/he-siegel.component';
import { HEUnterschriftOptionalComponent } from './projectables/he-unterschrift-optional.component';
import { HEUnterschriftComponent } from './projectables/he-unterschrift.component';
import { HEVersaeumnisComponent } from './projectables/he-versaeumnis.component';
import { HEVersetzungsvermerkComponent } from './projectables/he-versetzungsvermerk.component';
import { HEZeugniskopfComponent } from './projectables/he-zeugniskopf.component';
import { HEZeugnissatzFoerderschwerpunktComponent } from './projectables/he-zeugnissatz-foerderschwerpunkt.component';
import { HEZeugnissatzComponent } from './projectables/he-zeugnissatz.component';
import { HEZeugnistypComponent } from './projectables/he-zeugnistyp.component';
import { HESavVisibleComponent } from './options/he-sav-visible.component';
import { HE234hjSavFormularComponent } from './formulare/he-234hj-sav-formular.component';
import { HEAnlageInhaltTypComponent } from './options/he-anlage-inhalt-typ.component';

@NgModule({
  imports: [BrowserModule, FormsModule, ButtonModule, MenuModule, CheckboxModule, RadioButtonModule, FormularModule],
  declarations: [
    HE1234hjIntensivFormularComponent,
    HE123hjFlexFormularComponent,
    HE1234hjLernFormularComponent,
    HE1234hjGeistigFormularComponent,
    HE234jStandardFormularComponent,
    HE234hjKPFormularComponent,
    HE234hjFreitextFormularComponent,
    HE234hjKeineanlageFormularComponent,
    HE234hjSavFormularComponent,
    HEZeugniskopfComponent,
    HEZeugnissatzComponent,
    HEZeugnistypComponent,
    HESchuelerComponent,
    HEVersaeumnisComponent,
    HEVersetzungsvermerkComponent,
    HEUnterschriftComponent,
    HESeitenkopfComponent,
    HESeitenfussComponent,
    HESeitenfussLastComponent,
    HELeistungenComponent,
    HESavComponent,
    HESchuelerTypComponent,
    HESavTypComponent,
    HESavVisibleComponent,
    HELeistungenWahlComponent,
    HEAnlageTypComponent,
    HEUnterschriftOptionalComponent,
    HEZeugnissatzFoerderschwerpunktComponent,
    HESeitenfussLastFoerderschwerpunktComponent,
    HEBereichHeaderComponent,
    HEIntensivComponent,
    HESiegelComponent,
    HELeistungenIntensivComponent,
    HEAnlageInhaltTypComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class HEModule {}

FormularFactory.registerFormularsatz(new HEFormularsatz());
