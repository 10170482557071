import { Component } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { SLFolgeseiteComponent } from '../projectables/sl-folgeseite.component';
import { SLSeitenfussComponent } from '../projectables/sl-seitenfuss.component';
import { SLLegendeComponent } from '../projectables/sl-legende.component';
import { SLZeugnisFormular } from './sl-zeugnis-formular';
import { NoteField } from '../../../models/fields/note-field';
import { FormularViewType } from '../../formular/formular';
import { SLZeugnissatzComponent } from '../projectables/sl-zeugnissatz.component';
import { SLSchuelerComponent } from '../projectables/sl-schueler.component';
import { SLLeistungenComponent } from '../projectables/sl-leistungen.component';
import { SLSprachenportfolioComponent } from '../projectables/sl-sprachenportfolio.component';
import { SLVersaeumnisComponent } from '../projectables/sl-versaeumnis.component';
import { SLSchulortComponent } from '../projectables/sl-schulort.component';
import { SLUnterschriftComponent } from '../projectables/sl-unterschrift.component';
import { SLVersetzungsvermerkComponent } from '../projectables/sl-versetzungsvermerk.component';

export class SL34hjStandardFormular extends SLZeugnisFormular {
  get viewType(): FormularViewType {
    return SL34hjStandardFormularComponent;
  }

  override get noteFields(): NoteField[] {
    return [
      this.sozialLernArbeitsverhalten.sozialverhalten.note,
      this.sozialLernArbeitsverhalten.lernArbeitsverhalten.note,
      this.religion.gesamt.note,
      this.deutsch.gesamt.note,
      this.deutsch.sprechenZuhoeren.note,
      this.deutsch.lesen.note,
      this.deutsch.texteVerfassen.note,
      this.deutsch.rechtschreiben.note,
      this.sachunterricht.gesamt.note,
      this.mathematik.gesamt.note,
      this.bildendeKunst.gesamt.note,
      this.musik.gesamt.note,
      this.sport.gesamt.note,
      this.schrift.gesamt.note,
    ];
  }
}

@Component({
  selector: 'fz-sl-34hj-standard-formular',
  templateUrl: './sl-34hj-standard-formular.component.html',
})
export class SL34hjStandardFormularComponent extends FormularComponentBase<SL34hjStandardFormular> {
  static createModel(formular: SLZeugnisFormular) {
    return {
      zeugnissatz: SLZeugnissatzComponent.createModel(formular),
      schueler: SLSchuelerComponent.createModel(formular),
      sozialverhalten: {
        label: 'Sozialverhalten',
        subLabel:
          '(z.B. Gewissenhaftigkeit, Zuverlässigkeit, Kooperationsbereitschaft, Hilfsbereitschaft, angemessener Umgang mit Konflikten, Selbstkontrolle und -reflexion, Selbstständigkeit, Toleranz, Teamfähigkeit, Einhalten von Regeln)',
        textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
        noteField: formular.sozialLernArbeitsverhalten.sozialverhalten.note,
      },
      lernArbeitsverhalten: {
        label: 'Lern- und Arbeitsverhalten',
        subLabel:
          '(z.B. Anstrengungsbereitschaft, Ausdauer, Aufmerksamkeit, Lernorganisation, Lernreflexion, Sorgfalt)',
        textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
        noteField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.note,
      },
      besondereFaehigkeiten: {
        label: 'Besondere Fähigkeiten/Fertigkeiten',
        textField: formular.besondereFaehigkeiten,
      },
      leistungen: SLLeistungenComponent.createModel(formular),
      sprachenportfolio: SLSprachenportfolioComponent.createModel(formular),
      bemerkungen: {
        label: 'Bemerkungen',
        textField: formular.bemerkungen,
      },
      versaeumnis: SLVersaeumnisComponent.createModel(formular),
      versetzungsvermerk:
        formular.zeugnis.zeugnissatz.halbjahr === 1 ? SLVersetzungsvermerkComponent.createModel(formular) : undefined,
      schulort: SLSchulortComponent.createModel(formular),
      unterschrift: SLUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return SL34hjStandardFormularComponent.createModel(this.formular);
  }

  slFolgeseiteComponent = SLFolgeseiteComponent;
  slSeitenfussComponent = SLSeitenfussComponent;
  slLegendeComponent = SLLegendeComponent;
  constructor(formularService: FormularService<SL34hjStandardFormular>) {
    super(formularService);
  }
}
