import { ButtonModule } from 'primeng/button';
import { HttpClient } from '@angular/common/http';
import { Component, NgModule, Input, Output, EventEmitter } from '@angular/core';

import { DialogModule } from 'primeng/dialog';

import FlinkyVersionInfo from '../../../models/generated/flinky-version-info';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'fz-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  @Input() infoDialogVisible = false;
  @Output() infoDialogClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  environment = environment;
  info?: FlinkyVersionInfo;
  constructor(private http: HttpClient) {}

  async onShow() {
    this.info = await lastValueFrom(this.http.get<FlinkyVersionInfo>('/api/Info'));
  }

  onHide(): void {
    this.infoDialogClosed.next(true);
  }
}

@NgModule({
  imports: [DialogModule, ButtonModule],
  declarations: [InfoDialogComponent],
  exports: [InfoDialogComponent],
})
export class InfoDialogModule {}
