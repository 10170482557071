import { AfterViewInit, Component } from '@angular/core';
import { AppInfoService } from '../../shared/services';

@Component({
  selector: 'app-export-tb-page',
  templateUrl: './export-tb-page.component.html',
  styleUrls: ['./export-tb-page.component.scss'],
})
export class ExportTbPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Eigene Textbausteine exportieren'));
  }
}
