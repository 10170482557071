<fz-image [src]="src" [width]="width"></fz-image>
<ng-template #content="fzPortal" fzPortal>
  <fz-translate fzFocusable tabindex="0">
    <ng-container [fzPortalOutlet]="projectable?.content"></ng-container>
    <fz-string
      [shadow]="formularService.isReadonly ? '' : '[...]'"
      [style.left.px]="-38"
      [style.bottom.px]="-(image?.rect?.height ?? 0)"
    ></fz-string>
  </fz-translate>
</ng-template>
<ng-template #frontDeco="fzPortal" fzPortal>
  <div
    *ngIf="hasFocus"
    fzFocusable
    tabindex="0"
    style="position: absolute; border-style: solid; border-width: 1px"
    [style.bottom.px]="0"
    class="floating"
  >
    <button pButton label="Lizenz ändern" style="white-space: nowrap" (click)="goEditJahrgang()"></button>
  </div>
</ng-template>
