export const passwordKomplexString = '(?=.*\\d)(?=.*[!@#$%^&*?§,;._+-]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$';
export const passwordKomplexRegex = new RegExp(passwordKomplexString);
export const passwordMinLength = 10;
export const passwordRegexString = '(?=^.{' + passwordMinLength + ',}$)' + passwordKomplexString;
export const passwordRegex = new RegExp(passwordRegexString);
export const passwordHelp = [
  `Mindestens ${passwordMinLength} Zeichen lang`,
  'Mindestens ein Kleinbuchstabe',
  'Mindestens ein Großbuchstabe',
  'Mindestens eine Ziffer',
  'Mindestens ein Sonderzeichen',
];

export const emailRegex: RegExp = /[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}/;

export const activateText =
  'Ihr Account wurde noch nicht bestätigt. Bitte prüfen Sie Ihren Posteingang auf die Aktivierungsmail und klicken zunächst den enthaltenen Link zur Accountbestätigung an.';
