import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { NoteneingabelisteComponent } from './noteneingabeliste.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [NoteneingabelisteComponent],
  imports: [
    CommonModule,
    PanelModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    InputNumberModule,
    SelectButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    AutoCompleteModule,
  ],
  exports: [NoteneingabelisteComponent],
})
export class NoteneingabelisteModule {}
