<fz-formular
  name="BW34jStandard"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  <fz-bw-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-bw-zeugniskopf>
  <fz-bw-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-bw-zeugnistyp>
  <fz-bw-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-bw-schueler>
  <fz-bw-leistungen fzFormularItem="leistungen" fzContainer [model]="model.leistungen"></fz-bw-leistungen>
  <fz-section
    fzFormularItem="allgemeineBeurteilung"
    [label]="model.lernArbeitsverhalten.label"
    headerPaddingBottom="normal"
  >
    <fz-panel paddingBottom="normal" fzSectionHeader>
      <div class="Ueberschriften2">
        <fz-label>{{ model.lernArbeitsverhalten.label }}</fz-label>
      </div>
      <div class="Ueberschriften3">
        <fz-label>(Arbeitshaltung, Selbstständigkeit, Zusammenarbeit in der Klassen- und Schulgemeinschaft)</fz-label>
      </div>
    </fz-panel>
    <fz-verbal [field]="model.lernArbeitsverhalten.textField"></fz-verbal>
  </fz-section>
  <fz-section fzFormularItem="bemerkungen" [label]="model.bemerkungen.label" headerPaddingBottom="normal">
    <fz-intelli [field]="model.bemerkungen.textField"></fz-intelli>
  </fz-section>
  <fz-bw-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-bw-unterschrift>
</fz-formular>
