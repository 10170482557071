import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Hunspell, loadModule } from 'hunspell-asm';
import { NodeHelper, NodeWalker } from '../dom';
import itiriri from 'itiriri';

@Injectable({
  providedIn: 'root',
})
export class SpellCheckerService {
  hunspell: Hunspell | undefined;

  constructor(private http: HttpClient) {}

  async init() {
    try {
      const hunspellFactory = await loadModule();
      const aff = await fetch('assets/spell-checker/de_DE.aff');
      const affBuffer = new Uint8Array(await aff.arrayBuffer());
      const affFile = hunspellFactory.mountBuffer(affBuffer, 'de_DE.aff');
      const dic = await fetch('assets/spell-checker/de_DE.dic');
      const dicBuffer = new Uint8Array(await dic.arrayBuffer());
      const dictFile = hunspellFactory.mountBuffer(dicBuffer, 'de_DE.dic');
      this.hunspell = hunspellFactory.create(affFile, dictFile);
    } catch (ex) {
      console.log('Hunspell konnte nicht geladen werden:');
      console.log(ex);
    }
  }

  checkWord(word: string, excludes: string[]): boolean {
    if (this.hunspell == null) return true;
    if (this.hunspell.spell(word)) return true;
    if (excludes.includes(word)) return true;
    return false;
  }

  checkFragment(fragment: DocumentFragment, excludes: string[]) {
    fragment = fragment.cloneNode(true) as DocumentFragment;
    const text = fragment.textContent ?? '';
    const matches = Array.from(text.matchAll(/([a-zA-ZÀ-ž]+)/g)).filter((m) => !this.checkWord(m[0], excludes));
    if (matches.length > 0) {
      const splitIndexes: number[] = [];
      for (const match of matches) {
        if (match.index != null && match.index !== 0) splitIndexes.push(match.index);
        if (match.index != null && match.index + match[0].length !== text.length - 1)
          splitIndexes.push(match.index + match[0].length);
      }
      const splitFragments: DocumentFragment[] = [];
      let startIndex = 0;
      for (const splitIndex of splitIndexes) {
        const walker = new NodeWalker(fragment);
        const textPosition = itiriri(walker.textPositions).find((tp) => startIndex + tp.index === splitIndex);
        if (textPosition != null) {
          splitFragments.push(
            NodeHelper.extractContents({
              startNode: fragment,
              startOffset: 0,
              endNode: textPosition?.node,
              endOffset: textPosition?.nodeOffset,
            })
          );
          startIndex = splitIndex;
        }
      }
      splitFragments.push(fragment);
      let isNextFragementSpelledWrong = matches[0].index === 0;
      const resultFragment = document.createDocumentFragment();
      for (const splitFragment of splitFragments) {
        if (isNextFragementSpelledWrong) {
          const wrapperElement = document.createElement('span');
          wrapperElement.classList.add('spell-error');
          wrapperElement.appendChild(splitFragment);
          resultFragment.appendChild(wrapperElement);
        } else resultFragment.appendChild(splitFragment);
        isNextFragementSpelledWrong = !isNextFragementSpelledWrong;
      }
      return resultFragment;
    } else return fragment;
  }
}
