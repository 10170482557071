/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectorRef, Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  Block,
  BlockFactory,
  BlockFactoryOwnerProvider,
  BlockFactoryProvider,
  BlockRange,
} from '@modules/blocks/block-factory';
import { BlockDirective } from '@modules/blocks/block.directive';
import { SequenceBlockFactoryComponent } from '@modules/blocks/sequence-block-factory.component';
import { FocusableDirective } from '@modules/shared/focusable.directive';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { ConfirmationService } from 'primeng/api';
import { NIFachtexteItemBlockFactoryComponent } from './ni-fachtexte-item-block-factory.component';
import { FormularFachList } from '../../../models/formular-fach-list';

@Component({
  selector: 'fz-ni-fachtexte',
  templateUrl: 'ni-fachtexte-block-factory.component.html',
  providers: [provideInterfaceBy(BlockFactoryProvider, NIFachtexteBlockFactoryComponent)],
})
export class NIFachtexteBlockFactoryComponent implements BlockFactory {
  static createModel(fachList: FormularFachList) {
    return {
      faecher: fachList.faecher.map((f) => ({
        ...NIFachtexteItemBlockFactoryComponent.createModel(f),
        trackBy: f.fach.index.toString(),
        index: f.fach.index.toString(),
      })),
    };
  }
  @Input() model: ReturnType<typeof NIFachtexteBlockFactoryComponent.createModel> | undefined;
  @Input() fachList: FormularFachList | undefined;
  @ViewChild(SequenceBlockFactoryComponent) blockFactory: SequenceBlockFactoryComponent | undefined;
  @ViewChildren(BlockDirective) blockComponents = new QueryList<BlockDirective>();
  @ViewChildren(FocusableDirective) focusables = new QueryList<FocusableDirective>();

  blocks: Block[] = [];

  get hasFocus(): boolean {
    return this.focusables.some((f) => f.hasFocus);
  }
  constructor(
    public changeDetector: ChangeDetectorRef,
    private ownerProvider: BlockFactoryOwnerProvider,
    private confirmationService: ConfirmationService
  ) {}

  trackByProperty(_index: number, item: any) {
    return item.trackBy;
  }

  project(): void {
    this.blockFactory?.project();
  }
  getBlockCount(): number {
    return this.blockFactory?.getBlockCount() ?? 0;
  }
  measureHeight(range: BlockRange): number {
    return this.blockFactory?.measureHeight(range) ?? 0;
  }
  layout(ranges: BlockRange[]): Block[] {
    this.blocks = this.blockFactory?.layout(ranges) ?? [];
    this.detectChanges();
    return this.blockComponents.toArray();
  }

  addFach(): void {
    if (this.fachList != null) {
      this.fachList.addFach();
      this.detectChanges();
      this.ownerProvider.provided.invalidate();
    }
  }

  deleteFach(index: string): void {
    if (this.fachList != null) {
      const fach = this.fachList.faecher.find((f) => f.fach.index.toString() === index);
      if (fach != null) {
        this.confirmationService.confirm({
          header: 'Frage',
          message: `Soll das Fach "${fach.gesamt.label.value}" wirklich gelöscht werden?`,
          acceptLabel: 'Ja',
          rejectLabel: 'Nein',
          accept: () => {
            this.fachList?.deleteFach(fach);
            this.detectChanges();
            this.ownerProvider.provided.invalidate();
          },
        });
      }
    }
  }

  private detectChanges() {
    this.changeDetector.detectChanges();
  }
}
