<fz-panel
  *ngIf="model != null"
  name="Schueler"
  class="Versaeumnisse_Beschriftung"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <fz-label>Versäumt wurden entschuldigt </fz-label>
  <fz-number class="Versaeumnisse_Text" [field]="model.fehltageEntschuldigtField"></fz-number>
  <fz-label> {{ model.fehltageEntschuldigtField.value === 1 ? 'Tag' : 'Tage' }}, unentschuldigt </fz-label>
  <fz-number class="Versaeumnisse_Text" [field]="model.fehltageUnentschuldigtField"></fz-number>
  <fz-label> {{ model.fehltageUnentschuldigtField.value === 1 ? 'Tag' : 'Tage' }}.</fz-label>
</fz-panel>
