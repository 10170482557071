import { ConfirmationService, MenuItem } from 'primeng/api';
import { NotificationService } from './../../../services/notification/notification.service';
import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import SchulConfig from '../../../../models/generated/schul-config';
import { KoennensprofilService } from '../koennensprofil.service';
import { AppInfoService, WorkspaceService } from '../../../../shared/services';
import { Router } from '@angular/router';
import { FlinkyAuthService } from '../../../../shared/services/auth/flinky-auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fz-kp-setup',
  templateUrl: './kp-setup.component.html',
  styleUrls: ['./kp-setup.component.scss'],
})
export class KpBestaetigungComponent implements OnDestroy, OnInit, AfterViewInit {
  bestaetigung: string = '';
  art: string = '';
  publishKPItems: boolean = false;
  schulConfig: SchulConfig = {} as SchulConfig;
  prevSchulConfig: SchulConfig = {} as SchulConfig;
  isConfigError: boolean = false;
  isLoading: boolean = false;
  subscriptions: Subscription[] = [];
  activeStep: number = 0;
  steps: MenuItem[] = [{ label: 'Bestätigung' }, { label: 'Schulweite Einstellungen' }];

  constructor(
    private kpService: KoennensprofilService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private schuelerService: WorkspaceService,
    private router: Router,
    private flinkyAuth: FlinkyAuthService,
    private appInfoService: AppInfoService
  ) {
    this.subscriptions.push(flinkyAuth.lizenzChanged.subscribe(() => this.loadSchulConfig()));
  }

  ngOnInit(): void {
    this.loadSchulConfig();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Formulareinrichtung'));
  }

  async loadSchulConfig() {
    if (this.flinkyAuth.lizenz != null) {
      this.isConfigError = false;
      this.isLoading = true;
      const config = await this.kpService.loadSchulconfig(this.flinkyAuth.lizenz?.kundennummer);
      this.schulConfig = config;
      if (config == null) {
        this.notificationService.showPermanentError(
          'Die Schuleinstellungen konnten nicht geladen werden.',
          'Fehler beim Laden Schulkonfiguration'
        );
        this.isConfigError = true;
      }
      this.prevSchulConfig = JSON.parse(JSON.stringify(config));

      this.isLoading = false;
    }
  }

  @Output() bestaetigungSet: EventEmitter<boolean> = new EventEmitter<boolean>();

  ok(): void {
    if (
      this.prevSchulConfig.isKPActive !== this.schulConfig.isKPActive ||
      this.prevSchulConfig.kpItemsPublic !== this.schulConfig.kpItemsPublic
    ) {
      this.confirmationService.confirm({
        message:
          'Möchten Sie die Änderungen speichern und auf dem Server bereitstellen oder die Änderungen rückgängig machen?',
        header: 'Frage',
        acceptLabel: 'Speichern und bereitstellen',
        rejectLabel: 'Änderungen verwerfen',
        accept: async () => {
          const result = await this.kpService.uploadSchulconfig(
            this.schulConfig,
            this.flinkyAuth.lizenz?.kundennummer ?? ''
          );
          if (result) {
            if (this.schulConfig.isKPActive) {
              this.bestaetigungSet.emit(true);
            } else {
              this.router.navigate(['/main']);
            }
          } else {
            this.notificationService.showPermanentError(
              'Die Schulkonfiguration konnte nicht gespeichert werden. Bitte Versuchen Sie es später erneut.',
              'Fehler'
            );
          }
        },
        reject: () => {
          this.loadSchulConfig();
        },
      });
    } else {
      if (this.schulConfig.isKPActive) {
        this.bestaetigungSet.emit(true);
      } else {
        history.back();
      }
    }
  }

  cancel(): void {
    this.bestaetigung = '';
    history.back();
  }
}
