import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import BenutzerTextbaustein from '../../../../models/generated/benutzer-textbaustein';
import { FlinkyAuthService } from '../../../../shared/services/auth/flinky-auth.service';

@Injectable({
  providedIn: 'root',
})
export class TextbausteinService {
  cachedTextbausteine: BenutzerTextbaustein[] = [];
  constructor(
    private http: HttpClient,
    private flinkyAuth: FlinkyAuthService
  ) {
    this.flinkyAuth.lizenzChanged.subscribe(() => this.loadTb());
  }

  async loadTb() {
    if (this.flinkyAuth.lizenz != null) {
      this.cachedTextbausteine = await lastValueFrom(this.http.get<BenutzerTextbaustein[]>('/api/textbaustein/getAll'));
    }
  }

  async getAll() {
    if (this.cachedTextbausteine.length === 0) await this.loadTb();
    return this.cachedTextbausteine;
  }

  async saveTextbaustein(inhalt: string, feld: string) {
    await lastValueFrom(
      this.http.post('/api/textbaustein/post', JSON.stringify(encodeURIComponent(inhalt)), {
        params: new HttpParams().set('feld', feld ?? ''),
        headers: new HttpHeaders().append('Content-Type', 'application/json'),
      })
    );
    const cachedTb = this.cachedTextbausteine.find((tb) => tb.feld === feld);
    if (cachedTb != null) {
      cachedTb.inhalt = inhalt;
    } else {
      this.cachedTextbausteine.push({ inhalt, feld } as BenutzerTextbaustein);
    }
  }
}
