import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { BWPanelDecoratorBase } from './bw-panel-decorator-base';
import { BWFormular } from '../formulare/bw-formular';

@Component({
  selector: 'fz-bw-leistungen',
  templateUrl: 'bw-leistungen.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, BWLeistungenComponent)],
})
export class BWLeistungenComponent extends BWPanelDecoratorBase {
  static createModel(formular: BWFormular) {
    return {
      religion: {
        label: 'Religionslehre',
        labelField: !formular.isAbschluss ? formular.religionsunterricht : undefined,
        noteField: formular.religion.gesamt.note,
      },
      deutsch: {
        label: 'Deutsch',
        noteField: formular.deutsch.gesamt.note,
      },
      mathematik: {
        label: 'Mathematik',
        noteField: formular.mathematik.gesamt.note,
      },
      fremdsprache: {
        labelField: formular.fremdspracheBezeichnung,
        noteField: formular.fremdsprache.gesamt.note,
      },
      sachunterricht: {
        label: 'Sachunterricht',
        noteField: formular.sachunterricht.gesamt.note,
      },
      bildendeKunst: {
        label: 'Kunst/Werken',
        noteField: formular.bildendeKunst.gesamt.note,
      },
      musik: {
        label: 'Musik',
        noteField: formular.musik.gesamt.note,
      },
      sport: {
        label: 'Bewegung, Spiel und Sport',
        noteField: formular.sport.gesamt.note,
      },
      schrift:
        formular.zeugnis.zeugnissatz.halbjahr === 1
          ? {
              label: 'Schrift und Gestaltung',
              noteField: formular.schrift.gesamt.note,
            }
          : undefined,
    };
  }

  @Input() model: ReturnType<typeof BWLeistungenComponent.createModel> | undefined;
}
