import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { TbEditorTableComponent } from '../tb-editor-table/tb-editor-table.component';

@Component({
  selector: 'fz-tb-editor',
  templateUrl: './tb-editor.component.html',
  styleUrls: ['./tb-editor.component.scss'],
})
export class TbEditorComponent implements AfterViewInit {
  @ViewChild('table') table: TbEditorTableComponent | undefined;

  ngAfterViewInit() {
    this.loadData(null);
  }

  loadData(kategorie: (string | undefined)[] | null = null) {
    this.table?.loadData(kategorie);
  }

  save() {
    this.table?.save();
  }

  canDeactivate() {
    return !this.table?.isDirty();
  }
}
