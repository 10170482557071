import { Component } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';

@Component({
  selector: 'fz-sl-zeugnissatz-mitteilung',
  templateUrl: 'sl-zeugnissatz-mitteilung.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLZeugnissatzMitteilungComponent)],
})
export class SLZeugnissatzMitteilungComponent extends SLPanelDecoratorBase {}
