import { IntelliField } from '../../../models/fields/intelli-field';
import { RichTextField } from '../../../models/fields/rich-text-field';
import { ValueField } from '../../../models/fields/value-field';
import { VerbalField } from '../../../models/fields/verbal-field';
import { RPZeugnis } from '../../../models/rp-zeugnis';
import { RPSeitenfussComponent } from '../projectables/rp-seitenfuss.component';
import { RPSeitenkopfComponent } from '../projectables/rp-seitenkopf.component';
import { RPFormular } from './rp-formular';
import { RPFormularCategoryKey } from '../rp-formularsatz';

export abstract class RPProtokollFormular extends RPFormular {
  seitenkopf = RPSeitenkopfComponent;
  seitenfuss = RPSeitenfussComponent;
  beteiligtePersonen: RichTextField<RPZeugnis>;
  protokolltext: VerbalField<RPZeugnis>;
  ziel: IntelliField<RPZeugnis>;
  unterschriftSchuelerVisibleOverride: ValueField<RPZeugnis, boolean | null>;

  constructor(zeugnis: RPZeugnis) {
    super(zeugnis);
    this.beteiligtePersonen = new RichTextField(
      {
        label: 'Beteiligte Personen',
        labelShort: 'Pers',
        property: 'beteiligtePersonen',
      },
      { object: zeugnis }
    );
    this.protokolltext = new VerbalField(
      {
        label: 'Sozial-, Lern- und Arbeitsverhalten sowie Lernentwicklung in den Fächern und Lernbereichen',
        labelShort: 'Entw',
        property: 'protokolltext',
        kategorien:
          '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002\r\n10\r\n11\r\n12\r\n1\r\n9000\r\n2000\r\n1003\r\n1004\r\n1006\r\n1008\r\n1010\r\n1001\r\n1000\r\n1014\r\n1018\r\n1019\r\n1021\r\n1002\r\n2001\r\n1005\r\n1007\r\n1009\r\n1011\r\n1015\r\n1017\r\n60\r\n80\r\n90\r\n70',
        eigeneTextbausteinKey: 'Protokolltext',
      },
      { object: zeugnis }
    );
    this.ziel = new IntelliField(
      {
        label: 'Bemerkungen/Vereinbarungen',
        labelShort: 'Bem',
        property: 'ziel',
        textbausteinKey: 'Ziel',
      },
      { object: zeugnis }
    );
    this.unterschriftSchuelerVisibleOverride = new ValueField(
      {
        label: 'Unterschrift Schüler',
        labelShort: 'UnterschSchü',
        property: 'unterschriftSchuelerVisibleOverride',
        required: false,
      },
      { object: zeugnis }
    );
  }
  get category(): RPFormularCategoryKey {
    return 'protokoll';
  }

  get zeugnisTypLabel(): string {
    return 'Protokoll';
  }

  override get fontSizeFactor(): number | null {
    return this.zeugnis.fontSizeFactorProtokoll;
  }
  override set fontSizeFactor(value: number | null) {
    this.zeugnis.fontSizeFactorProtokoll = value;
  }

  get isKP(): boolean {
    return (this.zeugnis.zeugnissatz.raster?.isKPActive ?? false) && this.zeugnis.useKPProtokoll;
  }
  override get areKPItemsOptional(): boolean {
    return true;
  }
}
