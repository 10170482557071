import { TextRange } from '../pdl/text-range';

export type WordRange = TextRange & { spaceLength: number };

export class StringHelper {
  static getWordWithSpaceRanges(text: string): WordRange[] {
    const ranges: WordRange[] = [];
    let index = 0;
    while (index < text.length) {
      const start = index;
      while (index < text.length && !this.isWhiteSpace(text[index])) index++;
      const end = index;
      while (index < text.length && this.isWhiteSpace(text[index])) index++;
      const spaceEnd = index;
      if (start !== spaceEnd) {
        ranges.push({
          start,
          length: end - start,
          spaceLength: spaceEnd - end,
        });
      }
    }
    return ranges;
  }

  static getWordRanges(text: string): TextRange[] {
    const ranges: TextRange[] = [];
    let index = 0;
    while (index < text.length) {
      while (index < text.length && this.isWhiteSpace(text[index])) index++;
      if (index < text.length) {
        const start = index;
        while (index < text.length && !this.isWhiteSpace(text[index])) index++;
        const end = index;
        ranges.push({ start, length: end - start });
      }
    }
    return ranges;
  }

  static isWhiteSpace(char: string): boolean {
    return [' ', String.fromCharCode(160), String.fromCharCode(8203)].includes(char);
  }

  static removeSpecialCharacters(text: string | null | undefined): string | undefined {
    return (
      text
        // eslint-disable-next-line no-irregular-whitespace
        ?.replace(/\t|​/g, ' ')
        ?.replace(/\u00A0/g, ' ') // geschützte Leerzeichen nicht mehr ersetzen. Führt dies zu anderen Problemen?
        ?.replace(/­/g, '')
        // https://de.m.wikipedia.org/wiki/Unicodeblock_Kombinierende_diakritische_Zeichen
        // https://www.w3schools.com/charsets/ref_utf_diacritical.asp (Generator)
        // deutsch
        ?.replace(/ä/g, 'ä')
        ?.replace(/Ä/g, 'Ä')
        ?.replace(/ö/g, 'ö')
        ?.replace(/Ö/g, 'Ö')
        ?.replace(/ü/g, 'ü')
        ?.replace(/Ü/g, 'Ü')
        // französisch
        ?.replace(/ë/g, 'ë')
        ?.replace(/Ë/g, 'Ë')
        ?.replace(/ï/g, 'ï')
        ?.replace(/Ï/g, 'Ï')
        ?.replace(/à/g, 'à')
        ?.replace(/`a/g, 'à')
        ?.replace(/À/g, 'À')
        ?.replace(/`A/g, 'À')
        ?.replace(/è/g, 'è')
        ?.replace(/`e/g, 'è')
        ?.replace(/È/g, 'È')
        ?.replace(/`E/g, 'È')
        ?.replace(/ù/g, 'ù')
        ?.replace(/`u/g, 'ù')
        ?.replace(/Ù/g, 'Ù')
        ?.replace(/`U/g, 'Ù')
        ?.replace(/é/g, 'é')
        ?.replace(/´e/g, 'é')
        ?.replace(/É/g, 'É')
        ?.replace(/´E/g, 'É')
        ?.replace(/â/g, 'â')
        ?.replace(/\^a/g, 'â')
        ?.replace(/Â/g, 'Â')
        ?.replace(/\^A/g, 'Â')
        ?.replace(/ê/g, 'ê')
        ?.replace(/\^e/g, 'ê')
        ?.replace(/Ê/g, 'Ê')
        ?.replace(/\^E/g, 'Ê')
        ?.replace(/î/g, 'î')
        ?.replace(/\^i/g, 'î')
        ?.replace(/Î/g, 'Î')
        ?.replace(/\^I/g, 'Î')
        ?.replace(/ô/g, 'ô')
        ?.replace(/\^o/g, 'ô')
        ?.replace(/Ô/g, 'Ô')
        ?.replace(/\^O/g, 'Ô')
        ?.replace(/û/g, 'û')
        ?.replace(/\^u/g, 'û')
        ?.replace(/Û/g, 'Û')
        ?.replace(/\^U/g, 'Û')
        ?.replace(/ç/g, 'ç')
        ?.replace(/Ç/g, 'Ç')
        ?.replace(/`/g, '')
        ?.replace(/´/g, '')
    );
  }

  static normalizeGuid(guidString: string) {
    return guidString.replace(/{/g, '').replace(/}/g, '').replace(/-/g, '').replace(/ /g, '').toLocaleLowerCase();
  }
}
