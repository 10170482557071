import { Component, AfterViewInit } from '@angular/core';
import { AppInfoService } from '../../shared/services';

@Component({
  selector: 'app-export-page',
  templateUrl: './export-page.component.html',
  styleUrls: ['./export-page.component.scss'],
})
export class ExportPageComponent implements AfterViewInit {
  constructor(private appInfoService: AppInfoService) {}

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Jahrgang exportieren'));
  }
}
