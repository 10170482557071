<div style="position: relative; width: 76px; height: 76px">
  <div
    class="Unterschriften_Beschriftung"
    style="position: absolute; width: 100%; height: 100%; display: grid; justify-content: center; align-content: center"
  >
    <fz-label [color]="color">Siegel</fz-label>
  </div>
  <fz-circle #circle style="position: absolute; width: 100%; height: 100%" [style.color]="color"></fz-circle>
</div>
<ng-template #content="fzPortal" fzPortal>
  <fz-translate [fzPosition]="rect">
    <fz-circle [fzRect]="circleRect" [style.color]="color"></fz-circle>
  </fz-translate>
</ng-template>
