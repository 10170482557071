import { SchuelerlisteImportPreviewComponent } from './schuelerliste-import-preview/schuelerliste-import-preview.component';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { WorkSheet } from 'xlsx';
import { SchuelerlisteImportFileselectionComponent } from './schuelerliste-import-fileselection/schuelerliste-import-fileselection.component';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { WorkspaceService } from '../../../../shared/services';
import { ImportSchueler } from './model/import-schueler';
import { Schueler } from '../../../../models/schueler';
import { JahrgangService } from '../../../services/jahrgang.service';

@Component({
  selector: 'fz-schuelerliste-import',
  templateUrl: './schuelerliste-import.component.html',
  styleUrls: ['./schuelerliste-import.component.scss'],
})
export class SchuelerlisteImportComponent implements OnInit {
  @Output() finished: EventEmitter<null> = new EventEmitter();
  @ViewChild(SchuelerlisteImportFileselectionComponent)
  fileSelectionComponent?: SchuelerlisteImportFileselectionComponent;
  @ViewChild(SchuelerlisteImportPreviewComponent) preview?: SchuelerlisteImportPreviewComponent;
  items: MenuItem[] = [];
  activeStep: number = 0;
  selectedFile?: File;
  fileLines: any[] = [];
  selectedLines: any[] = [];
  schuelers: ImportSchueler[] = [];

  constructor(
    private notificationService: NotificationService,
    private workspaceService: WorkspaceService,
    private jahrgangService: JahrgangService
  ) {}
  public restart(): void {
    this.activeStep = 0;
    this.selectedFile = undefined;
    this.fileSelectionComponent?.reset();
  }

  onFileSelected(file: File) {
    this.readXlsxFile(file);
    this.activeStep++;
  }

  onLinesSelected(lines: any[]): void {
    this.selectedLines = lines;
    this.activeStep++;
  }

  onDataAssigned(schuelers: ImportSchueler[]): void {
    this.schuelers = schuelers;
    this.activeStep++;
  }

  async onSchuelersSelected(importSchuelers: ImportSchueler[]) {
    const schuelers: Schueler[] = [];
    // jetzt erst wirkliche schüler erzeugen
    for (const s of importSchuelers) {
      if (this.workspaceService.jahrgang != null) {
        const schueler = await this.jahrgangService.factory.createSchueler(this.workspaceService.jahrgang);
        schueler.name = s.name;
        schueler.vorname = s.vorname;
        schueler.geburtsdatum = s.geburtsdatum;
        schueler.geburtsort = s.geburtsort;
        schueler.geschlecht = s.geschlecht;

        schuelers.push(schueler);
      }
    }
    await this.workspaceService.createMultipleSchueler(schuelers);
    this.activeStep++;
  }

  back(): void {
    this.activeStep--;
  }

  close(): void {
    this.finished.emit();
  }

  private readXlsxFile(file: File) {
    this.fileLines = [];
    import('xlsx').then((xlsx) => {
      const reader = new FileReader();
      reader.onload = (_event) => {
        try {
          const data = reader.result;
          const wb = xlsx.read(data, { type: 'binary', cellDates: true, cellNF: false });
          const wsname: string = wb.SheetNames[0];
          const ws: WorkSheet = wb.Sheets[wsname];

          this.fileLines = xlsx.utils.sheet_to_json(ws, { header: 1, raw: true });
        } catch (e) {
          if ((e as any).message === 'File is password-protected') {
            this.notificationService.showPermanentError(
              'Diese Datei ist Passwortgeschützt und kann nicht verwendet werden.',
              'Fehler'
            );
          } else {
            this.notificationService.showPermanentError('Diese Datei konnte nicht gelesen werden.', 'Fehler');
          }
          this.activeStep--;
        }
      };
      reader.readAsBinaryString(file);
    });
  }

  ngOnInit(): void {
    this.items = [
      { label: 'Datei auswählen' },
      { label: 'Datenvorschau' },
      { label: 'Spalten zuordnen' },
      { label: 'Filter setzen' },
      { label: 'Fertig' },
    ];
  }
}
