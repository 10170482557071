import { Component, OnInit } from '@angular/core';
import { ReportingService } from '../reporting.service';
import { FormularFactory } from '@modules/formular/formular-factory';
import { Formular } from '@modules/formular/formular';
import ReportJahrgangSelectionOptionDto from '../../../../models/generated/report-jahrgang-selection-option-dto';
import { WorkspaceService } from '../../../../shared/services';
import { Zeugnis } from '../../../../models/zeugnis';

@Component({
  selector: 'fz-report-noten-faecher-1',
  templateUrl: './report-noten-faecher-1.component.html',
  styleUrls: ['./report-noten-faecher-1.component.scss'],
})
export class ReportNotenFaecher1Component implements OnInit {
  options: any;
  data: any;
  jahrgaenge: ReportJahrgangSelectionOptionDto[] = [];
  selectedJahrgang?: ReportJahrgangSelectionOptionDto;

  constructor(
    private reportingService: ReportingService,
    private schuelerService: WorkspaceService
  ) {}

  async ngOnInit() {
    this.options = {
      legend: {
        labels: {
          fontColor: '#495057',
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: '#495057',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: '#495057',
            },
          },
        ],
      },
    };
    this.jahrgaenge = await this.reportingService.getJahrgangSelectionOptions();
    this.selectedJahrgang = this.jahrgaenge[0];
    this.createChart();
  }

  async createChart(): Promise<void> {
    if (this.selectedJahrgang) {
      const data = await this.reportingService.getJahrgang(this.selectedJahrgang.id);
      const datasets: any[] = [];
      const labels: string[] = [];
      const ids: string[] = [];
      let index = 0;

      data.zeugnissaetze.forEach((zs) => {
        labels.push(zs.schuljahr + ' ' + (zs.halbjahr + 1) + '. Hj');
        ids.push(zs.id);
      });

      // alle fächer finden
      const faecher: string[] = [];
      const faecherKeys: string[] = [];
      data.zeugnisse.forEach((zeugnis) => {
        const zsVm = FormularFactory.getFormularsatz(zeugnis).createFormular(zeugnis, 'zeugnis');
        zsVm.noteFields.forEach((nf) => {
          if (!faecherKeys.includes(nf.description.fachKey)) {
            faecher.push(this.getFachLabel(nf.description.fachKey, zsVm));
            faecherKeys.push(nf.description.fachKey);
          }
        });
      });

      faecherKeys.forEach((fk) => {
        index++;
        datasets.push({
          label: faecher[index],
          data: this.getNotenData(data.zeugnisse, fk, ids),
          fill: false,
          borderColor: this.reportingService.getColor(index),
          backgroundColor: this.reportingService.getColor(index),
        });
      });

      // jetzt für alle gefundenen Fächer die Werte finden

      this.data = {
        labels: labels,
        datasets: datasets,
      };
    }
  }

  private getColor(index: number): string {
    const backgroundColors = ['#FF6384', '#4BC0C0', '#FFCE56', '#E7E9ED', '#36A2EB'];
    return backgroundColors[index];
  }

  private getNotenData(zeugnisse: Zeugnis[], key: string, ids: string[]): number[] {
    // alle fächer
    const notenschnitte: number[] = [];

    ids.forEach((id) => {
      let countNote = 0;
      let summeNoten = 0;
      let notenschnitt = 0;
      zeugnisse
        .filter((z) => z.zeugnissatz.id === id)
        .forEach((z) => {
          const zsVm = FormularFactory.getFormularsatz(z).createFormular(z, 'zeugnis');
          const noteNumber = zsVm.noteFields.find((nf) => key === nf.description.fachKey)?.value as number;
          if (noteNumber != null) {
            summeNoten += noteNumber;
            countNote++;
          }
        });
      if (countNote > 0) {
        notenschnitt = summeNoten / countNote;
      }
      notenschnitte.push(notenschnitt);
    });
    return notenschnitte;
  }

  getFachLabel(fachKey: string, formular: Formular): string {
    const fachViewModel = (formular as any)[fachKey ?? ''];
    if (fachViewModel != null) {
      return fachViewModel.description.label ?? fachKey;
    }
    return fachKey;
  }
}
