<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="null"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="Normal"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="'Lernbehindert'"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="SPS"
  ></p-radioButton>
</div>
<ng-container *ngIf="formular.zeugnis.zeugnissatz.klassenstufe === 3">
  <div>
    <p-radioButton
      name="schuelerTyp"
      [value]="'Schuleingangsphase'"
      [(ngModel)]="formular.zeugnis.schuelerTyp"
      label="Noten ausblenden*"
    ></p-radioButton>
  </div>
  <div class="text-xs">
    * Aufgrund des Beschlusses der Gesamtkonferenz können bei einzelnen Schülern in der Klassenstufe 3 die Noten
    komplett ausgesetzt werden (GSchO §34(3)). Laut MBWWK, Herr Reviol, dürfen dann keine Könnensprofile verwendet
    werden! Stand: 7.5.2014
  </div>
</ng-container>
