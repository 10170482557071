<form [formGroup]="profileform" (ngSubmit)="onSubmit()" class="flex-grow h-0 overflow-auto">
  <p-panel header="">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-2 p-sm-2 p-lg-2">
        <div class="flex flex-col items-center h-full justify-center">
          <div><p-avatar icon="pi pi-user" shape="circle" size="xlarge"></p-avatar></div>
          <div>{{ benutzer?.benutzername }}</div>
        </div>
      </div>
      <div class="p-field p-col-10 p-grid p-lg-10 p-sm-10">
        <div class="p-field p-col-6 p-sm-12 p-lg-6">
          <label for="email">E-Mail</label>
          <div class="flex flex-row gap-2">
            <input fzAutofocus id="email" type="text" pInputText formControlName="email" readonly />
            <button
              pButton
              type="button"
              label="Ändern"
              icon="pi pi-edit"
              (click)="emailEditDialogVisible = true"
            ></button>
          </div>
        </div>
        <div class="p-field p-col-6 p-sm-12 p-lg-6"></div>
        <div class="p-field p-col-6 p-sm-12 p-lg-6">
          <label for="vorname">Vorname</label>
          <input id="vorname" type="text" pInputText formControlName="vorname" />
        </div>
        <div class="p-field p-col-6 p-sm-12 p-lg-6">
          <label for="name">Name</label>
          <input id="name" type="text" pInputText formControlName="name" />
        </div>
        <div class="p-field p-col-6 p-sm-12 p-lg-6">
          <label for="lizenz">letzte Kundennummer/Lizenznummer</label>
          <input id="lizenz" type="text" pInputText formControlName="kundennummer" readonly />
        </div>
      </div>
      <!-- <div class="p-field p-col-12 p-sm-12">
        <app-signpad
          width="100%"
          [currentSignature]="benutzer?.unterschrift"
          [name]="profileform.value.name"
          [vorname]="profileform.value.vorname"
          #signpad
        ></app-signpad>
      </div> -->
    </div>
    <ng-template pTemplate="footer">
      <div class="p-filed p-col-12 p-sm-12">
        <button
          pButton
          type="submit"
          icon="pi pi-check"
          [disabled]="!profileform.valid"
          label="Speichern"
          [disabled]="!profileform.touched"
        ></button>
      </div>
    </ng-template>
  </p-panel>
</form>

<p-dialog
  #dialogLizenzwechsel
  [(visible)]="emailEditDialogVisible"
  header="E-Mail-Adresse ändern"
  [style]="{ width: '50vw' }"
>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12" *ngIf="!benutzer?.isLegacyAccount">
      Ihre E-Mail-Adresse ist gleichzeitig ihr Anmeldename. Wenn Sie Ihre E-Mail-Adresse ändern, müssen die diese
      bestätigen und sich dann erneut anmelden.
    </div>
    <div class="p-field p-col-12 p-md-12" *ngIf="benutzer?.isLegacyAccount">
      Seit Flinky Zeugnis Evo v2 ist die E-Mail-Adresse gleichzeitig der Benutzername. Wenn Sie jetzt Ihre
      E-Mail-Adresse ändern, wird diese der neue Benutzername und Sie müssen sie bestätigen und sich dann erneut
      anmelden.
    </div>
    <div class="p-field p-col-12 p-md-12">Aus Sicherheitsgründen müssen Sie hier Ihr Passwort noch einmal angeben.</div>
    <div class="p-field p-col-12 p-md-12">Mit dem Ändern der E-Mail-Adresse werden sie ausgeloggt.</div>
    <div class="p-field p-col-12 p-md-12">
      <label for="password">Aktuelle E-Mail-Adresse</label>
      <input class="w-full" fzAutofocus id="email" type="text" pInputText [value]="benutzer?.email" readonly disabled />
    </div>
    <div class="p-field p-col-12 p-md-12">
      <label for="password">Neue E-Mail-Adresse</label>
      <input
        class="w-full"
        fzAutofocus
        id="email"
        type="text"
        pInputText
        [(ngModel)]="newEmail"
        [pValidateOnly]="true"
        [pKeyFilter]="emailRegex"
        #email="ngModel"
        required
      />
      <div class="p-error" *ngIf="!pass.valid">Die angegebene E-Mail-Adresse ist ungültig.</div>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <label for="password">Aktuelles Passwort</label>
      <p-password
        id="password"
        [feedback]="false"
        styleClass="p-input-icon-right"
        [toggleMask]="true"
        [(ngModel)]="currentPassword"
        required
        #pass="ngModel"
      ></p-password>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      icon="pi pi-times"
      label="Abbrechen"
      (click)="emailEditDialogVisible = false"
      class="p-button-outlined"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="E-Mail-Adresse ändern"
      (click)="changeEmail()"
      [disabled]="!pass.valid || !email.valid"
    ></button>
  </ng-template>
</p-dialog>
