import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[fzFocusable]', exportAs: 'fzFocusable' })
export class FocusableDirective {
  @Output() fzHasFocusChange = new EventEmitter<boolean>();
  #focusCount = 0;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  get hasFocus(): boolean {
    return this.#focusCount > 0;
  }

  @HostListener('focusin', ['$event']) onFocusin(/*e: FocusEvent*/): void {
    this.#focusCount++;
    // console.log(`focusin ${(e.target as any).localName}`);
    this.fzHasFocusChange.emit(this.hasFocus);
  }
  @HostListener('focusout', ['$event']) onFocusout(/*e: FocusEvent*/): void {
    // console.log(`focusout ${(e.target as any).localName}`);
    // console.log(document.activeElement);
    setTimeout(() => {
      // console.log(document.activeElement);
      this.#focusCount--;
      if (document.activeElement === document.body) {
        this.elementRef.nativeElement.focus();
      }
      this.fzHasFocusChange.emit(this.hasFocus);
    });
  }

  focus(): void {
    setTimeout(() => {
      const elem = this.elementRef.nativeElement;
      if ((elem.tabIndex != null && elem.tabIndex !== -1) || elem.contentEditable === 'true') elem.focus();
      else {
        const focusableElements = Array.from(
          elem.querySelectorAll('[tabindex]:not([tabindex="-1"]),[contentEditable="true"]')
        ).filter((el) => !el.hasAttribute('disabled'));
        (focusableElements[0] as HTMLElement)?.focus();
      }
    });
  }
}
