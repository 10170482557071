<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="null"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="Normal"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="'Lernbehindert'"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="L-Schüler*"
  ></p-radioButton>
</div>
<div>
  <p-radioButton
    name="schuelerTyp"
    [value]="'Geistigbehindert'"
    [(ngModel)]="formular.zeugnis.schuelerTyp"
    label="G-Schüler"
  ></p-radioButton>
</div>
<div class="text-xs">* Keine speziellen Formulare laut BiMi - geänderter Versetzungsvermerk bei Abstufung</div>
