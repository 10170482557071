<ng-template #header>
  <p-steps class="w-full fz-steps-transparent" [model]="items" [readonly]="true" [activeIndex]="activeStep"></p-steps>
</ng-template>

<div class="flex-grow h-0 overflow-auto">
  <app-import-select-source
    *ngIf="activeStep === 0"
    (internetSelected)="internet()"
    (uploadCompleted)="uploadCompleted($event)"
    (restart)="firstStep()"
    class="flex-grow h-0 overflow-auto"
    [header]="header"
  ></app-import-select-source>

  <fz-select-kundennummer
    *ngIf="activeStep === 1"
    (kundennummerChecked)="kundennummerChecked()"
    (restart)="firstStep()"
    class="flex-grow h-0 overflow-auto"
    [header]="header"
  >
  </fz-select-kundennummer>

  <app-import-internet-pin
    *ngIf="activeStep === 2"
    (pinChecked)="pinChecked($event)"
    (restart)="firstStep()"
    class="flex-grow h-0 overflow-auto"
    [header]="header"
  >
  </app-import-internet-pin>

  <app-import-assi-passwort
    *ngIf="activeStep === 3"
    [transferFile]="transferFile"
    (passwordChecked)="passwordChecked($event)"
    (restart)="firstStep()"
    class="flex-grow h-0 overflow-auto"
    [header]="header"
  >
  </app-import-assi-passwort>

  <app-import-assi-select-content
    *ngIf="activeStep === 4"
    [transferFile]="transferFile"
    [jahrgang]="jahrgang"
    (importCompleted)="importCompleted()"
    (restart)="firstStep()"
    class="flex-grow h-0 overflow-auto"
    [header]="header"
  ></app-import-assi-select-content>

  <p-panel *ngIf="activeStep === 5">
    <ng-template pTemplate="header">
      <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
    </ng-template>
    <div>
      <div class="flex flex-row">
        <p>Der Import wurde erfolgreich abgeschlossen.</p>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="fz-button-group">
        <button
          pButton
          type="button"
          label="Weiterer Import"
          class="w-64 p-button-secondary"
          icon="pi pi-replay"
          (click)="firstStep()"
        ></button>
        <button
          pButton
          type="button"
          label="Zur Zeugnisübersicht"
          class="w-64"
          icon="pi pi-home"
          (click)="jahrgangAnzeigen()"
        ></button>
      </div>
    </ng-template>
  </p-panel>
</div>
