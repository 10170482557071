<ng-container #container></ng-container>
<!-- <ng-container [ngComponentOutlet]="component"></ng-container> -->
<ng-template #content="fzPortal" fzPortal>
  <fz-translate>
    <ng-container [fzPortalOutlet]="projectable?.content"></ng-container>
  </fz-translate>
</ng-template>
<ng-template #backDeco="fzPortal" fzPortal>
  <fz-translate>
    <ng-container [fzPortalOutlet]="projectable?.backDeco"></ng-container>
  </fz-translate>
</ng-template>
<ng-template #frontDeco="fzPortal" fzPortal>
  <fz-translate>
    <ng-container [fzPortalOutlet]="projectable?.frontDeco"></ng-container>
  </fz-translate>
</ng-template>
