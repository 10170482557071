import { BundeslandTyp } from '../../../../models/enums/bundesland-typ';
import BenutzerTextbaustein from '../../../../models/generated/benutzer-textbaustein';
import { IntelliTextItem } from '../../intellibox/intelliText';

export interface FachKategorie {
  key: string;
  label: string;
  typ: 'intelli' | 'verbal';
  items?: IntelliTextItem[];
  tb?: BenutzerTextbaustein;
  group: string;
}
export class FachSearcher {
  static getFaecher(bundesland: BundeslandTyp) {
    switch (bundesland) {
      case 'SL':
        return this.getSlFaecher();
      case 'NI':
        return this.getNiFaecher();
      case 'HE':
        return this.getHeFaecher();
      case 'RP':
        return this.getRpFaecher();
      case 'BW':
        return this.getBwFaecher();
      default:
        return [];
    }
  }

  static getNiFaecher() {
    const faecher: FachKategorie[] = [];
    faecher.push({ key: 'Deutsch_Sprechen_Zuhoeren', label: 'Sprechen und Zuhören', typ: 'verbal', group: 'Deutsch' });
    faecher.push({
      key: 'Deutsch_Lesen_Texte_umgehen',
      label: 'Lesen und mit Texten umgehen',
      typ: 'verbal',
      group: 'Deutsch',
    });
    faecher.push({
      key: 'Deutsch_Schreiben_Texte_verfassen',
      label: 'Schreiben, Texte verfassen',
      typ: 'verbal',
      group: 'Deutsch',
    });
    faecher.push({ key: 'Mathe_Zahlen_Operationen', label: 'Zahlen und Operationen', typ: 'verbal', group: 'Mathe' });
    faecher.push({ key: 'Mathe_Groessen_Messen', label: 'Größen und Messen', typ: 'verbal', group: 'Mathe' });
    faecher.push({ key: 'Mathe_Raum_Form', label: 'Raum und Form', typ: 'verbal', group: 'Mathe' });
    faecher.push({
      key: 'Sachunterricht_Zeit_Geschichte',
      label: 'Zeit und Geschichte',
      typ: 'verbal',
      group: 'Sachunterricht',
    });
    faecher.push({
      key: 'Sachunterricht_Gesellschaft_Politik',
      label: 'Gesellschaft und Politik',
      typ: 'verbal',
      group: 'Sachunterricht',
    });
    faecher.push({ key: 'Sachunterricht_Raum', label: 'Raum', typ: 'verbal', group: 'Sachunterricht' });
    faecher.push({ key: 'Sachunterricht_Natur', label: 'Natur', typ: 'verbal', group: 'Sachunterricht' });
    faecher.push({ key: 'Sachunterricht_Technik', label: 'Technik', typ: 'verbal', group: 'Sachunterricht' });
    faecher.push({ key: 'Sachunterricht', label: 'Sachunterricht', typ: 'verbal', group: 'Sachunterricht' });
    faecher.push({
      key: 'Fremdsprache_Kommunikative_Fertigkeiten',
      label: 'Kommunikative Fertigkeiten',
      typ: 'verbal',
      group: 'Fremdsprache',
    });
    faecher.push({
      key: 'Fremdsprache_Verfuegung_sprachliche_Mittel',
      label: 'Verfügung über sprachliche Mittel',
      typ: 'verbal',
      group: 'Fremdsprache',
    });
    faecher.push({
      key: 'Fremdsprache_Interkulturelle_Kompetenzen',
      label: 'Interkulturelle Kompetenzen',
      typ: 'verbal',
      group: 'Fremdsprache',
    });
    faecher.push({
      key: 'Fremdsprache_Methodenkompetenzen',
      label: 'Methodenkompetenzen',
      typ: 'verbal',
      group: 'Fremdsprache',
    });
    faecher.push({ key: 'Lernstand_Englisch', label: 'Hör-/Hör-Sehverstehen', typ: 'verbal', group: 'Lernstand' });
    faecher.push({ key: 'Lernstand_Englisch_Sprechen', label: 'Sprechen', typ: 'verbal', group: 'Lernstand' });

    faecher.push({
      key: 'Lernstand_Englisch_Leseverstehen',
      label: 'Leseverstehen',
      typ: 'verbal',
      group: 'Lernstand',
    });
    faecher.push({ key: 'Lernstand_Englisch_Schreiben', label: 'Schreiben', typ: 'verbal', group: 'Lernstand' });
    faecher.push({
      key: 'Lernstand_Englisch_Sprachmittlung',
      label: 'Sprachermittlung',
      typ: 'verbal',
      group: 'Lernstand',
    });
    faecher.push({
      key: 'Interessen',
      label: 'Interessen, Fähigkeiten, Fertigkeiten',
      typ: 'intelli',
      group: 'Interessen',
    });
    faecher.push({
      key: 'Lernstand_Empfehlungen',
      label: 'G Empfehlungen für den weiteren Schulbesuch',
      typ: 'intelli',
      group: 'Lernstand',
    });
    faecher.push({
      key: 'Arbeitsgemeinschaften',
      label: 'Teilnahme an folgenden Arbeitsgemeinschaften / Fördermaßnahmen',
      typ: 'intelli',
      group: 'Arbeitsgemeinschaften',
    });
    faecher.push({ key: 'Sozialverhalten', label: 'Sozialverhalten', typ: 'verbal', group: 'Sozialverhalten' });
    faecher.push({ key: 'Arbeitsverhalten', label: 'Arbeitsverhalten', typ: 'verbal', group: 'Arbeitsverhalten' });
    faecher.push({ key: 'MusikText', label: 'Musik', typ: 'verbal', group: 'Musik' });
    faecher.push({ key: 'SportText', label: 'Sport', typ: 'verbal', group: 'Sport' });
    faecher.push({ key: 'KunstText', label: 'Kunst*)', typ: 'verbal', group: 'Kunst' });
    faecher.push({
      key: 'Textiles Gestalten',
      label: 'Textiles Gestalten',
      typ: 'verbal',
      group: 'Textiles Gestalten',
    });
    faecher.push({ key: 'Gestalten', label: 'Gestalten', typ: 'verbal', group: 'Gestalten' });
    faecher.push({ key: 'Werken', label: 'Gestaltendes Werken', typ: 'verbal', group: 'Werken' });
    faecher.push({ key: 'Religion', label: 'Religion', typ: 'verbal', group: 'Religion' });
    faecher.push({ key: 'WerteNormen', label: 'Werte und Normen', typ: 'verbal', group: 'Werte und Normen' });
    faecher.push({
      key: 'KursHerkunftsspracheText',
      label: 'Herkunftssprachenunterricht',
      typ: 'verbal',
      group: 'Herkunftssprachenunterricht',
    });
    faecher.push({ key: 'Lernstand_Mathematik', label: 'C Mathematik', typ: 'verbal', group: 'Lernstand' });
    faecher.push({ key: 'Lernstand_WeitereFaecher', label: 'D Weitere Fächer', typ: 'verbal', group: 'Lernstand' });
    faecher.push({ key: 'Lernstand_Mehrsprachigkeit', label: 'E Mehrsprachigkeit', typ: 'verbal', group: 'Lernstand' });
    faecher.push({
      key: 'Lernstand_Methodenkompetenzen',
      label: 'F Methodenkompetenzen',
      typ: 'verbal',
      group: 'Lernstand',
    });
    faecher.push({ key: 'Freier_Text', label: 'Bericht', typ: 'verbal', group: 'Freier_Text' });

    faecher.push({ key: 'Bemerkungen', label: 'Bemerkungen', typ: 'intelli', group: 'Bemerkungen' });

    return faecher;
  }

  static getBwFaecher() {
    const faecher: FachKategorie[] = [];

    faecher.push({ key: 'Verhalten', label: 'Sozialverhalten', typ: 'verbal', group: 'Verhalten' });
    faecher.push({ key: 'Arbeiten', label: 'Arbeiten', typ: 'verbal', group: 'Arbeiten' });
    faecher.push({ key: 'Lernen', label: 'Lernen', typ: 'verbal', group: 'Lernen' });
    faecher.push({
      key: 'Allgemeine_Beurteilung',
      label: 'Allgemeine Beurteilung',
      typ: 'verbal',
      group: 'Allgemeine Beurteilung',
    });
    faecher.push({ key: 'Deutsch_Sprechen_Zuhoeren', label: 'Sprechen und Zuhören', typ: 'verbal', group: 'Deutsch' });
    faecher.push({ key: 'Deutsch_Lesen', label: 'Lesen', typ: 'verbal', group: 'Deutsch' });
    faecher.push({ key: 'Deutsch_Beilage', label: 'Deutsch', typ: 'verbal', group: 'Deutsch' });
    faecher.push({ key: 'Mathe_Beilage', label: 'Mathematik', typ: 'verbal', group: 'Mathe' });
    faecher.push({ key: 'Hinweise', label: 'Hinweise', typ: 'intelli', group: 'Hinweise' });
    faecher.push({ key: 'Fremdsprache_Beilage', label: 'Fremdsprache', typ: 'verbal', group: 'Fremdsprache' });
    faecher.push({ key: 'Sachunterricht_Beilage', label: 'Sachunterricht', typ: 'verbal', group: 'Sachunterricht' });
    faecher.push({ key: 'Musik_Beilage', label: 'Musik', typ: 'verbal', group: 'Musik' });
    faecher.push({ key: 'Kunst_Beilage', label: 'Kunst', typ: 'verbal', group: 'Kunst' });
    faecher.push({ key: 'Sport_Beilage', label: 'Bewegung, Spiel und Sport', typ: 'verbal', group: 'Sport' });
    faecher.push({ key: 'Religion_Beilage', label: 'Religionslehre', typ: 'verbal', group: 'Religion' });
    faecher.push({
      key: 'Schrift_Gestaltung_Text',
      label: 'Schrift und Gestaltung',
      typ: 'verbal',
      group: 'Schrift und Gestaltung',
    });

    return faecher;
  }

  static getRpFaecher() {
    const faecher: FachKategorie[] = [];
    faecher.push({ key: 'Sozialverhalten', label: 'Sozialverhalten', typ: 'verbal', group: 'Sozialverhalten' });
    faecher.push({
      key: 'LernArbeitsverhalten',
      label: 'Lern- und Arbeitsverhalten',
      typ: 'verbal',
      group: 'Lern- und Arbeitsverhalten',
    });
    faecher.push({ key: 'Religion_Ethik', label: 'Religion', typ: 'verbal', group: 'Religion' });
    faecher.push({
      key: 'Deutsch_Lesen_UmgangTxtMedien',
      label: 'Lesen, Umgang mit Texten und Medien',
      typ: 'verbal',
      group: 'Deutsch',
    });
    faecher.push({ key: 'Deutsch_Sprechen_Zuhoeren', label: 'Sprechen und Zuhören', typ: 'verbal', group: 'Deutsch' });
    faecher.push({ key: 'Deusch_Texte_verfassen', label: 'Texte verfassen', typ: 'verbal', group: 'Deutsch' });
    faecher.push({ key: 'Deutsch_Richtig_schreiben', label: 'Richtig schreiben', typ: 'verbal', group: 'Deutsch' });
    faecher.push({ key: 'Deutsch_Sprache_untersuchen', label: 'Sprache untersuchen', typ: 'verbal', group: 'Deutsch' });
    faecher.push({ key: 'Sachunterricht', label: 'Sachunterricht', typ: 'verbal', group: 'Sachunterricht' });
    faecher.push({
      key: 'Deutsch_Sachunterricht',
      label: 'Deutsch/Sachunterricht',
      typ: 'verbal',
      group: 'Deutsch/Sachunterricht',
    });
    faecher.push({ key: 'Mathematik', label: 'Mathematik', typ: 'verbal', group: 'Mathe' });
    faecher.push({ key: 'Mathe_Geometrie', label: 'Geometrie', typ: 'verbal', group: 'Mathe' });
    faecher.push({ key: 'Mathe_Zahlen_Rechnen', label: 'Zahlen und Rechnen', typ: 'verbal', group: 'Mathe' });
    faecher.push({ key: 'Mathe_Sachrechnen_Groessen', label: 'Sachrechnen und Größen', typ: 'verbal', group: 'Mathe' });
    faecher.push({ key: 'Musik', label: 'Musik', typ: 'verbal', group: 'Musik' });
    faecher.push({ key: 'Sport', label: 'Sport', typ: 'verbal', group: 'Sport' });
    faecher.push({
      key: 'BK_Textiles_Werken',
      label: 'Bildende Kunst/Textiles Gestalten/Werken',
      typ: 'verbal',
      group: 'Bildende Kunst/Textiles Gestalten/Werken',
    });
    faecher.push({
      key: 'Musik_Sport_BK_Tex_Werk',
      label: 'Musik/Sport/Bildende Kunst/Textiles Gestalten/Werken',
      typ: 'verbal',
      group: 'Musik/Sport/Bildende Kunst/Textiles Gestalten/Werken',
    });
    faecher.push({ key: 'Schrift', label: 'Schrift', typ: 'verbal', group: 'Schrift' });
    faecher.push({ key: 'Herkunftssprache', label: '1. Herkunftssprache', typ: 'verbal', group: 'Herkunftssprache' });
    faecher.push({ key: 'Herkunftssprache2', label: '2. Herkunftssprache', typ: 'verbal', group: 'Herkunftssprache' });
    faecher.push({ key: 'Bemerkungen', label: 'Bemerkungen', typ: 'intelli', group: 'Bemerkungen' });
    faecher.push({ key: 'Protokolltext', label: 'Protokolltext', typ: 'verbal', group: 'Protokolltext' });

    return faecher;
  }

  static getHeFaecher() {
    const faecher: FachKategorie[] = [];

    faecher.push({
      key: 'Verbalbeurteilung',
      label: 'Allgemeine Beurteilung',
      typ: 'verbal',
      group: 'Allgemeine Beurteilung',
    });
    faecher.push({
      key: 'AVHSVH',
      label: 'Arbeits- und Sozialverhalten',
      typ: 'verbal',
      group: 'Arbeits- und Sozialverhalten',
    });
    faecher.push({ key: 'SozialverhaltenText', label: 'Sozialverhalten', typ: 'verbal', group: 'Sozialverhalten' });
    faecher.push({ key: 'ArbeitsverhaltenText', label: 'Arbeitsverhalten', typ: 'verbal', group: 'Arbeitsverhalten' });
    faecher.push({ key: 'ReligionText', label: 'Religion', typ: 'verbal', group: 'Religion' });
    faecher.push({ key: 'DeutschText', label: 'Deutsch', typ: 'verbal', group: 'Deutsch' });
    faecher.push({ key: 'Deutsch_Sprechen_Zuhoeren', label: 'Sprechen und Zuhören', typ: 'verbal', group: 'Deutsch' });
    faecher.push({
      key: 'Deutsch_Lesen_UmgangTxtMedien',
      label: 'Lesen und Rezipieren - mit literarischen und nichtliterarischen Texten / Medien umgehen',
      typ: 'verbal',
      group: 'Deutsch',
    });
    faecher.push({ key: 'Deusch_Texte_verfassen', label: 'Schreiben', typ: 'verbal', group: 'Deutsch' });
    faecher.push({
      key: 'Deutsch_Sprache_untersuchen',
      label: 'Sprache und Sprachgebrauch untersuchen und reflektieren',
      typ: 'verbal',
      group: 'Deutsch',
    });
    faecher.push({ key: 'SachunterrichtText', label: 'Sachunterricht', typ: 'verbal', group: 'Sachunterricht' });
    faecher.push({ key: 'MathematikText', label: 'Mathematik', typ: 'verbal', group: 'Mathe' });
    faecher.push({ key: 'MusikText', label: 'Musik', typ: 'verbal', group: 'Musik' });
    faecher.push({ key: 'SportText', label: 'Sport', typ: 'verbal', group: 'Sport' });
    faecher.push({ key: 'KunstText', label: 'Kunst', typ: 'verbal', group: 'Kunst' });
    faecher.push({
      key: 'EinfFremdspracheText',
      label: 'Einführung in eine Fremdsprache',
      typ: 'verbal',
      group: 'Fremdsprache',
    });
    faecher.push({
      key: 'KursHerkunftsspracheText',
      label: 'Herkunftssprachenunterricht',
      typ: 'verbal',
      group: 'Herkunftssprachenunterricht',
    });
    faecher.push({
      key: 'KursFörderSprachkompetenzText',
      label: 'Sprachkompetenz',
      typ: 'verbal',
      group: 'Sprachkompetenz',
    });
    faecher.push({ key: 'Bemerkungen', label: 'Bemerkungen', typ: 'intelli', group: 'Bemerkungen' });

    return faecher;
  }

  static getSlFaecher() {
    const faecher: FachKategorie[] = [];

    faecher.push({ key: 'Verhalten_Text', label: 'Sozialverhalten', typ: 'verbal', group: 'Sozialverhalten' });
    faecher.push({
      key: 'Mitarbeit_Text',
      label: 'Lern- und Arbeitsverhalten',
      typ: 'verbal',
      group: 'Lern- und Arbeitsverhalten',
    });
    faecher.push({
      key: 'Faehigkeiten',
      label: 'Besondere Fähigkeiten/Fertigkeiten',
      typ: 'intelli',
      group: 'Besondere Fähigkeiten/Fertigkeiten',
    });
    faecher.push({
      key: 'Verbalbeurteilung',
      label: 'Fachbezogene Lern- und Leistungsentwicklung',
      typ: 'verbal',
      group: 'Fachbezogene Lern- und Leistungsentwicklung',
    });
    faecher.push({ key: 'AnlageFreitext', label: 'Anlage Freitext', typ: 'verbal', group: 'Anlage Freitext' });
    faecher.push({
      key: 'ErgaenzendeErlaeuterungen',
      label: 'Ergänzende Erläuterungen',
      typ: 'verbal',
      group: 'Ergänzende Erläuterungen',
    });
    faecher.push({
      key: 'Erziehungsberechtigte',
      label: 'Erziehungsberechtigte',
      typ: 'intelli',
      group: 'Erziehungsberechtigte',
    });
    faecher.push({ key: 'Anschrift', label: 'Anschrift/Telefon', typ: 'intelli', group: 'Anschrift' });
    faecher.push({ key: 'Teilnehmer', label: 'Teilnehmer/innen', typ: 'intelli', group: 'Teilnehmer' });
    faecher.push({
      key: 'Gespraechsinhalte',
      label: 'Zentrale Gesprächsinhalte',
      typ: 'verbal',
      group: 'Gespraechsinhalte',
    });
    faecher.push({
      key: 'VorgeseheneGespraechsinhalte',
      label: 'Vorgesehene zentrale Gesprächsinhalte und ggf. Vereinbarungen',
      typ: 'verbal',
      group: 'Vorgesehene Gespraechsinhalte',
    });
    faecher.push({
      key: 'WeitereBesprechungspunkte',
      label: 'Weitere Besprechungspunkte',
      typ: 'intelli',
      group: 'WeitereBesprechungspunkte',
    });
    faecher.push({ key: 'Vereinbarungen', label: 'Vereinbarungen', typ: 'intelli', group: 'Vereinbarungen' });
    faecher.push({
      key: 'Lernentwicklungsbericht',
      label: 'Lernentwicklungsbericht',
      typ: 'verbal',
      group: 'Lernentwicklungsbericht',
    });
    faecher.push({
      key: 'SchriftlicheMitteilungHinweise',
      label: 'Hinweise des Klassenlehrers/der Klassenlehrerin und ggf. des Förderschullehrers/der Förderschullehrerin',
      typ: 'intelli',
      group: 'SchriftlicheMitteilungHinweise',
    });
    faecher.push({
      key: 'EntwicklungsberichtC',
      label: 'a) Sozialverhalten',
      typ: 'verbal',
      group: 'Entwicklungsbericht',
    });
    faecher.push({
      key: 'EntwicklungsberichtB',
      label: 'b) Arbeitshaltung/Art des Arbeitens und Lernens',
      typ: 'verbal',
      group: 'Entwicklungsbericht',
    });
    faecher.push({
      key: 'EntwicklungsberichtA',
      label: 'c) Bisherige Lern- und Leistungsentwicklung',
      typ: 'verbal',
      group: 'Entwicklungsbericht',
    });
    faecher.push({
      key: 'EntwicklungsberichtD',
      label: 'd) Denkvermögen',
      typ: 'verbal',
      group: 'Entwicklungsbericht',
    });
    faecher.push({
      key: 'EntwicklungsberichtE',
      label: 'e) Sprachliche Ausdrucksfähigkeit',
      typ: 'verbal',
      group: 'Entwicklungsbericht',
    });
    faecher.push({
      key: 'EntwicklungsberichtG',
      label: 'Zusammenfassende Beurteilung',
      typ: 'intelli',
      group: 'Entwicklungsbericht',
    });
    faecher.push({ key: 'Bemerkungen', label: 'Bemerkungen', typ: 'intelli', group: 'Bemerkungen' });

    return faecher;
  }
}
