import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormularItemDirective } from './formular-item.directive';

@Component({
  selector: 'fz-formular-item',
  templateUrl: 'formular-item.component.html',
  styleUrls: ['formular-item.component.scss'],
})
export class FormularItemComponent implements OnInit, OnDestroy {
  @Input() item: FormularItemDirective | undefined;
  @Input() bottomAlignedVisible = false;
  @Output() layoutChange = new EventEmitter<void>();
  public get userLayoutMode(): boolean {
    return this._userLayoutMode;
  }
  @Input() @HostBinding('class.user-layout-mode') public set userLayoutMode(value: boolean) {
    if (this._userLayoutMode !== value) {
      this._userLayoutMode = value;
      this.userLayoutModeChange.emit(this._userLayoutMode);
    }
  }
  @Output() userLayoutModeChange = new EventEmitter<boolean>();
  dialogVisible = false;
  @HostBinding('class.mousedown') isMouseDown = false;

  private _userLayoutMode = false;
  private screenY = 0;

  constructor(
    private zone: NgZone,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // events explizit in runOutsideAngular definieren, damit keine globale Change Detection ausgelöst wird
    this.zone.runOutsideAngular(() => {
      window.addEventListener('mousemove', this.mousemove);
      window.addEventListener('mouseup', this.mouseup);
    });
  }
  ngOnDestroy(): void {
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseup);
  }

  mousedown(e: MouseEvent): void {
    this.isMouseDown = true;
    this.userLayoutMode = true;
    this.screenY = e.screenY - (this.item?.userMarginBottom ?? 0);
  }
  // @HostListener('window:mousemove', ['$event']) // event explizit in runOutsideAngular definieren, damit keine globale Change Detection ausgelöst wird
  mousemove = (e: MouseEvent): void => {
    if (this.isMouseDown && this.item != null) {
      this.item.userMarginBottom = e.screenY - this.screenY;
      if (this.item.userMarginBottom < 0) this.item.userMarginBottom = 0;
      this.changeDetector.detectChanges();
    }
  };
  // @HostListener('window:mouseup', ['$event']) // event explizit in runOutsideAngular definieren, damit keine globale Change Detection ausgelöst wird
  mouseup = (_e: MouseEvent): void => {
    if (this.isMouseDown) {
      this.isMouseDown = false;
      this.layoutChange.emit();
      this.changeDetector.detectChanges();
    }
  };
}
