<form [formGroup]="exportform" (ngSubmit)="export()">
  <p-panel>
    <ng-template pTemplate="header">
      <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
    </ng-template>
    <div class="p-fluid p-formgrid p-grid w-full">
      <div class="p-field p-col-12 p-md-12">
        <p>
          💡 Hinweis: Verwenden Sie diese Funktion ausschließlich zum Austausch von Zeugnissen oder Fachbeurteilungen
          innerhalb des Kollegiums! Der Export wird nach 14 Tagen automatisch gelöscht.
        </p>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <p>Aktuelles Zeugnis: {{ currentZeugnis }}</p>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="vorname">Vorname</label>
        <input pInputText type="text" id="vorname" formControlName="vorname" />
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="name">Name</label>
        <input pInputText type="text" id="name" formControlName="name" />
      </div>
      <div class="p-field p-col-12 p-md-12">
        <p>
          Bitte geben Sie das Export-Passwort ein. Dieses Passwort müssen Sie an den Datei-Empfänger weitergeben, damit
          dieser Ihre Daten am Zielrechner entschlüsseln kann.
        </p>
        <p>Ihre Daten werden vor der Übertragung mit diesem Passwort verschlüsselt.</p>
        <p>
          Notieren Sie sich dieses Passwort an einer sicheren Stelle. Eine Wiederherstellung dieses Passworts ist NICHT
          möglich!
        </p>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="passwort">Passwort</label>

        <p-password
          id="passwort"
          formControlName="password"
          [toggleMask]="true"
          weakLabel="zu unsicher"
          mediumLabel="ausreichend"
          strongLabel="sicher"
          promptLabel="Passwort eingeben"
          styleClass="p-input-icon-right"
          [mediumRegex]="passwordRegexString"
          [strongRegex]="passwordRegexString"
        >
          <ng-template pTemplate="header">
            <h6>Passwort wählen</h6>
          </ng-template>
          <ng-template pTemplate="footer">
            <p-divider></p-divider>
            <p class="p-mt-2">Vorgaben</p>
            <ul class="p-pl-2 p-ml-2 p-mt-0 p-mb-2">
              <li *ngFor="let helpItem of passwordHelpList">{{ helpItem }}</li>
            </ul>
          </ng-template>
        </p-password>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="passwortwiederholung">Passwortwiederholung</label>
        <p-password
          id="passwortwiederholung"
          [toggleMask]="true"
          styleClass="p-input-icon-right"
          formControlName="passwordConfirm"
          [feedback]="false"
        >
          <ng-template pTemplate="header">
            <h6>Passwort wählen</h6>
          </ng-template>
          <ng-template pTemplate="footer">
            <p-divider></p-divider>
            <p class="p-mt-2">Vorgaben</p>
            <ul class="p-pl-2 p-ml-2 p-mt-0 p-mb-2">
              <li *ngFor="let helpItem of passwordHelpList">{{ helpItem }}</li>
            </ul>
          </ng-template>
        </p-password>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="fz-button-group">
        <button
          pButton
          type="submit"
          label="Weiter"
          icon="pi pi-caret-right"
          class="w-64"
          [disabled]="!exportform.valid"
        ></button>
      </div>
    </ng-template>
  </p-panel>
</form>
