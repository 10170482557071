import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-gespraechssatz',
  templateUrl: 'sl-gespraechssatz.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLGespraechssatzComponent)],
})
export class SLGespraechssatzComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      selectField: formular.gespraechEinladungAnzahl,
      datumField:
        formular.zeugnis.gespraechEinladungAnzahl === 'eine' || formular.zeugnis.gespraechEinladungAnzahl === 'mehrere'
          ? formular.gespraechEinladungAm
          : undefined,
    };
  }

  @Input() model: ReturnType<typeof SLGespraechssatzComponent.createModel> | undefined;
}
