export function isMac() {
  return navigator.userAgent.toLowerCase().includes('mac');
}

export function isWindows() {
  return navigator.userAgent.toLowerCase().includes('win');
}

export function getPlatformShortcut(key: string) {
  return isWindows() ? ` (Strg-${key})` : isMac() ? ` (Cmd-${key})` : '';
}
