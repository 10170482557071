import { Component, EventEmitter, Output } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-notensystem',
  templateUrl: 'sl-notensystem.component.html',
})
export class SLNotensystemComponent {
  @Output() optionClick = new EventEmitter();
  constructor(private formularService: FormularService<SLFormular>) {}

  get formular(): SLFormular {
    return this.formularService.formular;
  }
}
