<fz-panel *ngIf="model != null">
  <div style="margin-top: 8px; display: grid; grid-template-columns: 1fr 1fr; column-gap: 19px">
    <div
      style="
        grid-column: 1;
        display: grid;
        grid-template-columns: 1fr 151px;
        grid-auto-rows: min-content;
        row-gap: 19px;
      "
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.deutsch.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.deutsch.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
    <div
      style="
        grid-column: 2;
        display: grid;
        grid-template-columns: 1fr 151px;
        grid-auto-rows: min-content;
        row-gap: 19px;
      "
    >
      <div class="Noten_Beschriftung">
        <fz-label>{{ model.mathematik.label }}</fz-label>
      </div>
      <div class="Noten_Text">
        <fz-panel style="text-align: center" backgroundColor="rgba(0,0,0,0.15)">
          <fz-note [field]="model.mathematik.noteField"></fz-note>
        </fz-panel>
      </div>
    </div>
  </div>
</fz-panel>
