<ng-template fzTemplate="sidebar">
  <fz-ni-schueler-typ></fz-ni-schueler-typ>
  <fz-ni-normal-typ *ngIf="formular.zeugnis.schuelerTyp == null"></fz-ni-normal-typ>
  <fz-ni-lern-typ *ngIf="formular.zeugnis.schuelerTyp == 'Lernbehindert'"></fz-ni-lern-typ>
  <fz-ni-herkunftssprache></fz-ni-herkunftssprache>
</ng-template>
<fz-formular
  name="NI34hjBericht"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
>
  <fz-ni-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-ni-zeugniskopf>
  <fz-ni-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-ni-zeugnistyp>
  <fz-ni-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-ni-zeugnissatz>
  <fz-ni-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-ni-schueler>
  <fz-ni-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-ni-versaeumnis>
  <fz-deco
    *ngIf="formular.zeugnis.zeugnissatz.schuljahr < 2023"
    fzFormularItem="interessen"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.interessen.label" headerPaddingBottom="normal">
      <fz-intelli
        [field]="model.interessen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="deutsch"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.deutsch.label" headerClass="Ueberschriften" headerPaddingBottom="normal">
      <fz-sequence gap="normal">
        <fz-section
          *ngFor="let bereich of model.deutsch.bereiche; trackBy: trackByProperty"
          [label]="bereich.label"
          headerPaddingBottom="normal"
        >
          <fz-verbal
            [field]="bereich.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-section>
      </fz-sequence>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="mathematik"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.mathematik.label" headerClass="Ueberschriften" headerPaddingBottom="normal">
      <fz-sequence gap="normal">
        <fz-section
          *ngFor="let bereich of model.mathematik.bereiche; trackBy: trackByProperty"
          [label]="bereich.label"
          headerPaddingBottom="normal"
        >
          <fz-verbal
            [field]="bereich.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-section>
      </fz-sequence>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="sachunterricht"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.sachunterricht.label" headerClass="Ueberschriften" headerPaddingBottom="normal">
      <fz-sequence gap="normal">
        <fz-section
          *ngFor="let bereich of model.sachunterricht.bereiche; trackBy: trackByProperty"
          [label]="bereich.label"
          headerPaddingBottom="normal"
        >
          <fz-verbal
            [field]="bereich.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-section>
      </fz-sequence>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="fremdsprache"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.fremdsprache.label" headerClass="Ueberschriften" headerPaddingBottom="normal">
      <fz-sequence gap="normal">
        <fz-section
          *ngFor="let bereich of model.fremdsprache.bereiche; trackBy: trackByProperty"
          [label]="bereich.label"
          headerPaddingBottom="normal"
        >
          <fz-verbal
            [field]="bereich.textField"
            [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
          ></fz-verbal>
        </fz-section>
      </fz-sequence>
    </fz-section>
  </fz-deco>
  <ng-container *ngFor="let fach of model.faecher; trackBy: trackByProperty">
    <fz-deco
      [fzFormularItem]="fach.key"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-section [label]="fach.label" headerPaddingBottom="normal">
        <fz-verbal
          [field]="fach.textField"
          [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
        ></fz-verbal>
      </fz-section>
    </fz-deco>
  </ng-container>
  <fz-deco
    *ngIf="model.herkunftssprache != null"
    fzFormularItem="herkunftssprache"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.herkunftssprache.label" headerPaddingBottom="normal">
      <fz-panel fzSectionHeader class="Ueberschriften2" paddingBottom="normal">
        <div>
          <fz-label>{{ model.herkunftssprache.label }}</fz-label>
        </div>
        <div>
          <fz-label>Herkunftssprache (</fz-label>
          <fz-text [field]="model.herkunftssprache.labelTextField"></fz-text>
          <fz-label>)</fz-label>
        </div>
      </fz-panel>
      <fz-verbal
        [field]="model.herkunftssprache.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <fz-deco
    *ngIf="model.arbeitsgemeinschaften != null"
    fzFormularItem="arbeitsgemeinschaften"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.arbeitsgemeinschaften.label" headerPaddingBottom="normal">
      <fz-intelli
        [field]="model.arbeitsgemeinschaften.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-section>
  </fz-deco>
  <fz-deco
    *ngIf="formular.zeugnis.zeugnissatz.schuljahr >= 2023"
    fzFormularItem="interessen"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.interessen.label" headerPaddingBottom="normal">
      <fz-intelli
        [field]="model.interessen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="arbeitsverhalten"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.lernArbeitsverhalten.label" headerPaddingBottom="normal">
      <fz-verbal
        [field]="model.lernArbeitsverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="sozialverhalten"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.sozialverhalten.label" headerPaddingBottom="normal">
      <fz-verbal
        [field]="model.sozialverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="bemerkungen"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.bemerkungen.label" headerPaddingBottom="normal">
      <fz-intelli
        [field]="model.bemerkungen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-section>
  </fz-deco>
  <fz-ni-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-ni-unterschrift>
  <fz-ni-seitenfuss-last fzFormularItem="legende" fzContainer></fz-ni-seitenfuss-last>
</fz-formular>
