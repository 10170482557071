import { HEFormularCategoryKey } from '../he-formularsatz';
import { HEFormular } from './he-formular';

export abstract class HEAnlageFormular extends HEFormular {
  get category(): HEFormularCategoryKey {
    return 'anlageDeutsch';
  }

  override get fontSizeFactor(): number | null {
    return this.zeugnis.fontSizeFactorAnlage;
  }
  override set fontSizeFactor(value: number | null) {
    this.zeugnis.fontSizeFactorAnlage = value;
  }
}
