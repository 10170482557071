<ng-template #header>
  <p-steps class="w-full fz-steps-transparent" [model]="items" [readonly]="true" [activeIndex]="activeStep"></p-steps>
</ng-template>
<div class="flex-grow h-0 overflow-auto">
  <app-import-tb-step1
    *ngIf="step === '1'"
    [header]="header"
    (internetSelected)="internetChecked($event)"
    (uploadCompleted)="uploadCompleted($event)"
    (restartSelected)="firstStep()"
  ></app-import-tb-step1>

  <app-import-tb-select-mode
    *ngIf="step === 'selectMode'"
    [importTbResult]="importTbResult"
    (restartSelected)="firstStep()"
    (importFinished)="importFinished($event)"
    [header]="header"
  ></app-import-tb-select-mode>

  <app-import-tb-no-data
    *ngIf="step === 'noData'"
    (restartSelected)="firstStep()"
    [header]="header"
  ></app-import-tb-no-data>

  <app-import-tb-error
    *ngIf="step === 'error'"
    [importTbResult]="importTbResult"
    (restartSelected)="firstStep()"
    [header]="header"
  ></app-import-tb-error>

  <app-import-tb-finished
    *ngIf="step === 'finished'"
    (restartSelected)="firstStep()"
    [header]="header"
  ></app-import-tb-finished>
</div>
