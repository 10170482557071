<fz-panel>
  <p>
    <fz-label class="Ueberschriften">Formular nicht verfügbar</fz-label>
  </p>
  <p>
    <fz-label class="Allgemeiner_Zeugnis_Text"
      >Die Formulare für die Halbjahreszeugnisse stehen in dieser Version noch nicht zur Verfügung. Diese werden bis
      spätestens Ende 2021 nachgereicht.</fz-label
    >
  </p>
  <p>
    <fz-label class="Allgemeiner_Zeugnis_Text"
      >Für die Jahreszeugnisse führen Sie bitte den Jahrgang über das "Hamburgermenu->Neu->Nächstes Halbjahr"
      weiter.</fz-label
    >
  </p>
</fz-panel>
