import { ZeugnisTyp } from '../../../models/enums/zeugnis-typ';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { SLZeugnis } from '../../../models/sl-zeugnis';

export class ZeugnisTypField extends DropdownField<SLZeugnis, ZeugnisTyp | null> {
  constructor(private zeugnis: SLZeugnis) {
    super(
      {
        label: 'Zeugnistyp',
        labelShort: 'ZTyp',
        required: false,
        property: 'zeugnisTyp',
        candidates: [
          {
            value: null,
            content:
              zeugnis.zeugnissatz.halbjahr === 1
                ? 'Jahreszeugnis'
                : zeugnis.zeugnissatz.klassenstufe <= 2 &&
                    (zeugnis.zeugnislevel === 1 || zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal)
                  ? 'Protokoll Entwicklungsgespräch'
                  : 'Halbjahreszeugnis',
          },
          { value: 'Abgang', content: 'Abgangszeugnis' },
        ],
      },
      { object: zeugnis }
    );
  }
}
