import jsPDF from 'jspdf';
import { Quantity } from '../dom/quantity';
import { ContentElement } from './content-element';

export class CircleElement extends ContentElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const style = window.getComputedStyle(this);
    if (style.visibility !== 'hidden') {
      this.style.display = 'block';
      this.style.position = 'relative';
      this.innerHTML = `
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100' style="position: absolute; width: 100%; height: 100%">
    <circle cx="50" cy="50" r="50" fill="none" stroke="${this.style.color}" style="stroke-dasharray: 4px, 4px; stroke-width: 1"/>
    </svg>`;
    }
  }
  exportPdf(doc: jsPDF, pageRect: DOMRect): void {
    const style = window.getComputedStyle(this);
    const nodeRect = this.getBoundingClientRect();
    const left = Quantity.px2Mm(nodeRect.left - pageRect.left);
    const top = Quantity.px2Mm(nodeRect.top - pageRect.top);
    const right = Quantity.px2Mm(nodeRect.right - pageRect.left);
    const bottom = Quantity.px2Mm(nodeRect.bottom - pageRect.top);
    doc.setLineWidth(0.25);
    const rgb = (style.color.match(/\d+(\.\d+)?/g) ?? ['0', '0', '0']).map((c) => parseFloat(c));
    doc.setDrawColor(rgb[0], rgb[1], rgb[2]);
    doc.setLineDashPattern([1], 0);
    doc.circle(left + (right - left) / 2, top + (bottom - top) / 2, (right - left) / 2, 'S');
    doc.setLineDashPattern([], 0);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'fz-circle': CircleElement;
  }
}
