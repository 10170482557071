import { Directive, Injector, NgZone } from '@angular/core';
import { ProjectableOwner, ProjectableOwnerProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Block, BlockFactory, BlockFactoryProvider } from './block-factory';
import { ContainerDirective } from './container.directive';

@Directive({
  selector: '[fzContainer]',
  providers: [
    provideInterfaceBy(BlockFactoryProvider, ContainerBlockFactoryDirective),
    provideInterfaceBy(ProjectableOwnerProvider, ContainerBlockFactoryDirective),
  ],
})
export class ContainerBlockFactoryDirective extends ContainerDirective implements BlockFactory, ProjectableOwner {
  constructor(zone: NgZone, injector: Injector) {
    super(zone, injector);
  }

  getBlockCount(): number {
    return (this.projectable?.height ?? 0) > 0 ? 1 : 0;
  }
  measureHeight(/*_range: LayoutRange*/): number {
    return this.projectable?.height ?? 0;
  }
  layout(/*_blockInstances: BlockInstance[]*/): Block[] {
    this.prevHeight = this.projectable?.height ?? 0;
    // console.log(`Container ${this?.panel?.name} height: ${this.#prevHeight}`);
    return [
      {
        content: this.projectable?.content,
        backDeco: this.projectable?.backDeco,
        frontDeco: this.projectable?.frontDeco,
      },
    ];
  }
}
