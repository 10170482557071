import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeleteAccountService {
  constructor(private http: HttpClient) {}

  deleteAccount(password: string) {
    return lastValueFrom(this.http.delete<boolean>('/api/Benutzer/deleteOwn', { body: { password: password } }));
  }
}
