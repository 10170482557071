import { Component, Input } from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { FormularFach } from '../../../models/formular-fach';
import RPFachKey from '../../../models/generated/rp-fach-key';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';
import { RPFormular } from '../formulare/rp-formular';

@Component({
  selector: 'fz-rp-fach-header',
  templateUrl: 'rp-fach-header.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, RPFachHeaderComponent)],
})
export class RPFachHeaderComponent extends RPPanelDecoratorBase {
  static createModel(fach: FormularFach, formular: RPFormular) {
    return {
      // asdf: 1,
      fachLabelDropdownField: fach.key === RPFachKey.religion ? formular.religionLabel : undefined,
      fachLabelStringAndTextField:
        fach.key === RPFachKey.herkunftssprache
          ? { text: 'Herkunftssprachenunterricht in ', field: formular.herkunftssprache.gesamt.label }
          : fach.key === RPFachKey.herkunftssprache2
            ? { text: 'Herkunftssprachenunterricht in ', field: formular.herkunftssprache2.gesamt.label }
            : undefined,
      fachLabelString: ![RPFachKey.religion, RPFachKey.herkunftssprache, RPFachKey.herkunftssprache2].includes(fach.key)
        ? fach.gesamt.description.label
        : undefined,
      noteLabelString:
        fach.gesamt.isNoteVisible === true && fach.formular.zeugnis.schuelerTyp !== 'Lernbehindert'
          ? 'Note: '
          : undefined,
      noteLabelSelectField:
        (formular.zeugnis.zeugnissatz?.klassenstufe || 0) >= 3 && formular.zeugnis.schuelerTyp === 'Lernbehindert'
          ? fach.gesamt.isVisibleOverrideNoteDefaultFalseField
          : undefined,
      noteField: fach.gesamt.isNoteVisible ? fach.gesamt.note : undefined,
      subs: fach.formular.zeugnis.zeugnissatz.klassenstufe > 1 ? fach.gesamt.description.subs : [],
      labelFortsetzung:
        fach.key === RPFachKey.religion
          ? formular.religionLabel.content
          : fach.key === RPFachKey.herkunftssprache || fach.key === RPFachKey.herkunftssprache2
            ? `Herkunftssprachenunterricht in ${fach.gesamt.bereich.label ?? ''}`
            : fach.gesamt.description.label,
    };
  }

  @Input() fach: FormularFach | undefined;
  @Input() model: ReturnType<typeof RPFachHeaderComponent.createModel> | undefined;
  headerBorder = BorderDirection.top | BorderDirection.left | BorderDirection.right;
}
