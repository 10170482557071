/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/naming-convention */

/* generated file - do not touch! */

import BereichKey from './bereich-key';

class RPBereichKey extends BereichKey {
  public static readonly katholisch: string = 'katholisch';
  public static readonly evangelisch: string = 'evangelisch';
  public static readonly ethik: string = 'ethik';
  public static readonly sonst: string = 'sonst';
  public static readonly geometrie: string = 'geometrie';
  public static readonly zahlenRechnen: string = 'zahlenRechnen';
  public static readonly sachrechnenGroessen: string = 'sachrechnenGroessen';
  public static readonly sprache1: string = 'sprache1';
  public static readonly sprache2: string = 'sprache2';
  public static readonly sprache3: string = 'sprache3';
  public static readonly sprache4: string = 'sprache4';
  public static readonly sprache5: string = 'sprache5';
}
export default RPBereichKey;
