import { ExportTbService } from './export-tb.service';
import { Component } from '@angular/core';
import ExportTbResult from '../../../models/generated/export-tb-result';
import ExportTbResultCode from '../../../models/generated/export-tb-result-code';
import { MenuItem } from 'primeng/api';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';

const headlineStep1 = 'Flinky Zeugnis Textbausteinexport';
const headlineStepConfirm = 'Flinky Zeugnis Textbausteinexport überschreiben';
const headlineStepFinished = 'Flinky Zeugnis Textbausteinexport abgeschlossen';
const headlineStepError = 'Flinky Zeugnis Textbausteinexport fehlerhaft';

@Component({
  selector: 'fz-export-tb',
  templateUrl: './export-tb.component.html',
  styleUrls: ['./export-tb.component.scss'],
})
export class ExportTbComponent {
  headlineText = headlineStep1;

  step = '1';
  exportTbResult?: ExportTbResult;
  exporttyp = '';
  items: MenuItem[];
  activeStep: number = 0;
  constructor(
    private exportTbService: ExportTbService,
    private flinkyAuth: FlinkyAuthService
  ) {
    this.items = [{ label: 'Ziel wählen' }, { label: 'Bestätigung' }, { label: 'Fertig' }];
  }

  exportFinishedManuell(data: ExportTbResult) {
    this.exporttyp = 'manuell';
    this.exportTbResult = data;

    if (data.code === ExportTbResultCode.success) {
      this.step = 'finished';
      this.headlineText = headlineStepFinished;
    } else {
      this.step = 'error';
      this.headlineText = headlineStepError;
    }
    this.activeStep = 2;
  }

  async exportInternetChecked(data: ExportTbResult) {
    this.exportTbResult = data;
    switch (data.code) {
      case ExportTbResultCode.noData:
        this.exportFinishedInternet(
          await this.exportTbService.exportToInternet(this.flinkyAuth.lizenz?.kundennummer ?? '')
        );
        break;
      case ExportTbResultCode.success:
        this.step = 'confirm';
        this.headlineText = headlineStepConfirm;
        this.activeStep = 1;
        break;
      default:
        this.step = 'error';
        this.headlineText = headlineStepError;
        this.activeStep = 2;
    }
  }

  exportFinishedInternet(data: ExportTbResult) {
    this.exporttyp = 'internet';
    this.exportTbResult = data;

    if (data.code === ExportTbResultCode.success) {
      this.step = 'finished';
      this.headlineText = headlineStepFinished;
    } else {
      this.step = 'error';
      this.headlineText = headlineStepError;
    }
    this.activeStep = 2;
  }

  firstStep() {
    this.step = '1';
    this.headlineText = headlineStep1;
    this.activeStep = 0;
  }
}
