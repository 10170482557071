import { DialogModule } from 'primeng/dialog';
import { NoteneingabelisteModule } from './shared/components/noteneingabeliste/noteneingabeliste.module';
import { BlankolisteModule } from './shared/components/blankoliste/blankoliste.module';
import { KonferenzlisteModule } from './shared/components/konferenzliste/konferenzliste.module';
import { ReportingModule } from './shared/components/reporting/reporting.module';
import { RestoreUserBackupModule } from './shared/components/restore-user-backup/restore-user-backup.module';
import { JahrgangOeffnenModule } from './shared/components/jahrgang-oeffnen/jahrgang-oeffnen.module';
import { JahrgangWeiterfuehrenModule } from './shared/components/jahrgang-weiterfuehren/jahrgang-weiterfuehren.module';
import { JahrgangNeuModule } from './shared/components/jahrgang-neu/jahrgang-neu.module';
import { JahrgangNeuPageComponent } from './pages/jahrgang-neu-page/jahrgang-neu-page.component';
import { ProfileModule } from './shared/components/profile/profile.module';
import { ExportModule } from './shared/components/export/export.module';
import { LoginFormModule } from './shared/components/login-form/login-form.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { DownloadLicenceModule } from './shared/components/download-licence/download-licence.module';
import { ChangePasswordModule } from './shared/components/change-password/change-password.component';
import { RecoverPasswordPageComponent } from './pages/recover-password-page/recover-password-page.component';
import { ChangePasswordPageComponent } from './pages/change-password-page/change-password-page.component';
import { DeleteAccountPageComponent } from './pages/delete-account-page/delete-account-page.component';
import { JahrgangOeffnenPageComponent } from './pages/jahrgang-oeffnen-page/jahrgang-oeffnen-page.component';
import { PasswordrecoveryModule } from './shared/components/passwordrecovery/passwordrecovery.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';

import { ExportPageComponent } from './pages/export-page/export-page.component';
import { ImportPageComponent } from './pages/import-page/import-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';

import { ImportModule } from './shared/components/import/import.module';

import { RegisterFormComponent } from './shared/components';

import { AuthGuard } from './shared/services';

import { JahrgangWeiterfuehrenPageComponent } from './pages/jahrgang-weiterfuehren-page/jahrgang-weiterfuehren-page.component';
import { SLModule } from '@modules/sl/sl.module';
import { FormularModule } from '@modules/formular/formular.module';
import { JahrgangWiederfuellenPageComponent } from './pages/jahrgang-wiederfuellen-page/jahrgang-wiederfuellen-page.component';
import { RestoreUserBackupPageComponent } from './pages/restore-user-backup-page/restore-user-backup-page.component';
import { ReportingPageComponent } from './pages/reporting-page/reporting-page.component';
import { RPModule } from '@modules/rp/rp.module';
import { ZeugnissatzDruckenPageComponent } from './pages/zeugnissatz-drucken-page/zeugnissatz-drucken-page.component';
import { ZeugnissatzDruckenModule } from './shared/components/zeugnissatz-drucken/zeugnissatz-drucken.module';
import { SchuelerAkteModule } from './shared/components/schueler-akte/schueler-akte.module';
import { KonferenzlistePageComponent } from './pages/konferenzliste-page/konferenzliste-page.component';
import { VerbalbeurteilungModule } from './shared/components/verbabeurteilung/verbalbeurteilung.module';
import { BlankolistePageComponent } from './pages/blankoliste-page/blankoliste-page.component';
import { ImportTbPageComponent } from './pages/import-tb-page/import-tb-page.component';
import { ExportTbPageComponent } from './pages/export-tb-page/export-tb-page.component';
import { ExportTbModule } from './shared/components/export-tb/export-tb.module';
import { ImportTbModule } from './shared/components/import-tb/import-tb.module';
import { NoteneingabelistePageComponent } from './pages/noteneingabeliste-page/noteneingabeliste-page.component';
import { KoennensprofilEditorPageComponent } from './pages/koennensprofil-editor-page/koennensprofil-editor-page.component';
import { KoennensprofilModule } from './shared/components/koennensprofil-editor/koennensprofil.module';
import { CanDeactivateGuard } from './shared/guards/can-component-deactivate.guard';
import { SchuelerGridModule } from './shared/components/schueler-grid/schueler-grid.module';
import { HEModule } from '@modules/he/he.module';
import { JahrgangAendernPageComponent } from './pages/jahrgang-aendern-page/jahrgang-aendern-page.component';
import { JahrgangAendernModule } from './shared/components/jahrgang-aendern/jahrgang-aendern.module';
import { NIModule } from '@modules/ni/ni.module';
import { BWModule } from '@modules/bw/bw.module';
import { SchuelerlistePageComponent } from './pages/schuelerliste-page/schuelerliste-page.component';
import { SchuelerlisteModule } from './shared/components/schuelerliste/schuelerliste.module';
import { WorkspacePageCanDeactivate } from './shared/components/workspace-title/workspace-page-can-deactivate';
import { ButtonModule } from 'primeng/button';
import { WorkspaceTitleComponent } from './shared/components/workspace-title/workspace-title.component';
import { TextbausteinEditorPageComponent } from './pages/textbaustein-editor-page/textbaustein-editor-page.component';
import { TextbausteinModule } from './shared/components/textbaustein/textbaustein.module';
import { DeleteAccountModule } from './shared/components/delete-account/delete-account.component';
import { SelectKundennummerComponent } from './shared/components/select-kundennummer/select-kundennummer.component';

const routes: Routes = [
  {
    path: 'main',
    component: MainPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [WorkspacePageCanDeactivate],
  },
  {
    path: 'profile',
    component: ProfilePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login-form',
    component: LoginPageComponent,
  },
  {
    path: 'register-form',
    component: RegisterFormComponent,
  },
  {
    path: 'recover',
    component: RecoverPasswordPageComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'delete-account',
    component: DeleteAccountPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'import-page',
    component: ImportPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'export-page',
    component: ExportPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'jahrgang-neu',
    component: JahrgangNeuPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'jahrgang-weiter',
    component: JahrgangWeiterfuehrenPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'jahrgang-oeffnen',
    component: JahrgangOeffnenPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'jahrgang-aendern/:id',
    component: JahrgangAendernPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'jahrgang-wiederfuellen',
    component: JahrgangWiederfuellenPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'restore-user-backup',
    component: RestoreUserBackupPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reporting',
    component: ReportingPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'zeugnissatz-drucken',
    component: ZeugnissatzDruckenPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'konferenzliste',
    component: KonferenzlistePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'blankoliste',
    component: BlankolistePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'schuelerliste',
    component: SchuelerlistePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'import-tb',
    component: ImportTbPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'export-tb',
    component: ExportTbPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'noteneingabeliste',
    component: NoteneingabelistePageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [WorkspacePageCanDeactivate],
  },
  {
    path: 'koennensprofil-editor',
    component: KoennensprofilEditorPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'textbaustein-editor',
    component: TextbausteinEditorPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: '**',
    redirectTo: 'login-form',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    TabViewModule,
    CardModule,
    DialogModule,
    ButtonModule,

    SchuelerGridModule,
    SchuelerAkteModule,
    CommonModule,
    FormularModule,
    SLModule,
    RPModule,
    HEModule,
    NIModule,
    BWModule,

    LoginFormModule,
    PasswordrecoveryModule,
    ChangePasswordModule,
    ImportModule,
    ExportModule,
    DownloadLicenceModule,
    SelectKundennummerComponent,
    ProfileModule,
    JahrgangNeuModule,
    JahrgangWeiterfuehrenModule,
    JahrgangOeffnenModule,
    RestoreUserBackupModule,
    ReportingModule,
    ZeugnissatzDruckenModule,
    KonferenzlisteModule,
    VerbalbeurteilungModule,
    BlankolisteModule,
    ImportTbModule,
    ExportTbModule,
    NoteneingabelisteModule,
    KoennensprofilModule,
    JahrgangAendernModule,
    SchuelerlisteModule,
    WorkspaceTitleComponent,
    TextbausteinModule,
    DeleteAccountModule,
    FormularModule,
  ],
  exports: [RouterModule],
  declarations: [
    ProfilePageComponent,
    MainPageComponent,
    RecoverPasswordPageComponent,
    ChangePasswordPageComponent,
    ImportPageComponent,
    ExportPageComponent,
    LoginPageComponent,
    JahrgangNeuPageComponent,
    JahrgangWeiterfuehrenPageComponent,
    JahrgangOeffnenPageComponent,
    JahrgangWiederfuellenPageComponent,
    RestoreUserBackupPageComponent,
    ReportingPageComponent,
    ZeugnissatzDruckenPageComponent,
    KonferenzlistePageComponent,
    BlankolistePageComponent,
    ImportTbPageComponent,
    ExportTbPageComponent,
    NoteneingabelistePageComponent,
    KoennensprofilEditorPageComponent,
    TextbausteinEditorPageComponent,
    JahrgangAendernPageComponent,
    SchuelerlistePageComponent,
    DeleteAccountPageComponent,
  ],
})
export class AppRoutingModule {}
