import { Directive, ElementRef, Optional, SkipSelf } from '@angular/core';
import { FormularService } from '@modules/formular/formular.service';
import { PanelDecoratorBase } from '@modules/projectables/panel.component';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { BWFormular } from '../formulare/bw-formular';

@Directive()
export class BWPanelDecoratorBase extends PanelDecoratorBase {
  constructor(
    elementRef: ElementRef<HTMLElement>,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private formularService: FormularService<BWFormular>
  ) {
    super(elementRef, parentProvider);
  }
  get formular(): BWFormular {
    return this.formularService.formular;
  }
}
