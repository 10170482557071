import { Directive, Input } from '@angular/core';
import { BlockFactory, BlockFactoryProvider, BlockInstance, BlockPosition } from '@modules/blocks/block-factory';
import { Quantity } from '@modules/dom/quantity';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { UserLayoutFormularItem } from '../../models/user-layout-item';
import { FormularItemInstance, FormularComponent, FormularItem, FormularItemProvider } from './formular.component';

@Directive({
  selector: '[fzFormularItem]',
  providers: [provideInterfaceBy(FormularItemProvider, FormularItemDirective)],
})
export class FormularItemDirective implements FormularItem {
  @Input('fzFormularItem') name = '';
  @Input() marginTop = 0;
  @Input() marginBottom = 4;
  @Input() manualPageBreak = false;
  @Input() bottomAligned = false;

  constructor(
    private formular: FormularComponent,
    private blockFactoryProvider: BlockFactoryProvider
  ) {}

  get userLayoutItem(): UserLayoutFormularItem {
    return this.formular.userLayout.items.getItem(this.name);
  }

  reset(): void {
    this.userLayoutItem.marginBottomM = null;
    this.userLayoutItem.manualPageBreak = null;
    this.userLayoutItem.bottomAligned = null;
  }

  get userMarginBottomM(): number {
    return Math.round(Quantity.px2Mm(this.userMarginBottom));
  }
  set userMarginBottomM(value: number) {
    this.userMarginBottom = Math.round(Quantity.mm2Px(value));
  }
  get userMarginBottom(): number {
    return this.userLayoutItem.marginBottom ?? this.marginBottom;
  }
  set userMarginBottom(value: number) {
    if (value === this.marginBottom) this.userLayoutItem.marginBottom = null;
    else this.userLayoutItem.marginBottom = value;
  }

  get userManualPageBreak(): boolean {
    return this.userLayoutItem.manualPageBreak ?? this.manualPageBreak;
  }
  set userManualPageBreak(value: boolean) {
    if (this.userManualPageBreak !== value) this.userLayoutItem.manualPageBreak = value;
  }

  get userBottomAligned(): boolean {
    return this.userLayoutItem.bottomAligned ?? this.bottomAligned;
  }
  set userBottomAligned(value: boolean) {
    if (this.userBottomAligned !== value) this.userLayoutItem.bottomAligned = value;
  }

  get blockFactory(): BlockFactory {
    return this.blockFactoryProvider.provided;
  }

  project(): void {
    this.blockFactory.project();
  }
  measure(position: BlockPosition): FormularItemInstance {
    const blockInstances: BlockInstance[] = [];
    let pageIndex = position.pageIndex;
    let y = position.y + this.marginTop;
    const blockCount = this.blockFactory.getBlockCount() ?? 0;
    if (blockCount > 0) {
      let diff = 0;
      let i = 1;
      const refHeight = this.blockFactory.measureHeight({
        start: 0,
        length: 0,
      });
      while (i <= blockCount && diff === 0) {
        const height = this.blockFactory.measureHeight({
          start: 0,
          length: i,
        });
        diff = height - refHeight;
        if (y + height > this.formular.getPageMarginTop(pageIndex) + this.formular.getPageHeight(pageIndex)) {
          pageIndex++;
          y = this.formular.getPageMarginTop(pageIndex);
        }
        i++;
      }
    } else {
      if (
        y + this.blockFactory.measureHeight({ start: 0, length: 0 }) >
        this.formular.getPageMarginTop(pageIndex) + this.formular.getPageHeight(pageIndex)
      ) {
        pageIndex++;
        y = this.formular.getPageMarginTop(pageIndex);
      }
    }
    let start = 0;
    let length = 0;
    let height = 0;
    for (let i = 0; i < blockCount; i++) {
      const heightNext = this.blockFactory.measureHeight({
        start,
        length: length + 1,
      });
      if (y + heightNext > this.formular.getPageMarginTop(pageIndex) + this.formular.getPageHeight(pageIndex)) {
        blockInstances.push({
          position: { pageIndex, y },
          range: { start, length },
          height,
        });
        pageIndex++;
        y = this.formular.getPageMarginTop(pageIndex);
        start += length;
        length = 0;
      }
      length++;
      height = heightNext;
    }
    height = this.blockFactory.measureHeight({ start, length });
    blockInstances.push({
      position: { pageIndex, y },
      range: { start, length },
      height,
    });
    y += height;
    const bottomWithMarginPosition =
      blockCount === 0 && this.blockFactory.measureHeight({ start: 0, length: 0 }) === 0
        ? { pageIndex, y }
        : this.userManualPageBreak
          ? {
              pageIndex: pageIndex + 1,
              y: this.formular.getPageMarginTop(pageIndex + 1),
            }
          : { pageIndex, y: y + this.userMarginBottom };
    return {
      item: this,
      blockFactory: this.blockFactory,
      blockInstances,
      blockCount,
      bottomPosition: { pageIndex, y },
      bottomWithMarginPosition,
      bottomAligned: this.userBottomAligned,
      bottomAlignedOffset: 0,
      height: blockInstances.reduce((prev, curr) => prev + curr.height, 0) + this.userMarginBottom,
      name: this.name,
    };
  }
}
