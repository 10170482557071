<div *ngIf="formular.zeugnis.zeugnissatz.klassenstufe < 3">
  <ng-container *ngIf="!formular.zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal">
    <div>
      <p-radioButton
        name="zeugnislevel"
        [value]="1"
        [(ngModel)]="formular.zeugnis.zeugnislevel"
        label="Formular 1"
      ></p-radioButton>
    </div>
    <div>
      <p-radioButton
        name="zeugnislevel"
        [value]="2"
        [(ngModel)]="formular.zeugnis.zeugnislevel"
        label="Formular 2"
      ></p-radioButton>
    </div>
  </ng-container>
  <div>
    <p-checkbox
      label="Schuleingangsphase verbal*"
      [readonly]="true"
      [binary]="true"
      [ngModel]="formular.zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal"
      (onChange)="onCheckboxChange()"
    ></p-checkbox>
  </div>
  <div class="text-xs">
    * Diese Option wird schulweit einheitlich festgelegt. Bitte kontaktieren Sie den Support für eine Änderung.
  </div>
</div>
