import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DownloadLicenceComponent } from './download-licence.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, InputTextModule, ProgressBarModule],
  declarations: [DownloadLicenceComponent],
  exports: [DownloadLicenceComponent],
})
export class DownloadLicenceModule {}
