import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ExportData } from '../export-start/export-start.component';

@Component({
  selector: 'app-export-manuell-filename',
  templateUrl: './export-manuell-filename.component.html',
  styleUrls: ['./export-manuell-filename.component.scss'],
})
export class ExportManuellFilenameComponent implements OnChanges {
  @Input() header: TemplateRef<any> | undefined;
  @Input() exportData: ExportData | null = null;
  @Input() vorname = '';
  @Output() restartClicked = new EventEmitter<boolean>();
  @Output() previousClicked = new EventEmitter<boolean>();
  @Output() filenameSelected = new EventEmitter<string>();
  filename: string = '';

  constructor(private router: Router) {}

  ngOnChanges(): void {
    this.filename = `${this.exportData?.name}-${this.exportData?.vorname}-${this.exportData?.klasseSchuljahrHalbjahr},${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}-${new Date().getHours()}.${new Date().getMinutes()}`;
    this.filename = this.filename.replaceAll(' ', '');
    this.filename = this.filename.replaceAll('|', '_');
    this.filename = this.filename.replaceAll('/', '_');
  }

  doExport(): void {
    if (!this.filename.endsWith('.fzt')) {
      this.filename += '.fzt';
    }
    this.filename = this.filename.replaceAll(' ', '');
    this.filename = this.filename.replaceAll('|', '_');
    this.filename = this.filename.replaceAll('/', '_');
    this.filenameSelected.next(this.filename);
  }
  previous(): void {
    this.previousClicked.next(true);
  }
}
