<fz-panel *ngIf="model != null">
  <div style="display: grid; grid-template-columns: auto 1fr; column-gap: 19px">
    <div style="grid-column: 1">
      <fz-label>Versäumte Unterrichtstage {{ formular.halbjahrLabel }}:</fz-label>&nbsp;
      <fz-number [field]="model.fehltageGesamtField"></fz-number>
    </div>
    <div style="grid-column: 2; justify-self: end">
      <fz-label>davon unentschuldigt:</fz-label>&nbsp;
      <fz-number [field]="model.fehltageUnentschuldigtField"></fz-number>
    </div>
  </div>
</fz-panel>
