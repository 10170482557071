<ng-template #header>
  <p-steps class="w-full fz-steps-transparent" [model]="items" [readonly]="true" [activeIndex]="activeStep"></p-steps>
</ng-template>
<div class="flex-grow h-0 overflow-auto">
  <app-export-tb-step1
    *ngIf="step === '1'"
    [header]="header"
    (exportManuellFinished)="exportFinishedManuell($event)"
    (exportInternetChecked)="exportInternetChecked($event)"
  ></app-export-tb-step1>

  <app-export-tb-confirm
    *ngIf="step === 'confirm'"
    [exportTbResult]="exportTbResult"
    [header]="header"
    (exportInternetFinished)="exportFinishedInternet($event)"
    (restartSelected)="firstStep()"
  ></app-export-tb-confirm>

  <app-export-tb-error
    *ngIf="step === 'error'"
    [header]="header"
    [exportTbResult]="exportTbResult"
    (restartSelected)="firstStep()"
  ></app-export-tb-error>

  <app-export-tb-finished
    *ngIf="step === 'finished'"
    [header]="header"
    [exporttyp]="exporttyp"
    (restartSelected)="firstStep()"
  ></app-export-tb-finished>
</div>
