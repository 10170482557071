import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { FormularBereich } from '../../../models/formular-bereich';
import HEFachKey from '../../../models/generated/he-fach-key';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-bereich-header',
  templateUrl: 'he-bereich-header.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, HEBereichHeaderComponent)],
})
export class HEBereichHeaderComponent extends HEPanelDecoratorBase {
  static createModel(bereich: FormularBereich, formular: HEFormular) {
    return {
      dropdownField: bereich.fach.key === HEFachKey.religion ? formular.religionLabel : undefined,
      label: bereich.fach.key !== HEFachKey.religion ? bereich.description.label : undefined,
      labelFortsetzung:
        bereich.fach.key === HEFachKey.religion ? formular.religionLabel.content : bereich.description.label,
    };
  }

  @Input() model: ReturnType<typeof HEBereichHeaderComponent.createModel> | undefined;
}
