<fz-panel
  name="Schueler"
  [paddingLeft]="formular.showBorder ? 'normal' : 'none'"
  [paddingRight]="formular.showBorder ? 'normal' : 'none'"
>
  <div style="display: grid; grid-template-columns: auto 1fr 1fr 1fr 1fr; grid-template-rows: auto auto">
    <fz-label class="Schuelerelement_Beschriftung" style="grid-row: 1; grid-column: 1">für&nbsp;</fz-label>
    <fz-label class="Schuelername" style="grid-row: 1; grid-column: 2">{{ formular.schuelerName.value }}</fz-label>
    <fz-panel class="Unterschriften_Beschriftung" style="grid-row: 2; grid-column: 2" [border]="borderDirection.top">
      <fz-label>Name</fz-label>
    </fz-panel>
    <fz-label class="Schuelername" style="grid-row: 1; grid-column: 3">{{ formular.schuelerVorname.value }}</fz-label>
    <fz-panel class="Unterschriften_Beschriftung" style="grid-row: 2; grid-column: 3" [border]="borderDirection.top">
      <fz-label>Vorname</fz-label>
    </fz-panel>
    <fz-label class="Schuelername" style="grid-row: 1; grid-column: 4">{{
      formular.schueler.geburtsdatum.displayString
    }}</fz-label>
    <fz-panel class="Unterschriften_Beschriftung" style="grid-row: 2; grid-column: 4" [border]="borderDirection.top">
      <fz-label>Geburtsdatum</fz-label>
    </fz-panel>
    <fz-label class="Schuelername" style="grid-row: 1; grid-column: 5">{{
      formular.zeugnissatz.klassenbezeichnung.value
    }}</fz-label>
    <fz-panel class="Unterschriften_Beschriftung" style="grid-row: 2; grid-column: 5" [border]="borderDirection.top">
      <fz-label>Klassenbezeichnung</fz-label>
    </fz-panel>
  </div>
</fz-panel>
