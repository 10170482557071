import { lastValueFrom } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import UpdateRasterBereichResult from '../../../models/generated/update-raster-bereich-result';
import SchulConfig from '../../../models/generated/schul-config';
import { BenutzerKPRaster } from '../../../models/benutzer-kp-raster';
import { KPItem } from '../../../models/kp-item';
import { Zeugnissatz } from '../../../models/zeugnissatz';

@Injectable({
  providedIn: 'root',
})
export class KoennensprofilService {
  constructor(private http: HttpClient) {}

  getCode(zeugnissatz: Zeugnissatz) {
    const klassenstufe = zeugnissatz.klassenstufe.toString();
    if (zeugnissatz.jahrgang.bundesland === 'RP') {
      return klassenstufe + (zeugnissatz.halbjahr === 0 ? '_p' : '');
    } else {
      return klassenstufe + (zeugnissatz.halbjahr === 0 ? '_1' : '_2');
    }
  }

  async loadRaster(kundennummer: string, klassenstufe: string): Promise<BenutzerKPRaster> {
    const httpParams = new HttpParams().append('klassenstufe', klassenstufe).append('kundennummer', kundennummer);
    const options = { params: httpParams };

    return BenutzerKPRaster.fromDto(await lastValueFrom(this.http.get('/api/koennensprofil/loadRaster', options)));
  }

  async downloadRaster(klassenstufe: string, bundesland: string, kundennummer: string): Promise<BenutzerKPRaster> {
    const params = new HttpParams()
      .append('klassenstufe', klassenstufe)
      .append('bundesland', bundesland.toLowerCase())
      .append('kundennummer', kundennummer);

    return BenutzerKPRaster.fromDto(
      await lastValueFrom(this.http.get('/api/koennensprofil/downloadRaster', { params }))
    );
  }

  getVorgegebeneItems(name: string, bundesland: string): Promise<string> {
    const options = { responseType: 'text' as const };
    return lastValueFrom(this.http.get(`/assets/laender/${bundesland}/kpi/${name}`, options));
  }

  async getEigeneItems(
    klassenstufe: string,
    fach: string,
    bereich: string,
    bundesland: string,
    kundennummer: string
  ): Promise<KPItem[]> {
    let params = new HttpParams()
      .append('klassenstufe', klassenstufe)
      .append('fach', fach)
      .append('bundesland', bundesland)
      .append('kundennummer', kundennummer);

    if (bereich) {
      params = params.append('bereich', bereich);
    }

    const dtos = await lastValueFrom(this.http.get<any[]>('/api/koennensprofil/loadEigene', { params }));
    return dtos.map((dto) => KPItem.fromDto(dto));
  }
  async getCommonItems(
    klassenstufe: string,
    fach: string,
    bereich: string,
    bundesland: string,
    kundennummer: string
  ): Promise<KPItem[]> {
    let httpParams = new HttpParams()
      .append('klassenstufe', klassenstufe)
      .append('fach', fach)
      .append('bundesland', bundesland)
      .append('kundennummer', kundennummer);

    if (bereich) {
      httpParams = httpParams.append('bereich', bereich);
    }

    const options = { params: httpParams };
    const dtos = await lastValueFrom(this.http.get<any[]>('/api/koennensprofil/loadAndereSchulen', options));
    return dtos.map((dto) => KPItem.fromDto(dto));
  }

  saveRaster(raster: BenutzerKPRaster): Promise<void> {
    return lastValueFrom(this.http.post<void>('/api/koennensprofil/saveRaster', BenutzerKPRaster.toDto(raster)));
  }

  uploadRaster(raster: BenutzerKPRaster, overwrite: boolean, bundesland: string): Promise<UpdateRasterBereichResult[]> {
    const httpParams = new HttpParams().append('overwrite', overwrite.toString()).append('bundesland', bundesland);
    const options = { params: httpParams };

    return lastValueFrom(
      this.http.post<UpdateRasterBereichResult[]>(
        '/api/koennensprofil/uploadRaster',
        BenutzerKPRaster.toDto(raster),
        options
      )
    );
  }

  loadSchulconfig(kundennummer: string): Promise<SchulConfig> {
    const params = new HttpParams().append('kundennummer', kundennummer);

    return lastValueFrom(this.http.get<SchulConfig>('/api/koennensprofil/loadSchulConfig', { params }));
  }

  uploadSchulconfig(config: SchulConfig, kundennummer: string): Promise<boolean> {
    const params = new HttpParams().append('kundennummer', kundennummer);

    return lastValueFrom(this.http.post<boolean>('/api/koennensprofil/uploadSchulConfig', config, { params }));
  }
}
