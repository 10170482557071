import { NIFormularCategoryKey } from '../ni-formularsatz';
import { NIFormular } from './ni-formular';

export abstract class NILernstandFormular extends NIFormular {
  get category(): NIFormularCategoryKey {
    return 'lernstand';
  }

  override get fontSizeFactor(): number | null {
    return this.zeugnis.fontSizeFactorLernstand;
  }
  override set fontSizeFactor(value: number | null) {
    this.zeugnis.fontSizeFactorLernstand = value;
  }
}
