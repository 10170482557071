import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { StepsModule } from 'primeng/steps';
import { ExportFinishedComponent } from './export-finished/export-finished.component';
import { ExportSelectTargetComponent } from './export-select-target/export-select-target.component';
import { ExportStartComponent } from './export-start/export-start.component';

import { ClipboardModule } from '@angular/cdk/clipboard';

import { ExportManuellFilenameComponent } from './export-manuell-filename/export-manuell-filename.component';
import { ExportManuellFinishedComponent } from './export-manuell-finished/export-manuell-finished.component';
import { ExportComponent } from './export.component';

import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';

@NgModule({
  imports: [
    CommonModule,
    ClipboardModule,
    PanelModule,
    ReactiveFormsModule,
    ButtonModule,
    PasswordModule,
    DividerModule,
    InputTextModule,
    StepsModule,
    FormsModule,
    InputGroupModule,
    InputGroupAddonModule,
  ],
  declarations: [
    ExportComponent,
    ExportStartComponent,
    ExportSelectTargetComponent,
    ExportFinishedComponent,
    ExportManuellFinishedComponent,
    ExportManuellFilenameComponent,
  ],
  exports: [ExportComponent],
})
export class ExportModule {}
