import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function importModeFormValidator(isNew: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const importMode = control.get('importMode');
    const ueberschreibenBestaetigung = control.get('ueberschreibenBestaetigung');
    if (importMode?.value === 'komplett' && isNew === false) {
      if (ueberschreibenBestaetigung?.value === false) {
        return { importModeForm: 'Bestätigung erforderlich' };
      }
      return null;
    }
    return null;
  };
}
