import { Observable, Subject } from 'rxjs';

export class RichTextSelection {
  readonly #changed = new Subject<void>();

  #focusIndex = 0;
  #focusLeft: number | null = null;
  #anchorIndex = 0;

  public get changed(): Observable<void> {
    return this.#changed.asObservable();
  }

  public get focus(): number {
    return this.#focusIndex;
  }

  public get focusLeft(): number | null {
    return this.#focusLeft;
  }

  public get anchor(): number {
    return this.#anchorIndex;
  }

  public get start(): number {
    return Math.min(this.#anchorIndex, this.#focusIndex);
  }

  public get length(): number {
    return Math.abs(this.#focusIndex - this.#anchorIndex);
  }

  public get end(): number {
    return this.start + this.length;
  }

  public get isCollapsed(): boolean {
    return this.#anchorIndex === this.#focusIndex;
  }

  public setPosition(index: number): void {
    if (this.#anchorIndex !== index || this.#focusIndex !== index) {
      this.#anchorIndex = index;
      this.#focusIndex = index;
      this.#focusLeft = null;
      this.#changed.next();
    }
  }

  public setBaseAndExtend(anchor: number, focus: number): void {
    if (this.#anchorIndex !== anchor || this.#focusIndex !== focus) {
      this.#anchorIndex = anchor;
      this.#focusIndex = focus;
      this.#focusLeft = null;
      this.#changed.next();
    }
  }

  public setFocus(index: number): void {
    if (this.#focusIndex !== index) {
      this.#focusIndex = index;
      this.#focusLeft = null;
      this.#changed.next();
    }
  }

  public setFocusIndexAndLeft(index: number, left: number): void {
    if (this.#focusIndex !== index) {
      this.#focusIndex = index;
      this.#focusLeft = left;
      this.#changed.next();
    }
  }

  public collapseToFocus(): void {
    if (this.#anchorIndex !== this.#focusIndex) {
      this.#anchorIndex = this.#focusIndex;
      this.#changed.next();
    }
  }
}
