<ng-container *ngIf="formular != null">
  <div
    class="Allgemeiner_Zeugnis_Text"
    [style.width.px]="usablePageWidth"
    style="visibility: hidden; position: absolute"
  >
    <ng-container
      *ngFor="let header of headerFooterModels"
      #header
      fzHeaderFooter
      [projectable]="projectable"
      [headerFooter]="header"
    >
      <fz-projectable-outlet
        #projectable
        [component]="header.component"
        [inputs]="{ pageIndex: header.pageIndex }"
      ></fz-projectable-outlet>
    </ng-container>
    <ng-content></ng-content>
  </div>
</ng-container>
