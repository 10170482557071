<div class="flex-grow overflow-auto h-0">
  <div
    *ngIf="(!zeugnis || !zeugnis.schueler) && !isEmpty() && !isNotLoaded() && !hasNoZeugnissatz()"
    class="p-fluid p-grid p-formgrid"
  >
    <div class="p-field p-col-6 p-md-6">
      <p-skeleton height="0.5rem" width="10rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
      <p-skeleton height="2rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
    </div>
    <div class="p-field p-col-6 p-md-6">
      <p-skeleton height="0.5rem" width="10rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
      <p-skeleton height="2rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
    </div>
    <div class="p-field p-col-6 p-md-6">
      <p-skeleton height="0.5rem" width="10rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
      <p-skeleton height="2rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
    </div>
    <div class="p-field p-col-6 p-md-6">
      <p-skeleton height="0.5rem" width="10rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
      <p-skeleton height="2rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
    </div>
    <div class="p-field p-col-6 p-md-6">
      <p-skeleton height="0.5rem" width="10rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
      <p-skeleton height="2rem" styleClass="p-col-12 p-mb-2"></p-skeleton>
    </div>
  </div>

  <p-panel *ngIf="isEmpty()" header="Es existiert kein Jahrgang, was möchten Sie tun?">
    <div class="flex flex-col gap-6">
      <div>
        <div>
          <span class="text-xl pi pi-file">&nbsp;</span
          ><strong class="text-lg">Möchten Sie einen neuen Jahrgang anlegen?</strong><br />
          Wählen Sie diese Option, wenn Sie Ihren Jahrgang neu erstellen möchten. Sie können später aus dem Menü Schüler
          z.B. aus einer CSV-Datei importieren.
        </div>
        <button
          pButton
          type="button"
          label="Neuen Jahrgang anlegen"
          class="w-96 mt-2"
          icon="pi pi-file"
          (click)="neuanlage()"
        ></button>
      </div>
      <div>
        <div>
          <span class="text-xl pi pi-download">&nbsp;</span
          ><strong class="text-lg">Daten aus einem exportierten Jahrgang importieren?</strong><br />
          Wählen Sie diese Option, wenn Sie Daten aus einem von Ihnen oder einer anderen Lehrkraft Ihrer Schule
          exportierten Jahrgang importieren möchten. Sie können eine Datei oder den Import über Internet auswählen.
        </div>
        <button
          pButton
          type="button"
          label="Import starten"
          class="w-96 mt-2"
          icon="pi pi-download"
          (click)="import()"
        ></button>
      </div>
      <div>
        <div>
          <span class="text-xl pi pi-save">&nbsp;</span
          ><strong class="text-lg">Daten aus einer Datensicherung wiederherstellen?</strong><br />
          Wählen Sie diese Option, wenn Sie Daten aus einer Datensicherung wiederherstellen möchten. Sie können eine
          Datensicherungsdatei (ZIP-Datei) aus Flinky Zeugnis Classic oder Evo auswählen.<br />
          <span class="text-xl">💡</span> Sie finden eine automatisch erstellte Datensicherung in Ihrem Dokumente-Ordner
          unter "Flinky Zeugnis Sicherungsordner" (Flinky Zeugnis Classic) bzw. "FlinkyZeugnis" bei Einsatz von Flinky
          Zeugnis Evo Desktop. Alternativ erstelen Sie eine Datensicherung in Flinky Zeugnis Classic über das Datei-Menü
          und hier den Eintrag Verwaltung. In Flinky Zeugnis Evo erstellen Sie eine Datensicherung über das Benutzermenü
          oben rechts.
        </div>
        <button
          pButton
          type="button"
          label="Aus Datensicherung wiederherstellen"
          class="w-96 mt-2"
          icon="pi pi-save"
          (click)="restore()"
        ></button>
      </div>
    </div>
    <!-- <ng-template pTemplate="footer">
      <div class="fz-button-group">
      </div>
    </ng-template> -->
  </p-panel>

  <p-panel *ngIf="hasNoZeugnissatz()" header="In diesem Jahrgang existieren keine Zeugnisse">
    Möchten Sie Zeugnisse erstellen?
    <ng-template pTemplate="footer">
      <div class="fz-button-group">
        <button
          pButton
          type="button"
          label="Zeugnisse erstellen"
          class="w-80"
          icon="pi pi-file"
          (click)="wiederfuellen()"
        ></button>
      </div>
    </ng-template>
  </p-panel>

  <p-panel *ngIf="isNotLoaded()" header="Kein Jahrgang geladen">
    Möchten Sie einen vorhandenen Jahrgang öffnen, einen neuen Jahrgang anlegen oder Daten importieren?
    <ng-template pTemplate="footer">
      <div class="fz-button-group">
        <button
          pButton
          type="button"
          label="Vorhandenen Jahrgang öffnen"
          class="w-80"
          icon="pi pi-folder-open"
          (click)="oeffnen()"
        ></button>
        <button
          pButton
          type="button"
          label="Neuen Jahrgang anlegen"
          class="w-80"
          icon="pi pi-file"
          (click)="neuanlage()"
        ></button>
        <button
          pButton
          type="button"
          label="Import starten"
          class="w-80"
          icon="pi pi-check"
          (click)="import()"
        ></button>
      </div>
    </ng-template>
  </p-panel>

  <div *ngIf="zeugnis && zeugnis.schueler" class="p-fluid p-grid">
    <div class="p-field p-col-12 p-md-6">
      <label for="name">Name</label>
      <input id="name" type="text" pInputText [(ngModel)]="zeugnis.schueler.name" (ngModelChange)="nameChange()" />
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="vorname">Vorname</label>
      <input
        id="vorname"
        type="text"
        pInputText
        [(ngModel)]="zeugnis.schueler.vorname"
        (ngModelChange)="vornameChange()"
      />
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="geschlecht">Geschlecht</label>
      <p-dropdown
        [options]="geschlechter"
        optionLabel="label"
        optionValue="value"
        placeholder="Geschlecht wählen"
        [(ngModel)]="zeugnis.schueler.geschlecht"
      ></p-dropdown>
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="geburtsdatum">Geburtsdatum</label>
      <p-calendar
        id="geburtsdatum"
        [(ngModel)]="zeugnis.schueler.geburtsdatum"
        [showIcon]="true"
        inputId="icon"
        dataType="string"
        dateFormat="dd.mm.yy"
        [yearNavigator]="true"
        [monthNavigator]="true"
        yearRange="2010:2021"
      ></p-calendar>
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="geburtsort">Geburtsort</label>
      <input id="geburtsort" type="text" pInputText [(ngModel)]="zeugnis.schueler.geburtsort" />
    </div>

    <p-panel class="p-col-12" header="Werdegang">
      <div *ngIf="werdegang.length == 0" class="p-fluid p-grid p-formgrid">
        <div class="p-field p-col-6 p-md-6">
          <p-skeleton shape="circle" width="3rem" class="flex justify-end"></p-skeleton>
        </div>
        <div class="p-field p-col-6 p-md-6">
          <p-skeleton height="10rem" styleClass="p-col-11 p-mb-2"></p-skeleton>
        </div>
      </div>

      <p-timeline *ngIf="werdegang.length != 0" align="alternate" [value]="werdegang">
        <ng-template pTemplate="marker" let-werdegang>
          <span class="custom-marker p-shadow-2" [style.backgroundColor]="werdegang.color">
            <i [ngClass]="werdegang.icon"></i>
          </span>
        </ng-template>
        <!-- <ng-template pTemplate="opposite" let-werdegang>
          {{ werdegang.date | date: 'dd.MM.yyyy' }}
          <br/>
          <br/>
          <button pButton label="irgendeine Aktion"></button>
        </ng-template> -->
        <ng-template pTemplate="content" let-werdegang>
          <br />
          <p-card header="{{ werdegang.type }}" subheader="Schuljahr {{ werdegang.schuljahr }}">
            <p>
              {{ werdegang.klassenstufe }}
              <br />
              Klassenleitung {{ werdegang.klassenleitung }}
            </p>
            <!-- <button pButton label="irgendeine Aktion"></button> -->
          </p-card>
        </ng-template>
      </p-timeline>
    </p-panel>
  </div>
</div>
