<div class="flex justify-center">
  <img class="w-20 h-20" src="./assets/Flinky-Logo.svg" alt="Flinky Logo" />
</div>
<div class="text-center">
  <div class="text-2xl">{{ appInfo.title }}</div>
  <div class="text-xl pt-2">Passwort wiederherstellen</div>
</div>

<div *ngIf="formVisible; else requested">
  <form [formGroup]="recoveryform" (ngSubmit)="recover()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12">
        <label for="user" class="pt-2">Benutzer</label>
        <input pInputText formControlName="login" placeholder="E-Mail-Adresse angeben" />
      </div>
      <div class="p-field p-col-12 p-md-12">
        <button pButton type="submit" label="Wiederherstellen" [disabled]="!recoveryform.valid"></button>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <button pButton type="button" icon="pi pi-sign-in" label="Zum Login" (click)="toLogin()"></button>
      </div>
    </div>
  </form>
</div>

<ng-template #requested>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <p class="pt-5">{{ requestedText }}</p>
    </div>
    <div *ngIf="successButtons" class="p-field p-col-12 p-md-12">
      <button pButton type="button" label="Zum Login" (click)="toLogin()"></button>
    </div>
    <div *ngIf="!successButtons" class="p-field p-col-12 p-md-12">
      <button pButton type="button" label="Zurück" (click)="repeat()"></button>
    </div>
  </div>
</ng-template>
