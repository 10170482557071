import { BWZeugnis } from '../../../models/bw-zeugnis';
import { Religionsunterricht } from '../../../models/enums/religionsunterricht';
import { DropdownField } from '../../../models/fields/dropdown-field';

export class ReligionsunterrichtField extends DropdownField<BWZeugnis, Religionsunterricht | null> {
  constructor(private zeugnis: BWZeugnis) {
    super(
      {
        label: 'Religionsunterricht',
        labelShort: 'RU',
        property: 'religionsunterricht',
        candidates: [
          { displayStringShort: String.fromCharCode(160), value: null, content: '' },
          { displayStringShort: '-', value: 'Keine', content: '-' },
          { displayStringShort: 'ev', value: 'Evangelisch', content: '(ev)' },
          { displayStringShort: 'rk', value: 'Katholisch', content: '(rk)' },
          {
            displayStringShort: 'ev-koop',
            value: 'EvangelischKooperativ',
            content: '(ev, konfessionell-kooperativ erteilt)',
          },
          {
            displayStringShort: 'rk-koop',
            value: 'KatholischKooperativ',
            content: '(rk, konfessionell-kooperativ erteilt)',
          },
          {
            displayStringShort: 'ev, rk',
            value: 'EvangelischKatholisch',
            content: '(erstes Halbjahr: ev, zweites Halbjahr: rk)',
          },
          {
            displayStringShort: 'rk, ev',
            value: 'KatholischEvangelisch',
            content: '(erstes Halbjahr: rk, zweites Halbjahr: ev)',
          },
          {
            displayStringShort: 'ev, rk, koop',
            value: 'EvangelischKatholischKooperativ',
            content: '(erstes Halbjahr: ev, zweites Halbjahr: rk, konfessionell-kooperativ erteilt)',
          },
          {
            displayStringShort: 'rk, ev, koop',
            value: 'KatholischEvangelischKooperativ',
            content: '(erstes Halbjahr: rk, zweites Halbjahr: ev, konfessionell-kooperativ erteilt)',
          },
          { displayStringShort: 'IRU', value: 'Iru', content: '(IRU)' },
          { displayStringShort: 'syr', value: 'Syrisch', content: '(syr)' },
        ],
      },
      { object: zeugnis }
    );
  }
}
