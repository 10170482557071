<fz-panel class="Fusstext" [border]="borderDirection.top">
  <div *ngIf="formular.zeugnis.schuelerTyp === 'Lernbehindert'">
    <fz-label>*) Werken und Textiles Gestalten sind Bestandteil des Faches Kunst.</fz-label>
  </div>
  <div *ngIf="formular.zeugnis.schuelerTyp === 'Lernbehindert'">
    <fz-label
      >**) Die Einführung in eine Fremdsprache kann ganz oder teilweise durch Angebote zur Förderung der Sprachkompetenz
      ersetzt werden.</fz-label
    >
  </div>
  <div>
    <fz-label>Erläuterungen:</fz-label>
  </div>
  <div>
    <fz-label
      >Dem Zeugnis liegt die „Verordnung über Unterricht, Erziehung und sonderpädagogische Förderung von Schülerinnen
      und Schülern mit Beeinträchtigungen oder Behinderungen“ in der jeweils geltenden Fassung zugrunde.</fz-label
    >
  </div>
  <div *ngIf="formular.zeugnis.schuelerTyp === 'Geistigbehindert'">
    <fz-label
      >Das Zeugnis beinhaltet eine verbale Beschreibung der Lernentwicklung und des Lernerfolgs sowie der individuellen
      Kompetenzerweiterung und der gemachten Erfahrungen in den angebotenen Erfahrungsfeldern der in diesem Schuljahr
      relevanten Kompetenzbereiche aus den „Richtlinien für Unterricht und Erziehung im Förderschwerpunkt geistige
      Entwicklung“ in der jeweils geltenden Fassung:</fz-label
    >
    <div *ngIf="formular.zeugnis.schuelerTyp === 'Geistigbehindert'">
      <fz-label
        >Sprache und Kommunikation, Soziale Beziehungen, Bewegung und Mobilität, Selbstversorgung, Gesundheitsvorsorge,
        Deutsch, Mathematik, Naturwissenschaft, Informations- und kommunikationstechnische Grundbildung, Ästhetik und
        Kreativität, Leben in der Gesellschaft, Arbeit und Beschäftigung, Religion.</fz-label
      >
    </div>
    <div *ngIf="formular.zeugnis.schuelerTyp === 'Geistigbehindert'">
      <fz-label
        >In den Kompetenzbereichen sind auch Aussagen über das Arbeits- und Sozialverhalten zu treffen.</fz-label
      >
    </div>
  </div>
</fz-panel>
