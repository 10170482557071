<fz-panel>
  <ng-container>
    <div style="text-align: justify">
      <fz-label>Hallo. Dies ist ein Test. </fz-label>
      <fz-text placeholder="Name"></fz-text>
      <fz-label>, </fz-label>
      <fz-text placeholder="Vorname"></fz-text>
      <fz-label> Hallo. Dies ist ein Test.</fz-label>
    </div>
  </ng-container>
</fz-panel>
