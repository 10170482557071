<fz-sequence gap="normal">
  <ng-container *ngIf="model != null">
    <ng-container *ngFor="let fach of model.faecher; trackBy: trackByProperty; let first = first">
      <fz-ni-fachtexte-item
        [model]="fach"
        [isFirstFach]="first"
        (delete)="deleteFach(fach.index)"
      ></fz-ni-fachtexte-item>
    </ng-container>
  </ng-container>
</fz-sequence>

<fz-panel #shadow>
  <fz-label shadow="[...]"></fz-label>
</fz-panel>

<ng-container
  *ngFor="let block of blocks; first as first"
  fzBlock
  [content]="content"
  [backDeco]="backDeco"
  [frontDeco]="frontDeco"
>
  <ng-template #content="fzPortal" fzPortal>
    <fz-translate fzFocusable tabindex="0">
      <fz-translate [style.left.px]="-32" *ngIf="first">
        <ng-container [fzPortalOutlet]="shadow.content"></ng-container>
      </fz-translate>
      <ng-container [fzPortalOutlet]="block.content"></ng-container>
    </fz-translate>
  </ng-template>
  <ng-template #backDeco="fzPortal" fzPortal>
    <fz-translate>
      <ng-container [fzPortalOutlet]="block.backDeco"></ng-container>
    </fz-translate>
  </ng-template>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <div *ngIf="hasFocus && first" fzFocusable [style.bottom.px]="0" class="floating fz-note" tabindex="0">
      <p-button icon="pi pi-plus" label="Fach&nbsp;hinzufügen" (onClick)="addFach()"></p-button>
    </div>
    <fz-translate>
      <ng-container [fzPortalOutlet]="block.frontDeco"></ng-container>
    </fz-translate>
  </ng-template>
</ng-container>
